<template>
<div>
  <div v-if="files" :key="index" class="form-section row" v-for="(file, index) in files">

    {{ file.organizationId }}
    <upload-drop-zone
        class="col-12 col-md-5 align-self-start"
                :asset-id="storeName.toLowerCase() === 'asset' ? id : null"
                :project-id="storeName.toLowerCase() === 'project' ? id : null"
                :user-id="storeName.toLowerCase() === 'user' ? id : null"
                :team-id="storeName.toLowerCase() === 'team' ? id : null"
                :preview-uri="`${targetId}/${file.key}`"
                :image-editable="true"
                :target-type="storeName"
                @fileReceived="forceReRenderKey++"
                :file-name="file.key"
                :downloadable="true"
                :organization-id="file.organizationId"
                :form-id="`${targetId}${index}`"
                :removable="false"
                :make-chunks="true"
        :auto-update-preview="false"
        :use-default-key="false"
        />
      <div class="col-12 col-md-6">
        <h4 class="bg-beige p-1">
            {{ file.key }}
        </h4>

        <span class="lighter">{{ $t('lastModified') }}:</span> {{ dateTimeFromISO(file.lastModified) }}<br />

        <span class="lighter">{{ $t('original filename') }}:</span> {{ file.metaData.filename }}<br />
        <span class="lighter">{{ $t('key') }}:</span> {{ file.key }}<br />
        <span class="lighter">{{ $t('size') }}:</span> {{ file.size / 1000000 }} MB<br />
        <span class="lighter">{{ $t('content-type') }}:</span> {{ file.metaData['content-type'] }}<br />

        <span v-if="file.metaData.attributes" class="lighter">{{ $t('attributes') }}:</span>
        <div v-if="file.metaData.attributes">
          <div
               class="slightly-darker p-2 col-12 mt-1"
              v-for="(val, key) in file.metaData.attributes"
              :key="key"
          >
            {{ key }}: {{ val }}
          </div>
        </div><br />
        <!--<Button
            class="mt-3 icon-rotate-animation make-longer-animation"
            @click="deleteFile(file.key)">{{ $t('delete') }} <icon type="cube" />
        </Button>-->
      </div>

    </div>
</div>
</template>

<script>
import DateMixin from "@/components/mixins/DateMixin.js";
import FileTypeMixin from "@/components/files/FileTypeMixin.js";
//import Button from "@/components/forms/Button";
//import Icon from "@/components/Icon";
import UploadDropZone from "@/components/files/UploadDropZone";
export default {
  name: "FileList",
  components: {
    //Button,
    //Icon,
    UploadDropZone
  },
  mixins: [DateMixin, FileTypeMixin],
  props: {
    storeName: {type: String, required: true},
    targetId: {type: String, required: true},
    fileTypes: {type: Array, default: () => {}}
  },
  data() {
    return {
      files: [],
      forceReRenderKey: 0
    };
  },
  beforeMount() {
    this.loadFiles();
  },
  methods: {
    loadFiles() {
      this.$store.dispatch(`load${this.storeName}Files`, {id: this.targetId})
          .then(data => {
            if(this.fileTypes) {
              this.files = data.filter(item => {
                return this.fileTypes.includes(this.getExtension(item.key));
              })
            } else {
              this.files = data;
            }
            this.forceReRenderKey++;
            this.attributes = {};
          })
    },
    deleteFile(key = '') {
      if(this.toBeDeleted) {
        this.$store.dispatch(`clientDelete${this.storeName}Part`, {id: this.targetId, key: this.toBeDeleted}).then(() => {
          this.loadFiles();
          this.toBeDeleted = null;
        })
      }
      else {
        this.toBeDeleted = key;
        this.showDeletePrompt = true;
      }
    },
  }
}
</script>

<style scoped>

</style>