<template>
    <div class="label-with-icon">
        <div class="vform-label">
            {{ $t(label) }}<span v-if="mandatory">*</span>
            <div v-if="editable" class="edit-trigger ml-1 d-inline-block lighter" @click.prevent="$emit('edit')">
                <icon :type="'edit'"/>
            </div>
        </div>
        <div class="value" v-if="isPassword">
            <p>*****</p>
        </div>
        <div class="value" v-else-if="!value && makePlaceholderSpace"><p/></div>
        <div class="value" v-else-if="showValue">
            <p>{{ value ? value : '-' }}</p>
        </div>
    </div>
</template>

<script>
    import Icon from "./Icon";
    export default {
        name: "EditLabelWithContent",
        components: {
            Icon
        },
        props: {
            value: {default: ''},
            label: {type: String, required: true},
            isPassword: {type: Boolean, default: false},
            editable: {type: Boolean, default: true},
            makePlaceholderSpace: {type: Boolean, default: true},
            showValue: {type: Boolean, default: true},
            mandatory: {type: Boolean, deafult: false}
        },
    }
</script>

<style lang="scss">
  .label-with-icon {
    background-color: rgba(0,0,0,0.2);
    border-radius: 3px;
    font-size: 1rem;
    padding: 5px 12px;
    word-wrap: break-word;
    .vform-label {
      font-weight: bold;
      color: #91929d;
    }
  }
  .value {
    color: #fff;
  }
</style>