<template>
  <div :id="dropdownId"
       ref="dropdownMenu"
       :class="['form-switch dropdown-widget', closed ? 'closed' : 'open']">
    <icon class="lighter up-and-down" size="0.8" type="angle-down"/>

    <div class="pl-1 pr-4">
      <div class="selected d-flex" @click.stop="toggleOpenClosed()">
        <div class="active-value" v-if="activeValue">
          <!--<icon :class="['mr-1 check hidden']" size="0.8" type="check"/>-->
          <div v-if="activeValue">{{ getLabelForVal(activeValue) }}</div>
          <div v-else class="">-</div>
        </div>

        <!-- This is a placeholder to make the correct with for the collapsed item (because it must be as wide as the lengthest language string) -->
        <div class="placeholders opacity-0">
          <div class="items">
            <!--<icon :class="['mr-1 check', !closed ? 'visible' : '']" size="0.8" type="check"/>-->
            {{ longestLabel ? longestLabel : 'N/A' }}
          </div>
        </div>
        <!--  this is the actual options array -->
        <div :class="['options fancy-scrollbar', openDirection, closed ? 'closed' : 'open']">
          <div v-for="(item, key) in labels"
               v-if="!excludeValues || (!excludeValues.includes(values[key]) || activeValue === values[key])"
               :key="key + forceReRenderKey + 90000"
               :value="values[key]"
               @click.stop="setValue(values[key]); closed = true;"
               :class="[ activeValue === values[key] ? 'selected' : '']"
          >
            <!--<icon :class="['mr-1 check', activeValue === values[key] ? 'visible' : 'hidden']" size="0.8" type="check"/>-->
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import listenerMixinJs from "../mixins/listenerMixin.js";

export default {
  name: "DropDown",
  components: {Icon},
  mixins: [listenerMixinJs],
  props: {
    labels: {type: Array, required: true},
    values: {type: Array, required: true},
    excludeValues: {
      type: Array, default: () => {
        return []
      }
    },
    initialActiveValue: {type: String, default: null},
    /**
     * whether the opened options should expand to the top or to the bottom
     * **/
    openDirection: {type: String, default: "bottom"},
  },
  data() {
    return {
      activeValue: null,
      closed: true,
      dropdownId: null,
      forceReRenderKey: 8000,
      longestLabel: ""
    };
  },
  watch: {
    initialActiveValue() {
      this.activeValue = this.initialActiveValue;
    },
    excludeValues() {
      this.forceReRenderKey++;
    },
  },
  beforeMount() {
    this.activeValue = this.initialActiveValue ? this.initialActiveValue : null;
    this.dropdownId = Math.random().toString(36).substring(7);
    this.getLongestLabel();
  },
  created() {
    this.addListener(document, "click", this.documentClick, 'lang-selector')
  },
  methods: {
    getLongestLabel() {
      if(!this.labels) {
        return;
      }
      const labels = JSON.parse(JSON.stringify(this.labels));
      const sorted = labels.sort(function(a, b){
        // ASC  -> a.length - b.length
        // DESC -> b.length - a.length
        return b.length - a.length;
      });
      this.longestLabel = sorted[0];
    },
    getLabelForVal(val) {
      const index = this.values.findIndex(item => {
        return item === val;
      })
      return index !== -1 ? this.labels[index] : null;
    },
    setValue(val) {
      this.activeValue = val;
      this.$emit('select', this.activeValue)
    },
    documentClick(e) {
      let el = document.getElementById(this.dropdownId);
      let target = e.target;
      if ((el !== target)) {
        this.closed = true;
      }
      el = null;
    },
    toggleOpenClosed() {
      this.closed = !this.closed;
    }
  }
}
</script>

<style lang="scss" scoped>
.active-value {
  position:absolute;
  left: 5px;
  color: #fff;
}
.up-and-down {
  position: absolute;
  right: 5px;
  margin-top: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.opacity-0 {
  opacity: 0;
  z-index: 0;
}
.invisible, .hidden {
  visibility: hidden;
  opacity: 0;
}

.dropdown-widget {
  font-size: 0.9rem;
  position: relative;
  color: #fff;
  align-self: start;
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 2px;
  min-width: 50px;
  padding-top: 2px;
  padding-bottom: 2px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  &.no-shadow {
    box-shadow: none;
  }
  &.dark {
    background-color: var(--vform-editor-ui-quaternary-color);
  }
  &.open {
    z-index: 5000;
  }

  .check {
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .selected {
    cursor: pointer;
    color: #fff;
  }

  .options {
    position: absolute;
    background-color: var(--vform-editor-layout-secondary-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    // color: var(--vform-editor-layout-quaternary-color);
    color: #fff;
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    padding: 2px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;
    z-index: 5;
    left: 0;
    min-width: 100%;
    border-radius: 3px;

    .selected {
      background-color: var(--vform-editor-layout-tertiary-color);
    }

    &.closed {
      z-index: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      opacity: 0;
    }

    div {
      padding: 2px;

      &:hover {
        background-color: var(--vform-editor-ui-secondary-color);
      }
    }

    &.top {
      bottom: 0;
    }

    &.bottom {
      top: 0;
    }
  }
}
</style>