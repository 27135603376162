<template>
  <div>
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="element.currentLang === key"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="dflex"
    >
      <div class="col p-0">
        <label class="vform-editor-label section">Choice Type</label>
        <div class="col-12 dflex pr-0 pl-0">
          <select :id="choiceLocation" v-model="element.choiceLocation" class="custom-select form-text-dark answer">
            <option v-for="(item) in choiceLocations"
                    :key="item"
                    :value="item">
              {{ $t(`vform.listMatch${item}`) }}
            </option>
          </select>

        </div>

        <!--ANSWERS-->
        <label class="vform-editor-label section">Question</label>
        <label class="vform-editor-label section" style="float: right"
        >Answer</label
        >
        <div
            v-for="(item, index4) in element.options"
            :key="index4 + 98239283 + forceReRenderKey"
            class="dflex mb-1"
        >
          <div class="col pl-0 pr-0 dflex">
            <div class="col pl-0">
              <textarea
                  v-model="item.text.dix[key]"
                  :placeholder="
                      key === 'Unknown'
                          ? placeholders.SHORT
                          : $t('vform.translationMessage', {
                                key: key,
                                content: item.text.dix['Unknown'],
                            })
                  "
                  :title="item.text.dix['Unknown']"
                  class="form-text v-form-label form-text-dark mb-2"
                  rows="300"
                  type="text"
              />
              <div
                  class="icon-delete-option"
                  style="position: absolute; top: 0px; right: 16px"
                  @click="removeOption(index4)"
              >
                <icon :size="'1.8'" type="fat-square-minus"/>
              </div>
            </div>
            <div class="col-auto pl-0 pr-0">
              <div class="col-12 dflex pr-0 pl-0">
                <select v-model="item.correctAnswer" class="custom-select form-text-dark answer">
                  <option v-for="(item) in getAnswersDataSource(element.currentLang)"
                          :key="item"
                          :value="item">
                    {{ item }}
                  </option>
                </select>

              </div>
            </div>
          </div>
        </div>
        <div class="w-100 mt-1 text-right col pr-0" style="padding-right: 34px">
          <span @click="addOption(key)">
              <icon :size="'2.0'" type="fad-square-plus"/>
          </span>
        </div>
      </div>
    </div>
    <label class="vform-editor-label section">Options</label>
    <!--ADD DIX TRANSLATION-->
    <div class="col row mt-2">
      <div class="col-12 pl-0 dflex">
        <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
<!--      <div class="col-12 pl-0 dflex">-->
<!--        <label :for="'chkRequired' + $vnode.key" class="container vform-editor-body"-->
<!--        >{{ $t("isRequired") }}-->
<!--          <input-->
<!--              :id="'chkRequired' + $vnode.key"-->
<!--              v-model="element.isRequired"-->
<!--              type="checkbox"-->
<!--          />-->
<!--          <span class="checkmark"></span>-->
<!--        </label>-->
<!--      </div>-->
      <div class="col-12 pl-0 dflex">
        <label :for="'chkCorrect' + $vnode.key" class="container vform-editor-body"
        >{{ $t("validateCorrectness") }}
          <input
              :id="'chkCorrect' + $vnode.key"
              v-model="element.validateCorrectness"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <!--DIX-->
    <!--FOR EACH TRANSLATION ONE FIELD-->
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import LearningDotsMixin from "@/components/vForm/viewer/LearningDotsMixin.js";

export default {
  name: "vFormListMatch",
  components: {
    Icon,
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
  },
  mixins: [vFormElementMixin, LearningDotsMixin],
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
      choiceLocations: ["panel", "image"]
    };
  },
  watch: {
    element: {
      deep: true,
      handler() {
      },
    },
  },
  beforeMount() {
    this.element.formElementType = vFormControls["LIST_MATCH"];
    if (!this.element.choiceLocation) {
      this.$set(this.element, "choiceLocation", "panel");
    }

    ///get all learning dots and generate corresponding options
    if (!this.element.options || this.element.options.length === 0){
      const answers = this.getAnswersDataSource(this.element.currentLang);
      if (answers) {
        answers.forEach(() => {
          this.addOption("Unknown");
        });
      }
    }

    if (!this.element.options) {
      this.addOption("Unknown");
    }
  },
  methods: {
    removeOption(index) {
      this.element.options.splice(index, 1);
    },
    addOption(lang) {
      if (!this.element.options) {
        this.$set(this.element, "options", []);
      }
      this.element.options.push({
        text: {
          dix: {
            [lang]: "",
          },
          imageDix: {
            [lang]: "",
          },
        },
        correctAnswer: '',
        selectedAnswer: '',
      });
      this.forceReRenderKey++;
    },
  }
}
</script>
<style lang="scss" scoped>
.answer {
  width: 100px;
}
</style>
