<template>
    <div>
        <div :key="index" class="form-group" v-for="(field, index) in values">
            <div class="input-prepend-icon interactive" @click="removeItem(index)"><icon type="times" /></div>

            <input
                    class="form-text"
                    id="from"
                    ref="from"
                    @keydown.enter="save"
                    type="text"
                    v-model="values[index]">
        </div>
        <Button
                class="mt-3"
                @click="addItem"
        >
            <icon type="plus" />
            {{ $t('ADD ITEM') }}
        </Button>
        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Icon from "../../Icon";
    import Button from "../../forms/Button";
    export default {
        name: "ListWidget",
        components: {
            Icon,
            Button
        },
        props: {
            value: {type: Array || String, default: ''},
        },
        data() {
            return {
                values: ['', ''],
            };
        },
        beforeMount() {
            let arr = this.value;
            if (!Array.isArray(this.value)) {
                arr = JSON.parse(this.value);
            }
            else {
                this.values = this.value;
                return;
            }

            if(Array.isArray(arr)) {
                this.values = arr;
            }
        },
        methods: {
            addItem: function() {
                this.values.push('');
            },
            removeItem: function(index) {
                this.values.splice(index, 1);
            },
            save: function () {
                this.$emit('save', this.values);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .remove-item {
        border-radius:50%;
        width:20px;
        height:20px;
        font-size:0.9em;
        position:relative;
        margin-right:15px;
        margin-top:2px;
        cursor:pointer;
        .icon {
           position:absolute;
            top:50%;
            left:50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
        &:hover {
            color: $highlight;
        }
    }
</style>