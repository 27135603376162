<template>
    <one-column-sidebar-left
        tab-selector="reporttab"
        back-button-tab-selector="reportbackbutton"
        :id="$route.params.id"
        type="report"
    >
<info-panel
                slot="sidebarLeft"
                class="main-content-info-panel"
                :created="$store.getters.getReportCreationDate(this.$route.params.id)"
                :last-change="$store.getters.getReportChangeDate(this.$route.params.id)"
                :organization="$store.getters.getReportOrganization($route.params.id)"
                preview-id="datasetEdit"
                :limit-tags-to-organization="$store.getters.getReportOrganization($route.params.id)"
        />

        <template slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getReportName($route.params.id)"
                    :value="$store.getters.getReportName($route.params.id)"
                    :id="$route.params.id"
                    store-name="Report"
                    :organization-id="$store.getters.getReportOrganization($route.params.id)"
            />
            <content-description-block
                    :value="$store.getters.getReportDescription($route.params.id)"
                    :id="$route.params.id"
                    store-name="Report"
            />
            <meta-panel :key="item.id" v-for="(item) in $store.getters.getReportMetaSets($route.params.id)"
                        :title="item.description" :fields="item.metaFields" icon-left="folder"
                        store-name="Report"
                        :organization-ids="[$store.getters.getReportOrganization($route.params.id)]"
                        @updated="updateMultiChart"
            />
            <info-panel
                    :info-panel-header="'configuration'"
                    :collapsible="true"
                    preview-id="'contentInfoPanel'"
                    :initially-collapsed="true"
            >
                <codemirror
                        slot="info"
                        v-if="$store.getters.isSuperAdmin"
                        :value="$store.getters.getReportContent($route.params.id)"
                        :show-save-button="true"
                        @save="updateContent"
                />
            </info-panel>

            <multi-chart
                    class="mt-4"
                    v-if="$store.getters.getReportMetaSets($route.params.id) && $store.getters.getReportMetaSets($route.params.id)[0]"
                    :config="$store.getters.getReportContent($route.params.id)"
                    :datasets="$store.getters.getReportMetaSets($route.params.id)[0].metaFields.filter(item => {return item.key === 'dataSet'})[0].metaValues"
                    @saveConfig="saveConfig"
                    @removeConfig="removeConfig"
                    :key="forceReRenderKey"
            />

            <Button
                    type="button"
                    class="mb-3"
                    @click="addChartConfigRow"
            >
                <icon type="save"/> {{ $t('add statistic') }}
            </Button>
           <!-- <dataset
                    store-name="Report"
                    :deletable="true"
                    :id="$route.params.id"
                    :has-pagination="true"
                    :filterable="true"
            />-->
        </template>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import Button from "../components/forms/Button";
    import Icon from "../components/Icon";
    import MultiChart from "@/components/widgets/dataset/MultiChart";
    import Codemirror from "@/components/widgets/editors/Codemirror";
    export default {
        name: 'ReportEdit',
        components: {
            MultiChart,
            InfoPanel,
            OneColumnSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            Codemirror,
            Button,
            MetaPanel,
            Icon,
        },
        data() {
            return {
                id: '',
                config: '',
                forceReRenderKey: 0
            };
        },
        beforeMount() {
            this.loadReport();
            /*this.$store.dispatch('loadDataset', {
                id: this.$route.params.id,
                listName: this.$route.params.id,
            });
            this.$store.dispatch('loadDatasetRows', {
                listName: this.$route.params.id + 'chart',
                keep: {
                    id: this.$route.params.id,
                },
                add: {
                    limit: {
                        default: 1500,
                    }
                }
            });*/
        },
        methods: {
            updateMultiChart: function() {
                this.loadReport();
                this.forceReRenderKey++;
            },
            loadReport: function() {
                this.$store.dispatch('loadReport', {
                    id: this.$route.params.id,
                    listName: this.$route.params.id,
                    include: 'tags,metaSets'
                }).catch(e => {
                  if(e.status === 401) {
                    this.$router.push('/access-denied');
                  } else if(e.status === 404) {
                    this.$router.push('/not-found')
                  }
                });
            },
            updateContent: function(content) {
                this.$store.dispatch('updateReport', {
                    id: this.$route.params.id,
                    content: content,
                });
            },
            getConfig: function() {
                return JSON.parse(this.$store.getters.getReportContent(this.$route.params.id));
            },
            uploadConfig: function(config) {
                this.updateContent(JSON.stringify(config));
            },
            addChartConfigRow: function(){
                let config = this.getConfig();
                let nextIndex = Object.keys(config).length;
                config['chartConfig' + nextIndex] = config.chartConfig0;
                this.uploadConfig(config);
            },
            saveConfig: function(localConfig, chartId) {
                let config = this.getConfig();
                config[chartId] = localConfig;
                this.uploadConfig(config);
            },
            removeConfig: function(chartId) {
                let config = this.getConfig();
                delete config[chartId];
                this.uploadConfig(config);
            }
        },
    }
</script>
