<template>
    <main-table-layout
      sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                        id="datasourceList"
                        @filter="filtering"
                        store-name="DataSource"
                        :type-filter-items="['sensor', 'feed']"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"/>
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getDatasourceListOptions(listName, 'pagination_items')"
                id="datasourcetablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
      <div slot="mainContentButtons">
        <Button
            permission="create"
            type="action-button"

            link="/library/datasource/add"
            icon="plus">
          {{ $t('createNewDataSource') }}
        </Button>
      </div>


      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="fields"
                :data="$store.getters.getDatasourcesByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                delete-permission="delete"
                :use-team-permission="true"
                :loading="loading"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                id="appsDefaultTable"
                :fields="thumbnailFields"
                 :data="$store.getters.getDatasourcesByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :deletable="true"
                @deleteRow="deleteRow"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :use-team-permission="true"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
    export default {
        name: 'DataSources',
      mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {

                listName: 'DatasourceList',
              registerMethod: 'registerDatasourceQueryParams',
              loadMethod: 'loadDatasources',
              include: 'tags,teams',
              deleteMethod: 'deleteDatasource',
                fields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                  {
                    name: 'Teams',
                    id: 'teams',
                    type: 'teams',
                    sortable: true
                  },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
    }
</script>
