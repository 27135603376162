<template>
  <div>
    <div @click="addNode" class="settings-button d-inline-block">Add node</div>
    <div class="mt-2">
      <canvas id='mycanvas' width='1024' height='720'></canvas>
    </div>
  </div>
</template>

<script>
import {LGraph, LGraphCanvas, LiteGraph} from "litegraph.js";
import {AssetTypes} from "../../enum";
export default {
  name: "ProjectNodeEditor",
  props: {
    project: {type: Object, required: true},
    connected: {type: Array, default: () => {}}
  },
  data() {
    return {
      graph: null,
      projectNode: null,
      instanceNodes: [],
      instanceCats: {}
    }
  },
  watch: {
    connected() {
      this.addInstances()
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if(this.graph) {
        return;
      }
      this.graph = new LGraph();
      LiteGraph.registerNodeType("basic/text", CustomMultNode);

      new LGraphCanvas("#mycanvas", this.graph);

      this.projectNode = LiteGraph.createNode("basic/text");
      this.projectNode.pos = [10,200];
      this.projectNode.setTitle('Project');
      this.graph.add(this.projectNode);

      //node_const.setValue(4.5);

      /*const node_watch = LiteGraph.createNode("basic/watch");
      node_watch.pos = [700,200];
      this.graph.add(node_watch);

      node_const.connect(0, node_watch, 0 );*/
      this.addInstances();
      this.graph.start()
    },
    addAssetCats() {
      const types = Object.values(AssetTypes);
      for(let i = 0; i < types.length; i++) {
        let type = types[i];
        this.projectNode.addItemOutput(type);
        const typeNode = LiteGraph.createNode("basic/text");
        typeNode.setTitle(type);
        typeNode.pos = [550,10 + i * 90];
        typeNode.addItemInput('project')
        this.graph.add(typeNode);
        this.connectNodes(this.projectNode, typeNode, i, 0);
        this.instanceCats[type] = {
          node: typeNode,
          index: 0
        };
      }
    },
    addAssetCat(type, i) {
      this.projectNode.addItemOutput(type);
      const typeNode = LiteGraph.createNode("basic/text");
      typeNode.setTitle(type);
      typeNode.pos = [250,i];
      typeNode.addItemInput('project')
      this.graph.add(typeNode);
      this.connectNodes(this.projectNode, typeNode, Object.keys(this.instanceCats).length, 0);
      this.instanceCats[type] = {
        node: typeNode,
        index: 0
      };
    },
    addInstances() {
      if(this.instanceNodes.length || !this.connected.length) {
        return;
      }
      this.connected.sort((b,a) => {
        if (a.type < b.type){
          return -1;
        }
        if (a.type > b.type){
          return 1;
        }
        return 0;
      })
      let previousCat = null;
      let lastPosKnown = 0;
      let previousItem = null;
      let lastItemPosKnown = 0;
      for(let i = 0; i < this.connected.length; i++) {
        const {name, type} = this.connected[i];

        if(!this.instanceCats[type]) {
          lastPosKnown = lastPosKnown + (previousCat ? (previousCat.size[1] + 95) : 0);
          this.addAssetCat(type,  lastPosKnown);
        }
        const {node, index} = this.instanceCats[type];
        this.instanceCats[type].index = index + 1;
        const instanceNode = LiteGraph.createNode("basic/text");
        instanceNode.setTitle(name);
        lastItemPosKnown = lastItemPosKnown + (previousItem ? (previousItem.size[1] + 35) : 0);
        instanceNode.pos = [750,lastItemPosKnown];
        instanceNode.addItemInput(type)
        this.graph.add(instanceNode);
        node.addItemOutput(name);
        this.connectNodes(node, instanceNode, index, 0);
        this.instanceNodes.push(instanceNode);
        previousCat = node;
        previousItem = instanceNode;
      }
    },
    createBasicNode(title) {
      const node_const = LiteGraph.createNode("CustomMultNode");
      node_const.pos = [200,200];
      this.graph.add(node_const);
      node_const.setValue(title);
    },
    connectNodes(source, target, sourceIndex, targetIndex) {
      source.connect(sourceIndex, target, targetIndex);
    },
    addNode() {
      const node_watch = LiteGraph.createNode("basic/watch");
      node_watch.pos = [200,600];
      this.graph.add(node_watch);
    }
  }
}
// Custom multiplication class
// https://github.com/jagenjo/litegraph.js?files=1#how-to-code-a-new-node-type
// eslint-disable-next-line no-unused-vars
class CustomMultNode {
  constructor(title){
    this.title = title;
    this.properties = { precision: 0.1 };
  }
  setTitle(title) {
    this.title = title;
  }
  addItemOutput(instanceName) {
    this.addOutput(instanceName,"text");
  }
  addItemInput(projectName) {
    this.addInput(projectName, "text")
  }

  onExecute(){
    let a = this.getInputData(0) || 0;
    let b = this.getInputData(1) || 0;

    this.setOutputData(0,a*b);
  }
}
</script>