<template>
  <div class="w-100">
    <!--<div @click="addNode" class="settings-button d-inline-block">Add node</div>-->
    <div @click="init">Init</div>
    <div class="mt-2">
      <canvas id='mycanvas' width='1024' height='720'></canvas>
    </div>
  </div>
</template>

<script>
import {LGraph, LGraphCanvas, LiteGraph} from "litegraph.js";
import FormTreeTraverserMixinJs from "@/components/nodeEditors/FormTreeTraverserMixin.js.vue";
export default {
  name: "FormNodeEditor",
  mixins: [FormTreeTraverserMixinJs],
  props: {
    vform: {type: Object, required: true},
    slides: {type: Array, required: true},
  },
  data() {
    return {
      graph: null,
      projectNode: null,
      slideNodes: {},
      stepNodes: {},
      connectorNodes: {},
      slideConnections: []
    }
  },
  mounted() {
    this.init();
    // todo: destroy graph on unmount
  },
  methods: {
    init() {
      this.slideNodes = {};
      this.stepNodes = {};
      this.connectorNodes = {};
      console.log('initializing graph...')
      /*if(this.graph) {
        return;
      }*/
      this.graph = new LGraph();
      LiteGraph.registerNodeType("basic/text", CustomMultNode);

      new LGraphCanvas("#mycanvas", this.graph);

      this.projectNode = LiteGraph.createNode("basic/text");
      this.projectNode.pos = [10,200];
      this.projectNode.setTitle('project');
      this.graph.add(this.projectNode);

      //node_const.setValue(4.5);

      /*const node_watch = LiteGraph.createNode("basic/watch");
      node_watch.pos = [700,200];
      this.graph.add(node_watch);

      node_const.connect(0, node_watch, 0 );*/
      this.addSlides();
      this.addSteps();
      // adding slide connections can be done but looks awfully messy
      this.traverseTree(this.vform, this.slides, this.addConnection, this.addSlideConnections);
      this.graph.start();
    },
    addSlideConnections(arr, showInNodes = false) {
      console.log('addSlideConnections')
      this.slideConnections = arr;
      if(!showInNodes) {
        return;
      }
      for(let i = 0; i < arr.length; i++) {
        const {source, target} = arr[i];
        console.log('adding connnection from ' + source + ' to ' + target)
        const sourceItem = this.slideNodes[source];
        sourceItem.node.addItemOutput('slideConnection')

        const targetItem = this.slideNodes[target];
        targetItem.node.addItemInput('slideConnection');

        this.connectNodes(sourceItem.node, targetItem.node, this.slideNodes[source].outputConnections, this.slideNodes[target].inputConnections);
        this.slideNodes[source].outputConnections += 1;
        this.slideNodes[target].inputConnections += 1;
      }
    },
    addSlides() {
      this.slideNodes = {};
      console.log('slides available')
      for(let i = 0; i < this.slides.length; i++) {
        let {id} = this.slides[i];
        this.projectNode.addItemOutput(id);
        const typeNode = LiteGraph.createNode("basic/text");
        typeNode.setTitle(id + ' - slide ' + (i + 1));
        typeNode.pos = [550,10 + i * 200];
        typeNode.addItemInput('vform')
        this.graph.add(typeNode);
        this.connectNodes(this.projectNode, typeNode, i, 0);
        this.slideNodes[id] = {
          node: typeNode,
          inputConnections: 1,
          outputConnections: 0,
          pos: typeNode.pos
        };
      }
    },
    addSteps() {
      this.stepNodes = {};
      const {steps} = this.vform;
      for(let i = 0; i < steps.length; i++) {
        let {uuid, name} = steps[i];
        const typeNode = LiteGraph.createNode("basic/text");
        typeNode.setTitle(name + ' - step ' + (i + 1));
        typeNode.pos = [1350,10 + i * 150];
        this.graph.add(typeNode);
        this.stepNodes[uuid] = {node: typeNode, inputConnections: 0, outputConnections: 0, pos: typeNode.pos};
      }
    },
    checkIfSlideConnectionExists(source, target, slidesArray) {
      return slidesArray.findIndex(item => {
        return item.sourceId === source && item.targetId === target;
      }) !== -1;
    },
    addConnection(sourceId, targetId, connectorName, connectorId, stepId) {
      // technically it would be correct to display that a button points to the slide someone is already at, but it is messy
      if(sourceId === targetId) {
        return;
      }
      const source = this.slideNodes[sourceId];
      const targetPos = source.pos;

      const existingConnector = this.connectorNodes[connectorId];
      let connectorNode = existingConnector ? existingConnector.node : null;
      if(!existingConnector) {
        connectorNode = LiteGraph.createNode("basic/text");
        connectorNode.setTitle(connectorName === 'Button' ? 'next' : connectorName);
        connectorNode.addItemOutput('step');
        this.graph.add(connectorNode);
        this.connectorNodes[connectorId] = {
          node: connectorNode,
          outputConnections: 1,
          inputConnections: 0,
          connectedSlides: [],
          connectedSteps: [],
          pairs: 1
        };
      } else if(this.checkIfSlideConnectionExists(sourceId, targetId, existingConnector.connectedSlides)) {
        return;
      }
      connectorNode.pos = [targetPos[0] + 450, targetPos[1]];
      connectorNode.addItemInput('from ' + this.connectorNodes[connectorId].pairs);
      connectorNode.addItemInput('to ' + this.connectorNodes[connectorId].pairs);

      source.node.addItemOutput('startslide');
      this.connectNodes(source.node, connectorNode, source.outputConnections, this.connectorNodes[connectorId].inputConnections);
      this.slideNodes[sourceId].outputConnections += 1;
      console.log(sourceId + ' has outputs ' + this.slideNodes[sourceId].outputConnections)

      // only connect button with targetSlide of
      if(targetId) {
        const target = this.slideNodes[targetId];
        target.node.addItemOutput('endslide');
        this.connectNodes(target.node, connectorNode, target.outputConnections, this.connectorNodes[connectorId].inputConnections + 1);
        this.slideNodes[targetId].outputConnections += 1;
        console.log(targetId + ' has outputs ' + this.slideNodes[targetId].outputConnections)
      }

      // connect the button to the step if the button node is new
      if(!existingConnector || !this.connectorNodes[connectorId].connectedSteps.includes(stepId)) {
        const targetStepNode = this.stepNodes[stepId];
        targetStepNode.node.addItemInput('block');
        this.connectNodes(connectorNode, targetStepNode.node, 0, targetStepNode.inputConnections);
      }

      this.stepNodes[stepId].inputConnections += 1;
      this.connectorNodes[connectorId].inputConnections += 2;
      this.connectorNodes[connectorId].connectedSlides.push({sourceId, targetId})
      this.connectorNodes[connectorId].connectedSteps.push(stepId);
      this.connectorNodes[connectorId].pairs += 1;
    },
    connectNodes(source, target, sourceIndex, targetIndex) {
      source.connect(sourceIndex, target, targetIndex);
    },
    addNode() {
      const node_watch = LiteGraph.createNode("basic/watch");
      node_watch.pos = [200,600];
      this.graph.add(node_watch);
    }
  }
}
// Custom multiplication class
// https://github.com/jagenjo/litegraph.js?files=1#how-to-code-a-new-node-type
// eslint-disable-next-line no-unused-vars
class CustomMultNode {
  constructor(title){
    this.title = title;
    this.properties = { precision: 0.1 };
  }
  setTitle(title) {
    this.title = title;
  }
  addItemOutput(instanceName) {
    this.addOutput(instanceName,"text");
  }
  addItemInput(projectName) {
    this.addInput(projectName, "text")
  }

  onExecute(){
    let a = this.getInputData(0) || 0;
    let b = this.getInputData(1) || 0;

    this.setOutputData(0,a*b);
  }
}
</script>