<template>
    <div v-if="!checkingPermission">
        <popup
                @close="() => {showDeletePrompt = false; unlinkAbort();}"
                v-if="showDeletePrompt || showUnlinkPrompt"
        >
            <delete-prompt
                    v-if="showDeletePrompt"
                    slot="popupMainContent"
                    @abort="() => {showDeletePrompt = false;}"
                    @confirm="() => {showDeletePrompt = false; deleteRowFinally();}"
            />
            <delete-prompt
                    v-if="showUnlinkPrompt"
                    slot="popupMainContent"
                    custom-message="unlinkPromptQuestion"
                    @abort="unlinkAbort"
                    @confirm="() => {showUnlinkPrompt = false; clickCheckBoxFinally();}"
            />
        </popup>
        <div :class="['content', !hasEditPermission ? 'no-access' : '']">
            <template v-for="(field, index) in fields">
                <div :class="[navByClickingRow && hasEditPermission ? 'clickable' : '']"
                     @click="() => {if(navByClickingRow && hasEditPermission) {$router.push(item.editLink)}}"
                     v-if="field.type === 'image' || field.type === 'icon'"
                     :key="index + 'div'">
                    <preview
                            v-if="field.type === 'image'"
                            class="thumbnail mb-3"
                            :asset-or-project-id="item.id"
                            :source="resource"
                            :preview-uri="item.previewUri ? item.previewUri : item.profilePictureUri"
                            :type="'image'"
                            :preview-id="item.id"
                            :limited-preview="true"
                            :removable="false"
                            :key="index">
                        <div class="selectLinking" v-if="selectableCheckbox">
                            <div :class="[item.selected ? 'selected' : '']"
                                 @click.stop="(e) => clickCheckBox(e, item.id, item.selected)">
                                <icon v-if="!item.selected" type="unlink"/>
                                <icon v-else type="link"/>
                            </div>
                        </div>
                    </preview>
                    <div class="text-center pt-5 pb-5 mb-3 icon-field" v-else-if="field.type === 'icon'">
                        <icon v-if="field.virtual" :size="'large'" :type="item.type"/>
                        <icon v-else-if="!field.virtual" :size="'large'" :type="item.type"/>
                    </div>
                </div>
                <div :key="index" class="pl-3" v-else-if="field.id === 'teams'">
                  <div class="tag" :key="index2" v-for="(team, index2) in item.teams"><icon class="mr-1" type="users" />{{ team.displayName ? team.displayName : team.name }}</div>
                </div>
                <div v-else-if="field.type === 'qrcode' || field.id === 'qrcode'" :key="field.type">
                    <qrcode-view
                            class="table-thumbnail"
                            :source="item.type"
                            :id="item.id"
                            :key="index"
                    />
                </div>
                <div :key="index" class="p-3 toggle-button" v-else-if="field.type === 'state'">
                    <toggle-button v-if="hasEditPermission" :value="item[field.id] === 'a'"
                                   color="#DA5E06"
                                   :sync="true"
                                   :labels="true"
                                   @change="$emit('updateContent', {
                                            id: item.id,
                                            state: $event.value?'a':'i'
                                    })" />
                </div>
                <div :key="index" v-else-if="field.type === 'editButton'" class="button-field">
                    <Button
                            v-if="hasEditPermission"
                            class="ml-3 mt-3"
                            :link="item.editLink" icon="pencil-alt">
                        {{ $t('edit') }}
                    </Button>
                </div>

                <div :key="index" v-else-if="field.type === 'editPopup'" class="button-field">
                    <Button
                            @click="$emit('edit', item.id)"
                            icon="pencil-alt">
                        {{ $t('edit') }}
                    </Button>
                </div>

                <div class="ml-3" :key="index" v-else-if="field.id === 'tags'">
                    <div v-if="item.tags">
                        <tag v-for="value in item.tags" :key="value.id" :tag="value" :is-removable="false" :clickable="false"/>
                    </div>
                </div>

                <div :key="index" class="ml-3 mr-3 mt-2"
                     v-else-if="field.id === 'name' && item.editLink && titleAsLinks && hasEditPermission">
                    <h4>
                        <router-link
                                class="title-link"
                                :to="item.editLink">
                            <div class="overflow-text">
                                <div :class="[item[field.id].length > 60 ? 'shorttext' : '']">{{ item[field.id].slice(0, 60) }}<span v-if="item[field.id].length > 60">...</span></div>
                                <div class="longtext" v-if="item[field.id].length > 30">{{ item[field.id] }}</div>
</div>
                        </router-link>
                    </h4>
                </div>

                <div :key="index" class="ml-3 mt-2 date" v-else-if="field.type === 'date'">
                    {{ $store.getters.convertDate(item[field.id]) }}
                </div>
                <div :key="index" class="ml-3 mr-3 mb-1 lighter" v-else-if="field.id === 'organization'">
                    <icon type="folder"
                          v-if="hasEditPermission"/>
                    <icon v-else type="lock"/>
                    {{ $store.getters.getOrganizationDisplayName(item.organizationId) }}
                </div>
                <div :key="index" class="ml-3 mr-3 mb-3 type" v-else-if="field.id === 'type'">
                    {{ item[field.id] }}
                </div>
                <div :key="index" class="ml-3 mr-3 mt-2" v-else>
                    <h4>
                        {{ item[field.id] }}
                        </h4>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Button from "../forms/Button";
    import Icon from "../Icon";
    import Preview from "../preview/Preview";
    import QrcodeView from "./QrcodeView";
    import Tag from "../Tag";
    import Popup from "../Popup";
    import DeletePrompt from "../forms/DeletePrompt";

    export default {
        name: 'Table',
        components: {
            Button,
            Icon,
            Preview,
            QrcodeView,
            Tag,
            Popup,
            DeletePrompt
        },
        props: {
            resource: {type: String, default: 'asset'},
            navByClickingRow: {type: Boolean, default: false,},
            fields: {type: Array, required: true,},
            item: {type: Object, default: null},
            selectableCheckbox: {type: Boolean,},
            selectableRows: {type: Boolean,},
            titleAsLinks: {type: Boolean,},
            editPermission: {type: String, default: null},
            deletePermission: {type: String, default: null},
           /**
             * @permissionByType If there are multiple permissions per type, use this one
             */
            permissionByType: {type: Object, default: () => {}},
        },
        data() {
            return {
                hasEditPermission: false,
                hasDeletePermission: false,
                clickedCheckBoxId: '',
                clickedCheckBoxAction: '',
                showUnlinkPrompt: false,
                showDeletePrompt: false,
                checkingPermission: true,
            }
        },
        watch: {
          item: {
            deep: true,
            handler: function() {
              this.checkPermission();
            }
          }
        },
        mounted() {
            this.checkPermission();
        },
        methods: {
          async checkPermission() {
              this.checkingPermission = true;
              let editDispatchMethod = 'checkTeamOrgPermission';
              let deleteDispatchMethod = 'checkTeamOrgPermission';
              if(this.editPermission === 'userIsOrganizationMember') {
                deleteDispatchMethod = editDispatchMethod = 'userIsOrganizationMember';
              }
              this.hasEditPermission = await this.$store.dispatch(editDispatchMethod, {
                  teams: this.item.teams,
                  op: this.permissionByType && this.permissionByType[this.item.type] ? this.permissionByType[this.item.type] : this.editPermission,
                  organizationId: this.item.organizationId
                })

                this.hasDeletePermission = await this.$store.dispatch(deleteDispatchMethod, {
                  teams: this.item.teams,
                  op: this.deletePermission,
                  organizationId: this.item.organizationId
                })

              this.checkingPermission = false;
            },
            clickCheckBox: function(e, id, selected) {
                if(!this.processing) {
                    this.processing = true;
                    e.stopPropagation();
                    this.clickedCheckBoxId = id;
                    this.clickedCheckBoxAction = selected ? 'remove' : 'add';
                    if(selected) {
                        this.showUnlinkPrompt = true;
                    }
                    else {
                        this.clickCheckBoxFinally();
                    }
                }
            },
            clickCheckBoxFinally: function() {
                this.$emit('selectCheckbox', this.clickedCheckBoxId, this.clickedCheckBoxAction, this.item);
                setTimeout(function(){
                    this.processing = false;
                }, 3000);
                this.clickedCheckBoxAction = '';
                this.clickedCheckBoxId = '';
            },
            unlinkAbort: function() {
                this.showUnlinkPrompt = false;
                this.processing = false;
                this.clickedCheckBoxAction = '';
                this.clickedCheckBoxId = '';
            },
        },
    }
</script>
