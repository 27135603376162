<template>
  <div class="meta-field-filter">
    <div v-if="localMetaSets.length">
      <div v-for="(item, index) in filter" :key="index + forceReRenderKey" class="mb-2 filter-row pb-1 pt-1">
        <!--SELECT MetaSet-->
        <select :key="forceReRenderKey" :value="filter[index].metaSet"
                class="custom-select form-text-dark mod-selector mr-2" @change="(evt) => {setMetaSet(index, evt.target.value)}">
          <option v-for="(field, index2) in localMetaSets" :key="index2" :value="field.name">{{ field.name }}</option>
        </select>
        <!--SELECT MetaField-->
        <div class="d-inline-block mr-2">
          <select :key="reRenderMetaFields + forceReRenderKey" :value="filter[index].metaField"
                  class="custom-select form-text-dark mod-selector mr-2" @change="(evt) => {setMetaField(index, evt.target.value)}">
            <option v-for="(field, index2) in getMetaFields(item.metaSet)" :key="index2" :value="field.key">
              {{ field.description ? field.description : field.key }}
            </option>
          </select>
        </div>
        <!--SELECT modifier-->
        <select :value="item.modifier"
                class="custom-select form-text-dark mod-selector mr-2" @change="(evt) => {setModifier(index, evt.target.value); }">
          <option v-for="(key, index2) in Object.keys(filterModifiers)" :key="index2" :value="filterModifiers[key]">
            {{ $t(key) }}
          </option>
        </select>
        <div class="d-inline-block">
          <input @input="(evt) => {setString(index, evt.target.value)}" :value="filter[index].val" class="form-text form-text-dark " type="text"/>
          <!--<lightweight-meta-field
              :field="getMetaField(filter[index].metaField)"
              :value="JSON.stringify(filter[index].val)"
              label=""
              :edit-mode-only="true"
              :hide-save-button="true"
              :do-emit-only="true"
              @update="(newval) => {setValue(index, newval)}"
          />-->
        </div>
        <div class="d-inline-block ml-5 lighter clickable" @click="removeItem(index)">
          <icon size="1.4" class="float-left" type="fat-square-minus"/>
        </div>
      </div>
      <div class="d-inline-block clickable p-1" @click="addRow">
        <icon type="square-plus"/>
      </div>
    </div>
  </div>
</template>

<script>
import {filterModifiers} from "../../enum";
import Icon from "../Icon";
//import LightweightMetaField from "../widgets/meta/LightweightMetaField";
import {mapState} from "vuex";
export default {
  name: "MetaFieldFilter2",
  components: {
    Icon,
    //LightweightMetaField
  },
  props: {
    organizationId: {type: String, default: ''},
    metaSets: {type: Array, required: true},
    type: {type: String, default: 'filter'}, // filter or merge
    filterId: {type: String, default: ''}
  },
  data() {
    return {
      forceReRenderKey: 0,
      reRenderMetaFields: 0,
      metaFields: [],
      filter: [],
      filterString: '',
      metaSetKey: '',
      timeout: null,
      timeout2: null,
      error: '',
      filterModifiers: filterModifiers,
      // this property prevents an endless loop, sending up the newest filter,
      // receiving the newest filter again from parent
      doNotUpdate: false,
      localMetaSets: [],
      filterChangeTimeout: null
    };
  },
  computed: mapState({exportProfiles: state => state.exportprofile.exportProfiles,}),
  watch: {
    exportProfiles(val) {
      if(val[this.filterId]) {
        this.filter = this.type ? this.$store.getters.getExportFilterById(this.filterId) : this.$store.getters.getMergeById(this.filterId);
        this.filter = val[this.filterId][this.type];
        if(this.filter) {
          this.normalizeFilter();
        }
      }
    },
    metaSets(val) {
      this.localMetaSets = val;
    },
    type() {
      this.setData();
      this.setUp();
    }
  },
  beforeMount() {
    this.setData();
    this.setUp();
  },
  methods: {
    setString(index, value) {
      if(this.filterChangeTimeout) {
        clearTimeout(this.filterChangeTimeout);
      }
      const $this = this;
      this.filterChangeTimeout = setTimeout(() => {
        $this.filter[index].val = value;
        $this.emitFilterChange();
      }, 600);
    },
    setData() {
      if(this.exportProfiles[this.filterId]) {
        this.filter = this.exportProfiles[this.filterId][this.type];
        if(this.filter) {
          this.normalizeFilter();
        }
      }
    },
    emitFilterChange() {
      this.$store.dispatch(this.type === 'filter' ? 'overwriteFilter' : 'overwriteMerge', {
        id: this.filterId,
        value: this.filter
      });
    },
    async setUp() {
      if (this.metaSets) {
        this.localMetaSets = this.metaSets;
      } else {
        await this.loadMetaSets();
      }
      this.normalizeFilter();
    },
    normalizeFilter() {
      if(!this.filter) {
        return;
      }
      if(!Array.isArray(this.filter)) {
        this.filter = this.convertToArray(this.filter);
        this.forceReRenderKey++;
        this.emitFilterChange();
      }
    },
    setMetaField(index, value) {
      this.$set(this.filter[index], "metaField", value);
      this.reRenderMetaFields++;
      this.emitFilterChange();
    },
    setValue(index, value) {
      if(this.filter[index].val != value) {
        this.$set(this.filter[index], "val", value);
        this.emitFilterChange();
      }
    },
    setMetaSet(index, value) {
      this.$set(this.filter[index], "metaSet", value);
      this.$set(this.filter[index], "metaField", this.getMetaFields(value)[0] ? this.getMetaFields(value)[0].key : "");
      this.reRenderMetaFields++;
      this.emitFilterChange();
    },
    setModifier(index, value) {
      this.$set(this.filter[index], "modifier", value);
      this.emitFilterChange();
    },
    getMetaField: function (key) {
      let metaField = {};
      if (this.localMetaSets) {
        this.localMetaSets.map(item => {
          item.metaFields.map(field => {
            if (field.key === key) {
              metaField = field;
              return metaField;
            }
          })
        })
      }
      return metaField;
    },
    getMetaFields(key) {
      if (this.localMetaSets) {
        const sets = this.localMetaSets.filter(item => {
          return item.name === key;
        })
        if (sets && sets.length) {
          return sets[0].metaFields;
        }
        return [];
      }
      return [];
    },
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: 'organizationId in ' + this.$store.getters.getSuperOrg + ' ' + this.organizationId
      })
          .then(metaSets => {
            this.localMetaSets = metaSets;
            this.forceReRenderKey++;
          })
    },
    convertToArray(object) {
      if (!object) {
        return [];
      }
      return Object.entries(object).map(item => {
        return {
          metaSet: this.getMetaSetName(item[0]),
          metaField: this.getMetaFieldName(item[0]),
          modifier: this.getFilterMod(item[1]),
          val: this.getFilterVal(item[1]),
        }
      });
    },
    convertToObject() {
      let filterString = {};
      this.filter.map(item => {
        filterString[item.metaSet + '.' + item.metaField] = item.modifier + ' ' + item.val
      })
      return filterString;
    },
    removeItem(index) {
      this.filter.splice(index, 1);
      this.forceReRenderKey++;
    },
    addRow() {
      this.filter.push({metaSet: "sfx.asset.model", metaField: "volume", modifier: "lt", val: "0.1"});
      this.forceReRenderKey++;
    },
    removeAllSmallParts() {
      this.filter.push({metaSet: "sfx.asset.model", metaField: "dimensions", modifier: "lt", val: "[0.1,0.1,0.1]"});
      this.$emit('setMode', {value: 'exclude'})
      this.forceReRenderKey++;
    },
    removeTheHull() {
      this.filter.push({metaSet: "sfx.asset.model", metaField: "hullLayer", modifier: "gt", val: "1"});
      this.$emit('setMode', {value: 'exclude'})
      this.forceReRenderKey++;
    },
    getFilterVal(val) {
      if (!val || !val.length) {
        return "";
      }
      const arr = val.split(" ");
      arr.shift();
      return arr.join(" ");
    },
    getFilterMod(mod) {
      if (!mod || !mod.length) {
        return ""
      }
      return mod.split(" ").shift();
    },
    getMetaSetName(fullKey) {
      if (!fullKey) {
        return "";
      }
      const arr = fullKey.split('.');
      arr.pop();
      return arr.join('.');
    },
    getMetaFieldName(fullKey) {
      if (!fullKey) {
        return ""
      }
      return fullKey.split('.').pop();
    }
  }
}
</script>

<style lang="scss" scoped>
.mod-selector {
  width: 200px;
  display: inline-block;
}

.meta-field-filter {
  max-width: 800px;
}

.filter-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.form-text-dark {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
</style>