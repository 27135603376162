import Vue from 'vue';
import i18n from "@/translation";
    import {mediaCategories} from '@/enum';

//import {_} from "vue-underscore";
let actions = {};
let state = {
    assemblyTrees: {},
};
let getters = {};
let mutations = {};

const stores = [
    'Filter',
    'Service',
    'Team',
    'Material',
    'Role',
    'Permission',
    'Assembly',
    'Model',
    'Helper',
    'Sfxdata',
    'Media',
    'Datasource',
    'Qrcode',
    'Form',
    'Webapp',
    'VirtualMachine',
    'VMLog',
    'Dataset',
    'Materialmapper',
    'Texture',
    'License',
    'AssemblyItems',
    'Regex',
    'Template'
];
const objectTypes = {
    'Filter': 'Asset',
    'Service':'Service',
    'Team': 'Team',
    'Material': 'Asset',
    'Role': 'Role',
    'Permission': 'Permission',
    'Assembly': 'Asset',
    'Model': 'Asset',
    'Helper': 'Asset',
    'Sfxdata': 'Asset',
    'Media': 'Asset',
    'Datasource': 'Asset',
    'Qrcode': 'Asset',
    'Form': 'Asset',
    'Webapp': 'Asset',
    'VirtualMachine': 'VirtualMachine',
    'VMLog': 'VMLog',
    'Dataset': 'Dataset',
    'Materialmapper': 'Dataset',
    'Texture': 'Asset',
    'License': 'License',
    'AssemblyItems': 'Project',
    'Regex': 'Asset',
    'Template': 'Asset'
};
const types = {
    'Filter': ['filter'],
    'Sfxdata': [
        'helper', 'model', 'assembly'
    ],
    'Material': ['material'],
    'Assembly': ['assembly'],
    'Helper': ['helper'],
    'Media': ['media'],
    'Datasource': ['sensor', 'feed'],
    'Qrcode': ['qrcode'],
    'Form': ['form'],
    'Webapp': ['webapp'],
    'Texture': ['texture'],
    'Regex': ['regex'],
    'Template': ['template']
};
const editLinks = {
    'Service': '/admin/services/:id/general',
    'Filter': '/library/exportprofiles/:id/general',
    'Team': '/admin/teams/:id/general',
    'Material': '/library/materials/:id/general',
    'Role': '/admin/roles/:id/general',
    'Permission': '/admin/permissions/:id/general',
    'Assembly': '/library/3d-data/assembly/:id/general',
    'Model': '/library/3d-data/model/:id/general',
    'Helper': '/library/3d-data/helper/:id/general',
    'Sfxdata': '/library/3d-data/:type/:id/general',
    'Media': '/library/media/:id/general',
    'Datasource': '/library/datasource/:id/general',
    'Qrcode': '/library/qrcodes/:id/general',
    'Materialmapper': '/library/materialmappers/:id/general',
    'Form': '/library/form/:id/general',
    'Webapp': '/app/:id/general',
    'VirtualMachine': '/admin/vm/:id/general',
    'VMLog': '/admin/vm-usage/:id/general',
    'Dataset': '/library/dataset/:id/general',
    'Texture': '/library/texture/:id/general',
    'License': '/admin/licenses/:id/general',
    'AssemblyItems': '/',
    'Regex': '/',
    'Template': '/library/template/:id/general'
};

const combineIntoSingleObject = (object) => {
    return Object.values(object).reduce((acc, item) => {
        acc[Object.keys(item)[0]] = Object.values(item)[0];
        return acc;
    }, {});
};
const combineFilters = (object) => {
    let first = true;
    return Object.values(object).reduce((acc, item) => {
        acc += first ? '' : ', ';
        acc += item;
        first = false;
        return acc;
    }, '');
};
const combineOverwrite = (object) => {
    if(typeof object === 'string') {
        return object;
    }
    let values = Object.values(object);
    return values[values.length-1];
};


for(let i = 0; i < stores.length; i++) {
    const storeName = stores[i];

    //----------------------- STATE -------------------

    /**
     * It is recommendable to use the current route id as a listName
     * and use the default listName for the main table views only
     * so you won't have problems with one component interfering with the
     * list of another component
     * */
    const listContainer = `${storeName}Lists`;
    const defaultList = `${storeName}List`;
    const instanceListContainer = `${storeName}InstanceLists`;
    const instanceDefaultList = `${storeName}Instances`;
    const optionList = `${storeName}ListOptions`;
    state.test = 'test'; // don't remove this – if state is empty initially, getters will fail
    state[listContainer] = {
        [defaultList]: {},
    };
    state[instanceListContainer] = {
        [instanceDefaultList]: {},
    };
    state[`combined${storeName}QueryParams`] = {};
    state[`${storeName}QueryParams`] = {
        defaults: {}
    };

    state[optionList] = {};
    state[`global${storeName}s`] = {};

    if(types[storeName]) {
        let string = types[storeName].length === 1 ? 'type eq ' + types[storeName] : 'type in ' + types[storeName].join(' ');

        state[`${storeName}QueryParams`].defaults.filter = {
            default: string
        }
    }

    //----------------------- GETTERS -------------------

    getters[`get${storeName}s`] = (state) => {
        if(state[`global${storeName}s`]) {
            return state[`global${storeName}s`];
        }
        return state[listContainer][defaultList] ? state[listContainer][defaultList] : {};
    };

    /**
     * getFiltersByListName
     * Getter
     * @return Object of items in the specified list
     * */
    getters[`get${storeName}sByListName`] = (state) => (listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(!listName) {
            return state[`global${storeName}s`];
        }
        return state[listContainer][localListName] ? state[listContainer][localListName] : {};
    };
    
    /**
     * getOwnOrPublicFiltersByListName
     * Getter
     * @return Object of items in the specified list
     * */
    getters[`getOwnOrPublic${storeName}sByListName`] = (state) => (userId, listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(!listName) {
            return state[`global${storeName}s`];
        }
        let list = state[listContainer][localListName];
        if(list && Object.keys(list).length) {
            list = JSON.parse(JSON.stringify(list));
            Object.keys(list).map(id => { if(list[id].visibility === 0 && list[id].ownerId !== userId) {delete list[id]}})
            return list;
        }
        return {};
    };


    getters[`get${storeName}sByOrganization`] = (state) => (organizationId, listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(state[listContainer][localListName]) {
            const list = state[listContainer][localListName];
            const filtered = Object.keys(list).filter(id => {
                return list[id].organizationId === organizationId;
            });
            return filtered ? filtered.map(id => {
                return state[listContainer][localListName][id];
            }) : {};
        }
        return {};
    };

    getters[`get${storeName}ById`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id] : {};
    };

    getters[`get${storeName}InstancesByListName`] = (state) => (listName = '') => {
        let localListName = listName ? listName : instanceDefaultList;
        return state[instanceListContainer][localListName] ? state[instanceListContainer][localListName] : {};
    };

    getters[`get${storeName}QueryParams`] = (state) => (listName) => {
        return state[`combined${storeName}QueryParams`][listName];
    };

    getters[`get${storeName}CreationDate`] = (state, rootGetters) => (id, listName = defaultList) => {
        return state[listContainer][listName][id] ? rootGetters['convertDate'](state[listContainer][listName][id].createdAt) : '';
    };
    
    getters[`get${storeName}RowLifetime`] = (state) => (id, listName = defaultList) => {
        return state[listContainer][listName][id] ? state[listContainer][listName][id].rowLifetime : 0;
    };
    
    getters[`get${storeName}Schema`] = (state) => (id, listName = defaultList) => {
        return  state[listContainer][listName][id] &&  state[listContainer][listName][id].schema.columns ? state[listContainer][listName][id].schema.columns : {};
    };

    getters[`get${storeName}SchemaName`] = (state) => (id, listName = defaultList) => {
        return  state[listContainer][listName][id] ? state[listContainer][listName][id].schemaName  : "";
    };

    getters[`get${storeName}ChangeDate`] = (state, rootGetters) => (id, listName = defaultList) => {
        return state[listContainer][listName][id] ? rootGetters['convertDate'](state[listContainer][listName][id].updatedAt) : '';
    };

    getters[`get${storeName}Organization`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].organizationId ? state[listContainer][localListName][id].organizationId : '';
    };

    getters[`get${storeName}Teams`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].teams ? state[listContainer][localListName][id].teams : '';
    };

    getters[`get${storeName}Owner`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].ownerId ? state[listContainer][localListName][id].ownerId : '';
    };

    // getFilterState
    getters[`get${storeName}State`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].state ? state[listContainer][localListName][id].state : '';
    };

    // getFilterMetaSets
    getters[`get${storeName}MetaSets`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].metaSets ? state[listContainer][localListName][id].metaSets : {};
    };
    // getFilterVMLogs
    getters[`get${storeName}VMLogs`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName][id] && state[listContainer][localListName][id].vmLogs ? state[listContainer][localListName][id].vmLogs : {};
    };

    getters[`get${storeName}Tags`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].tags : [];
    };

    getters[`get${storeName}Name`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].name : '';
    };
    getters[`get${storeName}DisplayName`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].displayName : '';
    };

    /**
    * getFilterType
    * */
    getters[`get${storeName}Type`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].type : '';
    };

    getters[`get${storeName}MediaCategory`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].mediaCategory : '';
    };

    getters[`get${storeName}MediaCategoryByName`] = (state) => (id, listName = '') => {

        let localListName = listName ? listName : defaultList;
        if(state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].mediaCategory) {
            const res = Object.entries(mediaCategories).filter(item => {return item[1] === state[listContainer][localListName][id].mediaCategory});
            if(res && res[0]) {
                return res[0][0];
            }
            return '';
        }
    };

    /**
     * getFilterSourceProjectId
     * */
    getters[`get${storeName}SourceProjectId`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].sourceProjectId : '';
    };

    /**
     * getFilterContent
     * **/
    getters[`get${storeName}Content`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        let content = {};
        try {
            content = state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].content ? JSON.parse(state[listContainer][localListName][id].content) : {};
        } catch {
          content = state[listContainer][localListName][id].content;
        }
        return content;
    };
    getters[`get${storeName}RawContent`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].content ? state[listContainer][localListName][id].content : '';
    };

    getters[`get${storeName}State`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].state ? state[listContainer][localListName][id].state : null;
    };
    // getAssemblyContentHasPipelineFile
    getters[`get${storeName}ContentHasPipelineFile`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].content) {
            let content = JSON.parse(state[listContainer][localListName][id].content);
            return content.pipeline && content.pipeline.files && content.pipeline.files.length > 0;
        }
        return false;
    };
    getters[`get${storeName}ContentPipelineMeta`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].content) {
            let content = JSON.parse(state[listContainer][localListName][id].content);
            return content.pipeline && content.pipeline.meta ? content.pipeline.meta : {};
        }
        return false;
    };
     getters[`get${storeName}ContentPipelineEmail`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        if(state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].content) {
            let content = JSON.parse(state[listContainer][localListName][id].content);
            return content.pipeline.email;
        }
        return false;
    };
     // getAssemblyPipelineStatus getAssemblyPipelineStatus(
     getters[`get${storeName}PipelineStatus`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].pipelineStatus ? state[listContainer][localListName][id].pipelineStatus : '';
    };
    // getAssemblyPipelineTask
     getters[`get${storeName}PipelineTask`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].pipelineTask ? state[listContainer][localListName][id].pipelineTask : '';
    };
    // getAssemblyPipelineHasRunningTask
     getters[`get${storeName}PipelineHasRunningTask`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] && state[listContainer][localListName][id].pipelineHasRunningTask ? state[listContainer][localListName][id].pipelineHasRunningTask : false;
    };
    

    getters[`get${storeName}DisplayName`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].displayName : '';
    };

    getters[`get${storeName}Description`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].description : '';
    };

    getters[`get${storeName}PreviewUri`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].previewUri : '';
    };

    getters[`get${storeName}Visibility`] = (state) => (id, listName = '') => {
        let localListName = listName ? listName : defaultList;
        return state[listContainer][localListName] && state[listContainer][localListName][id] ? state[listContainer][localListName][id].visibility : '';
    };

    /**
     * getFilterListOptions
     * returns the options for a list - you can provide a specific option key or get the whole option object
     * @params listName {String} the list for which the options are needed
     * @params optionKey {String} the key for a specific option
     *
     * **/
    getters[`get${storeName}ListOptions`] = (state) => (listName, optionKey = '') => {
        if(optionKey === 'preview') {
            return state[optionList][listName] && state[optionList][listName].preview ? Object.keys(state[optionList][listName].preview)[0] : '';
        }
        else if(optionKey === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPath ? Object.keys(state[optionList][listName].fbxPath)[0] : '';
        }
        else if(optionKey === 'title') {
            return state[optionList][listName] && state[optionList][listName].selected_item && state[optionList][listName].selected_item.value ? state[optionList][listName].selected_item.value.name : '';
        }
        else if(optionKey === 'selected') {
            return state[optionList][listName] && state[optionList][listName].selected ? Object.keys(state[optionList][listName].selected)[0] : '';
        }
        else if(optionKey === 'projectId') {
            return state[optionList][listName] && state[optionList][listName].projectId ? Object.keys(state[optionList][listName].projectId)[0] : '';
        }
        else if(optionKey === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        else if(optionKey === 'index') {
            return state[optionList][listName] && state[optionList][listName].index ? parseInt(Object.keys(state[optionList][listName].index)[0]) : 0;
        } else if(optionKey === 'selected_asset') {
            return state[optionList][listName] && state[optionList][listName].selected_asset ? state[optionList][listName].selected_asset.asset.asset : '';
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    }

    //----------------------- MUTATIONS -------------------

    mutations[`clearGlobal${storeName}s`] = (state) => {
        state[`clearGlobal${storeName}s`] = {};
    };

    /**
     * updateFilterListOptionMutation
     * adds additional options to lists like e.g. the total x-count (pagination_items) or the selected items
     * @params args
     *  optionKey {String} the key for the property e.g. pagination_items
     *  value {String} the value for the property e.g. 2000
     *  value2 {String} the secondary value if you have one – will be empty if you don't provide one
     */
    mutations[`update${storeName}ListOptionMutation`] = (state, args) => {
        //reset all data:
        if (!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if (args.override || !state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
    }

    /**
     * loadFiltersMutation
     * Puts the loaded data in a list (for multiple Entries)
     * @param args
     *  data Array an array of the loaded data
     *  listName String (optional) if you don't wish to use the default list
     */
    mutations[`load${storeName}sMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        
        Vue.set(state[listContainer], listName, {});
        for (let i = 0; i < args.data.length; i++) {
            const dataItem = args.data[i];
            if(dataItem) {
                if(editLinks[storeName]) {
                    dataItem.editLink = editLinks[storeName].replace(':id', dataItem.id).replace(':type', dataItem.type ? dataItem.type : '');
                }
                if(listName === `global${storeName}s`) {
                    Vue.set(state[`global${storeName}s`], dataItem.id, dataItem);
                }
                else {
                    Vue.set(state[listContainer][listName], dataItem.id, dataItem);
                }
            }
        }
    }

    mutations[`load${storeName}InstancesMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : instanceDefaultList;
        Vue.set(state[instanceListContainer], listName, {});
        for (let i = 0; i < args.data.length; i++) {
            const dataItem = args.data[i];
            Vue.set(state[instanceListContainer][listName], dataItem.id, dataItem);
        }
    }

    mutations[`load${storeName}InstancesArrayMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : instanceDefaultList;
        Vue.set(state[instanceListContainer], listName, args.data);
    }

    mutations[`set${storeName}PropertyMutation`] =  (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        let id = args.id;
        if(state[listContainer][listName][id]) {
            Vue.set(state[listContainer][listName][id], args.property, args.value);
        }
    }

    /**
     * markFilterSelectedMutation
     * */
    mutations[`mark${storeName}SelectedMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        if(!state[listContainer][listName]) {
            throw new Error('list does not exist');
        }
        Object.keys(args.data).forEach(key => {
            let id = args.data[key].id;
            if(state[listContainer][listName] && state[listContainer][listName][id]) {
                Vue.set(state[listContainer][listName][id], 'selected', true);
            }
            const temp = JSON.parse(JSON.stringify(state[listContainer][listName][id]));
            delete state[listContainer][listName][id];
            state[listContainer][listName] = {[temp.id]: temp, ...state[listContainer][listName]};
        });
    }
    mutations[`mark${storeName}UnselectedMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        if(!state[listContainer][listName]) {
            throw new Error('list does not exist');
        }
        Object.keys(args.data).forEach(key => {
            let id = args.data[key].id;
            if(state[listContainer][listName] && state[listContainer][listName][id]) {
                Vue.set(state[listContainer][listName][id], 'selected', false);
            }
        });
    }

    mutations[`remove${storeName}ListOptionMutation`] = (state, args) => {
        if(state[optionList][args.listName] && state[optionList][args.listName][args.optionKey]) {
          Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
    }

    mutations[`sort${storeName}ListMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        if(!state[listContainer][listName]) {
            return;
        }
        switch( args.order ){
            case 'selected':
                /*
                  Sort cafes by most liked.
                */
                // eslint-disable-next-line no-case-declarations
                let temp = state[listContainer][listName];
                // eslint-disable-next-line no-case-declarations
                let keys = Object.keys(state[listContainer][listName]).sort( function( a, b ){
                    if(temp[a].selected === temp[b].selected) {
                        return 0;
                    }
                    else if(temp[a].selected && !temp[b].selected) {
                        return -1;
                    }
                    else if(!temp[a].selected && temp[b].selected) {
                        return 1;
                    }
                });

                Vue.set(state[listContainer], listName, {});
                for(let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    Vue.set(state[listContainer][listName], key, temp[key]);
                }
        }
    }
    mutations[`add${storeName}TagsMutation`] = (state, args) => {
        let listName = args.listName ? args.listName : defaultList;
        Vue.set(state[listContainer][listName][args.id], 'tags', args.tags);
    }

    /**
     * Puts the loaded data in a list (for single Entries)
     * @param args
     *  data Array an array of the loaded data
     *  listName String (optional) if you don't wish to use the default list
     */
    mutations[`load${storeName}Mutation`] = (state, args) => {
        Vue.set(state[listContainer][defaultList], args.data.id, args.data);
    }

    //----------------------- ACTIONS -------------------

    /**
     * loadFilters
     * @param args has no mandatory items
     *  listName String the list in which the items get stored (e.g. state.filterLists.filterList is default, "filterList" being the default list – you can choose any name as a listname
     *  -ignoreIndex - if newer query is finished while the old one is still running, let the older still fill the store
     * */
    actions[`load${storeName}s`] = async ({commit, dispatch, getters}, args) => {
        const ignoreIndex = args && args.ignoreIndex ? args.ignoreIndex : false;
        const listName =  args && args.listName ? args.listName : defaultList;
        const targetIndex = await dispatch(`set${storeName}Index`, {listName: listName});
        
        if(args) {
            dispatch(`register${storeName}QueryParams`, {listName: listName, params: args});
        }
        commit(`load${storeName}sMutation`, {data: [], listName: listName});
        return await dispatch(`clientLoad${objectTypes[storeName]}s`, getters[`get${storeName}QueryParams`](listName)).then(async data => {
            const currentIndex = getters[`get${storeName}ListOptions`](listName, 'index');
            if(ignoreIndex || !(targetIndex < currentIndex)) {
               if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}
                dispatch(`set${storeName}Pagination`, {data: data, listName: listName});
                commit(`load${storeName}sMutation`, {data: data, listName: listName});
                return data;
            }
            return {};
        });
    };
    
    actions[`load${storeName}Versions`] = async ({dispatch, getters}, args) => {
        const listName =  args && args.listName ? args.listName : defaultList;
        
        if(args) {
            dispatch(`register${storeName}QueryParams`, {listName: listName, params: args});
        }
        return await dispatch(`clientLoad${objectTypes[storeName]}Versions`, getters[`get${storeName}QueryParams`](listName)).then(async data => {
             //dispatch(`set${storeName}Pagination`, {data: data, listName: listName});
             //commit(`load${storeName}sMutation`, {data: data, listName: listName});
             return data;
        });
    };


    /**
     * loadFilter
     * @param args
     *  id usually required for getting a certain item
     *  listName a string that identifies the list
     * */
    actions[`load${storeName}`] = async ({dispatch, commit}, args) => {
        return await dispatch(`clientLoad${objectTypes[storeName]}`, args).then(async data => {
            if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}

            const mutationArgs = {data: data};
            if(args.listName) {mutationArgs.listName = args.listName}
            commit(`load${storeName}Mutation`, mutationArgs);
            return data;
        });
    };

    actions[`set${storeName}MetaValues`] = async ({dispatch}, args) => {
        //commit('updateMediaMutation', args);
        return dispatch(`clientSave${objectTypes[storeName]}MetaSetValues`, args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.metaValuesUpdated')});
        });
    },

    actions[`unset${storeName}MetaValues`] = async ({dispatch}, args) => {
        return dispatch(`clientUnset${objectTypes[storeName]}MetaSetValues`, args).then(data => {
            dispatch('createNotification', {'text': i18n.t('events.metaValuesUnset')});
            return data;
        });
    },

    actions[`add${storeName}Tag`] = async ({commit, dispatch}, args) => {
        return dispatch(`clientAdd${objectTypes[storeName]}Tags`, args).then(() => {
            let d = null;
            dispatch(`clientLoad${objectTypes[storeName]}Tags`, args).then(data => {
                commit(`add${storeName}TagsMutation`, {
                    id: args.id,
                    listName: args.listName ? args.listName : null,
                    tags: data,
                });
                d = data;
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsAdded')});
            return d;
        });
    },
    actions[`remove${storeName}Tag`] =  async ({commit, dispatch}, args) => {
        dispatch(`clientRemove${objectTypes[storeName]}Tags`, args).then(() => {
            dispatch(`clientLoad${objectTypes[storeName]}Tags`, args).then(data => {
                commit(`add${storeName}TagsMutation`, {
                    id: args.id,
                    listName: args.listName ? args.listName : null,
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsRemoved')});
        });
    },

    /**
     * loadFilterInstances
     * @param args
     *  id {String} uuid ProjectId
     *  listName {String} the identifier for the list
     * */
    actions[`load${storeName}Instances`] = async ({dispatch, commit}, args) => {
        const listName =  args.listName ? args.listName : instanceDefaultList;
        // todo: add dynamic args
        // dispatch(`register${storeName}QueryParams`, {listName: listName, params: args});
        args.filter = 'type ';
        const filterTypes = types[storeName];
        if(filterTypes && filterTypes.length === 1) {
            args.filter += 'eq ' + filterTypes[0];
        }
        else if(filterTypes) {
            args.filter += 'in ' + filterTypes.join(' ');
        }

        return await dispatch('clientLoadProjectInstances', /*getters[`get${storeName}QueryParams`](listName)*/ args)
            .then(async data => {
                if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}
                commit(`load${storeName}InstancesMutation`, {data: data, listName: listName});
                return data;
        });
    };

    actions[`load${storeName}Instance`] = async ({dispatch, commit}, args) => {
        const listName =  args.listName ? args.listName : instanceDefaultList;

        return dispatch('clientLoadProjectInstance', args).then(data => {
            commit(`load${storeName}InstancesMutation`, {data: data, listName: listName});
            return data;
        });
    };

    actions[`set${storeName}InstanceMetaValues`] = async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveInstanceMetaSetValues', args).then(data => {
            dispatch('createNotification', {'text': i18n.t('events.qrcodeMetaValuesUpdated')});
            return data;
        });
    },

    /**
     * loadAllFilterInstances
     * loads all instances over all projects
     * @param args
     *  listName {String} the identifier for the list
     * */
    actions[`loadAll${storeName}Instances`] = async ({commit, dispatch, getters}, args) => {
        const listName =  args.listName ? args.listName : instanceDefaultList;
        dispatch(`register${storeName}QueryParams`, {listName: listName, params: args});

        return await dispatch(`clientGetCrossProjectInstances`, getters[`get${storeName}QueryParams`](listName)).then(async data => {
            if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}
            commit(`load${storeName}InstancesMutation`, {data: data, listName: listName});
            return data;
        });
    };


    /**
     * createFilterInstance
     * @param args
     *   id String projectId
     *   args Array of to be created objects
     *      assetId String (necessary)
     *      any all other fields can be added as present in the asset if you want to overwrite them
     *
     *
     * */
    actions[`create${storeName}Instance`] = async ({dispatch}, args) => {
        return await dispatch(`clientCreateProjectInstances`, args);
    };

    /**
     * updateFilterInstance
     * @param args
     *   id String projectId
     *   args Array of to be created objects
     *      assetId String (necessary)
     *      any all other fields can be added as present in the asset if you want to overwrite them
     *
     *
     * */
    actions[`update${storeName}Instance`] = async ({dispatch}, args) => {
        return await dispatch(`clientUpdateProjectInstance`, args);
    };


    /**
     * createFilterInstance
     * @param args
     *   id String projectId
     *   args Array of to be deleted id's
     *      asset/project/etc.-id
     *
     *
     * */
    actions[`delete${storeName}Instances`] = async ({dispatch}, args) => {
        return await dispatch(`clientDeleteProjectInstances`, args);
    };

    /**
     * createFilter
     * @param args
     *   type String
     *   name String the search string
     *   (description) String optional
     *   organizationId string
     *
     * */
    actions[`create${storeName}`] = async ({dispatch}, args) => {
        return await dispatch(`clientCreate${objectTypes[storeName]}`, args).then(data => {
            dispatch('createNotification', {'text': `${data.name ? data.name : data.id} updated`});
            return data;
        });
    };

    /**
     * updateFilter
     * @param args
     *   id String required (the id of the ressource)
     *   any the fields you wish to have changed
     * */
    actions[`update${storeName}`] = async ({dispatch, commit}, args) => {
        return await dispatch(`clientSave${objectTypes[storeName]}`, args).then(data => {
            dispatch('createNotification', {'text': `${data.name ? data.name : data.id} updated`});
            commit(`load${storeName}Mutation`, {listName: args.listName, data: data});
            return data;
        });
    };

    /**
     * deleteFilter
     * @param args
     *   id String targetId of the to be deleted item
     *
     *
     * */
    actions[`delete${storeName}`] = async ({dispatch}, args) => {
        return await dispatch(`clientDelete${objectTypes[storeName]}`, args);
    };

    /**
     * deleteFilters
     * @param args
     *   args Array of to be deleted id's
     *      asset/project/etc.-id
     *
     *
     * */
    actions[`delete${storeName}s`] = async ({dispatch}, args) => {
        return await dispatch(`clientDelete${objectTypes[storeName]}s`, args);
    };

    /**
     * CheckIfFilterNameExists
     * @param args
     *   type String Asset, Project, Instance or Dataset
     *   name String the search string
     *   organizationId string
     * */
    actions[`checkIf${storeName}NameExists`] = async ({dispatch, getters}, args) => {
        let name = args.name;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        let filterString =  'name eq ' + name;
        if(storeName !== 'Service') {
            filterString += ', organizationId eq ' + orgId;
        }
        return dispatch(`clientLoad${objectTypes[storeName]}s`, {
            filter: [filterString],
            fields: 'name,id',
        });
    };

     /*
    * Attention: this method is vuex-store only (no database-connection)
    * listName
    * order (property name)
    * */
    actions[`sort${storeName}List`] = ({commit}, args) => {
        commit(`sort${storeName}ListMutation`, args);
    },

    //----------------------- GLOBAL THINGS -------------------
    // such as helpers, query-params and so on

    getters.convertDate = () => (date) => {
        var currentDate = new Date(date);
        var date2 = currentDate.getDate();
        var month = ('0' + (currentDate.getMonth()+1)).slice(-2);
        var year = currentDate.getFullYear();
        return date2 + '.' + month + '.' + year;
    }


    /**
     * combineFilterQueryParams
     * Combines the saved query params into one query to send to the api
     * @params args
     *  listName the identifier of the list/the query
     * */
    mutations[`combine${storeName}QueryParams`] = async (state, args) => {
        let params = state[`${storeName}QueryParams`][args.listName];
        let queryObj = {};

        Object.keys(params).forEach(paramType => { //paramType = z.B. Filter
            let paramItem = params[paramType];
            if(Object.keys(paramItem).length > 0) {
                switch(paramType) {
                    case 'filter':
                        queryObj[paramType] = combineFilters(paramItem);
                        break;
                    case 'textSearch':
                        queryObj[paramType] = combineIntoSingleObject(paramItem);
                        break;
                    default:
                        queryObj[paramType] = combineOverwrite(paramItem);
                        break;
                }
            }

        });
        Vue.set(state[`combined${storeName}QueryParams`], args.listName, queryObj);
        return queryObj;
    }

    /**
     * saveFilterQueryParams
     * saves the query params to the list
     * @params args
     *  listName string identifies the target list
     *  params object containing the params to add/remove or keep
     * */
    mutations[`save${storeName}QueryParams`] = async (state, args) => {
        const target = state[`${storeName}QueryParams`];
        if(!target[args.listName]) {
            Vue.set(target, args.listName, {});
        }
        let targetList = state[`${storeName}QueryParams`][args.listName];

        // if there are default settings, add them to the target list
        let defaults = target.defaults;
        Object.keys(defaults).forEach(paramType => {
            let paramItem = defaults[paramType];
            Object.keys(paramItem).forEach(inputID => {
                if(!targetList[paramType]) {
                    Vue.set(targetList, [paramType], {});
                }
                if(!targetList[paramType][inputID]) {
                  targetList[paramType][inputID] = paramItem[inputID]
                }
            });
        });

        if('remove' in args.params) {
            let removes = args.params['remove'];
            Object.keys(removes).forEach(paramType => {
                let paramItem = removes[paramType];
                Object.keys(paramItem).forEach(inputID => {
                    if(targetList[paramType]) {
                        delete targetList[paramType][paramItem[inputID]]
                        // Vue.delete(targetList[paramType], paramItem[inputID]);
                    }
                });
            });
        }

        /*
        adds any new params to the "queryParams"-State of the corresponding store
        example of data.params
        {add: {filter: {[inputID]: 'name like %'+ filterValue +'%'}}}
         */
        if('add' in args.params) {
            let adds = args.params['add'];
            if(adds && Object.keys(adds)) {
                Object.keys(adds).forEach(paramType => {
                    let paramItem = adds[paramType];
                    Object.keys(paramItem).forEach(inputID => {
                        if(!targetList[paramType]) {
                            Vue.set(targetList, paramType, {});
                        }
                        targetList[paramType]
                        Vue.set(targetList[paramType], inputID, paramItem[inputID]);
                    });
                });
            }
        }

        if('addObject' in args.params) {
            let addsObj = args.params['addObject'];
            Object.keys(addsObj).forEach(key => {
                if(!targetList[key]) {
                    Vue.set(targetList, key, {});
                }
                Vue.set(targetList[key], Object.keys(addsObj[key])[0], addsObj[key][Object.keys(addsObj[key])[0]]);
            });
        }

        /*
        * This is for special values that never change (like an id for assets per project)
        * attention: they are flat and have no inputID because they won't be removed or added:
        * {keep: {id: 'idvalue'}}
        * */
        if('keep' in args.params) {
            let keeps = args.params['keep'];
            Object.keys(keeps).forEach(paramType => {
                let paramValue = keeps[paramType];
                Vue.set(targetList, paramType, paramValue);
            });
        }
        return targetList
    }

    /**
     * RegisterFilterQueryParams
     * Register parameters for a query,
     * e.g. if you have a list of filters and want to add a q='test' to be applied to the query
     *
     * @param args object
     *  listName string the identifier for the query (must match the identifier for the query you want to inject the params)
     *  {params} an object containing one or multiple params
     *  to add a params to the query:
     *      add: {
     *          filter: {
     *              yourspecialkey: 'type eq filter'
     *          }
     *      }
     *
     *  to remove a param from the query:
     *      remove: {
     *          filter: [
     *              yourspecialkey
     *          ]
     *      }
     *
     *  You can also mix both:
     *      {
     *         add: { ... }
     *         remove: { ... }
     *      }
     *
     *  If you don't want to have something modified, you can use (you don't any special key):
     *  keep: {
     *      id: 5333,
     *  }
     * */
    actions[`register${storeName}QueryParams`] = ({commit}, args) => {
        if(args && args.params && Object.keys(args).length > 0 && Object.keys(args.params).length > 0) {
            commit(`save${storeName}QueryParams`, {listName: args.listName ? args.listName : defaultList, params: args.params});
            commit(`combine${storeName}QueryParams`, {listName: args.listName ? args.listName : defaultList});
            return true;
        }
        return false;
    }

    /**
     * setFilterPagination
     * sets the item-count in the list options
     * @params args {object}
     *  listName {String} the identifier for the list
     *  data {Object || Array} the returned data of the query
     *  override {Boolean} whether or not to override if a value is already in the option list, default: true
     * */
    actions[`set${storeName}Pagination`] =  ({commit}, args) => {
        if(args.data.headers()) {
            let count = args.data.headers()['x-count'] ? parseInt(args.data.headers()['x-count']) : 0;
            return commit(`update${storeName}ListOptionMutation`, {optionKey: 'pagination_items', value: count, listName: args.listName, override: args.override ? args.override : true});
        }
        return true;
    }
    
    /**
     * setFilterIndex
     * */
    actions[`set${storeName}Index`] =  ({commit, getters}, args) => {
        const {listName} = args;
        let count = getters[`get${storeName}ListOptions`](listName, 'index');
        count = count + 1;
        commit(`update${storeName}ListOptionMutation`, {optionKey: 'index', value: count, listName: listName, override: true});
        return count;
    }

    actions[`set${storeName}Property`] = ({commit}, args) => {
        commit(`set${storeName}PropertyMutation`, args);
    }

    actions[`mark${storeName}Selected`] = ({commit}, args) => {
        return commit(`mark${storeName}SelectedMutation`, args);
    }

    actions[`mark${storeName}Unselected`] = ({commit}, args) => {
        return commit(`mark${storeName}UnselectedMutation`, args);
    }
}
getters[`getAssemblyTreeRootNodeId`] = (state) => (listName, parentId) => {
    return state.assemblyTrees[listName] && state.assemblyTrees[listName][parentId] ? state.assemblyTrees[listName][parentId][0].id : '';
};

getters[`getAssemblyTreeLevel`] = (state) => (parentId, listName) => {
    return state.assemblyTrees[listName] && state.assemblyTrees[listName][parentId] ? state.assemblyTrees[listName][parentId] : [];
};

getters[`getAssemblyItemsChildren`] = (state) => (parentId, listName) => {
    const lists = state.AssemblyItemsInstanceLists[listName];
    if(lists) {
        const items = lists.filter(item => {return item.parentId === parentId});
        if(items.length) {
            return items;
        }
    }
    return [];
};

const arraySmallerThan = (val, compare) => {
    for(let i = 0; i < val.length; i++) {
        if(val[i] >= compare[i]) {
            return false;
        }
    }
    return true;
}

// eslint-disable-next-line no-unused-vars
const getMinMaxVal = (percentage, arrayOfValues) => {
    let min;
    let max;
    arrayOfValues.map(item => {
        if(item > max) {
            max = item;
        }
        if(item < min) {
            min = item;
        }
    })
    return {min, max};
}

/**
 * @params val - the original value
 * @params compare - the compare value
 * @params modifier - eq, lt, gt or contains
 * the sentence is in the form of: "val lt compare"
 * */
const filterMethod = (val, modifier, compare) => {
    if(!val || !modifier || !compare) {
        return false;
    }
    let s, t;
    const rgx = /[[0-9.]*,[0-9.]*,[[0-9.]+]/g;
    let isArray = val.match(rgx);
    if(isArray && modifier !== 'eq') {
        s = JSON.parse(val);
        t = JSON.parse(compare);
    } else if(isArray) {
        s = JSON.stringify(val);
        t = JSON.stringify(compare);
    }
    else if(['lt', 'gt'].includes(modifier)) {
        s = parseFloat(val);
        t = parseFloat(compare);
    }
    else {
        s = val.toLowerCase();
        t = compare.toLowerCase();
    }
    switch (modifier) {
        case 'eq':
            return s == t; // only two because we want to check numbers as well
        case 'lt':
            return isArray ? arraySmallerThan(t,s) :  s > t;
        case 'gt':
            return isArray ? arraySmallerThan(s,t) :  s < t;
        case 'contains':
            return !!s.includes(t);
        case 'like':
            return !!s.includes(t);
    }
}

getters[`getAssemblyItemsInstancesFiltered`] = (state) => (args) => {
    const {stringFilter, stringFilterMode, listName, asKeys, metaFilter} = args;
    const lists = state.AssemblyItemsInstanceLists[listName];
    if(lists) {
        let items = lists;
        if(!stringFilter.length && !metaFilter.length) {
            return [];
        }
        if(stringFilter && stringFilter.length) {
            items = items.filter(item => {
                const length = stringFilter.filter(filter => {
                    const {field, modifier, val} = filter;
                    return filterMethod(item[field], modifier, val);
                }).length;
                return stringFilterMode === 'AND' ? length === stringFilter.length : length !== 0;
            })
        }
        if(metaFilter && metaFilter.length) {
            items = items.filter(item => {
                const itemMetaSets = item.squashedMetaFields;
                if(!itemMetaSets) {
                    return false;
                }
                const metaValuesOnly = itemMetaSets.map(item => {return item.squashedMetaValues });
                if(metaValuesOnly) {
                    return metaFilter.filter(filter => {
                        const {metaSet, metaField, modifier, val} = filter;
                        const targetFields = metaValuesOnly.filter(item => {return item.metaSetName === metaSet && item.metaFieldKey === metaField})
                        if(!targetFields.length) {
                            return false;
                        }
                        const finalValue = targetFields[0].finalMetaValue;
                        return filterMethod(val, modifier, finalValue);
                    }).length === metaFilter.length;
                }
            })
        }
        if(items.length) {
            if(asKeys) {
                return items.map(item => {return item.id});
            }
            return items;
        }
    }
    return [];
}

getters[`getAssemblyTreeItemById`] = (state) => (listName, id) => {
        let level = 0;
        let index = null;
        if(state.assemblyTrees[listName]) {
            let size = Object.keys(state.assemblyTrees[listName]).length;
            for(let i = 0; i < size; i++) {
                level = i;
                index = state.assemblyTrees[listName][i].findIndex(function(item) {
                    return item.id === id;
                });
                if(index !== -1) {
                    break;
                }
            }
        }
        return {
            level: level,
            index: index,
            listName: listName,
        }
}


mutations[`increaseAssemblyTreeSourceListMutation`] = (state, args) => {
    let sources = [];
    if(state.assemblyTrees[args.listName][args.level][args.index].sources) {
        sources = state.assemblyTrees[args.listName][args.level][args.index].sources;
    }
    sources.unshift('new');
    Vue.set(state.assemblyTrees[args.listName][args.level][args.index], 'sources', sources);
};
mutations[`decreaseAssemblyTreeSourceListMutation`] = (state, args) => {
    let sources = state.assemblyTrees[args.listName][args.level][args.index].sources;
    sources.shift();
    Vue.set(state.assemblyTrees[args.listName][args.level][args.index], 'sources', sources);
};

mutations[`unloadAssemblyTreeLevelMutation`] = (state, args) => {
        let listName = args.listName;
        Vue.delete(state.assemblyTrees[listName], args.parentId);
    }
mutations[`loadAssemblyTreeLevelMutation`] = (state, args) => {
        let listName = args.listName;

        if(!state.assemblyTrees[listName]) {
            Vue.set(state.assemblyTrees, listName, {});
        }
        delete args.data.headers;
        Vue.set(state.assemblyTrees[listName], args.parentId, args.data);
};

mutations[`loadAssemblyTreeLevelItemMutation`] = (state, args) => {
    let listName = args.listName;

    if(!state.assemblyTrees[listName]) {
        Vue.set(state.assemblyTrees, listName, {});
    }
    const index = state.assemblyTrees[listName][args.parentId].findIndex(item => {
        return item.id === args.item.id;
    })
    Vue.set(state.assemblyTrees[listName][args.parentId], index, args.item);
};

actions[`checkIfPermissionNameExistsSpecial`] = async ({dispatch}, args) => {
    let name = args.name;
    return dispatch(`clientLoadPermissions`, {
        filter: ['name eq ' + name],
        fields: 'name,id',
    });
};

actions[`checkIfRoleNameExistsSpecial`] = async ({dispatch}, args) => {
    let name = args.name;
    return dispatch(`clientLoadRoles`, {
        filter: ['name eq ' + name],
        fields: 'name,id',
    });
};

//* CUSTOM STORE METHODS */

/**
 * checks whether asset has an instance in a given projectId or not (include = instances is necessary)
 * @param args
 *  projectId - uuid
 *  asset - asset object
 * */
// eslint-disable-next-line no-unused-vars
actions[`assetHasInstanceOfProject`] = ({dispatch}, args) => {
    const {asset, projectId} = args;
    return !!asset.instances && asset.instances.filter(instance => {return instance.projectId === projectId}).length;
}
actions[`setAssemblyTreeSelected`] = ({dispatch, commit}, args) => {
        if(args && args.id && args.assetId) {
            args.optionKey = 'selected';
            args.value = args.id;
            args.value2 = args.assetId;
            args.override = true;
            commit('updateAssemblyListOptionMutation', args);
        }

        args.optionKey = 'selected_asset';
        args.value = 'asset';
        args.value2 = args.asset;
        commit('updateAssemblyListOptionMutation', args);

        args.optionKey = 'selected_item';
        args.value = 'value';
        args.value2 = args.item;
        commit('updateAssemblyListOptionMutation', args);

        //dispatch('loadSfxdataAndTree', {id: args.listName});

        dispatch('loadAssemblyImagePreview', {id: args.id, listName: args.listName});
        dispatch('loadAssemblyFbxPreview', {id: args.id, listName: args.listName});
};
actions[`loadAssemblyImagePreview`] = ({commit}, args) => {
    let listName = args.listName ? args.listName : args.id;
    commit('updateAssemblyListOptionMutation', {optionKey: 'previewPath', value: args.id, listName: listName, override: true});
};

actions[`loadAssemblyFbxPreview`] = ({dispatch, commit}, args) => {
    dispatch('clientLoadAssetFBXFromStorage', {id: args.id})
            .then(data => {
                commit('updateAssemblyListOptionMutation', {optionKey: 'fbxPath', value: data, listName: args.listName, override: true});
            });
};

/*
* args:
*   optionKey
*   value
*   value2 (optional)
* */
actions[`setAssemblyTreeOpen`] = ({commit}, args) => {
    if(args && args.id) {
        commit('updateAssemblyListOptionMutation', {
            listName: args.listName,
            optionKey: 'open',
            value: args.id,
            value2: 'open'
        });
    }
};

actions[`unloadAssemblyTreeLevel`] = async ({commit}, args) => {
    commit('unloadAssemblyTreeLevelMutation', args);
}

actions[`loadAssemblyTreeLevelItem`] = async ({commit, dispatch}, args) => {
    const parentIdFromArgs = args.parentId;
    delete args.parentId;
    return dispatch('clientLoadProjectInstance', args)
        .then(instance => {
        const mutationArgs = {
            listName: args.id,
            item: instance ? instance : null,
            level: instance && instance.level ? instance.level : 0,
            parentId: instance && instance.parentId ? instance.parentId : parentIdFromArgs,
        };
        commit('loadAssemblyTreeLevelItemMutation', mutationArgs);
        return instance;
    });
};

actions[`loadAssemblyTreeLevel`] = async ({commit, dispatch}, args) => {
    let i;
    return dispatch('clientLoadProjectInstances', args).then(instances => {
        const mutationArgs = {
                listName: args.id,
                data: instances,
                level: instances[0] && instances[0].level ? instances[0].level : 0,
                parentId: instances[0] && instances[0].parentId ? instances[0].parentId : args.parentId,
            };
            commit('loadAssemblyTreeLevelMutation', mutationArgs);
            i = instances;
        }).then(() => {
            dispatch('loadAssemblyTreeLevelLength', args);
        }).then(() => {
            return i;
    });
    };

actions[`setAssemblyTreeClosed`] = async ({commit}, args) => {
        if(args && args.id) {
            args.optionKey = 'open';
            args.value = args.id;
            args.value2 = 'open';
            commit('removeAssemblyListOptionMutation', args);
        }
    }

actions[`loadAssemblyTreeLevelLength`] = async ({commit, dispatch}, args) => {
    let argsCopy = JSON.parse(JSON.stringify(args));
    argsCopy.fields = ['id'];
        argsCopy.limit = 100000;
        delete argsCopy.offset;
        delete argsCopy.include;

        return dispatch('clientLoadProjectInstances', argsCopy).then(instances => {
            commit('updateAssemblyListOptionMutation', {optionKey: 'pagination_items', value: instances.length, listName: args.parentId, override: true});
        });
    };

actions[`increaseAssemblyTreeSourceList`] = async ({commit, getters}, args) => {
    let result = getters.getAssemblyTreeItemById(args.listName, args.id);
    commit('increaseAssemblyTreeSourceListMutation', result);
};
actions[`decreaseAssemblyTreeSourceList`] = async ({commit, getters}, args) => {
    let result = getters.getAssemblyTreeItemById(args.listName, args.id);
    commit('decreaseAssemblyTreeSourceListMutation', result);
};


actions[`loadProjectSfxdatas`] = async ({commit, dispatch}, args) => {
        let listName = args.listName ? args.listName : 'SfxdataList';

        return dispatch('loadSfxdatas', args).then(() => {
            dispatch('clientLoadProjectAssets', /*getters.getSfxdataQueryParams(listName)*/ args).then(data => {
                data.listName = listName;
                data.id = args.id;
                commit('markSfxdataSelectedMutation', {listName: listName, data: data});
            });
        });
    };



/**
 * Helper method, loads the pipeline state and adds it to an existing set of data
 * @params args single Object or Array of Objects
 * @returns Object or Array (same kind as given by params)
 * */
actions.includeItemPipelineState = async ({dispatch}, args) => {
    let data = !Array.isArray(args) ? [args] : args;
    for(let i = 0; i < data.length; i++) {
        await dispatch('clientGetPipelineStatus', {id: data[i].id }).then(async res => {
                data[i].pipelineHasRunningTask = !!res;
                const type = await dispatch('getItemType', {id: data[i].id});
                if(type === 'Asset') {
                    const rootNode = await dispatch('clientLoadProjectInstances', {
                        id: data[i].sourceProjectId,
                        filter: 'type in node model, level eq 1, parentId eq null'
                    });
                    const hasRootNode = rootNode && JSON.parse(JSON.stringify(rootNode)).length;
                    // 1: new: not yet begun: hasRootNode = false && no res
                    // 2: processing: res
                    // 3: finished: no res and root node
                    data[i].pipelineStatus = res ? 'processing' : (hasRootNode ? 'finished' : 'new');
                    // 4: new: hasRootNode = true && pipeline.content.update = true
                    if(data[i].pipelineStatus === 'finished') {
                        const content = data[i].content ? JSON.parse(data[i].content) : {};
                        if(content && content.pipeline && content.pipeline.isUpdate === true && !res) {
                            data[i].pipelineStatus = 'new';
                        }
                    }
                }
                else if(type === 'Instance') {
                    // 1: new: not yet begun: hasFilterResults = false && no res
                    // 2: processing: res
                    // 3: finished: no res and hasFilterResults
                    const hasFilterResults = data[i].content && JSON.parse(data[i].content) && JSON.parse(data[i].content).filterResults
                    data[i].pipelineStatus = res ? 'processing' : (hasFilterResults ? 'finished' : 'new')
                }
                data[i].pipelineTask = res ? res.task : '';
        })
    }
    return !Array.isArray(args) ? data[0] : data;
};

actions[`loadDatasets`] = async ({commit, dispatch, getters}, args) => {
    /*if(args.add) {
        if(args.add.filter) {
            args.add.filter.schemaName = "schemaName eq null";
        }
        else {
            args.add.filter =  {schemaName: "schemaName eq null"}
        }
    } else {
        args.add =  { filter: { schemaName: "schemaName eq null"}}
    }*/
    const defaultList = `DatasetList`;
    const ignoreIndex = args && args.ignoreIndex ? args.ignoreIndex : false;
    const listName =  args && args.listName ? args.listName : defaultList;
    const targetIndex = await dispatch(`setDatasetIndex`, {listName: listName});

    if(args) {
        dispatch(`registerDatasetQueryParams`, {listName: listName, params: args});
    }
    commit(`loadDatasetsMutation`, {data: [], listName: listName});
    return await dispatch(`clientLoad${objectTypes['Dataset']}s`, getters[`getDatasetQueryParams`](listName)).then(async data => {
        const currentIndex = getters[`getDatasetListOptions`](listName, 'index');
        if(ignoreIndex || !(targetIndex < currentIndex)) {
            if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}
            dispatch(`setDatasetPagination`, {data: data, listName: listName});
            commit(`loadDatasetsMutation`, {data: data, listName: listName});
            return data;
        }
        return {};
    });
};

actions[`filterAssemblyItemsInstances`] = ({commit}, args) => {
    const {func, listName} = args;
    commit('filterFuncFunction', {func, listName})
}

actions[`loadAssemblyItemsInstances`] = async ({dispatch, commit}, args) => {
    const storeName = 'AssemblyItems';
    const listName =  args.listName ? args.listName : 'AssemblyItemsInstances';
    // dispatch(`register${storeName}QueryParams`, {listName: listName, params: args});

    return await dispatch('clientLoadProjectInstances', /*getters[`get${storeName}QueryParams`](listName)*/ args)
        .then(async data => {
            if(args && args.addPipelineState) {data = await dispatch('includeItemPipelineState', data);}
            commit(`load${storeName}InstancesArrayMutation`, {data: data, listName: listName});
            return data;
        });
};


export default {
    state, getters, mutations, actions,
}