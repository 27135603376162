import { render, staticRenderFns } from "./CostOverview.vue?vue&type=template&id=bd0b67a4&scoped=true&"
import script from "./CostOverview.vue?vue&type=script&lang=js&"
export * from "./CostOverview.vue?vue&type=script&lang=js&"
import style0 from "./CostOverview.vue?vue&type=style&index=0&id=bd0b67a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd0b67a4",
  null
  
)

export default component.exports