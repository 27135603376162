<template>
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0357542 1.42L4.57474 6L0.0357534 10.59L1.43313 12L7.3794 6L1.43313 -5.24537e-07L0.0357542 1.42Z" fill="white"/>
  </svg>
</template>


<script>
export default {
  name: 'SfxIconArrowRight',
}

</script>