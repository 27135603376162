<template>
  <div :class="['w-100 video-wrapper']">
    <!--<div @click="start" class="play">Play</div>
    <div @click="stop" class="play">Stop</div>-->
    <template v-if="loadingPanel">
      <div style="z-index:100;" class="inapp-loading-screen">
        <loading-spinner size="big" class="white centered-item" />
        <!--<div class="bg-container"><div class="bg"></div></div>-->
      </div>
    </template>
    <loading-spinner v-else-if="loading" size="big" class="white centered-item" />
        <div v-if="error && editorMode" class="form-error border-radius p-2">{{ error }} <br /><div @click="handleTransition" class="d-inline-block settings-button mt-1">{{ $t('reload') }}</div></div>
        <div v-if="debugMode" style="z-index: 9000; position:absolute;top:15px; left:15px; color: #000;">
          CURRENT FILE: {{ currentVideoFile }}<br/>
          CURRENT TARGET TIME: {{ targetTime }}<br />
          transition: <div @click="handleTransition">hanlde trans</div>
          {{ urls }}
          <div @click="currentVideoFile = urls[0].fileName">set 0 als filename</div>
          <div @click="currentVideoFile = urls[1].fileName">set 1 als filename</div>
          <div class="p-3 bg-dark" @click.stop="setVideoTimes">Set video times</div>
          <div @click="checkURLValidity(urls[0].url)">check validity</div>
          <div @click="splitMode = !splitMode" class="d-inline-block settings-button">Toggle split view</div>
          <div class="mt-2" @click="play(currentVideoFile)">Play video</div>
        </div>
        <background-video-item
          v-for="(item) in urls"
          :class="[
                'bg-video',
                item.fileName === currentVideoFile && videoMode ? 'active' : (item.fileName === oldCurrentVideoFile && videoMode ? 'inactive' :'reallyinactive'),
                notFullTransition && (item.fileName === currentVideoFile || item.fileName === oldCurrentVideoFile) && videoMode ? 'transition-fade' : '',
                splitMode ? 'split-mode' : '',
                item.fileName
                ]"
          :key="item.url"
          :url="item.url"
          :can-play="videoMode"
          :poster="images[item.slideId] ? images[item.slideId] : placeholder"
          :width="width"
          :height="height"
          :should-be-playing="shouldBePlaying === item.fileName"
          :start-time="item.startTime"
          :target-time="item.endTime"
          @playError="reloadVideo"
        />
      </div>
    </template>

    <script>
    import LoadingSpinner from "../../LoadingSpinner";
    import listenerMixinJs from "../../mixins/listenerMixin.js";
    import BackgroundVideoItem from "@/components/vForm/viewer/BackgroundVideoItem.vue";
    import {checkURLValidity} from "@/components/vForm/utils";

    export default {
      name: "BackgroundVideo",
      components: {LoadingSpinner, BackgroundVideoItem},
      mixins: [listenerMixinJs],
      props: {
        /**
         * Whether it is edit or view mode
         * */
        editorMode: {type: Boolean, default: false},
        /**
         * The content of videoconfig.json – this will later be automatically generated by vSTAGE
         * {
         *   inSlide: 'slide-uuid-1',
         *   outSlide: 'slide-uuid-2',
         *   startTime: 5.03,
         *   endTime: 6.03,
         *   videoFile: 'myfile.mp4'
         * }
         * */
        videoConfig: {type: Array, required: true},
        /**
         *  The slide we were last
         * */
        sourceSlideUuid: {type: String, required: true},
        /***
         * The slide we are going to
         * */
        targetSlideUuid: {type: String, required: true},
        /**
         * The id of the project belonging to the vform
         * */
        projectId: {type: String, required: true},
        /**
         * Whether or not it is offline mode which means
         * that the videos will be loaded from a local directory
         * rather than from the vhub storage api
         * */
        offlineMode: {type: Boolean, default: false},
        /**
         * The home slide must be resetted to time = initial time otherwise we will always see flickering...
         * */
        homeSlide: {type: String, required: true},
        images: {type: Object, default: () => {return {}}},
        jumpTargets: {type: Array, default: () => {return {}}}
      },
      data() {
        return {
          shouldBePlaying: null,
          timeout: null,
          checkURLValidity: checkURLValidity,
          /**
           * This is for debugging – shows the active video top left, the inactive video bottom right
           * */
          splitMode: false,
          /**
           * Will show debug info overlay over the video if set to true
           * if a cookie is set debugMode = debug, it will set this to true
           * */
          debugMode: false,
          performanceMode: "normal",
          /**
           * Whether there is a video loading right now
           * */
          loading: false,
          loadingPanel: false,
          /***
           * If the video could not be loaded: switch to image mode
           * */
          videoMode: true,
          /**
           * The name of the current visible video file
           * */
          currentVideoFile: "",
          /**
           * The name of the previous visible video file
           * This one will also be visible in case the current video file needs
           * to be loaded, so it will be visible in the background and the transition
           * looks smooth
           * */
          oldCurrentVideoFile: "",
          /**
           * The currently used video tag
           * */
          player: null,

          /**
           * Video loading errors will be shown here
           * */
          error: "",

          /**
           * The width and height of the video
           * this will adapt based on the size of the images being loaded in the background
           * */
          width: 0,
          height: 0,

          /**
           * The blob storage urls in the browser cache
           * */
          urls: [],
          notFullTransition: false,
          initialTransitionDone: false,
          handlingTransition: false,
          lowPerformanceMode: false,
          forceReRenderKey: 0,
          placeholder: require('@/assets/images/transparent.png'),
        };
      },
      watch: {
        targetSlideUuid: {
          deep: true,
          handler() {
            this.handleTransition()
          }
        },
        editorMode() {
          this.adjustSize();
        },
        splitMode() {
          this.adjustSize();
        }
      },
      beforeMount() {
        // eslint-disable-next-line no-undef
        const debugMode = $cookies.get('debugMode');
        if (debugMode === 'debug') {
          this.debugMode = true;
        }
        // todo: change to live when finished
        // eslint-disable-next-line no-undef
        const performanceMode = this.$route.query.performance; //$cookies.get('performanceMode');
        if (performanceMode === 'low') {
          this.lowPerformanceMode = true;
        }
      },
      mounted() {
        this.handleTransition();
        this.adjustSize();
        this.addListener(window, "resize", this.adjustSize, 'resize-bg-video')
      },
      beforeDestroy() {
        // revoke all urls to clean up browser memory
        for (let i = 0; i < this.urls.length; i++) {
          try {
            window.URL.revokeObjectURL(this.urls[i].url);
          } catch {
            // do nothing, video probably has been purged already
          }
        }
      },
      methods: {
        reloadVideo() {
          // todo: load video from storage
        },
        async handleTransition() {
          this.handlingTransition = true;
          console.log('handle transition')
          this.error = "";

          // search for transition && set variables
          let videoFile = '';
          let videoStartTime = undefined;
          let videoEndTime = undefined;
          const transition = this.findTransitionsFor(this.sourceSlideUuid, this.targetSlideUuid);
          if (transition) {
            this.notFullTransition = false;

            // case 1: found the full transition – play video from start to end time
            const {startTime, endTime} = transition;
            videoFile = transition.videoFile;
            videoStartTime = startTime;
            videoEndTime = endTime;
          } else {
            this.notFullTransition = true;
            const transitionCandidates = this.findAllTransitionsForSlide(this.targetSlideUuid);
            if (transitionCandidates && transitionCandidates.length) {
              // case 2: found a transition for the target slide – show video and jump directly to target time
              const transition = transitionCandidates[0];
              const {startTime, endTime, inSlide} = transition;
              videoFile = transition.videoFile;
              videoStartTime = inSlide === this.targetSlideUuid ? startTime : endTime;
            } else {
              // case 3: no transition found at all – switch back to image mode
              console.log('found no exact transition')
              this.$emit('showImage');
              this.currentVideoFile = null;
              this.videoMode = false;
            }
          }
          //let newlyLoaded = false;
          // set up video and set its start frame
          if (videoFile && videoStartTime !== undefined) {
            let hasError = false;
            const index = this.urls.findIndex(item => {
              return item.fileName === videoFile;
            })
            if (index === -1) {
              this.loading = true;
            }
            await this.loadVideoFile(videoFile, this.sourceSlideUuid)
                .catch(() => {
                  hasError = true;
                  this.initialTransitionDone = true;
                  this.handlingTransition = false;
            });
            if(hasError) {
              return;
            }
            this.$emit('showVideo');
            this.videoMode = true;
            this.oldCurrentVideoFile = this.currentVideoFile;
            this.currentVideoFile = videoFile;
          }
          // set video target time (which will trigger the video playback)
          if (videoFile && videoEndTime !== undefined) {
            videoEndTime = parseFloat(videoEndTime);
          }
          const videoIndex = this.urls.findIndex(item => {
            return item.fileName === videoFile;
          })
          if(videoIndex !== -1) {
            this.urls[videoIndex].startTime = videoStartTime;
            this.urls[videoIndex].endTime = videoEndTime;
          } else {
            console.log('video not found?!')
          }
          if(videoEndTime) {
            const $this = this;
            setTimeout(() => {
              $this.shouldBePlaying = videoFile;
            }, 200)
          }
          console.log('selected videoFile: ' + videoFile + ' ' + videoStartTime + ' -> ' + videoEndTime)
          this.initialTransitionDone = true;
          this.handlingTransition = false;
          // this preloads the videos for the target slide already
          //await this.preloadVideos();
        },
        async preloadVideos() {
          for(let i = 0; i < this.jumpTargets.length; i++) {
            console.log('preloading video for: ' + this.jumpTargets[i])
            const transition = this.findTransitionsFor(this.targetSlideUuid, this.jumpTargets[i]);
            if(transition) {
              const {videoFile} = transition;
              if(videoFile) {
                await this.loadVideoFile(videoFile);
              }
            }
          }
        },
        /***
         * Sets all the non active videos to the correct state for the current slide
         * */
        setVideoTimes() {
          if(this.lowPerformanceMode) {
            return;
          }
          const transitionsFromThisSlide = this.findAllTransitionsForSlide(this.targetSlideUuid);
          const transitionsForHomeSlide = this.sourceSlideUuid !== this.homeSlide ? this.findAllTransitionsForSlide(this.homeSlide, true) : [];
          const mixed = transitionsFromThisSlide ? [...transitionsFromThisSlide, ...transitionsForHomeSlide] : transitionsForHomeSlide;
          if (this.urls.length && mixed.length) {
            const doneVideoFiles = [this.currentVideoFile];
            for (let i = 0; i < mixed.length; i++) {
              const {startTime, endTime, videoFile, inSlide} = mixed[i];
              // and we don't want to modify the same video twice
              if (doneVideoFiles.includes(videoFile)) {
                continue;
              }
              // check if video is already in the dom
              const index = this.urls.findIndex(item => {
                return item.fileName === videoFile;
              })
              if (index !== -1) {
                const targetTime = inSlide === this.targetSlideUuid ? startTime : endTime;
                console.log('---- setting time to ' + targetTime + ' for ' + videoFile)
                this.setTime(targetTime, videoFile);
                doneVideoFiles.push(videoFile);
              }
            }
          }
        },
        /**
         * Loads the video file based on file name from the project storage folder
         * @params {string} - fileName - the name of the video file e.g. videofile.mp4
         * */
        async loadVideoFile(fileName, slideId) {
          this.error = "";

          const index = this.urls.findIndex(item => {
            return item.fileName === fileName;
          })
          if (index === -1) {

            const $this = this;
            this.timeout = setTimeout(() => {
              $this.loadingPanel = true;
            }, 1000);

            return await this.fetchVideo(fileName)
                .then(blob => {
                  this.createUrl(fileName, blob, slideId);
                  clearTimeout(this.timeout);
                  this.loadingPanel = false;
                  this.loading = false;
                  return true;
                }).catch(e => {
                  console.log(e);
                  this.error = this.$t('videoFileNotFound') + ' ' + fileName;
                  this.loading = false;
                  clearTimeout(this.timeout);
                  this.currentVideoFile = null;
                  this.$emit('showImage')
                  console.log('video could not be loaded')
                  throw new Error('video could not be loaded')
                })
          } else {
            const url = this.urls[index].url;
            if (!this.checkURLValidity(url)) {
              // video file expired from browser cache
              console.log('link invalid, reloading video')
              this.urls.splice(index, 1);
              await this.loadVideoFile(fileName, slideId);
              return true;
            }
          }
          return false;
        },
        /***
         * Delivers the correct video loading method based on offline mode
         * @params {string} - fileName - the name of the video file e.g. videofile.mp4
         * @returns {blob} - the blob containing the video file
         * */
        async fetchVideo(fileName) {
          return !this.offlineMode ? await this.loadVideoFromStorage(fileName) : await this.loadVideoFromDisk(fileName);
        },
        /**
         * Loads the video from the storage api
         * @params {string} - fileName - the name of the video file e.g. videofile.mp4
         * @returns {blob} - the video as a blob
         * */
        async loadVideoFromStorage(fileName) {
          return await this.$store.dispatch('clientDownloadProject', {
            id: this.projectId,
            key: fileName
          }).then(data => {
            const mimeType = data.headers['content-type'].toLowerCase();
            return new Blob([data.text], {type: mimeType})
          })
        },
        /**
         * Loads the video from the disk folder of the webapp
         * @params {string} - fileName - the name of the video file e.g. videofile.mp4
         * @returns {blob} - the video as a blob
         * */
        async loadVideoFromDisk(fileName) {
          let thumbnail = `${window.location.pathname}vforms/${this.projectId}/${fileName}`;
          return await fetch(thumbnail)
              .then(response => {
                return response.blob()
              });
        },
        /**
         * Creates an object url to the cached file for the browser
         * @params {string} - fileName - the name of the video file e.g. videofile.mp4
         * @params {blob} - blob - the data as a blob
         * */
        createUrl(fileName, blob, slideId) {
          if(this.lowPerformanceMode && this.urls.length > 1) {
            console.log('low performance mode, removing url from stack')
            const url = this.urls[0];
            this.urls.shift();
            window.URL.revokeObjectURL(url);
          }
          this.urls.push({
            fileName,
            url: URL.createObjectURL(blob),
            slideId
          });
        },
        /**
         * Finds a transition between two slides
         * @params {uuid} - source - the slide id of the source slide
         * @params {uuid} - target - the slide id of the target slide
         * */
        findTransitionsFor(source, target) {
          if (this.videoConfig && this.videoConfig.length) {
            const transition = this.videoConfig.filter(item => {
              return item.inSlide === source && item.outSlide === target;
            });
            if (transition && transition.length) {
              return transition[0];
            }
          }
          return null;
        },
        /**
         * Finds all transitions containing a slide either as a inSlide or outSlide
         * @params {uuid} - slideId – the id of the slide
         * @returns {array}
         * */
        findAllTransitionsForSlide(slideId, inSlidesOnly = false) {
          if (this.videoConfig && this.videoConfig.length) {
            if(inSlidesOnly) {
              return this.videoConfig.filter(item => {
                return item.inSlide === slideId;
              });
            }
            return this.videoConfig.filter(item => {
              return item.inSlide === slideId || item.outSlide === slideId;
            });
          }
          return [];
        },
        /**
         * Adjusts the size of the video container based on the first loaded image
         * */
        adjustSize() {
          console.log('adjusting size...')
          if(this.splitMode) {
            this.width = 400;
            this.height = 300;
            return;
          }
          const coverImages = document.getElementsByClassName("slide-full-image");
          if(!coverImages || !coverImages.length) {
            return;
          }
          const coverImage = coverImages[0];
          const mediaRatio = coverImage.naturalWidth / coverImage.naturalHeight;
          const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
          if (mediaRatio <= containerRatio) {
            this.width = coverImage.clientWidth;
            this.height = coverImage.clientWidth * mediaRatio;
          } else {
            this.width = coverImage.clientHeight * mediaRatio;
            this.height = coverImage.clientHeight;
          }
        },
      }
    }
    </script>

    <style lang="scss" scoped>
    .video-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .bg-video {
      width: 100% !important;
      height: 100% !important;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      outline: none;
      //animation-duration: 2.5s;
      //animation-direction: forwards;
      z-index: -1;
      &.active {
        opacity: 1;
        z-index: 2;
        //animation: 200ms fadeIn forwards;
      }

      // this is the previous video...
      &.inactive {
        //animation: 10ms fadeOut forwards;
        opacity: 0;
      }

      &.reallyinactive {
        opacity: 0;
        z-index: -1;
      }

      &:focus, &:active {
        outline: none;
        border: 0;
        box-shadow: none;
      }

      // for debugging
      &.split-mode {
        width: 50% !important;
        min-width: 50%;
        height: 50% !important;
        min-height: 50%;
        top:0;
        left:0;
        transform: none;
        outline: 1px solid red;
        &.inactive {
          opacity: 1 !important;
          bottom: 0;
          right: 0;
          top: auto;
          left: auto;
        }
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .curr-time {
      position: absolute;
      bottom: 65px;
      left: 15px;
      z-index: 9005;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 3px;
      border-radius: 3px;
    }

    .video-bg-loading, .form-error {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
        z-index: 0;
      }
      to {
        opacity: 0;
        z-index: 0;
      }
    }
    </style>