<template>
  <div :class="['tag', viewStyle === 'table' ? 'd-flex w-100 long-tag' : '']"
       :style="'background-color: ' + org.color ">
    <div
        :class="['', viewStyle === 'table' ? 'col-12 p-1 mr-3 d-flex align-items-center' : 'organization-row pl-2 pr-2 mb-3']">
      <div class="orgname">
        <icon v-if="Number(org.visibility) !== 0" class="mr-2 float-left" size="1.1" type="building"/>
        <icon v-else class="mr-2 float-none float-xl-left" size="1.1" type="user"/>
        <b>{{ org.displayName ? org.displayName : org.name }}</b>
      </div>
      <!--AppPanel-->
      <div v-if="showAppPanel" :class="['align-self-end app-panel-field', viewStyle === 'table' ? 'p-1' : 'mt-3']">
        <label-with-edit-icon-and-content
            :editable="!appPanelEditing"
            :make-placeholder-space="false"
            :value="appPanel"
            label="appPanel URL"
            @edit="() => {appPanelEditing = true;}"
        />
        <div v-if="appPanelEditing" :class="['ml-0', viewStyle === 'table' ? 'col-12 p-1 row' : 'mt-1']">
          <div class="mb-2">
            <span class="lighter">{{ $t('select') }}</span>
            <universal-selector
                :limit-by-organization-id="org.id"
                include="instances"
                list-name="apps"
                store-name="App"
                @addItem="setAppPanel"
            />
          </div>
          <div class="w-100"/>

          <div class="edit-trigger mr-2" @click="() => { appPanelEditing = false }">
            <icon :type="'times'"/>
          </div>
          <div class="edit-trigger mr-2" @click="update('appPanel'); appPanelEditing = false;">
            <icon :type="'save'"/>
          </div>
          <div class="edit-trigger" @click="appPanel = ''; update('appPanel'); appPanelEditing = false;">
            <icon :type="'trash-alt'"/>
          </div>
        </div>
      </div>
      <!--/AppPanel-->
      <!--Role-->
      <div class="ml-3">
        <div class="mt-2 mr-1 lighter">{{ $t('Role') }}:</div>
        <div v-if="isEditable && !lock" class="w-100"></div>
        <select v-if="isEditable && !lock" v-model="role" class="custom-select align-self-center"
                @change="update('role')">
          <option v-for="(role, value) in possibleRoles" :key="role" :value="role">{{ value }}</option>
        </select>
        <span v-else>{{ role }}</span>
      </div>
      <!--/Role-->
      <div v-if="showDetails && isDefault && !isEditable" class="ml-3 p-2 default-org">
        <b>{{ $t('forms.default') }}</b>
      </div>
      <!--Default-->
      <div v-if="isEditable && !lock" class="ml-3">
        <div class="lighter">{{ $t('forms.default') }}:</div>
        <div class="custom-checkbox border-radius" @click="() => {this.default = !this.default; update('default')}">
          <div ref="checkbox" :class="['custom-checkbox border-radius', isDefault ? 'active' : '' ]" type="checkbox"
          >
            <div class="inner">
              <icon size="0.8" type="check"/>
            </div>
          </div>
        </div>
      </div>
      <!--/Default-->
    </div>
    <div v-if="isRemovable && !isDefault" class="icon-right" @click="$emit('removeorg', org)">
      <icon size="1" type="timesCircle"/>
    </div>


  </div>
</template>

<script>
import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
import Icon from "../Icon"
import {UserRoles, AdminData} from '../../enum';
import UniversalSelector from "@/components/organizations/UniversalSelector";
import {filterByAppPanelOnly, filterByCCLightOnly} from "@/utils";

export default {
  name: "Org",
  components: {
    Icon,
    LabelWithEditIconAndContent,
    UniversalSelector
  },
  props: {
    org: {type: Object, required: true},
    isRemovable: {type: Boolean, default: true},
    iconLeft: {type: String, default: "faHome"},
    isEditable: {type: Boolean, default: false},
    showDetails: {type: Boolean, default: true},
    isDefault: {type: Boolean, default: false},
    viewStyle: {type: String, default: "thumbnails"},
    showAppPanel: {type: Boolean, default: true},
    acceptNoneAsRole: {type: Boolean, default: true},
  },
  data() {
    if (this.showDetails) {
      return {
        role: '',
        possibleRoles: {},
        lock: false,
        appPanel: '',
        appPanelEditing: false,
        default: false,
        appPanelsOnly: filterByAppPanelOnly,
        ccLightOnly: filterByCCLightOnly
      };
    }
    return {
      lock: false,
      appPanel: '',
      appPanelEditing: false,
      default: false,
    };
  },
  beforeMount() {
    if (this.org.id === AdminData.vHubOrganizationId) {
      this.lock = true;
    }
    this.possibleRoles = JSON.parse(JSON.stringify(UserRoles));
    if (!this.acceptNoneAsRole) {
      delete this.possibleRoles['None'];
    }
    this.role = this.org.members && this.org.members[0] && this.org.members[0].relation ? this.org.members[0].relation.role : (this.org.role ? this.org.role : 'viewer');
    this.appPanel = this.org.members && this.org.members[0].relation && this.org.members[0].relation.appPanel ? this.org.members[0].relation.appPanel : '';
    this.default = this.org.members && this.org.members[0] && this.org.members[0].relation.default ? this.org.members[0].relation.default : false;
  },
  methods: {
    setAppPanel(item) {
      fetch(process.env.VUE_APP_CONFIG)
          .then(r => r.json())
          .then(async config => {
            const baseURL = config.sfx.project.webapp.appHost;
            this.appPanel = baseURL + '/' + item.slug;
            const res = filterByAppPanelOnly(item);
            if(!res) {
              this.appPanel = baseURL + '/' + item.slug + '/#/app-overview';
            }
            this.update('appPanel');
            this.appPanelEditing = false;
          });
    },
    update(field) {
      let member = {};
      member[field] = this[field];
      if (field === 'default') {
        this.$emit('setDefaultItem', this.org.id);
      } else {
        this.$emit('update', this.org.id, member);
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.app-panel-field {
  width: 220px;
}

.tag {
  background-color: $tag-background-color;
  display: inline-block;
  padding: 3px 35px 3px 8px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  word-break: break-all;

  .icon-right {
    position: absolute;
    right: 10px;
    top: 15px;
    opacity: 0.8;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .tag-details {
    background-color: darken($tag-background-color, 10%);
    padding: 3px;
    width: 100%;
    margin-top: 6px;
  }
}

.organization-row {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 1px;
}

.long-tag {
  margin-right: 0;
}

.orgname {
  width: 120px;

}
</style>
