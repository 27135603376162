import Vue from 'vue';
import Vuex from 'vuex';
import projects from './projects';
import scenes from './scenes';
import client from './client';
import notifications from './notifications';
import apps from './apps';
import datasetrows from "./datasetrows";
import connections from "./connections";
import page from "./page";
import tag from "./tag";
import metaset from "./metaset";
import users from "./users";
import organization from "./organization";
import permissions from "./permissions";
import metafield from "./metafield";
import instances from "./instances";
import text from "./text";
import report from "./report";
import formpage from "./vformpage";
import dynamicStore from "@/store/dynamicStore";
import exportprofile from "./exportprofile";
import auth from "./identityProviders/auth";
import identityProvider from './identityProviders/identityProvider';

Vue.use(Vuex);

const combineIntoSingleObject = (object) => {
    return Object.values(object).reduce((acc, item) => {
        acc[Object.keys(item)[0]] = Object.values(item)[0];
        return acc;
    }, {});
};
const combineFilters = (object) => {
    let first = true;
    return Object.values(object).reduce((acc, item) => {
        acc += first ? '' : ', ';
        acc += item;
        first = false;
        return acc;
    }, '');
};
const combineOverwrite = (object) => {
    if(typeof object === 'string') {
        return object;
    }
    let values = Object.values(object);
    return values[values.length-1];
};

let store =  new Vuex.Store({
    // eslint-disable-next-line no-undef
    strict: process.env.NODE_ENV !== 'production',
    state: {},
    modules: {
        identityProvider,
        auth,
        dynamicStore,
        projects,
        client,
        notifications,
        apps,
        scenes,
        datasetrows,
        connections,
        page,
        tag,
        metaset,
        users,
        organization,
        permissions,
        metafield,
        instances,
        text,
        report,
        formpage,
        exportprofile,
        Vue
    },
    getters: {},
    mutations: {
        /*
        * Merges a set of new query params into those of any given store
        * */
        queryParamsSave(context, data) {
            /*
            removes selected params from the "queryParams"-State of the corresponding store
            example of data.params {remove: {
                     filter: {inputID}
                  }}
             */

            //adds the default Query params to saved params
            let targetStore = context[data.storeType].queryParams;
            let params = data.params;

            if(!targetStore[data.listName]) {
                Vue.set(targetStore, data.listName, {});
                let obj = context[data.storeType].defaultQueryParams;
                Object.keys(obj).forEach(paramType => {
                    let paramItem = obj[paramType];
                    Object.keys(paramItem).forEach(inputID => {
                        if(!targetStore[data.listName][paramType]) {
                            Vue.set(targetStore[data.listName], [paramType], {});
                        }
                        if(!targetStore[data.listName][paramType][inputID]) {
                          Vue.set(targetStore[data.listName][paramType], inputID, paramItem[inputID]);
                        }
                    });
                });
            }

            if('remove' in params) {
                let obj = params['remove'];
                Object.keys(obj).forEach(paramType => {
                    let paramItem = obj[paramType];
                    Object.keys(paramItem).forEach(inputID => {
                        if(targetStore[data.listName][paramType]) {
                            Vue.delete(targetStore[data.listName][paramType], paramItem[inputID]);
                        }
                    });
                });
            }

            /*
            adds any new params to the "queryParams"-State of the corresponding store
            example of data.params
            {add: {filter: {[inputID]: 'name like %'+ filterValue +'%'}}}
             */
            if('add' in params) {
                let obj = params['add'];
                Object.keys(obj).forEach(paramType => {
                    let paramItem = obj[paramType];
                    Object.keys(paramItem).forEach(inputID => {
                        if(!targetStore[data.listName][paramType]) {
                            Vue.set(targetStore[data.listName], paramType, {});
                        }
                        Vue.set(targetStore[data.listName][paramType], inputID, paramItem[inputID]);
                    });
                });
            }

            if('addObject' in params) {
                let obj = params['addObject'];
                Object.keys(obj).forEach(key => {
                    if(!targetStore[data.listName][key]) {
                        Vue.set(targetStore[data.listName], key, {});
                    }
                    Vue.set(targetStore[data.listName][key], Object.keys(obj[key])[0], obj[key][Object.keys(obj[key])[0]]);
                });
            }

            /*
            * This is for special values that never change (like an id for assets per project)
            * attention: they are flat and have no inputID because they won't be removed or added:
            * {keep: {id: 'idvalue'}}
            * */
            if('keep' in params) {
                let obj = params['keep'];
                Object.keys(obj).forEach(paramType => {
                    let paramValue = obj[paramType];
                    Vue.set(targetStore[data.listName], paramType, paramValue);
                });
            }
        },
        combineQueryParams(context, data) {
            let params = context[data.storeType].queryParams[data.listName];
            let queryObj = {};

            Object.keys(params).forEach(paramType => { //paramType = z.B. Filter
                let paramItem = params[paramType];
                if(Object.keys(paramItem).length > 0) {
                    switch(paramType) {
                        case 'filter':
                            queryObj[paramType] = combineFilters(paramItem);
                            break;
                        case 'textSearch':
                            queryObj[paramType] = combineIntoSingleObject(paramItem);
                            break;
                        case 'numSearch':
                            queryObj[paramType] = combineIntoSingleObject(paramItem);
                            break;
                        case 'dateSearch':
                            queryObj[paramType] = combineIntoSingleObject(paramItem);
                            break;
                        default:
                            queryObj[paramType] = combineOverwrite(paramItem);
                            break;
                    }
                }
            });
            // in a second round we combine textSearch/numSearch/dateSearch like this:
            // dataFilter: {textSearch: {}, numSearch: {}}
            // and remove the original keys
            const dataFilterKeys = Object.keys(queryObj).filter(key => {return ['textSearch', 'textSearchOptions', 'numSearch', 'dateSearch'].includes(key)});
            if(dataFilterKeys.length) {
                queryObj.dataFilter = '';
                const obj = {};
                for(let i = 0; i < dataFilterKeys.length; i++) {
                    const key = dataFilterKeys[i];
                    obj[key] = queryObj[key];
                    delete queryObj[key];
                }
                queryObj.dataFilter = JSON.stringify(obj);
            }

            /*Object.keys(params).forEach(paramType => {
                let paramItem = params[paramType];
                if(Object.keys(paramItem).length > 0) {
                    if(['textSearch', 'numSearch', 'dateSearch'].includes(paramType)) {

                    }
                }
            });*/
            Vue.set(context[data.storeType].combinedQueryParams, data.listName, queryObj);
            return queryObj;
        },
    },

    actions: {
        submitQueryParams: function({commit}, args) {
            commit('queryParamsSave', args);
        },
        loadProjectFiles: async ({dispatch}, args = {}) => {
            return dispatch('clientListProjectParts', args);
        },
        loadAssetFiles: async ({dispatch}, args = {}) => {
            return dispatch('clientListAssetParts', args);
        },
        loadInstanceFiles: async ({dispatch}, args = {}) => {
            return dispatch('clientListInstanceParts', args);
        },
        deleteAllCookies: function() {
            const cookies = document.cookie.split(";");
        
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }
    },
});

export default store;