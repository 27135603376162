<template>
    <div>
      <div class="d-flex mb-2 darker p-2 col-12 border-radius">
        <div class="mr-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-2" type="grip-vertical" />{{ $t('vertices') }}</div> {{ logEntry ? logEntry.vertices : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-1" type="cube" />{{ $t('geometries') }}</div> {{ logEntry ? logEntry.geometries : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-highlight text-right"><div class="lighter"><icon class="mr-1" type="cubes" />{{ $t('groups') }}</div> {{ logEntry ? logEntry.groups : '-'}}</div>
        <div class="mr-1 ml-1 p-2 bg-darker text-right"><div class="lighter mr-1">{{ $t('TotalTime') }}:</div>{{ logEntry && logEntry.startedAt ? timeDifferenceFromTwoISOs(logEntry.stoppedAt ? logEntry.stoppedAt : new Date(), logEntry.startedAt).string : '-'}}</div>
       <div class="ml-1 p-2 bg-darker text-right"><div class="lighter">{{ $t('VMTime') }}:</div> {{ logEntry && logEntry.vmStoppedAt ? timeDifferenceFromTwoISOs(logEntry.vmStoppedAt, logEntry.vmStartedAt).string : '-'}}</div>
      </div>
      <div v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember" class="d-flex mb-2 darker p-2 col-12 border-radius">
        {{ predictConversionTime(logEntry.vertices, logEntry.groups, logEntry.geometries) }}
      </div>
      <div class="" v-if="$store.getters.isSuperAdmin && false">
        {{ $t('original') }}: {{ logEntry.sourceFileSizeInMB.toFixed(2) }} MB | {{ $t('result') }}: {{ logEntry.convertedFileSizeInMB.toFixed(2) }} MB
      </div>

        <div class="mb-2" v-if="logEntry">
          <div :class="['border-radius', isDone(key) ? 'log-task log-task-done' : 'log-task log-task-not-done']" :key="index" v-for="(key, value, index) in PipelineSimplifiedExtractSteps">
            <icon size="0.9" :type="isDone(key) ? 'check' : (getTask(key) ? 'spinner' : 'times')" /> {{ $t(value) }} <div v-if="getTask(key)" class="ml-4 inline-block col-3 dz-progress bg-beige float-right"><div :style="{'width': getProgress(key) + '%'}" class="dz-upload"><p>{{ getProgress(key) }}%</p></div></div>
            <p class="smaller ml-4">{{ getDuration(key) }}<span v-if="getTask(key) && getTask(key).totalItems"> | {{ getTask(key).totalItems }} items</span></p>
          </div>
        </div>
      <!--<pre style="color: #fff">{{ logEntry.stats }}</pre>-->
      </div>
</template>

<script>
import { PipelineSimplifiedExtractSteps } from "@/enum";
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
export default {
  name: "LogEntryProgress",
  components: {
    Icon
  },
  mixins: [DateMixin],
  props: {
    id: {type: String, required: true},
    isUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      logEntry: {},
      PipelineSimplifiedExtractSteps: PipelineSimplifiedExtractSteps,
      killSwitch: false,
    };
  },
  beforeMount() {
    this.startUpdatingLogEntry();
  },
  beforeDestroy() {
    this.killSwitch = true;
  },
  methods: {
    predictConversionTime(vertices, groups, geometries) {
      if(!vertices || !groups || !geometries) {
        return;
      }
      /*console.log(vertices)
      console.log(groups)
      console.log(geometries)
      const verticesCoefficient = 2.042e-5;
      const groupsCoefficient = 0.3236;
      const geometriesCoefficient = 0.01735;
      const intercept = -53.16819076489077;

      let predictedTime = (verticesCoefficient * vertices) +
          (groupsCoefficient * groups) +
          (geometriesCoefficient * geometries) +
          intercept;

      //predictedTime = predictedTime * 2;

      return predictedTime < 0 ? 'just a few minutes' : (predictedTime > 60 ? (predictedTime / 60).toFixed(1) + ' hours' : predictedTime.toFixed(1) + ' minutes');*/
      let time1 = this.predictConversionTimeForGeometries(geometries, vertices);
      let time2 = this.predictConversionTimeForGroups(groups, vertices);
      const total = time1 + time2;
      // todo: gib die Zeiten einzeln aus, wenn sie unter 10min sind, dann sind sie vermutlich negativ
      if(total < 60) {
        return total.toFixed(1) + ' minutes'
      } else {
        return (total / 60).toFixed(1) + ' hours';
      }
    },
    predictConversionTimeForGroups(groups, vertices) {
      const groupsCoefficient = 0.3236;
      const verticesCoefficient = 2.042e-5;
      const intercept = -53.16819076489077;

      const predictedTimeForGroups = (groupsCoefficient * groups) + (verticesCoefficient * vertices) + intercept;

      return predictedTimeForGroups;
    },
    predictConversionTimeForGeometries(geometries, vertices) {
      const geometriesCoefficient = 0.01735;
      const verticesCoefficient = 2.042e-5;
      const intercept = -53.16819076489077;

      const predictedTimeForGeometries = (geometriesCoefficient * geometries) + (verticesCoefficient * vertices) + intercept;

      return predictedTimeForGeometries;
    },
    getDuration(taskName) {
      const task = this.getTask(taskName);
      if(task && task.createdAt && task.updatedAt && task.createdAt !== task.updatedAt) {
        return this.timeDifferenceFromTwoISOs(task.updatedAt, task.createdAt).string;
      }
    },
    getProgress(taskName) {
      const task = this.getTask(taskName);
      if(task) {
        const progress = task.progress && typeof(parseFloat(task.progress)) === 'number'? parseFloat(task.progress) : 0;
        return (progress * 100.0).toFixed(1);
      } else {
        return 0;
      }
    },
    getTask(taskName) {
      if(this.logEntry.stats) {
        const item = this.logEntry.stats.filter(item=> {return item.taskName === taskName});
        if(item[0]) {
          return item[0];
        }
      }
      return null;
    },
    isDone(taskName) {
      const task = this.getTask(taskName);
      return !!task && task.progress && parseFloat(task.progress) === 1.0;
    },
    async startUpdatingLogEntry() {
      if(this.killSwitch) {
        return;
      }
      const $this = this;
      this.$store.dispatch('clientLoadVMLogs', {filter: `objectId eq ${this.$route.params.id}`, sort: '-createdAt', limit: 1})
      .then(async data => {
        if(data && data[0]) {
          if(this.isUpdate) {
            // if it is an update it tends to display the last vmlog progress instead of waiting for the current one
            if(!data[0].stoppedAt) {
              this.logEntry = data[0];
            }
          } else {
            this.logEntry = data[0];
          }
        }
        setTimeout(async () => {
          await $this.startUpdatingLogEntry()
        }, 30000);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .log-task {
    background-color: var(--vform-editor-ui-secondary-color);
    padding:3px 6px;
    margin-bottom:1px;
    border: 1px solid rgba(255,255,255,0.1);
  }
  .log-task-not-done {
    background-color: var(--vform-editor-ui-quinary-color);
  }
  .dz-progress {
    position:relative;
    width: 440px;
    padding:0;
    height:15px;
    margin-top:2px;
    margin-bottom: -2px;
    .dz-upload {
      background-color: $highlight;
      height:15px;
      p {
        position:absolute;
        left:5px;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
</style>