<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      :type="$route.params.type"
      :tab-selector="$route.params.type + 'tab'"
      :back-button-tab-selector="$route.params.type + 'backbutton'"
  >
    <template slot="mainContent">
      <h1>{{ $store.getters['get' + $route.params.type.charAt(0).toUpperCase() + $route.params.type.substring(1, $route.params.type.length) + 'Name']($route.params.id) }}</h1>
      <version-browser
          class="mt-4 mr-3"
        :type="contentTypes[$route.params.type]"
        :subtype="$route.params.type"
        :id="$route.params.id"
      />
    </template>
  </one-column-sidebar-left>
</template>

<script>
import OneColumnSidebarLeft from "@/layouts/OneColumnSidebarLeft";
import VersionBrowser from "@/components/versions/VersionBrowser";
import {contentTypes} from "@/enum";
export default {
  name: "FileBrowserView",
  components: {
    OneColumnSidebarLeft,
    VersionBrowser
  },
  data() {
    return {
      contentTypes: contentTypes,
    };
  },
  beforeMount() {
    //console.log(contentTypes[this.$route.params.type]);
  },
  methods: {

  }
}
</script>

<style scoped>

</style>