<template>
  <div :class="['tag drop-shadow mb-1', clickable ? 'clickable' : '', isRemovable ? 'removable' : '']"
       :style="{backgroundColor: tag.color, color: fontColor }">
    <icon v-if="iconLeft" :type="iconLeft" class="mr-1" size="0.9"/>
    <span v-if="name || tag[nameProperty] || tag.name">{{
        name ? name : (tag[nameProperty] ? tag[nameProperty] : tag.name)
      }}
        </span>
    <p v-if="organizationId" class="lighter smaller">({{
        $store.getters.getOrganizationDisplayName(organizationId)
      }})</p>
    <slot></slot>
    <div v-if="isRemovable" class="icon-right" @click="$emit('removeTag', tag)">
      <icon size="1" type="timesCircle"/>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon"
import ColorContrastMixinJs from "@/components/mixins/ColorContrastMixin.js.vue";

export default {
  name: "Tag",
  mixins: [ColorContrastMixinJs],
  components: {
    Icon
  },
  props: {
    tag: {type: Object, required: true},
    clickable: {type: Boolean, default: true},
    isRemovable: {type: Boolean, default: true},
    iconLeft: {type: String, default: 'tag'},
    nameProperty: {type: String, default: 'name'},
    name: {type: String, default: ''},
    organizationId: {type: String, default: ''},
  },
  data() {
    return {
      fontColor: ""
    };
  },
  beforeMount() {
    this.fontColor = this.isBrightColor(this.tag.color) ? '#000' : '#fff';
  },
}
</script>