<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                       @filter="filtering"
                        :id="listName"
                        store-name="Qrcode"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getQrcodeListOptions(listName, 'pagination_items')"
                :id="'sfxtablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="create"
                type="action-button"
                slot="mainContentButtons"
                link="/library/qrcodes/add"
                icon="plus">
            {{ $t('createNewQrCode') }}
        </Button>

      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'sfxdataDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                @deleteRows="deleteRows"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                :permission-by-type="{'model': 'write', 'node': 'write', 'assembly':'createMetaSet'}"
                delete-permission="delete"
                :fields="tableFields"
                :data="$store.getters.getQrcodesByListName(listName)"
                :title-as-links="true"
                :use-team-permission="true"
                :show-state="$store.getters.isSuperAdmin"
                :loading="loading"
                :initial-sorting="sortObject"
                @sort="sort"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'sfxdataDefaultThumbnails'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="thumbnailFields"
                :data="$store.getters.getQrcodesByListName(listName)"
                :title-as-links="true"
                :permission-by-type="{'model': 'write', 'node': 'write', 'assembly':'createMetaSet'}"
                :show-state="$store.getters.isSuperAdmin"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

    export default {
        name: "QrCodes",
        mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
              egisterMethod: 'registerQrcodeQueryParams',
              loadMethod: 'loadQrcodes',
              include: 'tags,teams',
              deleteMethod: 'deleteQrcode',

                listName: 'QrcodeList',

                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
    }
</script>