<template>
    <filter-section :id="id">
        <template slot="name">{{ $t('filter.tagFilter') }}</template>
        <template slot="content">
            <tag @removeTag="removeTag" v-for="(value, index) in tags" :key="index" :tag="value" />

            <tag-input
                    class="mt-1"
                    @addTag="addTag"
                    :exclude-from-tag-list="tags"
                    :creating-tags-allowed="false"
                    :exclude-v-h-u-b-organization="!$store.getters.isSuperAdmin"
            />
        </template>
    </filter-section>
</template>

<script>
    import TagInput from "../../forms/TagInput";
    import Tag from "../../Tag";
    import FilterSection from "./FilterSection";
    export default {
        name: "TagFilter",
        components: {
            TagInput,
            Tag,
            FilterSection,
        },
        props: {

            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0},
            collapsible: {type: Boolean, default: false},
        },
        data() {
            return {
                tags: [],
            };
        },
        computed: {
            getId: function() {
                return this.$route.name + this.id  + 'TextFilter';
            }
        },
        watch: {
            clear() {
                this.clearValue();
            },
        },
        beforeMount() {
            let tags = JSON.parse(window.localStorage.getItem(this.getId));
            if(tags) {
                this.tags = tags;
            }
        },
        mounted() {
            this.filter(true);
        },
        methods: {
            clearValue() {
                this.tags = [];
                this.filter(true);
            },
            removeTag(tag) {
                this.tags = this.tags.filter(item => {return item.id !== tag.id});
                this.filter();
            },
            addTag(tag) {
                this.tags.push(tag);
                this.filter();
            },
            filter(initial = false) {
                const filterParamId = this.getId;
                let params = {};

                if (this.tags && this.tags.length > 0) {
                    let filterString = '';
                    for (let i = 0; i < this.tags.length; i++) {
                        filterString += this.tags[i].name;
                        if(i !== this.tags.length - 1) {
                            filterString += ',';
                        }
                    }

                    params = {
                        add: {
                            tags: {
                                [filterParamId]: filterString
                            },
                            default: {
                                limit: 400,
                            },
                        }
                    };
                } else {
                    params = {
                        remove: {
                            tags: {filterParamId}
                        }
                    };
                }
                this.$emit('filter', params, initial, 'tags');
                window.localStorage.setItem(this.getId,  JSON.stringify(this.tags));
            },
        },
    }
</script>