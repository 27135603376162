<template>
    <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      back-button-tab-selector="servicebackbutton"
      tab-selector="servicetab"
    >
        <div slot="buttonsRight">
            <Button
                    type="button"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>
        <div slot="mainContent">
            <service-add-form
            ref="serviceAddForm"
            @enableSaving="doEnableSaving"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";

    export default {
        name: "ServiceAdd",
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
            ServiceAddForm: () => import("@/components/ServiceAddForm"),
        },
        data() {
            return {
                enableSaving: false,
            };
        },
        methods: {
            doEnableSaving: function(bool){
                this.enableSaving = bool;
            },
            save: function() {
                this.$refs.serviceAddForm.save();
            },
        }
    }
</script>