<template>
  <two-column-layout
      :id="$route.params.id"
      back-button-tab-selector="mediabackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="mediatab"
      type="media"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        :id="$route.params.id"
        slot="sidebarLeft"
        :created="$store.getters.getMediaCreationDate(id)"
        :downloadable="true"
        :last-change="$store.getters.getMediaChangeDate(id)"
        :organization="$store.getters.getMediaOrganization($route.params.id)"
        :upload-type="'Asset'"
        preview-id="mediaEdit"
    />
    <info-panel
        :id="$route.params.id"
        :key="'infopanel2'"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getMediaOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getMediaTags($route.params.id)"
        preview-id="mediaEdit"
        @addTag="addTag"
        @removeTag="removeTag"
    />
    <team-attacher
        v-if="$store.getters.getMediaOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getMediaOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

    <div slot="mainContent">
      <name-display-edit-block
          :id="$route.params.id"
          :icon="$store.getters.getMediaMediaCategoryByName($route.params.id)"
          :organization-id="$store.getters.getMediaOrganization($route.params.id)"
          :name="$store.getters.getMediaName(id)"
          :display-name="$store.getters.getMediaDisplayName(id)"
          store-name="Media" />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getMediaDescription(id)"
          store-name="Media"
      />
      <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
        <state-setter
            :given-state="$store.getters.getMediaState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="Media"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <h2 class="mt-5">{{ $t('mediaEditFile') }}</h2>
          <p>{{ $t('uploadDisclaimer') }}</p>
          <div class="row">
            <upload-drop-zone
                :asset-id="$route.params.id"
                :downloadable="true"
                :make-chunks="true"
                :preview-uri="$store.getters.getMediaPreviewUri($route.params.id)"
                :removable="true"
                class="col-12 col-md-8 mb-5 mt-4"
                form-id="uploadMedia"
                target-type="Asset"
            />
          </div>
        </div>
        <div class="w-100"/>
        <div class="col-12 col-md-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getMediaMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadMedia"
                @removed="loadMedia"
            />
          </info-panel>
        </div>
        <meta-panel
            v-for="(item) in $store.getters.getMediaMetaSets(id)"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getMediaOrganization(id)]"
            :title="item.description"
            class="col-12 col-md-6"
            icon-left="folder"
            store-name="Media"
            @updated="loadMedia"
        />
      </div>
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import UploadDropZone from "../components/files/UploadDropZone";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import {mediaCategories} from '@/enum';
import NameDisplayEditBlock from "@/components/NameDisplayEditBlock.vue";

export default {
  name: 'MediaEdit',
  components: {
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentDescriptionBlock,
    UploadDropZone,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    NameDisplayEditBlock
  },
  data() {
    return {
      id: '',
      teams: [],
    };
  },
  beforeMount() {
    this.loadMedia();
    this.id = this.$route.params.id;
    this.loadAssetTeams();
  },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadMedia() {
      this.$store.dispatch('loadMedia', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).then(media => {
        // todo: this is a temporary fix, find a permanent solution for type specific edit links
        if (media && media.mediaCategory === mediaCategories.font) {
          this.$router.push('/library/font/' + this.$route.params.id + '/general')
        }
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>