<template>
    <div class="row darker p-4 border-radius">
      <div class="col-12">
        <h3>Internal Section</h3>
        <span class="lighter">{{ $t('status') }}:</span> {{ $t(content.pipeline.status) }}
      </div>

      <div class="col-12">
        <div class="form-item mt-3">
          <div class="dflex ml-2">
            <label class="container" :for="'deleteExistingAssemblyPartsLocal' + $vnode.key"
            >{{ $t("Delete previous imported parts") }} <info-helper class="ml-1 d-inline" text="Es werden sowohl die Dateien als auch die Assets und Instanzen gelöscht – nur verwenden, wenn man sicher ist"/>

              <input
                  type="checkbox"
                  :id="'deleteExistingAssemblyPartsLocal' + $vnode.key"
                  v-model="deleteExistingAssemblyPartsLocal"
                  @change="$emit('setDeleteExisting', deleteExistingAssemblyPartsLocal)"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="dflex ml-2">
            <label class="container" :for="'sendMailsLocal' + $vnode.key"
            >{{ $t("Send mails to clients") }} <info-helper class="ml-1 d-inline"
                                                            text="Emails werden nur beim Extract-Prozess versendet, nicht beim Reimport"/>
              <input
                  type="checkbox"
                  :id="'sendMailsLocal' + $vnode.key"
                  v-model="sendMailsLocal"
                  @change="$emit('setSendMails', sendMailsLocal)"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="form-item mt-3 mb-3">
          <vm-selector :key="'lower'" :show-prices="false" @selected="(val) => {$emit('setVM', val)}"/>

        </div>
        <div>
          Reimport only works if the item is fully extracted on the vm
          <div class="settings-button d-inline-block mr-1"
               @click="$emit('reImport')">
            {{ $t('reImport') }}
            <icon type="recycle"/>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
import InfoHelper from "../InfoHelper";
import Icon from "../Icon";
import VmSelector from "../vmSelector";
export default {
  name: "ImportAdvancedSection",
  components: {InfoHelper,Icon, VmSelector},
  props: {
    content: {type: Object, default: () => {return {}}},
    deleteExistingAssemblyParts: {type: Boolean, default: false},
    sendMails: {type: Boolean, default: true},
    vm: {type: String, default: ""}
  },
  beforeMount() {
    this.deleteExistingAssemblyPartsLocal = this.deleteExistingAssemblyParts;
    this.sendMailsLocal = this.sendMails;
    this.vmLocal = this.vm;
  },
  data() {
    return {
      deleteExistingAssemblyPartsLocal: false,
      sendMailsLocal: true,
      vmLocal: "",
    };
  },
}
</script>

<style scoped>

</style>