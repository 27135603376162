<template>
  <div>
      <!--<global-message
        id="message-2"
        message="<h2>Wichtige Mitteilung</h2>Vom <b>Fr, 6. Mai 18:00 bis So, 8. Mai 23:59 Uhr</b><br /> wird der Server aufgrund Wartungsarbeiten nicht erreichbar sein.<br />Dies betrifft den Zugriff via vSTAGE als auch der Zugang über vhub.ch.<br /><br /><h2>Important notice</h2>From <b>Fri, 6 May 18:00 to Sun, 8 May 23:59</b><br />the server will be down due to maintenance.<br />This affects the access via vSTAGE as well as the access via vhub.ch.
        "
      />-->

      <div class="connect-info p-3 border-radius" v-if="$store.getters.getConnectStatus === 'error'">
        {{ $t('ConnectionError') }}
      </div>
      <div class="connect-info p-3 border-radius" v-if="$store.getters.getConnectStatus === 'waiting'">
        {{ $t('ConnectionWaiting') }}
      </div>
      <div v-if="loading">
        <div class="loading-screen">
          <loading-spinner class="white" />
        </div>
      </div>
      <div :class="['app-inner', environment]" v-else-if="!$store.getters.getCurrentUserId && $route.meta.singlePage">
        <a id="main-logo" href="/"><logo class="main ml-5 pl-3 mt-5" /></a>
        <component :is="$route.meta.component" />
      </div>
      <div :class="['app-inner', environment]" v-else-if="$store.getters.getCurrentUserId">
        <navbar v-if="!$store.getters.hideNav" :show-main-nav="$store.getters.getCurrentUserGDPR" />
        <div v-if="!$store.getters.hideNav" class="container-fluid subtitle-container">
          <div class="row">
            <div class="col-3 col-md-2 col-lg-2 subtitle-inner">
              <div class="page-title-container">
                <div class="page-title">
                  <span class="title">{{ $t('nav.' + $route.meta.pagetitle) }} <icon :type="$route.meta.icon" /></span>
                </div>
                <div class="spickel"/>
              </div>
            </div>
          </div>
        </div>
        <notification/>
        <div v-if="!$store.getters.hideNav" :class="['environment', environment]"><div class="text">{{ environment }}</div></div>
        <slot v-if="globalOrganizations && Object.keys(globalOrganizations).length && userId" /><!--this is where the router view goes-->
        <div v-else class="page-placeholder" />
        <page-footer v-if="!$store.getters.hideNav" />
      </div>

      <div class="app-inner" v-else>
        <!--<language-switcher class="language-switch-front" />-->
        <login />
      </div>
    </div>
</template>

<script>
import Login from '../views/Login';
import Navbar from './navigation/Navbar';
import Notification from './Notification';
import {mapState} from 'vuex';
import Icon from "./Icon";
import PageFooter from "./PageFooter";
import LoadingSpinner from "./LoadingSpinner";
import GDPR from "@/views/GDPR";
import SAAS_AGB from "@/views/SAAS_AGB";
import SAAS_SERVICES from "@/views/SAAS_SERVICES";
import Logo from "@/assets/svgs/Logo";
//import GlobalMessage from "@/components/GlobalMessage";

export default {
  name: "Page",
  components: {
    Login,
    Navbar,
    Notification,
    Icon,
    PageFooter,
    LoadingSpinner,
    GDPR,
    SAAS_AGB,
    SAAS_SERVICES,
    Logo,
    //GlobalMessage
  },
  data() {
    return {
      tempURL: '',
      environment: '',
      userId: null,
      hasOrgs: false,
    };
  },
  computed:
      mapState({
        loading: state => state.users.loading,
        currentUserId: state => state.auth.currentUserId,
        globalUser: state => state.users.globalUser,
        globalOrganizations: state => state.organization.globalOrganizations,
        globalTeams: state => state.dynamicStore.globalTeams,
      }),
  watch: {
    '$route.path': function () {
      // this.sendBackToLoginPageIfNecessary();
    },
    globalUser(user) {
      if(!this.userId && user.id) {
        this.userId = user.id;
        this.redirectTo();
        this.loadOrganizations()
          .then(() =>{
            return this.loadTeams();
          });
      }
    },
    currentUserId(id) {
      if(this.userId && id === null) {
        // null means logout
        this.userId = null;
      }
    },
  },
  created: function () {
    // this creates problem when editing data in an input field, losing focus and hitting the delete button
    //window.addEventListener('keyup', this.historyBack)
  },
  beforeMount() {
    this.$store.dispatch('checkFilterSettings');
    this.$store.dispatch('checkPageViewSwitcher', {routeName: this.$route.name, id: 'mainContent'});
    this.doLogin();
  },
  methods: {
    async doLogin() {
      await this.$store.dispatch('checkLogin');
    },
    async checkUserDashboardRedirect() {
      if(this.globalUser && this.globalUser.homeOrganizationId) {
        const {homeOrganizationId} = this.globalUser;
        const res = await this.$store.dispatch('checkIfService', {
          organizationIds: [homeOrganizationId],
          serviceName: 'organization_dashboard'
        });
        if(!res) {
          return;
        }
        const orgs = this.globalUser.organizations.filter(item => {return item.id === homeOrganizationId})
        const {members} = orgs[0];
        if(members && members.role === 'admin') {
          return orgs[0].id;
        }
      }
      return;
    },
    /**
    * Redirects user to gdpr page if not accepted yet
    * to the originally selected path, if path was not front page
    * or to the project page
    * */
    async redirectTo() {
      if(!this.$store.getters.getCurrentUserGDPR) {
        this.$router.push('/gdpr');
      }

      if(this.$route.path === '/' && this.$store.getters.getCurrentUserId) {
        if(this.tempURL) {
          this.$router.push(this.tempURL);
          this.tempURL = '';
        }
        else {
          const orgId = await this.checkUserDashboardRedirect();
          if(orgId) {
            this.$router.push('/admin/organizations/' + orgId + '/dashboard');
          } else {
            this.$router.push('/project');
          }
        }
      }
    },
    async loadOrganizations() {
      return this.$store.dispatch('loadOrganizations', {
        ignoreIndex: true,
        add: {
          limit: {
            default: 800,
          },
        },
        listName: 'globalOrganizations'
      });
    },
    async loadTeams() {
      return this.$store.dispatch('loadTeams', {
         ignoreIndex: true,
          add: {
            limit: {
              default: 15500,
            }
          },
          listName: 'globalTeams'
        });
    },
    /*historyBack(e) {
      const el = document.activeElement;
      if (el.id && el.id === 'vhub' && e.keyCode === 8) {
        this.$router.go(-1);
      }
    },*/
    version: function(){
      return this.$store.getters.appVersion;
    }
  },
}
</script>

<style lang="scss">
.language-switch-front {
  position:fixed;
  top:0;
  right:15px;
  z-index:10;
}
.environment {
  position:fixed;
  top:0;
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width: 250px;
  height:45px;
  font-size: 1.1rem;
  text-transform:uppercase;
  z-index:500;
  .text {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .spickel {
    right: -25px;
    left:auto;
    top:0;
    width:50px;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
  }
  .spickel2 {
    left: -30px;
    top:0;
    width:50px;
    height:100%;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
    position:relative;
  }
  background-color: $stagingColor;
  display:none;
  &.staging {
    display:block;
    background-color: $stagingColor;
    .spickel, .spickel2 {
      background-color: $stagingColor;
    }
  }
  &.demo {
    display:block;
    background-color: $demoColor;
    .spickel, .spickel2 {
      background-color: $demoColor;
    }
  }
}
.connect-info {
  position:fixed;
  bottom:25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: $highlight;
  z-index:108;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #fff;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
}
.page-placeholder {
  min-height: 80vh;
}
</style>