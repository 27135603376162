<template>
<div>
  <template v-if="editing">
    <div class="input-container mb-2" :key="index" v-for="(row, index) in rows">
      <input class="form-text border-radius" v-model="rows[index]" type="text" />
      <div class="delete-icon" @click="removeEntry(index)"><icon class="lighter" size="2.1" type="square-minus" /></div>
    </div>
    <div class="w-100" />
    <div class="float-right" @click="addRow"><icon size="1.9" type="square-plus" /></div>
    <div class="settings-button d-inline-block" @click="save">{{ $t('save') }}</div>
    <div>{{ error }}</div>
  </template>
  <template v-else>
    <div :key="index" v-for="(row, index) in rows">
      <span class="lighter">{{ index }}:</span> {{ row ? row : 'N/A' }}
    </div>
  </template>
</div>
</template>

<script>
import Icon from "@/components/Icon";
export default {
  name: "JsonAdder",
  components: {
    Icon
  },
  props: {
    value: {type: String, default: ''},
    config: {type: Object, required: true},
    editing: {type: Boolean, default: true}
  },
  data() {
    return {
      rows: [''],
      error: '',
    };
  },
  beforeMount() {
    if(this.value && this.value.length) {
      try {
        this.rows = JSON.parse(this.value);
      } catch {
        this.error = 'malformed json';
      }
    }
  },
  watch: {
    value: function (val){
      if (!val){
        this.rows = [''];
      }
    }
  },
  methods: {
    removeEntry(index) {
      this.rows.splice(index, 1);
    },
    addRow() {
      this.rows.push('');
    },
    save() {
      this.$emit('save', JSON.stringify(this.rows));
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  position: relative;
}
.form-text, .newStyle .form-text {
  height: auto;
  padding: 2.3px 6px;
}
.delete-icon {
  position:absolute;
  right:1px;
  top: -6.3px;
  cursor:pointer;
}
</style>