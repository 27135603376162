<template>
  <div class="mb-3">
    <div class="col p-0">
      <label
          class="vform-editor-label mt-2">
        {{ $t("vform.sendEmailToAddress") }}
      </label>
      <input
          :id="'chkEmailAddress'"
          v-model="emailAddress"
          :class="['form-text form-text-dark', $v.emailAddress.$error ? 'form-group--error' : '']"
          type="text"
          @blur="$v.emailAddress.$touch()"
      />
      <div v-if="!$v.emailAddress.required  && $v.emailAddress.$dirty" class="form-error">
        {{ $t('vform.mustFillEmailInput') }}
      </div>
      <div v-if="!$v.emailAddress.email" class="form-error">{{ $t('vform.invalidEmailFormat') }}</div>
    </div>
  </div>
</template>

<script>
import {email} from 'vuelidate/lib/validators'
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";

export default {
  name: "VFormCheckout",
  mixins: [vFormElementMixin],
  props: {
    element: { type: Object, required: true },
  },
  data() {
    return {
      emailAddress: "",
    }
  },
  watch: {
    emailAddress() {
      this.$set(this.element, "emailAddress", this.emailAddress);
    },
  },
  mounted() {
    this.emailAddress = this.element.emailAddress;
  },
  validations: {
    emailAddress: {
      required(value, vm) {
        return vm.emailAddress.trim().length > 0;
      },
      email
    },
  },
}

</script>