<template>
  <div>
    <div
        v-for="(sensorAssetId, index) in element.sensors"
        :key="index + forceReRenderKey"
        class=""
    >
      <label v-if="index === 0" class="vform-editor-label section">{{ $t("SelectSensor") }}</label>

      <div class="w-100 d-flex" style="height: fit-content">
        <universal-selector
            :after-query-filter="(item) => {
                      return item.type === 'sensor'
                    }"
            :limit-by-organization-id="organizationId"
            class="vform-editor-universal-select"
            filter-attribute="name"
            list-name="vform"
            store-name="Datasource"
            tag-icon="home"
            @addItem="(asset) => { selectSensor(asset, index) }"
        />
        <div class="ml-4" style="position: relative; top: -7px; height: 0px">
          <div @click="removeSensor(index)">
            <icon :size="'2.0'" type="fat-square-minus"/>
          </div>
        </div>
      </div>
      <div v-if="sensorAssetId" class="tag mt-2 mb-3 pt-0">
        <content-display
            :list-name="$route.params.id"
            :show-preview="true"
            :value="sensorAssetId"
            class="mt-1 d-inline-block"
            type="sensor"
        />
        <div class="d-inline-block ml-3" @click="resetItem()">x</div>
      </div>
    </div>
    <div class="w-100 text-right mr-2">
      <div class="float-right" @click="addSensor()">
        <icon :size="'2.0'" type="fad-square-plus"/>
      </div>
    </div>

    <!--    <label class="vform-editor-label section">Sensor</label>-->
    <!--    <div-->
    <!--        v-for="(key, index2) in Object.keys(element.label.dix)"-->
    <!--        v-if="element.currentLang === key"-->
    <!--        :key="index2 + 1000000000 + forceReRenderKey"-->
    <!--        class="dflex"-->
    <!--    >-->
    <!--      <div class="col p-0">-->
    <!--        <input-->
    <!--            v-model="element.label.dix[key]"-->
    <!--            :placeholder="-->
    <!--                        key === 'Unknown'-->
    <!--                            ? placeholders.SHORT-->
    <!--                            : $t('vform.translationMessage', {-->
    <!--                                  key: key,-->
    <!--                                  content: element.label.dix['Unknown'],-->
    <!--                              })-->
    <!--                    "-->
    <!--            :title="element.label.dix['Unknown']"-->
    <!--            class="mb-1 form-text v-form-label form-text-dark"-->
    <!--            type="text"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="">
      <div v-if="element.sensors">
        <label class="vform-editor-label section">{{ $t('SensorDisplay') }}</label>
        <select v-model="element.display" class="custom-select form-text-dark">
          <option v-for="view in chartTypeDataSource" :key="view" :value="view">
            {{ $t(`vform.chart_${view}`) }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="element.display === 'line'">
      <label class="vform-editor-label section">Y-Axis Min</label>
      <div class="col p-0">
        <input
            :placeholder="element.yAxisMinDefault"
            class="mb-1 form-text v-form-label form-text-dark"
            type="text"
            v-model="element.yAxisMinCustom"
        />
      </div>
      <label class="vform-editor-label section">Y-Axis Max</label>
      <div class="col p-0">
        <input
            :placeholder="element.yAxisMaxDefault"
            class="mb-1 form-text v-form-label form-text-dark"
            type="text"
            v-model="element.yAxisMaxCustom"
        />
      </div>
    </div>
    <label class="vform-editor-label section">Options</label>
    <div class="col row dflex">
      <div class="dflex">
        <label
            :for="'chkEmphasized' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import UniversalSelector from "../organizations/UniversalSelector";
import ContentDisplay from "../widgets/editors/ContentDisplay";
import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js";
import {vFormControls, placeholders} from "@/enum";
import Icon from "@/components/Icon";

export default {
  name: "vFormSENSOR",
  components: {
    UniversalSelector,
    ContentDisplay,
    Icon,
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
  },
  mixins: [vFormElementMixin, MetaSetsMixin],
  data() {
    return {
      forceReRenderKey: 0,
      editImage: null,
      vFormControls: vFormControls,
      placeholders: placeholders,
      chartTypes: ['line'],
      sensorValueType: ''
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls["SENSOR"];
    if (!this.element.display) {
      this.element.display = 'line';
    }

    if (!this.element.sensors) {
      this.$set(this.element, 'sensors', ['']);
    }

    if (this.element.sensorAssetId) {
      this.loadSensor(this.element.sensorAssetId);
    }

    if (!this.element.yAxisMinCustom){
      this.$set(this.element, 'yAxisMinCustom', "");
    }

    if (!this.element.yAxisMaxCustom){
      this.$set(this.element, 'yAxisMaxCustom', "");
    }
  },
  computed: {
    chartTypeDataSource() {
      if (!this.sensorValueType) {
        return ['text', ...this.chartTypes];
      }

      if (this.sensorValueType === 'number') {
        return this.chartTypes;
      }

      return this.chartTypes;
    }
  },
  methods: {
    resetItem() {
      this.$set(this.element, 'sensorAssetId', null);
    },
    addSensor() {
      this.element.sensors.push('');
    },
    removeSensor(index) {
      this.element.sensors.splice(index, 1);
    },
    selectSensor(asset, index) {
      //this.element.sensors[index] = asset.id;
      this.$set(this.element.sensors, index, asset.id);
      this.loadSensor(asset.id);
      this.forceReRenderKey++;
    },
    loadSensor(sensorAssetId) {
      this.$store.dispatch('clientLoadAsset', {
        id: sensorAssetId,
        include: 'metaSets'
      }).then(asset => {
        const {metaSets} = asset;
        const metaObj = this.smashMetaSets(metaSets);
        this.sensorValueType = metaObj.type;
      })
    },
  },
};
</script>
