<template>
  <div/>
</template>
<script>
   import _ from 'lodash';
    /*
    * This file contains all file type data in a centralized way – used e.g. by a
    *  Dropzone or a Preview Component
    * */
    import {textureFileTypes, mediaCategories, typeMatching, allowPreviewsFor, defaultKeys, mimeTypes, previewTypes, textFileTypes} from '@/enum';
export default {
  name: "FileTypeMixin",
      data() {
        return {
          mimeTypes: mimeTypes,
          defaultKeys: defaultKeys,
          mediaCategories: mediaCategories,
          types: typeMatching,
          previews: previewTypes,
          allowPreviewsFor: allowPreviewsFor,
          textFileTypes: textFileTypes,
          textureFileTypes: textureFileTypes,
        }
    },
  methods: {
    isTextFile(fileName) {
      if(this.textFileTypes.includes(this.getExtension(fileName).toLowerCase())) {
        return true;
      }
      return false;
    },
    getDefaultKey(type) {
      return this.defaultKeys[type];
    },
    getTypeFromMimeType: function(mimeType){
      return this.mimeTypes[mimeType] ? this.mimeTypes[mimeType] : '';
    },
    getExtension: function(filenameOrUri) {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(filenameOrUri)[1] ? re.exec(filenameOrUri)[1] : "";
    },
    getDefaultKeyByFileEnding: function(filenameOrUri) {
      if(filenameOrUri) {
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(filenameOrUri)[1];
        if(this.defaultKeys[ext]) {
          return this.defaultKeys[ext];
        }
      }
    },
    getTypeByFileEnding: function(filenameOrUri) {
      if(filenameOrUri) {
        let string = filenameOrUri;
        const re = /(?:\.([^.]+))?$/;
        let ext = re.exec(string)[1];
        if(ext) {
          ext = ext.toLowerCase();
        }
        if(this.types[ext]) {
          return this.types[ext];
        }
        return ext;
      }
    },
    //allows either a fileName or a complete file as an argument
    sanitizeFileName (fileName) {
      let name = fileName;
      if(typeof(fileName) === 'object' && fileName.name) {
        name = fileName.name;
      }
      const ext = name.split('.').pop();
      const rest = name.replace('.'+ext, '');
      return rest.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + ext.toLowerCase();
    },
    getMediaCategory: function(filenameOrUri) {
      if(filenameOrUri) {
        let type = this.getTypeByFileEnding(filenameOrUri);
        return this.mediaCategories[type] ? this.mediaCategories[type] : '';
      }
      return '';
    },
    getAllowedMediaFileTypes: function() {
      const mediaKeys = Object.keys(this.mediaCategories).filter(item => {return item !== 'model'}); // remove model because it makes no sense right now

      return Object.keys(_.pickBy(this.types, function(value) {return mediaKeys.includes(value)})).map(val => {return '.'+val}).join(',');
    },
  }
}
</script>
