// tokenManager.js
const ACCESS_TOKEN_KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const TOKEN_REFRESHING_KEY = 'tokenRefreshing';
const TOKEN_REFRESH_TIME_KEY = 'tokenRefreshTime';
const SESSION_KEY = 'sessionId';
const NO_TOKEN_REFRESH_KEY = 'noTokenRefresh';
const EXPIRY_THRESHOLD_SECONDS = 60; // Refresh if within 60 seconds of expiration
import store from '@/store/store';
export const REFRESH_TIMEOUT = 25 * 1000; // 25 seconds
const REFRESH_TIMEOUT_SECONDS = 20;
let tokenRefreshPromise = null;
let isTokenRefreshing = false;
let refreshTimer;
let scheduleInterval;
let cleanupInterval;
export function setAppMode(passivMode = false) {
    console.log('setting passivMode', passivMode);
    localStorage.setItem(NO_TOKEN_REFRESH_KEY, passivMode);
}

// Function to parse JWT and return the payload
function parseJwt(token) {
    if(!token) {
        return {};
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join('')
    );
    return JSON.parse(jsonPayload);
}

// Function to check if the access token is expiring soon
function isTokenExpiringSoon(token) {
    if(!token && store.getters.getCurrentUserId) {
        store.dispatch('idpLogout');
    }
    if (!token || token === 'null' || !token.includes('.')) return false;
    console.log('calculating expiration date...')
    try {
        const { exp } = parseJwt(token);
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        return exp - currentTimeInSeconds <= EXPIRY_THRESHOLD_SECONDS;
    } catch (error) {
        console.error('Error checking token expiration:', error);
        return true; // Assume token is expiring if parsing fails
    }
}

// Schedule a check to see if the token needs refreshing
// this will not be run in a loop if passive mode is detected
export async function scheduleTokenRefresh() {
    console.log('scheduled refresh')
    if(localStorage.getItem(NO_TOKEN_REFRESH_KEY) === 'true') {
        console.log('no token refresh, passive mode, exiting loop');
        clearTimeout(refreshTimer); // stop any started timers, adjust variable name if it's different
        clearInterval(scheduleInterval);
        clearInterval(cleanupInterval);
        return;
    }
    const authToken = getAccessToken();
    if (isTokenExpiringSoon(authToken)) {
        console.log('token expiring soon');
        await refreshTokens();
    }
    clearTimeout(refreshTimer); // Clear the existing timer
    refreshTimer = setTimeout(scheduleTokenRefresh, REFRESH_TIMEOUT); // store timer id, adjust name if used elsewhere
}

export function setSessionId(sessionId) {
    if(!sessionId) {
        localStorage.removeItem(SESSION_KEY);
    } else {
        localStorage.setItem(SESSION_KEY, sessionId);
    }
}

export function getSessionId() {
    return localStorage.getItem(SESSION_KEY);
}

export function getAccessToken() {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    return token || null;
}

export function setAccessToken(token) {
    if(!token) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    } else {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }
}

export function setRefreshToken(token) {
    if(!token) {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    } else {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

// Request new tokens from the refresh token endpoint
async function requestNewTokens() {
    await store.dispatch('authNewAuthToken');
}

// Cleanup function to release the refreshing lock if held for too long
function cleanupStaleRefreshLock() {
    const refreshTime = parseInt(localStorage.getItem(TOKEN_REFRESH_TIME_KEY), 10);
    const currentTime = Date.now();

    if (!refreshTime || (refreshTime && (currentTime - refreshTime) > (REFRESH_TIMEOUT_SECONDS * 1000))) {
        localStorage.removeItem(TOKEN_REFRESHING_KEY);
        localStorage.removeItem(TOKEN_REFRESH_TIME_KEY);
        isTokenRefreshing = false;
    }
}


// Refresh the tokens and notify other tabs
export async function refreshTokens() {
    if (isTokenRefreshing) {
        return new Promise(resolve => resolve());
    }

    isTokenRefreshing = true;
    localStorage.setItem(TOKEN_REFRESHING_KEY, 'true');
    localStorage.setItem(TOKEN_REFRESH_TIME_KEY, Date.now());

    // todo: check if there is an auth token and a refresh token present!!

    tokenRefreshPromise = requestNewTokens()
        .then(() => {
            localStorage.removeItem(TOKEN_REFRESHING_KEY);
            localStorage.removeItem(TOKEN_REFRESH_TIME_KEY);
            isTokenRefreshing = false;
            tokenRefreshPromise = null;
        })
        .catch((err) => {
            localStorage.removeItem(TOKEN_REFRESHING_KEY);
            localStorage.removeItem(TOKEN_REFRESH_TIME_KEY);
            isTokenRefreshing = false;
            tokenRefreshPromise = null;
            console.error('Error refreshing tokens:', err);
            store.dispatch('idpLogout');
        });

    return tokenRefreshPromise;
}

// Listen for changes in localStorage to handle token sharing
window.addEventListener('storage', (event) => {
    if (event.key === TOKEN_REFRESHING_KEY && event.newValue === 'true') {
        isTokenRefreshing = true;
    } else if(event.key === TOKEN_REFRESHING_KEY && event.newValue === 'true') {
        isTokenRefreshing = false;
    } else if (event.key === ACCESS_TOKEN_KEY) {
        // Token has been updated by another tab
        store.dispatch('setAuthToken', event.newValue);
        if(!store.getters.getCurrentUserId) {
            store.dispatch('checkLogin');
        }
    } else if (event.key === REFRESH_TOKEN_KEY) {
        // Refresh token has been updated by another tab
        store.dispatch('setRefreshToken', event.newValue);
    } else if(event.key === SESSION_KEY) {
        store.dispatch('setSessionId', event.newValue);
    }
});

// Periodically check for stale refresh lock
cleanupInterval = setInterval(cleanupStaleRefreshLock, REFRESH_TIMEOUT / 10);

// Periodically schedule token refresh
scheduleInterval = setInterval(scheduleTokenRefresh, REFRESH_TIMEOUT);