<template>
<div class="mt-3">
  <h3>{{ $t('webappPackage') }}</h3>
  <select class="custom-select webapp-selector d-inline-block" v-model="packageId">
    <option :value="asset.id" :key="asset.id" v-for="asset in webappAssets">{{ asset.name }}</option>
  </select><br />
  <div @click="save" class="settings-button d-inline-block mt-2">{{ $t('save') }}</div><br />
  <div v-if="saving" class="darker p-3 mt-2 d-inline-block">saving webapp package...</div>
  <div v-if="justSaved" class="bg-highlight p-3 mt-2 d-inline-block">When changing the package, you need to republish your webapp with the button below</div>
</div>
</template>

<script>

export default {
  name: "AppPackageSelector",
  props: {
    appId: {type: String, required: true},

  },
  data() {
    return {
      webapp: null,
      webappAssets: [],
      packageId: null,
      instanceId: null,
      saving: false,
      justSaved: false
    };
  },
  beforeMount() {
    this.$store.dispatch('loadProject', {id: this.appId, include: ['instances']})
        .then(webapp => {
          console.log(webapp)
          this.webapp = webapp;
          if(this.webapp.instances) {
            const webappInstances = this.webapp.instances.filter(item => {return item.type === 'webapp'});
            if(webappInstances.length) {
              this.packageId = webappInstances[0].assetId;
              this.instanceId = webappInstances[0].id;
            }
          }
        })
        .then(() => {
          this.$store.dispatch('clientLoadAssets', {filter: 'type eq webapp'})
              .then(webappAssets => {
                this.webappAssets = webappAssets;
              })
        })
  },
  methods: {
    async save() {
      this.saving = true;
      if(this.instanceId) {
        await this.$store.dispatch('clientDeleteProjectInstances', {id: this.appId, args: [this.instanceId]})
      }
      let args = {
        args: [
          {
            assetId: this.packageId,
            name: this.webappAssets.filter(item => {return item.id === this.packageId})[0].name,
          },
        ],
        id: this.appId
      };
      await this.$store.dispatch('clientCreateProjectInstances', args)
          .then(items => {
            const item = items[0];
            this.packageId = item.assetId;
            this.instanceId = item.id;
            this.$store.dispatch('createNotification', {'text': this.$t('events.appUpdated')});
            this.saving = false;
            this.justSaved = true;
          })
    }
  }
}
</script>

<style scoped>
.webapp-selector {
  max-width: 300px;
}
</style>