<template>
  <div class="d-flex">
    <div class="w-100 mt-1">
      <vue-slider v-model="value" :interval="interval" :max="max" :min="min"></vue-slider>
    </div>
    <input @change="inputValueChanged" :value="value" class="mr-2 ml-2 form-text form-text-dark manual" type="number" :step="interval"/>
    <div class="clickable reset-button" @click="reset()">
      <icon size="1.2" type="rotate-left"/>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Icon from "@/components/Icon";

export default {
  name: "Slider",
  components: {
    VueSlider,
    Icon
  },
  props: {
    defaultValue: {type: Number, default: 0},
    initialValue: {type: Number, default: 0},
    interval: {type: Number, default: 1},
    min: {type: Number, default: 0},
    max: {type: Number, default: 100},
  },
  data() {
    return {
      value: 0,
    };
  },
  beforeMount() {
    this.init();
  },
  computed: {},
  watch: {
    initialValue(val) {
      this.value = val;
    },
    value(val) {
      if (val === undefined && this.defaultValue){
        this.value = this.defaultValue;
      }
      else {
        if (val > this.max) {
          val = this.max;
          this.value = val;
        }

        if (val < this.min) {
          val = this.min;
          this.value = val;
        }
      }

      this.$emit("change", val);
    }
  },
  methods: {
    init() {
      this.value = this.initialValue;
    },
    reset() {
      this.value = this.defaultValue;
    },
    inputValueChanged(val){
      this.value = Number(val.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.manual {
  width: 60px;
}

.reset-button {
  color: var(--vform-editor-ui-tertiary-color);

  &:hover {
    color: var(--vform-editor-ui-primary-color);
  }
}
</style>