<template>
  <div>
    <label v-if="!templateEditing" class="vform-editor-label section">{{ $t("Label") }}</label>
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="!templateEditing && currentLang === key"
        :key="index2 + 1000000000 + forceReRenderKey"
    >
      <div class="col p-0">
        <textarea
            v-model="element.label.dix[key]"
            :placeholder="key === 'Unknown' ? placeholderText: key + ' translation for: ' + element.label.dix['Unknown']"
            :title="element.label.dix['Unknown']"
            class="mb-1 form-text v-form-label form-text-dark"
            type="text" />
      </div>
    </div>
    <div v-if="!templateEditing" class="dflex mt-3">
      <div class="col row mt-1">
        <label class="vform-editor-label section mt-0">Icon</label>
      </div>
      <div
          v-for="(key, index2) in Object.keys(element.label.dix)"
          v-if="currentLang === key"
          :key="index2 + 1000000000 + forceReRenderKey"
          class="dflex col-auto pr-0"
      >
        <div>
          <v-form-image-dix-cell
              :element="element"
              :image-dix="element.label.imageDix"
              :media-category="3"
              :organization-id="organizationId"
              :v-s-t-a-g-e-context="vSTAGEContext"
              preview-size="medium"
          />
        </div>
      </div>
    </div>
    <div v-if="!templateEditing && element.label.imageDix[currentLang] && element.showastiles">
      <label class="vform-editor-label section mt-2">Icon Size</label>

      <label class="section ml-2 vform-editor-body slider-label">{{ element.iconSize }}%</label>
      <slider :default-value="40" :initial-value="element.iconSize"
              :interval="1" :max="100" :min="1"
              @change="(val) => { this.$set(this.element, 'iconSize', val) }"/>
    </div>
    <div v-if="!element.showastiles" class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Alignment</label>
      </div>
      <set-by-template v-if="selectedTemplate.alignment" />
      <div
          v-else
          v-for="(align, index) in alignments"
          :key="index"
          :class="['vform-alignment-button', align === element.alignment ? 'active' : '']"
          @click="setAlignment(align)">
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
    <div class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Text Alignment</label>
      </div>
      <set-by-template v-if="selectedTemplate.textAlignment" />
      <div
          v-else
          v-for="(align, index) in alignments"
          :key="'textAlign' + index"
          :class="['vform-alignment-button', align === element.textAlignment ? 'active' : '',]"
          @click="setTextAlignment(align)"
      >
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
    <div v-if="!element.showastiles" class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Style</label>
      </div>
      <set-by-template v-if="selectedTemplate.buttonStyle" />
      <select v-else v-model="element.buttonStyle" class="custom-select form-text-dark">
        <option v-for="(style, index) in buttonStyles" :key="index" :value="style">
          {{ $t("vform.buttonStyle." + style) }}
        </option>
      </select>
    </div>
    <div v-if="showAsTilesIsVisible" class="col pl-0 pr-0 dflex">
      <div class="col pl-0">
        <label class="mt-3 vform-editor-label section">{{
            $t("showAsTile")
          }}</label>
      </div>
      <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
      <div v-else class="col-auto pl-0 pr-0" style="margin: auto">
        <div v-if="true" class="col-12 dflex row">
          <label
              :for="'chkshowastiles' + $vnode.key"
              class="container"
              style="padding-left: 5px !important"
          >
            <input
                :id="'chkshowastiles' + $vnode.key"
                v-model="element.showastiles"
                type="checkbox"
            >
            <span class="checkmark"/>
          </label>
        </div>
      </div>
    </div>
    <div v-if="element.showastiles">
      <label class="vform-editor-label section mt-3">Height</label>
      <label class="section ml-2 vform-editor-body slider-label">{{ element.height ? `${element.height}%` : 'AUTO' }}</label>
      <set-by-template v-if="selectedTemplate.height" />
      <slider v-else :default-value="0" :initial-value="element.height"
              :interval="1" :max="100" :min="0" @change="(val) => { this.$set(this.element, 'height', val) }"></slider>
    </div>
    <div class="w-100"/>
    <label class="vform-editor-label section">Background Color</label>
    <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
    <div
        v-else
        :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor ? element.backgroundColor : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
        class="clickable"
        @click="(event) => {
          setColorPickrId(event, 'backgroundColor');
        }"
    />

    <label class="vform-editor-label section">Border Color</label>
    <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
    <div
        v-else
        :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.borderColor ? element.borderColor : '',
                border: element.borderColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
        class="clickable"
        @click="(event) => {
          setColorPickrId(event, 'borderColor');
        }"
    />

    <label class="vform-editor-label section">Font Color</label>
    <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
    <div
        v-else
        :style="{
          width: '20px',
          height: '20px',
          backgroundColor: element.fontColor ? element.fontColor : '',
          border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
        }"
        class="clickable"
        @click="(event) => {
          setColorPickrId(event, 'fontColor');
        }"
    />
    <color-picker
        v-if="!templateEditing"
        v-show="colorPickrId"
        :key="colorPickrId"
        ref="colorpickr"
        :popup-style="true"
        :show-save-button="true"
        :template-editing="templateEditing"
        :value="element[colorPickrId] ? element[colorPickrId] : '#000'"
        class="step-settings-color-pickr"
        @changeColor="(color) => { setItemColor(colorPickrId, color, false); }"
        @close="() => { colorPickrId = null;}"
        @save="(color) => { setItemColor(colorPickrId, color); }"
    />
    <portal to="colorPickerOverlay" :key="index + colorPickrId" v-else>
        <color-picker
        v-show="colorPickrId"
        ref="colorpickr"
        :key="colorPickrId"
        :popup-style="true"
        :show-save-button="true"
        :value="element[colorPickrId] ? element[colorPickrId] : '#fff'"
        class="step-settings-color-pickr"
        @changeColor="(color) => { setItemColor(colorPickrId, color, false);}"
        @close="() => { colorPickrId = null;}"
        @save="(color) => { setItemColor(colorPickrId, color); }"
        />
    </portal>
    <div
        v-if="(!selectedTemplate.backgroundColor && !selectedTemplate.fontColor && !selectedTemplate.borderColor) && (element.backgroundColor || element.fontColor || element.borderColor)"
        class="clickable darker p-1 border-radius d-inline-block mt-1"
        @click="element.backgroundColor = null; element.fontColor = null; element.borderColor = null;">
      {{ $t('resetColor') }}
    </div>
    <div class="w-100"/>

    <label v-if="!templateEditing" class="vform-editor-label section">Button Type</label>
    <select v-if="!templateEditing" class="custom-select form-text-dark"
            v-model="element.actionMode"
            @change="(e) => {setMode(e.target.value)}">
      <option v-for="mode in vFormButtonActionModes" :key="mode" :value="mode">{{ $t(mode) }}</option>
    </select>
    <condition-editor
        class="mt-2"
        v-if="!templateEditing && element && element.actionMode === vFormButtonActionModes.RULES_BASED"
        :organization-id="organizationId"
        :project-id="projectId"
        :slides="slides"
        :steps="steps"
        :globals="globals"
        :element="element"
        :current-lang="currentLang"
    />
    <v-form-go-to-component
        v-else-if="!templateEditing"
        :current-lang="currentLang"
        :element="element"
        :organization-id="organizationId"
        :project-id="projectId"
        :slides="slides"
        :steps="steps"
        default-action="NextSlide"
        @selectedAction="(newSelectedAction) => {selectedAction = newSelectedAction}"
    />
  </div>
</template>

<script>
import {vFormControls, placeholders, vFormButtonActionModes} from "@/enum";
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import Icon from "../Icon";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import ColorPicker from "@/components/widgets/editors/ColorPicker";
import Slider from "@/components/widgets/editors/Slider";
import vFormGoToComponent from "@/components/vForm/vFormGoToComponent";
import ConditionEditor from "./viewer/ConditionEditor";
import SetByTemplate from "@/components/vForm/SetByTemplate.vue";

export default {
  name: "vFormBUTTONMixin.js.vue",
  components: {
    SetByTemplate,
    Icon,
    vFormImageDixCell,
    ColorPicker,
    Slider,
    vFormGoToComponent,
    ConditionEditor
  },
  mixins: [vFormElementMixin],
  props: {
    thumbs: {type: Object, default: null},
    slides: {type: Array, default: null},
    steps: {type: Array, default: null},
    allowTile: {type: Boolean, default: true},
    currentLang: {type: String, required: true},
    templateEditing: {type: Boolean, default: false},
  },
  data() {
    return {
      vFormControls: vFormControls,
      placeholders: placeholders,
      vFormButtonActionModes: vFormButtonActionModes,
      forceReRenderKey: 0,
      buttonStyles: ["Text", "Arrow", "TextAndArrow"],
      selectedAction: "NextSlide",
      targetSlide: "",
      alignments: ["left", "center", "right", "justify"],
      colorPickrId: null,
      textArea: false,
    };
  },
  computed: {
    placeholderText() {
      if (this.element.formElementTypeString === this.vFormControls.BUTTON) {
        return this.$t("next");
      }

      return this.$t(this.element.formElementTypeString.toLowerCase());
    },
    showAsTilesIsVisible() {
      return this.allowTile && !this.element.bottomDropZone;
    },
  },
  watch: {
    selectedAction() {
      this.element.selectedAction = this.selectedAction;
      if (this.selectedAction === 'NextSlide') {
        this.element.isCurrentProject = null;
        this.element.linkedProjectId = null;
        this.element.linkedProjectName = null;
        this.element.targetStep = null;
        this.element.targetSlide = null;
      }

      this.forceReRenderKey++;
    },
    targetSlide() {
      this.element.targetSlide = this.targetSlide;
    },
  },
  beforeMount() {
    if (this.element.selectedAction) {
      this.selectedAction = this.element.selectedAction;
    }

    if (this.element.targetSlide) {
      this.targetSlide = this.element.targetSlide;
    }

    if (!this.element.showastiles) {
      this.$set(this.element, "showastiles", false);
    }
    if (!this.element.alignment) {
      this.$set(this.element, "alignment", "right");
    }

    if (!this.element.buttonStyle) {
      this.$set(this.element, "buttonStyle", "Text");
    }

    if (!this.element.actionMode) {
      this.$set(this.element, "actionMode", "default")
    }
    if (!this.element.conditions) {
      this.$set(this.element, "conditions", [])
    }

    if (!this.element.multiLine) {
      this.$set(this.element, "multiLine", false)
    }

  },
  methods: {
    setMode(val) {
      this.$set(this.element, "actionMode", val);
      if(val !== vFormButtonActionModes.RULES_BASED) {
        this.$set(this.element, "rules", []);
      }
    },
    setAlignment(val) {
      this.$set(this.element, "alignment", val);
    },
    setTextAlignment(val) {
      this.$set(this.element, "textAlignment", val);
    },
  },
};
</script>

<style lang="scss">
/*hr {
    margin: 0;
  }*/
.vform-alignment-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &.active {
    background-color: var(--vform-editor-accent-color);
  }
}

.prop-tab-separator {
  height: 1px;
  background: var(--vform-editor-gizmos-primary-color);
  margin-bottom: -10px;
}

.label-prop-tab-separator {
  margin: 0;
  padding: 0 8px 0 8px;
  color: var(--vform-editor-gizmos-secondary-color);
  background: var(--vform-editor-gizmos-primary-color);
}
</style>
