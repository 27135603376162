import Vue from 'vue';
import i18n from "../translation";
const state = {
    /*
    * usersLists contains all lists (e.g. if there is a box with the users per organization, you would find it
    * in here with the id of the organization as the listname
    * */
    loading: true,
    currentUserId: null,
    currentUserOrg: null,
    currentUserTeam: null,
    globalUser: {},
    usersLists: {
        userList: {},
    },
    userListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            //default: 'type eq vstage',
        },
        sort: {
            //default: '-updatedAt',
        },
        include: {
            //default: 'metaSets'
        },
    },

    /*
    * The query params contain the different parameters of a query added by filters, table sorts and so on
    * the default filters always stay there
    *
    * */
    queryParams: {
        userList: {
            limit: {
                default: '50',
            },
            filter: {
                //default: 'type eq vstage',
            },
            sort: {
                default: '-updatedAt',
            },
            include: {
                //default: 'metaSets'
            },
        },
    },
    /*
    * Combined query params are e.g. filter params which get combined from 'filter eq vstage' and 'filter eq coolman' to 'filter in vstage, coolman'
    * */
    combinedQueryParams: {
        userList: {},
    },
    /**
     * Used keys so far:
     * last_inserted
     * */
    usersTmp: {
    },
};

const getters = {
    getUsers() {
        return state.usersLists.userList;
    },
    getCurrentUserOrg(){
        return state.currentUserOrg;
    },
    getCurrentUserHomeOrg() {
        return state.globalUser.homeOrganizationId;
    },
    getCurrentUserOrgs(){
        return state.globalUser.organizations;
    },
    getCurrentUserTeam() {
        return state.currentUserTeam;
    },
    getCurrentUserPrivateOrg() {
        return state.globalUser.organizationId;
    },
    getCurrentUserGDPR() {
        return state.globalUser.gdprAccepted;
    },
    getUserTeams: (state) => (id = null, listName = 'userList') => {
        if(!id) {
            return state.globalUser.teams;
        }
        return state.usersLists[listName][id].teams;
    },
    getCurrentUserPreviewUri() {
        return state.globalUser.profilePictureUri;
    },
    getUsersByListName: (state) => (listName) => {
        return state.usersLists[listName] ? state.usersLists[listName] : {};
    },
    getUserById: (state) => (id, listName = 'userList') => {
        return state.usersLists[listName] && state.usersLists[listName][id] ? state.usersLists[listName][id] : null;
    },
    getUserName: (state) => (id = null, listName = 'userList') => {
        if(!id) {
            return state.globalUser.id;
        }
        return state.usersLists[listName][id] ? state.usersLists[listName][id].name : '';
    },
    getCurrentUserName: function(state) {
        return state.globalUser.name;
    },
    getFirstName: (state) => (id = null, listName = 'userList') => {
        if(!id) {return state.globalUser.firstName;}
        return state.usersLists[listName][id] ? state.usersLists[listName][id].firstName : '';
    },
    getLastName: (state) => (id = null, listName = 'userList') => {
        if(!id) {return state.globalUser.lastName;}
        return state.usersLists[listName][id] ? state.usersLists[listName][id].lastName : '';
    },
    getUserEmail: (state) => (id = null, listName = 'userList') => {
        if(!id) {return state.globalUser.email;}
        return state.usersLists[listName][id] ? state.usersLists[listName][id].email : '';
    },
    getUserDefaultOrganization: (state) => (id = null, listName = 'userList') => {
        if(!id) {
            return state.globalUser.organizations.filter(item => {return item.members.default === true});
        }
        if(state.usersLists[listName][id] && state.usersLists[listName][id].organizations) {
            let res = state.usersLists[listName][id].organizations.filter(item => {return item.members.default === true});
            if(res && res[0]) {
                return res[0].id;
            }
        }
        return '';
    },
    getUserAppPanel: (state) => (id = null, listName = 'userList') => {
       if(!id) {return state.globalUser.appPanel;}
        return state.usersLists[listName][id] && state.usersLists[listName][id].appPanel ? state.usersLists[listName][id].appPanel : '';
    },
    getUserOrganizations: (state) => (id = null, listName = 'userList') => {
        if(!id) {return state.globalUser.organizations;}
        return state.usersLists[listName][id] ? state.usersLists[listName][id].organizations : [];
    },
    getVisibleUserOrganizations: (state) => (id = null, listName = 'userList') => {
        let orgs = state.usersLists[listName][id] ? state.usersLists[listName][id].organizations : [];

        if(!id) {orgs = state.globalUser.organizations;}

        if (orgs) {
            let filteredOrgs = orgs.filter(item => {
                return item.visibility > 0;
            });
            return filteredOrgs;
        }
        return [];
    },
    getCurrentUserOrganizations: function(state) {
        return state.globalUser.organizations;
    },
    getUserCreationDate: (state, rootGetters) => (id, listName = 'userList') => {
        if(!id) {return rootGetters['convertDate'](state.globalUser.createdAt);}
        return state.usersLists[listName][id] ? rootGetters['convertDate'](state.usersLists[listName][id].createdAt) : '';
    },
    getUserChangeDate: (state, rootGetters) => (id, listName = 'userList') => {
        if(!id) {return rootGetters['convertDate'](state.globalUser.updatedAt);}
        return state.usersLists[listName][id] ? rootGetters['convertDate'](state.usersLists[listName][id].updatedAt) : '';
    },
    getUserOriginalQueryParams: function() {
        return state.queryParams;
    },
    getUserQueryParams: (state) => (listName = 'userList') => {
        return state.combinedQueryParams[listName];
    },
    getUserListOptions: (state) => (listName, option = '') => {
        let optionList = 'userListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
    getUserPreviewUri: (state) => (id, listName = 'userList') => {
         if(!id) {return state.globalUser.profilePictureUri;}
        return state.usersLists[listName][id] && state.usersLists[listName][id].profilePictureUri ? state.usersLists[listName][id].profilePictureUri : '';
    },
};

const mutations = {
    setUserTmpMutation(state, data) {
        if(state.usersTmp[data.key]) {
            Vue.delete(state.usersTmp, data.key);
        }
        Vue.set(state.usersTmp, data.key, data.value);
    },
    loadUsersMutation(state, data) {
        let listName = 'userList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.usersLists[listName]) {
            Vue.set(state.usersLists, listName, {});
        }
        else {
            let userList = state.usersLists[listName];
            Object.keys(userList).forEach(key => {
                Vue.delete(state.usersLists[listName], key);
            });
        }

        for (var i = 0; i < data.length; i++) {
            let user = data[i];
            //protect the loggedIn user from being overwritten
            Vue.set(state.usersLists[listName], user.id, user);
         }
    },

    loadUserMutation(state, args) {
        const user = args.data;
        const listName = args.listName ? args.listName :'userList';
        user['editLink'] = '/admin/users/' + user.id + '/general';

        if(listName === 'globalUser') {
            state.globalUser = user;
        }

        if(!state.usersLists[listName]) {
            Vue.set(state.usersLists, listName, {});
        }

        Vue.set(state.usersLists[listName], user.id, user);
    },

    /**
     * expects a data object like data[key] = value
     * */
    updateUserMutation(state, data) {
        let listName = data.listName ? data.listName : 'userList';
        let id = data.id;
        //delete data.id;
        for (const key in data){
            const value = data[key];
            if(state.usersLists[listName][id]) {
                if(!state.usersLists[listName][id][key]) {
                    Vue.set(state.usersLists[listName][id], key, {});
                }
                Vue.set(state.usersLists[listName][id], key, value);
            }
        }
    },
    deleteUserMutation(state, data) {
        let listName = data.listName ? data.listName : 'userList';
        let id = data.id;
        if(state.usersLists[listName] && state.usersLists[listName][id]) {
            Vue.delete(state.usersLists[listName], id);
            state.dispatch('createNotification', {'text': i18n.t('events.userDeleted')});
        }
    },
    updateUserListOptionMutation(state, args) {
        let optionList = 'userListOptions';

        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
    setLoading (state, loading) {
        state.loading = loading;
    },
    login (state) {
        state.loggedIn = true;
        state.loading = false;
    },
    setCurrentUserOrg(state, currentUserOrg) {
        // eslint-disable-next-line no-undef
        $cookies.set('currentUserOrg', currentUserOrg);
        state.currentUserOrg = currentUserOrg;
    },
    setCurrentUserTeam(state, currentUserTeam) {
        // eslint-disable-next-line no-undef
        $cookies.set('currentUserTeam', currentUserTeam);
        state.currentUserTeam = currentUserTeam;
    },
    logout (state) {
        // eslint-disable-next-line no-undef
        $cookies.remove('authToken');
        // eslint-disable-next-line no-undef
        $cookies.remove('currentUserOrg');
        // eslint-disable-next-line no-undef
        $cookies.remove('currentUserTeam');
        Vue.delete(state.usersLists.userList, state.currentUserId);
        Vue.set(state, 'currentUserId', null);
        window.localStorage.clear();
        window.sessionStorage.clear();
        state.loggedIn = false;
    },
    clearGlobalUser: (state) => {
        state.globalUser = {};
    }
};
const actions = {
    /*
    * Loads the users and adds them to the store
    * args:
    *   listName: optional (default is userList)
    *   to add a filter item to the query, do it with add (and use 'remove' to clear the query of this item)
    *   args.add = {filter: {
    *                   customId: 'name like %lalala%',
    *                   customId2: 'type eq media',
    *               }}
    * */
    registerUsersQueryParams: ({commit}, args) => {
        let listName = 'userList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'users', listName: listName, params: args});
        }
    },
    loadUsers: async ({commit, dispatch, getters}, args = {}) => {
        let { listName } = args
        if(!listName) {
            listName = 'userList';
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'users', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'users', listName: listName});
        return dispatch('clientLoadUsers', getters.getUserQueryParams(listName)).then(data => {
            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/admin/users/' + data[i].id + '/general';
            }
            data.listName = listName;
            commit('loadUsersMutation', data);

            /*added pagination part*/
            if(getters.getUserQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateUserListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });
    },
    loadOrganizationUsers: async ({commit, dispatch, getters}, args = {}) => {
        let listName = 'userList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'users', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'users', listName: listName});
        return dispatch('clientLoadOrganizationUsers', getters.getUserQueryParams(listName))
            .then(data => {
            let filteredData = data.filter(item => {
                return item.userId !== '8a26c307-0b45-4420-b3cc-16db98bbfe7d' && !getters.idIsSFXMember(item.userId);
            });
            let result = filteredData.map(item => {
                item.user.organizationId = item.organizationId;
                return item.user
            })
            for (let i = 0; i < result.length; i++) {
                result[i]['editLink'] = '/admin/users/' + result[i].id + '/general';
            }
            result.listName = listName;
            commit('loadUsersMutation', result);

            /*added pagination part*/
            if(getters.getUserQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateUserListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return result;
        });
    },

    deleteUser: async ({dispatch}, args = {}) => {
        return dispatch('clientDeleteUser', args);
    },
    setUserCurrentOrg: ({commit}, id) => {
        commit('setCurrentUserOrg', id);
    },
    setUserCurrentTeam: ({commit}, id) => {
        commit('setCurrentUserTeam', id);
    },
    createUser: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateUser', args)
            .then(data => {
                commit('loadUserMutation', {data: data});
                commit('setUserTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.userCreated')});
            })
    },

    /**
     * loads a single user
     */
    loadUser: async ({commit, dispatch}, args) => {
        args.include = 'organizations,teams';
           return dispatch('clientLoadUser', args).then(data => {
                if(args.listName) {
                    data.listName = args.listName;
                }
                commit('loadUserMutation', {data: data});
                return data;
            });
    },
    checkIfUserNameExists: async ({dispatch}, args) => {
        let value = args.value;
        return dispatch('clientLoadUsers', {
            filter: ['name eq ' + value],
            fields: 'name,id',
        });
    },
    checkIfUserEmailExists: async ({dispatch}, value) => {
        return dispatch('clientLoadUsers', {
            filter: ['email eq ' + value],
            fields: 'email',
        });
    },
    updateUser: async ({commit, dispatch}, args) => {
        commit('updateUserMutation', args);
       return dispatch('clientSaveUser', args).then(data => {
            dispatch('createNotification', {'text': i18n.t('events.userUpdated')});
            return data;
        });
    },
    // eslint-disable-next-line no-unused-vars
    fetchAccessUrl: async ({ commit, dispatch }, {username, password}) => {
        if(username === 'public') {
            throw new Error(i18n.t('errors.userPassOrNameIsWrong'));
        }
        //console.log(password);
    },
    setStateLogin({ commit }) {
        commit('login');
    },
    setLoading({commit}, bool) {
        commit('setLoading', bool);
    },
    /*checkLogin: async ({commit, dispatch, getters}, opts) => {
       // commit('setLoading', true);
        // eslint-disable-next-line no-undef
        const authToken = opts && opts.authToken ? opts.authToken : getters.authToken;
        if (authToken && authToken.length > 0) {
            if(opts.authToken) {
                commit('setAuthToken', {authToken});
            }
            // eslint-disable-next-line no-undef
            const currentUserOrg = $cookies.get('currentUserOrg');
            // eslint-disable-next-line no-undef
            const currentUserTeam = $cookies.get('currentUserTeam');
            if(currentUserOrg) {
                commit('setCurrentUserOrg', currentUserOrg);
            }
             if(currentUserTeam) {
                commit('setCurrentUserTeam', currentUserTeam);
            }
            await dispatch('loadClient');
            return dispatch('clientGetCurrentUser', {include:'organizations,teams'})
                .then(user => {
                    if(!currentUserOrg) {
                        const defaultOrg = user.organizations.filter(item => {
                            return item.members.default === true;
                        });
                        if(defaultOrg[0]) {
                            commit('setCurrentUserOrg', defaultOrg[0].id);
                        }
                        else {
                            commit('setCurrentUserOrg', user.organizations[0].id);
                        }
                    }
                    commit('setCurrentUserId', user.id);
                    user['editLink'] = '/admin/users/' + user.id + '/general';
                    commit('loadUserMutation', {data: user, listName: 'globalUser'});
                    commit('login');

                    commit('setLoading', false);
                    return user;
                }).catch(e => {
                    console.log(e);
                    commit('logout');
                    commit('setLoading', false);
                    return false;
                })
        }
        else {
            commit('setLoading', false);
        }
    },*/
};
export default {
    state, getters, mutations, actions
}
