<template>
    <div>
        <popup @close="$emit('abort')">
            <div slot="popupMainContent">
                <add-qrcode-form
                    ref="addqrcodeform"
                    :organizationId="organizationId"
                    :teams="teams"
                    @enableSaving="canSave"
                    :canSelectOrganization="false"
                    :afterSaveGoToGeneralPage="false"
                    @saved="$emit('saved')"
                ></add-qrcode-form>
            </div>
            <Button
                slot="popupFooter"
                class="mb-3"
                :deactivated="!enableSaving"
                @click="save"
            >
                <icon v-if="enableSaving" type="save" /><icon v-else type="ban" />
                {{ $t("SAVE") }}
            </Button>
        </popup>
    </div>
</template>

<script>
import Popup from "../Popup";
import Button from "../forms/Button";
import Icon from "../Icon";
import AddQrcodeForm from "@/components/forms/AddQrcodeForm";

export default {
    name: "AddQrcodePopup",
    components: {
        Popup,
        Button,
        Icon,
        AddQrcodeForm,
    },
    props: {
        organizationId: { type: String, default: "" },
        teams: { type: Array, required: true },
    },
    data() {
        return {
            title: "",
            description: "",
            enableSaving: false,
            options: ["text", "sms", "tel", "mail"],
            type: "text",
            telnr: null,
            message: "",
            email: "",
            subject: "",
            selectedOrganizationId: "",
            teamId: "",
        };
    },
    watch: {
        title() {
            if (this.title.length > 0) {
                this.enableSaving = true;
            }
        },
    },
    beforeMount() {
        if (this.organizationId) {
            this.selectedOrganizationId = this.organizationId;
        }
    },
    methods: {
        save: function () {
            this.$refs.addqrcodeform.save();
        },
        canSave: function (value) {
            this.enableSaving = value;
        },
    },
};
</script>
