<template>
  <div class="pb-5">
    <div v-if="original && original.type && original.type === 'assembly' && $store.getters.getAssemblyPipelineStatus($route.params.id) === 'processing'"
         class="row mt-2 mb-4">
      <div class="col-12 col-md-10 bg-highlight border-radius p-2">
        {{ $t('AssemblyProcessing') }}
      </div>
    </div>
    <template v-if="$store.getters.isSuperAdmin">
    <div class="row">
      <div class="col-6 uppercase">older version</div>
      <div class="col-6 uppercase">{{ isLive ? 'current live' : 'newer' }} version</div>
    </div>
    <div v-for="(value, key) in getFilteredObject(diff)" v-if="diff" :key="key" class="mt-2 row">
      <div class="col-12 darker">{{ key }}</div>
      <div v-if="hasChange(copy, key) && typeof(value) === 'string'" class="col-6 bg-beige">{{ key }}:
        {{ getObjectValue(copy[key], key) }}
      </div>
      <div v-if="hasChange(original, key) && typeof(value) === 'string'"
           :class="['col-6 bg-beige mr-1 ',
        hasChange(original, key) && hasChange(copy, key) ? 'updated' : '']">{{ getObjectValue(original[key], key) }}
      </div>
      <div v-if="key === 'metaSets' && $store.getters.isSFXMember" class="col-12">
        <!--<pre style="color: #fff">{{ compareMetaSets(copy[key], original[key]) }}</pre>-->
        <div v-for="(metaDiff, key1) in compareMetaSets(copy[key], original[key])" :key="key1 + 55">
          <div v-for="(item, index) in metaDiff" v-if="key1 === 'deleted'" :key="index + 1239423" class="row">
            <div class="col-6 bg-beige">+ {{ $t(item.name) }}</div>
            <div class="col-6 bg-beige deleted">- {{ $t(item.name) }}</div>
          </div>
          <div v-for="(item, index) in metaDiff" v-if="key1 === 'added'" :key="index + 1239423" class="row">
            <div class="col-6 bg-beige">-</div>
            <div class="col-6 added">+ {{ $t(item.name) }}</div>
          </div>
        </div>
      </div>
      <div v-if="key === 'metaValues' && $store.getters.isSFXMember" class="col-12">
        <!--<pre style="color: #fff">{{ compareMetaValues(original[key], copy[key])}}</pre>-->
        <div v-for="(metaValDiff, metaValKey) in compareMetaValues(copy[key], original[key])"
             :key="metaValKey + 551234">
          <div v-for="(item, index) in metaValDiff" v-if="metaValKey === 'deleted'" :key="index + 1239423" class="row">
            <div class="col-6 bg-beige deleted"><span class="lighter">+ {{ item.key }}:</span>{{ item.value }}</div>
            <div class="col-6 bg-beige"><span class="lighter">- {{ item.key }}:</span>{{ item.value }}</div>
          </div>
          <div v-for="(item, index) in metaValDiff" v-if="metaValKey === 'added'" :key="index + 1239423" class="row">
            <div class="col-6 bg-beige">-</div>
            <div class="col-6 added"><span class="lighter">{{ item.key }}:</span>{{ item.value }}</div>
          </div>
          <div v-for="(item, index) in metaValDiff" v-if="metaValKey === 'updated'" :key="index + 1239423" class="row">
            <div class="col-6 bg-beige"><span class="lighter">- {{ item.key }}:</span>{{
                copy.metaValues.filter(mtv => {
                  return mtv.metaFieldId === item.metaFieldId
                })[0].value
              }}
            </div>
            <div class="col-6 updated"><span class="lighter">+ {{ item.key }}:</span>{{ item.value }}</div>
          </div>
        </div>
      </div>
      <div v-if="key === 'instances'" class="col-12">
        {{ item.key }} {{ instances }}
      </div>
    </div>
    </template>
    <div class="row">
      <div class="w-100 mt-3">
        <div v-if="!removeButtons"
             class="settings-button d-inline-block mt-2"
             @click="$emit('restore', copy.tag)">
          {{ $t('restoreVersion') }}
        </div>
        <div
            v-if="!removeButtons"
            class="settings-button d-inline-block mt-2 ml-2"
            @click="$emit('delete', copy.tag)">
          {{ $t('deleteVersion') }}
        </div>
        <div v-if="showLoadingPanel" class="row mt-3">
          <loading-panel :time-in-seconds="0" class="col-12" message="pleaseWait"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {diff, addedDiff, deletedDiff, updatedDiff, detailedDiff} from 'deep-object-diff';
// eslint-disable-next-line no-unused-vars
import {_} from 'vue-underscore';
import LoadingPanel from "@/components/LoadingPanel";

export default {
  name: "VersionObject",
  components: {
    LoadingPanel
  },
  props: {
    original: {type: Object, required: true},
    copy: {type: Object, required: true},
    removeButtons: {type: Boolean, default: false},
    showLoadingPanel: {type: Boolean, default: false},
    isLive: {type: Boolean, default: false}
  },
  data() {
    return {
      diff: {},
      exclude: [
          "headers",
          "updatedAt",
          "assetId",
          "tag",
          "userId",
          "id",
          "hasSnapshot",
          "organizationId",
          "sourceProjectId",
          "userName",
          "pipelineStatus",
          "pipelineHasRunningTask",
          "pipelineTask",
          "content"
      ],
      formatting: {
        metavalues: {
          fields: ["value", "key"]
        },
        metasets: {
          fields: ["name"]
        },
        instances: {
          fields: ["name", "instanceId"]
        }
      }
    };
  },
  beforeMount() {
    this.makeDiff();
  },
  methods: {
    /**
     * Get the difference of two objects
     * @returns Object
     *   {
     *     "added": {},
     *     "deleted": {},
     *     "updated": {}
     *   }
     * */
    makeDiff() {
      //console.log(detailedDiff(this.original, this.copy));
      this.diff = this.difference(this.original, this.copy)
    },
    /**
     * Deep diff between two object, using underscore
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    difference(object, base) {

      const changes = (object, base) => (
          _.pick(
              _.mapObject(object, (value, key) => (
                  (value !== base[key]) ?
                      ((_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value) :
                      null
              )),
              (value) => (value !== null)
          )
      );
      return changes(object, base);
    },
    compareMetaValues(metaValues2, metaValues1) {
      let results = {
        added: [],
        deleted: [],
        updated: []
      };
      /**
       * Get all values of metaValues a that:
       *   a: does not exist in b
       *   b: has a different value than b
       * */
      if (metaValues1 && metaValues2) {
        metaValues1.map(metaVal => {
          if (metaValues2.filter(metaVal2 => {
            return metaVal2.metaFieldId === metaVal.metaFieldId && metaVal2.value !== metaVal.value
          }).length) {
            results.updated.push(metaVal);
          } else if (!metaValues2.filter(metaVal2 => {
            return metaVal2.metaFieldId === metaVal.metaFieldId
          }).length) {
            results.added.push(metaVal);
          }
        })
        metaValues2.map(metaVal => {
          if (!metaValues1.filter(metaVal2 => {
            return metaVal2.metaFieldId === metaVal.metaFieldId
          }).length) {
            results.deleted.push(metaVal);
          }

        })
      }

      return results;
    },
    compareMetaSets(metaSets2, metaSets1) {
      let results = {
        added: [],
        deleted: [],
        updated: []
      };
      if (metaSets1 && metaSets2) {
        metaSets1.map(metaVal => {
          if (!metaSets2.filter(metaVal2 => {
            return metaVal2.id === metaVal.id
          }).length) {
            results.added.push(metaVal);
          }
        })
        metaSets2.map(metaVal => {
          if (!metaSets1.filter(metaVal2 => {
            return metaVal2.id === metaVal.id
          }).length) {
            results.deleted.push(metaVal);
          }
        })
      }
      return results;
    },
    getComparedMetaValue(metaSets, metaKey) {
      const res = metaSets.filter(item => {
        return item.name === metaKey
      });
      return res && res[0] ? res[0].name : '-';
    },
    getFilteredObject(object) {
      if (!object) {
        return {}
      }
      const keys = Object.keys(object);
      if (keys) {
        Object.keys(object).map(key => {
          if (this.exclude.includes(key)) {
            delete object[key];
          }
        })
      }
      return object;
    },
    hasChange(object, key) {
      return Object.keys(object).includes(key);
    },
    // eslint-disable-next-line no-unused-vars
    getObjectValue(value, objkey) {
      if (value) {
        if (typeof (value) === 'string') {
          return value;
        } else {
          const tmp = JSON.parse(JSON.stringify(value));

          Object.keys(tmp).map(key => {
            if (!this.formatting[objkey.toLowerCase()]) {
              delete tmp[objkey];
            }
            if (tmp[key]) {
              Object.keys(tmp[key]).map(subkey => {
                if (tmp[key][subkey] && !this.formatting[objkey.toLowerCase()].fields.includes(subkey)) {
                  delete tmp[key][subkey]
                }
              })
              if (!Object.keys(tmp[key]).length) {
                delete tmp[key];
              }
            }
            return key;
          })
          if (!Object.keys(tmp).length) {
            return null;
          }
          return tmp;
        }
      }
      return {};
    }
  }
}
</script>

<style scoped>
.added {
  background-color: #455e26;
  border: 1px solid #618337;
}

.deleted {
  background-color: #ff5100;
}

.updated {
  background-color: #313180;
  border: 1px solid #6a6ade;
}
</style>