<template>
    <div class="slider">
        <loading-spinner v-if="loading" class="white mt-1" />
        <div class="p-1 bg-beige slider-message" v-if="message">{{ message }}</div>
        <div :id="getId"/>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import LoadingSpinner from "../../../LoadingSpinner";
    import noUiSlider from 'nouislider';
    import 'nouislider/distribute/nouislider.css';
    import wNumb from 'wnumb';

    export default {
        name: "NumberRangeFilter",
        components: {
            LoadingSpinner
        },
        props: {
            /**
             * The name of the to be searched field
             * */
            fieldName: {type: String, required: true},
            /**
             * The id of the content (e.g. Asset, Project)
             * */
            contentId: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0}
        },
        data() {
            return {
                localValue: {
                    min: 0,
                    max: 0,
                },
                minValue: null,
                maxValue: null,
                minMaxImported: false,
                sliderObject: null,
                loading: true,
                message: ''
            };
        },
        computed: {
            ...mapState({datasetRowListOptions: state => state.datasetrows.datasetRowListOptions}),
                getId: function() {
                    return this.$route.name + this.id  + 'NumberRangeFilter';
            },
        },
        watch: {
            datasetRowListOptions: {
                deep: true,
                handler(val) {
                    if(val && !this.minMaxImported) {
                        if(!this.localValue.min) {
                            this.localValue.min = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'min', this.fieldName);
                        }
                        if(!this.localValue.max) {
                            this.localValue.max = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'max', this.fieldName);
                        }
                        this.minValue = parseInt(this.$store.getters['getDatasetRowListOptions'](this.contentId, 'min', this.fieldName));
                        this.maxValue = parseInt(this.$store.getters['getDatasetRowListOptions'](this.contentId, 'max', this.fieldName));

                        if(this.maxValue) {
                            this.minMaxImported = true;
                            this.filter(true);
                            this.loading = false;
                            this.mountSlider();
//                            this.sliderObject.set(this.localValue.min, this.localValue.max);
                        } else {
                          this.message = this.$t('noFilterData');
                          this.loading = false;
                        }
                    }
                }
            },
            localValue: {
                deep: true,
                handler() {
                    this.filter(false);
                }
            },
            clear() {
                this.clearValue();
            },
        },
        beforeMount() {
            this.$emit('getMinMax', this.fieldName);
            let localFilter = JSON.parse(window.localStorage.getItem(this.getId));
            if(localFilter && localFilter.min && localFilter.max) {
                this.localValue = localFilter;
            }
        },
        methods: {
            mountSlider: function() {
                var handlesSlider = document.getElementById(this.getId);

                this.sliderObject = noUiSlider.create(handlesSlider, {
                    start: [this.localValue.min, this.localValue.max],
                    range: {
                        'min': [this.minValue],
                        'max': [this.maxValue]
                    },
                    behaviour: 'tap',
                    format: wNumb({
                        decimals:0
                    }),
                    tooltips: [true, wNumb({decimals: 0})],
                });
                this.sliderObject.on('change', () => {
                    let values = this.sliderObject.get();
                    this.localValue.min = values[0];
                    this.localValue.max = values[1];
                    this.filter();
                });
            },
            clearValue: function(initial = true) {
                let filterParamId = this.getId;
                let params = {
                    remove: {
                        numSearch: [filterParamId]
                    }
                };
                if(this.sliderObject) {
                  this.sliderObject.set([this.minValue, this.maxValue]);
                }
                this.$emit('filter', params, initial, this.getId);
            },
            filter: function (initial = false) {
                let filterParamId = this.getId;
                let params = {
                    addObject: {
                        numSearch: {
                            [filterParamId]: {
                                [this.fieldName]: {
                                    value: this.localValue.min,
                                    value2: this.localValue.max,
                                    operator: "bt"
                                }
                            }
                        }
                    },
                };
                /**
                 * sends the filtering content to the parent component
                 * ready to use in a register or loading method
                 * */
                this.$emit('filter', params, initial, this.getId);
                window.localStorage.setItem(this.getId, JSON.stringify(this.localValue));
            },
        }
    }
</script>

<style lang="scss">
    .slider {
        position:relative;
        padding-top:60px;
        padding-left:8px;
        padding-right:9px;
    }
    .noUi-target {
        background: $slider-handle-background-color;
        border: 1px solid $slider-handle-background-color;
        box-shadow:none;
    }
    .noUi-touch-area {
        background: $slider-handle-background-color;
        border: 2px solid darken($slider-handle-background-color, 20%);
        &:focus, &:active {
            -webkit-tap-highlight-color:transparent;
            outline:none;
            box-shadow:none;

        }
    }
    .noUi-horizontal .noUi-handle {
        height:20px;
        width: 25px;
    }
    .noUi-handle {
        border: 1px darken($slider-handle-background-color, 10%);
        box-shadow: none;
        border-radius:0;
        &:focus, &:active {
            outline: none
        }
    }
    .noUi-horizontal {
        height: 8px;
    }
    .noUi-handle:before, .noUi-handle:after {
        height: 7px;
        position:absolute;
        top:50%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
    .noUi-horizontal .noUi-tooltip {
        bottom: 150%;
        background: $slider-tooltip-background-color;
        color: #fff;
        border: 1px solid $slider-border-color;
        border-radius:0;
    }
    .slider-message {
      position:absolute;
      top:15px;
      left:0;
    }
</style>