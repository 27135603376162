<template>
  <div v-if="$store.getters.isSuperAdmin" class="col-6">
    <div class="dashboard-panel">
      <h3>{{ $t('Users')}}</h3>
      <span class="lighter">Users</span><br />
      <b>{{ users.length }}</b><br /><br />
      <div :key="user.id" v-for="user in users">
        <router-link :to="'/admin/users/' + user.id + '/general'">{{ user.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserViewer",
  props: {
    organizationId: {type: String, required:true},
    autoLoad: {type: Boolean, default: false},
  },
  data() {
    return {
      users: {},
      showDetail: false,
      forceReRenderKey: 0
    };
  },
  beforeMount() {
    if(this.autoLoad) {
      this.loadUsers();
    }
  },
  methods: {
    async loadUsers() {
      const users = await this.$store.dispatch('clientLoadUsers', {filter: 'homeOrganizationId eq ' + this.organizationId});
      this.users = users;
      this.forceReRenderKey++;
    },
  }
}
</script>

<style scoped>

</style>