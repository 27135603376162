<template>
    <div :class="['popup-container container-fluid', show ? 'open' : '']" :style="elevatedZIndex ? {zIndex: elevatedZIndex} : {}">
        <portal-target name="colorPickerOverlay" />
        <div class="row">
            <div class="popup border-radius col-12 col-md-8 p-5">
                <div v-if="!isNotClosable" class="popup-close" @click="close">
                    <icon type="close"/>
                </div>
                <div class="popup-content">
                    <slot name="popupMainContent"/>
                    <div class="popup-content-footer">
                        <slot name="popupFooter"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Icon from "./Icon";

    export default {
        name: "Popup",
        components: {
            Icon,
        },
        props: {
            show: {type: Boolean, default: true},
            /**
             * In case there are multiple popups and one needs to be in front
             * **/
            elevatedZIndex: {type: Number, default: null},
            isNotClosable: {type: Boolean, default: false}
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },

    }
</script>

<style lang="scss" scoped>
    .popup-container {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        width: 0;
        height: 0;
        overflow: hidden;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 10000;

        &.open {
            width: 100vw;
            height: 100vh;
        }
    }

    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: var(--vform-editor-ui-secondary-color);
        max-height: 100vh;
        overflow: auto;
    }

    .popup-close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 1.4em;
        color: #fff;
        cursor: pointer;
    }
</style>