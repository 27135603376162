export const alphaNumSpace = function(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /^[\u00C0-\u017Fa-zA-Z0-9 °\-_.]*$/.test(value);
};
export const alphaNum = function(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /^[a-zA-Z0-9-_]*$/.test(value);
};
export const alphaNumDot = function(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /^[a-zA-Z0-9.]*$/.test(value);
};

export const orgName = function(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /^[a-zA-Z0-9]+([a-zA-Z0-9-])*[a-zA-Z0-9]+$/.test(value);
};

export const metaSetName = function(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    //return /^(?!(sfx\.)).*$/.test(value);
    return true;
};
