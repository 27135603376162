<template>
  <div class="w-100">
    <!--Hotspot panel-->
    <div
        :class="['settings-button action-button d-inline-block mb-2 mt-2']"
        @click="addHotspot()">
      {{ $t("vform.addHotspot") }}
    </div>
    <template
        v-for="(slideMeta, index) in config.slidesMeta"
        v-if="slides && slides.length"
        :id="slideMeta.uuid"
    >
      <div
          v-if="slideMeta.uuid === activeSlideUuid && slideMeta.hotspots && slideMeta.hotspots.length > 0"
          :key="index + forceReRenderKey + 3001"
      >
        <div class="col row dflex mt-2">
          <label
              :for="'chkHidePanel'"
              class="container vform-editor-body"
          >{{ $t("Hide Step Panel") }}
            <input
                :id="'chkHidePanel'"
                v-model="slideMeta.hideStepPanel"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="col row dflex mt-2">
          <label
              :for="'chkvStageScene'"
              class="container vform-editor-body"
          >{{ $t("Use vStage Scene") }}
            <input
                :id="'chkvStageScene'"
                v-model="slideMeta.vStageScene"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div v-for="(hotspot, index) in slideMeta.hotspots"
             :key="slideMeta.uuid + index"
             :class="['mt-1', 'hotspot', hotspot.uuid === state.selectedHotspot.uuid ? 'selected' : '']">
          <div
              :class="['settings-button d-inline-block mb-1 mt-1 pt-0 w-100']"
          >
            <div class="clickable pt-2" @click="selectHotspot(hotspot)">
              <div class="d-flex justify-content-between">
                <label v-if="hotspot.uuid !== state.selectedHotspot.uuid" class="clickable">{{
                    hotspot.label.dix[language] ? hotspot.label.dix[language] : `Hotspot ${index}`
                  }} </label>
                <div v-if="hotspot.uuid === state.selectedHotspot.uuid">
                  <icon :size="'1.2'" type="caret-up"/>
                </div>

                <div
                    class="float-right clickable pb-1"
                    @click="deleteHotspot(slideMeta, index)"
                >
                  <icon :size="'1.2'" type="trash"/>
                </div>
              </div>
            </div>
            <div v-if="hotspot.uuid === state.selectedHotspot.uuid" class="mb-2 mt-2 pb-2">
              <div
                  v-for="(key, index2) in Object.keys(hotspot.label.dix)"
                  v-if="language === key && hotspot.label"
                  :key="index2 + 10000001 + forceReRenderKey"
                  class="dflex"
              >
                <div class="col p-0">
                  <label class="vform-editor-label section mt-1">{{ $t("Tooltip") }}</label>
                  <input
                      v-model="hotspot.label.dix[key]"
                      :placeholder="
                        key === 'Unknown'
                            ? placeholders.SHORT
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: hotspot.label.dix['Unknown'],
                              })
                    "
                      :title="hotspot.label.dix['Unknown']"
                      class="mb-0 form-text v-form-label form-text-dark"
                      :maxlength="maxTooltipLength"
                      type="text"
                  />
                  <label v-if="hotspot.label.dix[key].length >= maxTooltipLength" class="mb-0 validation-error">{{$t("maxChars", { num: maxTooltipLength })}}</label>
                </div>
              </div>
              <label class="mt-3 vform-editor-label section">{{ $t("Behavior") }}</label>
              <select v-model="hotspot.hotspotAction" class="custom-select form-text-dark">
                <option v-for="(action, index) in hotspotActions" :key="index" :value="action">
                  {{ $t("vform.hotspot." + action) }}
                </option>
              </select>
              <div v-if="hotspot.hotspotAction === 'goto'">
                <label class="vform-editor-label section">{{ $t("Action") }}</label>
                <select v-model="hotspot.selectedAction" class="custom-select form-text-dark">
                  <option v-for="(action, index) in actions" :key="index" :value="action">
                    {{ $t("vform." + action) }}
                  </option>
                </select>
                <v-form-project-switch
                    v-if="hotspot.selectedAction === 'GoToStep' || hotspot.selectedAction === 'GoToSlide'"
                    :key="forceReRenderKey + 700"
                    :element="hotspot"
                    :organization-id="organizationId"
                    :project-id="projectId"
                    :selected-action="hotspot.selectedAction"
                    :slides-current-project="slides"
                    :steps="config.steps"
                    class="mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <popup
        v-if="showDeletePrompt"
        @close="() => {
                  showDeletePrompt = false;
                  resetDelete();
                }"
    >
      <delete-prompt
          slot="popupMainContent"
          @abort="
              () => {
                  showDeletePrompt = false;
                  resetDelete();
              }"
          @confirm="
              () => {
                  showDeletePrompt = false;
                  deleteHotspot(this.slideToDeleteHotspotFrom, this.hotspotIndexToDelete, true);
              }"
      />
    </popup>
  </div>
</template>

<script>
import {vFormControls, placeholders} from "@/enum";
import Icon from "@/components/Icon";
import vFormProjectSwitch from "./vFormPROJECT_SWITCH";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import {v4 as uuidv4} from "uuid";

export default {
  name: "HotspotsPanel",
  components: {
    Icon,
    vFormProjectSwitch,
    Popup,
    DeletePrompt
  },
  props: {
    config: {type: Object, default: null},
    activeStep: {type: String, default: null},
    activeSlideUuid: {type: String, default: null},
    state: {type: Object, default: null},
    slides: {type: Array, default: null},
    thumbs: {type: Object, default: null},
    targetTeams: {type: Array, default: null},
    projectId: {type: String, default: null},
    organizationId: {type: String, default: null},
    vSTAGEContext: {type: Boolean, default: false},
    language: {type: String, default: null},
  },
  data() {
    return {
      placeholders: placeholders,
      vFormControls: vFormControls,
      forceReRenderKey: 0,
      actions: ["NextSlide", "GoToSlide", "GoToStep"],
      selectedAction: "NextSlide",
      showDeletePrompt: false,
      slideToDeleteHotspotFrom: null,
      hotspotIndexToDelete: null,
      hotspotActions: ["popup", "goto"],
      maxTooltipLength: 60,
    };
  },
  beforeMount() {
    if (this.config.slidesMeta) {
      this.config.slidesMeta.forEach((sm) => {
        sm.hotspots.forEach((hs) => {
          hs.currentLang = this.language;
          //hs.linkedProjectId = this.projectId;
          hs.isCurrentProject = hs.linkedProjectId === this.projectId;
        });
        if (!sm.label) {
          sm.label = {
            dix: {
              Unknown: "",
            },
            imageDix: {
              Unknown: "",
            },
          }
        }
      });
    }
  },
  methods: {
    addHotspot() {
      if (!this.config.slidesMeta) {
        this.$set(this.config, "slidesMeta", []);
      }

      let slideMeta = this.config.slidesMeta.find((s) => s.uuid === this.activeSlideUuid);
      if (!slideMeta) {
        slideMeta = {
          uuid: this.activeSlideUuid,
          hotspots: [],
          learningDots: [],
          hideStepPanel: true,
          vStageScene: true,
        };
        this.config.slidesMeta.push(slideMeta);
      }

      const hotspot = {
        uuid: uuidv4(),
        x: 50,
        y: 50,
        label: {
          dix: {
            Unknown: "",
          },
          imageDix: {
            Unknown: "",
          },
        },
        hotspotAction: 'popup',
        selectedAction: '',
        currentLang: this.language,
        elements: []
      };

      slideMeta.hotspots.push(hotspot);
      this.state.selectedHotspot = hotspot;
      this.$emit('setSlideInfo')
    },
    deleteHotspot(slideMeta, index, confirmed) {
      if (!confirmed && slideMeta) {
        this.slideToDeleteHotspotFrom = slideMeta;
        this.hotspotIndexToDelete = index;
        this.showDeletePrompt = true;
      } else {
        this.slideToDeleteHotspotFrom.hotspots.splice(this.hotspotIndexToDelete, 1);
        if (this.slideToDeleteHotspotFrom.hotspots.length == 0
            && (!this.slideToDeleteHotspotFrom.learningDots || this.slideToDeleteHotspotFrom.learningDots.length === 0)) {
          for (var i = 0; i < this.config.slidesMeta.length; i++) {
            if (this.config.slidesMeta[i].uuid === slideMeta.uuid) {
              this.config.slidesMeta.splice(i, 1);
            }
          }
        }
      }
    },
    resetDelete() {
      this.slideToDeleteHotspotFrom = null;
      this.hotspotIndexToDelete = null;
    },
    selectHotspot(hotspot) {
      if (this.state.selectedHotspot.uuid === hotspot.uuid) {
        this.state.selectedHotspot = {};
      } else {
        this.state.selectedHotspot = hotspot;
      }
    }
  }
};
</script>
<style lang="scss">
.vform-container {
  .step-settings-color-pickr {
    .form-submit-button {
      border: 0 solid transparent;
      font-size: 0.9rem;

      .a {
        border: 0 solid transparent;
        border-radius: 4px;
      }

      .content {
        padding: 5px 8px;
        height: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.hotspot {
  &.selected {
  }
}

.settings-button.action-button {
  float: unset;
}

.validation-error{
  font-size: 0.875em;
  color: orange;
  font-weight: 400;
  text-transform: none !important;
}
</style>
