<template>
  <div
      :class="['color-pickr', popupStyle ? 'popup-style border-radius' : '']"
      :style="{
        top: `${yPosition}px`,
        left: `${xPosition}px`,
        position: positioning
      }"
  >
    <div v-if="popupStyle" class="popup-close" @click="close">
      <icon class="close-color-picker" type="close"/>
    </div>
    <color-picker
        ref="cp"
        :color="color"
        :sucker-area="suckerArea"
        :sucker-canvas="suckerCanvas"
        :sucker-hide="true"
        theme="dark"
        @changeColor="changeColor"
    />
    <div v-if="showSaveButton" class="settings-button d-inline-block mt-3" @click="save">
      <icon type="save"/>
      {{ $t("SAVE") }}
    </div>
  </div>
</template>

<script>
//https://www.npmjs.com/package/@caohenghu/vue-colorpicker
import colorPicker from "@caohenghu/vue-colorpicker";
import Icon from "../../Icon";
import listenerMixinJs from "../../mixins/listenerMixin.js.vue";

export default {
  name: "ColorPicker",
  components: {
    colorPicker,
    Icon,
  },
  mixins: [listenerMixinJs],
  props: {
    popupStyle: {type: Boolean, default: false},
    value: {type: String, default: null},
    showSaveButton: {type: Boolean, default: true},
    hasTransparency: {type: Boolean, default: true},
    ignoreCustomPositioning: {type: Boolean, default: false},
    isPositionAbsolute: {type: Boolean, default: false},
  },
  data() {
    return {
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      originalTop: 0,
      originalLeft: 0,
      xPosition: 0,
      yPosition: 0,
      positioning: 'fixed'
    };
  },
  beforeMount() {
    if (this.value && this.value.startsWith("#")) {
      //this.color = this.value;
      this.color = this.hexToRgbA(this.value);
    } else {
      this.color = this.value;
    }
  },
  mounted() {
    if(this.ignoreCustomPositioning) {
      this.xPosition = this.$el.offsetLeft;
      this.yPosition = this.$el.offsetTop;
      this.positioning = '';
    }
    if (this.isPositionAbsolute) {
      this.positioning = 'absolute';
    }
  },
  methods: {
    positionColorPicker(event) {
      this.$nextTick(() => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Colorpicker component dimensions
        const componentWidth = 276; 
        const componentHeight = 433; 

        let xPosition = event.clientX;
        let yPosition = event.clientY;

        // If the component goes beyond the right edge of the viewport
        if (xPosition + componentWidth > viewportWidth) {
          xPosition = viewportWidth - componentWidth;
        }

        // If the component goes beyond the bottom edge of the viewport
        if (yPosition + componentHeight > viewportHeight) {
          yPosition = viewportHeight - componentHeight;
        }

        // If the component goes beyond the left edge of the viewport
        if (xPosition < 0) {
          xPosition = 0;
        }

        // If the component goes beyond the top edge of the viewport
        if (yPosition < 0) {
          yPosition = 0;
        }

        this.xPosition = xPosition;
        this.yPosition = yPosition;
      });
    },
    deliverColor() {
      if (this.color.startsWith("#")) {
        return this.color;
      } else if (!this.hasTransparency) {
        return "#" + this.rgba2hex(this.color, false);
      } else {
        return "#" + this.rgba2hex(this.color);
      }
    },
    changeColor(color) {
      const {r, g, b, a} = color.rgba;
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.$emit('changeColor', this.deliverColor());
    },
    hexToRgbA(hex) {
      hex = hex.substring(1);
      if (hex.length === 3) {

        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      // Split to four channels
      let c = hex.match(/.{1,2}/g);
      // Function: to decimals (for RGB)
      const d = function (v) {
        return parseInt(v, 16);
      };

      // Function: to percentage (for alpha), to 3 decimals
      //2 decimals .. 3 is not correct.. cannot map consistent bewteen alpha and hex with 3 decimals
      const p = function (v) {
        return parseFloat(parseInt((parseInt(v, 16) / 255) * 1000) / 1000).toFixed(2);
      };

      // Check format: if it's argb, pop the alpha value from the end and move it to front
      let a,
          rgb = [];
      /*if($('#format').val() === 'argb') {
          c.push(c.shift());
        }*/

      // Convert array into rgba values
      a = c[3] ? p(c[3]) : 1;
      const arr = c.slice(0, 3);
      arr.map((item) => {
        rgb.push(d(item));
      });

      return "rgb(" + rgb.join(",") + "," + a + ")";
    },
    rgba2hex(orig, includeTransparency = true) {
      var a,
          rgb = orig
              .replace(/\s/g, "")
              .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
          alpha = ((rgb && rgb[4]) || "").trim(),
          hex = rgb
              ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
              (rgb[2] | (1 << 8)).toString(16).slice(1) +
              (rgb[3] | (1 << 8)).toString(16).slice(1)
              : orig;

      if (alpha !== "") {
        a = alpha;
      } else {
        a = 1;
      }
      if (!includeTransparency) {
        return hex;
      }
      // multiply before convert to HEX
      a = ((a * 255) | (1 << 8)).toString(16).slice(1);
      hex = hex + a;
      return hex;
    },
    /*openSucker(isOpen) {
            if (isOpen) {
                // ... canvas be created
                // this.suckerCanvas = canvas
                // this.suckerArea = [x1, y1, x2, y2]
            } else {
                // this.suckerCanvas && this.suckerCanvas.remove
            }
        },*/
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit('save', this.deliverColor(this.color));
    },
  },
};
</script>

<style lang="scss" scoped>
.color-pickr.popup-style {
  position: fixed;
  background-color: var(--vform-editor-ui-tertiary-color);
  padding: 8px;
  padding-right: 25px;
  z-index: 530;
  top: 0;
  left: 0;

  .hu-color-picker {
    margin-right: 25px;
    min-width: 218px;
  }

  .popup-close {
    position: absolute;
    top: 15px;
    right: 17px;
    z-index: 4;
  }
}

.close-color-picker {
  color: white;
}

.hu-color-picker {
  //also apply the min-width when not in pop-up style, as it affects color pickers used in metafield editor
  min-width: 218px;
}
</style>