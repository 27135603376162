<template>
    <div/>
</template>

<script>
  const touchMap = new WeakMap()
    export default {
        name: 'VuelidateMixin',
        methods: {
          delayTouch($v) {
            $v.$reset()
            if (touchMap.has($v)) {
              clearTimeout(touchMap.get($v))
            }
            touchMap.set($v, setTimeout($v.$touch, 500))
          },
        },

    }
</script>
