<template>
    <div class="">
        <table-widget
                class=""
                :id="'datasetsPerSfxdataTable'"
                :fields="fields"
                :data="$store.getters.getInstancesByListName(connectedInstance)"
                :selected-rows="$store.getters.getInstancesByListName(connectedInstance).selected"
                @unlink="unlink"
                :can-unlink="true"
        />
    </div>
</template>

<script>
    import TableWidget from "../widgets/TableWidget";

    export default {
        name: "Instances",
        components: {
            TableWidget,
        },
        props: {
            id: {type: String, required:true,},
            connectedInstance: {type: String, default: null},
        },
        data() {
            return {
                previewPath: null,
                fields: [
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                    },

                ],
            };
        },
        watch: {
            id: function() {
                this.loadInstances();
            },
            connectedInstance: function(){
                this.loadInstances();
            },
        },
        beforeMount() {
            this.loadInstances();
        },
        methods: {
            unlink: function(item) {
                this.$store.dispatch('removeInstance', {id: item.id, listName: this.connectedInstance});
                this.$emit('clickAsset', item.assetId, 'remove');
            },
            loadInstances: function() {
                let args = {
                    id: this.id,
                    listName: this.connectedInstance,
                    cid: this.connectedInstance,
                    include: ['instanceAndAsset'],
                };
                this.$store.dispatch('loadConnectionsWithInstances', args);
            },
        },
    }
</script>
