<template>
  <div class="row"
  >

    <div v-if="showLoading" class="col-12 col-md-6 mt-3">
      <loading-panel message="pleaseWait"/>
    </div>
    <div class="w-100" />

    <!--FILE-INFO-->
    <import-viewer
        v-if="localContentReady"
        :content="localContent"
        class="col-12 col-md-6"
    />
    <div class="w-100" />
    <div v-if="showPipelineForm()" class="col-12 col-md-5">
      <!--META CONFIG-->
      <div
          v-if="metaDataColumns.length && $store.getters.getAssemblyContent($route.params.id).pipeline.vhubPostProcessors && hasRootFile"
          class="mb-3">
        <meta-config
            ref="MetaConfig"
            :meta-data-columns="metaDataColumns"
            :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
            :teams="teams"
            permission="writeMetaSet"
            @setConfig="setMetaConfig"
        />
      </div>

      <info-panel
          class="new-style smaller-title mb-1 mt-3"
          info-panel-header="Advanced"
          :collapsible="true"
          :initially-collapsed="true"
      >
        <div slot="info" class="p-2">
          <!--COMPLEXITY--->
          <div class="form-item mt-3 mb-3" v-if="$store.getters.isSuperAdmin">
            <label class="mb-5 lighter " for="lod">{{ $t('OverallComplexity') }}</label><br>
            <div v-if="isUpdate" class="darker p-1">
              {{ $t('previousSelection') }}:
              {{ oldContent.pipeline.complexity }}
            </div>
            <vue-slider
                v-model="localContent.pipeline.complexity"
                :interval="1"
                :max="10"
                :min="-10"
                :tooltip-formatter="formatComplexity"
                tooltip="always"
                :marks="complexityMarks"
            >
            </vue-slider>
          </div>

          <!--UP-Axis--->
          <div class="form-item mt-5 mb-4" v-if="$store.getters.isSuperAdmin">
            <label class="low-margin-bottom lighter vform-label white medium" for="upAxis">{{ $t('upAxis') }}</label><br>
            <div v-if="isUpdate" class="darker p-1">
              {{ $t('previousSelection') }}:
              {{ oldContent.pipeline.upAxis }}
            </div>
            <selector
                :values="['auto', 'x', 'y', 'z']"
                translation-prefix=""
                @setValue="(val) => {localContent.pipeline.upAxis = val;}"
                :pre-selected-value="localContent.pipeline.upAxis"
                class="up-axis-selector"
                ref="upAxisSelector" />
          </div>

          <!--UP-Axis--->
          <!--REGEX--->
          <div v-if="isUpdate">
            <label class="low-margin-bottom mt-3 lighter vform-label white medium">{{ $t('useRegex') }}</label>
            <info-helper class="ml-1" text="useRegexInfo"/>
            <universal-selector
                store-name="Regex"
                list-name="importRegex"
                @addItem="addRegex"
                :exclude-from-item-list="localContent.pipeline.updateRegex ? localContent.pipeline.updateRegex : []"
                />
            <div v-if="localContent && localContent.pipeline && localContent.pipeline.updateRegex"
                 v-for="regex in localContent.pipeline.updateRegex"
                 :key="regex.name"
            >
              <tag
                  :is-removable="true"
                  :tag="regex"
                  @removeTag="removeRegex"
              />
            </div>
          </div>
        </div>
      </info-panel>

      <!--PIVOT PLACEMENT--->
      <div class="form-item mt-3">
        <label class="low-margin-bottom lighter vform-label white medium">{{ $t('pivotPlacement') }}</label>
        <info-helper class="ml-1" text="pivotComment"/>
        <div v-if="isUpdate" class="darker p-1">
          {{ $t('previousSelection') }}:
          {{ oldContent.pipeline.pivotPlacement }}
        </div>
        <selector
            :values="pivotPositions"
            translation-prefix="pivot."
            @setValue="(val) => {localContent.pipeline.pivotPlacement = val;}"
            :pre-selected-value="localContent.pipeline.pivotPlacement"
            :include-values="showAllPivotPositions ? [] : ['source', 'center']"
            class="pivot-selector"
            ref="pivotSelector"
        />
        <div v-if="!showAllPivotPositions" class="clickable" @click="showAllPivotPositions = true;"><i>{{ $t('showMorePivotPositions') }}</i></div>
        <div v-else>{{ $t('showingAllPivotsInDropdown')}}</div>
        <!--<select v-if="localContentReady" v-model="localContent.pipeline.pivotPlacement" class="custom-select dark">
          <option v-if="showAllPivotPositions || ['source', 'center'].includes(key)" v-for="(key) in pivotPositions" :key="key" :value="key">{{ $t('pivot.' + key) }}</option>
        </select>-->
      </div>

      <!--LOD-LEVELS--->
      <div class="form-item mt-3">
        <label class="low-margin-bottom lighter vform-label white medium" for="lod">{{ $t('LodLevels') }} (Lod)</label><br>
        <div v-if="isUpdate" class="darker p-1">
          {{ $t('previousSelection') }}:
          {{ oldContent.pipeline.lod }}
        </div>

        <selector
          :values="[0,1,2,3]"
          translation-prefix="lodLevel."
          @setValue="(val) => {localContent.pipeline.lod = parseInt(val);}"
          :pre-selected-value="localContent.pipeline.lod"
          class="lod-selector"
          ref="lodSelector" />
      </div>


      <!--OUTPUT FORMATS--->
      <label class="low-margin-bottom mt-5 lighter vform-label white medium" for="lod">{{ $t('OutputFormats') }}</label>
      <info-helper class="ml-1" text="outputFormatsHelp"/>

      <div class="form-item darker p-1 pt-3 border-radius">
        <div v-for="(item,index) in Object.keys(available3DOutputFormats)" :key="index">
          <div class="dflex ml-2">
            <label :for="item + $vnode.key" class="container"
            >{{ item }}
              <input
                  :id="item + $vnode.key"
                  v-model="selectedOutputFormats[item]"
                  :disabled="item === 'fbx'"
                  type="checkbox"
              >
              <span class="checkmark"/>
            </label>
          </div>
        </div>
      </div>
      <div class="w-100"/>


      <!--EMAIL--->
      <div>
        <label class="low-margin-bottom mt-3 lighter vform-label white medium">{{ $t('email') }}</label>
        <info-helper class="ml-1" text="stepEmailDisclaimer"/>
        <div v-if="isUpdate" class="darker p-1">
          {{ $t('previousSelection') }}:
          {{ oldContent.pipeline.email }}
        </div>
        <input
            v-if="localContentReady"
            v-model="email"
            :class="['form-text form-text-dark bigger w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '']"
            type="text"
            @input="delayTouch($v.email)"
        >
        <div v-if="$v.email.$dirty && !$v.email.email" class="form-error mb-2">
          {{ $t('errors.mustBeAValidEmailAdress') }}
        </div>
      </div>

      <!--VM-->
      <vm-selector key="upper" :show-prices="false" @selected="setVM" class="mt-3" custom-classes="darker" />

      <!--MESSAGES-->
      <div class="mt-3 mb-1 darker p-3 border-radius">
        <h4>{{ $t('Disclaimer') }}</h4>{{ $t('stepFileProcessingDisclaimer') }}
      </div>
      <div v-if="error" class="form-error">{{ error }}</div>

      <!--START TASK-->
      <Button :deactivated="!this.$v.email.email || partIdentifierColumnMissing"
              class="mt-3 icon-rotate-animation make-longer-animation" @click="initiatePiplineFileProcessing">
        {{ $t('importStart') }}
        <icon type="cube"/>
      </Button>
    </div>
    <!--ADVANCED SECTION-->
    <div class="col-12 col-md-4 mt-3 offset-md-1">
      <import-advanced-section
          v-if="localContent && !showLoading"
          :content="localContent"
          :delete-existing-assembly-parts="localContent.pipeline.deleteExistingAssemblyParts"
          :send-mails="localContent.pipeline.sendMails"
          :vm="vm"
          @reImport="reImport"
          @setDeleteExisting="(bool) => {localContent.pipeline.deleteExistingAssemblyParts = bool; updateAssemblyContent()}"
          @setSendMails="(bool) => {localContent.pipeline.sendMails = bool; updateAssemblyContent()}"
          @setVM="setVM"
      />
    </div>
  </div>
</template>

<script>
import {PivotPositions, TaskManagerTaskTypes, available3DOutputFormats, lodExport, extractComplexityLevels, RegexContexts} from "@/enum";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import vmSelector from "../vmSelector";
import {between, email} from "vuelidate/lib/validators";
import InfoHelper from "../InfoHelper";
import Button from "../forms/Button";
import ImportAdvancedSection from "./ImportAdvancedSection";
import MetaConfig from "./MetaConfig";
import ImportViewer from "./ImportViewer";
import Icon from "../Icon";
import LoadingPanel from "../LoadingPanel";
import Selector from "../baseComponents/Selector";
import VueSlider from "vue-slider-component";
import InfoPanel from "../InfoPanel";
import {SpecialUuids} from "@/enum";
import UniversalSelector from "@/components/organizations/UniversalSelector.vue";
import Tag from "@/components/Tag.vue";
export default {
  name: "AssemblyImportConfig",
  components: {
    vmSelector,
    Button,
    InfoHelper,
    ImportAdvancedSection,
    MetaConfig,
    ImportViewer,
    Icon,
    LoadingPanel,
    Selector,
    VueSlider,
    InfoPanel,
    UniversalSelector,
    Tag
  },
  mixins: [VuelidateMixin],
  props: {
    /**
     * assetId {string} - the id of the assembly asset
     * */
    assetId: {type: String, required: true},
    organizationId: {type: String, required: true},
    /**
     * content {object} - the content field of the assembly asset
     * */
    content: {type: Object, required: true},
    /**
     * oldContent {object} - the content field of the assembly asset from a previous import (if available)
     * */
    oldContent: {
      type: Object, default: () => {
        return {}
      }
    },
    /**
     * hasRoot {boolean} - whether the assembly has a root node or not
     * (indicator whether something has already imported or not)
     * */
    hasRoot: {type: Boolean, default: false},
    teams: {
      type: Array, default: () => {
        return []
      }
    },
    pipelineStatus: {type: String, default: ""},
  },
  validations: {
    email: {
      email,
    },
    lod: {
      between: between(0, 5)
    }
  },
  data() {
    return {
      regexOptions: [],
      SpecialUuids: SpecialUuids,
      complexityMarks: {
        '-10': '1',
        '-5': '2',
        '0': '3',
        '5': '4',
        '10': '5'
      },
      showAllPivotPositions: false,
      vm: "",
      showLoading: false,
      buttonsDisabled: false,
      localContentReady: false,
      isProcessing: false,
      localContent: {},

      // pivots
      pivotPositions: PivotPositions,
      lodExport: lodExport,

      // output formats
      available3DOutputFormats: available3DOutputFormats,
      selectedOutputFormats: {"fbx": true, "glb": true},

      partIdentifierColumnMissing: true,

      error: "",

      TaskManagerTaskTypes: TaskManagerTaskTypes,
      metaDataColumns: [],

      hasRootFile: true,

      metaConfig: {},
      email: "",
    };
  },
  computed: {
    isUpdate() {
      return this.hasRoot;
    },
  },
  watch: {
    isUpdate() {
      this.$emit('isUpdate', true);
    },
    content: {
      deep: true,
      handler(content) {
        if (content.pipeline) {
          this.fixContent();

          const {pipeline} = content;
          const {sendMails, deleteExistingAssemblyParts, rootNodeFile, files} = pipeline;
          this.sendMails = sendMails !== undefined ? sendMails : true;
          this.deleteExistingAssemblyParts = deleteExistingAssemblyParts !== undefined ? deleteExistingAssemblyParts : false;
          if (rootNodeFile) {
            if (!(files.length === 1 && files[0].includes('.zip'))) {
              this.checkIfFileExists(rootNodeFile);
            }
          }
        }
      }
    }
  },
  beforeMount() {
    this.fixContent();
    this.checkAutoApplyingRegexes();
  },
  methods: {
    async checkAutoApplyingRegexes() {
      const metaValues = await this.$store.dispatch('clientLoadMetaValues', {
        id: SpecialUuids.REGEX_METASET,
        cid: SpecialUuids.CONTEXT_META_FIELD,
      });
      for(let i = 0; i < metaValues.length; i++) {
        let {value, targetId} = metaValues[i];
        let contexts;
        try {
          contexts = JSON.parse(value);
        } catch (e) {
          console.log('could not parse context object')
        }
        if(contexts) {
          const updateContext = contexts.find(item => {
            return item.realm === RegexContexts.SFX_UPDATE && item.autoApply === true;
          })
          if(updateContext) {
            console.log('auto applying ' + targetId)
            const rgx = await this.$store.dispatch('loadRegex', {id: targetId});
            const {organizationId} = rgx;
            if(organizationId === this.organizationId) {
              this.addRegex(rgx);
            }
          }
        }
      }
      console.log(metaValues);
    },
    removeRegex(item) {
      const {id} = item;
      const index = this.localContent.pipeline.updateRegex.findIndex(el => {
        return el.id === id;
      })
      if(index !== -1) {
        this.localContent.pipeline.updateRegex.splice(index, 1);
      }
    },
    addRegex(item) {
      const {content, name, id} = item;
      if(!this.localContent.pipeline.updateRegex) {
        this.localContent.pipeline.updateRegex = [];
      }
      this.localContent.pipeline.updateRegex.push({regex: content, name, id});
    },
    formatComplexity(val) {
      return extractComplexityLevels[val];
    },
    /**
     * Adds mandatory fields to the content field if missing
     * and sets default values because v-model doesn't work if a property is missing
     * */
    fixContent() {
      this.localContentReady = false;

      this.localContent = {
        pipeline: {
          lod: 2,
          upAxis: 'auto',
          complexity: -5,
          sendMails: true,
          email: this.$store.getters.getUserEmail(),
          pivotPlacement: 'source',
          deleteExistingAssemblyParts: false,
          updateRegex: [],
          ...this.content.pipeline
        },
      };
      this.email = this.localContent.pipeline.email;
      if (this.localContent.pipeline.meta && this.localContent.pipeline.meta.columns) {
        this.metaDataColumns = this.localContent.pipeline.meta.columns;
      } else {
        this.partIdentifierColumnMissing = false;
      }

      this.localContentReady = true;
    },

    /**
     * Checks if the root file in the content field exists
     * if it does not, remove the edit form to start the pipeline
     * */
    async checkIfFileExists(fileName) {
      await this.$store.dispatch('clientGetAssetInfo', {id: this.assetId, key: fileName})
          .catch(() => {
            this.hasRootFile = false;
          })
    },

    /**
     * Gathers necessary data for the content field, checks for errors
     * and starts the task if all is good
     * */
    async initiatePiplineFileProcessing() {
      this.error = '';
      this.buttonsDisabled = true;
      if (this.email) {
        if (!this.$v.email.email) {
          return;
        }
      }
      if (this.localContent) {
        const finalFormats = Object.keys(this.selectedOutputFormats)
            .filter(key => {
              return !!this.selectedOutputFormats[key]
            });
        // trigger saving the metaConfig so we have the current data
        if (this.$refs.MetaConfig) {
          await this.$refs.MetaConfig.save();
        }
        let currMeta = null;
        if (this.metaConfig && Object.keys(this.metaConfig).length) {
          currMeta = {clientMetaFile: this.localContent.pipeline.meta.clientMetaFile, ...this.metaConfig};
        }
        this.localContent.pipeline = {
          status: 'cc: extract procedure initiated',
          ...this.localContent.pipeline,
          user: this.$store.getters.getCurrentUserId,
          email: this.email ? this.email : this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
          outputFormats: finalFormats,
          meta: currMeta
        };

        this.isProcessing = true;
        await this.updateAssemblyContent();
        if (!this.error) {
          await this.startTask();
        }
      }
    },
    resetStatus() {
      this.content.pipeline.status = 'initiallyUploaded';
      this.$store.dispatch('updateAssembly', {
        id: this.assetId,
        content: JSON.stringify(this.content),
      });
      this.$store.dispatch('loadAssembly', {
        id: this.assetId,
        include: 'tags,metaSets'
      });
    },
    /**
     * Starts the pipeline task
     * if it has a root node: update
     * if it has no root node: extract (= initial import)
     * */
    async startTask() {
      this.buttonsDisabled = true;
      this.showLoading = true;
      this.$emit('startingTask');
      await this.$store.dispatch('clientAssetToPipeline', {
        id: this.assetId,
        type: this.hasRoot ? 'update' : 'extract',
        virtualMachine: this.vm,
        config: JSON.stringify({
          ...this.localContent.pipeline,
          sendMails: true,
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        })
      }).then(() => {
        this.$emit('taskStarted')
        this.showLoading = false;
      }).catch(res => {
        this.error = res.response.errors.message;
        this.isProcessing = false;
      })
    },
    /**
     * Starts a reImport task – only working if files are still fully extracted on VM
     * */
    reImport() {
      this.buttonsDisabled = true;
      this.$store.dispatch('clientAssetToPipeline', {
        id: this.assetId,
        type: 'reimport',
        virtualMachine: this.vm,
        config: JSON.stringify({
          sendMails: true,
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en'
        })
      }).catch(res => {
        this.error = res.response.errors.message;
        this.isProcessing = false;
      })
    },
    /**
     * Updates the content field of the asset
     * */
    async updateAssemblyContent() {
      return this.$store.dispatch('updateAssembly', {
        id: this.assetId,
        content: JSON.stringify(this.localContent),
      }).catch(e => {
        this.error = e;
      });
    },
    /**
     * Sets the to be used vm
     * */
    setVM(id) {
      this.vm = id;
    },
    /**
     * Sets the meta config for metadata import
     * */
    setMetaConfig(metaConfig) {
      this.metaConfig = metaConfig;
      this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
    },
    showPipelineForm() {
      return ['new', 'newUpdate'].includes(this.pipelineStatus) && !this.isProcessing;
    }
  }
}
</script>

<style lang="scss" scoped>
.pivot-selector {
  z-index: 250;
  position:relative;
}
</style>