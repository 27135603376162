<template>
<div class="screen-size-selector d-flex">
  <select @change="$emit('setSize', screenSize, { width: width, height: height })" v-model="screenSize" class="p-0 m-0 vform-custom-select">
    <option value="NONE">{{ $t('Select device') }}</option>
    <option value="DEFAULT">{{ $t('FullScreen') }}</option>
    <option :key="key" :value="key" v-for="(key) in Object.keys(screenSizes)">{{ screenSizes[key].name }}</option>
    <option value="CUSTOM">{{ $t('Custom') }}</option>
  </select>
  <div class="d-flex ml-1">
    <div :class="['scape-chooser', screenOrientation === 'landscape' ? 'selected' : 'unselected']"
         @click="setOrientation('landscape')"><icon size="1.6" type="image-landscape" /></div>
    <div :class="['scape-chooser', screenOrientation === 'portrait' ? 'selected' : 'unselected']"
         @click="setOrientation('portrait')"><icon size="1.2" type="image-portrait" /></div>
  </div>
  <div class="d-flex ml-1" v-if="!allowCustomSize && showSizeInPixels">
    <input :disabled="'disabled'" class="form-text v-form-label form-text-dark pixels" v-model.number="deviceWidth" />
    <span class="ml-1 mr-1 ">x</span>
    <input :disabled="'disabled'" class="form-text v-form-label form-text-dark pixels" v-model.number="deviceHeight" />
  </div>
  <div class="d-flex ml-1" v-if="allowCustomSize">
    <input :disabled="allowCustomSize ? null : 'disabled'" class="form-text v-form-label form-text-dark pixels" type="number" v-model.number="width" />
    <span class="ml-1 mr-1 ">x</span>
    <input :disabled="allowCustomSize ? null : 'disabled'" class="form-text v-form-label form-text-dark pixels" type="number" v-model.number="height" />
  </div>
</div>
</template>

<script>
import { screenSizes } from "@/enum";
import Icon from "../../Icon";
export default {
  name: "ScreenSizeSelector",
  components: {Icon},
  data() {
    return {
      screenSizes: screenSizes,
      screenSize: 'DEFAULT',
      screenOrientation: 'landscape',
      allowCustomSize: false,
      deviceWidth: 0,
      deviceHeight: 0,
      width: 1024,
      height: 768,
      showSizeInPixels: false
    };
  },
  watch: {
    screenSize() {
      this.showSizeInPixels = !(this.screenSize === "NONE" || this.screenSize === "DEFAULT");
      this.allowCustomSize = this.screenSize === "CUSTOM";
      if (this.screenSize !== "NONE" && this.screenSize !== "DEFAULT" && this.screenSize !== "CUSTOM") {
        this.deviceWidth = screenSizes[this.screenSize].width;
        this.deviceHeight = screenSizes[this.screenSize].height;
      }

      if (this.screenSize === "CUSTOM") {
        this.$emit('setSize', this.screenSize, { width: this.width, height: this.height });
      }
    },
    width(val){
      if (!val){
        val = 0;
      }
      this.width = parseInt(val);
      this.$emit('setSize', this.screenSize, { width: this.width, height: this.height });
    },
    height(val){
      if (!val){
        val = 0;
      }

      this.height = parseInt(val);
      this.$emit('setSize', this.screenSize, { width: this.width, height: this.height });
    }
  },
  methods: {
    setOrientation(screenOrientation) {
      this.screenOrientation = screenOrientation;
      this.$emit('setOrientation', this.screenOrientation)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-text-dark:disabled{
  background-color: var(--vform-editor-ui-secondary-color) !important;
}
.screen-size-selector {
  .vform-custom-select {
    font-size: 13px;
    border-radius: 4px;
    background-color: var(--vform-editor-ui-secondary-color);
    border-color: var(--vform-editor-ui-secondary-color);
    color: var(--vform-editor-ui-primary-color);
    width: 130px;
  }
}
.scape-chooser {
  padding: 3px;
  margin-right:3px;
  width: 40px;
  height: 25px;
  border-radius: 4px;
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-tertiary-color);
  position:relative;
  cursor:pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &.selected, &:hover {
    background-color: var(--vform-editor-ui-tertiary-color);
    color: var(--vform-editor-ui-secondary-color);
  }
}
.pixels {
  width: 60px !important;
}
</style>