<template>
  <three-columns
      :id="$route.params.id"
      :init-drag-zone-width="15"
      back-button-tab-selector="sfxbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="assemblytab"
      @endDrag="endDrag"
  >
    <div slot="topContent" class="col-12 pl-4">
      <h1>{{ $store.getters.getSfxdataName($route.params.id) }}</h1>
    </div>
    <portal-target slot="leftContent" name="aboveTreeSection"/>
    <template slot="outerLeftContent">
      <div class="dflex pl-4 mt-3">
        <label :for="'showPreview' + $vnode.key" class="container"
        >{{ $t("showPreview") }}
          <input
              :id="'showPreview' + $vnode.key"
              v-model="showPreview"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div :class="['babylon-viewer-export-profile bg-dark', showPreview ? 'open' : 'closed']">
        <!--<div class="lasche" @click="showPreview = !showPreview">{{ $t('Preview') }}</div>-->
        <babylon
            v-if="previewId && previewAssetId && showPreview"
            ref="babylon"
            :asset-id="previewAssetId"
            :instance-id="previewId"
            :storage-source="previewType === 'node' ? 'Instance' : 'Asset'"
            class="babylon"
            source="Instance"
        />
      </div>
    </template>

    <tree-view
        v-if="projectId && hasAccess"
        slot="leftContent"
        :class="activeTab + '-mode'"
        :enable-filtering="!!currentlyEditingFilter"
        :filter-array="$store.getters.getExportFilterById(currentlyEditingFilter)"
        :highlight-filter-array="$store.getters.getMergeById(currentlyEditingFilter)"
        :highlight-string-array="$store.getters.getExportMergeStringById(currentlyEditingFilter)"
        :mode="$store.getters.getExportFilterModeById(currentlyEditingFilter)"
        :project-id="projectId"
        :show-select-box="true"
        :string-array="$store.getters.getExportFilterStringById(currentlyEditingFilter)"
        @setPreviewId="setPreviewId"
    />
    <div v-if="$store.getters.getSfxdataPipelineStatus($route.params.id) === 'finished' && hasAccess" slot="fullContent"
         class="row">
      <div class="w-100"/>
      <div class="col-12 col-lg-6 export-profile-left">
        <info-panel
            :collapsible="true"
            :initially-collapsed="editFormShowing || startFormShowing"
            class="new-style"
            info-panel-header="ExportProfiles"
            preview-id="subversionForm"
        >
          <div slot="info">
            <export-profile-list
                v-if="$store.getters.getSfxdataOrganization($route.params.id)"
                :key="forceReRenderKey"
                :list-id="$route.params.id"
                :organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
                @create="currentlyEditingFilter = 'new'; processingFilterItem = '';"
                @createInstance="(item) => {processingFilterItem = item; forceReRenderKey++;}"
                @edit="(id) => {currentlyEditingFilter = id;}"
            />
          </div>
        </info-panel>
      </div>
      <div v-if="hasAccess" class="col-12 col-lg-6 export-profile-right">
        <info-panel
            :collapsible="true"
            :initially-collapsed="editFormShowing || startFormShowing"
            class="new-style"
            info-panel-header="ExportedProfiles"
            preview-id="subversionForm"
        >
          <div slot="info">
            <export-instance-list
                v-if="projectId && rootNode"
                :key="forceReRenderKey"
                :list-id="$route.params.id + 'instances'"
                :project-id="projectId"
                :root-node="rootNode"
                @started="processingFilterItem = ''; forceReRenderKey++;"
                @updateFilterInstance="updateFilterInstance"
            />
          </div>
        </info-panel>
      </div>
      <div v-if="hasAccess" class="col-12">
        <info-panel
            v-if="processingFilterItem"
            :collapsible="true"
            :initially-collapsed="false"
            class="mt-3 new-style"
            info-panel-header="Preview"
            preview-id="processForm"
        >
          <div slot="info">
            <filter-trigger-form
                v-if="projectId && rootNode && processingFilterItem"
                :key="forceReRenderKey"
                :filter-item="processingFilterItem"
                :instance-id="updateInstanceId"
                :project-id="projectId"
                :root-node="rootNode.id"
                :input-units="inputUnits"
                @abort="processingFilterItem = ''; updateInstanceId = ''; updateAssetId = ''"
                @started="processingFilterItem = ''; forceReRenderKey++;"
            />
          </div>
        </info-panel>
        <info-panel
            v-if="$store.getters.getSfxdataOrganization($route.params.id) && editFormShowing"
            :collapsible="true"
            :initially-collapsed="false"
            class="mt-3 new-style"
            info-panel-header="New"
            preview-id="subversionForm"
        >
          <div slot="info">
            <filter-add-form
                :key="forceReRenderKey"
                :filter-id="currentlyEditingFilter ? currentlyEditingFilter : 'new'"
                :organization-id="$store.getters.getSfxdataOrganization($route.params.id)"
                :teams="$store.getters.getSfxdataTeams($route.params.id)"
                class="ml-2"
                @activeTab="setActiveTab"
                @finished="finishEditing"
            />
          </div>
        </info-panel>
      </div>
    </div>
    <div v-if="!hasAccess && !loading" slot="fullContent" class="row">
      <div class="col-12">
        {{ $t('NoExportProfileAccess') }}
      </div>
    </div>
  </three-columns>
</template>

<script>

import ThreeColumns from "../layouts/ThreeColumns";
import InfoPanel from "@/components/InfoPanel";
import DateMixin from "@/components/mixins/DateMixin.js";
import ExportInstanceList from "@/components/exportProfiles/ExportInstanceList";
import Babylon from "../components/preview/Babylon";
import TreeView from "../components/tree/TreeView";
import {SpecialUuids} from "../enum";
export default {
  name: "SFXLinkedData",
  components: {
    Babylon,
    ExportProfileList: () => import("@/components/exportProfiles/ExportProfileList"),
    ThreeColumns,
    InfoPanel,
    FilterAddForm: () => import("../components/sfxFilter/FilterAddForm"),
    FilterTriggerForm: () => import("../components/sfxFilter/FilterTriggerForm"),
    ExportInstanceList,
    TreeView
  },
  mixins: [DateMixin],
  data() {
    return {
      inputUnits: null,
      updateInstanceId: null,
      showPreview: false,
      activeTab: 'filter',
      loading: false,
      organizationId: '',
      metaSets: [],
      metaSetName: '',
      projectId: '',
      currentlyEditingFilter: '',
      processingFilterItem: '',
      forceReRenderKey: 0,
      hasAccess: false,
      rootNode: '',
      previewId: null,
      previewAssetId: null,
      previewType: ""
    };
  },
  computed: {
    editFormShowing() {
      return (!!this.currentlyEditingFilter);
    },
    startFormShowing() {
      return !!this.processingFilterItem;
    }
  },
  beforeMount() {
    this.loading = true;
    this.id = this.$route.params.id;
    this.init();
  },
  methods: {
    async init() {
     return await this.$store.dispatch('loadSfxdata', {
      id: this.id,
      addPipelineState: true,
      include: ['teams', 'metaValues']
    }).then(async data => {
      this.projectId = this.$store.getters.getSfxdataSourceProjectId(this.id);
      this.organizationId = this.$store.getters.getSfxdataOrganization(this.id);
      this.hasAccess = this.$store.getters.isSuperAdmin ? true : await this.$store.dispatch('checkIfExportProfileFeature', {organizationId: this.organizationId});
      this.loading = false;
      await this.setRootNode();
      const {metaValues} = data;
      this.setInputUnits(metaValues);
    }).catch(e => {
      if (e.status === 401) {
        this.$router.push('/access-denied');
      } else if (e.status === 404) {
        this.$router.push('/not-found')
      }
    });
    },
    setInputUnits(metaValues) {
      if(metaValues && metaValues.length) {
          const index = metaValues.findIndex(item => {
            return item.metaFieldId === SpecialUuids.ASSEMBLY_UNIT_METAFIIELD;
          })
          console.log(index);
          if(index !== -1) {
            this.inputUnits = metaValues[index].value;
          }
        }
    },
    async updateFilterInstance(instanceId, assetId) {
      this.updateInstanceId = instanceId;
      await this.$store.dispatch('loadFilter', {
        id: assetId
      }).then(filter => {
        this.processingFilterItem = filter;
      })
    },
    endDrag() {
      this.$refs.babylon.endDrag();
    },
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },
    setPreviewId(item) {
      if (!item) {
        return;
      }
      this.previewId = item.id;
      this.previewAssetId = item.assetId;
      this.previewType = item.type;
    },
    async setRootNode() {
      return await this.$store.dispatch('clientLoadProjectInstances', {
        id: this.projectId,
        filter: 'type in node model, level eq 1, parentId eq null'
      }).then(rootNode => {
        if (rootNode && rootNode[0] && rootNode[0].id) {
          const root = rootNode[0];
          const {id, assetId} = root;
          this.rootNode = root;
          this.previewId = id;
          this.previewType = 'node';
          this.previewAssetId = assetId;
        }
      })
    },
    finishEditing() {
      this.currentlyEditingFilter = '';
      this.forceReRenderKey++;
    },
  },
}
</script>
<style lang="scss">
.babylon-viewer-export-profile {
  /*position:fixed;
  bottom:0;
  left:0;*/
  width: 90%;
  padding-top: 90%;
  margin: auto;
  z-index: 1500;
  border-radius: 4px;
  -webkit-box-shadow: 6px -7px 6px -5px #2c2b2b;
  box-shadow: 6px -7px 6px -5px #2C2B2BFF;
  border: 1px solid #313131;
  position: relative;

  .babylon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.closed {
    left: -150%;
  }

  &.big {
    width: 600px;
    height: 600px;
  }

  .lasche {
    position: absolute;
    right: -50px;
    top: 25px;
    padding: 5px;
    border-radius: 2px;
    transform: rotate(90deg);
    cursor: pointer;
    background: var(--vform-editor-ui-secondary-color);
    transition: all 300ms ease;
    box-shadow: #0f0f10 5px 5px 6px;

    &:hover {
      background: var(--vform-editor-ui-tertiary-color);
      color: #000;
    }
  }
}

// iPad landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 768px) and (max-device-height: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .export-profile-right, .export-profile-left {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>