<template>
  <one-column-sidebar-left
      back-button-tab-selector="mediabackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="addmediatab"
  >
    <div slot="buttonsRight">
      <Button
          :deactivated="!enableSaving"
          class="mb-3 mt-5"
          @click="save">
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>

    <div slot="mainContent">
      <h1>
        <icon class="mr-2" size="0.9" type="film"/>
        {{ $t('createNewMedia') }}
      </h1>
      <organization-switcher
          class="mb-2"
          @setOrganization="id => {organizationId = id}"
          @setTeam="id => {teamId = id}"
          @unsetTeam="teamId = ''"/>
      <input
          :id="'name'"
          v-model="name"
          :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
          :placeholder="$t('enternamehere')"
          type="text"
          @input="delayTouch($v.name)"
      >

      <div v-if="$v.name.$dirty && !$v.name.required && $v.name.$error" class="form-error">{{
          $t('errors.required')
        }}
      </div>
      <div v-if="$v.name.$dirty && !$v.name.alphaNumSpace" class="form-error">{{ $t('errors.alphaNumSpaceOnly') }}</div>

      <div v-if="$v.name.$dirty && !$v.name.maxLength" class="form-error">{{
          $t('errors.atMostCharacters', {num: 128})
        }}
      </div>

      <div v-if="$v.name.$dirty && !$v.name.minLength" class="form-error">{{
          $t('errors.atLeastCharacters', {num: 3})
        }}
      </div>
      <div v-if="$v.name.$dirty && !$v.name.isUnique" class="form-error">{{ $t('errors.mediaNameAlreadyExists') }}</div>
      <div v-if="error" class="form-error">{{ $t(error) }}</div>
      <div>
        <div class="vform-label white">
          <b>{{ $t('description') }}</b>
        </div>

        <textarea
            :id="'description'"
            v-model="description"
            :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
            :placeholder="$t('enterdeschere')"
            @input="delayTouch($v.description)"
        />
        <div v-if="$v.description.$dirty && !$v.description.maxLength" class="form-error">
          {{ $t('errors.atMostCharacters', {num: 128}) }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="vform-label white mb-0">{{ $t('Type') }}</label>
          <tile-button
              :initial-active-tiles="[type]"
              :tiles="['TileButtons.imgvid', 'Fonts']"
              :values="['imgvid', 'font']"
              @trigger="adaptType"
          />
        </div>
        <div class="w-100 mb-2 mt-4"/>
        <div v-if="isWithFile()" class="col-12 col-md-6 col-lg-4">
          <upload-drop-zone
              ref="uploadComponent"
              :accepted-file-types="getAllowedMediaFileTypes()"
              :asset-description="description"
              :asset-name="name"
              :auto-upload="false"
              :make-chunks="true"
              :organization-id="organizationId"
              :removable="false"
              :team-id="teamId"
              form-id="MediaUpload"
              target-type="Asset"
              @fileReceived="finish"
              @filesInQueue="hasFile = true; checkSaving();"
              @queueEmpty="hasFile = false; checkSaving();"
          />
        </div>
      </div>
      <Button
          :deactivated="!enableSaving"
          class="mb-3 mt-5"
          @click="save">
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import Button from "../components/forms/Button";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import Icon from "../components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../customValidators';
import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
import FileTypeMixin from '@/components/files/FileTypeMixin.js';
import UploadDropZone from "@/components/files/UploadDropZone";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import TileButton from "../components/forms/TileButton";
import {mediaCategories, AssetTypes} from "../enum";
export default {
  name: 'SFXAdd',
  components: {
    OrganizationSwitcher,
    Button,
    OneColumnSidebarLeft,
    Icon,
    UploadDropZone,
    TileButton
  },
  mixins: [
    FileTypeMixin,
    VuelidateMixin
  ],
  data() {
    return {
      hasFile: false,
      id: '',
      enableSaving: false,
      name: '',
      description: '',
      error: null,
      organizationId: null,
      teamId: '',
      type: 'imgvid'
    };
  },
  watch: {
    name() {
      this.error = null;
      this.checkSaving();
    },
    description() {
      this.checkSaving()
    },
    type() {
      this.checkSaving();
    },
    '$v.$invalid': function () {
      this.checkSaving();
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfMediaNameExists', {
          name: value,
          type: 'Asset',
          value: value,
          organizationId: this.organizationId,
        }).then(data => {
          bool = data.length === 0;
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    }
  },
  methods: {
    isWithFile() {
      return this.type === 'imgvid'
    },
    adaptType(item) {
      const {value} = item;
      this.type = value;
    },
    checkSaving() {
      this.enableSaving = this.name && (this.hasFile || !this.isWithFile()) && !this.$v.$invalid;
    },
    async save() {
      this.error = "";
      if(!this.isWithFile()) {
        return await this.$store.dispatch('clientCreateAsset', {
          name: this.name,
          description: this.description,
          organizationId: this.organizationId,
          teams: this.teams,
          mediaCategory: mediaCategories[this.type],
          type: AssetTypes.MEDIA
        }).then(item => {
          this.$router.push(`/library/font/${item.id}/general`);
        }).catch(e => {
          this.error = e;
        })
      }
      if (this.enableSaving) {
        this.$refs.uploadComponent.resumeUpload();
      }
    },
    finish(assetId, push = false) {
      if (push) {
        this.$router.push(`/library/media/${assetId}/general`);
      }
    },
  },
}
</script>
