<template>
  <div></div>
</template>

<script>
import {jsPDF} from "jspdf";
import vFormAndProjectMixin from "./vFormAndProjectMixin.js.vue";
import DateMixinJs from "../mixins/DateMixin.js.vue";

import {
  vFormControls
} from "../../enum";

export default {
  name: "toPDFMixin.js",
  mixins: [vFormAndProjectMixin, DateMixinJs],
  data() {
    return {
      footer: {
        name: "",
        website: "",
        logo: null
      },
      pdf: {
        processing: false,
        ExportProcessing: false,
        topOffset: 20,
        currentPage: 1,
        totalPages: 0,
        fillData: [],
        userId: null,
        sessionId: null,
        footerData: "",
        stepData: "",
        initialDate: null,
        useComponentLogs: false,
        renderGlobalPanels: false,
        useThumbnails: false
      },
      pdfConfig: {
        tileHeight: 40,
        tileWidth: 40,
        evenTileOffsetLeft: 51,
        tileOdd: true,
        buttonImageWidth: 33,
        slideImageWidth: 100,
        standardImageWidth: 80,
        maxLengthForShortAnswerText: 70,
        minHeightForShortAnswerBox: 33,
        fileName: "",
        topOffset: 20,
        processing: false,
        logoId: null,
        elementsLeftOffset: 10,
        FONT_SIZE: 10,
        NEW_PAGE_TOP_OFFSET: 20,
        PAGE_HEIGHT: 250,
        MAX_IMAGE_TOP_OFFSET: 220
      },
    };
  },
  methods: {
    getNewestStepLogEntry(step) {
      if(this.pdf.fillData && this.pdf.fillData.length) {
        const stepItems = this.pdf.fillData.filter(item => {
          return item.target === step.uuid;
        })
        if(stepItems && stepItems.length) {
          const stepItemSorted = stepItems.sort((a,b) => {
            return b.time - a.time;
          })
          return stepItemSorted[0]; // select the newest entry
          }
      }
      return {};
    },
    getNewestLogEntryForElement(element, step) {
      const stepItem = this.getNewestStepLogEntry(step);
      if(stepItem) {
        return this.getMatchingLogForQuestion(element, this.getLabel(element, 'unknown'), stepItem.data);
      }
      return null;
    },
    setFooterInfo(currentItem) {
      if(currentItem && currentItem.userId) {
        this.pdf.footerData = currentItem.userId + ' - ' + this.dateTimeFromUnix(currentItem.time);
        this.pdf.stepData = currentItem.userId + ' - ' + this.dateTimeFromUnix(currentItem.time);
        if(!this.pdf.initialDate) {
          this.pdf.initialDate = this.dateTimeFromUnix(currentItem.time).replaceAll(' ', '--').replaceAll(':', '-');
        }
      }
    },
    getMatchingLogForQuestion(element, question, data) {
      if(data && data.length) {
        const index = data.findIndex(item => {
          return item.elementId === element.uuid;
        })
        if(index !== -1) {
          return data[index]
        }
      }
      return null;
    },
    getMatchingOption(option, data) {
      if(!data) {
        return false;
      }
      const index = data.findIndex(item => {
        return item.optionId === option.uuid;
      })
      if(index !== -1) {
        return !!data[index].selected;
      }
      return false;
    },
    getOffsetTop() {
      return this.pdfConfig.topOffset
    },
    setOffsetTop(num) {
      this.pdfConfig.topOffset = num;
    },
    addOffsetTop(num) {
      this.pdfConfig.topOffset += num;
    },
    resetOffsetTop() {
      this.pdfConfig.topOffset = this.pdfConfig.NEW_PAGE_TOP_OFFSET;
    },
    getImage(config, lang) {
      if (config.label.imageDix) {
        const defaultImg = config.label.imageDix.Unknown;
        const langImg = config.label.imageDix[lang];
        return langImg ? langImg : defaultImg;
      }
    },
    async getAssetUri(logoId) {
      return await this.$store.dispatch("clientLoadAsset", {id: logoId})
      .then(asset => {
        return asset.previewUri;
      }).catch(err => {
        this.$log.debug(err);
        return null;
      });
    },
    async getAssetFileAsBase64(logoId) {
      let previewUri = await this.getAssetUri(logoId);
      if (!previewUri) {
        return;
      }
      let arr = previewUri.split('/');
      let key = arr[arr.length - 1];
      return await this.$store.dispatch('clientDownloadAsset', {id: logoId, key: key})
      .then(async (data) => {
        const arrayBuffer = await new Response(data.text).arrayBuffer();
        return new Buffer(arrayBuffer, "binary").toString("base64");
      }).catch(err => {
        this.$log.error(err)
        return "";
      });
    },
    async setBasicOrganizationData(organizationId) {
      await this.$store.dispatch('loadOrganization', {
        id: organizationId,
        include: 'metaSets'
      }).then(data => {
        this.footer.name = data.displayName;
        this.footer.website = data.url;
        let metaValues = this.smashMetaSets(data.metaSets);
        this.pdf.logoId = metaValues.themeImage;
      })
    },
    setUpLogData(dataConfig = {}) {
      const {fillData, sessionId, userId, useComponentLogs, renderGlobalPanels, useThumbnails} = dataConfig;
      if(fillData && fillData.length) {
        this.pdf.fillData = fillData;
        this.pdf.sessionId = sessionId;
        this.pdf.userId = userId;
        this.pdf.useComponentLogs = useComponentLogs;
        this.pdf.renderGlobalPanels = renderGlobalPanels;
        this.pdf.useThumbnails = useThumbnails;
      } else {
        this.pdf.fillData = [];
        this.pdf.sessionId = null;
        this.pdf.userId = null;
        this.pdf.useComponentLogs = false;
        this.pdf.renderGlobalPanels = false;
        this.pdf.useThumbnails = false;
      }
      this.pdfConfig.topOffset = 20;
    },
    async toPDF(organizationId, projectId, vFormId, slides, config, loadingMode = 'current', dataConfig) {
      let images = {};
      this.setUpLogData(dataConfig);
      this.$emit('addingPage', 0)
      // eslint-disable-next-line no-unreachable
      this.pdf.processing = true;
      this.pdf.totalPages = slides.length;
      this.$emit('totalPages', this.pdf.totalPages)

      await this.setBasicOrganizationData(organizationId);

      const {slideImageWidth} = this.pdfConfig;

      const {logoId} = this.pdf;
      if (logoId) {
        this.pdf.logo = await this.getAssetFileAsBase64(logoId);
      }

      const doc = new jsPDF();
      doc.setFontSize(this.pdfConfig.FONT_SIZE);

      for (let i = 0; i < slides.length; i++) {
        this.pdf.currentPage = i + 1;
        this.$emit('addingPage', this.pdf.currentPage)
        const slide = slides[i];
        const {id} = slide;
        const step = config.steps.find((step) => {
          return step.linkedSlides.find((s) => {
            return s === id
          });
        });
        if (step) {
          const data = this.getNewestStepLogEntry(step);
          if(data) {
            this.setFooterInfo(data);
          }
        }
        if(step && i > 0) {
          this.addPageToPdf(doc);
        } else if(i === 0) {
          this.addHeaderFooter(doc);
        }

        if (step && step.elements) {
          //add step elements
          const slideImageTopOffset = this.getOffsetTop();
          const pageNumber = doc.getCurrentPageInfo().pageNumber;
          this.resetOffsetTop();
          this.addTextToPdf(doc, 10, 'Step Nr: ' + step.step);
          this.addTextToPdf(doc, 10, 'Step Name: ' + (step.name ? step.name : 'step ' + step.step));
          if(this.pdf.stepData) {
            this.addTextToPdf(doc, 10, `Log: ${this.pdf.stepData}`)
          }
          if(this.pdf.renderGlobalPanels) {
            const globalPanels = config.global.panels;
            for(let j = 0; j < globalPanels.length; j++) {
              const {uuid} = globalPanels[j];
              const elements = this.getElementsByPanel(uuid, config.global, step, false);
              const filtered = elements.filter(item => {
                return !item.bottomDropZone;
              })
              await this.addStepElementsToPdf(doc, filtered, step);
            }
            this.addOffsetTop(2);
          }
          for(let j = 0; j < step.panels.length; j++) {
            const {uuid} = step.panels[j];
            const elements = this.getElementsByPanel(uuid, config.global, step, j === 0);
            const filtered = elements.filter(item => {
              return !item.bottomDropZone;
            })
            await this.addStepElementsToPdf(doc, filtered, step);
          }
          this.setOffsetTop(slideImageTopOffset);
          doc.setPage(pageNumber);
        }

        const {useThumbnails} = this.pdf;
        if (!images[id]) {
          images[id] = await this.loadThumbnailNew(projectId, slide, i, {
            loadingMode,
            mini: useThumbnails,
            width: useThumbnails ? 300 : null,
            height: useThumbnails ? 170 : null
          })
          .catch(() => {
            //console.log(e);
            console.log('could not load slide ' + slides[i].id)
          });
        }

        //draw slides on first step page, then continue from last page
        //add step slide images
        if (images[id]) {
          if ((this.getOffsetTop()) > this.pdfConfig.MAX_IMAGE_TOP_OFFSET) {
            this.addPageToPdf(doc);
          }

          const height = this.calculateImageHeight(doc, images[id], slideImageWidth);
          doc.addImage(images[slides[i].id], "png", 100, this.getOffsetTop(), slideImageWidth, height, undefined, 'FAST');
          this.addOffsetTop(height + 5);
          if (this.getOffsetTop() > this.pdfConfig.PAGE_HEIGHT) {
            this.addPageToPdf(doc);
          }
        }
      }

      this.pdf.processing = false;
      this.pdf.ExportProcessing = false;
      const date = this.pdf.initialDate ? this.pdf.initialDate : this.dateFromUnix(Date.now());
      doc.save(`${this.projectName ? this.projectName : 'projekt'}-${date}.pdf`);
      this.pdf.initialDate = null;
    },
    /*renderGlobalsForStep(uuid) {
      if(this.pdf.renderGlobalPanels) {
        // todo

      }
    },*/
    addPageToPdf(doc) {
      doc.addPage();
      this.addHeaderFooter(doc);
      this.resetOffsetTop();
    },
    addHeaderFooter(doc) {
      const {website, name, logo} = this.footer;
      let headerText = website ? `${name} - ${website}` : `${this.projectName ? this.projectName : name}`;
      let footerText = `${this.pdf.footerData}`;
      const headerTextLeftOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2;
      const footerTextLeftOffset = (doc.internal.pageSize.getWidth() - doc.getTextWidth(footerText)) / 2;

      //logo header & footer
      if (logo) {
        const height = this.calculateImageHeight(doc, logo, 15);
        doc.addImage(logo, "png", 10, 2, 15, height);
        doc.addImage(logo, "png", 10, 280, 15, height);
      }

      //header text
      doc.text(headerText, headerTextLeftOffset, 8);
      doc.line(10, 14, 200, 14);

      //footer text
      doc.text(footerText, footerTextLeftOffset, 286);
      doc.line(10, 278, 200, 278);

      //page number
      doc.text(doc.getCurrentPageInfo().pageNumber.toString(), 197, 286);
    },
    calculateImageHeight(doc, imageBase64, width) {
      const imageProps = doc.getImageProperties(imageBase64);
      return imageProps.height * width / imageProps.width;
    },
    addHeadline(doc, element) {
      const {elementsLeftOffset} = this.pdfConfig;
      let fontSize = doc.getFontSize();
      doc.setFontSize(16);
      this.addParagraphToPdf(doc, elementsLeftOffset, this.getLabel(element, this.language), 78);
      doc.setFontSize(fontSize);
    },
    addWebsite(doc, element) {
      if (element.url) {
        const {elementsLeftOffset} = this.pdfConfig;
        this.addParagraphToPdf(doc, elementsLeftOffset, this.getLabel(element, this.language), 78);
        let color = doc.getTextColor();
        doc.setTextColor("#0000FF");
        this.addTextToPdf(doc, elementsLeftOffset, element.url.dix[this.language], 78);
        doc.setTextColor(color);
      }
    },
    /**
     * @returns height of the element
     * */
    async addImage(doc, element, nextElement) {
      const {topOffset, standardImageWidth, tileWidth} = this.pdfConfig;
      const imageId = this.getImage(element, this.language)
      if (imageId) {
        let imageData = this.$store.getters.getFormImageById(imageId);
        if (!imageData) {
          imageData = await this.getAssetFileAsBase64(imageId);
        }

        if (imageData) {
          if (topOffset > this.pdfConfig.MAX_IMAGE_TOP_OFFSET) {
            this.addPageToPdf(doc);
          }

          const pdfImageWidth = element.showastiles ? tileWidth : standardImageWidth;
          const height = this.calculateImageHeight(doc, imageData, pdfImageWidth);
          //check if image would overflow current page
          if (height + topOffset > this.pdfConfig.PAGE_HEIGHT) {
            this.addPageToPdf(doc);
          }

          doc.addImage(imageData, "png", this.getLeftOffset(element, nextElement, height), topOffset, pdfImageWidth, height);
          return height;
        }
      }
      return 0;
    },
    setIsOdd(bool) {
      this.pdfConfig.tileOdd = bool;
    },
    getIsOdd() {
      return this.pdfConfig.tileOdd;
    },
    getLeftOffset(element) {
      const {elementsLeftOffset, tileWidth} = this.pdfConfig;
      if(!element.showastiles) {
        return elementsLeftOffset;
      }
     return  this.getIsOdd() ? elementsLeftOffset : (elementsLeftOffset + tileWidth + 2);
    },
    getLeftButtonImageOffset(element) {
      const {elementsLeftOffset} = this.pdfConfig;
      if(element.showastiles) {
        return this.getIsOdd() ? elementsLeftOffset + 3 : elementsLeftOffset + 44;
      } else {
        return elementsLeftOffset + 3;
      }
    },
    async addShortAnswer(doc, element, data = {}) {
      const {elementsLeftOffset, minHeightForShortAnswerBox} = this.pdfConfig;

      let height = minHeightForShortAnswerBox;
      let text = this.getLabel(element, this.language);
      if (element.isRequired) {
        text += " *";
      }
      this.addOffsetTop(3);
      this.addParagraphToPdf(doc, elementsLeftOffset, text, 78);
      // variant 1: component logs
      if(this.pdf.useComponentLogs && element.logId) {
        height = await this.addShortAnswerWithComponentLogs(doc, element)
        // variant 2: logs from the logger
      } else if(data && data.answers.length) {
        const answer = data.answers[0].answer;
        height = this.addShortAnswerWithDefaultLogs(doc, answer)
        // variant 3: empty textbox
      } else {
        height = this.addShortAnswerWithoutLogs(doc);
      }

      return height;
    },
    addShortAnswerWithoutLogs(doc) {
      const {maxLengthForShortAnswerText, minHeightForShortAnswerBox} = this.pdfConfig;
      this.drawRect(doc, maxLengthForShortAnswerText + 10, this.getOffsetTop(), minHeightForShortAnswerBox);
      return minHeightForShortAnswerBox;
    },
    addShortAnswerWithDefaultLogs(doc, answer) {
      const offset = this.getOffsetTop();
      this.addOffsetTop(-3);
      const {elementsLeftOffset, maxLengthForShortAnswerText} = this.pdfConfig;
      const {height, isNewPage} = this.addUnseparableText(doc, elementsLeftOffset + 5, answer, maxLengthForShortAnswerText, 13)
      console.log(isNewPage)
      this.setOffsetTop(offset); // reset the offset to its previous position
      const paddingTop = 13;
      this.drawRect(doc, maxLengthForShortAnswerText + 10, this.getOffsetTop(), height + paddingTop);
      return height + paddingTop;
    },
    async addShortAnswerWithComponentLogs(doc, element) {
      const {elementsLeftOffset, maxLengthForShortAnswerText, minHeightForShortAnswerBox} = this.pdfConfig;
      let height = 0;
      doc.setFontSize(this.pdfConfig.FONT_SIZE - 2);
      this.drawRect(doc, maxLengthForShortAnswerText + 10, this.getOffsetTop(), minHeightForShortAnswerBox);
      this.addOffsetTop(minHeightForShortAnswerBox + 5);
      const logString = await this.retrieveComponentLogEntries(element.logId, element.uuid);
      if(logString) {
        height = this.addTextToPdf(doc, elementsLeftOffset, logString, maxLengthForShortAnswerText + 10)
      }
      doc.setFontSize(this.pdfConfig.FONT_SIZE);
      return height;
    },
    drawRect(doc, width, offset, height) {
      doc.setDrawColor(120,120,120);
      doc.rect(10, offset, width, height);
      doc.setDrawColor(0,0,0);
    },
    async retrieveComponentLogEntries(logId, elementId) {
      return await this.$store.dispatch('clientLoadDatasetRows', {
        id: logId,
        filter: 'key eq ' + elementId
      }).then(data => {
        const res = data.length ? data.map(item => {return item.data}) : [];
        let fullString = "";
        for(let i = 0; i < res.length; i++) {
          const {userId, time, data} = res[i];
          if(data) {
            const string = `${userId ? userId + ' ' : ''}${time ? time : ''}`;
            const printString = string ? (string + '\n' + data) : data;
            if(i > 0) {
              fullString += '\n\n';
            }
            fullString += printString;
          }
        }
        console.log('logstring: ' + fullString)
        return fullString;
      }).catch(e => {
        console.log(e);
        console.log('no component log data found')
      })
    },
    async getButtonImage(element) {
      let imageId = this.getImage(element, this.language);
      let image = null;
      if(imageId) {
        image = this.$store.getters.getFormImageById(imageId);
        if (!image) {
          image = await this.getAssetFileAsBase64(imageId);
        }
      }
      return image;
    },
    /**
     * @returns height of the element
     * */
    async addButton(doc, element, nextElement) {
      const {tileHeight, tileWidth} = this.pdfConfig;

      const image = await this.getButtonImage(element);
      const text = this.getLabel(element, this.language);
      const buttonOffsetLeft = this.getLeftOffset(element, nextElement);
      const paddingLeft = 5;
      let tempTopOffset = this.getOffsetTop();

      if (element.showastiles) {
        const paddingTop = 6;
        //tile image
        let textHeight = 0;
        let newPageVar = false;
        if (image) {
          const pdfImageWidth = 30;
          const imgHeight = this.calculateImageHeight(doc, image, pdfImageWidth);
          if(this.getOffsetTop() + imgHeight + paddingTop > this.pdfConfig.PAGE_HEIGHT) {
            this.addPageToPdf(doc);
            tempTopOffset = this.getOffsetTop();
          }
          this.addOffsetTop(paddingTop);
          doc.addImage(image, "png", buttonOffsetLeft + paddingLeft, this.getOffsetTop(), pdfImageWidth, imgHeight);
          const {height, isNewPage} = this.addUnseparableText(doc, buttonOffsetLeft + paddingLeft, text, tileWidth - (paddingTop * 2), imgHeight + paddingTop);
          textHeight = height + imgHeight + (paddingTop * 2);
          newPageVar = isNewPage;
        } else {
          const {height, isNewPage} = this.addUnseparableText(doc, buttonOffsetLeft + paddingLeft, text, tileWidth - (paddingTop * 2), paddingTop);
          textHeight = height + (paddingTop * 2);
          newPageVar = isNewPage;
        }
        this.setOffsetTop(tempTopOffset); // reset the offset to its previous position
        const finalHeight = Math.max(textHeight, tileHeight);
        if(newPageVar) {
          tempTopOffset = this.pdfConfig.NEW_PAGE_TOP_OFFSET;
        }
        this.drawButtonRect(doc, buttonOffsetLeft, tempTopOffset, tileWidth, finalHeight);
        return finalHeight;
      } else {
        const paddingTop = 5;
        const maxLength = image ? 40 : 60;

        const pdfImageWidth = 10;
        const textOffsetLeft = image ? buttonOffsetLeft + pdfImageWidth + paddingLeft : buttonOffsetLeft + paddingLeft;
        const {height, isNewPage} = this.addUnseparableText(doc, textOffsetLeft, text, maxLength, paddingTop);
        if(isNewPage) {
          tempTopOffset = this.pdfConfig.NEW_PAGE_TOP_OFFSET;
        }

        const totalHeight = height + 3;
        this.drawButtonRect(doc,10, tempTopOffset, 80, totalHeight);

        if (image) {
          const height = this.calculateImageHeight(doc, image, pdfImageWidth);
          const middle = ((totalHeight)/2 - height/2);
          doc.addImage(image, "png", buttonOffsetLeft + 3, tempTopOffset + middle, pdfImageWidth, height);
        }

        return 0;
      }
    },
    drawButtonRect(doc, offsetY, offsetX, width, height) {
      doc.setDrawColor(220,220,220);
      doc.rect(offsetY, offsetX, width, height);
      doc.setDrawColor(0,0,0);
    },
    addCheckBoxes(doc, element, data) {
      this.addOffsetTop(2);
      const {elementsLeftOffset} = this.pdfConfig;

      let text = this.getLabel(element, this.language);
      if (element.isRequired) {
        text += " *";
      }

      this.addParagraphToPdf(doc, elementsLeftOffset, text, 78);
      for (let idx = 0; idx < element.options.length; idx++) {
        let option = element.options[idx];
        const isSelected = data && data.answers ? this.getMatchingOption(option, data.answers) : false;
        if(isSelected) {
          doc.setFillColor(0, 0, 0);
          doc.rect(elementsLeftOffset + 1, this.getOffsetTop() + 1, 2, 2, 'F');
        }
        doc.rect(elementsLeftOffset, this.getOffsetTop(), 4, 4);
        this.addOffsetTop(3.3);
        this.addTextToPdf(doc, elementsLeftOffset + 6, this.getText(option, this.language), 70);
      }
    },
    addRadioButtons(doc, element, data) {
      const {elementsLeftOffset} = this.pdfConfig;
      this.addOffsetTop(2)
      const radius = 2;

      let text = this.getLabel(element, this.language);
      if (element.isRequired) {
        text += " *";
      }

      this.addParagraphToPdf(doc, elementsLeftOffset, text, 78);
      for (let idx = 0; idx < element.options.length; idx++) {
        let option = element.options[idx];
        const isSelected = data && data.answers ? this.getMatchingOption(option, data.answers) : false;
        if(isSelected) {
          doc.setFillColor(0, 0, 0);
          doc.circle(elementsLeftOffset + radius, this.getOffsetTop() + radius, radius - 1, 'F');
        }
        doc.circle(elementsLeftOffset + radius, this.getOffsetTop() + radius, radius);
        this.addOffsetTop(3)
        this.addTextToPdf(doc, elementsLeftOffset + 6, this.getText(option, this.language), 70);
      }
    },
    addSeparator(doc, element) {
      const {elementsLeftOffset} = this.pdfConfig;
      const height = element.height ? element.height / 10 : 5;
      this.addOffsetTop(height);
      doc.line(elementsLeftOffset, this.getOffsetTop(), elementsLeftOffset + 80, this.getOffsetTop());
      this.addOffsetTop(height);
    },
    async addStepElementsToPdf(doc, elements, step) {
      const {elementsLeftOffset, PAGE_HEIGHT} = this.pdfConfig;
      let tmpElRowLeft = 0;
      let tmpElRowRight = 0;
      let height = 0;
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        const nextElement = elements[i + 1] ? elements[i + 1] : null;
        /*if (i > 0 && element.panelUuid !== elements[i - 1].panelUuid) {
          this.addOffsetTop(10);
        }*/
        const data = this.getNewestLogEntryForElement(element, step);
        const {formElementTypeString} = element;
        if (this.getOffsetTop() + 10 > PAGE_HEIGHT) {
          this.addPageToPdf(doc);
        }
        if (formElementTypeString === vFormControls.HEADLINE) {
          this.addHeadline(doc, element)
        }
        if (formElementTypeString === vFormControls.WEBSITE) {
          this.addWebsite(doc, element)
        }
        if (formElementTypeString === vFormControls.TEXT) {
          this.addParagraphToPdf(doc, elementsLeftOffset, this.getLabel(element, this.language), 78);
        }
        if (formElementTypeString === vFormControls.IMAGE) {
          height = await this.addImage(doc, element, nextElement);
        }
        if ([vFormControls.SHORT_ANSWER, vFormControls.LOGIN].includes(formElementTypeString)) {
          height = await this.addShortAnswer(doc, element, data);
        }
        if (formElementTypeString === vFormControls.BUTTON) {
          height = await this.addButton(doc, element, nextElement);
        }
        if (formElementTypeString === vFormControls.CHECKBOXES) {
          this.addCheckBoxes(doc, element, data);
        }
        if (formElementTypeString === vFormControls.RADIOBUTTONS) {
          this.addRadioButtons(doc, element, data);
        }
        if (formElementTypeString === vFormControls.SEPARATOR) {
          this.addSeparator(doc, element);
        }
        if (formElementTypeString === vFormControls.VERTICAL_SPACE) {
          this.addOffsetTop(element.height ? element.height / 5 : 4);
        }

        // add even odd tile handling
        if(element.showastiles) {
          if(this.getIsOdd()){
            tmpElRowLeft = height;
          } else {
            tmpElRowRight = height;
          }
          this.setIsOdd(!this.getIsOdd());
        } else if(height) {
          this.addOffsetTop(height + 2)
        }
        if((element.showastiles && this.getIsOdd()) || (nextElement && !nextElement.showastiles && (tmpElRowLeft || tmpElRowRight))) {
          this.addOffsetTop(Math.max(tmpElRowLeft, tmpElRowRight) + 2)
          tmpElRowLeft = 0;
          tmpElRowRight = 0;
        }
        // if next element is no tile: add offset top to the elements height
        if (!nextElement || !nextElement.showastiles) {
          this.setIsOdd(true);
        }
        height = 0;
      }
    },
    addParagraphToPdf(doc, leftOffset = 10, text, maxlen = 80, doNotSplitOverPages = false) {
      this.addOffsetTop(3)
      return this.addTextToPdf(doc, leftOffset, text, maxlen, doNotSplitOverPages);
    },
    checkTextLengthCausesOverflow(doc, text, maxlen = 80, correction = 0) {
      let res = doc.splitTextToSize(text, maxlen, null);
      const lineHeight = this.getLineHeight();
      return res.length * lineHeight + this.getOffsetTop() + correction > this.pdfConfig.PAGE_HEIGHT;
    },
    getLineHeight() {
      return this.pdfConfig.FONT_SIZE / 2
    },
    addUnseparableText(doc, leftOffset = 10, text, maxlen = 80, paddingTop = 0) {
      let top = 0;

      if (!text) {
        return {height: 0, isNewPage: false};
      }

      try {
        text = decodeURIComponent(text);
      } catch {
        // do nothing
      }
      const lineHeight = this.getLineHeight();

      let res = doc.splitTextToSize(text, maxlen, null);
      const willTextCausePageOverflow = this.checkTextLengthCausesOverflow(doc, text, maxlen);

      if(willTextCausePageOverflow) {
        this.addPageToPdf(doc);
      }
      if(paddingTop){
        this.addOffsetTop(paddingTop);
      }
      for (let i = 0; i < res.length; i++) {
        doc.text(res[i], leftOffset, this.getOffsetTop());
        top += lineHeight
        this.addOffsetTop(lineHeight)
      }
      return {height: top, isNewPage: willTextCausePageOverflow};
    },
    addTextToPdf(doc, leftOffset = 10, text, maxlen = 80) {
      let top = 0;
      if (!text) {
        return 0;
      }

      try {
        text = decodeURIComponent(text);
      } catch {
        // do nothing
      }

      const lineHeight = this.getLineHeight();

      let res = doc.splitTextToSize(text, maxlen, null);
      const willTextCausePageOverflow = this.checkTextLengthCausesOverflow(doc, text, maxlen);
      if (willTextCausePageOverflow) {
        let maxRowsOnCurrentPage = Math.floor((this.pdfConfig.PAGE_HEIGHT - this.getOffsetTop()) / lineHeight);
        for (let i = 0; i < maxRowsOnCurrentPage; i++) {
          doc.text(res[i], leftOffset, this.getOffsetTop());
          top += lineHeight
          this.addOffsetTop(lineHeight)
        }
        this.addPageToPdf(doc);
        for (let i = maxRowsOnCurrentPage; i < res.length; i++) {
          doc.text(res[i], leftOffset, this.getOffsetTop());
          top += lineHeight
          this.addOffsetTop(lineHeight)
        }
      }
      else {
        for (let i = 0; i < res.length; i++) {
          doc.text(res[i], leftOffset, this.getOffsetTop());
          top += lineHeight
          this.addOffsetTop(lineHeight)
        }
      }
      return top;
    },
  },
}
</script>