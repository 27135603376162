<template>
<div>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="organizationbackbutton"
      sub-menu-selector="adminpanel"
      tab-selector="organizationtab"
      type="organization"
  >
    <div class="row" slot="mainContent">
      <div class="col-12">
        <h1>{{ $t('Dashboard') }} {{ $store.getters.getOrganizationById($route.params.id).displayName }}</h1>
      </div>
      <div v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember" class="col-12">
        <!--<div class="dashboard-panel">
          <div :key="metaField.id" v-for="metaField in metaSet.metaFields">
            <label class="vform-label">{{ metaField.description ? metaField.description : metaField.key }}</label>
            <input class="form-text" type="text" :value="metaField.metaValues[0] ? metaField.metaValues[0] : metaField.defaultValue" />
          </div>
        </div>-->
        <meta-panel
            v-if="metaSet && ($store.getters.isSFXMember || $store.getters.isSuperAdmin)"
            :key="metaSet.id"
            :title="metaSet.description" :fields="metaSet.metaFields" icon-left="folder"
            store-name="Organization"
            :organization-ids="[$route.params.id]"
            @updated="init"
        />
      </div>

      <div class="col-3">
        <div class="dashboard-panel">
          <h3>3D {{ $t('components') }}</h3>
          <span class="lighter">{{ $t('yourComponentLimit') }}</span><br />
          <b>{{ nodeModelAssetsLimit }}</b><br />
          <span v-if="storage" class="lighter">{{ $t('usedComponents') }}</span>
          <h3>{{ nodeModelAssets }}</h3>
          <loading-spinner v-if="loadingStorageUsage" />
          <canvas :id="nodeModelChartId" width="400" height="200"></canvas>
        </div>

        <div class="dashboard-panel mt-4">
          <h3>{{ $t('Total') }}</h3>
          <!--<span class="lighter">Your instances</span><br />-->
          <!--<b>{{ instancesLimit }}</b><br />-->
          <span v-if="storage" class="lighter">{{ $t('usedInstances') }}</span>
          <h3>{{ totalInstances }}</h3>
          <!--<loading-spinner v-if="loadingStorageUsage" />
          <canvas :id="instanceChartId" width="400" height="200"></canvas>-->
        </div>
      </div>

      <div class="col-6">
        <div class="dashboard-panel break-word">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('Users')}}</h3>
            </div>
            <div class="col-6">
              <span class="lighter">{{ $t('numberOfViewers') }}</span><br />
              <b>{{ viewers.length }}</b><br /><br />
              <canvas class="mb-5" :id="userChartId" width="400" height="200"></canvas>
              <span class="lighter">{{ $t('VIEWERS') }} <icon class="ml-1" type="eye" /></span><br />
              <div class="small-font" :key="user.id" v-for="user in viewers">
                <div>
                  {{ user.name }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <span class="lighter">{{ $t('numberOfEditors') }}</span><br />
              <b>{{ editors.length }}</b><br /><br />
              <canvas class="mb-5" :id="userChartId2" width="400" height="200"></canvas>

              <span class="lighter">{{ $t('EDITORS') }} <icon class="ml-1" type="pencil-alt" /></span><br />
              <div class="small-font" :key="user.id + 'viewer'" v-for="user in editors">
                <div>
                  {{ user.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--VM USAGE-->
      <div class="col-3">
        <div class="dashboard-panel">
          <h3>{{ $t('VMUsage') }}</h3>
          <log-summary :auto-load="true" :organization-id="$route.params.id" :logs="logs" />
          <log-viewer v-if="logs" :data="logs" />
        </div>
      </div>

      <div class="col-12 mt-5 pt-3">
        <h2>{{ $t('CurrentItems') }}</h2>
      </div>

      <div class="col-3">
        <div class="dashboard-panel">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('NewestAssemblies')}}</h3>
              <div class="mb-2" :key="a.id + assembliesReRenderKey" v-for="(a, assindex) in assemblies">
                <div class="lighter date-item">{{ dateFromISO(a.createdAt) }}</div>
                <router-link :to="'/library/3d-data/assembly/' + a.id + '/general'">{{ a.name }}</router-link><br />
                <span v-if="a.assets"> ({{ a.assets }} {{ $t('parts') }})</span> <div class="settings-button d-inline-block" @click="loadProjectInstances(assindex, a.sourceProjectId, a.id)" v-else>{{ $t('LoadAmountOfParts') }} <loading-spinner class="ml-1" v-if="isLoading === a.id" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="dashboard-panel">
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('NewestProjects')}}</h3>
              <div class="mb-2" :key="a.id" v-for="a in projects">
                <div class="lighter date-item">{{ dateFromISO(a.createdAt) }}</div>
                <router-link :to="'/project/' + a.id + '/general'">{{ a.name }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--STORAGE-->
      <div class="col-3" v-if="$store.getters.isSuperAdmin && false">
        <div class="dashboard-panel">
          <h3>{{ $t('Storage') }}</h3>
          <span class="lighter">Your storage limit</span><br />
          <b>{{ getReadableFileSize(storageLimit) }}</b><br />
          <span v-if="storage" class="lighter">Used storage</span>
          <h3>{{ storage }}</h3>
          <loading-spinner v-if="loadingStorageUsage" />
          <canvas :id="storageChartId" width="400" height="200"></canvas>
        </div>
      </div>


      <div class="col-3">
        <div class="dashboard-panel">
          <h3>{{ $t('MediaAssets') }}</h3>
          <span class="lighter">{{ $t('yourMediaAssets') }}</span><br />
          <!--<b>{{ mediaAssetsLimit }}</b><br />-->
          <!--<span v-if="storage" class="lighter">Used assets</span>-->
          <h3>{{ mediaAssets }}</h3>
          <loading-spinner v-if="loadingMediaAssets" />
          <!--<canvas :id="mediaChartId" width="400" height="200"></canvas>-->
        </div>
      </div>



    </div>
  </one-column-sidebar-left>
</div>
</template>

<script>
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import LoadingSpinner from "../components/LoadingSpinner";
import filesize from "file-size";
import Chart from 'chart.js/auto';
import LogSummary from "../components/dashboard/LogSummary";
import LogViewer from "../components/dashboard/LogViewer";
import {SpecialUuids} from "@/enum";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import Icon from "../components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";

export default {
  name: "OrganizationDashboard",
  components: {
    LogViewer,
    OneColumnSidebarLeft,
    LoadingSpinner,
    LogSummary,
    MetaPanel,
    Icon
  },
  mixins:[DateMixin],
  data() {
    return {
      isLoading: null,
      assembliesReRenderKey: 0,
      SpecialUuids: SpecialUuids,

      // storage
      loadingStorageUsage: false,
      storage: 0,
      storageLimit: 30000000000,
      storageChartId: null,
      storageChart: null,

      userChartId: null,
      userChart: null,

      userChartId2: null,
      userChart2: null,

      logs: null,

      // 3d assets
      nodeModelChartId: null,
      nodeModelAssetsLimit: 100000,
      nodeModelAssets: null,
      nodeModelChart: null,

      // media
      loadingMediaAssets: false,
      mediaChartId: null,
      mediaAssets: null,
      mediaAssetsLimit: 100000,

      // 3d instances
      instanceChartId: null,
      instancesLimit: 1000,
      instances: null,
      totalInstances: null,
      instanceChart: null,

      metaSet: null,

      users: [],
      editors: [],
      editorLimit: 0,
      viewers: [],
      viewerLimit: 0,

      assemblies: [],
      projects: [],

      organization: {},
      orgUsers: []
    };
  },
  beforeMount() {
    this.storageChartId = Math.random().toString(36).substring(2, 7);
    this.nodeModelChartId = Math.random().toString(36).substring(2, 7);
    this.mediaChartId = Math.random().toString(36).substring(2, 7);
    this.instanceChartId = Math.random().toString(36).substring(2, 7);
    this.userChartId = Math.random().toString(36).substring(2, 7);
    this.userChartId2 = Math.random().toString(36).substring(2, 7);

  },
  mounted() {
    //this.loadVMLogs(); // the summary is enough for now
    this.init();
  },
  methods: {
    async init() {
      await this.loadMetaSet();
      this.loadStorageUsage();
      this.loadAssetNumbers();
      this.load3DInstances();
      this.loadOrganizationUsers();
      this.loadAssemblies();
      this.loadProjects();
    },
    getEditorTeams(teams) {
      return teams ? !!teams.filter(item => {
        return ['editor', 'admin'].includes(item.members[0].relation.role);
      }).length : false;
    },
    async loadOrganizationUsers() {
      await this.$store.dispatch('loadUsers', {
        add: {
          filter: {[this.$route.params.id]: 'homeOrganizationId eq ' + this.$route.params.id},
        }
      }).then(async users => {
        if(users) {
          for(let i = 0; i < users.length; i++) {
            const {id} = users[i];
            const teams = await this.$store.dispatch('clientLoadUserTeams', {
              id: id
            });
            users[i].teams = teams ? teams.filter(item => {return item.organizationId === this.$route.params.id}) : [];
            users[i].isEditor = this.getEditorTeams(users[i].teams);
            users[i].organizationRole = this.orgUsers.filter(item => {
              return item.userId === id;
            })[0].role
            if(['editor', 'admin'].includes(users[i].organizationRole)) {
              users[i].isEditor = true;
            }
          }
          this.users = users;
          if(this.users.length) {
            this.editors = this.users.filter(item => {return item.isEditor});
            this.viewers = this.users.filter(item => {return !item.isEditor});
            this.userChart = this.initChart(this.userChart, this.userChartId, this.viewerLimit, this.viewers.length, ['Viewer Licenses', 'Used'], false);
            this.userChart2 = this.initChart(this.userChart2, this.userChartId2, this.editorLimit, this.editors.length, ['Editor Licenses', 'Used'], false);
          }
        }
      });
    },
    async loadMetaSet() {
      await this.$store.dispatch('loadOrganization', {
        id: this.$route.params.id,
        include: 'metaSets'
      }).then(async data => {
        await this.$store.dispatch('clientLoadOrganizationUsers', {
          id: this.$route.params.id
        }).then(orgUsers => {
          this.orgUsers = orgUsers;
        })
        this.organization = data;
        console.log(data);
        const metaSets = data.metaSets;
        this.metaSet = metaSets.filter(item => {return item.id === this.SpecialUuids['LICENCE_METASET']})[0];
        const storageLimit = this.metaSet.metaFields.filter(item => {return item.id === SpecialUuids.STORAGE_LIMIT_METAFIELD});
        if(storageLimit[0]) {
          const st = storageLimit[0];
          this.storageLimit = this.getValue(st, true);
        }
        const sfxAssetLimit = this.metaSet.metaFields.filter(item => {return item.id === SpecialUuids.ASSET_LIMIT_METAFIELD});
        if(sfxAssetLimit[0]) {
          const st = sfxAssetLimit[0];
          this.nodeModelAssetsLimit = this.getValue(st, true);
        }

        const viewerLimit = this.metaSet.metaFields.filter(item => {return item.id === SpecialUuids.VIEWER_LIMIT_METAFIELD});
        if(viewerLimit[0]) {
          const st = viewerLimit[0];
          this.viewerLimit = this.getValue(st, true);
        }

        const editorLimit = this.metaSet.metaFields.filter(item => {return item.id === SpecialUuids.EDITOR_LIMIT_METAFIELD});
        if(editorLimit[0]) {
          const st = editorLimit[0];
          this.editorLimit = this.getValue(st, true);
        }
      });
    },
    getValue(st, doParseInt = false) {
      if(doParseInt) {
        return st.metaValues && st.metaValues.length ? parseInt(st.metaValues[0].value) : parseInt(st.defaultValue);
      }
     return st.metaValues && st.metaValues.length ? parseInt(st.metaValues[0].value) : parseInt(st.defaultValue);
    },
    async loadVMLogs() {
      this.$store.dispatch('clientLoadVMLogs', {sort: '-createdAt'})
          .then(data => {
            this.logs = data;
          })
    },
    async loadProjectInstances(index, projectId, assemblyId) {
      this.isLoading = assemblyId;
      return await this.$store.dispatch('clientLoadProjectInstances', {
        id: projectId,
        fields: ['id', 'assetId'],
        limit: 0
      }).then(items => {
        const uniqueAssets = [];
        for(let i = 0; i < items.length; i++) {
          const item = items[i];
          if(!uniqueAssets.includes(item.assetId)) {
            uniqueAssets.push(item.assetId)
          }
        }
        this.assemblies[index].assets = uniqueAssets ? uniqueAssets.length : 'N/A';
        this.isLoading = null;
        this.assembliesReRenderKey++;
      })
    },
    async loadAssetNumbers() {
      this.loadingMediaAssets = true;
      return await this.$store.dispatch('clientLoadAssetsCount', {
        filter: 'type eq media,organizationId eq ' + this.$route.params.id,
      }).then(async res => {
        this.mediaAssets = res && res.count ? res.count : 0;

        return this.$store.dispatch('clientLoadAssetsCount', {
          filter: 'type in node model helper,organizationId eq ' + this.$route.params.id,
        });
      })
      .then(res => {
        this.nodeModelAssets = res && res.count ? res.count : 0;
        this.nodeModelChart = this.initChart(this.nodeModelChart, this.nodeModelChartId, this.nodeModelAssetsLimit, this.nodeModelAssets);
        this.loadingMediaAssets = false;
      }).catch(() => {
        this.loadingMediaAssets = false;
      });
    },
    async loadAssemblies() {
      return await this.$store.dispatch('clientLoadAssets', {
        filter: 'type eq assembly,organizationId eq ' + this.$route.params.id,
        sorting: '-createdAt',
        //limit: 6,
      }).then(data => {
        this.assemblies = data;
      })
    },
    async loadProjects() {
      return await this.$store.dispatch('clientLoadProjects', {
        filter: 'type eq vstage,organizationId eq ' + this.$route.params.id,
        sorting: '-createdAt',
        limit: 6,
      }).then(data => {
        this.projects = data;
      })
    },
    async load3DInstances() {
        return await this.$store.dispatch('clientLoadOrganizationInstances', {
          id: this.$route.params.id,
          filter: 'type in node model assembly',
        }).then(data => {
            this.totalInstances = data.count ? data.count : 0;
            this.instanceChart = this.initChart(this.instanceChart, this.instanceChartId, this.instancesLimit, this.totalInstances);
          })
    },
    /***
     * Loads the storage for a given organization
     * */
    async loadStorageUsage() {
      this.loadingStorageUsage = true;
      return this.$store.dispatch('clientListStorageUsage', {
        id: this.$route.params.id
      }).then(data => {
        if(data && data.body) {
          this.storage = data.body.readableSize;
          this.storageNumber = data.body.size
        }
        this.loadingStorageUsage = false;
        if(this.$store.getters.isSFXMember || this.$store.getters.isSuperAdmin) {
          this.storageChart = this.initChart(this.storageChart, this.storageChartId, this.storageLimit, this.storageNumber);
        }
      }).catch(() => {
        this.loadingStorageUsage = false;
      });
    },
    getReadableFileSize(num) {
      return filesize(num, {fixed: 2, spacer: ' '}).human('si');
    },
    initChart(preExistingChart, chartId, limit, total, labels = ['Free', 'Used'], isBytes = true) {
      if(preExistingChart) {
        preExistingChart.destroy();
      }
      const $this = this;
      const toolTip = (tooltipItems) => {
        let sum = 0;
        tooltipItems.forEach(function(tooltipItem) {
          sum += tooltipItem.parsed;
        });

        return isBytes ? $this.getReadableFileSize(sum) : "";
      };

      const el = document.getElementById(chartId);
      let ctx;
      try {
        ctx = el.getContext('2d');
      } catch {
        console.log(chartId + ' could not be initialized')
        return;
      }
      const config = {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: 'Free',
            data: [limit - total < 0 ? 0 : limit - total,total], //todo
            backgroundColor: ['#60b66f', '#e1def3'],
            borderColor: 'transparent',
          }]
        },
        options: {
          responsive: true,
          color: '#fff',
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
              text: 'Storage',
              color: '#fff',
            },
            tooltip: {
              callbacks: {
                footer: toolTip,
              }
            }
          }
        },
      };
      return new Chart(ctx, config);
    },
  }
}
</script>

<style lang="scss" scoped>
  .dashboard-panel {
    background-color: var(--vform-editor-ui-secondary-color);
    padding: 15px;
  }
  .vform-label {
    color: #fff !important;
  }
  .date-item {
    margin-bottom: -4px;
    font-size: 0.8rem;
  }
  .break-word {
    word-wrap: break-word;
  }
  .small-font {
    font-size: 0.8rem;
  }
</style>