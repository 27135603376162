<template>
    <one-column-sidebar-left back-button-tab-selector="materialmapperbackbutton">
        <Button
            slot="buttonsRight"
            class="mb-3 mt-5"
            :deactivated="!enableSaving"
            @click="save"
        >
            <icon v-if="enableSaving" type="save" />
            <icon v-else type="ban" />{{ $t("SAVE") }}
        </Button>

        <navigation-panels-container
            slot="subMenu"
            id="library"
            tab-selector="librarypanel"
        />

        <div slot="mainContent">
            <h1>
                <icon class="mr-2" type="table" size="0.9" />{{ $t("createNewMaterialMapper") }}
            </h1>
            <organization-switcher
                @setOrganization="
                    (id) => {
                        organizationId = id;
                    }
                "
                @setTeam="
                    (id) => {
                        teamId = id;
                    }
                "
                @unsetTeam="teamId = ''"
                class="mb-2"
            />
            <input
                type="text"
                :class="[
                    'form-text mb-3 mt-3',
                    $v.name.$dirty && $v.name.$error ? 'form-group--error' : '',
                ]"
                :id="'name'"
                v-model="name"
                :placeholder="$t('enternamehere')"
                @input="delayTouch($v.name)"
            />

            <div
                class="form-error"
                v-if="$v.name.$dirty && !$v.name.required && $v.name.$error"
            >
                {{ $t("errors.required") }}
            </div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">
                {{ $t("errors.alphaNumSpaceOnly") }}
            </div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">
                {{ $t("errors.atMostCharacters", { num: 128 }) }}
            </div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">
                {{ $t("errors.atLeastCharacters", { num: 3 }) }}
            </div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">
                {{ $t("errors.materialmapperNameAlreadyExists") }}
            </div>

            <div class="inline-edit-label">
                <b>{{ $t("description") }}</b>
            </div>
            <div>
                <textarea
                    :class="[
                        'form-text mb-3',
                        $v.description.$dirty && $v.description.$error
                            ? 'form-group--error'
                            : '',
                    ]"
                    :id="'description'"
                    v-model="description"
                    :placeholder="$t('enterdeschere')"
                    @input="delayTouch($v.description)"
                />
                <div
                    class="form-error"
                    v-if="$v.description.$dirty && !$v.description.maxLength"
                >
                    {{ $t("errors.atMostCharacters", { num: 128 }) }}
                </div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
import Button from "../components/forms/Button";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import NavigationPanelsContainer from "../components/navigation/NavigationPanelsContainer";
import Icon from "../components/Icon";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { alphaNumSpace } from "../customValidators";
import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import { SchemaNames } from "@/enum";

export default {
    name: "MaterialMapperAdd",
    components: {
        Button,
        OneColumnSidebarLeft,
        NavigationPanelsContainer,
        Icon,
        OrganizationSwitcher,
    },
    mixins: [VuelidateMixin],
    data() {
        return {
            nameEdit: true,
            descEdit: true,
            id: "",
            previewUri: "",
            enableSaving: false,
            name: "",
            description: "",
            fileIsReceived: false,
            organizationId: "",
            teamId: "",
        };
    },
    watch: {
        id: function (newValue) {
            if (newValue) {
                this.$store.dispatch("loadDataset", {
                    id: this.id,
                });
            }
        },
        "$v.$invalid": function () {
            this.enableSaving = !this.$v.$invalid;
            this.$emit("enableSaving", !this.$v.$invalid);
        },
    },
    validations: {
        name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(127),
            alphaNumSpace,
            async isUnique(value) {
                // standalone validator ideally should not assume a field is required
                if (value === "") {
                    return true;
                }
                let bool = true;
                await this.$store
                    .dispatch("checkIfDatasetNameExists", {
                        name: value,
                        type: "Dataset",
                        value: value,
                        organizationId: this.organizationId,
                    })
                    .then((data) => {
                        bool = data.length === 0;
                    });
                return bool;
            },
        },
        description: {
            maxLength: maxLength(1024),
        },
    },
    methods: {
        fileReceived: function (assetId, fileName) {
            this.fileIsReceived = true;
            this.$store.dispatch("mediaExternallyCreated", {
                name: fileName,
                id: assetId,
            });
            this.previewUri = assetId + "/" + fileName;
        },
        updateContent: function (args) {
            this[args.field] = args.value;
            this.checkSaving();
        },
        checkSaving: function () {
            this.enableSaving = !!this.name;
        },
        save: async function () {
            if (this.enableSaving) {
                let args = {
                    name: this.name,
                    description: this.description ? this.description : "",
                    type: "tbl",
                    schemaName: SchemaNames.MATERIAL_MAPPER,
                    schemaVersion: "1",
                };

                args.organizationId = this.organizationId;
                if (this.teamId) {
                    args.teams = [this.teamId];
                }
                await this.$store.dispatch("createMaterialmapper", args).then((dataset) => {
                    this.$router.push("/library/materialmappers/" + dataset.id + "/general");
                });
            }
        },
    },
};
</script>
