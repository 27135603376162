import { getLabel } from "../utils";

export const getElementListForm = function(stepFormData) {
    return Object.keys(stepFormData);
}

export const getAllElements = function(globals, steps) {
    let mixed = [];
    for(let i = 0; i < steps.length; i++) {
        const {elements} = steps[i];
        if(elements.length) {
            mixed = mixed.length ? [...mixed, ...elements] : elements;
        }
    }
    const allElements = globals && globals.elements ? globals.elements : [];
    mixed = allElements ? [...mixed, ...allElements] : mixed;
    return mixed;
}

export const getElementQuestion = function(elementId, globals, steps, lang) {
    let response = "-- ";

    const elements = getAllElements(globals, steps);
    const element = elements.length ? elements.find(item => {
        return item.uuid === elementId;
    }) : null;
    if(element) {
        const label = getLabel(element, lang);
        if(label) {
            response = label;
        }
    } else {
        console.log('error, could not find summary question element')
    }
    return response;
}

/**
 * Function takes in all the data contained within the form summary element.
 * It then formats all the given data into a Javascript object eligible for the /performFormCheckout POST Endpoint.
 *
 * @param elementId
 * @param stepFormData
 * @returns Object
 */
export const getElementInfoForCheckout = function(elementId, stepFormData) {
    let item = stepFormData[elementId] ? stepFormData[elementId] : [];
    if(Array.isArray(item) && item.length) {
        return item.map(data => ({
            id: data.uuid,
            selected: data.isSelected,
            text: data.text.dix.Unknown,
        }));
    } else if (item && item.length) {
        return item;
    }
    return '-'
}

export const getElementInfo = function(elementId, stepFormData, lang) {
    let item = stepFormData[elementId] ? stepFormData[elementId] : [];
    if(Array.isArray(item) && item.length) {
        item = item.filter(sub => {
            return sub.isSelected;
        })
        if(!item.length) {
            return '-';
        }
        const names = item.map(sub => {
            return this.getOptionName(sub, lang);
        })
        return names.length ? names.join('\n') : null;
    } else if(item && item.length) {
        return item;
    }
    return '-';
}