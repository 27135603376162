<template>
<div class="regex-item">
  <h3>{{ name ? name : 'unknown regex'}}</h3>
  <div v-if="edit">
    <h4>Regex</h4>
    <input placeholder="name" class="form-text form-text-dark mt-2" type="text" v-model="name" />
    <input placeholder="regex" class="form-text form-text-dark mt-2"  type="text" v-model="content" /><br />
    <h4>Contexts</h4>
    <context-editor
        v-if="regexItem"
        ref="contextEditor"
        :asset-id="regexItem.id"
        :meta-values="regexItem.metaValues ? regexItem.metaValues : []"
        :edit-mode="true"
        class="w-50"
    /><br />
    <div @click="createRegex" class="settings-button d-inline-block">{{ $t('save') }}</div>
    <div @click="cancel" class="settings-button d-inline-block ml-1">{{ $t('cancel') }}</div>
    <div v-if="regexItem && regexItem.id" @click="deleteRegex" class="settings-button d-inline-block ml-1">{{ $t('delete') }}</div>
  </div>
  <div v-else>
    <h4>Regex</h4>
    {{ content }}
    <h4 class="mt-4">Contexts</h4>
    <context-editor
        v-if="regexItem"
        ref="contextEditor"
        :asset-id="regexItem.id"
        :meta-values="regexItem.metaValues ? regexItem.metaValues : []"
    /><br />
    <div @click="edit = true" class="settings-button d-inline-block mt-2">{{ $t('edit') }}</div><br />
  </div>
</div>
</template>

<script>
import {AssetTypes} from "@/enum";
import ContextEditor from "@/components/ContextEditor.vue";
import regex from "@/views/Regex.vue";
export default {
  name: "RegexItem",
  computed: {
    regex() {
      return regex
    }
  },
  components: {ContextEditor},
  props: {
    edit: {type: Boolean, default: false},
    organizationId: {type: String, required: true},
    teams: {type: Array, required: true},
    regexItem: {type: Object, default: () => {return {}}}
  },
  data() {
    return {
      name: '',
      content: ''
    };
  },
  beforeMount() {
    this.setLocalItems();
  },
  methods: {
    setLocalItems() {
      if(this.regexItem && Object.keys(this.regexItem).length) {
        const keys = Object.keys(this.regexItem);
        try {
          this.regexItem.content = JSON.parse(this.regexItem.content)
        } catch {
          console.log('could not jsonparse regex')
        }
        for(let i = 0; i < keys.length; i++) {
          const key = keys[i];
          this[key] = this.regexItem[key];
        }
      }
    },
    async deleteRegex() {
      if(this.regexItem.id) {
        await this.$store.dispatch('deleteRegexs',
          [this.regexItem.id]
        )
      }
      this.$emit('deleted')
    },
    cancel() {
      this.edit = false;
      this.$emit('cancel');
    },
    async createRegex() {
      if(this.regexItem.id) {
        await this.$store.dispatch('updateRegex', {
          id: this.regexItem.id,
          name: this.name,
          content: JSON.stringify(this.content),
          teams: this.teams,
        })
        await this.$refs.contextEditor.saveContextValue();
      } else {
        await this.$store.dispatch('createRegex', {
          name: this.name,
          content: JSON.stringify(this.content),
          organizationId: this.organizationId,
          teams: this.teams,
          type: AssetTypes.REGEX
        })
      }
      // todo: if context editor has any changes: save the metaValues as well
      this.$emit('saved')
    }
  }
}
</script>

<style scoped lang="scss">
.regex-item {
  input {
    max-width: 500px;
  }
}
</style>