<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <h1>
        <icon class="mr-1" size="0.9" type="user"/>
        {{ $t('createNewUser') }}
      </h1>
    </div>

    <div class="col-12 col-md-8">
      <label class="mt-3">{{ $t('username') }}*</label>
      <input v-model="username"
             :class="['form-text', $v.username.$dirty && $v.firstName.$error ? 'form-group--error' : '']"
             type="text"
             @input="delayTouch($v.username)"
      >
      <div v-if="$v.username.$dirty && !$v.username.minLength" class="form-error">
        {{ $t('errors.atLeastCharacters', {num: 3}) }}
      </div>
      <div v-if="$v.username.$dirty && !$v.username.isUnique" class="form-error">{{
          $t('errors.userNameAlreadyExists')
        }}
      </div>
    </div>

    <div class="w-100"/>
    <div class="col-12 col-md-4">
      <label>{{ $t('firstName') }}*</label>
      <input v-model="firstName"
             :class="['form-text', $v.firstName.$dirty && $v.firstName.$error ? 'form-group--error' : '']"
             type="text"
             @input="delayTouch($v.firstName)"
      >
      <div v-if="$v.firstName.$dirty && $v.firstName.$error" class="form-error">
        {{ $t('errors.atLeastCharacters', {num: 3}) }}
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="w-100"/>
      <label>{{ $t('lastName') }}*</label>
      <input v-model="lastName"
             :class="['form-text', $v.lastName.$dirty && $v.lastName.$error ? 'form-group--error' : '']"
             type="text"
             @input="delayTouch($v.lastName)"
      >
      <div v-if="$v.lastName.$dirty && $v.lastName.$error" class="form-error">
        {{ $t('errors.atLeastCharacters', {num: 3}) }}
      </div>
    </div>
    <div class="w-100"/>
    <div class="col-12 col-md-8">
      <label>{{ $t('email') }}*</label>
      <input v-model="email"
             :class="['form-text', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '']"
             type="text"
             @input="delayTouch($v.email)"
      >
      <div v-if="$v.email.$dirty && !$v.email.email" class="form-error">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
      <div v-if="$v.email.$dirty && !$v.email.isUnique" class="form-error">{{
          $t('errors.emailAddressAlreadyExists')
        }}
      </div>
    </div>
    <div class="w-100"/>
    <div class="col-12 col-md-4">
      <label>{{ $t('password') }}*</label>
      <input v-model="password"
             :class="['form-text mb-2', ($v.password.$dirty && $v.password.$error) || ($v.password2.$dirty && $v.password2.$error) ? 'form-group--error' : '']"
             :type="showPassword ? 'text' : 'password'"
             @input="delayTouch($v.password)"
      >
      <input v-model="showPassword" type="checkbox"/>{{ $t('showPassword') }}
      <password-checker
          :password="password"
          @status="state => setPwdState(state)"
      />
    </div>
    <div class="col-12 col-md-4">
      <label>{{ $t('repeatPassword') }}*</label>
      <input v-model="password2"
             :class="['form-text', $v.password2.$dirty && $v.password2.$error ? 'form-group--error' : '']"
             type="password"
             @input="delayTouch($v.password2)"
      >
      <div v-if="$v.password2.$dirty && $v.password2.$error" class="form-error">
        {{ $t('errors.bothPasswordsMustBeTheSame') }}
      </div>
    </div>

    <div class="form-section col-12 col-md-8">
      <info-panel preview-id="something">
        <div slot="info">
          <h3>{{ $t('SelectOrganizations') }}</h3>
          <p>{{ $t('UserCreationHint') }}</p>
          <organization-tag
              v-for="org in selectedOrgs"
              :key="org.id"
              :is-default="defaultOrganizationId === org.id"
              :is-editable="false"
              :is-removable="!$store.getters.isSuperOrg(org.id)"
              :org="org"
              :show-app-panel="false"
              icon-left="user"
              view-style="table"
              @removeorg="(obj) => {removeOrg(obj)}"
              @setDefaultItem="(id) => {makeOrgDefault(id, {default: true})}"
              @update="(orgId,item) => {updateOrg(orgId, item)}"
          />
          <universal-selector
              :afterQueryFilter="afterQueryFilter"
              :exclude-from-item-list="selectedOrgs"
              class="mt-2"
              filter-attribute="displayName"
              list-name="userAddOrgs"
              store-name="UserAdminOrganization"
              tag-icon="building"
              @addItem="addOrg"
          />
          <div v-if="$v.selectedOrgs.$dirty && $v.selectedOrgs.$error" class="form-error">{{
              $t('errors.required')
            }}
          </div>

        </div>
      </info-panel>

    </div>
    <div class="w-100"/>
    <div class="col-12">
      <div class="form-error p-2 mt-2" v-if="error">
        {{ error }}
      </div>
      <Button
          :deactivated="!enableSaving"
          class="mb-3 mt-3"
          type="button"
          @click="save"
      >
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>

  </div>
</template>

<script>
import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
import {mapState} from 'vuex';
import Button from "./Button";
import Icon from "../Icon";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import UniversalSelector from "@/components/organizations/UniversalSelector";
import {AdminData} from "@/enum";
import OrganizationTag from "@/components/organizations/OrganizationTag";
import InfoPanel from "@/components/InfoPanel";
import PasswordChecker from "@/components/forms/PasswordChecker";
import ErrorMixinJs from "../mixins/ErrorMixin.js";

export default {
  name: "AddUser",
  components: {
    Button,
    Icon,
    UniversalSelector,
    OrganizationTag,
    InfoPanel,
    PasswordChecker
  },
  mixins: [VuelidateMixin,ErrorMixinJs],
  data() {
    return {
      AdminData: AdminData,
      enableSaving: false,
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      passwordError: '',
      password: '',
      password2: '',
      error: '',
      orgs: [],
      selectedOrgs: [],
      defaultOrganizationId: '',
      showPassword: false,
      passwordOkay: false,
    };
  },
  computed: mapState({
    usersTmp: state => state.users.usersTmp,
    globalUser: state => state.users.globalUser,
  }),
  watch: {
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
    usersTmp(newValue) {
      if (newValue['last_inserted']) {
        let id = newValue['last_inserted'];
        this.$router.push('/admin/users/' + id + '/general');
      }
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(3),
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfUserNameExists', {value: value}).then(data => {
          bool = data.length === 0;
        });
        return bool;
      }
    },
    firstName: {
      required,
      minLength: minLength(3)
    },
    lastName: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email,
      async isUnique(value) {
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfUserEmailExists', value).then(data => {
          bool = data.length === 0;
        });
        return bool;
      }
    },
    password: {
      required,
    },
    password2: {
      required,
      sameAsPassword: sameAs('password')
    },
    selectedOrgs: {
      required,
    },
    passwordOkay: {
      special() {
        return this.passwordOkay;
      }
    }
  },
  methods: {
    afterQueryFilter(item) {
      if(this.$store.getters.isSuperAdmin) {
        return true;
      }
      const selected = this.globalUser.organizations.filter(org => {
        return org.id === item.id;
      })
      if(selected.length) {
        if(selected[0].members.role === 'admin') {
          return true;
        }
      }
      return false;
    },
    updateOrg(orgId, item) {
      console.log(item);
    },
    makeOrgDefault(orgId) {
      this.defaultOrganizationId = orgId;
    },
    addOrg(org, role = 'none') {
      this.selectedOrgs.push({id: org.id, displayName: this.$store.getters.getOrganizationName(org.id), role: role});
      if (this.selectedOrgs.filter(item => {
        return item.id !== AdminData.vHubOrganizationId
      }).length === 1) {
        this.defaultOrganizationId = org.id;
      }
    },
    removeOrg(org) {
      this.selectedOrgs = this.selectedOrgs.filter(orgUser => orgUser.id !== org.id);
    },
    setPwdState(state) {
      this.passwordOkay = state;
    },
    save: function () {
      this.error = '';
      if (!this.$v.$anyError) {
        if (!this.defaultOrganizationId) {
          this.defaultOrganizationId = this.selectedOrgs[0].id;
        }
        let args = {
          name: this.username,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          organizationUsers: this.selectedOrgs.map(item => {
            return {
              organizationId: item.id,
              role: 'none',
              default: item.id === this.defaultOrganizationId
            }
          }),
          homeOrganizationId: this.defaultOrganizationId
        };
        this.$store.dispatch('createUser', args)
            .catch(e => {
              this.error = this.getErrorContent(e)
            });
      }
    },
  }
}
</script>