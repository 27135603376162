<template>
  <div>
    <div class="bg-dark border-radius p-2">
      <h3>{{ $t('addGlbFiles') }}</h3>
      <input :disabled="showLoading" v-model="email" class="form-text form-text-dark mb-2"/>
      <vm-selector v-if="!showLoading" class="mb-3 w-100" custom-classes="bright" @selected="setVM" />
      <label class="vform-label white">{{ $t('ScalingUnits') }}</label>
      <select v-model="selectedInputUnit" class="custom-select mb-2">
        <option :value="input" :key="input" v-for="input in inputUnits">{{ input }}</option>
      </select>
      <div v-if="!showLoading" @click="startTask" class="settings-button d-inline-block">{{ $t('start') }}</div>
      <loading-panel v-if="showLoading" />
    </div>
  </div>
</template>

<script>
import {TaskManagerTaskTypes} from '@/enum';
import VmSelector from "../vmSelector";
import LoadingPanel from "../LoadingPanel";
export default {
  name: "GlbConversionTrigger",
  components: {VmSelector,LoadingPanel},
  props: {
    /**
     * assetId {string} - the id of the assembly asset
     * */
    assetId: {type: String, required: true},
    type: {type: String, default: 'assembly'}
  },
  data() {
    return {
      email: "",
      TaskManagerTaskTypes: TaskManagerTaskTypes,
      showLoading: false,
      inputUnits: [
          "Millimeters",
          "Centimeters",
          "Meters",
          "Kilometers",
          "Inches",
          "Feet",
          "Miles"
      ],
      selectedInputUnit: "Meters"
    };
  },
  beforeMount() {
    this.email = this.$store.getters.getUserEmail();
  },
  methods: {
    setVM(id) {
      this.vm = id;
    },
    /**
     * Starts the pipeline task
     * if it has a root node: update
     * if it has no root node: extract (= initial import)
     * */
    async startTask() {
      this.buttonsDisabled = true;
      this.showLoading = true;
      this.$emit('startingTask');
      await this.$store.dispatch('clientAssetToPipeline', {
        id: this.assetId,
        type: this.type === 'assembly' ? TaskManagerTaskTypes.CONVERT_ASSEMBLY : TaskManagerTaskTypes.CONVERT_HELPER,
        virtualMachine: this.vm,
        config: JSON.stringify({
          sendMails: true,
          formats: ['glb'],
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
          inputUnits: this.selectedInputUnit
        })
      }).then(() => {
        this.$emit('taskStarted')
        this.showLoading = false;
      }).catch(res => {
        this.error = res.response.errors.message;
        this.isProcessing = false;
      })
    },
  }
}
</script>