<template>
  <video
      v-if="uniqueId"
      :id="uniqueId"
      :class="classes"
      :poster="poster"
      preload="none"
      ref="video"
      :controls="false"
      :style="{
              width: width ? width + 'px !important' : '150% !important',
              height: height ? height + 'px !important' : '120% !important'
            }"
      width="500"
      @click.prevent="doNothing"
      @canplaythrough="canPlayThrough = true"
      @canplay="canPlayThrough = true"
      playsinline
      muted
      :src="url"
  />
</template>
<script>
export default {
  name: "BackgroundVideoItem",
  props: {
    url: {type: String, required: true},
    poster: {type: String, required: true},
    canPlay: {type: Boolean, default: false},
    classes: {type: String, default: ""},
    width: {type: Number, default: 0},
    height: {type: Number, default: 0},
    shouldBePlaying: {type: Boolean, default: false},
    startTime: {type: Number, default: 0},
    targetTime: {type: Number, default: 0}
  },
  data() {
    return {
      currentTime: 0,
      canPlayThrough: false,
      uniqueId: null
    }
  },
  watch: {
    shouldBePlaying(val) {
      this.setUpCanPlayThrough();
      if(val && this.canPlayThrough) {
        this.play();
      } else if(!val) {
        this.stop();
      }
    },
    canPlayThrough(val) {
      this.setUpCanPlayThrough();
      if(val && this.shouldBePlaying) {
        this.play();
      }
    }
  },
  beforeMount() {
    this.uniqueId = Math.floor(Math.random() * 16777215).toString(16);
  },
  mounted() {
    this.$refs.video.load();
    this.$nextTick(function () {
      console.log('calling next tick...')
      this.setUpCanPlayThrough();
      // Code that will run only after the
      // entire view has been rendered
      this.setUpPlayer();
      if(this.startTime !== 0) {
        this.setTime(this.startTime);
      }
      if(this.shouldBePlaying) {
        this.play();
      }
    })
  },
  methods: {
    /**
     * Used to prevent clicks on video tag
     * */
    doNothing() {},
    /**
     * Sets the current time of a video
     * @params time - time in seconds
     * */
    setTime(time) {
      let player = this.getPlayer();
      if (player) {
        // player.pause();
        player.currentTime = time;
      }
    },
    getPlayer() {
      return this.$refs.video;
    },
    isPlaying(player) {
      return !!(player.currentTime > 0 && !player.paused && !player.ended && player.readyState > 2);
    },
    /**
     * Stops a video
     * */
    stop() {
      let player = this.getPlayer();
      if (player) {
        if(this.isPlaying(player)) {
          console.log('player is playing, pausing now')
          player.pause();
        } else {
          console.log('player is not playing, nothing to pause')
        }
        // this is for single videos only – if it were one huge file, we should not do that
        console.log('resetting time to: ' + this.startTime)
        if(this.currentTime !== 0) {
          const $this = this;
          setTimeout(() => {
            $this.setTime(0);
          }, 100);
        }
      }
    },

    /**
     * Plays a video
     * */
    async play() {
      let player = this.getPlayer();
      if (player && !this.isPlaying(player)) {
        console.log('starting to play')
        await player.play()
            .catch(async () => {
              this.$emit('playError')
            });
      }
    },
    setUpCanPlayThrough() {
      const el = document.getElementById(this.uniqueId);
      console.log('original player readystate: ' + el.readyState)
      const player = this.getPlayer();
      console.log('player readystate: ' + player.readyState)
      if(player.readyState === 4) {
        this.canPlayThrough = true;
      }
    },
    /**
     * Sets the current player to the selected video tag
     * and adds the ontimeupdate hook which will update the current time
     * when the video is playing
     * @params {string} - fileName - the name of the file
     * */
    setUpPlayer() {
      const player = this.getPlayer();
      const $this = this;
      setTimeout(() => {
        player.ontimeupdate = function () {
          $this.currentTime = player.currentTime;
        };
        if(player.readyState === 4) {
          this.canPlayThrough = true;
        }
        player.addEventListener("canplaythrough", () => {
          $this.canPlayThrough = true;
        });
      }, 100)
    },
  }
}
</script>