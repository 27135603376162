<template lang="html">
  <div class="">
    <div v-if="showVersionText" class="d-flex align-items-end mb-3">
      <div id="version">
        3.1
      </div>
      <div id="versiontext" class="ml-2">
        <div class="white">is here with new features!</div>
        <div class="highlight"><a href="https://sideeffects.ch/release-communication/release-3-1" target="_blank">learn more</a></div>
      </div>
    </div>
    <section class="download d-flex">
      <div v-for="asset in assets"
            v-if="asset.key"
           :key="asset.id"
           class="d-inline-block"
      >
      <!--<p class="lighter smaller slim mb-1">{{ asset.version }}</p>-->
      <download-button
          classes="highlight"
          :object-id="asset.id"
          :file-key="asset.key"
          :storage-u-r-l="storageURL"
          :username="username"
          :password="password"
          :file-key-only="true"
          :external-file-size="asset.size"
      ><icon class="left" type="windows" size="1.9" /><span v-if="asset.info">{{ asset.info }}</span><br /><span class="float-right lighter">{{ getReadableFileSize(asset.size) }}</span><span class="lighter">{{ asset.label }}</span>
      </download-button>
        </div>
    </section>
  </div>
</template>

<script>
    import DownloadButton from "@/components/forms/DownloadButton";
    import {compareVersion} from "@/utils";
    import {SpecialUuids} from "@/enum";
    import axios from "axios";
    import Icon from "./Icon";
    import filesize from "file-size";
    export default  {
        name: 'DownloadSection',
        components: {
            DownloadButton,
            Icon,
        },
        props: {
          showVersionText: {type: Boolean, default: false}
        },
        data() {
          return {
            uuids: SpecialUuids,
            assets: [],
            authToken: '',
            clientURL: '',
            storageURL: '',
            files: [],
            username:'public',
            password:'public',
          };
        },
        beforeMount() {
         this.setUpDownloadSection();

        },
        methods: {
          async setUpDownloadSection() {
            return fetch(process.env.VUE_APP_CONFIG)
                .then(r => r.json())
                .then(config => {
                  this.clientURL = config.sfx.project.webapp.apiHost + '/api/v2';
                  this.storageURL = config.sfx.project.webapp.storageHost + '/api/v2';
                })
                .then(() => {
                  this.getApplicationUuid('windows');
                  this.setNewestVersionsFromStorage();
                })
                .catch(e => {
                  console.log(e);
                })
          },
          getApplicationUuid(os) {
            this.assets.push({
              id: SpecialUuids[`VSTAGE_${os.toUpperCase()}`],
              key: '',
              os: os,
              version: '',
              size: '',
              label: this.$t('DOWNLOAD'),
              info: 'installer'
            });
            this.assets.push({
              id: SpecialUuids[`VSTAGE_${os.toUpperCase()}_NO_INSTALLER`],
              key: '',
              os: os,
              version: '',
              size: '',
              label: this.$t('DOWNLOAD'),
              info: 'ZIP ARCHIVE'
            });
          },
          /**
           * */
          async setNewestVersionsFromStorage() {
            await this.assets.map(async asset => {
              const object = await this.getNewestVersionFromStorage(asset.id);
             if(object) {
                asset.key = object.key ? object.key : '';
                asset.version = object.metaData.attributes.version ? object.metaData.attributes.version : '';
                asset.size = object.size;
              }
            });
          },
          async getNewestVersionFromStorage(id) {
            return axios.get(`${this.storageURL}/assets/${id}/ls`,{auth: {
                username: this.username,
                password: this.password
              }}).then(response => {
              const arr = response.data;
              const filtered = arr.filter(file => {
                return !!file.metaData && !!file.metaData.attributes && !!file.metaData.attributes.version && file.contentType.includes('application/')
              });
              const sorted = filtered.sort((a, b) => {
                return compareVersion(b.metaData.attributes.version, a.metaData.attributes.version);
              });
              return sorted[0];
            })
          },
          getReadableFileSize(size) {
            return filesize(size, {fixed: 2, spacer: ' '}).human('si')
          },
        }
    }
</script>


<style lang="scss">
    .download {
      .content {
        height:auto !important;
      }
      &> div {
        //margin-right:50%;
        margin-right: 8px;
      }

    }
    .download .button {
        width:210px;
      .smaller {
        max-width: 130px;
        text-transform: none;
        &.lower {
          margin-bottom: 15px;
        }
      }
    }
    #versiontext {
      font-weight: bold;
      font-family: "DIN Pro Bold", sans-serif;
      font-size: 1.1rem;
      line-height: 1.4rem;
      .highlight a {
        color: $highlight;
        cursor: pointer;
        &:hover {
          color: lighten($highlight, 10%);
        }
      }
    }
    #version {
      float:left;
      display: inline-block;
      font-family: "DIN Pro Bold", sans-serif;
      font-size: 4.5rem;
      line-height: 4.5rem;
      font-weight: bold;
      color: #0066FF;
      -webkit-text-fill-color: #0066FF; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #b1bbd7;
      text-shadow: rgba(31, 111, 232, 0.9) 0px 0px 32px;

    }
</style>
