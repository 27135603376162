<template>
  <div @click="setEditingProperty"
       :class="['vform-viewer-editor-overlay d-flex', active ? 'active' : 'inactive']">
    <div class="template-indicator" v-if="hasTemplate"></div>
    <div v-if="canHaveTemplate" class="editor-overlay-icon icon3" @click.stop="openTemplateEditor">
      <icon type="file-export" size="0.9" />
    </div>
    <div @click.stop="$emit('removeBlock')" class="editor-overlay-icon icon2">
      <icon type="trash" size="0.9" />
    </div>
    <div class="editor-overlay-icon icon1">
      <icon type="grip-dots-vertical" size="0.9" />
    </div>
  </div>
</template>


<script>
import Icon from "../../Icon";
export default {
  name: "EditorOverlay",
  components: { Icon },
  props: {
    active: { type: Boolean, default: false },
    canHaveTemplate: {type: Boolean, default: true},
    hasTemplate: {type: Boolean, default: false}
  },
  methods: {
    setEditing() {
      this.$emit("edit");
    },
    setEditingProperty() {
      this.$emit("editProperty");
    },
    openTemplateEditor() {
      this.$emit("editTemplate");
    }
  }
}
</script>


<style lang="scss" scoped>
  $icon-width: 18px;
  .icon1 {
    position:absolute;
    bottom: 0;
    right: 0;
    width: $icon-width;
    height: $icon-width;
    cursor: move;
  }
  .icon2 {
    position:absolute;
    bottom: 0;
    right: $icon-width + 3;
    width: $icon-width;
    height: $icon-width;
  }
  .icon3 {
    position: absolute;
    bottom: 0;
    right: ($icon-width + 3) * 2;
    width: $icon-width;
    height: $icon-width;
  }
  .vform-viewer-editor-overlay {
    .template-indicator {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 7px;
      height: 7px;
      background-color: var(--vform-editor-gizmos-primary-color);
    }
    &:hover {
      .template-indicator {
        top: 2px;
        left: 2px;
      }
    }
  }
</style>