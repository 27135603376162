<template>
<div>
  <h2>{{ $t('GlobalSettings') }}</h2>
  <div class="form-section">
    <h4>Material tiling</h4>
    <div class="vform-label white">Apply Material Tiling for all items</div>
    <div class="d-flex">
      <div>
        <div class="vform-label white">x</div>
        <input class="form-text form-text-dark" type="number" v-model="x" />
      </div>
      <div>
        <div class="vform-label white">y</div>
        <input class="form-text form-text-dark" type="number" v-model="y" />
      </div>
    </div>
    <div v-if="!saving" @click="applyTilingForAll" class="settings-button d-inline-block mt-1">{{ $t('applyForAllItems') }}</div>
    <loading-panel v-else />
  </div>
  <div class="form-section">
    <h4>Metasets</h4>
    <div class="vform-label white">Apply metaSet for all items</div>
    <universal-selector
        store-name="MetaSet"
        list-name="assemblyGlobalMetaSet"
        :limit-by-organization-ids="[$store.getters.getSuperOrg, organizationId]"
        @addItem="addMetaSet"
        class="mb-1"
    />
    <tag
        @removeTag="removeMetaSet"
        :is-removable="true"
        class="mr-1"
        :key="metaSet.id"
        :tag="metaSet"
        v-for="metaSet in selectedMetaSets" />
    <div class="w-100" />
    <div v-if="!saving && selectedMetaSets.length > 0" @click="applyMetaSetsForAll(false)" class="settings-button d-inline-block mt-1">{{ $t('applyForAllItems') }}</div>
    <div v-if="!saving && selectedMetaSets.length > 0" @click="applyMetaSetsForAll(true)" class="settings-button d-inline-block mt-1">{{ $t('removeFromAllItems') }}</div>
  </div>
</div>
</template>

<script>
import LoadingPanel from "./LoadingPanel";
import {SpecialUuids} from "@/enum";
import UniversalSelector from "@/components/organizations/UniversalSelector.vue";
import Tag from "@/components/Tag.vue";
export default {
  name: "AssemblyGlobalSettings",
  components: {
    LoadingPanel,
    UniversalSelector,
    Tag
  },
  props: {
    sourceProjectId: {type: String, required: true},
    organizationId: {type: String, required: true},
  },
  data() {
    return {
      x: 1,
      y: 1,
      saving: false,
      saved: false,
      SpecialUuids: SpecialUuids,
      selectedMetaSets: []
    }
  },
  methods: {
    removeMetaSet(metaSet) {
      const {id} = metaSet;
      const index = this.selectedMetaSets.findIndex(item => {
        return item.id === id;
      })
      this.selectedMetaSets.splice(index, 1);
    },
    addMetaSet(metaSet) {
      this.selectedMetaSets.push(metaSet);
    },
    getRawValues(hierarchy) {
      const rawVals = hierarchy.map(item => {
        const {assetId, id, squashedMetaFields} = item;
        // filter metafields only
        const vals = squashedMetaFields ? squashedMetaFields.filter(item => {
          return item.id === SpecialUuids.MATERIAL_METAFIELD;
        }) : [];
        return {
          assetId,
          id,
          values: vals && vals.length ? vals[0].squashedMetaValues : []
        }
      });
      // remove items with no values given
      const pureVals = rawVals ? rawVals.filter(item => {return !!item.values && !!item.values.finalMetaValue }) : [];
      return pureVals;
    },
    getJson(item) {
      let res = null;
      try {
        res = JSON.parse(item);
      } catch {
        return false;
      }
      return res;
    },
    setTiling(arr) {
      console.log(arr);
      return arr.length ? arr.map(item => {
        item.tiling = [this.x, this.y];
        return item;
      }) : [];
    },
    updateInstance(instanceId, value) {
      this.$store.dispatch('setInstanceMetaValues', {
        id: this.sourceProjectId,
        cid: instanceId,
        values: [{
          metaFieldId: this.SpecialUuids.MATERIAL_METAFIELD,
          value: JSON.stringify(value)
        }]
      })
    },
    updateAsset(assetId, value) {
      this.$store.dispatch('clientSaveAssetMetaSetValues', {
        id: assetId,
        values: [{
          metaFieldId: this.SpecialUuids.MATERIAL_METAFIELD,
          value: JSON.stringify(value)
        }]
      })
    },
    async applyMetaSetsForAll(remove = false) {
      this.saving = true;
      this.saved = false;
      const hierarchy = await this.$store.dispatch('clientLoadProjectInstances', {
        id: this.sourceProjectId,
        limit: 0,
        filter: 'type in model node',
        fields: ['id', 'assetId']
      });
      const uniqueAssets = [];
      hierarchy.map(item => {
        const {assetId} = item;
        if(!uniqueAssets.includes(assetId)) {
          uniqueAssets.push(assetId);
        }
      })
      const metaSetIds = this.selectedMetaSets.map(item => {return item.id});
      for(let i = 0; i < metaSetIds.length;i++) {
        await this.$store.dispatch(remove ? 'clientRemoveAssetsToMetaSet' : 'clientAddAssetsToMetaSet', {
          id: metaSetIds[i],
          values: uniqueAssets
        })
      }
      this.saving = false;
      this.saved = true;
    },

    async applyTilingForAll() {
      this.saving = true;
      this.saved = false;
      // 2b deserialisieren
      // 3 setze auf all denen ein Tiling x y
      const hierarchy = await this.$store.dispatch('clientLoadProjectInstances', {
        id: this.sourceProjectId,
        limit: 0,
        include: 'squashedMeta',
        filter: 'type in model node',
      });
      const pureVals = this.getRawValues(hierarchy);
      const doneAssets = [];
      for(let i = 0; i < pureVals.length; i++) {
        console.log(i);
        const {id, assetId, values} = pureVals[i];
        const {assetMetaValue,instanceMetaValue} = values;
        console.log(pureVals[i])
        console.log(instanceMetaValue)
        if(instanceMetaValue) {
          console.log('got instance meta val')
          const ins = this.getJson(instanceMetaValue);
          const update = this.setTiling(ins);
          if(update) {
            console.log('update instance')
            await this.updateInstance(id, update);
          }
        }
        if(assetMetaValue && !doneAssets.includes(assetId)) {
          console.log('got asset meta val')
          const ass = this.getJson(assetMetaValue);
          const update = this.setTiling(ass);
          if(update) {
            console.log('got asset')
            await this.updateAsset(assetId, update);
            doneAssets.push(assetId);
          }
        }
      }
      this.saving = false;
      this.saved = true;
    },
  },
}
</script>