<template>
  <div>
    <div class="dflex mt-4">
      <div class="col row">
        <label class="vform-editor-label section mt-0">File</label>
      </div>
      <div
          v-for="(key, index2) in Object.keys(element.label.dix)"
          v-if="element.currentLang === key"
          :key="index2 + 1000000000 + forceReRenderKey"
          :class="['dflex col-auto pr-0']"
      >
        <div>
          <v-form-image-dix-cell
              :v-s-t-a-g-e-context="vSTAGEContext"
              :element="element"
              :image-dix="element.label.imageDix"
              :media-category="1"
              :organization-id="organizationId"
              preview-size="medium"
          />
        </div>
      </div>
    </div>
    <div class="dflex mt-3" v-if="false">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Alignment</label>
      </div>
      <div
          v-for="(align, index) in alignments"
          :key="index"
          :class="[
                    'vform-alignment-button',
                    align === element.alignment ? 'active' : '',
                ]"
          @click="setAlignment(align)"
      >
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
    <label class="vform-editor-label section">Height</label>
    <label class="section ml-2 vform-editor-body slider-label"
    >{{  this.size ? `${this.size}%` : 'AUTO' }}</label
    >
    <slider :default-value="30" :initial-value="size"
            :interval="10" :max="100" :min="0"
            @change="(val) => { this.$set(this, 'size', val) }"></slider>
    <div class="col pl-0 pr-0 dflex">
      <div class="col pl-0">
        <label class="mt-3 vform-editor-label section">{{ $t("AutoPlay") }}</label>
      </div>
      <div class="col-auto pl-0 pr-0" style="margin: auto">
        <div v-if="true" class="col-12 dflex row">
          <label :for="'chkautoPlay' + $vnode.key" class="container" style="padding-left: 5px !important">
            <input
                :id="'chkautoPlay' + $vnode.key"
                v-model="element.autoPlay"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import {vFormControls} from "@/enum";
import Icon from "../Icon";
import Slider from "@/components/widgets/editors/Slider";

export default {
  name: "vFormAUDIO",
  components: {
    vFormImageDixCell,
    Slider,
    Icon,
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
    vSTAGEContext: {type: Boolean, default: false}
  },
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      size: 30,
      alignments: ["left", "center", "right"],
    };
  },
  watch: {
    size() {
      this.$set(this.element, "size", this.size);
    },
  },
  beforeMount() {
    this.element.formElementType = vFormControls["AUDIO"];
    this.size = this.element.size ? this.element.size : this.size;
    if (!this.element.size) {
      this.$set(this.element, "size", this.size);
    }
    this.setAlignment("center");
  },
  methods: {
    save() {
      this.$emit("save", this.element);
    },
    setAlignment(val) {
      this.$set(this.element, "alignment", val);
    },
  },
};
</script>
<style lang="scss">
.vform-element .audio-preview {
  width: 240px;
}

.mr-audio-selected {
  margin-right: 130px;
}
</style>