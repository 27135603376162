<template>
<div>
  <h2 class="mt-2 pt-2 pl-0 col-12 mb-0 vform-editor-section-title">
    Component logger (optional)
  </h2>
  <div v-if="!element.logId" @click="adding = true">
    <icon type="square-plus" />
  </div>
  <!-- ADD A LOGGER -->
  <template v-if="adding">
    <div class="mt-3">
      <div
          :class="['settings-button d-inline-block mb-1 mr-2', mode === 'select' ? 'active' : '']"
          style=""
          @click="mode = 'select'">
        {{ $t("addExisting") }}
      </div>
      <div
          :class="['settings-button d-inline-block mb-1', mode === 'add' ? 'active' : '']"
          style=""
          @click="mode = 'add'">
        {{ $t("create") }}
        <icon class="ml-2" type="plus" />
      </div>
    </div>
    <div v-if="mode === 'add'">
      <label class="vform-editor-label">Name</label>
      <input type="text" class="form-text v-form-label form-text-dark" v-model="name" />
      <div @click="createCustomLog" class="mt-1 settings-button d-inline-block">
        {{ $t('save') }}
      </div>
    </div>
    <div v-else-if="mode === 'select'">
      <drop-down
          v-if="logs && logs.length"
          :initial-active-value="element.logId ? element.logId : null"
          :labels="logs.map(item => {return item.name})"
          :values="logs.map(item => {return item.id})"
          @select="setLog"
      />
      <div @click="loadLogs"><icon type="redo" /></div>
    </div>
  </template>
  <template v-else-if="element.logId">
    <div class="mt-2 d-inline-block" v-if="logName"><span class="lighter mr-2">{{ $t('selected') }}</span>{{ logName }}</div><div @click="removeLog" class="d-inline-block ml-2"><icon type="square-minus" /></div>
    <div @click="adding = true" class="d-inline-block ml-2"><icon type="editLight" /></div><br />
    <div class="mt-1 settings-button d-inline-block" v-if="element.logId">
      <router-link target="_blank" :to="'/library/dataset/' + element.logId + '/general'">Go to log</router-link>
    </div>

    <div class="col row dflex mt-4">
      <div class="dflex">
        <label :for="'chkshowentries' + $vnode.key" class="container vform-editor-body">{{ $t("showLogEntries") }}
          <input
              :id="'chkshowentries' + $vnode.key"
              v-model="element.showLogEntries"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </template>
</div>
</template>

<script>
import Icon from "../Icon.vue";
import DropDown from "../forms/DropDown.vue";
export default {
  name: "ComponentLogger",
  components: {
    Icon,
    DropDown
  },
  props: {
    teams: {type: Array, required: true},
    organizationId: {type: String, required: true},
    element: {type: Object, required: true}
  },
  data(){
    return {
      name: "",
      mode: "select",
      schemaName: "sfx.formElementData",
      logs: [],
      adding: false,
      logName: "",
    }
  },
  watch: {
    element() {
      this.setLogName()
    }
  },
  mounted() {
    this.loadLogs();
    if(!this.element.showLogEntries) {
      this.$set(this.element, "showLogEntries", false);
    }
  },
  methods: {
    removeLog() {
      this.$set(this.element, "logId", null);
      this.logName = "";
    },
    setLog(logId) {
      this.$set(this.element, "logId", logId);
      this.adding = false;
      this.setLogName();
    },
    setLogName(name = null) {
      if(name) {
        this.logName = name;
        return;
      }
      const {logId} = this.element;
      if(logId) {
        const index = this.logs.findIndex(item => {
          return item.id === logId;
        })
        if(index !== -1) {
          this.logName = this.logs[index].name;
        }
      }
    },
    async loadLogs() {
      this.logs = await this.$store.dispatch('clientLoadDatasets', {
        filter: 'schemaName eq ' + this.schemaName
      }).catch(e => {
        console.log('could not load logs for logger')
        console.log(e);
      })
      this.setLogName();
    },
    async createCustomLog() {
      let args = {
        name: this.name,
        type: "tbl",
        organizationId: this.organizationId,
        teams: this.teams,
        schemaName: this.schemaName,
        schemaVersion: 1,
      };
      await this.$store.dispatch("createDataset", args)
          .then((dataset) => {
            this.setLog(dataset.id);
            this.setLogName(dataset.name);
            this.adding = false;
          });
    }
  }
}
</script>

<style scoped lang="scss">
.settings-button.active {
  background-color: var(--vform-editor-accent-color);
}
</style>