<template>
    <div class="pagination">
        <ul v-if="total > limit">
            <li @click="setActive(1)"><icon type="angle-left" /><icon type="angle-left" /></li>
            <li @click="decreaseActive"><icon type="angle-left" /></li>
            <li
                    v-for="index in pages"
                    v-if="index >= activeIndex && index <= activeIndex + range-1 || index + range > pages && activeIndex + range > pages"
                    :key="index"
                    :class="[activeIndex === index ? 'active' : '']"
                    @click="setActive(index)"
            >{{ index }}</li>
            <li @click="increaseActive" v-if="activeIndex + range <= pages">...</li>
            <li @click="increaseActive"><icon type="angle-right" /></li>
            <li @click="setActive(pages)"><icon type="angle-right" /><icon type="angle-right" /></li>
        </ul>
    </div>
</template>

<script>

    import Icon from "./Icon";

    export default {
        name: "Pagination",
        components: {
            Icon,
        },
        props: {
            limit: {
                type: Number,
                default: 10,
            },
            total: {
                type: Number, default: 0,
            },
            id: {
                type: String,
                required: true,
            },
            range: {
                type: Number,
                default: 10,
            },
            localPagination: {type: Boolean, default: false},
        },
        data() {
            return {
                activeIndex: 1,
                pages: 0,
            };
        },
        computed: {
            currentOffset: function() {
                return this.limit * (this.activeIndex - 1);
            },
        },
        watch: {
            total: function() {
                this.calculatePages();
            }
        },
        beforeMount() {
          if(!this.localPagination) {
            this.$store.dispatch('checkPagePagination', {
              routeName: this.$route.name,
              id: this.id,
            });

            let selected = this.$store.getters.getPagination(this.$route.name, this.id);
            this.setActive(selected, true, true);
          }
          this.calculatePages();
        },
        methods: {
            calculatePages: function() {
                if(this.total && this.total !== 0) {
                    this.pages = Math.ceil(this.total / this.limit);
                    if(this.pages < this.activeIndex) {
                        this.setActive(1);
                    }
                }
                else {
                    this.pages = 0;
                }
            },
            setActive: function(index, doEmit = true, initial = false) {
                this.activeIndex = index;
                if(index === 0 || !index) {
                    this.activeIndex = 1;
                }
                if(doEmit) {
                    this.$emit('setActive', this.currentOffset, initial);
                }
                this.$store.dispatch('setPagination', {
                    routeName: this.$route.name,
                    pagination: index,
                    id: this.id,
                });
            },
            increaseActive: function() {
                if((this.activeIndex + 1) <= this.pages) {
                    this.setActive(this.activeIndex + 1);
                }
            },
            decreaseActive: function() {
                if((this.activeIndex - 1) >= 1) {
                    this.setActive(this.activeIndex - 1);
                }
            },
            goToLastPage: function(){
                this.setActive(this.pages);
            }
        },
    }
</script>
<style lang="scss">
    .pagination {
        ul {
            margin:auto;
            display: flex;
            padding: 5px;
            li {
                list-style:none;
                cursor:pointer;
                min-width:2em;
                text-align: center;
                line-height: 1rem;
                font-size: 0.9rem;
                &:hover, &.active {
                    color: $highlight;
                    font-weight: bold;
                }
            }
        }
    }
</style>