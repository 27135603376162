<template>
  <div class="node-container">
   <portal to="aboveTreeSection">
      <model-edit-view-popup
          v-if="editing"
          :has-write-access="hasWriteAccess"
          :instance-id="editing"
          :project-id="projectId"
          @abort="$emit('setEditing', null)"
          @updateName="updateItemName"
      />
    </portal>
    <div
        :class="['node d-flex',
        isHierarchy ? 'level-' + item.level : '',
        (mode === 'include' && filtered && filtered.includes(item.id) && enableFiltering) || (mode !== 'include' && (!filtered || !filtered.includes(item.id)) && enableFiltering) ? 'highlighted' : '',
        selected && selected.id && selected.id === item.id ? 'selected' : '']"
        @click="$emit('preview', item)">

      <icon :type="children ? 'folder' : 'cube'"
            class="mr-1"
      />
      <div class="name">{{ item.displayName ? item.displayName : item.name }}
        <span
            v-if="children">({{ children }})</span>
        <div v-if="metaValueFilter.length" :key="forceReRenderKey">
          <div v-for="item in selectedMeta" :key="item.id">
            <span class="lighter">{{ item.metaFieldKey }}:</span> {{ item.finalMetaValue }}
          </div>
        </div>
      </div>
      <div v-if="highlightFilterOn && highlighted.includes(item.id) && item.type === 'node'" class="highlighted-2"></div>
      <div class="icon-box-2 d-flex">
        <!--<div v-if="showSelectBox">
          <input type="checkbox" />
        </div>-->
        <div class="ml-2 clickable mr-2" @click.stop="$emit('setEditing', item.id)">
          <icon type="cog"/>
        </div>
        <div v-if="item.type !== 'node'" class="icon-container">
          <router-link :to="'/library/3d-data/model/' + item.assetId + '/general'">
            <icon class="lighter" size="0.9" type="external-link-alt"/>
          </router-link>
        </div>
        <div v-else-if="item.type === 'node'" class="icon-container">
          <router-link :to="'/library/3d-data/instance/' + item.id + '/general'">
            <icon class="lighter" size="0.9" type="external-link-alt"/>
          </router-link>
        </div>
        <!--<div class="ml-2 clickable" @click.stop="$emit('preview', item.id)">
          <icon type="eye"/>
        </div>-->
      </div>
      <!--Icons right-->
      <div class="icon-box d-flex">
        <div v-if="children" class="ml-2 clickable"
             @click.stop="toggleOpenClosed">
          <icon :type="(open || item.level < 2) ? 'angle-down' : 'angle-right'"/>
        </div>
      </div>
    </div>
    <template v-if="item.id && (open || item.level < 2) && isHierarchy">
      <div v-if="children > limit"
           :class="['clickable', 'level-' + (item.level + 1)]"
           @click="goUp">
        <icon type="angle-up" /> {{ offset }} - {{ offset + limit }} / {{ $store.getters.getAssemblyItemsChildren(item.id, projectId).length }}
      </div>
      <node
          v-for="(subitem, index) in $store.getters.getAssemblyItemsChildren(item.id, projectId)"
          v-if="index < limit + offset && index >= offset"
          :key="subitem.id"
          :enable-filtering="enableFiltering"
          :filtered="filtered"
          :has-write-access="hasWriteAccess"
          :highlight-filter-on="highlightFilterOn"
          :highlighted="highlighted"
          :item="subitem"
          :meta-value-filter="metaValueFilter"
          :mode="mode"
          :project-id="projectId"
          :selected="selected"
          :show-select-box="showSelectBox"
          @preview="preview"
          @setEditing="setEditing"
      />
      <div v-if="children > limit"
           :class="['clickable', 'level-' + (item.level + 1)]"
           @click="goDown"><icon type="angle-down" /></div>
    </template>
  </div>
</template>

<script>
import Node from "./Node";
import Icon from "../Icon";
import ModelEditViewPopup from "./ModelEditViewPopup";
import {mapGetters, mapState} from 'vuex';

export default {
  name: "Node",
  components: {
    Node,
    Icon,
    ModelEditViewPopup
  },
  props: {
    item: {
      type: Object, default: () => {
        return []
      }
    },
    projectId: {type: String, required: true},
    isHierarchy: {type: Boolean, default: true},
    filtered: {
      type: Array, default: () => {
        return []
      }
    },
    highlighted: {
      type: Array, default: () => {
        return []
      }
    },
    showSelectBox: {type: Boolean, default: false},
    metaValueFilter: {
      type: Array, default: () => {
        return []
      }
    },
    mode: {type: String, default: 'include'},
    enableFiltering: {type: Boolean, default: true},
    highlightFilterOn: {default: true},
    selected: {
      type: Object, default: () => {
        return {}
      }
    },
    editing: {type: String, default: ""},
    hasWriteAccess: {type: Boolean, default: true}
  },
  data() {
    return {
      open: false,
      selectedMeta: [],
      forceReRenderKey: 0,
      limit: 10,
      offset: 0,
      children: 0,
    };
  },
  computed: {
    ...mapGetters(['getAssemblyItemsChildren']),
    ...mapState({
      /**
       * whether the user is still loading
       * */
      lists: state => state.dynamicStore.AssemblyItemsInstanceLists,
    }),
    getEndNumber() {
      return this.offset + 1 + this.limit < this.filteredItems.length ? this.offset + 1 + this.limit : this.filteredItems.length;
    }
  },
  watch: {
    filtered(val) {
      if (val.includes(this.item.id)) {
        this.$emit('showMe')
      }
    },
    metaValueFilter: {
      deep: true,
      handler() {
        this.getSelectedMeta();
        this.forceReRenderKey++;
      },
    },
  },
  beforeMount() {
    this.getSelectedMeta();
  },
  mounted() {
    this.setLength();
  },
  methods: {
    setLength() {
      this.children = this.$store.getters.getAssemblyItemsChildren(this.item.id, this.projectId).length;
    },
    goUp() {
      this.offset -= 20;
      if(this.offset < 0) {
        this.offset = 0;
      }
    },
    goDown() {
      this.offset += 20;
    },
    updateItemName(id, name) {
      this.item.name = name;
    },
    getSelectedMeta() {
      if (!this.metaValueFilter) {
        return;
      }
      let valueRes = [];
      const metaFields = this.item.squashedMetaFields || [];
      if (metaFields) {
        const rawFields = metaFields.map(item => {
          return item.squashedMetaValues
        });
        this.metaValueFilter.map(filter => {
          if (filter.metaSet && filter.metaField) {
            const subres = rawFields.filter(item => {
              return item.metaSetName === filter.metaSet && item.metaFieldKey === filter.metaField;
            });
            if (subres.length) {
              valueRes = [...valueRes, ...subres];
            }
          }
        })
      }
      this.selectedMeta = valueRes;
    },
    preview(item) {
      this.$emit('preview', item)
    },
    setEditing(id) {
      this.$emit('setEditing', id)
    },
    toggleOpenClosed() {
      this.open = !this.open;
    }
  }
}
</script>

<style lang="scss" scoped>
.level-1 {
  padding-left: 5px;
}

.level-2 {
  padding-left: 1rem;
}

.level-3 {
  padding-left: 1.8rem;
}

.level-4 {
  padding-left: 2.4rem;
}

.level-5 {
  padding-left: 3rem;
}

.level-6 {
  padding-left: 3.5rem;
}

.node {
  width: 100%;
  margin-bottom: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  position: relative;
  cursor: pointer;

  .name {
    word-wrap: break-word;
    position: relative;
    max-width: 80%;
  }

  .icon-box {
    position: absolute;
    right: 5px;
  }

  .icon-box-2 {
    position: absolute;
    right: 20px;
  }

  &.highlighted, &:hover {
    background-color: $highlight;
  }

  .highlighted-2 {
    position: absolute;
    right: -8px;
    width: 8px;
    height: 100%;
    background-color: #2da2ad;
  }

  &.selected, &:hover {
    background-color: var(--vform-editor-ui-quaterniary-color);
  }
}

</style>