<template>
    <div class="nav-bar">
        <div class="container-fluid">
            <div class="row">
                <div class="col-3 col-md-2 col-lg-2 logo-container">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="logo-section">
                                <div class="spickel"/>
                                  <a id="main-logo" href="/">
                                      <logo class="nav" />
                                  </a>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-9 offset-lg-1 nav-container">
                    <div class="row align-items-end full-height">
                        <div class="col-12 usernav align-self-start">
                            <user-navigation/>
                        </div>
                        <div class="col-12 text-right align-self-end">
                            <main-navigation v-if="showMainNav" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MainNavigation from "./MainNavigation";
    import Logo from '../../assets/svgs/Logo';
    import UserNavigation from "./UserNavigation";

    export default {
        name: 'Navbar',
        components: {
            Logo,
            MainNavigation,
            UserNavigation
        },
        props: {
            showMainNav: {type: Boolean, default: true}
        },
    }
</script>
