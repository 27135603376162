<template>
    <div>
        <codemirror ref="myCm"
                    :options="cmOptions"
                    :value="value"
                    @ready="onCmReady"
                    @focus="onCmFocus"
                    @input="onCmCodeChange"
        />
        <Button
                v-if="showSaveButton"
                type="button"
                class="mb-3 mt-3 mr-3"
                @click="save"
        >
            <icon type="save"/> {{ $t('SAVE') }}
        </Button>
        <Button
                v-if="showSaveButton"
                type="button"
                class="mb-3 mr-3 mt-1"
                @click="codemirrorStartup"
        >
            <icon type="broom"/> {{ $t('makeCodePretty') }}
        </Button>
         <Button
                v-if="showSaveButton && !hideAbortButton"
                type="button"
                class="mb-3 mt-3 mr-3"
                @click="$emit('abort')"
        >
            <icon type="times"/> {{ $t('ABORT') }}
        </Button>
    </div>
</template>

<script>
    // require component
    import {codemirror} from 'vue-codemirror'
    import 'codemirror/theme/base16-dark.css'

    import 'codemirror/mode/javascript/javascript.js'
    // eslint-disable-next-line no-unused-vars
    import {codemirror_formatting} from 'codemirror-formatting';

    // require styles
    import 'codemirror/lib/codemirror.css';
    import Icon from "../../Icon";

    import Button from "../../forms/Button";

    export default {
        name: "Codemirror",
        components: {
            codemirror,
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line
            codemirror_formatting,
            Button,
            Icon
        },
        props: {
            value: {type: String, default: ''},
            showSaveButton: {type: Boolean, default: true},
            doNotFormatOnStartup: {type: Boolean, default: false},
            hideAbortButton: {type: Boolean, default: false}
        },
        data() {
            return {
                cmOptions: {
                    // codemirror options
                    tabSize: 4,
                    indentUnit:4,
                    mode: {name: "javascript", jsonld: true},
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                    smartIndent: true,
                    showCursorWhenSelecting: true,
                    lineWrapping: true,
                }
            };
        },
        watch: {
            value: function() {
                this.codemirrorStartup();
            }
        },
        mounted() {
            this.codemirrorStartup();
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            onCmReady(cm) {

            },
            // eslint-disable-next-line no-unused-vars
            onCmFocus(cm) {

            },
            onCmCodeChange(newCode) {
                this.code = newCode;
                this.$emit('change', this.$refs.myCm.codemirror.doc.getValue());
            },
            save() {
                this.$emit('save', this.$refs.myCm.codemirror.doc.getValue());
                return this.$refs.myCm.codemirror.doc.getValue();
            },
            codemirrorStartup() {
                let codemirror = this.$refs.myCm.codemirror;
                this.$refs.myCm.codemirror.doc.setValue(this.value);

                if(!this.doNotFormatOnStartup) {
                  codemirror.execCommand('indentAuto');

                  codemirror.setCursor(0,0);
                  codemirror.execCommand('selectAll');
                  codemirror.autoFormatRange(codemirror.getCursor(true), codemirror.getCursor(false));
                  codemirror.setCursor(0,0);
                }
            },
        },
    }
</script>