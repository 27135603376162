<template>
  <div></div>
</template>
<script>
export default {
  name: "vFormElementMixin",
  props: {
    steps: {type: Array, default: () => {return []}},
    globals: {type: Object, default: () => {return {}}},
    element: {type: Object, required: true},
    organizationId: {type: String, default: ""},
    projectId: {type: String, required: true},
    vSTAGEContext: {type: Boolean, default: false},
    currentLang: {type: String, required: true},
    selectedTemplate: {type: Object, default: () => {return {}}},
    templateEditing: {type: Boolean, default: false},
  },
  data() {
    return {
      colorPickrId: null,
      forceReRenderKey: 0
    }
  },
  watch: {
    "element.label": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
    "element.options": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
    "element.fields": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
  },
  methods: {
    setColorPickrId(event, colorPickrId) {
      this.colorPickrId = colorPickrId;
      this.$nextTick(() => {
        this.$refs.colorpickr.positionColorPicker(event);
      });
    },
    setItemColor(propertyName, color, closePickr = true) {
      if (closePickr) {
        this.colorPickrId = null;
      }
      this.$set(this.element, propertyName, color);
      this.forceReRenderKey++;
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  }
};
</script>