<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                id="textureList"
                @filter="filtering"
                store-name="Texture"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="15"
                :total="$store.getters.getTextureListOptions(listName, 'pagination_items')"
                :id="'texturetablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="create"
            type="action-button" slot="mainContentButtons" link="/library/texture/add" icon="plus">
            {{ $t('createNewType', {type: $t('type.texture')}) }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'textureDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="tableFields"
                :data="$store.getters.getTexturesByListName(listName)"
                :title-as-links="true"
                :use-team-permission="true"
                :loading="loading"
                 @sort="sort"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'textureDefaultTable'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="thumbnailFields"
                :data="$store.getters.getTexturesByListName(listName)"
                :title-as-links="true"
                :use-team-permission="true"
                :loading="loading"
                 @sort="sort"
        />
</main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
    export default {
        name: "Texture",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        mixins: [
            FileTypeMixin,
            LoadOverviewMixinJs
        ],
        data() {
            return {
                listName: 'TextureList',
              registerMethod: 'registerTextureQueryParams',
              loadMethod: 'loadTextures',
              include: 'tags,teams,versions',
              deleteMethod: 'deleteTexture',
                thumbnailFields: [

                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
    }
</script>
