<template>
    <div class="preview text-center">
        <slot/>
        <!--IMAGE-->
        <div class="square-image"
             :key="forceReRenderKey"
             :style="{ 'background-image': 'url(' + previewData + ')' }"
            :id="id"/><br >
        <div class="clickable qr-code-download" @click="download">Download</div>
    </div>
</template>

<script>
import { saveAs } from 'file-saver';
    export default {
        name: "Preview",
        props: {
            id: {type: String, required: true},
            source: {type: String, default: 'asset'},
            fileName: {type: String, default: 'qrcode'},
        },
        data() {
            return {
                previewData: null,
                forceReRenderKey: 1,
                arrayBuffer: null,
            }
        },
        computed: {
            getURL: function() {
               return "data:image/" + this.previewData;
            },
            downloadFileName: function() {
              return `QR-Code-${this.fileName}`;
          }
        },
        watch: {
            previewData: function () {
                if (this.previewData) {
                    this.forceReRenderKey++;
                    this.$emit('preview');
                }
            },
        },
        beforeMount() {
            this.getResource();
        },
        methods: {
            async download() {
              await this.$store.dispatch(this.getDownloadDispatcher(), {
                id: this.id,
                width: 812,
                height: 812
              }).then(data => {
                //const blob = new Blob([data], {type: "image/png"});
                let fileToSave = data;
                (async () => {
                  saveAs(fileToSave, this.downloadFileName);
                })();
              }).catch(err => {
                this.$log.error(err)
                this.$emit('edit');
              });
            },
            getDownloadDispatcher() {
                if(this.source === 'asset') {
                    return 'clientLoadAssetQrcode';
                }
                else if(this.source === 'project') {
                    return 'clientLoadProjectQrcode';
                }
                return 'clientLoadAssetQrcode';
            },
            getResource: function () {
                this.$store.dispatch(this.getDownloadDispatcher(), {id: this.id}).then(data => {
                    if (!data) {
                        this.$emit('edit');
                        return;
                    }
                    (async () => {
                        this.previewData = data;
                    })();
                }).catch(err => {
                    this.$log.error(err)
                    this.$emit('edit');
                });
            },
            renderImage: function () {
                // eslint-disable-next-line no-undef
                const imgBase64 = new Buffer(this.arrayBuffer, 'binary').toString('base64');
                this.previewData = 'data:image/png;base64,' + imgBase64;
            },
            unloadPreviewContent: function () {
                this.$log.warn('resetting preview image to null');
                this.previewData = '';
            },
        },
    }
</script>

<style lang="scss" scoped>

    .image-edit-toggler {
        position: absolute;
        right: 25px;
        top: 15px;
        font-size: 1.3em;
        padding: 6px 8px 6px 10px;
        background-color: $highlight;
        cursor: pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;

        &:hover {
            background-color: darken($highlight, 10%);
        }
    }
    .preview {
        position:relative;
        .square-image {
            background-color: #171615;
        }
        .selectLinking {
            cursor:pointer;
            position:absolute;
            top:0;
            right:0;
            z-index:3;
            div {
                padding:5px 8px;
                background-color: rgba(255,255,255,0.8);
                color:#000;
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;
                &.selected, &:hover {
                    background-color: $highlight;
                    color:#fff;
                }
            }
        }
    }

    .qr-code-download{
      width: fit-content;
    }
</style>
