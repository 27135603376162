<template>
    <svg version="1.1" class="logosvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px" viewBox="0 0 586.5 197" style="enable-background:new 0 0 586.5 197;" xml:space="preserve"><g>
	<path class="st0" d="M223.3,152.4h-10.2l-19.2-52.2h13.7l10.6,32.3l10.5-32.3h13.7L223.3,152.4z"/><path class="st0"
                                                                                                          d="M304.3,152.4v-29.9h-24.2v29.9h-13.9V81h13.9v29.2h24.2V81h13.9v71.4H304.3z"/>
	<path class="st0" d="M375.1,153c-14.6,0-26.3-9.9-26.3-25.1V81h13.9v46.4c0,8.2,4.8,13.1,12.3,13.1s12.4-4.9,12.4-13.1V81h13.8
		v46.9C401.3,143,389.7,153,375.1,153z"/>
	<path class="st0" d="M461.8,152.4h-29.9V81h28.7c13.9,0,22.1,7.8,22.1,20c0,7.9-5.2,13-8.8,14.7c4.3,2,9.8,6.5,9.8,16
		C483.6,145.1,474.5,152.4,461.8,152.4z M459.5,93.4h-13.6v16.4h13.6c5.9,0,9.2-3.3,9.2-8.2C468.7,96.7,465.4,93.4,459.5,93.4z M460.4,122.4h-14.5v17.5h14.5c6.3,0,9.3-4,9.3-8.8C469.7,126.3,466.7,122.4,460.4,122.4z"/></g><g>
	<path d="M206.3,197c-3.4,0-6.1-1.8-6.3-5.7h2.2c0.2,2.7,2.1,3.7,4.1,3.7c2.3,0,4.1-1.5,4.1-4.2c0-2.6-1.3-4.2-4.2-4.2h-0.5v-1.9
		h0.5c2.6,0,3.8-1.5,3.8-3.9c0-2.6-1.7-3.9-3.7-3.9c-2.3,0-3.6,1.4-3.9,3.6h-2.2c0.2-3.5,2.8-5.5,6-5.5c3.5,0,5.9,2.3,5.9,5.8
		c0,2.3-1,3.9-2.9,4.8c2.2,0.8,3.3,2.6,3.3,5.2C212.6,194.9,209.8,197,206.3,197z"/>
            <path d="M251.4,194.8c-1.3,1.3-3.2,2-5.4,2h-7.3v-21.4h7.3c2.2,0,4.1,0.7,5.4,2c2.2,2.2,2.1,5.5,2.1,8.5
		C253.5,188.8,253.6,192.6,251.4,194.8z M249.8,179c-1.1-1.1-2.6-1.6-4.2-1.6h-4.7v17.3h4.7c1.7,0,3.1-0.4,4.2-1.6
		c1.5-1.5,1.4-4.7,1.4-7.3C251.2,183.2,251.2,180.5,249.8,179z"/>
            <path d="M314,197c-3.2,0-5.3-0.8-7.3-2.8l1.6-1.6c1.8,1.8,3.4,2.3,5.8,2.3c3.1,0,5.1-1.5,5.1-4c0-1.1-0.3-2.1-1-2.7s-1.2-0.8-2.8-1
		l-2.5-0.4c-1.7-0.3-3-0.8-3.9-1.6c-1.1-1-1.6-2.3-1.6-4c0-3.6,2.6-6,6.9-6c2.7,0,4.5,0.7,6.4,2.3L319,179c-1.3-1.2-2.7-1.8-5-1.8
		c-2.9,0-4.5,1.6-4.5,4c0,1.1,0.3,1.9,1,2.5c0.7,0.6,1.8,1,2.9,1.2l2.3,0.4c2,0.3,3,0.7,3.9,1.5c1.2,1,1.8,2.5,1.8,4.2
		C321.4,194.6,318.4,197,314,197z"/>
            <path d="M347.3,196.8v-21.4h13.3v2h-11v7.6h9.4v2h-9.4v7.7h11v2H347.3z"/>
            <path d="M398.7,196.8l-4.9-9.5h-5.1v9.5h-2.3v-21.4h8.2c3.7,0,6.3,2.3,6.3,6c0,3.1-1.9,5.2-4.7,5.7l5.1,9.7H398.7z M394.4,177.4
		h-5.7v7.9h5.7c2.5,0,4.3-1.3,4.3-3.9S396.9,177.4,394.4,177.4z"/>
            <path d="M433.2,196.8h-1.9l-7-21.4h2.4l5.6,17.3l5.6-17.3h2.4L433.2,196.8z"/>
            <path d="M464.7,196.8v-21.4h2.3v21.4H464.7z"/>
            <path d="M500.9,197c-2.1,0-4-0.8-5.5-2.2c-2-2-2-4.1-2-8.7c0-4.5,0-6.7,2-8.7c1.4-1.4,3.4-2.2,5.5-2.2c3.9,0,6.7,2.3,7.4,6.4H506
		c-0.6-2.6-2.4-4.3-5.1-4.3c-1.4,0-2.8,0.5-3.7,1.5c-1.4,1.4-1.5,2.9-1.5,7.3s0.2,6,1.5,7.3c0.9,1,2.3,1.5,3.7,1.5
		c2.7,0,4.6-1.7,5.1-4.3h2.3C507.7,194.6,504.7,197,500.9,197z"/>
            <path d="M534.5,196.8v-21.4h13.3v2h-11v7.6h9.4v2h-9.4v7.7h11v2H534.5z"/>
            <path d="M579.1,197c-3.2,0-5.3-0.8-7.3-2.8l1.6-1.6c1.8,1.8,3.4,2.3,5.8,2.3c3.1,0,5.1-1.5,5.1-4c0-1.1-0.3-2.1-1-2.7
		s-1.2-0.8-2.8-1l-2.5-0.4c-1.7-0.3-3-0.8-3.9-1.6c-1.1-1-1.6-2.3-1.6-4c0-3.6,2.6-6,6.9-6c2.7,0,4.5,0.7,6.4,2.3l-1.5,1.5
		c-1.3-1.2-2.7-1.8-5-1.8c-2.9,0-4.5,1.6-4.5,4c0,1.1,0.3,1.9,1,2.5c0.7,0.6,1.8,1,2.9,1.2l2.3,0.4c2,0.3,3,0.7,3.9,1.5
		c1.2,1,1.8,2.5,1.8,4.2C586.5,194.6,583.6,197,579.1,197z"/></g><g><polygon points="139.1,157 148.8,99.2 133.5,104.9 129.7,127.1 	"/><path d="M95.4,55.8L80.7,77.2l26.6,83.4l4.8,15.7L80,176.2l0.2,20.4l46.4,0.2l5.5-19.5L95.4,55.8z"/><polygon points="86.7,176.2 24.3,97.6 83.3,19.4 154,19.4 139.3,0 74.8,0 0,98.8 80.1,196.6 	"/>
</g>
</svg>
</template>

<script>
    export default {
        name: 'Logo'
    }
</script>