<template>
<div>
  <div class="form-error p-2" v-if="error">
    {{ error }}
  </div>
  <template v-else>
    <loading-spinner v-if="loading" />
    <div v-else>
      <label :class="['vform-label', darkLabels ? '' : 'white']">{{ $t('selected user') }}</label>
      <drop-down
          class="dark"
          :values="uniqueUsers"
          :labels="uniqueUsers"
          @select="(user) => {selectedUser = user}"
      />
      <div class="mt-2" v-if="selectedUser && getSessionsPerUser(selectedUser)">
        <label :class="['vform-label', darkLabels ? '' : 'white']">{{ $t('selected session') }}</label>
        <drop-down
            class="dark"
            :values="getSessionsPerUser(selectedUser).map(item => {return item.sessionId})"
            :labels="getSessionsPerUser(selectedUser).map(item => {return item.sessionId + ' - ' + dateTimeFromUnix(item.time)})"
            @select="setSession"
        />
      </div>

      <!--<div @click="deleteSession(null)" class="settings-button d-inline-block mt-1" v-if="showDeleteSessionButton && selectedSession">
        Delete Session
      </div>-->
      <div :key="row.id" v-for="row in toBeDeletedRows">
        {{ row.updatedAt }}:
        {{ row.data['2'] }}
      </div>
      <div @click="deleteSession(toBeDeletedRows)" class="settings-button d-inline-block" v-if="toBeDeletedRows.length">{{ $t('finalDelete')}}</div>

      <div v-if="showLogDisplay">
        <log-display class="mt-2" :rows="getRowsWithSession()" v-if="selectedSession" />
      </div>

      <div v-if="selectedUser && showPrintItems" class="col-12 pl-0 dflex mt-2">
        <label
            :for="'chkComponentLogs' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("useComponentLogs") }}
          <input
              :id="'chkComponentLogs' + $vnode.key"
              v-model="useComponentLogs"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div v-if="selectedUser && showPrintItems" class="col-12 pl-0 dflex">
        <label
            :for="'renderGlobalPanels' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("renderGlobalPanels") }}
          <input
              :id="'renderGlobalPanels' + $vnode.key"
              v-model="renderGlobalPanels"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div v-if="selectedUser && showPrintItems" class="col-12 pl-0 dflex">
        <label
            :for="'useThumbnails' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("useSmallImages") }}
          <input
              :id="'useThumbnails' + $vnode.key"
              v-model="useThumbnails"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div @click="emitStart" v-if="selectedUser && selectedSession && showPrintItems" class="settings-button d-inline-block mt-2">{{ $t('start') }}</div>

    </div>
  </template>
</div>
</template>

<script>
import DropDown from "../../forms/DropDown.vue";
import DateMixin from "@/components/mixins/DateMixin.js.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LogDisplay from "@/components/vForm/logs/LogDisplay.vue";
import {vFormEvents} from "@/enum";
export default {
  name: "SessionUserSelector",
  components: {
    DropDown,
    LoadingSpinner,
    LogDisplay
  },
  mixins: [DateMixin],
  props: {
    logId: {type: String, required: true},
    showPrintItems: {type: Boolean, default: true},
    darkLabels: {type: Boolean, default: false},
    showLogDisplay: {type: Boolean, default: false},
    showDeleteSessionButton: {type: Boolean, default: false}
  },
  data() {
    return {
      selectedUser: null,
      selectedSession: null,
      uniqueSessions: [],
      uniqueUsers: [],
      sessionsByUser: {},
      rows: [],
      toBeDeletedRows: [],
      error: "",
      useComponentLogs: false,
      renderGlobalPanels: false,
      useThumbnails: false,
      loading: false,
      vFormEvents: vFormEvents,
    }
  },
  beforeMount() {
    this.loadLog();
  },
  methods: {
    async deleteSession(ids = []) {
      if(ids) {
        await this.deleteEntries(ids);
      } else {
        const allRows = await this.$store.dispatch('clientLoadDatasetRows', {
          id: this.logId
        });
        this.toBeDeletedRows = allRows.filter(item => {
          return item.data['1'] === this.selectedSession;
        })
      }
    },
    async deleteEntries(entries) {
      await this.$store.dispatch('clientDeleteDatasetRow', {
        id: this.logId,
        args: entries.map(item => {return item.id})
      }).then(() => {
        this.$store.dispatch('createNotification', {text: 'The session got deleted'})
        this.loadLog();
      }).catch(e => {
        this.error = e;
      })
    },
    getRowsWithSession(eventType = null) {
      return this.rows.filter(item => {
        return item.userId === this.selectedUser && item.sessionId === this.selectedSession && (!eventType || item.eventType === eventType);
      }).sort((a,b) => {
        return b.time - a.time;
      });
    },
    emitStart() {
      const rows = this.getRowsWithSession(vFormEvents.AGGREGATION);
      const config = {
        fillData: rows,
        userId: this.selectedUser,
        sessionId: this.selectedSession,
        useComponentLogs: this.useComponentLogs,
        renderGlobalPanels: this.renderGlobalPanels,
        useThumbnails: this.useThumbnails
      };
      console.log(config);
      this.$emit('setSessionData', config)
    },
    setSession(session) {
      this.selectedSession = session;
    },
    getSessionsPerUser(userId) {
      return this.sessionsByUser[userId]
    },
    setUniqueUsersAndSessions() {
      this.uniqueUsers = [];
      this.uniqueSessions = [];
      this.sessionsByUser = {}
      this.rows.map(item => {
        const {sessionId, time, userId} = item;
        const finalUserId = userId ? userId : 'n/a';
        if(!this.uniqueUsers.includes(finalUserId)) {
          this.uniqueUsers.push(finalUserId)
        }
        if(!this.uniqueSessions.includes(sessionId)) {
          this.uniqueSessions.push(sessionId)
          const obj = {sessionId, time};
          this.sessionsByUser[finalUserId] = this.sessionsByUser[finalUserId] ? [...this.sessionsByUser[finalUserId], obj] : [obj]
          if(this.sessionsByUser[finalUserId].length > 1) {
            this.sessionsByUser[finalUserId].sort((a,b) => {
              return b.time - a.time;
            })
          }
        }
        if(this.uniqueUsers.length > 1) {
          this.uniqueUsers.sort();
        }
      })
    },
    loadLog() {
      this.error = "";
      this.loading = true;
      this.toBeDeletedRows = [];
      this.$store.dispatch('clientLoadDatasetRowsAsJson', {
        id: this.logId,
        limit: 0
      }).then(rows => {
        if(!rows || !rows.length) {
          this.error = this.$t('logIsEmpty')
        }
        this.rows = rows;
        this.setUniqueUsersAndSessions();
        this.loading = false;
      }).catch(e => {
        console.log(e);
        // todo: fehlermeldungen anzeigen
      })
    },

  }
}
</script>

<style scoped lang="scss">

</style>