<template>
  <div></div>
</template>

<script>
/**
 * This is a template for the elements used in learning concept, list matching, etc
 * */
export default {
  name: "LearningDotsMixin.js.vue",
  props: {
    state: {type: Object, required: true},
  },
  data() {
    return {}
  },
  methods: {
    getAnswersDataSource(activeSlideMeta, lang) {
      let answers = [];
        if (activeSlideMeta && activeSlideMeta.learningDots) {
          for (let i = 0; i < activeSlideMeta.learningDots.length; i += 1) {
            let learningDot = activeSlideMeta.learningDots[i];
            answers.push(learningDot.label.dix[lang] ? learningDot.label.dix[lang] : i + 1);
          }
      }

      return answers.sort();
    }
  }
}
</script>