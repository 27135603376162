<template>
  <two-column-layout
      tab-selector="filtertab"
      type="filter"
      back-button-tab-selector="filterbackbutton"
      :id="$route.params.id"
      sub-menu-selector="librarypanel"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :upload-type="'Asset'"
        :created="$store.getters.getFilterCreationDate(id)"
        :last-change="$store.getters.getFilterChangeDate(id)"
        :downloadable="true"
        preview-id="filterEdit"
        :organization="$store.getters.getFilterOrganization($route.params.id)"
    />
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getFilterTags($route.params.id)"
        :taggable="true"
        preview-id="filterEdit"
        @removeTag="removeTag"
        @addTag="addTag"
        :key="'infopanel2'"
        :limit-tags-to-organization="$store.getters.getFilterOrganization($route.params.id)"
    />
    <team-attacher
        v-if="$store.getters.getFilterOrganization($route.params.id)"
        class="mt-3"
        slot="sidebarLeft"
        store-name="Asset"
        :id="$route.params.id"
        :teams="teams"
        :limit-by-organization-id="$store.getters.getFilterOrganization($route.params.id)"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

        <div slot="mainContent">
          <content-title-block
                  v-if="$store.getters.getFilterName(id)"
            :value="$store.getters.getFilterName(id)"
            :id="$route.params.id"
                  store-name="Filter"
                  :organization-id="$store.getters.getFilterOrganization($route.params.id)"

          />
          <content-description-block
                  :value="$store.getters.getFilterDescription(id)"
                  :id="$route.params.id"
                  store-name="Filter"
          />
          <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
            <state-setter
                class="col-12 col-md-4 mb-2 mt-2"
                :target-id="$route.params.id"
                store-name="Filter"
                :given-state="$store.getters.getFilterState($route.params.id)"
            />
          </div>

          <div class="row">
            <div class="w-100"/>
            <div class="col-12 col-md-6 no-gutters">
              <filter-add-form
                  :filter-id="$route.params.id"
                  :organization-id="$store.getters.getFilterOrganization($route.params.id)"
                  :form-embed-mode="true"
              />
              <info-panel
                  class="mt-5"
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Asset"
                    @added="loadFilter"
                    @removed="loadFilter"
                    :given-meta-sets="$store.getters.getFilterMetaSets(id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-6"
                :key="item.id"
                v-for="(item) in $store.getters.getFilterMetaSets(id)"
                :title="item.description"
                :fields="item.metaFields"
                icon-left="folder"
                store-name="Filter"
                :organization-ids="[$store.getters.getFilterOrganization(id)]"
                @updated="loadFilter"
            />
          </div>
    </div>
  </two-column-layout>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import StateSetter from "@/components/StateSetter";
    import FilterAddForm from "../components/sfxFilter/FilterAddForm";
export default {
  name: 'FilterEdit',
  components: {
        MetaPanel,
        InfoPanel,
        TwoColumnLayout,
        ContentTitleBlock,
        ContentDescriptionBlock,
        MetaSetAttacher,
        TeamAttacher,
        StateSetter,
      FilterAddForm
  },
        data() {
            return {
                id: '',
                teams: [],
            };
        },
        beforeMount() {
            this.loadFilter();
            this.id = this.$route.params.id;
            this.loadAssetTeams();
        },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadFilter() {
      this.$store.dispatch('loadFilter', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if(e.status === 401) {
          this.$router.push('/access-denied');
        } else if(e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeFilterTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addFilterTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>