<template>
  <div class="model-edit-view">
    <info-panel
        v-if="asset"
        :collapsible="true"
        :info-panel-header="$t('globalValues')"
        :initially-collapsed="false"
        class="new-style bright"
        preview-id="fileProcessing"
    >
      <template slot="info">
        <h2 class="mb-0 too-long-text-break">{{ asset.displayName ? asset.displayName : asset.name }}</h2>
        <p class="lighter p-0">{{ $t('assetId') }}: {{ asset.id }}</p>
        <p v-if="asset.type !== 'node'" class="lighter">{{ $t('assetMetaDisclaimer') }}</p>
        <div class="col row dflex mt-2">
          <label
              for="collapsed"
              class="container vform-editor-body"
          >{{ $t("alwaysStartCollapsed") }}
            <input
                id="collapsed"
                v-model="collapsed"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div v-if="loading">
          <loading-spinner/>
        </div>
        <div v-else-if="!assetMetaSets.length" class="p-4">
          {{ $t('nodata') }}
        </div>
        <div v-else :key="forceReRenderKey" class="mt-3">

          <meta-panel
              v-for="(item) in assetMetaSets" :key="item.id"
              :editable="hasWriteAccess &&
                        item.name !== 'sfx.asset.model' &&
                        ((asset.type === 'node' && item.organizationId === instanceOrganizationId) ||
                        (asset.type !== 'node' && (item.organizationId === instanceOrganizationId || $store.getters.isSuperOrg(item.organizationId))))"
              :fields="item.metaFields"
              :organization-ids="[asset.organizationId, $store.getters.getSuperOrg]"
              :target-id="asset.id"
              :title="item.description ? item.description : item.name"
              :initially-collapsed="collapsed"
              icon-left="folder"
              store-name="Assembly"
              @updated="loadAsset"
          />
        </div>
      </template>
    </info-panel>
    <info-panel
        v-if="asset"
        :collapsible="true"
        :info-panel-header="$t('localValues')"
        :initially-collapsed="false"
        class="new-style bright"
        preview-id="fileProcessing"
    >
      <div slot="info">
        <div v-if="instance">
          <div class="d-flex">
            <h2 class="mb-0 too-long-text-break">{{ instance.displayName ? instance.displayName : instance.name }}</h2>
            <div v-if="!nameEditing && hasWriteAccess" class="edit-trigger ml-2" @click.prevent="nameEditing = true">
              <icon :type="'edit'"/>
            </div>
          </div>
          <p class="lighter p-0">{{ $t('instanceId') }}: {{ instance.id }}</p>
          <div v-if="nameEditing">
            <input v-model="instanceName" class="form-text" type="text">
            <div class="w-100"/>
            <div class="edit-trigger mr-2" @click="cancelEditing">
              <icon type="times"/>
            </div>
            <div v-if="instanceName" class="edit-trigger" @click="updateName">
              <icon type="save"/>
            </div>
          </div>
          <p class="lighter">{{ $t('localValuesDisclaimer') }}</p>
          <div v-if="instanceOrganizationId && instanceMetaSets.length" :key="forceReRenderKey + 500" class="mt-3">
            <meta-panel
                v-for="(item) in instanceMetaSets"
                v-if="asset.type !== 'node' || (item.organizationId === instanceOrganizationId || item.organizationId === $store.getters.getSuperOrg)"
                :key="item.id"
                :cid="instance.id"
                :editable="hasWriteAccess"
                :fields="item.metaFields"
                :organization-ids="[instanceOrganizationId, $store.getters.getSuperOrg]"
                :target-id="projectId"
                :title="item.description ? item.description : item.name"
                :initially-collapsed="collapsed"
                icon-left="folder"
                store-name="Instance"
                @updated="loadInstanceMetaValues"
            />
          </div>
          <div v-else-if="loading">
            <loading-spinner/>
          </div>
          <div v-else>
            <info-panel
                v-if="asset"
                :collapsible="true"
                :initially-collapsed="false"
                info-panel-header="MetaData"
                preview-id="modeleditViewInstance"
            >
              <div slot="info">
                {{ $t('nodata') }}
              </div>
            </info-panel>
          </div>
        </div>
      </div>
    </info-panel>
    <div class="w-100 mt-4 darker p-3 border-radius">
      <meta-set-attacher
          v-if="asset.id && instanceOrganizationId && hasWriteAccess"
          slot="info"
          :allow-access="true"
          :given-meta-sets="assetMetaSets"
          :organization-id="instanceOrganizationId"
          :show-warning="true"
          :target-id="asset.id"
          store-name="Asset"
          @added="loadData"
          @removed="loadData"
      />
    </div>
  </div>
</template>

<script>
import MetaPanel from "@/components/widgets/meta/MetaPanel";
import Icon from "@/components/Icon";
import InfoPanel from "@/components/InfoPanel";
import LoadingSpinner from "@/components/LoadingSpinner";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import {combineAssetAndInstanceMetaValues} from "@/utils";

export default {
  name: "ModelEditView",
  components: {
    MetaPanel,
    Icon,
    InfoPanel,
    LoadingSpinner,
    MetaSetAttacher
  },
  props: {
    instanceId: {type: String, required: true,},
    projectId: {type: String, required: true,},
    organizationId: {type: String, default: ''},
    hasWriteAccess: {type: Boolean, default: true}
  },
  data() {
    return {
      collapsed: true,
      loading: true,
      instance: {},
      instanceMetaValues: null,
      assetMetaSets: [],
      asset: {},
      instanceMetaSets: [],
      instanceOrganizationId: null,
      nameEditing: false,
      instanceName: '',
      forceReRenderKey: 0,
    };
  },
  watch: {
    instanceId() {
      this.loadData();
    },
    collapsed() {
      // eslint-disable-next-line no-undef
      $cookies.set('3dMetaEditCollapsed', this.collapsed);
    }
  },
  beforeMount() {
    this.loadData();
    // eslint-disable-next-line no-undef
    this.collapsed = $cookies.get('3dMetaEditCollapsed') !== 'false';
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('loadProject', {
        id: this.projectId
      }).then(project => {
        this.instanceOrganizationId = project.organizationId;
      }).then(async () => {
        return await this.loadProjectInstances();
      }).then(async () => {
        return await this.loadAsset();
      }).then(async () => {
        return await this.loadInstanceMetaValues();
      }).then(() => {
        this.loading = false;
      }).catch(e => {
        console.log(e);
        this.loading = false;
      })
    },
    async loadInstanceMetaValues() {
      this.instanceMetaValues = await this.$store.dispatch('getProjectInstanceMetaValues', {
        id: this.projectId,
        cid: this.instanceId,
      });
      this.makeMetaFieldsForInstance();
    },
    makeMetaFieldsForInstance() {
      this.instanceMetaSets = combineAssetAndInstanceMetaValues(this.assetMetaSets, this.instanceMetaValues);
    },
    async loadProjectInstances() {
      return await this.$store.dispatch('clientLoadProjectInstance', {
        id: this.projectId,
        cid: this.instanceId,
        include: ['assetAndMetaSetsBugfix', 'metaFields']
      }).then(instance => {
        this.instance = instance;
        this.instanceName = instance.displayName ? instance.displayName : instance.name;
        return instance;
      });
    },
    async loadAsset() {
      return await this.$store.dispatch('clientLoadAsset', {
        id: this.instance.asset.id,
        include: 'metaSets'
      }).then(asset => {
        if (asset) {
          this.asset = asset;
          this.assetMetaSets = asset.metaSets;
        }
        this.forceReRenderKey++;
        return asset;
      });
    },
    updateName() {
      this.$store.dispatch('clientUpdateProjectInstance', {
        id: this.projectId,
        cid: this.instance.id,
        displayName: this.instanceName
      });
      this.instance.displayName = this.instanceName;
      this.nameEditing = false;
      this.$emit('updateName', this.instance.id, this.instance.displayName);
    },
    cancelEditing() {
      this.instanceName = this.instance.displayName ? this.instance.displayName : this.instance.name;
      this.nameEditing = false;
    },
  },
}
</script>
<style lang="scss">
.model-edit-view {
  h2 {
    font-weight: bold;
  }
}
</style>