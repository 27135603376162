import Vue from 'vue'
import i18n from "../translation";

const state = {
    appLists: {
        appList: {},
    },
    appListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            default: 'type in webapp app',
        },
        sort: {
            default: '-updatedAt',
        }
    },
    queryParams: {
        appList: {
            limit: {
                default: '50',
            },
            filter: {
                default: 'type in app webapp',
            },
            sort: {
                default: '-updatedAt',
            },
        }
    },
    combinedQueryParams: {},
    /**
     * Used keys so far:
     * last_inserted
     * */
    appsTmp: {
    },
};
const getters = {
    getAppsTmp: (state) => (attr = '') => {
        if(attr) {
            return state.appsTmp['attr'];
        }
        else {
            return state.appsTmp;
        }
    },
    getApps() {
        return state.appLists.appList;
    },
    getAppById: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id];
    },
    getAppsByListName: (state) => (listName = 'appList') => {
        return state.appLists[listName] ? state.appLists[listName] : {};
    },
    getAppBaseProjectId: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].baseProjectId ? state.appLists[listName][id].baseProjectId : '';
    },
    getAppTitle: (state) => (id, listName = 'appList') => {
       if(state.appLists[listName][id]) {
         return state.appLists[listName][id].name;
       }
       else {
         return '';
       }
    },
    getAppName: (state) => (id, listName = 'appList') => {
        if(state.appLists[listName][id]) {
            return state.appLists[listName][id].name;
        }
        else {
            return '';
        }
    },
    getAppDescription: (state) => (id, listName = 'appList') => {
        if(state.appLists[listName][id]) {
            return state.appLists[listName][id].description;
        }
        else {
            return '';
        }
    },
    getAppCreationDate: (state, rootGetters) => (id, listName = 'appList') => {
        return state.appLists[listName][id] ? rootGetters['convertDate'](state.appLists[listName][id].createdAt) : '';
    },
    getAppChangeDate: (state, rootGetters) => (id, listName = 'appList') => {
        return state.appLists[listName][id] ? rootGetters['convertDate'](state.appLists[listName][id].updatedAt) : '';
    },
    getAppOrganization: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].organizationId ? state.appLists[listName][id].organizationId : '';
    },
    getAppSlug: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].slug ? state.appLists[listName][id].slug : '';
    },
    getAppType: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].type ? state.appLists[listName][id].type : '';
    },
    getAppTags: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].tags ? state.appLists[listName][id].tags : [];
    },
    getAppOriginalQueryParams: function() {
        return state.queryParams;
    },
    getAppQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getAppPreviewUri: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].previewUri ? state.appLists[listName][id].previewUri : '';
    },
    getAppStatus: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].state === 'a'?true:false
    },
    getAppMetaSets: (state) => (id, listName = 'appList') => {
        return state.appLists[listName][id] && state.appLists[listName][id].metaSets ? state.appLists[listName][id].metaSets : {};
    },
    getAppListOptions: (state) => (listName, option = '') => {
        let optionList = 'appListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
}
const mutations = {
    setAppTmpMutation(state, data) {

        if(state.appsTmp[data.key]) {
            Vue.delete(state.appsTmp, data.key);
        }
        Vue.set(state.appsTmp, data.key, data.value);
    },
    loadAppsMutation(state, data) {
        const listName = data.listName ? data.listName : 'appList'
        Vue.set(state.appLists, listName, {});
        for (let i = 0; i < data.length; i++) {
            let app = data[i];
            Vue.set(state.appLists[listName], app.id, app);
         }
    },
    loadAppMutation(state, data) {
        Vue.set(state.appLists.appList, data.id, data);
    },
    deleteAppMutation(state, data) {
        let listName = data.listName ? data.listName : 'appList';
        const args = data.args;
        for(let i = 0; i < args.length; i++) {
            const id = args[i]
            if(state.appLists[listName] && state.appLists[listName][id]) {
                Vue.delete(state.appLists[listName], id);
            }
        }
    },
    updateAppMutation(state, data) {
        let dataCopy = JSON.parse(JSON.stringify(data));
        let listName = dataCopy.listName ? dataCopy.listName : 'appList';
        delete dataCopy.listName;
        let id = dataCopy.id;
        delete dataCopy.id;
        for (const key in dataCopy){
            const value = dataCopy[key];
            Vue.set(state.appLists[listName][id], key, value);
        }
    },
    appUpdateTagsMutation(state, data) {
        let listName = data.listName ? data.listName : 'appList';
        Vue.set(state.appLists[listName][data.id], 'tags', data.tags);
    },
    updateAppListOptionMutation(state, args) {
        let optionList = 'appListOptions';
        //reset all data:
        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
}
const actions = {
    /*
    * Loads the projects and adds them to the store
    * */
    registerAppsQueryParams: ({commit}, args) => {
        let listName = 'appList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'apps', listName: listName, params: args});
        }
    },
    loadApps: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'appList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'apps', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'apps', listName: listName});
        return dispatch('clientLoadProjects', getters.getAppQueryParams(listName))
            .then(data => {

            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/app/' + data[i].id + '/general';
            }
            data.listName = listName;
            commit('loadAppsMutation', data);

            /*added pagination part*/
            if(getters.getAppQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateAppListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });
    },
    loadApp: async ({commit, dispatch}, args) => {
           return dispatch('clientLoadProject', args).then(data => {
                commit('loadAppMutation', data);
                //dispatch('loadAppImagePreview', {id: args.id, previewUri: data.previewUri});
               return data;
            });
    },
    deleteApp: async ({commit, dispatch}, args = {}) => {
        let listName = 'appList';
        dispatch('clientDeleteProjects', args).then(() => {
            commit('deleteAppMutation', {listName: listName, args});
            dispatch('createNotification', {'text': i18n.t('events.appDeleted')});
        });
    },
    createApp: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateProject', args)
            .then(data => {
                commit('loadAppMutation', data);
                commit('setAppTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.appCreated')});
                return data;
            });
    },
    reloadApp: async ({commit, dispatch}, args) => {
        dispatch('clientLoadApp', args).then(data => {
            commit('loadAppMutation', data);
        });
    },
    updateApp: async ({commit, dispatch}, args) => {
        commit('updateAppMutation', args);
        dispatch('clientSaveProject', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.appUpdated')});
        });
    },
    checkIfAppNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        return dispatch('clientLoadProjects', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId + ', type in app webapp'],
            fields: 'name,id',
        });
    },
    addAppTag: async ({commit, dispatch}, args) => {
        dispatch('clientAddProjectTags', args).then(() => {
            dispatch('clientLoadProjectTags', args).then(data => {
                commit('appUpdateTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'appList',
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsAdded')});
        });
    },
    removeAppTag:  async ({commit, dispatch}, args) => {
        dispatch('clientRemoveProjectTags', args).then(() => {
            dispatch('clientLoadProjectTags', args).then(data => {
                commit('appUpdateTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'appList',
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsRemoved')});
        });
    },
    setAppMetaValues: async ({dispatch}, args) => {
        //commit('updateProjectMutation', args);
        return dispatch('clientSaveProjectMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.appMetaValuesUpdated')});
        });
    },
    unsetAppMetaValues: async ({dispatch}, args) => {
        return dispatch(`clientUnsetProjectMetaSetValues`, args).then(data => {
            dispatch('createNotification', {'text': i18n.t('events.metaValuesUnset')});
            return data;
        });
    },
    loadAppProjects: async ({commit, dispatch, getters}, args) => {
        let listName = args.listName;
        let id = args.id;
        //baseProjectId

        dispatch('loadProjects', args).then(() =>{
            //get baseProjectId of project == selected
            dispatch('loadApp', {id: args.id}).then(() => {
                if (id) {
                    let baseProjectId = getters.getAppById(id) && getters.getAppById(id).baseProjectId ? getters.getAppById(id).baseProjectId : null;
                    if (baseProjectId) {
                        commit('markProjectsSelectedMutation', {
                            listName: listName,
                            data: {baseProjectId: {id: baseProjectId}}
                        });
                    }
                }
            });
        });
    },
}
export default {
    state, getters, mutations, actions
}
