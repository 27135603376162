import Vue from 'vue'
import i18n from "../translation";

const state = {
    metaFieldLists: {
        metaFieldList: {},
    },
    metaFieldListOptions: {},
    defaultQueryParams: {

    },
    queryParams: {
        metaFieldList: {
            limit: {
                default: '50',
            },
        }
    },
    combinedQueryParams: {},
    /**
     * Used keys so far:
     * last_inserted
     * */
    metaFieldTmp: {
    },
};
const getters = {
    getMetaFieldTitle: (state) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName][id] ? state.metaFieldLists[listName][id].name : '';
    },
    getMetaFieldDescription: (state) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName][id] ? state.metaFieldLists[listName][id].description : '';
    },
    getMetaFieldQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getMetaFieldCreationDate: (state, rootGetters) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName][id] ? rootGetters['convertDate'](state.metaFieldLists[listName][id].createdAt) : '';
    },
    getMetaFieldOrganization: (state) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName][id] && state.metaFieldLists[listName][id].organizationId ? state.metaFieldLists[listName][id].organizationId : '';
    },
    getMetaFieldChangeDate: (state, rootGetters) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName][id] ? rootGetters['convertDate'](state.metaFieldLists[listName][id].updatedAt) : '';
    },
    getMetaFieldName: (state) => (id, listName = 'metaFieldList') => {
        return state.metaFieldLists[listName] && state.metaFieldLists[listName][id] && state.metaFieldLists[listName][id].name ? state.metaFieldLists[listName][id].name : '';
    },
    getMetaFieldOriginalQueryParams: function() {
        return state.queryParams;
    },
    getMetaFields() {
        return state.metaFieldLists.metaFieldList;
    },
    getMetaFieldsByListName: (state) => (listName = 'metaFieldList') => {
        return state.metaFieldLists[listName] ? state.metaFieldLists[listName] : {};
    },
    getMetaFieldListOptions: (state) => (listName, option = '') => {
        let optionList = 'metaFieldListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};
const mutations = {
    loadMetaFieldsMutation(state, data) {
        Vue.set(state.metaFieldLists, data.listName, {});
        if(data.data) {
            for (var i = 0; i < data.data.length; i++) {
                let metaField = data.data[i];
                Vue.set(state.metaFieldLists[data.listName], metaField.id, metaField);
            }
        }
    },
    loadMetaFieldMutation(state, data) {
        let listName = 'metaFieldList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.metaFieldLists[listName]) {
            Vue.set(state.metaFieldLists, listName, {});
        }
        Vue.set(state.metaFieldLists[listName], data.data.id, data.data);
    },
    updateMetaFieldListOptionMutation(state, args) {
        let optionList = 'metaFieldListOptions';

        if(!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if(args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if(args.optionKey === 'selected') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if(!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if(state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        }
        else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
};
const actions = {
    registerMetafieldsQueryParams: ({commit}, args) => {
        let listName = 'metaFieldList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'metafield', listName: listName, params: args});
        }
    },
    loadMetaFields: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'metaFieldList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'metafield', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'metafield', listName: listName});
        dispatch('clientLoadMetaFields', getters.getMetaFieldQueryParams(listName)).then(data => {

            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/organizing/metaFields/' + data[i].id + '/general';
            }
            commit('loadMetaFieldsMutation', {listName: listName, data: data});

            /*added pagination part*/
            if(getters.getMetaFieldQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateMetaFieldListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
        });
    },
    loadMetaField: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'metaFieldList';
        dispatch('clientLoadMetaField', args).then(data => {
            commit('loadMetaFieldMutation', {listName: listName, data: data});
        });
    },
    createMetaField: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateOrUpdateMetaField', args)
            .then(data => {
                commit('loadMetaFieldsMutation', data);
                //commit('setMetaFieldTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.metaFieldCreated')});
            });
    },
    updateMetaField: async ({dispatch}, args) => {
        let listName = args.listName ? args.listName : 'metaFieldList';
        return dispatch('clientUpdateMetaField', args)
            .then(() => {
                dispatch('loadMetaField', {id: args.id, cid: args.cid, listName: listName});
                dispatch('createNotification', {'text': i18n.t('events.metaFieldUpdated')});
            });
    },
    deleteMetaField: async ({dispatch}, args) => {
        return dispatch('clientDeleteMetaField', args)
            .then(() => {
                dispatch('loadMetaFields', args);
                //commit('setMetaFieldTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.metaFieldDeleted')});
            });
    },
    getMetaValues: async ({dispatch}, args) => {
        return dispatch('clientLoadMetaValues', args);
    },


};
export default {
    state, getters, mutations, actions
}
