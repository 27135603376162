<template>
  <div></div>
</template>

<script>
let playMode = false;

function dragMoveListener(event) {
  if (!playMode) {
    var target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);

    target = null;
  }
}

/*
    if you use this mixin, the following things are needed:
    a div:
    <div :id="uuId + 'dragHandle'">dragHandle</div>

    afterDraggedMethod: function() {
        do the things after finished dragging here...
    }

    Further Documentation:
    https://interactjs.io/
    * */
import interact from "interactjs";

export default {
  name: "VformDraggableDroppableMixin",
  props: {},
  data() {
    return {
      sidePanelTarget: ".panel-undocked",
      sidePanelDocked: ".panel-docked"
    };
  },
  methods: {
    disableDragDropSidePanel() {
      interact(this.sidePanelTarget).unset();
    },
    enableDragDropSidePanel() {
      const $this = this;
      interact(this.sidePanelTarget).unset();
      interact(this.sidePanelTarget).draggable({
        inertia: true,
        allowFrom: '.drag-grip-global-settings',
        modifiers: [
          interact.modifiers.restrict({
            restriction: ".vform-container-2",
            endOnly: true,
          }),
        ],
        //autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start() {
            playMode = $this.viewerModeOnly;
          },
          end(event) {
            let target = event.target;
            $this.adjustInlineMenuPopup(target);
          },
        },
      });
    },
    adjustInlineMenuPopup(panel){
      panel.classList.remove("slide-right");
      if (panel.offsetLeft + Number(panel.getAttribute("data-x")) < 400){
        panel.classList.add("slide-right");
      }
    }
  },
};
</script>
