import { render, staticRenderFns } from "./vFormQR_CODE.vue?vue&type=template&id=dcbda55a&scoped=true&"
import script from "./vFormQR_CODE.vue?vue&type=script&lang=js&"
export * from "./vFormQR_CODE.vue?vue&type=script&lang=js&"
import style0 from "./vFormQR_CODE.vue?vue&type=style&index=0&id=dcbda55a&lang=scss&scoped=true&"
import style1 from "./vFormQR_CODE.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcbda55a",
  null
  
)

export default component.exports