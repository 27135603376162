<template>
<div>
  <div class="mb-3" :key="index" v-for="(stepRows, stepName, index) in rowsByStep">
    <b class="uppercase">{{ $t('step')}} {{ stepName }}</b>
    <div :key="row.time + row.eventType + index1" v-for="(row, index1) in stepRows">
      <div class="p-3 log-summary-field" v-if="row.eventType === vFormEvents.AGGREGATION && row.data">
        <span class="uppercase">{{ $t('summary') }}</span> <span class="lighter">({{ getTime(row) }})</span><br /><br />
        <span class="lighter">{{ $t('User') }}: </span> {{ row.userId }}<br />
        <div class="mb-2" v-if="row.data" :key="index" v-for="(item, index) in row.data">
          <span class="lighter">{{ $t('Question') }}: </span> {{ item.question ? item.question : '-' }}<br />
          <div :key="answerIndex" v-for="(answer, answerIndex) in item.answers">
            <div v-if="answer.hasOwnProperty('selected')">
              <div v-if="answerIndex === 0" class="lighter">{{ $t('Answer') }}:</div>
              <label class="container vform-editor-body mb-0" :for="answer.answer + $vnode.key"
              >{{ answer.answer }}
                <input
                    type="checkbox"
                    :id="answer.answer + $vnode.key"
                   v-model="answer.selected"
                    :disabled="true"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-else>
              <span class="lighter">{{ $t('answer') }}:</span> {{ answer.answer }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="row.eventType === vFormEvents.AGGREGATION">
        {{ getTime(row) }}: Step visited <!--This is for legacy forms which don't have the answers saved or if no answers possible because there are no questions-->
      </div>
      <div v-else>
        {{ getTime(row) }}: {{ row.eventType }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {vFormEvents} from "@/enum";
import DateMixinJs from "@/components/mixins/DateMixin.js.vue";
export default {
  name: "LogDisplay",
  props: {
    rows: {type: Array, default: () => {return []}}
  },
  mixins: [DateMixinJs],
  data() {
    return {
      vFormEvents: vFormEvents,
      rowsByStep: {},
      disableLogFilter: true
    }
  },
  watch: {
    rows() {
      this.sortRowsByStep();
    }
  },
  beforeMount() {
    this.sortRowsByStep();
  },
  methods: {
    sortRowsByStep() {
      this.rowsByStep = {};
      for(let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        if(!this.rowsByStep[row.stepNo]) {
          this.rowsByStep[row.stepNo] = [];
        }
        if(this.disableLogFilter || [this.vFormEvents.AGGREGATION, this.vFormEvents.STARTED, this.vFormEvents.FINISH, this.vFormEvents.CANCELLED, this.vFormEvents.GO_TO_STEP, this.vFormEvents.GO_TO_SLIDE, this.vFormEvents.GO_TO_PROJECT, this.vFormEvents.NEXT_SLIDE].includes(row.eventType)) {
          this.rowsByStep[row.stepNo].push(row);
          this.rowsByStep[row.stepNo].sort((a,b) => {
            // make the aggregation rows always first
            if(a.eventType === this.vFormEvents.AGGREGATION) {
              return -1;
            } else if(b.eventType === this.vFormEvents.AGGREGATION) {
              return 1;
            }
            return a.time - b.time;
          })
        }
      }
    },
    getTime(row) {
      return row.time ? this.dateTimeFromUnix(row.time) : '-';
    },
  }
}
</script>

<style lang="scss">
.log-summary-field {
  background-color: var(--vform-editor-ui-secondary-color);
  color: #fff;
}
</style>