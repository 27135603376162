<template>
<div></div>
</template>

<script>
import {SpecialUuids} from "@/enum";

export default {
  name: "vFormPublishingMixin.js",
  data() {
    return {
      publishedToWebApps: [],
      message: "",
      metaValues: [],
      app: null,
      publishUnpublishProcessing: false
    }
  },
  methods: {
    async loadApp() {
      if(this.appId) {
        await this.$store.dispatch('loadProject', {id: this.appId, include: ['metaSets']})
            .then(app => {
              this.app = app;
            })
      }
    },
    setMessage(message, projectId) {
      this.message = this.$t(message);
      setTimeout(() => {
        this.message = "";
        this.publishTargetWebApp = {};
        this.getWebAppsWhereFormIsPublished(projectId);
      }, 5000);
    },
    async publishvform(targetWebAppId, projectId) {
      this.error = "";
      let payload = {
        id: projectId,
        cid: targetWebAppId,
      }
      await this.$store.dispatch('clientPublishVformToApp', payload)
          .then(() => {
            console.log("Request sent, this can take up to a few minutes. You'll get an email when the process is finished;");
          }).catch(e => {
            this.error = e;
          });
      this.setMessage("vform.publishvformToAppMessage", projectId);
    },
    async unpublishvform(webAppId, projectId) {
      let payload = {
        id: projectId,
        cid: webAppId,
      }
      return this.$store.dispatch('clientUnpublishVformFromApp', payload)
          .then(() => {
            this.setMessage("vform.unpublishvformToAppMessage", projectId);
            console.log("Request sent, this can take up to a few minutes. You'll get an email when the process is finished;");
          });
    },
    async loadMetaValues() {
      await this.$store.dispatch("getMetaValues", {
        id: SpecialUuids.SFX_PROJECT_WEBAPP_METASET, //'f16fc02e-f77f-4476-a7ba-2ccd808ebfb6', // sfx.project.webapp:
        cid: SpecialUuids.VFORMS_PUBLISHED_METAFIELD //'3e3c02a6-682e-4502-a52c-43a65def8d8f', // publishedvFormProjects:
      }).then(metaValues => {
        this.metaValues = metaValues;
      })
    },
    async getWebAppsWhereFormIsPublished(projectId) {
      await this.loadMetaValues();
      this.publishedToWebApps = [];
      this.metaValues.forEach((metaField) => {
        const vformProjectIds = JSON.parse(metaField.value);
        if (vformProjectIds && vformProjectIds.find((v) => v === projectId)) {
          this.publishedToWebApps.push(metaField.targetId);
        }
      });
    },
  }
}
</script>
