<template>
    <div>
        <div class="thumbnail-empty mt-2" v-if="!data || Object.keys(data).length === 0">
            <loading-spinner v-if="!hasGivenUp" />
            <span v-else>
                {{ $t('nodata') }}
            </span>
        </div>
        <div v-else class="row">
            <thumbnail
                    v-for="(item, key, index) in data"
                    :key="item.id"
                    :class="['mb-3 mt-3 thumbnail-view', colClasses, index % 2 === 0 ? 'even' : 'odd', item.selected ? 'selected' : '', selectedRows && item.id in selectedRows ? 'active' : '']"
                    :item="item"
                    :fields="fields"
                    :resource="resource"
                    :nav-by-clicking-row="navByClickingRow"
                    :selectable-checkbox="selectableCheckbox"
                    :edit-permission="editPermission"
                    :delete-permission="deletePermission"
                    :title-as-links="titleAsLinks"
                    @selectCheckbox="selectCheckbox"
                    @updateContent="updateContent"
                    :permission-by-type="permissionByType"
            />
        </div>
    </div>
</template>

<script>
    import Thumbnail from "./Thumbnail";
    import LoadingSpinner from "../LoadingSpinner";
    export default {
        name: 'Table',
        components: {
            Thumbnail,
            LoadingSpinner
        },
        props: {
            resource: {type: String, default: 'asset'},
            navByClickingRow: {type: Boolean, default: false,},
            fields: {type: Array, required: true,},
            data: {type: Object, default: null},
            selectableCheckbox: {type: Boolean,},
            selectableRows: {type: Boolean,},
            titleAsLinks: {type: Boolean,},
            selectedRows: {type: Object, default: null},
            colClasses: {type: String, default: 'col-12 col-md-3 col-lg-4 col-xl-3 col-xxl-2'},
            editPermission: {type: String, default: null},
            deletePermission: {type: String, default: null},
            /**
             * @permissionByType If there are multiple permissions per type, use this one
             */
            permissionByType: {type: Object, default: () => {}},
        },
        data() {
            return {
                hasGivenUp: false,
                sort: {
                    fieldname: '',
                    direction: '+'
                }
            }
        },
        mounted() {
            let $this = this;
            setTimeout(function() {
                $this.hasGivenUp = true;
            }, 3000);
        },
        methods: {
            selectCheckbox: function (id, action, item) {
                this.$emit('selectCheckbox', id, action, item);
            },
            unlink(id) {
                this.$emit('unlink', id);
            },
            sortTable(fieldname) {
                if (this.sort.fieldname === fieldname) {
                    if (this.sort.direction === '-') {
                        this.sort.direction = '+';
                    } else {
                        this.sort.direction = '-';
                    }
                    this.$emit('sort', {fieldname: fieldname, action: 'remove', direction: this.sort.direction});
                }
                this.sort.fieldname = fieldname;
                this.$emit('sort', {fieldname: fieldname, action: 'add', direction: this.sort.direction});
            },
            //used if a content gets modified in the table directly
            updateContent(args) {
                this.$emit('updateContent', args);
            },
        }
    }
</script>
<style lang="scss">
    .thumbnail-empty {
        padding:15px;
        background-color: $table-row-even-background;
        width:100%;
    }
    .title-link {
        display:block;
        &:hover {
            color: $highlight;
        }
    }
    .thumbnail-view {
        h4 {
            line-height: 1.5em;
            font-weight: normal;
        }
        .date {
            font-size: 0.9em;
        }
        .icon-field {
            background-color: lighten($thumbnail-box-background-color, 5%);
        }
        .type {
            display: inline-block;
            font-size: 0.9em;
            padding: 4px 8px;
            background-color: darken($thumbnail-box-background-color, 5%);
        }
        .content {
            background-color: $thumbnail-box-background-color;
            width: 100%;
            height: 100%;
            word-break: break-all;
            padding-bottom: 70px;
            position: relative;
            &.no-access {
                background-color: $table-inactive-row-background;
            }
        }

        .clickable .thumbnail {
            width: 100%;
            padding-top: 100%;
            margin-bottom: 15px;
            position: relative;
            overflow: hidden;

            .square-image, .three, .pdf {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;

                &:hover {
                    transform: translate(-50%, -50%) scale(1.05);
                    -webkit-transform: translate(-50%, -50%) scale(1.05);
                }
            }
        }

        .button {
            opacity: 0;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }

        .button-field {
            position: absolute;
            bottom: 15px;
            width: 80%;
        }
        .toggle-button {
            position:absolute;
            bottom: 0px;
            right:0px;
        }

        &:hover {
            .button {
                opacity: 1;
            }
        }
    }
</style>
