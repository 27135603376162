<template>
    <div>
        <input class="form-text"
               ref="text"
               @keydown.enter="save"
               :value="value">
        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    export default {
        name: "TextWidget",
        components: {
            Button,
            Icon,
        },
        props: {
            value: {type: String, default: ''},
        },
        methods: {
            save: function() {
                this.$emit('save', this.$refs.text.value);
            }
        },
    }
</script>

<style scoped>

</style>