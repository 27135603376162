<template>
    <main-table-layout>
        <content-filter
                slot="filter"
                @filter="filter"
                store-name="Report"
                id="reportList"
        />
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getReportListOptions('reportList', 'pagination_items')"
                id="reporttablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="create"
                slot="mainContentButtons"
                type="action-button" link="/report/add" icon="plus">
                    {{ $t('createNewReport') }}
        </Button>

<Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <div slot="mainContent">
            <table-widget
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                    id="appsDefaultTable"
                    :fields="fields"
                    :data="$store.getters.getReports"
                    :title-as-links="true"
                    @sort="sort"
                    :nav-by-clicking-row="true"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :show-organization="true"
                    edit-permission="editDataSets"
                    delete-permission="deleteDataSets"
                     :loading="loading"
            />
            <thumbnails
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                    id="appsDefaultTable"
                    :fields="thumbnailFields"
                    :data="$store.getters.getReports"
                    :title-as-links="true"
                    @sort="sort"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                    edit-permission="editDataSets"
                    delete-permission="deleteDataSets"
                     :loading="loading"
            />
        </div>
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";

    export default {
        name: 'DataSets',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                loading: true,
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                sorting: '-updatedAt',
                fields: [{
                    name: '',
                    id: 'icon',
                    type: 'icon',
                    virtual: true,
                    fieldId: 'type'
                },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadPaginatedData();
                }
            }
        },
        methods: {
            loadPaginatedData: function (offset, initial) {
              this.loading = true;
                let args = {
                    listName: this.$route.params.id,
                    add: {
                        paging: {
                            reportdefaultTable: true,
                        },
                        offset: {
                            reportdefaultTable: offset,
                        },
                        limit: {
                            reportdefaultTable: this.paginationLimit,
                        },
                        sort: {
                          [this.listName]: this.sorting,
                        }
                    }
                };
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerReportsQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadReports', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            deleteRow: function (id) {
                this.$store.dispatch('deleteReport', {
                    args: [id]
                });
            },
            filter: function(args, initial) {
                args.listName = this.$route.params.id;
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerReportsQueryParams', args);
                }
                else {
                    this.$store.dispatch('loadReports', args).then(() => {
                      this.loading = false;
                    })
                }
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadPaginatedData(0);
            }
        }
    }
</script>
