<template>
  <div class="info-helper">
    <icon size="0.9" type="question-circle"/>
    <div class="tooltip border-radius" v-html="'<p>' + $t(text) + '</p>' + (image ? '<img class=\'img\' src=\'' + image + '\' />' : '')"/>
  </div>
</template>

<script>
import Icon from "./Icon";

export default {
  name: "InfoHelper",
  components: {
    Icon,
  },
  props: {
    text: {type: String, required: true},
    imageName: {type: String, default: ""}
  },
  computed: {
    image() {
      return this.imageName ? require('@/assets/images/' + this.imageName) : '';
    }
  }
}
</script>

<style lang="scss">
.info-helper {
  display: inline-block;
  padding-top: 5px;
  position: relative;
  z-index: 0;
  cursor: pointer;

  .tooltip {
    min-width: 370px;
    position: absolute;
    background-color: $info-helper-background-color;
    pointer-events: none;
    overflow: auto;
    padding: 10px;
    .img {
      max-width: 100%;
    }
  }

  &:hover {
    z-index: 500;
    .tooltip {
      opacity: 1;
    }
  }
}
</style>