<template>
    <full-width-sidebar-left
        sub-menu-selector="organizingpanel"
    >
        <div slot="mainContent">
            <h1><icon class="mr-2" type="tag" size="0.9" />{{ $t('Tags') }}</h1>
            <organization-switcher
              @setOrganization="id => organizationId = id"
              :show-teams="false"
              :show-all-orgs="true"
            />
            <tag-input
                    v-if="organizationId"
                    class="mt-3"
                    :show-tag-list-permanently="true"
                    :tags-are-deletable="true"
                    :can-set-color="true"
                    :table-mode="true"
                    :limit-tags-to-organization="organizationId"
            />
        </div>
    </full-width-sidebar-left>
</template>

<script>
    import FullWidthSidebarLeft from "../layouts/FullWidthSidebarLeft";
    import TagInput from "../components/forms/TagInput";
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import Icon from "../components/Icon";

    export default {
        name: 'TagsEdit',
        components: {
            FullWidthSidebarLeft,
            OrganizationSwitcher,
            TagInput,
            Icon,
        },
        data() {
            return {
                id: '',
                organizationId: '',
            };
        },
    }
</script>
