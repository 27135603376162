import axios from "axios";
import {vFormControls} from "@/enum";
export const getComponentName = function(formElementTypeString) {
    let componentName = "";
    Object.entries(vFormControls).map((item) => {
        if (item[1] === formElementTypeString) {
            componentName = item[0];
        }
    });
    // TEXT and IMAGE are reserved vuejs words, cannot use components with this name
    if (componentName === "TEXT") {
        componentName = "TEXTDISPLAY";
    } else if (componentName === "IMAGE") {
        componentName = "IMAGEDISPLAY";
    } else if (componentName === "VIDEO") {
        componentName = "VIDEODISPLAY";
    } else if (componentName === "AUDIO") {
        componentName = "AUDIODISPLAY";
    } else if (componentName === "CHECKBOXES2") {
        componentName = "CHECKBOXES";
    } else if (componentName === "PDF") {
        componentName = "PDFDISPLAY";
    } else if (componentName === "BUTTON") {
        return "BUTTON_ITEM";
    } else if(componentName === "FormSummary") {
        return "FORM_SUMMARY"
    }
    return componentName;
};

export const checkURLValidity = async (url) => {
    return await axios.get(url, {})
        .then(res => {
            return !!res;
        }).catch(() => {
            console.log('url invalid')
            return false;
        })
}


export const getLabel = (config, lang) => {
    if (config.label && config.label.dix) {
        return config.label.dix[lang] ? config.label.dix[lang] : config.label.dix.Unknown;
    }

    return '';
}