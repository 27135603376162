<template>
  <div>
    <!--<loading-panel message="pleaseWait" class="l-panel-furioos" v-if="resuming" />-->
    <div id="containerDivId"></div>
  </div>
</template>

<script>
import {Player, FS_SDK_EVENTS_NAME} from 'furioos-sdk';
//import LoadingPanel from "./LoadingPanel";
export default {
  name: "Furioos",
  /*components: {
    LoadingPanel
  },*/
  props: {
    sdk: {type: String, required: true}
  },
  data() {
    return {
      player: null,
      resuming: false
    };
  },
  watch: {
    sdk() {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.resuming = true;
      const options = {
        whiteLabel: true,
        hideToolbar: false,
        hideTitle: true,
        hidePlayButton: false,
        inactiveTimeout: 3600000,
      };

      const player = new Player(this.sdk, "containerDivId", options);
      this.player = player;
      const $this = this;
// Bind player loaded
      player.on(FS_SDK_EVENTS_NAME.LOAD, function () {
        console.log("SDK client FIRED: Player loaded");
        $this.resuming = false;
      });

// Bind application install progress
      player.on(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS, function (data) {
        console.log("SDK client FIRED: App install progress", data);
      });

// Bind application start
      player.on(FS_SDK_EVENTS_NAME.ON_APP_START, function () {
        console.log("SDK client FIRED: App start");
      });

// Bind stream start
      player.on(FS_SDK_EVENTS_NAME.ON_STREAM_START, function () {
        console.log("SDK client FIRED: Stream start");
      });

// Bind stream start
      player.on(FS_SDK_EVENTS_NAME.ON_SDK_START, function () {
        console.log("SDK client FIRED: SDK start");
      });

// Bind SDK messages
      player.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, function (data) {
        console.log("SDK Message Received:", data);
      });

// Bind an event that lets you know if you can resume session
      player.on(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION, function ({canResumeSession}) {
        $this.resuming = true;
        if (canResumeSession) {
          player.resumeSession();
        }
      });

// Bind session stoppeds
      player.on(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED, function () {
        console.log("SDK client FIRED: Session Stopped");
      });
      this.keepSessionOpen();
    },
    keepSessionOpen() {
      const $this = this;
      setTimeout(() => {
        console.log('setting active...')
        $this.player.setUserActive();
        $this.keepSessionOpen();
      }, 10000)
    }
  }
}
</script>

<style lang="scss" scoped>
.l-panel-furioos {
  position:fixed;
  top: 25px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
}
#containerDivId {
  height: 100vh;
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  border: 0px solid transparent !important;
  iframe {
    height: 100vh !important;
    border: 0px solid transparent !important;
  }
}
</style>