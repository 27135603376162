<template>
    <one-column-sidebar-left
        sub-menu-selector="adminpanel"
        back-button-tab-selector="permissionsbackbutton"
    >
        <div slot="buttonsRight">
            <Button
                    type="button"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>
        <div slot="mainContent">
            <h1>{{ $t('createNewType', {type: $t('permission')}) }}</h1> {{ id }}
            <input
                    type="text"
                    :class="['form-text', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNum">{{ $t('errors.alphaNumOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.permissionNameAlreadyExists') }}</div>

          <input
              type="text"
              :class="['form-text', $v.displayName.$dirty && $v.displayName.$error ? 'form-group--error' : '']"
              :id="'displayName'"
              v-model="displayName"
              :placeholder="$t('displayName')"
              @input="delayTouch($v.displayName)"
          >

          <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.required && $v.displayName.$error">{{ $t('errors.required') }}</div>

          <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

          <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                <textarea
                        :class="['form-text', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                        :id="'description'"
                        v-model="description"
                        :placeholder="$t('enterdeschere')"
                        @input="delayTouch($v.description)"
                />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNum} from '../customValidators';
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: "PermissionAdd",
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                displayName: '',
            };
        },
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNum,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfPermissionNameExistsSpecial', {name: value}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            displayName: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(127),
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        methods: {
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        displayName: this.displayName,
                        description: this.description,
                    };
                    await this.$store.dispatch('createPermission', args).then(data => {
                      this.$router.push('/admin/permissions/'+data.id+'/general');
                    })
                }
            },
        },
    }
</script>