/*
*   Script to parse xml-files exported by Windchill search result
*
* */
const parseString = require('xml2js').parseString;
const {promisify} = require('util');

/**
 * returns the columns from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the column names as an array
 * */
export const getColumnNames = async(xml) => {
    const result = await convertXMLToObject(xml);
    return result.SearchResults.TableHeader[0].ColumnName;
};

/**
 * returns the data from an xml as a string array
 * @params xml {String} the xml as a string
 * @returns {Array} the xml data rows as an array
 * */
 export const getData = async(xml) => {
    const result = await convertXMLToObject(xml);
    return result.SearchResults.Object;
};


/**
 * Converts an XML-String to an object
 * @param xml {String}
 * @returns {Object} an object with the XML-contents
 * */
const convertXMLToObject = async (xml) => {
    const parse = promisify(parseString);
    return await parse(xml).then(result => {
        if(result) {
            return result;
        }
    });
};