<template>
  <div class="lazy-node-material">
    <div class="float-right clickable" @click="$emit('close')"><icon type="close"/></div>
    <div v-if="!multiInstanceIds || !multiInstanceIds.length">{{ name }}</div>
    <div v-else>Multi Instance Editing Mode</div>
    <div v-if="type !== 'node' && (!multiInstanceIds || !multiInstanceIds.length)">
      <div class="vform-label white">GLOBAL</div>
      <span class="lighter">{{ $t('change material everywhere this part is used') }}</span>
      <mat-color-adder
          v-if="!loadingMats"
          :key="forceReRenderKey"
          :value="assetValue ? [assetValue] : [{}]"
          :organization-ids="[organizationId, $store.getters.getSuperOrg]"
          :show-save-button="false"
          :default-tiling="defaultTiling"
          ref="global"
          class="mb-1 mb-4"
      />
      <loading-spinner v-else />
    </div>
    <div class="vform-label white">LOCAL <span v-if="multiInstanceIds && multiInstanceIds.length">(Multiple)</span></div>
    <span v-if="!multiInstanceIds || !multiInstanceIds.length" class="lighter">{{ $t('change material only for this part')}}</span>
    <div class="form-error p-1 mt-1 mb-1" v-else>{{ $t('The view might not show actual values due to multiple instances being selected. Disable the multiple editing mode to see the data for a single instance.') }}</div>
    <mat-color-adder
        v-if="!loadingMats"
        :key="forceReRenderKey"
        :value="instanceValue ? [instanceValue] : [{}]"
        :organization-ids="[organizationId, $store.getters.getSuperOrg]"
        :show-save-button="false"
        :default-tiling="defaultTiling"
        ref="local"
        class="mb-1"
    />
    <loading-spinner v-else />
    <loading-panel v-if="saving" />
    <div v-if="saving">{{ counter }} / {{ total }}</div>
    <div class="settings-button d-inline-block" @click="saveAll"><loading-spinner v-if="loading" class="d-inline-block float-right" /> {{ $t('save') }}</div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import MatColorAdder from "./widgets/editors/MatColorAdder";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
export default {
  name: "MobileMaterialSetter",
  components: {
    Icon,
    MatColorAdder,
    LoadingSpinner,
    LoadingPanel
  },
  props: {
    instanceId: {type: String, required: true},
    multiInstanceIds: {type: Array, default: () => {return []}},
    assetId: {type: String, required: true},
    projectId: {type: String, required: true},
    name: {type: String, default: ""},
    organizationId: {type: String, required: true},
    defaultTiling: {type: Array, default: () => {return [1,1]}},
    /**
     * Type of the asset – possible values: node or model
     * */
    type: {type: String, required: true}
  },
  data() {
    return {
      listName: this.projectId + '-matlist',
      instanceValue: null,
      assetValue: null,
      materialMetaFieldId: "c2b71aad-0cbf-412f-9f2c-44b1fa46b85e",
      forceReRenderKey: 0,
      loading: false,
      loadingMats: false,
      saving: false,
      counter: 0,
      total: 0
    }
  },
  watch: {
    instanceId() {
      this.loadMaterials()
    },
    assetId() {
      this.loadMaterials()
    }
  },
  beforeMount() {
    this.loadMaterials();
  },
  methods: {
    async saveAll() {
      console.log('hitting saveall!')
      this.loading = true;
      const instanceVals = this.$refs.local.getValues();
      let assetVals = undefined;
      if(this.$refs.global) {
        assetVals = this.$refs.global.getValues();
      }

      this.loadingMats = true;
      if(instanceVals.length) {
        if(this.multiInstanceIds && this.multiInstanceIds.length) {
          await this.saveInstanceValues(JSON.stringify(instanceVals));
        } else {
          await this.saveInstanceValue(JSON.stringify(instanceVals), this.instanceId);
        }
      } else {
        if(this.multiInstanceIds && this.multiInstanceIds.length) {
          await this.removeInstanceValues();
        } else {
          await this.removeInstanceValue(this.instanceId);
        }
      }
      if(assetVals !== undefined) {
        if(assetVals.length) {
          await this.saveAssetValue(JSON.stringify(assetVals));
        } else {
          await this.removeAssetValue();
        }
      }
      this.$store.dispatch('createNotification', {'text': this.$t('events.metaValuesUpdated')});
      await this.loadMaterials();
      this.loading = false;
    },
    checkValueLength(value) {
      try {
        const item = JSON.parse(value);
        return !!item.length;
      } catch {
        return false;
      }
    },
    async saveAssetValue(value) {
        return this.$store.dispatch('clientSaveAssetMetaSetValues', {
          id: this.assetId,
          values: [{metaFieldId: this.materialMetaFieldId, value: value}]
        })
    },
    async removeAssetValue() {
      return this.$store.dispatch('clientUnsetAssetMetaSetValues', {
        id: this.assetId,
        values: [this.materialMetaFieldId]
      })
    },
    async removeInstanceValues() {
      this.saving = true;
      this.total = this.multiInstanceIds.length;
      for(let i = 0; i < this.multiInstanceIds.length; i++) {
        this.counter = i+1;
        await this.removeInstanceValue(this.multiInstanceIds[i]);
      }
      this.saving = false;
    },
    async removeInstanceValue(instanceId) {
      console.log('removing instance meta...')
      return this.$store.dispatch('clientUnsetInstanceMetaSetValues', {
        id: this.projectId,
        cid: instanceId,
        values: [this.materialMetaFieldId]
      })
    },
    async saveInstanceValues(value) {
      this.saving = true;
      this.total = this.multiInstanceIds.length;
      for(let i = 0; i < this.total; i++) {
        this.counter = i+1;
        await this.saveInstanceValue(value, this.multiInstanceIds[i]);
      }
      this.saving = false;
    },
    async saveInstanceValue(value, instanceId) {
        return this.$store.dispatch('setInstanceMetaValues', {
          id: this.projectId,
          cid: instanceId,
          values: [{metaFieldId: this.materialMetaFieldId, value: value}]
        })
    },
    async loadMaterials() {
      this.loadingMats = true;
      // load squashed meta for instance with all 3 values
      // add option
      await this.$store.dispatch('loadAssemblyItemsInstances', {
        id: this.projectId,
        listName: this.listName,
        include: 'squashedMeta',
        filter: 'type in model node,id eq ' + this.instanceId,
        limit: 1
      }).then(res => {
        if(res && res.length) {
          const instance = res[0];
          const meta = instance.squashedMetaFields;
          if(meta && meta.length) {
            const matVal = meta.filter(item => {return item.key === 'material' && item.metaSetId === '03951580-26dd-4560-8541-272b584d78fc'});
            if(matVal && matVal.length) {
              const {squashedMetaValues} = matVal[0];
              this.instanceValue = squashedMetaValues.instanceMetaValue;
              this.assetValue = squashedMetaValues.assetMetaValue;
            }
          }
        }
        this.forceReRenderKey++;
        this.loadingMats = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lazy-node-material {
  z-index: 500;
  background-color: var(--vform-editor-ui-secondary-color);
  -webkit-box-shadow: 8px 8px 6px -5px black;
  box-shadow: 8px 5px 6px -5px black;
  &.popup {
    position: absolute;
    top: -30px;
    right: -600px;
    width: 600px;
    min-height: 300px;
  }
}
</style>