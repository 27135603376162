<script>
// there is no template because it uses the template of vFormBUTTONMixin
import vFormBUTTONMixin from "@/components/vForm/vFormBUTTONMixin.js";

export default {
  name: "vFormBUTTON",
  mixins: [vFormBUTTONMixin],
  beforeMount() {
    this.element.formElementType = this.vFormControls["BUTTON"];
  },
}
</script>