<template>
  <div>
    <div v-if="files">
      <div v-if="label" class="mb-2">{{ $t(label) }} <info-helper v-if="description" :text="description" /></div>
      <input type="text"
             class="form-text w-100"
             @keyup="filter"
             v-model="filterString"
             :placeholder="$t('Filter')"
      >
      <div class="form-error" v-if="error">{{ $t(error) }}</div>
      <div class="mt-2 mb-2 lighter">{{ $t('SelectedFile') }}</div>
      <table-widget
          v-if="selected.length"
          class="mb-3 selectedTable"
          id="selectedTable"
          :fields="fields"
          :data="selected"
          :no-header="true"
          :key="forceReRenderKey"
          :can-unlink="true"
          @unlink="unlink"
      />
      <div v-if="Object.keys(filteredFiles).length" class="scroll-container">
        <table-widget
            class=""
            id="toBeSelectedTable"
            :fields="fields"
            :data="filteredFiles"
            :no-header="true"
            @selectCheckbox="selectRow"
            :key="forceReRenderKey"
            :full-row-selectable="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
const JSZip = require('jszip');
import TableWidget from "@/components/widgets/TableWidget";
import InfoHelper from "@/components/InfoHelper";
import _ from 'lodash';
import FileTypeMixin from '@/components/files/FileTypeMixin.js';

export default {
name: "ZipPreview",
  components: {
    TableWidget,
    InfoHelper,
  },
  mixins: [FileTypeMixin],
  props: {
    /**
     * @zip ArrayBuffer The zipFile as an ArrayBuffer
     */
    zip: {type: ArrayBuffer, required: true},
    /**
     * @label String The untranslated label-text
     */
    label: {type: String, default: ''},
    /**
     * @description String The untranslated description to show in the info-helper
     */
    description: {type: String, default: ''},
    /**
     * @maxSelected Number The maximal number of how many files can be selected
     */
    maxSelected: {type: Number, default: 1},
    /**
     * @allowedFileTypes Array The types that are allowed for this selector
     */
    allowedFileTypes: {type: Array, default: () => {return []}},
    /**
     * @fileStreamOnSelection Boolean If the zip-selector should emit a file stream to the parent on selection
     * (@stream event)
     */
    fileStreamOnSelection: {type: Boolean, default: false},
  },
  data() {
    return {
      files: {},
      filteredFiles: {},
      fields: [{
        name: '',
        id: 'name',
        type: 'name',
        fieldId: 'name',
      },],
      filterString: '',
      forceReRenderKey: 0,
      selected: [],
      error: '',
      convertedZip: null,
    };
  },
  watch: {
    zip() {
      this.readZip();
    },
  },
  beforeMount() {
    this.readZip();
  },
  methods: {
    selectRow(id) {
      this.error = '';
      if(this.selected.length === this.maxSelected) {
        this.error = this.$t('maxSelected', {num: this.maxSelected});
        return;
      }
      this.selected.push(JSON.parse(JSON.stringify(this.files[id])));
      if(this.filteredFiles[id]) delete this.filteredFiles[id];
      this.forceReRenderKey++;
      this.$emit('selected', this.getFileNameOnly(id));
      if(this.fileStreamOnSelection) {
        this.getFileFromZip(id);
      }
    },
    unlink(item) {
      const id = item.id;
      this.error = '';
      this.$emit('unselected', id);
      let index = this.selected.findIndex(item => { return item.id === id});
      this.filteredFiles[id] = this.selected[index];
      this.selected.splice(index, 1);
      this.filterString = '';
      this.forceReRenderKey++;
    },
    filter() {
      let self = this;
      this.filteredFiles = this.files;
      if(!this.filterString) {
        return;
      }
      this.filteredFiles = _.pickBy(this.files, function(value, key) {return key.toLowerCase().includes(self.filterString.toLowerCase());})
    },
    readZip() {
      if(this.zip) {
        let self = this;
        this.convertedZip = JSZip();

        this.convertedZip.loadAsync(self.zip, {}).then(newZip => {
          self.files = Object.assign({}, ...Object.entries(newZip.files).map(([k, value]) => ({[k]: {name: value.name, id: value.name}})));
          self.files = _.pickBy(self.files, function(value, key) {
            return (!self.allowedFileTypes || !self.allowedFileTypes.length) || self.allowedFileTypes.includes(self.getExtension(key).toLowerCase());})
          self.filteredFiles = self.files;
        });
      }
    },
    getFileNameOnly(path) {
      let fileName = path;
      if(path.includes('/')) {
        const arr = fileName.split('/');
        fileName = arr[arr.length - 1];
      }
      return fileName;
    },
    getFileFromZip(id) {
      let fileName = id;
      const self = this;
      this.convertedZip.file(fileName).async('nodebuffer').then(function(content) {
        /*var dest = path + id;
        fs.writeFileSync(dest, content);*/
        const text = new TextDecoder("utf-8").decode(content);
        fileName = self.getFileNameOnly(fileName);
        self.$emit('getFileAsString', text, fileName);
      });
    },
  },
}
</script>

<style lang="scss">
  .selectedTable {
    tr {
      &.even, &.odd {
        background-color: $highlight;
      }
      .icon {
        &:hover svg {
          color: rgba(255,255,255,0.3);
        }
      }
    }
  }
</style>