<template>
  <div class="template-editor newStyle" id="template-editor">
    <!-- 
    Display the currently selected template type.
    Useful for debugging.
    {{ templateObject }} 
    -->
    <div v-if="!templateAssetId">
      <icon type="question-circle" /> {{ $t('vform.templateAssetNotFound') }}
    </div>

    <div v-else class="row">

      <div class="col-6">
        <h2>{{ $t('vform.templateEditor') }}</h2>
        <div>
          <label for="template-type" class="vform-label white mb-0">{{ $t('vform.templateType') }}</label><br />
          {{ getComponentName(selectedTemplateType) }}
        </div>
        <div class="mt-2 template-preview">
          <label class="vform-label white mb-0">{{ $t('Preview') }}</label><br />
          <div :key="forceRerenderKey" v-if="selectedTemplateType">
            <!-- Preview based on type -->
             <component
                :key="forceReRenderKey"
                :is="getComponentName(selectedTemplateType)"
                :config="templateObject"
                lang="Unknown"
                :template-mode="true"
                :template="selectedTemplate"></component>
          </div>
          <div v-else>
            <div class="form-error" v-if="selectedTemplate">
              <icon type="exclamation-triangle" /> {{ $t('vform.noTemplateSelected') }}
            </div>
          </div>
        </div>
        <!--<div class="dflex mt-3">
          <label :for="'saveContent' + $vnode.key" class="container ml-0"
          >{{ $t("saveContent") }}
            <input
                :id="'saveContent' + $vnode.key"
                v-model="saveContent"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>-->
        <div class="mt-3">
          <label class="vform-label white mb-2">{{ $t('vform.selectTemplate') }}</label><br />
          <div v-if="unknownError" :key="forceReRenderKey" class="form-error template-editor-error w-auto">
            <icon type="exclamation-triangle" /> {{ unknownError }}
          </div>
          <div v-if="templateIsEmptyError" :key="forceReRenderKey" class="form-error template-editor-error">
            <icon type="exclamation-triangle" /> {{ $t('vform.templateIsEmpty') }}
          </div>
          <div v-if="selectedTemplateId" :key="forceReRenderKey" class="form-error template-editor-error w-auto">
            <icon type="exclamation-triangle" /> 
            {{ $t('vform.templateOverwrite') }}
            <div @click="selectedTemplateId = ''" class="settings-button d-inline-block switched-colors">{{ $t('vform.createNewTemplate') }}</div>
          </div>
          <drop-down
              v-if="templates.length"
              class="template-name dark"
              :values="templateFiles"
              :labels="templateNames"
              :initial-active-value="selectedTemplateId"
              @select="(id) => {
                changeTemplate(id);
                forceReRenderKey++;
              }"
          />
          <div v-if="!selectedTemplateId" class="mt-2">
            <input 
              class="form-text form-text-dark template-name" 
              v-model="newTemplateName" 
              placeholder="New Template Name" 
              />
          </div>
          <div class="settings-button d-inline-block mt-2 switched-colors" @click="saveTemplate">{{ $t('vform.saveTemplate') }}</div>
        </div>
      </div>
      <div class="col-6">
        <properties-panel
            id="template-editor-properties"
            :selected-element="temporaryObject"
            current-lang="Unknown"
            :template-asset-id="templateAssetId"
            :state="{}"
            :show-template-applier="false"
            :template-editing="true"
            :config="{steps: []}"
        />
      </div>
    </div>
  </div>
</template>

<script>

import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY.vue";
import BUTTON_ITEM from "@/components/vForm/viewer/BUTTON_ITEM.vue";
import HEADLINE from "@/components/vForm/viewer/HEADLINE.vue";
import RADIOBUTTONS from "@/components/vForm/viewer/RADIOBUTTONS.vue";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES.vue";
import LOGIN from "@/components/vForm/viewer/LOGIN.vue";
import LOGOUT from "@/components/vForm/viewer/LOGOUT.vue";
import DropDown from "@/components/forms/DropDown.vue";
import {getComponentName} from "@/components/vForm/utils";
import {templateTypes, vformTemplatableFields, vFormTemplatableSubFields} from "@/enum";
import {v4 as uuidv4} from "uuid";
import {applyTemplate, getTemplateFile} from "@/components/vForm/templateFunctions";
import PropertiesPanel from "@/components/vForm/PropertiesPanel.vue";
import Icon from "@/components/Icon.vue";

export default {
  name: 'TemplateEditor',
  components: {
    PropertiesPanel,
    DropDown,
    TEXTDISPLAY,
    BUTTON_ITEM,
    HEADLINE,
    RADIOBUTTONS,
    CHECKBOXES,
    LOGIN,
    LOGOUT,
    Icon,
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    templateAssetId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      templateTypes,
      selectedTemplateType: 'Button',
      selectedTemplateId: '',
      newTemplateName: '',
      templates: [],
      templateObject: {},
      saveContent: false,
      temporaryObject: {},
      forceReRenderKey: 0,
      unknownError: '',
      templateIsEmptyError: false,
      /*textFields: [
          'label'
      ]*/
    };
  },
  computed: {
    selectedTemplate() {
      return this.templates ? this.templates.find(template => template.key === this.selectedTemplateId) : null;
    },
    templateNames() {
      return this.templates ? this.templates.map(item => {
        const {data} = item;
        return data && data.templateName ? data.templateName : 'no-name';
      }) : []
    },
    templateFiles() {
      return this.templates ? this.templates.map(item => {
        return item.key;
      }) : []
    }
  },
  watch: {
    /*saveContent() {
      this.setTemplateObject(this.temporaryObject);
    },*/
    temporaryObject: {
      deep: true,
      handler() {
        console.log('change!!')
        this.setTemplateObject();
      }
    },
    selectedTemplateId(newVal) {
      if (newVal !== '') {
        this.newTemplateName = '';
      } 
    }
  },
  beforeMount() {
    this.selectedTemplateType = this.object.formElementType;
    this.temporaryObject = JSON.parse(JSON.stringify(this.object));
    this.setTemplateObject();
  },
  mounted() {
    if (this.object.templateId) {
      this.selectedTemplateId = this.object.templateId;
    }
    this.updateTemplateList();
  },
  methods: {
    getComponentName,
    /**
     * This is necessary because we made the stupid decision to have a global formatting which only affects buttons in the bottom drop zone
     * */
    handleBottomBarButton() {
      if(this.object.bottomDropZone) {
        if(!this.templateObject.backgroundColor) {
          this.$set(this.templateObject, 'backgroundColor', getComputedStyle(document.documentElement).getPropertyValue('--vform-editor-layout-accent-color'));
          this.$set(this.templateObject, 'color', getComputedStyle(document.documentElement).getPropertyValue('--vform-editor-emphasized-font-color'))
        }
      }
    },
    setTemplateObject() {
      console.log('setting new...')
      let fields = vformTemplatableFields;
      let subFields = vFormTemplatableSubFields;
      /*if(this.saveContent) {
        fields = [...fields, this.textFields];
      }*/
      this.templateObject = {};
      for(let i = 0; i < fields.length; i++) {
        console.log('setting ', fields[i])
        this.$set(this.templateObject, fields[i], this.temporaryObject[fields[i]]);
      }
      for(let j = 0; j < subFields.length; j++) {
        console.log('setting subfields', subFields[j])
        this.setTemplateSubObjects(fields, subFields[j]);
      }
      this.handleBottomBarButton();
      // if(this.temporaryObject.templateId) {
      //   this.selectedTemplateId = this.temporaryObject.templateId;
      // }
    },
    setTemplateSubObjects(fields, subField) {
      if (this.temporaryObject[subField]) {
        this.$set(this.templateObject, subField, {});
        for(let i = 0; i < fields.length; i++) {
          this.$set(this.templateObject[subField], fields[i], this.temporaryObject[subField][fields[i]]);
        }
      }
    },
    changeTemplate(id) {
      this.selectedTemplateId = id;
      this.deepMerge(this.temporaryObject, this.selectedTemplate.data);
    },
    // Shallow copies don't work since null values need to be overwritten
    isObject(obj) {
      return obj && typeof obj === 'object' && !Array.isArray(obj);
    },
    deepMerge(target, source) {
      for (let key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            target[key] = {};
          }
          this.deepMerge(target[key], source[key]);
        } else {
          // Directly assign the value, allowing null to overwrite target's values
          target[key] = source[key];
        }
      }
    },
    async updateTemplateList() {
      let files = await getTemplateFile(this.$store, this.templateAssetId, {
        metaFilter: {
          templateType: templateTypes.FORM_BLOCK
        }
      });
      files = files.filter(item => {
        return item.data && item.data.formElementType === this.object.formElementType;
      })
      this.templates = files ? files : [];
    },
    async saveTemplateFile(existingId = null) {
      const fileName = existingId ? existingId : (uuidv4() + '.json');
      // this.selectedTemplateType.toLowerCase()
      this.templateObject.templateName = this.selectedTemplate ? this.selectedTemplate.data.templateName : this.newTemplateName;
      await this.$store.dispatch('createAssetTextFile', {
        id: this.templateAssetId,
        fileName,
        fileContent: JSON.stringify(this.templateObject)
      }).then(async () => {
        const attributes = {
          templateType: templateTypes.FORM_BLOCK,
          itemType: this.templateObject.formElementType,
          templateName: this.templateObject.templateName,
          templateVersion: '1.0'
        };
        await this.$store.dispatch('clientUpdateAssetMeta', {
          id: this.templateAssetId,
          key: fileName,
          meta: {attributes}
        })
        
        // Intentionally add a timeout to not trigger the "no templates found" error under templateApplier.vue (Refer to line 4 - 7)
        setTimeout(() => {
          applyTemplate(this, this.object, {
            data: this.templateObject,
            key: fileName
          });
        }, 1000)
        

        this.$emit('templateSaved');
        this.$emit('templateFileChanged');
      })
    },
    async saveTemplate() {
      try {
        if (this.selectedTemplateId === '') {
          // New template
          if (!this.newTemplateName.trim()) {
            this.templateIsEmptyError = true;
            return;
          }
          const existingTemplate = this.templates ? this.templates.find(t => t.templateName === this.newTemplateName.trim()) : null;
          if (existingTemplate) {
            this.templateIsEmptyError = true;
            return;
          }
          await this.saveTemplateFile();
        } else {
          console.log('overwriting existing...', this.selectedTemplateId);
          await this.saveTemplateFile(this.selectedTemplateId);
        }
      } catch (e) {
        console.log(e);
        this.unknownError = "There has been an error saving the template.";
        this.forceReRenderKey++;
      }
    },
  },
};
</script>

<style lang="scss">
#template-editor-properties .form-elements-container {
  font-size: 16px;
}
</style>

<style lang="scss" >
.template {
  &-name {
    max-width: 250px;
  }

  &-preview {
    max-width: 300px;
    color: black;

    div {
      font-size: 16px;
    }
  }

  &-editor {
    font-size: 18px;
    height: auto;

    &-error {
      font-size: 14px;
    }

    .vform-header h1.with-bg,
    .vform-header h2.with-bg,
    .vform-header h3.with-bg {
      width: auto;
      margin-left: 0;
    }
  }


}

</style>
