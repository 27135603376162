<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                id="sceneList"
                @filter="filtering"
                :state-filter-items="['a', 'i', 'h']"
                store-name="Scene"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getScenesListsOptions(listName, 'pagination_items')"
                id="scenetablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            :permission="$store.getters.isSFXMember ? 'create' : 'createScene'"
                type="action-button" slot="mainContentButtons" link="/library/scenes/add" icon="plus">
              {{ $t('createNewType', {type: $t('type.scene')}) }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="listName"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                :edit-permission="$store.getters.isSFXMember ? 'write' : 'editScene'"
                :delete-permission="$store.getters.isSFXMember ? 'delete' : 'deleteScene'"
                :fields="tableFields"
                :data="$store.getters.getScenesByListName('sceneList')"
                :title-as-links="true"
                @sort="sort"
                 :loading="loading"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="listName"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="editScene"
                delete-permission="deleteScene"
                :fields="thumbnailFields"
                :data="$store.getters.getScenesByListName('sceneList')"
                :title-as-links="true"
                @sort="sort"
                 :loading="loading"
        />
</main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
    export default {
        name: "Scene",
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        mixins: [
            FileTypeMixin,
            LoadOverviewMixinJs
        ],
        data() {
            return {
              paginationLimit: 1000,
                registerMethod: 'registerScenesQueryParams',
                loadMethod: 'loadScenes',
                include: 'tags,teams',
                deleteMethod: 'deleteScene',
                listName: 'sceneList',
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                  {
                    name: 'DisplayName',
                    id:'displayName',
                    type: 'string',
                    sortable: true
                  },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },

                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                  {
                    name: 'DisplayName',
                    id:'displayName',
                    type: 'string',
                    sortable: true
                  },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        methods: {
            deleteRow: function(id) {
                this.$store.dispatch('deleteScene', {
                    args: [id]
                }).then(() => {
                  this.loadPaginatedData(0);
                });
            },
        }
    }
</script>
