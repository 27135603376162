<template>
  <div v-if="$store.getters.isSuperAdmin || allowAccess" class="meta-set-attacher">
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     custom-message="RemoveMetaSetWarning"
                     @abort="() => {showDeletePrompt = false;}"
                     @confirm="() => {removeMetaSet();}"
      />
    </popup>
    <h4 class="mb-3">{{ $t('AttachOrRemoveMetaSet') }}</h4>
    <div class="lighter">{{ $t('RemoveMetaSetWarning') }}</div><br />
    <select v-model="selected" class="custom-select col-12 col-md-6">
      <option
          v-for="(item, index) in $store.getters.getMetaSetsByListName(targetId)"
          v-if="(!attachedMetaSets || !attachedMetaSets.length) || !attachedMetaSets.filter(meta => meta.id === item.id).length" :key="index" :value="item.id">
        {{ item.description ? item.description : item.name }}
      </option>
    </select><br/>
    <div
        :deactivated="!selected"
        class="settings-button d-inline-block mb-3 mt-1 mr-2"
        type="button"
        @click="attachMetaSet"
    >
      <icon type="save"/>
      {{ $t('ADD') }}
    </div>
    <div class="w-100"/>
    <select v-model="unselected" class="custom-select col-12 col-md-6">
      <option
          v-for="(item, index) in $store.getters.getMetaSetsByListName(targetId)"
          v-if="(!attachedMetaSets || !attachedMetaSets.length) || attachedMetaSets.filter(meta => meta.id === item.id).length" :key="index" :value="item.id">
        {{ item.description ? item.description : item.name }}
      </option>
    </select><br/>
    <div
        :deactivated="!unselected"
        class="settings-button d-inline-block mb-3 mt-1"
        type="button"
        @click="removeMetaSet"
    >
      <icon type="save"/>
      {{ $t('REMOVE') }}
    </div>
  </div>
</template>

<script>
import Icon from "../../Icon";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Popup from "@/components/Popup";

export default {
  name: "MetaSetAttacher",
  components: {
    Icon,
    DeletePrompt,
    Popup
  },
  props: {
    targetId: {type: String, required: true},
    storeName: {type: String, required: true},
    givenMetaSets: {
      default: () => {
      }
    },
    /**
     * Whether or not to show a warning on removing a metaSet
     * */
    showWarning: {type: Boolean, default: false},
    organizationId: {type: String, default: ''},
    allowAccess: {type: Boolean, default: false}
  },
  data() {
    return {
      selected: '',
      unselected: '',
      attachedMetaSets: [],
      showDeletePrompt: false
    };
  },
  watch: {
    givenMetaSets() {
      this.attachedMetaSets = this.givenMetaSets ? this.givenMetaSets : [];
    }
  },
  beforeMount() {
    this.attachedMetaSets = this.givenMetaSets;
    let args = {listName: this.targetId};
    if (this.organizationId) {
      args.add = {};
      args.add.filter = {default: 'organizationId eq ' + this.organizationId}
      if (this.organizationId && this.$store.getters.isSuperAdmin) {
        args.add = {};
        args.add.filter = {default: `organizationId in '${this.organizationId}' '${this.$store.getters.getSuperOrg}'`}
      }
    }
    args.add = {sort: {targetId: "+description"}};
    this.$store.dispatch('loadMetaSets', args);
  },
  methods: {
    attachMetaSet() {
      this.$store.dispatch(`attach${this.storeName}MetaSets`, {
        id: this.targetId,
        args: [this.selected]
      }).then(() => {
        this.$store.dispatch('createNotification', {'text': this.$t('MetaSetAttached')});
        this.$emit('added', this.selected);
      })
    },
    removeMetaSet() {
      if (this.showWarning && !this.showDeletePrompt) {
        this.showDeletePrompt = true;
      } else {
        this.$store.dispatch(`remove${this.storeName}MetaSets`, {
          id: this.targetId,
          args: [this.unselected]
        }).then(() => {
          this.$store.dispatch('createNotification', {'text': this.$t('MetaSetRemoved')});
          this.$emit('removed', this.unselected);
          this.showDeletePrompt = false;
        })
      }
    },
  }
}
</script>

<style lang="scss">
.info-panel {
  .meta-set-attacher {
    .custom-select {
      background-color: $tag-background-dark;
      color: #fff;
    }
  }
}
</style>