<template>
  <two-column-layout
      :id="$route.params.id"
      back-button-tab-selector="templatebackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="templatetab"
      type="template"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        :id="$route.params.id"
        slot="sidebarLeft"
        :created="$store.getters.getTemplateCreationDate(id)"
        :downloadable="true"
        :last-change="$store.getters.getTemplateChangeDate(id)"
        :organization="$store.getters.getTemplateOrganization($route.params.id)"
        :upload-type="'Asset'"
        preview-id="templateEdit"
    />
    <info-panel
        :id="$route.params.id"
        :key="'infopanel2'"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getTemplateOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getTemplateTags($route.params.id)"
        preview-id="templateEdit"
        @addTag="addTag"
        @removeTag="removeTag"
    />
    <team-attacher
        v-if="$store.getters.getTemplateOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getTemplateOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

    <div slot="mainContent">
      <name-display-edit-block
          :id="$route.params.id"
          :organization-id="$store.getters.getTemplateOrganization($route.params.id)"
          :name="$store.getters.getTemplateName(id)"
          :display-name="$store.getters.getTemplateDisplayName(id)"
          store-name="Template" />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getTemplateDescription(id)"
          store-name="Template"
      />
      <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
        <state-setter
            :given-state="$store.getters.getTemplateState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="Template"
        />
      </div>

      <div class="row">
        <div class="w-100"/>
        <div class="col-12 col-md-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getTemplateMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadTemplate"
                @removed="loadTemplate"
            />
          </info-panel>
        </div>
        <meta-panel
            v-for="(item) in $store.getters.getTemplateMetaSets(id)"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getTemplateOrganization(id)]"
            :title="item.description"
            class="col-12 col-md-6"
            icon-left="folder"
            store-name="Template"
            @updated="loadTemplate"
        />
      </div>
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import NameDisplayEditBlock from "@/components/NameDisplayEditBlock.vue";

export default {
  name: 'TemplateEdit',
  components: {
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentDescriptionBlock,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    NameDisplayEditBlock
  },
  data() {
    return {
      id: '',
      teams: [],
    };
  },
  beforeMount() {
    this.loadTemplate();
    this.id = this.$route.params.id;
    this.loadAssetTeams();
  },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadTemplate() {
      this.$store.dispatch('loadTemplate', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeTemplateTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addTemplateTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>