<template>
  <label
      :for="labelFor"
      class="container"
      :id="uniqueId"
      @click="$emit('clicked')"
  >
    {{ labelText }}
    <input
        :id="labelFor"
        v-model="selectedValue"
        :disabled="disabled"
        type="checkbox"
    />
    <span :class="type === 'checkbox' ? 'checkmark' : 'radio'"
          :style="{
            backgroundColor: color && selectedValue ? color : '',
            borderColor: color
           }"
    ></span>
  </label>
</template>

<script>
import ColorContrastMixinJs from "../../mixins/ColorContrastMixin.js";

export default {
  name: "CheckboxWidget",
  components: {},
  mixins: [ColorContrastMixinJs],
  props: {
    labelFor: {type: String, default: 'chk'},
    initialValue: {type: Boolean, default: false},
    labelText: {type: String, default: ''},
    color: {type: String, default: ''},
    type: {type: String, default: 'checkbox'},  //checkbox, radio
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      selectedValue: false,
      uniqueId: null,
    }
  },
  watch: {
    selectedValue() {
      this.$emit('change', this.selectedValue);
    }
  },
  beforeMount() {
    this.selectedValue = this.initialValue;
    this.uniqueId = 'id-' + Math.floor(Math.random() * 16777215).toString(16);
    let styleString = '';
    styleString += '#' + this.uniqueId + ' .checkmark::after {border-color: ' + this.getContrast(this.color) +'}';
    styleString += ' #' + this.uniqueId + ' .radio::after {border-color: ' + this.getContrast(this.color) +'}';

    const style = document.createElement('style');
    style.id = this.uniqueId;
    style.appendChild(document.createTextNode(styleString));
    document.getElementsByTagName('head')[0].appendChild(style);
  },
}
</script>