var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
      'w-100 vform-text-display position-relative', 
    _vm.config.emphasized ? 'vform-emphasized font-weight-bold' : '', 
    _vm.config.backgroundColor ? 'with-bg' : 'without-bg',
    _vm.templateMode ? 'template-mode' : ''],style:({
          backgroundColor: _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
          color: !_vm.config.emphasized && _vm.config.fontColor ? _vm.config.fontColor : '',
          fontFamily: _vm.getFontName()
        })},[_vm._t("dropzone"),(_vm.getImage(_vm.config, _vm.lang))?_c('preview',{staticClass:"mini mr-2 float-left",attrs:{"asset-id":_vm.getImage(_vm.config, _vm.lang),"may-load-now":_vm.isActive,"preview-id":_vm.getImage(_vm.config, _vm.lang),"removable":false,"source":"asset"}}):_vm._e(),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected,"has-template":!!_vm.config.templateId},on:{"editProperty":_vm.editProperty,"editTemplate":_vm.editTemplate,"removeBlock":function($event){return _vm.$emit('removeBlock')}}}):_vm._e(),_c('div',{class:[
        _vm.config.backgroundColor ? 'with-bg' : 'without-bg',
        _vm.templateMode ? 'template-mode' : '' ],attrs:{"id":_vm.uniqueId,"contenteditable":"false"},on:{"input":_vm.onInput,"click":function($event){$event.stopPropagation();return _vm.doNothing($event)}}},[_vm._v(_vm._s(_vm.getLabel(_vm.config, _vm.lang) ? _vm.getLabel(_vm.config, _vm.lang) : _vm.placeholders.LONG)+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }