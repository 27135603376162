<template>
    <div class="template-previewer newStyle">
        <div class="row">
            <h2>{{ $t('vform.templatePreviewer')}}</h2>
        </div>
        <div class="row mb-3">
            <text-input
                ref="textinput"
                :id="'searchQuery'"
                type="text"
                :placeholder="$t('typeToSelect')"
                @typingAlert="setSearchQuery"
            />
        </div>
        <div class="template-previewer_container pt-4 pb-4 row col-12">
            <div 
                v-if="filteredTemplates.length > 0"
                v-for="template in filteredTemplates" 
                :key="template.key"
                @click="switchToTemplate(template)"
                :class="[
                    'template-previewer_item d-flex justify-content-between flex-column mr-2 mb-2 p-2',
                    selectedTemplateId === template.key ? 'template-previewer_item-selected' : '']"
            >
                <component
                    :key="forceReRenderKey"
                    :class="['template-previewer_component', 'mb-1']"
                    :is="getComponentName(template.data.formElementType)" 
                    :template-mode="true"
                    :config="template.data"
                    lang="Unknown"
                    :template="template"></component>
                <br/>
                {{ template.data.templateName }}
            </div>

            <div v-if="filteredTemplates.length === 0">
                {{ $t('vform.noTemplatesFound') }}
            </div>
        </div>
    </div>
</template>

<script>
import {getComponentName} from "@/components/vForm/utils";
import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY.vue";
import BUTTON_ITEM from "@/components/vForm/viewer/BUTTON_ITEM.vue";
import HEADLINE from "@/components/vForm/viewer/HEADLINE.vue";
import RADIOBUTTONS from "@/components/vForm/viewer/RADIOBUTTONS.vue";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES.vue";
import LOGIN from "@/components/vForm/viewer/LOGIN.vue";
import LOGOUT from "@/components/vForm/viewer/LOGOUT.vue";
import Icon from "@/components/Icon.vue";
import TextInput from "../forms/TextInput";

export default {
    name: 'TemplatePreviewer',
    components: {
        TEXTDISPLAY,
        BUTTON_ITEM,
        HEADLINE,
        RADIOBUTTONS,
        CHECKBOXES,
        LOGIN,
        LOGOUT,
        Icon,
        TextInput,
    },
    data() {
        return {
            forceReRenderKey: 0,
            searchQuery: '',
        }
    },
    computed: {
        filteredTemplates() {
            if(this.searchQuery === '') {
                return this.compatibleTemplates;
            }

            return this.compatibleTemplates.filter(template => {
                return template.data.templateName.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        }
    },
    props: {
        compatibleTemplates: {
            type: Array,
            required: true,
        },
        selectedTemplateId: {
            type: String,
            required: true,
        }
    },
    methods: {
        getComponentName,
        switchToTemplate(template) {
            this.$emit('switchToTemplate', template, this.selectedTemplateId === template.key);
        },
        setSearchQuery(input) {
            this.searchQuery = input.value;
        }
    }
}
</script>

<style lang="scss">
.template-previewer {
    &_container {
        max-height: 60vh;
        overflow: auto;
    }

    &_component {

        // width: auto !important;
    }

    &_item {
        width: 32%;
        border-radius: 5px;
        height: 100%;
        border: 1px solid var(--vform-editor-layout-primary-color);

        &-selected {
            border: 1px solid #fff;
        }

        .vform-header h1.with-bg,
        .vform-header h2.with-bg,
        .vform-header h3.with-bg {
            width: auto;
            margin-left: 0;
        }
    }
}
</style>