<template>
<div id="threedview" class="threedview">
  <select class="custom-select sfxfile-selector" v-model="currentFile">
    <option v-if="lodComplexity[file.key]" :disabled="!checkFileSize(file)" :value="file.key" :key="file.key" v-for="file in threeDFiles">{{ lodComplexity[file.key] }}</option>
  </select>
  <!--<div class="float-right" @click="turnleft">turn left</div>-->
  <div class="babyloncontainer" v-if="!isFBX">
    <div :class="animated ? '' : 'active'" @click="animated = false" id="stop"><icon type="pause" /></div>
    <div @click="animated = true" :class="animated ? 'active' : ''" id="play"><icon type="play" /></div>
    <canvas :key="forceReRenderKey" id="baby" ref="bjsCanvas" />
  </div>
  <div v-else>
    <three :id="currentId" :object-u-r-l="objectURL" />
  </div>
</div>
</template>

<script>
/* eslint-disable */
import Icon from "../Icon";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Debug/debugLayer";
import {
  Color3,
  Engine,
  FreeCamera,
  HemisphericLight,
  DirectionalLight,
  Scene,
  SceneLoader,
  StandardMaterial,
  Vector3,
    Matrix,
  Space,
  TransformNode,
  UniversalCamera,
  ArcRotateCamera,
  AxesViewer,
  MeshBuilder,
  CubeTexture,
    Texture,
  AssetsManager
} from "@babylonjs/core";
import {lodComplexity} from "@/enum";
import Three from "./Three";
import listenerMixinJs from "../mixins/listenerMixin.js";

export default {
  name: "Babylon",
  components: {Icon, Three},
  props: {
    assetId: {type: String, default: null},
    instanceId: {type: String, default: null},
    source: {type: String, default: "Asset"},
    // this one will be used to list the files
    storageSource: {type: String, default: "Asset"},
    rotation: {type: Number, default: 0},
    autoPlayFromStart: {type: Boolean, default: false}
  },
  mixins: [listenerMixinJs],
  data() {
    return {
      hasGivenUp: false,
      objectURL: null,
      mimeType: null,
      localPreviewData: null,
      animated: false,
      localRotation: 0,
      localRotationAxis: 'x',
      files: [],
      filesFor: "",
      currentFile: 'lod1.glb',
      lodComplexity: lodComplexity,
      maxSizeLimitFBX: 6000000,
      maxSizeLimitGLB: 25000000,
      forceReRenderKey:0,
      engine: null
    };
  },
  beforeMount() {
    if(this.autoPlayFromStart) {
      this.animated = true;
    }
  },
  mounted() {
    if(this.assetId || this.instanceId) {
      this.loadThings();
    }
  },
  computed: {
    threeDFiles() {
      return this.files ?
          this.files.filter(item => {return !item.key.toLowerCase().includes('skel') && (item.key.toLowerCase().includes('fbx') || item.key.toLowerCase().includes('glb'))})
          : [];
    },
    currentId() {
      return this.instanceId ? this.instanceId : this.assetId;
    },
    isFBX() {
      return this.currentFile.includes('.fbx')
    }
  },
  watch: {
    assetId(val) {
      if(val) {
        this.loadThings();
      }
    },
    instanceId(val) {
      if(val) {
        this.loadThings();
      }
    },
    currentFile() {
      this.loadItem();
    }
  },
  beforeDestroy() {
    this.revokeURL();
  },
  methods: {
    endDrag() {
      if(this.engine) {
        this.engine.resize();
      }
    },
    loadThings() {
      this.forceReRenderKey++;
      this.loadItems();
      this.loadItem();
    },
    revokeURL() {
      if(this.objectURL) {
        window.URL.revokeObjectURL(this.objectURL);
      }
    },
    rotate(axis, degrees) {
      this.localRotation = degrees;
      this.localRotationAxis = axis;
    },
    checkFileSize(file) {
      if(file.key.toLowerCase().includes('.fbx')) {
        return file.size < this.maxSizeLimitFBX
      } else {
        return file.size < this.maxSizeLimitGLB
      }
    },
    loadItems() {
      if(this.files.length) {
        return;
      }
      console.log('clientList' + this.storageSource +'Parts');
      this.$store.dispatch('clientList' + this.storageSource +'Parts', {
        id: this.storageSource === 'Instance' ? this.instanceId : this.assetId,
      }).then(data => {
        this.files = data;
        this.filesFor = this.storageSource === 'Instance' ? this.instanceId : this.assetId;
        // in case we don't have a lod1, try a few different things...
       /* if(!this.checkFileExists(this.currentFile)) {
          if(this.checkFileExists('lod0.glb')) {
            this.currentFile = 'lod0.glb';
          } else if(this.checkFileExists('lod1.fbx')) {
            this.currentFile = 'lod0.fbx'
          } else {
            this.currentFile = 'lod1.fbx'
          }
        }*/
      }).catch(e => {
        console.log(e);
        this.files = [];
      })
    },
    checkFileExists(fileName) {
      return !!this.files.filter(item => {return item.key === fileName}).length;
    },
    loadItem() {
      const key = this.currentFile;
      let args = {
        id: this.instanceId ? this.instanceId : this.assetId,
        key: key
      };
      this.$store.dispatch('clientDownload'+ this.source, args).then(data => {
        if (!data) {
          this.hasGivenUp = true;
          this.$emit('edit');
          return;
        }
        else {
          this.hasGivenUp = false;
        }
        (async () => {
          this.mimeType = data.headers['content-type'].toLowerCase();
          this.createUrl(new Blob([data.text]));
          this.hasGivenUp = true;
          this.$emit('preview');
        })();
      }).catch(e => {
        console.log(e);
        // todo: if lod X is not working, try the same with other lod levels
        // todo: if all is not working, switch to fbx
      })
    },
    createUrl(data) {
      this.revokeURL(); // if there is a previous url – revoke it
      this.objectURL = window.URL.createObjectURL(data);
      if(!this.isFBX) {
        this.start();
      }
    },
    async start() {
      const bjsCanvas = this.$refs.bjsCanvas;
      if (bjsCanvas) {
        await this.createScene(bjsCanvas, this.objectURL);
      }
    },
    async createScene(canvas, modelURL) {
      // basic setup
      const engine = new Engine(canvas);
      this.engine = engine;
      const scene = new Scene(engine);
      SceneLoader.ShowLoadingScreen = false;
      new HemisphericLight("light", Vector3.Up(), scene);
      // sets a box at the world 0,0,0 coordinate
      /* const material1 = new StandardMaterial("box-material2", scene);
       material1.diffuseColor = Color3.Red();
       const box = MeshBuilder.CreateBox("box", { size: 0.5 }, scene);
       box.material = material1;
       box.setAbsolutePosition(Vector3.Zero())*/

      await SceneLoader.AppendAsync(modelURL, undefined, scene, undefined, ".glb");

      // this is the root of the object we just added
      const root = scene.meshes[0];

      // get bounding box for root
      const {min, max} = root.getHierarchyBoundingVectors();
      const bbCenter = new Vector3(min.x + ((max.x-min.x) / 2), min.y + ((max.y-min.y) / 2), min.z + ((max.z-min.z) / 2));

      // creates a cube at the bounding box center
      /*const material2 = new StandardMaterial("box-material2", scene);
      material2.diffuseColor = Color3.Blue();
      const box2 = MeshBuilder.CreateBox("box2", { size: 0.1 }, scene);
      box2.material = material2;
      box2.setAbsolutePosition(bbCenter)*/

      // displays the axis of the object (local coordinates) (do the same without parenting to show the world coordinates)
      // y=green, x=red, z=blue
      //const axes = new AxesViewer(scene, '5')
      //axes.xAxis.parent = root;
      //axes.yAxis.parent = root;
      //axes.zAxis.parent = root;
      //const material = new StandardMaterial("box-material", scene);
      //material.diffuseColor = Color3.Gray();
      //axes.xAxis.getChildMeshes().forEach(m => {m.material = material })


      // change the position of the root node to world center
      const rootPos = root.getAbsolutePosition();
      root.setAbsolutePosition(rootPos.subtract(bbCenter))

      // initiate the camera setting its radius to 2 times the bounding box / 2 so the scale of the object is always in view
      const camera = new ArcRotateCamera("ArcRotateCamera", 1.5708, 1.5708, 2.5 * (max.x - min.x), new Vector3(0, 0, 0), scene);
      camera.attachControl(canvas, false); // false adds a "prevent default" so e.g. drag doesn't drag the webpage
      camera.wheelPrecision = 6; //Mouse wheel speed
      camera.inputs.attached.mousewheel.wheelPrecision = 4;
      camera.maxX = 0;
      camera.maxY = 0;
      camera.maxZ = 0;
      camera.minX = 0;
      camera.minY = 0;
      camera.minZ = 0;
      this.addListener(window, "resize", engine.resize, 'resize-1');
      const view = document.getElementById('threedview');
      this.addListener(view, "resize", engine.resize, 'resize-2')

      // add object to an empty parent in order to rotate it
      const pivot = new TransformNode("pivotObject");
      pivot.position = Vector3.Zero();
      root.parent = pivot;

      /*const skybox = MeshBuilder.CreateBox("skyBox", { size: 100.0 }, scene);
      const skyboxMaterial = new StandardMaterial("skyBox", scene);
      skyboxMaterial.backFaceCulling = false;
      skyboxMaterial.disableLighting = true;*/
      //skyboxMaterial.reflectionTexture = new CubeTexture("textures/skybox", scene);
      //skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
      //skybox.infiniteDistance = true;
     // scene.fogMode = Scene.FOGMODE_EXP;
      //scene.fogDensity = 0.01;
      scene.clearColor = new Color3(0.0,0.0,0.0);

      scene.ambientColor = Color3.White();
      const l = new DirectionalLight('light1', new Vector3(0,-1,0), scene);
      //l.parent = camera;
      l.diffuse = new Color3(1.0,1.0,1.0);
      l.intensity = 10;
      const $this = this;
      engine.runRenderLoop(() => {
        scene.render();
        if($this.animated) {
          pivot.rotate(new Vector3(0,1,0), 0.025, Space.WORLD);
        }
        if($this.localRotation) {
          pivot.rotate(new Vector3(this.localRotationAxis === 'x',this.localRotationAxis === 'y',this.localRotationAxis === 'z'), this.localRotation, Space.WORLD)
          this.localRotation = 0;
        }
        root.showBoundingBox = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.babycontainer, .babyloncontainer {
  width: 100%;
  height: 100%;
}
canvas {
  width: 100%;
  height: 100%;
}
#stop, #play {
  font-size: 0.9rem;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &.active, &:hover {
    color: rgba(255,255,255,0.3);
  }
}
#play {
  left: 20px;
}
.sfxfile-selector {
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: inline-block;
  z-index: 55;
  max-width: 200px;
}
</style>