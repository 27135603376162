<template>
    <div />
</template>

<script>
export default {
    name: "ArrayMixin",
    components: {},
    data() {
        return {};
    },
    methods: {
        fieldsToArray: function (fields) {
            let array = [];
            let keys = Object.keys(fields);
            //fill array
            keys.forEach((key) => {
                fields[key]["name"] = key;
                array.push(fields[key]);
            });
            //sort array
            array.sort((a, b) => (a.columnIndex > b.columnIndex ? 1 : -1));
            //set columnIndex = key in array (so we have a steady numbering from 0 to n)
            let length = array.length;
            for (var i = 0; i < length; i++) {
                array[i].columnIndex = i;
            }
            return array;
        },
        dataToArray: function (data) {
            let array = [];
            let keys = Object.keys(data);
            //fill array
            keys.forEach((key) => {
                let dataItem = data[key]["data"];
                array.push(dataItem);
            });
            return array;
        },
        dataToObjectArray: function (data) {
            let array = [];
            let keys = Object.keys(data);
            keys.forEach((key) => {
                let dataItem = data[key]["data"];
                array.push(dataItem);
            });
            return array;
        },
        /**
         * Generic object comparer. Works for string and numeric properties
         * @param key - property key
         * @param order - sort order. 'asc' / 'desc'. Defaults to 'asc'
         * @returns {int}
         */
        objectComparer(key, order = "asc") {
            return function innerSort(a, b) {
                if (
                    !Object.prototype.hasOwnProperty.call(a, key) ||
                    !Object.prototype.hasOwnProperty.call(b, key)
                ) {
                    // property doesn't exist on either object
                    return 0;
                }

                const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
                const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }

                return order === "desc" ? comparison * -1 : comparison;
            };
        },
    },
};
</script>
