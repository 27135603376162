<template>
  <div class="w-100 position-relative">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <div :class="['mb-3', config.emphasized ? 'vform-emphasized' : '']">
      <div :class="['w-100 vform-item-with-icon-container mb-3', getImage(config, lang) ? '' : 'w-100']">
        <div class="text d-block">
          <div class="vform-label-for-input">
            {{ getLabel(config, lang) }}{{ getLabel(config, lang) && getRequired(config) ? "*" : "" }}
          </div>
          <!--<div :class="disabled ? 'lighter' : ''">{{ getLabel(config, lang) }}{{ getRequired(config) ? "*" : "" }}</div>-->
          <input v-if="!config.textArea"
                 v-model="value"
                 :class="['w-100 vform-form-text', markAsMissing ? 'vform-error' : '', disabled ? 'lighter' : '']"
                 :disabled="disabled"
                 :style="{ borderColor: fontColor && !config.emphasized ? fontColor : 'inherit', color: fontColor && !config.emphasized ? fontColor : 'inherit'}"
                 type="text"
                 @keyup="(evt) => {$emit('storeData', config.uuid, evt.target.value)}"
                 />
          <textarea
              v-if="config.textArea"
              v-model="value"
              :rows="config.textAreaRows"
              :class="['w-100 vform-form-text', markAsMissing ? 'vform-error' : '', disabled ? 'lighter' : '']"
              :disabled="disabled"
              :style="{ borderColor: fontColor && !config.emphasized ? fontColor : 'inherit', color: fontColor && !config.emphasized ? fontColor : 'inherit'}"
              type="text"
              @keyup="(evt) => {$emit('storeData', config.uuid, evt.target.value)}"
              />
        </div>
      </div>
    </div>
    <component-logger-viewer
        v-if="config.logId"
        :element="config"
        :edit-mode="editable"
        :form-data="value"
        :project-name="projectName"
        :user-id="userId"
        :session-id="sessionId"
        :element-label="getLabel(config)"
    />
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import ComponentLoggerViewer from "./ComponentLoggerViewer.vue";

export default {
  name: "SHORT_ANSWER",
  components: {
    EditorOverlay,
    ComponentLoggerViewer
  },
  mixins: [ElementMixin],
  props: {
    userId: {type: String, default: null},
    sessionId: {type: String, default: null},
  },
  data() {
    return {
      value: '',
      markAsMissing: false
    };
  },
  beforeMount() {
    if (this.isID(this.config) && this.userId) {
      this.value = this.userId;
    }
  },
  mounted() {
    this.increaseCounter();
  },
  methods: {
    validate() {
      return this.validateOptions();
    },
    checkRequired() {
      if (this.getRequired(this.config)) {
        this.markAsMissing = !this.value;
        return !!this.value;
      }
      return true;
    },
    fillData(data) {
      this.value = data;
    },
    retrieveData() {
      return this.value;
    },
    getData() {
      if (!this.disabled) {
        const label = this.getLabel(this.config, this.currentLang);
        const data = {
          question: label,
          elementId: this.config.uuid,
          answers: [{
            answer: this.value
          }]
        }
        return {
          fields: [{
            value: (label ? label : 'no-label') + ';' + this.value,
            type: "default",
            data
          }],
          type: 'textAnswer'
        };
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.vform-emphasized {
  .vform-label {
    color: var(--vform-editor-layout-quaternary-color);
  }

  .vform-form-text {
    border: 1px solid var(--vform-editor-layout-quaternary-color);
    color: var(--vform-editor-layout-quaternary-color);
  }
}

.vform-form-text:disabled {
  background-color: transparent;
}
</style>