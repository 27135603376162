<template>
  <two-column-layout
      :id="$route.params.id"
      back-button-tab-selector="mediabackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="fonttab"
      type="media"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        :id="$route.params.id"
        slot="sidebarLeft"
        :created="$store.getters.getMediaCreationDate(id)"
        :downloadable="true"
        :last-change="$store.getters.getMediaChangeDate(id)"
        :organization="$store.getters.getMediaOrganization($route.params.id)"
        :upload-type="'Asset'"
        preview-id="mediaEdit"
    />
    <info-panel
        :id="$route.params.id"
        :key="'infopanel2'"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getMediaOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getMediaTags($route.params.id)"
        preview-id="mediaEdit"
        @addTag="addTag"
        @removeTag="removeTag"
    />
    <team-attacher
        v-if="$store.getters.getMediaOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getMediaOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

    <div slot="mainContent">
      <popup
          v-if="showQuestionPopup"
          @close="() => {showQuestionPopup = false;}"
      >
        <delete-prompt slot="popupMainContent"
                       :custom-message-params="{itemName: editFile}"
                       custom-message="youWantToReplace"
                       @abort="abortUpload"
                       @confirm="() => {startUpload(editForm); showQuestionPopup = false;}"
        />
      </popup>
      <content-title-block
          v-if="$store.getters.getMediaName(id)"
          :id="$route.params.id"
          :icon="$store.getters.getMediaMediaCategoryByName($route.params.id)"
          :organization-id="$store.getters.getMediaOrganization($route.params.id)"
          :value="$store.getters.getMediaName(id)"
          store-name="Media"

      />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getMediaDescription(id)"
          store-name="Media"
      />
      <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
        <state-setter
            :given-state="$store.getters.getMediaState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="Media"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <h2 class="mt-5">{{ $t('mediaEditFile') }}</h2>
          <div class="clickable p-1 pl-2 pr-2 bg-dark border-radius mb-2 d-inline-block" @click="loadFiles"><icon type="redo" /></div>
          <loading-spinner class="d-inline-block ml-2 pt-1 white" v-if="loading" />
          <div v-for="type in Object.values(fontTypes)"
               :key="type + forceReRenderKey"
               class="p-3 bg-dark mb-2 border-radius">
            <div class="vform-label white">{{ $t(type).toUpperCase() }}</div>
            <div class="vform-label white mb-1">
              <icon class="mr-1" type="file"/>
              {{ getFile(type) ? getFile(type) : '-' }}
              <div v-if="getFile(type)" class="d-inline-block ml-6" @click="removeFile(getFile(type), type)">
                <icon type="trash"/>
              </div>
            </div>
            <div class="dropdown mb-2 mt-2">
              <select :value="getFile(type)" class="custom-select dark"
                      @change="(evt) => {addItemTo(evt.target.value, getFile(type), type)}">
                <option v-for="file in files" :key="file.key">
                  {{ file.key }}
                </option>
              </select>
            </div>
            <upload-drop-zone
                :ref="type"
                :asset-id="$route.params.id"
                :attributes='JSON.stringify({
                  "sfxFontTypes": [type]
                })'
                :auto-update-preview="false"
                :auto-upload="false"
                :downloadable="true"
                :form-id="type"
                :make-chunks="true"
                :preview-uri="null"
                :removable="true"
                class="font-upload-zone"
                target-type="Asset"
                @fileReceived="loadFiles"
                @filesInQueue="checkFile"
                :accepted-file-types="Object.values(supportedFontTypes).map(item => {return '.' + item}).join(',')"
            />
          </div>
        </div>
        <div class="w-100"/>
        <div class="col-12 col-md-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getMediaMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadMedia"
                @removed="loadMedia"
            />
          </info-panel>
        </div>
        <meta-panel
            v-for="(item) in $store.getters.getMediaMetaSets(id)"
            v-if="$store.getters.isSuperAdmin"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getMediaOrganization(id)]"
            :title="item.description"
            class="col-12 col-md-6"
            icon-left="folder"
            store-name="Media"
            @updated="loadMedia"
        />
      </div>
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import UploadDropZone from "../components/files/UploadDropZone";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import Icon from "../components/Icon";
import FileTypeMixin from "@/components/files/FileTypeMixin.js";
import Popup from "../components/Popup";
import DeletePrompt from "../components/forms/DeletePrompt";
import LoadingSpinner from "../components/LoadingSpinner";
import {supportedFontTypes, fontCuts} from "@/enum";

export default {
  name: 'MediaEdit',
  components: {
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentTitleBlock,
    ContentDescriptionBlock,
    UploadDropZone,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    Icon,
    Popup,
    DeletePrompt,
    LoadingSpinner
  },
  mixins: [FileTypeMixin],
  data() {
    return {
      id: '',
      teams: [],
      files: [],
      fontTypes: fontCuts,
      loading: false,
      forceReRenderKey: 0,
      showQuestionPopup: false,
      editForm: "",
      editFile: "",
      supportedFontTypes: supportedFontTypes
    };
  },
  beforeMount() {
    this.loadMedia();
    this.id = this.$route.params.id;
    this.loadAssetTeams();
    this.loadFiles();
  },
  methods: {
    async addItemTo(newFile, oldFile, type) {
      if (oldFile === newFile) {
        return;
      }
      if (oldFile) {
        await this.removeFile(oldFile, type);
      }
      await this.addFile(newFile, type);
      await this.loadFiles();
      // todo: if old file: remove type from that files meta config
      // todo: newFile: add type to new file
    },
    async addFile(fileName, type) {
      let index = this.files.findIndex(item => {
        return item.key === this.sanitizeFileName(fileName)
      })
      if (index !== -1) {
        const {metaData} = this.files[index];
        let {attributes} = metaData;
        attributes.sfxFontTypes = attributes.sfxFontTypes ? [...attributes.sfxFontTypes, type] : [type]
        await this.$store.dispatch('clientUpdateAssetMeta', {
          id: this.$route.params.id,
          key: fileName,
          meta: {attributes}
        }).catch(e => {
          console.log(e);
          this.error = e;
        })
      } else {
        this.error = 'file not found'
      }
    },
    async removeFile(fileName, type) {
      let index = this.files.findIndex(item => {
        return item.key === this.sanitizeFileName(fileName)
      })
      if (index !== -1) {
        console.log(this.files[index])
        const {metaData} = this.files[index];
        let {attributes} = metaData;
        const index2 = attributes.sfxFontTypes.findIndex(item => {
          return item === type
        })
        if (index2 !== -1) {
          attributes.sfxFontTypes.splice(index2, 1);
        } else {
          console.log('type for file not found!!')
          return;
        }
        return await this.$store.dispatch('clientUpdateAssetMeta', {
          id: this.$route.params.id,
          key: fileName,
          meta: {attributes}
        })
        .catch(e => {
          console.log(e);
          this.error = e;
        })
      } else {
        this.error = 'file not found'
      }
    },
    getFile(type) {
      const index = this.files && this.files.length ? this.files.findIndex(item => {
        return item.fontTypes && item.fontTypes.includes(type);
      }) : -1;
      if (index !== -1) {
        return this.files[index].key;
      }
      return null;
    },
    checkFile(files, formId) {
      if (files.length && this.files.length) {
        const {name} = files[0];
        if (this.files.findIndex(item => {
          return this.sanitizeFileName(item.key) === this.sanitizeFileName(name);
        }) !== -1) {
          this.editForm = formId;
          this.editFile = name;
          this.showQuestionPopup = true;
          return;
        }
      }
      this.startUpload(formId);
    },
    resetUploadParams() {
      this.editForm = "";
      this.editFile = "";
      this.showQuestionPopup = false;
    },
    abortUpload() {
      this.$refs[this.editForm][0].removeFile(this.sanitizeFileName(this.editFile));
      this.$refs[this.editForm][0].startEditing();
      this.resetUploadParams();
    },
    /**
     *
     * */
    async startUpload(type) {
      const $this = this;
      if(this.getFile(type)) {
        await this.removeFile(this.getFile(type), type);
      }
      setTimeout(() => {
        $this.$refs[type][0].resumeUpload();
        $this.resetUploadParams();
      }, 200);
    },
    async loadFiles() {
      this.loading = true;
      return this.$store.dispatch(`loadAssetFiles`, {
        id: this.$route.params.id
      }).then(data => {
        if (data && data.length) {
          this.files = data.map(item => {
            const {metaData} = item;
            const {attributes} = metaData;
            item.fontTypes = attributes ? attributes.sfxFontTypes : [];
            return item;
          })
        }
        this.forceReRenderKey++;
        this.loading = false;
      })
    },
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadMedia() {
      this.$store.dispatch('loadMedia', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addMediaTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>

<style lang="scss">
.font-upload-zone {
  max-width: 150px;
}
</style>
<style lang="scss" scoped>
.custom-select {
  max-width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
</style>