<template>
  <div>
    <div class="w-100 mb-3">
      <loading-spinner v-if="loadingPDF" />
    </div>
    <div class="">
      <div :key="key + forceReRenderKey" v-for="(log, key) in logs">
        <div class="year-section p-2 mb-1 ml-1" :key="index2" v-for="(year, yearNumber, index2) in log">
          <div class="year">{{yearNumber}}</div>
          <div class="mb-1" :key="monthNumber + yearNumber + forceReRenderKey" v-for="(month, monthNumber) in year">
          <span class="lighter month-label">
            <span class="clickable" @click="createPDF(month, yearNumber, parseInt(monthNumber) + 1)">
              <icon type="download" />
            </span>
            {{ new Intl.DateTimeFormat('en-US', {month: 'long'}).format(new Date(yearNumber, parseInt(monthNumber) + 1, 0)) }}:</span>
            <span class="price">{{ getTotalPrice(month) }} CHF</span>
            <div v-if="showDetail" :key="entry.id" v-for="(entry) in month">
              {{ entry.updatedAt}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {_} from "vue-underscore";
import DateMixin from "@/components/mixins/DateMixin.js";
import { jsPDF } from "jspdf";
import Icon from "../Icon";
import LoadingSpinner from "../LoadingSpinner";
export default {
  name: "LogSummary",
  components: {Icon, LoadingSpinner},
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, required:true},
    autoLoad: {type: Boolean, default: false},
  },
  data() {
    return {
      logs: {},
      entries: [],
      showDetail: false,
      forceReRenderKey: 0,
      loadingPDF: false
    };
  },
  beforeMount() {
    if(this.autoLoad) {
      this.loadLogs();
    }
  },
  methods: {
    async createPDF(entries, year, month) {
      this.loadingPDF = true;
      const doc = new jsPDF();
      doc.setFontSize(9);
      const pageHeight = 295;

      doc.text('Side Effects AG', 10, 10);
      doc.text('Gessnerallee 28', 10, 15);
      doc.text('8001 Zürich', 10, 20);

      let origPos = 50;
      doc.setFontSize(15);

      doc.text('CAD Conversion Costs for ' + this.$store.getters.getOrganizationById(this.organizationId).displayName, 10, origPos);
      doc.setFontSize(9);
      origPos += 10;
      for(let i = 0; i < entries.length; i++) {
        const {createdAt, objectId, vmStartedAt, vmStoppedAt, clientPricePerMinute} = entries[i];
        const asset = await this.$store.dispatch('clientLoadAsset', {id: objectId})
            .catch(() => {
              console.log('asset not found')
            })
        const name = asset && asset.name ? asset.name : objectId;
        const td = this.timeDifferenceFromTwoISOs(vmStoppedAt, vmStartedAt);
        doc.text(this.dateTimeFromISO(createdAt) + ': ' + name + '  (' + td.string + ')', 10, origPos);
        if(parseInt(td.minutes)) {
          doc.text('' + (td.minutes * clientPricePerMinute).toFixed(2) + ' CHF', 180, origPos);
        }
        origPos += 5;
        if(origPos >= pageHeight) {
          origPos = 10;
          doc.addPage();
        }
      }
      doc.text('Total: ' + this.getTotalPrice(entries) + ' CHF', 10, origPos + 15);
      doc.save(year + '-' + month + "-vHUB.pdf");
      this.loadingPDF = false;
    },
    async loadLogs() {
      const logs = await this.$store.dispatch('clientLoadVMLogs', {filter: 'organizationId eq ' + this.organizationId});
      this.entries = logs;
       this.logs = this.groupLogsByMonth(logs);
       this.forceReRenderKey++;
    },
    /***
     * Groups the vm log entries by month
     * */
    groupLogsByMonth(logs) {
      logs = _.groupBy(logs, ({updatedAt})=> new Date(updatedAt).getFullYear());
      logs = Object.keys(logs).map(key => {
        return {[key]: _.groupBy(logs[key], ({updatedAt})=> new Date(updatedAt).getMonth()) };
      })
      return logs;
    },
    /***
     * calculates the total price of given entries
     * */
    getTotalPrice(entries) {
      let totalPricePerMonth = 0;
      entries.map(entry => {
        if(entry.vmStoppedAt) {
          const timeDifference = this.timeDifferenceFromTwoISOs(entry.vmStoppedAt, entry.vmStartedAt).minutes;
          if(timeDifference) {
            totalPricePerMonth += entry.clientPricePerMinute *  this.timeDifferenceFromTwoISOs(entry.vmStoppedAt, entry.vmStartedAt).minutes;
          }
        }
      })
      return totalPricePerMonth.toFixed(2);
    },

  }
}
</script>

<style lang="scss" scoped>
  .year {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .year-section {
    background-color: var(--vform-editor-ui-quinary-color);
    color: var(--vform-editor-ui-primary-color);
    height: 100%;
  }
  .month-label {
    display: inline-block;
    min-width: 110px;
  }
</style>
