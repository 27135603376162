<template>
  <div>
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; deletingVersion = null;}"
                     @confirm="() => {deleteVersion(); showDeletePrompt = false;}"
      />
    </popup>
    <popup
        v-if="showRestorePrompt"
        @close="() => {showRestorePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     custom-message="restoreVersionConfirm"
                     @abort="() => {showRestorePrompt = false; restoringVersion = null;}"
                     @confirm="() => {restoreVersion(); showRestorePrompt = false;}"
      />
    </popup>
    <h2>{{ $t('VersionsBackups') }}</h2>
    <div v-if="original && original.type && original.type === 'assembly' && $store.getters.getAssemblyPipelineStatus($route.params.id) === 'processing'"
         class="bg-highlight p-2 col-12 col-md-10 border-radius">
      {{ $t('AssemblyProcessing') }}
    </div>
    <div class="row">
      <div v-if="!isLoading && !creatingVersion && (original && original.type && original.type !== 'assembly' || $store.getters.getAssemblyPipelineStatus($route.params.id) != 'processing')"
           class="col-12 col-md-4">
        <div
            class="mr-1 settings-button d-inline-block"
            @click="createVersion">
          {{ $t('createNewType', {type: 'version'}) }}
        </div>
        <input v-model="versionName" :placeholder="$t('name')" class="form-text form-text-dark mt-2"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <loading-panel
            v-if="creatingVersion"
            :time-in-seconds="0"
            message="pleaseWait"
        />
      </div>
    </div>
    <Button class="mt-5 highlight icon-button d-inline-block mr-2"
            @click="loadAll">
      <icon type="redo"/>
    </Button>
    <loading-spinner v-if="isLoading" class="d-inline-block mb-1"/>
    <!--<div @click="listenToNotification">listen</div>-->
    <div v-for="(item, key) in versions" v-if="original" :key="key" class="mb-4 p-3 bg-dark border-radius">
      <span class="lighter">Name:</span> {{ item.versionName }}<br/>
      <span class="lighter">Tag:</span> {{ item.tag }}<br/>
      <span class="lighter">{{ $t('Created') }}:</span> {{ item.createdAt }}<br/>
      <span class="lighter">{{ $t('Updated') }}:</span> {{ item.updatedAt }}<br/>
      <div v-if="item.user">User: {{ item.user }}</div>
      <version-object
          v-if="original"
          :key="forceReRenderKey"
          :copy="item"
          :is-live="key === 0"
          :original="key === 0 ? original : original"
          :remove-buttons="!(!creatingVersion && (original.type !== 'assembly' || $store.getters.getAssemblyPipelineStatus($route.params.id) != 'processing'))"
          :show-loading-panel="creatingVersion"
          class="ml-3 mt-4 mr-3"
          @delete="deleteVersion"
          @restore="restoreVersion"
      />
    </div>
  </div>
</template>

<script>
import VersionObject from "@/components/versions/VersionObject";
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import {AssetTypes} from "@/enum";
import LoadingPanel from "@/components/LoadingPanel";
import Popup from "../Popup";
import DeletePrompt from "../forms/DeletePrompt";
import LoadingSpinner from "../LoadingSpinner";

export default {
  name: "VersionBrowser",
  components: {
    Button,
    Icon,
    VersionObject,
    LoadingPanel,
    Popup,
    DeletePrompt,
    LoadingSpinner
  },
  props: {
    /**
     * @type String Asset, Project, Instance and so on
     * */
    type: {type: String, required: true},
    /**
     * @subtype String Assembly, Media and so on
     * */
    subtype: {type: String, required: true},
    id: {type: String, required: true},
  },
  data() {
    return {
      versions: [],
      original: {},
      creatingVersion: false,
      forceReRenderKey: 0,
      showDeletePrompt: false,
      showRestorePrompt: false,
      restoringVersion: false,
      deletingVersion: null,
      isLoading: false,
      versionName: ""
    };
  },
  beforeMount() {
    this.loadAll();
  },
  methods: {
    async loadAll() {
      this.isLoading = true;
      await Promise.all([this.loadOriginal(), this.loadVersions()]);
      this.isLoading = false;
    },
    async loadOriginal() {
      this.original = null;
      let include = ['metaValues', 'metaSets'];
      if (this.type === 'Project') {
        include.push('assets')
      }
      let args = {
        id: this.$route.params.id,
        include: include,
        keep: {
          include: include
        },
      };
      if (this.subtype === 'assembly') {
        args.addPipelineState = true;
      }
      const storeName = this.subtype.charAt(0).toUpperCase() + this.subtype.slice(1);
      await this.$store.dispatch(`load${storeName}`, args)
          .then(data => {
            this.forceReRenderKey++;
            this.original = data;
          })
    },
    async loadVersions() {
      this.$store.dispatch(`clientLoad${this.type}Versions`, {
        id: this.id,
        listName: this.id + 'versions',
        sort: '-createdAt',
        keep: {
          sort: '-createdAt'
        },
      })
          .then(data => {
            this.versions.map(item => {
              return item;
            })
            this.versions = this.versions.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            });
            this.versions = data;
          })
    },
    async createVersion() {
      this.creatingVersion = true;
        await this.$store.dispatch('clientAssetToPipeline', {
          id: this.$route.params.id,
          type: 'createVersion',
          virtualMachine: null,
          config: JSON.stringify({versionName: this.versionName})
        }).then(() => {
          //this.creatingVersion = false;
          location.reload();
        }).catch(() => {
          // todo: show error
        })
            .then(() => {
              this.loadVersions();
              this.listenToNotification();
              this.creatingVersion = false;
            })
    },
    async listenToNotification() {
      /*this.$store.dispatch('clientLoadNotifications', {cid: this.$store.getters.getCurrentUserId}).then(data => {
          const res = !data.length ? [] : data.filter(item => {return item.targetId === this.id && item.category === 'version'})
          if(!res || !res.length) {
            const $this = this;
            setTimeout(() => {$this.listenToNotification()}, 5000);
          }
          else {
             this.loadOriginal();
            this.loadVersions();
            this.$store.dispatch('createNotification', {
              'text': res[0].message
             });
             this.deleteNotification(res[0].id);
          }
      })*/
    },
    async deleteNotification(id) {
      this.$store.dispatch('clientDeleteNotifications', {cid: this.$store.getters.getCurrentUserId, id: id});
    },
    async restoreVersion(id = null) {
      if (id) {
        this.restoringVersion = id;
        this.showRestorePrompt = true;
      } else {
        await this.$store.dispatch('clientAssetToPipeline', {
          id: this.$route.params.id,
          type: 'restoreVersion',
          virtualMachine: null,
          config: JSON.stringify({tag: this.restoringVersion})
        }).then(() => {
          // this is a quickfix because the view does not update dammit
          location.reload();
        }).catch(() => {
          // todo: show error
        })
      }
    },
    async deleteVersion(id = null) {
      if (id) {
        this.deletingVersion = id;
        this.showDeletePrompt = true;
      } else {
        this.creatingVersion = true;
        if (this.subtype === AssetTypes.ASSEMBLY) {
          await this.$store.dispatch('clientAssetToPipeline', {
            id: this.$route.params.id,
            type: 'deleteVersion',
            virtualMachine: null,
            config: JSON.stringify({tag: this.deletingVersion})
          }).then(() => {
            this.deletingVersion = null;
            this.loadOriginal();
            //this.creatingVersion = false;
            location.reload();
          }).catch(() => {
            // todo: show error
          })
        } else {
          await this.$store.dispatch(`clientDelete${this.type}Version`, {tag: id})
              .then(() => {
                this.loadOriginal();
                this.loadVersions();
                this.listenToNotification();
                this.creatingVersion = false;
              })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
