<template>
  <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      back-button-tab-selector="teambackbutton"
      tab-selector="teamtab"
    >
    <div slot="buttonsRight">
      <Button
          type="button"
          class="mb-3 mt-5"
          :deactivated="!enableSaving"
          @click="save"
      >
        <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
      </Button>
    </div>
        <div slot="mainContent">
            <h1><icon class="mr-1" type="users" size="0.9" /> {{ $t('createNewType', {type: $t('type.team')}) }}</h1> {{ id }}
            <organization-switcher
                :show-all-orgs="true"
                @setOrganization="id => organizationId = id"
                :show-teams="false"
                class="mb-2" />

            <input
                    type="text"
                    :class="['form-text', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.orgName">{{ $t('errors.orgNameOnly') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.alreadyExists', {type: $t('type.team') }) }}</div>

          <label>{{ $t('displayName') }}</label>
          <input
              type="text"
              :class="['form-text', $v.displayName.$dirty && $v.displayName.$error ? 'form-group--error' : '']"
              id="displayName"
              v-model="displayName"
              :placeholder="$t('enternamehere')"
              @input="delayTouch($v.displayName)"
          >

          <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.alphaNumSpace">{{ $t('errors.alphaNumSpace') }}</div>

          <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
                <textarea
                        :class="['form-text', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                        :id="'description'"
                        v-model="description"
                        :placeholder="$t('enterdeschere')"
                        @input="delayTouch($v.description)"
                />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace, orgName} from '@/customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: "TeamAdd",
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
            OrganizationSwitcher
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                displayName:'',
                organizationId: ''
            };
        },
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        validations: {
            name: {
                orgName,
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfTeamNameExists', {
                      name: value,
                      organizationId: this.$store.getters.getCurrentUserOrg,
                      type: 'Team',
                    }).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            displayName: {
              alphaNumSpace,
              minLength: minLength(3),
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        methods: {
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        displayName: this.displayName ? this.displayName : this.name,
                        description: this.description,
                        organizationId: this.organizationId,
                        visibility: 1
                    };
                    await this.$store.dispatch('createTeam', args).then(async data => {
                      await this.$store.dispatch('checkLogin'); // reload user so the user-teams get updated
                      await this.$router.push(`/admin/teams/${data.id}/general`);
                    });
                }
            },
        },
    }
</script>