<template>
    <div class="content-list-widget col-12">
      <div class="content-list-content-filter row">
        <content-filter
                v-if="getTypeStore"
                :id="listName + 'metafilter'"
                @filter="filtering"
                type="miniFilter"
                class="mb-3 col-12 pl-1 pr-1"
                :limit-to-organization-ids="organizationIds"
                :org-filtering="!!organizationIds.length"
                :store-name="getTypeStore"
                :category-filter-items="getMediaCategories()"
                :category-filter-values="getMediaCategoryValues()"
                :category-filter-pre-selected-values="getMediaCategories()"
                :type-filter-items="subType ? [subType] : null"
                :initial-type-filter="subType ? [subType] : null"
        />
        <pagination
                class="col-12"
                :limit="paginationLimit"
                :total="$store.getters['get' + getTypeStore + 'ListOptions'](listName, 'pagination_items')"
                :id="listName"
                @setActive="loadPaginatedData"
        />
      </div>
      <loading-spinner class="white mt-2 mb-2" v-if="loading" />
      <div class="list-container row">
        <div :class="[customColClasses ? customColClasses : (thumbnailView ? 'col-4 col-md-4 pl-1 pr-1 mb-1' : 'col-12 col-md-12 pl-1 pr-1 mb-2')]"
          :key="item.id"
          v-for="item in $store.getters['get' + getTypeStore + 'sByListName'](listName)"
          >
            <!--v-if="(!teams || !teams.length) || (item && item.teams && item.teams.filter(team => {return teams.includes(team.id)}).length)"-->
            <div :class="['item-chooser mb-3 border-radius', item.id === value ? 'selected' : '']" :key="item.id + 'item'" @click="save(item.id)">
                <preview
                        :class="['mb-1', thumbnailView ? 'thumbnail' : 'medium-thumbnail']"
                        :asset-or-project-id="value"
                        v-if="showPreview"
                        :preview-uri="item.previewUri"
                        :type="'image'"
                        :preview-id="item.id"
                        :removable="false"
                />
                <div :class="[thumbnailView ? 'too-long-text-break' : '']">{{ item.name }}</div>
                <div class="lighter">{{ $t('teams') }}:</div>
                <span :key="index" v-for="(team, index) in item.teams">
                  {{ team.displayName }}
                </span>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import ContentFilter from "../filter/ContentFilter";
    import Pagination from "../../Pagination";
    import {MetaFieldStores} from '../../../enum';
    import Preview from "../../preview/Preview";
    import {mediaCategories} from '@/enum';
    import LoadingSpinner from "../../LoadingSpinner";


    /**
     * An item selection list
     * @displayName Content List
     */
    export default {
        name: "ContentList",
        components: {
            ContentFilter,
            Pagination,
            Preview,
            LoadingSpinner
        },
        props: {
            /**
             * The selected item id
             * @values item-ID
             */
            value: {type: String, default: null},
            /**
             * The type to be listed
             * @values feed, table, dataset, project, app, scene, model, assembly, node, media
             */
            type: {type: String, default: ''},
            /**
             * The list name used in the corresponding store (if multiple content lists on a page, make sure it is a unique identifier)
             * @values any string, usually the id of the linking item
             */
            listName: {type: String, required: true},
            /**
             * The ID of the organization from which the contents should be listed
             */
            organizationIds: {type: Array,  default: () => {return []}},
            /**
            * The team ID's as an array if it should be filtered
            * */
            teams: {type: Array, default: null},
            /**
             * Whether to display a preview or not (if the content type doesn't have a preview property, it will be black)
             * @values true or false
             */
            showPreview: {type: Boolean, default: false,},
            /**
             * Whether to display as thumbnails or not
             * @values true or false
             */
            thumbnailView: {type: Boolean, default: false,},
            /**
             * When pagination is displayed how many items per page
             * @values {Number}
             */
            paginationLimit: {type: Number, default: 10},
            mediaCategory: {type: Number, default: null,},
            remoteSubType: {type: String, default: null},
            customColClasses: {type: String, default: ""}
        },
        data() {
            return {
                storeType: null,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                getTypeStore: '',
                subType: '',
                offset: 0,
                filter: {},
                mediaCategories: mediaCategories,
                loading: false
            };
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadData(false);
                }
            },
            remoteSubType(val) {
              if(val) {
                this.subType = val;
                this.setStore();
              }
            },
        },
        beforeMount() {
            this.setStore();
        },
        methods: {
            getMediaCategories() {
              if(this.mediaCategory) {
                return Object.keys(mediaCategories).filter(key => {return mediaCategories[key] === this.mediaCategory});
              }
              return [];
            },
            getMediaCategoryValues() {
              const cats = this.getMediaCategories();
              if(cats.length) {
                return cats.map(item => {return mediaCategories[item] ? mediaCategories[item] : null});
              }
              return [];
            },
            setStore() {
                this.setByRemoteSubType();
                if(MetaFieldStores[this.type].name) {
                  if(this.remoteSubType) {
                    this.setByRemoteSubType();
                  } else {
                    this.subType = MetaFieldStores[this.type].subType;
                  }
                  this.getTypeStore = MetaFieldStores[this.type].name;
                } else {
                  this.getTypeStore = MetaFieldStores[this.type];
                }
            },
            setByRemoteSubType() {
              if(this.remoteSubType) {
                this.subType = this.remoteSubType;
              }
            },
            filtering: function(args, initial) {
              this.filter = args;
              this.loadData(initial);
            },
            loadPaginatedData(offset, initial) {
              this.offset = offset;
              this.loadData(initial);
            },
            loadData(initial = false) {
              this.loading = true;
              let args = {
                  listName: this.listName,
                  keep: {
                    include: ['teams'],
                    listName: this.listName,
                  }
                 }
                  let paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter && this.filter.remove) {
                    args.remove = this.filter.remove;
                  }

                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('register' + this.getTypeStore + 'QueryParams', args);
                }
                else {
                  this.$store.dispatch('load' + this.getTypeStore + 's', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            save: function(id) {
                /**
                 * Emits the selected id to the parent component
                 * @param value {string} – the item ID
                 */
                this.$emit('save', id);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .item-chooser {
        padding:8px;
        background-color: var(--vform-editor-ui-quaternary-color);
        cursor:pointer;
        height: 100%;
        &:hover, &.selected {
            background: $highlight;
        }
    }
    .content-list-content-filter {
      //position: absolute;
      //top: 15px;
    }
    .content-list-widget {
      //padding-top: 15px;
    }
    .list-container {
      max-height: 500px;
      overflow: auto;
    }
</style>
<docs>
    Vue component examples:

    A media content list with a selected id:
    ```jsx
    <content-list
            value="'234ksdf8234ksdflakdj'"
            type="media"
            list-name="'asdflk4252lkhsadklg'"
            @save="updateMediaLink"
            :organization-id="'asf33141sdlk4252lkhsadklg'"
            :show-preview="true"
    />
    ```
</docs>
