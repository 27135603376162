<template>
    <div :class="['container-fluid main-container', !hasMarginTop ? 'no-margin-top' : '', backgroundImage ? 'with-background' : '']" :style="{ 'background-image': 'url(' + backgroundImage + ')' }">
        <div :class="['row content-panel', $store.getters.hideNav ? 'pt-3 pb-0' : '']">
            <slot name="filter"/>
            <div class="col">
                <div class="row">
                    <div class="col-12">
                        <slot name="mainContent"/>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
    export default {
        name: 'FullScreenLayout',
        components: {
        },
        props: {
          backgroundImage: { type: String, default:'' },
          hasMarginTop: { type: Boolean, default: true },
        },
    }
</script>
