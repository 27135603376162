<template>
  <div class="row">
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeleted = null;}"
                     @confirm="() => {showDeletePrompt = false; deleteConnection();}"
      />
    </popup>
    <div class="col-12" v-if="$store.getters.getConnectionsByListName(projectId).length">
      <h3 class="mb-3">{{ $t('AllConnections') }}</h3>
      <table :key="forceReRenderKey">
        <tr :key="item.id" v-for="item in $store.getters.getConnectionsByListName(projectId)">
          <td><b>{{ item.source.name ? item.source.name : item.source.asset.name }}</b></td>
          <td> {{ item.target.name ? item.target.name : item.target.asset.name }}</td>
          <td class="connection-delete clickable">
            <Button @click="deleteConnection(item.id, item)">{{ $t('delete') }}</Button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
    import Button from "../forms/Button";
    import Popup from "../Popup";
    import DeletePrompt from "../forms/DeletePrompt";

    export default {
        name: 'SFXEdit',
        components: {
            Button,
            Popup,
            DeletePrompt,
        },
        props: {
            projectId: {type: String, required: true},
        },
        data() {
            return {
                titleEdit: false,
                descEdit: false,
                id: '',
                connection: '',
                connections: null,
                forceReRenderKey: 0,
                toBeDeleted: null,
                showDeletePrompt: false,
            };
        },
        mounted() {
          this.loadConnections();
        },
        methods: {
            loadConnections() {
              this.$store.dispatch('loadConnections', {
                listName: this.projectId,
                keep: {
                  id: this.projectId,
                },
                add: {
                  include: {
                    sfxconnection: 'instanceAndAsset',
                  },
                },
              });
            },
            getTitle(id) {
                this.$store.dispatch('clientLoadAsset', {id: id}).then(data => {
                    return data.name;
                });
            },
            deleteConnection(id = null, connection = null) {
                if(!this.toBeDeleted) {
                    this.showDeletePrompt = true;
                    this.toBeDeleted = id;
                    this.connection = connection;
                }
                else {
                    this.$store.dispatch('deleteConnection', {
                        listName: this.projectId,
                        projectId: this.projectId,
                        id: this.toBeDeleted,
                    }).then(() => {
                      this.loadConnections();
                      this.showDeletePrompt = false;
                      this.toBeDeleted = null;
                      this.$emit('deleted', this.connection);
                    });
                }
            },
        },
    }
</script>