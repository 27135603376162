<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="materialbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="materialtab"
      type="material"
  >
    <info-panel :id="$route.params.id"
                slot="sidebarLeft"
                :created="$store.getters.getMaterialCreationDate($route.params.id)"
                :downloadable="true"
                :image-editable="true"
                :last-change="$store.getters.getMaterialChangeDate($route.params.id)"
                :organization="$store.getters.getMaterialOrganization($route.params.id)"
                :preview-uri="$store.getters.getMaterialPreviewUri($route.params.id)"
                :removable="true"
                preview-id="materialEdit"
                upload-type="Asset"
    />
    <team-attacher
        v-if="$store.getters.getMaterialOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getMaterialOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

    <div slot="mainContent">
      <name-display-edit-block
          v-if="$store.getters.getMaterialName($route.params.id)"
          :id="$route.params.id"
          :organization-id="$store.getters.getMaterialOrganization($route.params.id)"
          :name="$store.getters.getMaterialName($route.params.id)"
          :display-name="$store.getters.getMaterialDisplayName($route.params.id)"
          store-name="Material"
      />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getMaterialDescription($route.params.id)"
          store-name="Material"
      />
      <div class="row">
        <div class="col-12 col-md-6 no-gutters">
          <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
            <state-setter
                class="col-12 col-md-4 mb-2 mt-2"
                :target-id="$route.params.id"
                store-name="Material"
                :given-state="$store.getters.getMaterialState($route.params.id)"
            />
          </div>
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getMaterialMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadMaterial"
                @removed="loadMaterial"
            />
          </info-panel>
        </div>
        <meta-panel
            v-for="(item) in $store.getters.getMaterialMetaSets(id)"
            v-if="$store.getters.getMaterialOrganization($route.params.id)"
            :key="item.id"
            :fields="item.metaFields" :organization-ids="[$store.getters.getMaterialOrganization($route.params.id)]"
            :title="item.description" class="col-12 col-md-8" icon-left="folder"
            store-name="Material"
            type="material"
            @updated="loadMaterial"
        />

        <div v-if="$store.getters.isSuperAdmin" class="col-7 mt-2">
          <material-three
              v-if="$store.getters.getMaterialMetaSets($route.params.id).length"
              id="materialView"
              :meta-sets="$store.getters.getMaterialMetaSets($route.params.id)"
              :asset-id="$route.params.id"
              @saved="loadMaterial"
          />
        </div>
      </div>


    </div>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import MetaPanel from "@/components/widgets/meta/MetaPanel";
import MaterialThree from "../components/preview/MaterialThree";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import NameDisplayEditBlock from "@/components/NameDisplayEditBlock.vue";
import StateSetter from "@/components/StateSetter.vue";

export default {
  name: 'MaterialEdit',
  components: {
    StateSetter,
    InfoPanel,
    OneColumnSidebarLeft,
    ContentDescriptionBlock,
    MetaSetAttacher,
    MetaPanel,
    MaterialThree,
    TeamAttacher,
    NameDisplayEditBlock
  },

  data() {
    return {
      id: '',
      teams: []
    };
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.loadMaterial();
    this.loadAssetTeams();
  },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadMaterial() {
      this.$store.dispatch('loadMaterial', {
        id: this.$route.params.id,
        include: 'tags,metaSets'
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
  },
}
</script>