<template>
    <one-column-sidebar-left>
        <div slot="buttonsRight">
            <Button
                    class="mb-3"
                    :deactivated="!enableSaving"
                    @click="save">
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('NEXT') }}
            </Button>
        </div>
        <tabs slot="tabs"
              :classes="'col-12 col-md-2 col-lg-2 offset-md-1 backbutton'"
              :tab-selector="'reportbackbutton'"
              custom-tab-name="reportbackbutton"
              :first-inactive="true"
        />
        <tabs slot="tabs"
              :tab-selector="'addreporttab'"
              :disabled="true"
              :arrow-style="true"
              :id="'555'"
              :first-active="true"
        />

        <div slot="mainContent">
            <h1>{{ $t('createNewReport') }}</h1>
            <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @blur="$v.name.$touch"
            >

            <div class="form-error" v-if="!$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="!$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="!$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="!$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="!$v.name.isUnique">{{ $t('errors.reportNameAlreadyExists') }}</div>
            <div class="form-error" v-if="error">{{ $t(error) }}</div>
            <div>
                <div class="inline-edit-label">
                    <b>{{ $t('description') }}</b>
                </div>

                <textarea
                        :class="['form-text mb-3', $v.description.$error ? 'form-group--error' : '']"
                        :id="'description'"
                        v-model="description"
                        :placeholder="$t('enterdeschere')"
                />
                <div class="form-error" v-if="!$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            {{ $t('configuration') }}
            <codemirror
                    :value="content"
                    :show-save-button="false"
                    @change="updateContent"
            />
            <div class="form-error" v-if="!$v.content.required && $v.content.$error">{{ $t('errors.required') }}</div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import Tabs from "../components/navigation/Tabs";
    import { mapState } from 'vuex';
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';
    import Codemirror from "@/components/widgets/editors/Codemirror";
    export default {
        name: 'SFXAdd',
        components: {
            Codemirror,
            OrganizationSwitcher,
            Button,
            Tabs,
            OneColumnSidebarLeft,
            Icon,
        },
        mixins: [
            FileTypeMixin
        ],
        data() {
            return {
                organizationId: '',
                teamId: '',
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                error: null,
                type: 'report',
                content: '{\n' +
                    '    "chartConfig0":{\n' +
                    '        "preFilteringIndizes":[\n' +
                    '            0,\n' +
                    '            1],\n' +
                    '"addValuesToLabelIndizes": [],' +
                    '"preFilterSelected": [],' +
                    '        "categoryIndex1":4,\n' +
                    '        "categoryIndex2":2,\n' +
                    '        "categoryIndex3":7,\n' +
                    '        "afterSortingIndex":3,\n' +
                    '        "customColorConfig":{\n' +
                    '            "Success":"#35af20",\n' +
                    '            "Failed":"#d0330e",\n' +
                    '            "WrongAnswer":"#9c1c51",\n' +
                    '            "CorrectAnswer":"#1a730b",\n' +
                    '            "Finished":"#e0b822",\n' +
                    '            "Finish":"#e0b822",\n' +
                    '            "Started":"#e0b822"\n' +
                    '        }\n' +
                    '    }\n' +
                    '}',
            };
        },
        computed: mapState({reportTmp: state => state.report.reportTmp,}),
        watch: {
            name() {
                this.error = null;
            },
            reportTmp(newValue) {
                if(newValue['last_inserted']) {
                    let id = newValue['last_inserted'];
                    this.$router.push('/report/' + id + '/general');
                }
            },
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfReportNameExists', {name: value, type: 'Asset', value: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            content: {
                required
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        methods: {
            updateContent: function(args) {
                this[args.field] = args.value;
                this.checkSaving();
            },
            checkSaving: function() {
                this.enableSaving = !!this.name;
            },

            save: async function() {
                if (this.enableSaving) {
                    let args = {
                        name: this.name,
                        slug: this.name,
                        description: this.description,
                        type: this.type,
                        content: this.content,
                        organizationId: this.organizationId,
                        teams: this.teamId ? [this.teamId] : []
                    };
                    this.$store.dispatch('createReport', args).catch(res => {
                        if (res.response.errors.errorCode === 1003) {
                            this.error = this.$t('errors.duplicateNameForReport');
                        }
                    });
                }
            },
        },
    }
</script>
