<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeletedInstance = null; toBeDeletedAsset = null;}"
                     @confirm="() => {showDeletePrompt = false; if(toBeDeletedInstance) {deleteInstance();}}"
      />
    </popup>
    <loading-panel :time-in-seconds="0" v-if="isLoading" />
    <div class="mb-3" v-else-if="!Object.keys(list).length && !list.length">
      {{ $t('nodata') }}
    </div>
    <div v-else class="mb-3 mt-2 export-profile-instance" :key="id + forceReRenderKey" v-for="(item, id) in $store.getters.getFilterInstancesByListName(listId)">
       <div class="row">
         <div class="header col-12 ml-0 pl-1 pt-1 mb-1">
           <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" @click="$router.push('/library/3d-data/instance/'+id+'/files')"
                class="files float-right mr-2 clickable">
             <icon type="angle-right" size="1" />
           </div>
           <div class="instance-delete-button float-right mr-2 border-radius clickable pr-1 pl-1" @click="deleteInstance(item.id)">
             <icon type="trash" size="1" />
           </div>
           <state-display :state="item.state" />
         </div>
         <div class="w-100 ml-2 pr-4">
           <div class="lighter darker p-1 mb-1" v-if="compareItemsOutOfSync(item.content, item.asset.content)"><icon type="exclamation-triangle" /> profile instance not identical to original profile</div>
           <!--<div v-if="timeDifferenceFromTwoISOs(item.updatedAt, rootNode.updatedAt).minutes < 0"><icon type="exclamation-triangle" /> out of sync</div>-->
            <div @click="editing = item.id; editName = item.displayName" class="float-right mr-3"><icon type="edit" size="0.9" /></div><p class="larger"><b>{{ item.displayName ? item.displayName : item.asset.displayName }}</b></p>
            <p v-if="item.name !== item.asset.name" class="lighter smaller">Original name: {{ item.asset.displayName }}</p>
            <div v-if="editing === item.id">
              <input type="text" class="form-text col-8" v-model="editName" />
               <div class="edit-trigger mr-2" @click="cancelEditing">
                   <icon :type="'times'"/>
               </div>
               <div class="edit-trigger" @click="updateContent">
                  <icon :type="'save'"/>
               </div>
            </div>
           <p class="lighter smaller">{{ $t('Last change') }}: {{ dateTimeFromISO(item.updatedAt) }}</p>

           {{ item.description }}
           <p class="smaller lighter mt-2">ID: {{ item.id }}</p>

           <info-panel
                preview-id="something"
                class="mt-3 mb-2 mr-2  smaller-title small-header new-style"
                info-panel-header="Details"
                :collapsible="true"
                :initially-collapsed="true">
                <div slot="info">
                  <filter-display v-if="item.content" :content="getParsed(item.content)" />
                  <div v-if="!isProcessing(listFiles, item)" class="border-radius mt-3">
                    <div class="settings-button d-inline-block" @click="updateInstance(item.id, item.asset.id)">{{ $t('update') }}</div>
                  </div>
                </div>
            </info-panel>

         </div>
         <div class="dark-section-right">
          <div class="text text-center">
            <div v-if="!isProcessing(listFiles, item)">
              <div v-if="file.key.includes('.glb') || file.key.includes('.fbx')" :key="file.key" v-for="file in listFiles[item.id]">
                {{ file.key }}
              </div>
              <div>
                <download-button
                    loading-panel-classes="black"
                    class="text-center w-100 not-absolute mb-1"
                    download-message=""
                    :div-instead-of-button="true"
                    :show-stats="false"
                    :object-id="item.id"
                ><icon type="download" size="1.2" />
                  {{ $t('download') }}</download-button>
              </div>
            </div>
            <div v-else>
              <icon type="clock" size="1.3" /><br />
              <p class="smaller mt-1">{{ $t(item.pipelineStatus) }}</p>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
import InfoPanel from "@/components/InfoPanel";
import DownloadButton from "@/components/forms/DownloadButton";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import LoadingPanel from "@/components/LoadingPanel";
import StateDisplay from "@/components/StateDisplay";
import FilterDisplay from "./FilterDisplay";
import _ from 'lodash';

export default {
  name: "ExportInstanceList",
  components: {
    Icon,
    InfoPanel,
    DownloadButton,
    Popup,
    DeletePrompt,
    LoadingPanel,
    StateDisplay,
    FilterDisplay
  },
  mixins: [DateMixin],
  props: {
    projectId: {type: String, required: true},
    listId: {type: String, required: true},
    rootNode: {type: Object, required: true}
  },
  data() {
    return {
      list: {},
      filter: {},
      toBeDeletedInstance: null,
      showDeletePrompt: false,
      isLoading: true,
      updatingInstance: '',
      editing: null,
      editName: '',
      enableSaving: true,
      listFiles: {},
      forceReRenderKey: 0
    }
  },
  watch: {
    projectId() {
      this.loadInstances();
    }
  },
  beforeMount() {
    this.loadInstances();
  },
  methods: {
    isProcessing(listFiles, item) {
      return !listFiles[item.id] || item.pipelineStatus === 'processing';
    },
    compareItemsOutOfSync(item1, item2) {
      const p1 = this.getParsed(item1);
      const p2 = this.getParsed(item2);
      const properties = ['filterStringArray', 'mergeStringArray', 'mode', 'pipeline'];
      for(let i = 0; i < properties.length; i++) {
        const prop = properties[i];
        if(p1[prop] && p2[prop]) {
          if(!_.isEqual(p1[prop], p2[prop])) {
            return true;
          }
        }
      }
    },
    getParsed(json) {
      try {
        const item = JSON.parse(json);
        return item;
      } catch {
        // do nothing
      }
      return {};
    },
    edit(id) {
      this.editing = id;
    },
    cancelEditing() {
      this.editing = null;
      this.editName = null
    },
    updateContent() {
      return this.$store.dispatch('clientUpdateProjectInstance', {
            id: this.projectId,
            cid: this.editing,
            name: this.editName,
            displayName: this.editName,
          }).then(() => {
            const index = this.list.findIndex(item => {return item.id === this.editing});
            this.list[index].name = this.editName;
            this.cancelEditing();
            this.$store.dispatch('createNotification', {'text': this.$t('events.instanceUpdated')});
          })
    },
    setVM(id) {
      this.vm = id;
    },
    /**
     * Update export profile by using the newest filter asset and files
     * */
    updateInstance(instanceId, assetId) {
      this.$emit('updateFilterInstance', instanceId, assetId);
    },
    /**
     * Load the part list of an instance
     * */
    async loadStorage(id) {
      await this.$store.dispatch('clientListInstanceParts', {id: id})
          .then(result => {
            this.listFiles[id] = result;
          })
    },
    loadInstances() {
      if(this.projectId) {
        let args = {
          id: this.projectId,
          listName: this.listId,
          include: ['asset', 'project'],
          addPipelineState: true,
        };
        /*if(this.filter) {
          if (this.filter.add) { args.add = this.filter.add}
          if (this.filter.remove) { args.remove = this.filter.remove }
        }*/
        this.$store.dispatch('loadFilterInstances', args)
            .then(async data => {
              this.list = data;
              this.isLoading = false;
              for(let i = 0; i < data.length; i++) {
                await this.loadStorage(data[i].id);
              }
              this.forceReRenderKey++;
            }).catch(() => {
              this.isLoading = false;
        });
      }
    },
    deleteInstance(cid) {
      if(this.toBeDeletedInstance) {
        this.$store.dispatch('deleteFilterInstances',
            {
              id: this.projectId,
              args: [this.toBeDeletedInstance]
            }
        ).then(() => {
          this.forceReRenderKey++;
          this.showDeletePrompt = false;
          this.toBeDeletedInstance = null;
          this.loadInstances();
        });
      } else {
        this.toBeDeletedInstance = cid;
        this.showDeletePrompt = true;
      }
    },
    /*filtering(val) {
      this.filter = val;
      this.loadInstances();
    }*/
  }
}
</script>

<style lang="scss">
  $right-section-width: 110px;
  .export-profile-instance {
    background: var(--vform-editor-ui-secondary-color);
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    padding: 0 15px;
    padding-right: $right-section-width;
    position:relative;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    .info {
      word-wrap: break-word;
    }
    .header {
      background-color: rgba(0,0,0,0.18);
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding-bottom: 2px;
    }
  }
  .dark-section-right {
    background: var(--vform-editor-ui-secondary-color);
    border-left: 1px solid rgba(255,255,255,0.1);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 100%;
    position:absolute;
    right: 0;
    top:0;
    width: $right-section-width;
    text-align: center;
    padding:8px;
    .text {
      top: 50%;
      left: 50%;
      position:absolute;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      width: 90%;
      text-align: center;
      font-size: 0.7rem;
    }
  }
  .instance-delete-button, .files {
    width: 13px;
    height: 18px;
    position:relative;
    .icon {
      transition: all 300ms ease;
      opacity: 0.5;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      &:hover {
        opacity: 1;
      }
    }
  }

  .export-instance-download {
    cursor: pointer;
    &:hover {
      .icon {
       margin-bottom: -5px;
      }
    }
  }
</style>