<template>
    <div/>
</template>

<script>
    import jsPDF from "jspdf";
    import domtoimage from 'dom-to-image-more'; //turns html-elements into images
    //canvg turns svg into canvases, required by domtoimage if you want to add svg as image

    export default {
        name: 'CanvasToPDFMixin',
        components: {
        },
        data() {
            return {
                pdfPrintingActive: false,
                defaultPDFCanvasWidth: 2480,
                defaultPDFCanvasHeight: 3508,
                pages: [],
                pdf: null,
                leftBorder: 20,
                rightBorder: 426,
                topBorder: 20,
            };
        },
        methods: {
            createBasicPDF() {
                this.pdf = null;
                this.pages = [];
                this.pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'px',
                    format: 'a4',
                    floatPrecision: "smart", // or "smart", default is 16
                });
            },
            async makePDFFromCanvases() {
                this.pdfPrintingActive = true;
                this.createBasicPDF();
                /*
                * create canvas
                * loop through canvases
                * if(next canvas) doesnt fit the page:
                * create canvas
                * loop through canvases
                * */
                this.createPage(true);
                this.pdf.setPage(1);
                this.addPageNumber(1);
                await this.addPageLogoAndTitle(document.getElementsByTagName('h1')[0].innerHTML);
                //pdfctx.scale(1.1,1.1);
                let canvases = document.getElementsByTagName('canvas');
                canvases = Array.prototype.slice.call(canvases);
                let pageIndex = 0;
                let scalingFactor = 1;
                let targetWidth = 200;
                for(let i = 0; i < canvases.length; i++) {
                    let original = canvases[i];
                    let canvasHeight = original.getAttribute('height');
                    let canvasWidth = original.getAttribute('width');
                    scalingFactor = (targetWidth / canvasWidth);
                    let page = this.pages[pageIndex];
                    let clone = this.cloneAndScaleCanvas(original, scalingFactor);
                    if(page) {
                        let nextYValue = parseInt(page.pdfctxY) + parseInt(canvasHeight)*scalingFactor + parseInt(page.gap) + 5; //200 is the safety span
                        //console.log('canvas-height: ' + canvasHeight);
                        //console.log('currentPosition: ' + parseInt(page.pdfctxY));
                        if(nextYValue > this.pdf.internal.pageSize.getHeight()) {
                            pageIndex++;
                            this.createPage();
                            //console.log('setting page to: ' + (pageIndex + 1));
                            this.pdf.setPage(pageIndex+1);
                            page = this.pages[pageIndex];
                            this.addPageNumber(pageIndex + 1);
                        }
                    }

                    //Chart heading start
                    let vueChartComponent = this.$refs[original.getAttribute('id')];
                    let chartName = 'chartName';
                    let description = null;
                    if(vueChartComponent) {
                        chartName = vueChartComponent[0].getChartName();
                        description = vueChartComponent[0].getChartDescription();
                    }
                    let barHeight = 24;
                    if(description) {
                        barHeight = 35;
                    }
                    this.pdf.setFillColor(218,218,218);
                    this.pdf.rect(page.pdfctxX, page.pdfctxY, 306, barHeight, 'F'); //fulllwidth: 406
                    this.pdf.setFillColor(0,0,0);
                    this.pdf.rect(page.pdfctxX, page.pdfctxY, 2, barHeight, 'F'); //fulllwidth: 406
                    this.pdf.setFontSize(10);
                    if(chartName) {
                        this.pdf.text(chartName.toUpperCase(), page.pdfctxX + 15, page.pdfctxY + 15);
                    }
                    if(description) {
                        this.pdf.setFontSize(6);
                        this.pdf.text(description.length > 80 ? this.splitStringInHalf(description) : description, page.pdfctxX + 15, page.pdfctxY + 20);
                    }
                    this.pages[pageIndex].pdfctxY = page.pdfctxY + barHeight + 10;
                    //Chart heading end

                    this.pdf.addImage(clone, 'PNG',  page.pdfctxX, page.pdfctxY, canvasWidth*scalingFactor, canvasHeight*scalingFactor);
                    if(vueChartComponent) {
                        //re-initialize chart because with all the scaling we fckd it up
                        vueChartComponent[0].initChart();
                    }

                    //legend printing
                    let legend = null;
                    if(original.nextSibling && (original.nextSibling.className === 'chart-legend')) {
                        legend = original.nextSibling;
                    }
                    if(legend) {
                        let $this = this;
                        let legendScalingFactor = (430 / legend.clientWidth);
                        legend.classList.add('print'); //this class is given in Chart and added printing-friendly styles
                        await domtoimage.toPng(legend,{bgcolor: '#fff'})
                            .then(function (dataUrl) {
                                var img = new Image();
                                img.src = dataUrl;
                                $this.pdf.addImage(img, 'PNG',
                                    page.pdfctxX + 200,
                                    page.pdfctxY,
                                    $this.convertPxToMm(legend.clientWidth * legendScalingFactor),
                                    $this.convertPxToMm(legend.clientHeight * legendScalingFactor),
                                );
                            })
                            .catch(function (error) {
                                console.error('oops, something went wrong!', error);
                            });
                        legend.classList.remove('print');
                    }

                    this.pages[pageIndex].pdfctxX = this.leftBorder;
                    this.pages[pageIndex].pdfctxY += parseInt(canvasHeight)*scalingFactor + parseInt(page.gap);
                }

                this.renderPages();
            },
            splitStringInHalf(s) {
                let middle = Math.floor(s.length / 2);
                let before = s.lastIndexOf(' ', middle);
                let after = s.indexOf(' ', middle + 1);

                if (middle - before < after - middle) {
                    middle = before;
                } else {
                    middle = after;
                }

                let s1 = s.substr(0, middle);
                let s2 = s.substr(middle + 1);
                if(s1 && s2) {
                    return [s1, s2];
                }
            },
            async addPageLogoAndTitle(title) {
                let logo = document.getElementById('main-logo');
                if(logo) {
                    this.pdf.setFillColor(218,218,218);
                    this.pdf.rect(this.leftBorder, 5, 406, 80, 'F');
                    //let logoCanvas = document.createElement('canvas');
                    //canvg(logoCanvas, logo.outerHTML);
                    let $this = this;
                    logo.classList.add('print');
                    let scalingFactor = (300 /  logo.clientWidth);
                    let logoWidthInmm = this.convertPxToMm(logo.clientWidth * scalingFactor);
                    let logoHeightInmm = this.convertPxToMm(logo.clientHeight * scalingFactor);
                    await domtoimage.toPng(logo,{bgcolor: 'transparent'})
                        .then(function (dataUrl) {
                            var img = new Image();
                            img.src = dataUrl;
                            $this.pdf.addImage(img, 'PNG',
                                $this.rightBorder - logoWidthInmm - 15, 6, logoWidthInmm, logoHeightInmm
                            );
                        })
                        .catch(function (error) {
                            console.error('oops, something went wrong!', error);
                        });
                    logo.classList.remove('print');
                    this.pdf.setFontSize(10);
                    if(title) {
                        this.pdf.text(title, $this.leftBorder + 10, 20);
                    }
                    this.pdf.setFontSize(6);
                    let today = new Date();
                    let date = today.getDate() + '.' + (today.getMonth()+1) + '.' + today.getFullYear();
                    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    let dateTime = date+' '+time;
                    this.pdf.text('Auszug vom: ' + dateTime, $this.leftBorder + 10, 28);
                    //this.pdf.addSvgAsImage(logo.outerHTML, 15, 15, logo.clientWidth, logo.clientHeight);
                }
            },
            addPageNumber(num) {
                this.pdf.setFontSize(6);
                if(num) {
                    this.pdf.text('Seite ' + num, 25, 610);
                }
            },
            cloneAndScaleCanvas(oldCanvas) {
                var newCanvas = document.createElement('canvas');
                var context = newCanvas.getContext('2d');

                let ctx = oldCanvas.getContext('2d');
                ctx.scale(4,4);

                newCanvas.width = oldCanvas.width;
                newCanvas.height = oldCanvas.height;
                context.fillStyle = "#fff";
                context.fillRect(0, 0, oldCanvas.width, oldCanvas.height);
                //apply the old canvas to the new one
                context.drawImage(oldCanvas, 0, 0);
                //context.scale(scalingFactor, scalingFactor);
                //return the new canvas
                //ctx.scale(-4,-4);
                return newCanvas;
            },
            createPage(first = false) {
                let page = {
                    pdfctxX: this.leftBorder,
                    pdfctxY: first ? 90 : 40,
                    gap: 60,
                };
                this.pages.push(page);
                if(!first) {
                    this.pdf.addPage();
                }
                return page;
            },
            renderPages() {
                // download the pdf
                //console.log(this.pdf.internal.pageSize.getHeight());
                //console.log(this.pdf.internal.pageSize.getWidth());
                this.pdf.save('filename.pdf');
                this.pdfPrintingActive = false;
            },
            convertPxToMm(px) {
                return px * 0.352;
            },
            convertMmToPx(mm) {
                return mm / 0.352;
            }
        },
    }
</script>
