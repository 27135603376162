<template>
    <div>
      <label>{{ $t('chooseType', {type: $t('type.MetaSet')}) }}*</label><br >
      <select @change="setMeta" class="custom-select darker standard-height" v-model="metaSetId">
        <option :key="index" v-for="(item, type, index) in metaSets" :value="item.id">{{ getRawName(item.name) ? getRawName(item.name) : item.name }}</option>
      </select>
      <div v-if="showEditForm">
        <label class="mt-1" v-if="canCreateNew">{{ $t('metaSetName')}}</label>
        <input v-if="canCreateNew" class="form-text" type="text" v-model="newMetaSetName" @input="delayTouch($v.newMetaSetName)">
        <div class="form-error" v-if="$v.newMetaSetName.$dirty && !$v.newMetaSetName.isUnique">{{ $t('errors.objectNameAlreadyExists') }}</div>
        <div class="form-error" v-if="$v.newMetaSetName.$dirty && !$v.newMetaSetName.alphaNumDot">{{ $t('errors.alphaNumDot') }}</div>

        <div class="w-100 mt-1" />
        <Button v-if="showSaveButton" @click="createNewMetaSet">{{ $t('save') }}</Button>
      </div>
    </div>
</template>

<script>
import Button from "@/components/forms/Button";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {maxLength, minLength} from "vuelidate/lib/validators";
import {alphaNumDot} from "@/customValidators";
export default {
name: "MetaSetSelector",
  components: {
    Button
  },
  mixins: [VuelidateMixin],
  props: {
    organizationId: {type: String, required: true},
    teams: {type: Array, default: () => {}},
    /**
     * Preselect a metaSet
     * @value {String} uuid of the metaset
     */
    preSelectedMetaSet: {type: String, default: ''},
    /**
     * Whether or not to show a input field to create a new metaSet
     * @value {Boolean} true or false
     */
    canCreateNew: {type: Boolean, default: false},
    /**
     * Whether or not to show a save button
     * @value {Boolean} true or false
     */
    showSaveButton: {type: Boolean, default: true},
    /**
     * Array of metaSet ids or names to exclude from this selector
     * @value Array
     */
    exclude: {type: Array, default: () => {return []}},
    /**
     * MetaSet realm for which to search and if created new, which to add to the name
     * @value String
     */
    metaSetPrefix: {type: String, default: 'asset.model'},
    permission: {type: String, default: ''},
    usevHUBMetaSets: {type: Boolean, default: false}
  },
  data() {
   return {
     metaSets: [],
     metaSetId: '',
     showEditForm: false,
     newMetaSetName: '',
     error: '',
   };
  },
  watch: {
    metaSetId() {
      this.setMeta();
    },
    preSelectedMetaSet() {
      this.metaSetId = this.preSelectedMetaSet;
    },
    metaSetPrefix() {
      this.loadMetaSets();
    }
  },
  validations: {
    newMetaSetName: {
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumDot,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '' || value === this.value) {
          return true;
        }
        let bool = true;
        let args = {value: this.metaSetPrefix + '.' + value};
        if(this.organizationId) {
          args.organizationId = this.organizationId;
        }
        await this.$store.dispatch('checkIfMetaSetNameExists', args).then(data => {
          bool = data.length === 0;
        });
        return bool;
      },
    },
  },
  beforeMount() {
    this.loadMetaSets();
  },
  methods: {
    loadMetaSets() {
      this.metaSetPrefix = this.metaSetPrefix.replace(' ', ''); // quickfix: no spaces allowed, causes problems
    this.$store.dispatch('clientLoadOrganizationMetaSets', {
      id: this.organizationId,
      q: this.metaSetPrefix,
    }).then(async data => {
      if(this.usevHUBMetaSets) {
        const data2 = await this.$store.dispatch('clientLoadOrganizationMetaSets', {
          id: this.$store.getters.getSuperOrg,
          q: this.metaSetPrefix,
        });
        //console.log(data2);
        data = [...data, ...data2]
      }
      if(this.exclude) {
        for(let i = 0; i < this.exclude.length; i++) {
          data = data.filter(item => {return item.id !== this.exclude[i] && item.name !== this.exclude[i]});
        }
      }
      if(this.permission) {
        for(let i = 0; i < data.length; i++) {
          data[i].hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {op: this.permission, organizationId: data[i].organizationId, teams: data[i].teams});
        }
      }
      data = data.filter(item => {return item.hasAccess});
      this.metaSets = data ? data : [];
      this.metaSetId = data && data[0] ? data[0].id : 999;
      if(this.preSelectedMetaSet) {this.metaSetId = this.preSelectedMetaSet;}
      if(this.canCreateNew) {
        this.metaSets.push({id: 999, name: 'CreateNew'});
      }
    });
    },
    async setMeta() {
      if(this.metaSetId === 999) {
        this.error = '';
        const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {op: this.permission, organizationId: this.organizationId, teams: this.teams});
        if(!hasAccess) {
          this.error = this.$t('CannotCreateType', {type: 'MetaSet'});
        } else {
          this.showEditForm = true;
        }
      }
      else {
        this.showEditForm = false;
      }
      let metaSetName = '';
      const res = this.metaSets.filter(item => { return item.id === this.metaSetId});
      if(res && res[0] && res[0].name) {
        metaSetName = res[0].name;
      }
      this.$emit('update', metaSetName, this.metaSetId);
    },
    getRawName(name) {
      if(name.includes('.')) {
        return name.split('.').splice(2).join('.');
      }
      return name;
    },
    async createNewMetaSet() {
      if(this.newMetaSetName) {
        let args = {
          name: this.metaSetPrefix + '.' + this.newMetaSetName,
          organizationId: this.organizationId,
          teams: this.teams
        };
        return await this.$store.dispatch('createMetaSet', args).then(metaSet => {
          this.metaSetId = metaSet.id;
          this.metaSets.push(metaSet);
          this.setMeta();

          // after we created a new MetaSet, let's remove the addNew option
          const index = this.metaSets.findIndex(item => {return item.id === 999});
          this.metaSets.splice(index, 1);
          this.showEditForm = false;
          return metaSet;
        });
      }
    },
  }
}
</script>