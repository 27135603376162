<template>
  <!--Single choice-->
  <div
      :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: config.fontColor ? config.fontColor : '',
          }"
      :class="['mb-3 position-relative w-100 vform-checkboxes', config.emphasized ? 'vform-emphasized' : '', config.backgroundColor ? 'with-bg' : 'without-bg']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :has-template="!!config.templateId"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <div :class="['mb-2', getImage(config, lang) ? 'vform-item-with-icon-container' : '']">
      <preview
          v-if="getImage(config, lang)"
          :asset-id="getImage(config, lang)"
          :may-load-now="isActive"
          :preview-id="getImage(config, lang)"
          :removable="false"
          class="mini mr-2 vform-preview-img"
          source="asset"
      />
      <div v-if="!config.noQuestion" :class="['text', !editable && (!isAnswerValid || isAnswerRequired) ? 'vform-error pl-2' : '']">{{
          getLabel(config, lang) ? getLabel(config, lang) : placeholders.SHORT
        }}{{ getRequired(config) ? "*" : "" }}
      </div>
    </div>
    <div 
      v-if="templateMode"
      :class="['clickable slide-control-element pt-1 pb-1']"
    >
      <div class="text display-checkboxes-wrapper pl-2">
        <checkbox-widget :initial-value="true"
                         :color="!config.emphasized && config.fontColor ? config.fontColor : fontColor ? fontColor : ''"
                         class="vform-checkbox-label-text"
                         :label-for="'chk' + index"
                         :label-text="'Lorem ipsum dolor sit amet'"
                         :disabled="true"
        />
      </div>
      <div class="text display-checkboxes-wrapper pl-2">
        <checkbox-widget :initial-value="false"
                         :color="!config.emphasized && config.fontColor ? config.fontColor : fontColor ? fontColor : ''"
                         class="vform-checkbox-label-text"
                         :label-for="'chk' + index"
                         :label-text="'Lorem ipsum dolor sit amet'"
                         :disabled="true"
        />
      </div>
    </div>
    <div
      v-else
      v-for="(option, index) in getOptions(config)"
      :key="index + forceReRenderKey + Math.random().toString(36).substring(7)"
      :class="['clickable slide-control-element pt-1 pb-1', getOptionImage(option, lang) ? 'vform-item-with-icon-container' : '']"
      @click="selectUnselectItem(index)">
      <preview
          v-if="isActive && getOptionImage(option, lang)"
          :asset-id="getOptionImage(option, lang)"
          :preview-id="getOptionImage(option, lang)"
          :removable="false"
          class="mini mr-2 vform-preview-img vform-icon-img"
          source="asset"
      />
      <div class="text display-checkboxes-wrapper pl-2">
        <checkbox-widget :initial-value="option.isSelected"
                         :color="!config.emphasized && config.fontColor ? config.fontColor : fontColor ? fontColor : ''"
                         class="vform-checkbox-label-text"
                         :label-for="'chk' + index"
                         :label-text="getText(option, lang) ? getText(option, lang) : placeholders.SHORT"
                         :disabled="disabled"
                         @change="(val) => {option.isSelected = val;}"
                         @clicked="$emit('storeData', config.uuid, retrieveData());"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import EditorOverlay from "./EditorOverlay";
import CheckboxWidget from "@/components/widgets/editors/Checkbox";

export default {
  name: "MultipleChoice",
  components: {
    Preview,
    EditorOverlay,
    CheckboxWidget
  },
  mixins: [ElementMixin],
  data() {
    return {
      selected: {},
      isUserChanged: false,
      isAnswerValid: true,
      isAnswerRequired: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        if (!this.isUserChanged) {
          this.setOptionPreSelected(this.config);
        }
      }
    }
  },
  mounted() {
    this.increaseCounter();
    const {uuid} = this.config;
    if(!this.$store.getters.isElementVisited(uuid)) {
      this.setOptionPreSelected(this.config)
    }
    this.$store.dispatch('setElementVisited', uuid)
  },
  methods: {
    selectUnselectItem(index) {
      this.isUserChanged = true;
      if (!this.disabled) {
        let options = this.getOptions(this.config);
        options[index].isSelected = !options[index].isSelected;
        this.forceReRenderKey++;
      }
    },
    validate() {
      let isValid = this.validateOptions();
      this.isAnswerValid = isValid;

      return isValid;
    },
    checkRequired() {
        let result = this.getAnyOptionSelected(this.config);
        this.isAnswerRequired = !result;

        return result;
    },
    fillData(data) {
      if(data) {
        this.setOptions(this.config, data);
      } else {
        this.setOptionPreSelected(this.config);
      }
    },
    retrieveData() {
      return this.getOptions(this.config);
    },
    getData() {
      if (!this.disabled) {
        return {
          data: {
            value: this.getOptionData(this.config, this.lang),
            question: this.getLabel(this.config, this.lang),
            elementId: this.config.uuid
          },
          type: 'checkboxes'
        };
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.vform-checkboxes.with-bg {
  padding: 11px 12px;
}
.display-checkboxes-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>

<style lang="scss">
.display-checkboxes-wrapper {
  .checkmark{
    background-color: transparent;
  }
}
.vform-checkbox-label-text {
  font-size: 1em !important;
}
</style>