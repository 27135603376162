<template>
  <div>
    Storage<br />
    <div class="settings-button d-inline-block" v-if="!storageUsage" @click="loadStorageUsage()">{{ $t('LoadStorageUsage') }} <loading-spinner class="ml-2" v-if="loadingStorageUsage" /></div>
    <div v-else>
      <icon type="hdd" /> {{ storageUsage }}
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import Icon from "@/components/Icon";
export default {
  name: "StorageStats",
  components: {
    LoadingSpinner,
    Icon
  },
  props: {
    organizationId: {type: String, required:true},
    autoLoad: {type: Boolean, default: false},
  },
  data() {
    return {
      storageUsage: null,
      loadingStorageUsage: false,
    };
  },
  beforeMount() {
    if(this.autoLoad){
      this.loadStorageUsage()
    }
  },
  methods: {
    /***
     * Loads the storage for a given organization
     * */
    loadStorageUsage() {
      this.loadingStorageUsage = true;
      this.$store.dispatch('clientListStorageUsage', {
        id: this.organizationId
      }).then(data => {
        if(data && data.body) {
          this.storageUsage = data.body.readableSize;
        }
        this.loadingStorageUsage = false;
      });
    },
  }
}
</script>

<style scoped>

</style>