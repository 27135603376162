<template>
<div></div>
</template>

<script>
import ColorContrastMixinJs from "./ColorContrastMixin.js.vue";
const themeNames = Object.freeze({
  DEFAULT: 'defaultTheme',
  DARK: 'darkTheme'
})
export default {
  name: "ThemeSetterMixin.js",
  mixins: [ColorContrastMixinJs],
  data() {
    return {
      themeId: null,
      customFont: null,
      customHighlightColor: null,
      fontColor: null,
      lightenDarkenFactor: null,
      themeName: 'defaultTheme',
      defaultTheme: {
        'main-nav-background-color': '#f3f3f3',
        'main-nav-color': '#343434',
        'main-nav-hover-color': '#000',
        'tab-nav-color': '#bbbbbb',
        'tab-nav-hover-color': '#ffffff',
        'tab-nav-background-color': '#fff',
        'tab-nav-background-hover-color': '#7e7d80',
        'top-nav-color': '#bbbbbb',
        'top-nav-background-color': '#fff',
        'main-nav-border-color': '#c5c5c5',
        'main-nav-font-size': '0.9rem',
        'main-nav-sub-nav-font-size': '1rem',
        'secondary-color': '#cbcbcb',
        'secondary-color-darker': '#9d9c9c',
        'accent-color': '#20325e',
        'accent-color-contrast': '#fff',
        'h1-font-color': '#000',
      },
      darkTheme: {
        'main-nav-background-color': '#1f1f1f',
        'main-nav-color': '#eaeaea',
        'main-nav-hover-color': '#000',
        'tab-nav-color': '#afafaf',
        'tab-nav-hover-color': '#ffffff',
        'tab-nav-background-color': '#333333',
        'top-nav-color': '#bbbbbb',
        'top-nav-background-color': '#1c1c1c',
        'main-nav-border-color': '#c5c5c5',
        'main-nav-font-size': '0.9rem',
        'main-nav-sub-nav-font-size': '1rem',
        'secondary-color': '#cbcbcb',
        'secondary-color-darker': '#9d9c9c',
        'accent-color': '#481d70',
        'accent-color-contrast': '#fff',
        'h1-font-color': '#000'
      },
      styleString: '',
      themeNames: themeNames
    }
  },
  beforeMount() {
    this.createThemeId();
  },
  methods: {
    createThemeId() {
      this.themeId = Math.random().toString(36).substring(2, 7);
    },
    setFont() {
      // todo
    },
    /**
     * @params {Object} - config
     *  - themeColor - the highlight color
     * */
    setThemeColors(config = {}) {
      const {themeColor, organization} = config;
      this.styleString = ':root {';

      //calculate emphasize text color in contrast with the background
      this.customHighlightColor = themeColor ? themeColor : null;
      if (organization && config.organization.ci) {
        const {fontName} = organization.ci;
        this.customFont = fontName;
      }
      this.applyVars(this[this.themeName]);
      this.applyvFormStyles();
      this.styleString += '}';
      this.createCSS(this.styleString);
    },
    applyVars(variables) {
      const keys = Object.keys(variables);
      const values = Object.values(variables);
      for(let i = 0; i < keys.length; i++) {
        this.styleString += `--cclight-${keys[i]}: ${values[i]}; `;
      }
      if(this.customFont) {
        document.documentElement.style.setProperty('--vform-editor-primary-font', this.customFont);
      }
    },
    /**
     * Sets the custom colors for the vform if available
     * */
    applyvFormStyles() {
      if(this.customHighlightColor) {
        this.fontColor = this.getContrast(this.customHighlightColor);
        this.lightenDarkenFactor = this.isBrightColor(this.customHighlightColor) ? 0.7 : 1.5;
        this.styleString += '--vform-editor-layout-accent-color: ' + this.customHighlightColor + ' !important; ';
        this.styleString += '--vform-editor-emphasized-font-color: ' + this.fontColor + ' !important; ';
        this.styleString += '--vform-highlight-hover-color: ' + this.lightenDarkenColor(this.customHighlightColor, this.lightenDarkenFactor) + ' !important; ';
      }
    },
    createCSS(stylesAsString) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.appendChild(document.createTextNode(stylesAsString));
      document.getElementsByTagName('head')[0].appendChild(style);
      this.styleString = "";
    },
    /**
     * Sets the logo for the application
     * @params {config} - Object, containing:
     *  - logo (the assetId)
     *  - logoName (the fileName of the logo)
     * **/
    setLogo(config) {
      const {logo, logoName} = config;
      // logo is an assetId
      if(logo) {
        this.$store.dispatch('setLogo', logo);
      }
      if(logoName) {
        this.$store.dispatch('setLogoFileName', logoName);
      }
    }
  }
}

</script>