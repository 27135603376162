<template>
    <div class="">
        <label class="vform-editor-label section">Height</label>
        <label class="section ml-4 vform-editor-body slider-label"
            >{{ this.height }}px</label
        >
        <slider :default-value="20" :initial-value="height"
              :interval="10" :max="100" :min="0"
              @change="(val) => { this.$set(this, 'height', val) }"></slider>
    </div>
</template>

<script>
import { vFormControls } from "@/enum";
import Slider from "@/components/widgets/editors/Slider";

export default {
    name: "vFormSEPARATOR",
    components: { Slider },
    props: {
        element: { type: Object, required: true },
    },
    data() {
        return {
            vFormControls: vFormControls,
            height: 10
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["SEPARATOR"];
        this.height = this.element.height ? this.element.height : this.height;
    },
    watch: {
        height() {
            this.$set(this.element, "height", this.height);
        },
    },
    methods: {
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
