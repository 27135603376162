<template>
  <div class="carousel-wrapper">
    <!-- Tooltip for long step name - positioned dynamically using javascript -->
    <div
        v-if="stepNameTooltipVisible && !editMode"
        :style="'left: ' + stepNameTooltipLeft"
        class="step-title-tooltip p-1 vform-editor-body"
    >
      {{ tooltipText }}
    </div>
    <div
        v-if="stepNameTooltipVisible && !editMode"
        :style="'left: ' + stepNameTooltipBubbleLeft"
        class="triangle-down bubble-step-name"
    />
    <!-- Tooltip for new step diamond - positioned dynamically using javascript -->
    <div
        v-if="newStepTooltipVisible"
        :style="'left: ' + newStepTooltipLeft"
        class="new-step-tooltip p-1 vform-editor-body"
    >
      <label>{{ $t("vform.newStep") }}</label>
    </div>
    <div
        v-if="newStepTooltipVisible"
        :style="'left: ' + newStepTooltipBubbleLeft"
        class="triangle-down bubble-new-step"
    />
    <div class="fancy-scrollbar carousel pl-4">
      <div class="separator-line"/>
      <div class="w-100 d-flex">
        <div
            v-for="(slide, index) in slides"
            :key="index + forceReRenderKey + 1000000"
            class="mt-1 position-relative">
            <slides-carousel-item
              :active-slide-uuid="selectedSlideUuid"
              :dragged-step="draggedStep"
              :edit-mode-step-uuid="editModeStep && editModeStep.uuid ? editModeStep.uuid : ''"
              :index="index"
              :loading-thumbs-in-progress="loadingThumbsInProgress"
              :show-transition-button="showTransitionButton"
              :slide="slide"
              :slide-u-r-l="thumbs[slide.id]"
              :step="getStepIfFirstSlide(slide.id)"
              :title-ellipsis="titleEllipsis"
              :tooltip-active="stepNameTooltipVisible === slide.id"
              @addStep="addStep"
              @cancelEditStep="cancelEditStep"
              @deleteStep="deleteStep"
              @editStep="editStep"
              @finishEditStep="finishEditStep"
              @hideNewStepTooltip="hideNewStepTooltip"
              @hideTooltip="hideTooltip"
              @selectSlide="selectSlide"
              @showNewStepTooltip="showNewStepTooltip"
              @showTooltip="showTooltip"
              @addTransition="addTransition"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VformCarouselDraggableDroppableMixin from "@/components/mixins/VformCarouselDraggableDroppableMixin.js.vue";
import SlidesCarouselItem from "../SlidesCarouselItem";
import listenerMixinJs from "../../mixins/listenerMixin.js";

export default {
  name: "SlidesCarousel",
  components: {
    SlidesCarouselItem
  },
  mixins: [VformCarouselDraggableDroppableMixin, listenerMixinJs],
  props: {
    loadingThumbsInProgress: {type: Boolean, default: false},
    showTransitionButton: {type: Boolean, default: false},
    selectedSlideUuid: {type: String, default: null},
    slides: {type: Array, required: true},
    thumbs: {type: Object, required: true},
    steps: {type: Array, required: true},
    state: {type: Object, required: true}
  },
  data() {
    return {
      editMode: false,
      editModeStep: null,
      editStepName: null,
      stepNameTooltipVisible: "",
      stepNameTooltipPosLeft: 0,
      newStepTooltipVisible: "",
      newStepTooltipPosLeft: 0,
      stepNameEllipsisLimit: 17,
      tooltipText: "",
      dragging: false,
      draggedStep: "",
      forceReRenderKey: 0
    };
  },
  computed: {
    stepNameTooltipLeft() {
      return this.stepNameTooltipPosLeft + "px";
    },
    stepNameTooltipBubbleLeft() {
      return this.stepNameTooltipPosLeft + 90 + "px";
    },
    newStepTooltipLeft() {
      return this.newStepTooltipPosLeft + "px";
    },
    newStepTooltipBubbleLeft() {
      return this.newStepTooltipPosLeft + 33 + "px";
    },
  },
  watch: {
    slides(val) {
      if (val.length) {
        this.enableDragDropCarousel();
      }
    },
    vformConfig(val) {
      if (val) {
        this.enableDragDropCarousel();
      }
    }
  },
  mounted() {
    if (this.steps) {
      this.enableDragDropCarousel();
    }
    this.addListener(document, "click", this.documentClick, 'carousel');

  },
  methods: {
    documentClick() {
      this.cancelEditStep();
    },
    titleEllipsis(text) {
      if (!text) {
        return "";
      }

      const upperCaseCount = text.substring(0, this.stepNameEllipsisLimit).replace(/[^A-Z]/g, "").length;
      if (upperCaseCount > 3 || text.length > this.stepNameEllipsisLimit) {
        let adjustedEllipsisLimit = 0;
        if (upperCaseCount > 8) {
          adjustedEllipsisLimit = 6;
        } else if (upperCaseCount > 4) {
          adjustedEllipsisLimit = 4;
        } else {
          adjustedEllipsisLimit = 2;
        }
        return text.substring(0, this.stepNameEllipsisLimit - adjustedEllipsisLimit) + "..";
      }

      return text;
    },
    addStep(slideUuid) {
      this.newStepTooltipVisible = false;
      this.$emit("addStep", slideUuid);
      this.editStep(this.getStepIfFirstSlide(slideUuid), true);
    },
    deleteStep(uuid) {
      this.$emit("deleteStep", uuid);
    },
    editStep(step) {
      this.editModeStep = step;
      this.editStepName = step.name;
      if (step.name === this.$t("vform.UnnamedStep")) {
        this.editStepName = "";
      }

      this.editMode = true;
      setTimeout(() => {
        this.dragging = false;
        this.draggedStep = "";
      }, 100);
    },
    cancelEditStep() {
      this.stepNameTooltipVisible = false;
      this.editMode = false;
      this.editModeStep = null;
      this.editStepName = null;
    },
    finishEditStep(stepName) {
      if (this.editModeStep) {
        this.stepNameTooltipVisible = false;
        this.editModeStep.name = stepName ? stepName : this.$t("vform.UnnamedStep");
        this.editMode = false;
        this.selectSlide(this.editModeStep.linkedSlide);
        this.editModeStep = null;
        this.editStepName = null;
        this.$emit('setDirtyFlag', true);
      }
    },
    getStepIfFirstSlide(uuid) {
      if (this.steps) {
        let step = this.steps.find((x) => x.linkedSlide === uuid);
        if (!step) {
          step = this.steps.find((x) => x.linkedSlides && x.linkedSlides[0] === uuid);
        }
        return step;
      } else {
        console.log('did not find anything...')
      }
    },
    isSlideSelected(slide) {
      return slide.id === this.selectedSlideUuid;
    },
    selectSlide(uuid) {
      this.$emit("selectSlide", uuid);
    },
    addTransition(slideId) {
      this.$emit('addTransition', slideId)
    },
    showTooltip(uuid, step) {
      if (step && this.titleEllipsis(step.name).endsWith("..")) {
        const rect = document.getElementById(uuid).getBoundingClientRect();
        this.stepNameTooltipPosLeft = rect.x - 25;
        this.stepNameTooltipVisible = uuid;
        this.tooltipText = step.name;
      }
    },
    hideTooltip() {
      this.stepNameTooltipVisible = "";
    },
    showNewStepTooltip(el, uuid) {
      const rect = el.getBoundingClientRect();
      this.newStepTooltipPosLeft = rect.x - 44;
      this.newStepTooltipVisible = uuid;
    },
    hideNewStepTooltip() {
      this.newStepTooltipVisible = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  overflow: visible;
}

.carousel {
  background: var(--vform-editor-layout-secondary-color);
  z-index: 100;
  overflow-x: scroll;
  position: relative;
  width: 100%;
  // please do not remove the following line, it is needed by the slide-show-display to calculate its height
  height: var(--vform-editor-carousel-height);
}

.fancy-scrollbar {
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }
}

.separator-line {
  background: var(--vform-editor-ui-quinary-color);
  height: 2px;
  position: relative;
  top: 30px;
  width: 100%;
}

.step-title-tooltip {
  position: absolute;
  right: -20px;
  bottom: 137px;
  background: var(--vform-editor-ui-quaternary-color);
  border: solid 1px var(--vform-editor-ui-quaternary-color);
  border-radius: 3px;
  color: var(--vform-editor-ui-primary-color);
  height: 50px;
  width: 190px;
  z-index: 103;
  opacity: 0.8;
  overflow: hidden;
}

.tooltip-active {
  background: var(--vform-editor-accent-color);
  border-top: solid 1px var(--vform-editor-accent-color);
  border-radius: 3px;
  height: 25px;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 14px solid var(--vform-editor-ui-quaternary-color);
  position: absolute;

  &.bubble-step-name {
    bottom: 123px;
    z-index: 104;
    opacity: 0.8;
  }

  &.bubble-new-step {
    bottom: 98px;
    z-index: 104;
    opacity: 0.8;
  }
}

.new-step-tooltip {
  position: absolute;
  bottom: 110px;
  background: var(--vform-editor-ui-quaternary-color);
  border: solid 1px var(--vform-editor-ui-quaternary-color);
  color: var(--vform-editor-ui-primary-color);
  border-radius: 3px;
  height: 30px;
  width: 80px;
  z-index: 104;
  text-align: center;
  opacity: 0.88;
}
</style>
