<template>
  <div>
    <div v-for="(item, index) in list" :key="index + forceReRenderKey">
      <div class="row mb-2">
        <div>
          <br/>
          <div class="ml-3 clickable" @click="removeItem(index)">
            <icon type="trash-alt"/>
          </div>
        </div>
        <div class="col-5">
          {{ $t('onvHUBInstance') }}
          <drop-down
              :exclude-values="getUsedItems(true)"
              :initial-active-value="list[index].source ? list[index].source : null"
              :labels="items1Labels && items1Labels.length ? items1Labels : items1"
              :values="items1"
              class="w-100"
              @select="(value) => {setValue(value, index, true)}"
          />
        </div>
        <div class="mt-4"><icon type="angle-right" /></div>
        <div v-if="!allowMultiItemsRight" class="col-5">
          {{ $t('inFile') }}
          <drop-down
              :exclude-values="getUsedItems(false)"
              :initial-active-value="list[index].target"
              :labels="items2Labels && items2Labels.length ? items2Labels : items2"
              :values="items2"
              class="w-100"
              @select="(value) => {setValue(value, index, false)}"
          />
        </div>
        <div v-else class="col-5">
          Multimode<br />
          <div class="d-flex mb-1" :key="subIndex + 1230493" v-for="(sub, subIndex) in item.targets">
            <tile-button
                class="w-50"
                ref="tiles"
                :initial-active-tiles="[sub.type]"
                :tiles="['Text', 'Field']"
                :values="['Text', 'Field']"
                @trigger="(val) => {selectType(index, subIndex, val)}"
            />
            <drop-down
                v-if="isField(sub)"
                :initial-active-value="sub.target"
                :labels="items2Labels && items2Labels.length ? items2Labels : items2"
                :values="items2"
                class="w-50"
                @select="(value) => {setSubValue(value, index, subIndex, false)}"
            />
            <div v-else class="d-inline-block float-left mr-1">
              <input type="text" class="form-text form-text-dark" v-model="sub.value" />
            </div>
            <div class="ml-2"><div v-if="subIndex !== 0" @click="moveUp(index, subIndex)"><icon type="angle-up" /></div></div>
          </div>
          <div class="mt-2" @click="addSubItem(index)"><icon type="square-plus"/></div>
          <h4 class="mt-1">Result:</h4>
          <span :key="subIndex + 1239990493" v-for="(sub, subIndex) in item.targets">
            <span v-if="isField(sub)">[{{ sub.value }}]</span> <span v-else>{{ sub.value }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="settings-button d-inline-block" @click="addEmptyItem">Add item
      <icon type="square-plus"/>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";
import DropDown from "./forms/DropDown";
import TileButton from "@/components/forms/TileButton.vue";

export default {
  name: "ItemMapper",
  components: {Icon, DropDown, TileButton},
  props: {
    items1Labels: { type: Array, default: () => {return []}},
    items1: { type: Array, required: true},
    items2Labels: { type: Array, default: () => {return []}},
    items2: { type: Array, required: true},
    doNotAllowDoubleSelect: {type: Boolean, default: true},
    allowMultiItemsRight: {type: Boolean, default: false}
  },
  data() {
    return {
      list: [],
      forceReRenderKey: 5000,
    };
  },
  beforeMount() {
    this.addEmptyItem()
  },
  methods: {
    isField(sub) {
      return sub.type === 'Field';
    },
    setValue(value, index, isSource = true) {
      if (isSource) {
        this.list[index].source = value;
      } else {
        this.list[index].target = value;
      }
      this.$emit('update', this.list);
      this.forceReRenderKey++;
    },
    getUsedItems(isSource = true) {
      if (isSource) {
        return this.items1.filter(item => {
          return this.isExistent(item, isSource);
        })
      } else {
        return this.items2.filter(item => {
          return this.isExistent(item, isSource);
        })
      }
    },
    isExistent(compare, isSource = true) {
      if (this.doNotAllowDoubleSelect) {
        return this.list.findIndex(item => {
          return (isSource && item.source === compare) || (!isSource && item.target === compare)
        }) !== -1;
      } else {
        return false;
      }
    },
    update() {
      this.$emit('update', this.list)
    },
    addEmptyItem() {
      if(this.allowMultiItemsRight) {
        this.list.push({source: null, targets: []});
      } else {
        this.list.push({source: null, target: null});
      }
      this.update();
    },
    moveUp(index, subIndex) {
      const before = this.list[index].targets[subIndex-1];
      this.$set(this.list[index].targets, subIndex-1, this.list[index].targets[subIndex]);
      this.$set(this.list[index].targets, subIndex, before);
    },
    setSubValue(value, index, subIndex) {
      this.list[index].targets[subIndex].value = value;
    },
    selectType(index, subIndex, res) {
      const {value} = res;
      this.list[index].targets[subIndex].type = value;
      this.setSubValue(null, index, subIndex);
    },
    addSubItem(index) {
      this.list[index].targets.push({value: null, type: 'Field'});
    },
    removeItem(index) {
      this.list.splice(index, 1);
      this.update();
    }
  }
}
</script>