<template>
  <div class="">
    <template v-if="editing">
      <table class="table-fixed mat-table">
        <tr>
          <th class=""><div class="vform-label white">{{ $t('MATERIAL') }}</div></th>
          <th class="clr"><div class="vform-label white">{{ $t('COLOR') }}</div></th>
          <th><div class="vform-label white mb-0 pb-0">{{ $t('TILING') }}</div></th>
          <th class="del"><div class="vform-label white mb-0 pb-0">{{ $t('DEL') }}</div></th>
        </tr>
        <tr v-for="(item, index) in rows" :key="index" class="align-items-center">
          <td>
            <loading-spinner v-if="loading" />
            <select v-else v-model="item.material" class="custom-select form-text-dark material-select darker">
              <option
                  v-for="(matItem, matIndex) in sortedItems"
                  :key="matIndex"
                  :value="matItem.id"
                  class="form-text mb-1"
              >
                {{ $t(matItem.displayName ? matItem.displayName : matItem.name) }}
              </option>
            </select>
          </td>
          <td class="clr">
            <div class="d-flex align-items-center justify-content-start">
              <div
                  :class="[
                            colorPickrId === index ? 'selected' : '',
                            'colormat-color-preview mr-2 clickable align-items-center',
                            colorPickrId === index ? 'hover' : '',
                        ]"
                  :style="{
                            position: 'relative !important',
                            backgroundColor: item.color ? item.color : 'white',
                            border: '1px solid ' + (item.color ? item.color : '#fff')
                        }"
                  @click.self="
                            () => {
                                colorPickrId = index;
                            }
                        "
              >
                <color-picker
                    v-if="colorPickrId === index"
                    ref="colorpickr"
                    slot="popupMainContent"
                    :popup-style="true"
                    :value="item.color ? item.color : '#fff'"
                    @close="
                                () => {
                                    colorPickrId = null;
                                }
                            "
                    @save="
                                (color) => {
                                    colorPickrId = null;
                                    return updateColor(color, index);
                                }
                            "
                    :ignore-custom-positioning="true"
                    :is-position-absolute="true"
                />
                <!--<div class="mini-text" v-if="colorPickrId === index">
                  {{ item.color ? item.color : '#fff' }}
                </div>-->
              </div>
              <div v-if="item.color" class="remove-color-button clickable pl-1 pr-1 d-inline-block"
                   @click="item.color = null">
                <icon size="0.9" type="trash"/>
              </div>
            </div>
          </td>
          <td class="ml-3">
            <vector-widget
                :editing="true"
                :hide-save-button="true"
                :value="item.tiling"
                class="mat-color-vector-widget p-1"
                type="v2"
                @save="(value) => {updateTiling(value, index)}"
                @update="(value) => {updateTiling(value, index)}"
            />
          </td>
          <td><div class="delete-button pl-1 pr-1 clickable" @click="deleteRow(index)">
            <icon size="0.8" type="trash"/>
          </div></td>
        </tr>

      </table>

      <div class="w-100"/>
      <div class="settings-button d-inline-block mr-2 mt-1" @click="addRow"><icon type="plus" /></div>
      <div v-if="showSaveButton" class="settings-button d-inline-block" @click="save">{{ $t("save") }}</div>
      <div>{{ error }}</div>
    </template>
    <template v-else>
      <div v-for="(row, index) in rows" :key="index">
        <div class="d-flex">
          <div class="mat-display">
            <div class="label">{{ $t('MATERIAL') }}</div>
            {{ getMaterial(row.material) }}
          </div>
          <div class="ml-3">
            <div class="label">{{ $t('COLOR') }}</div>
            <div
                :class="[
                            'colormat-color-preview mr-2 clickable align-items-center',
                        ]"
                :style="{
                            position: 'relative !important',
                            backgroundColor: row.color ? row.color : 'transparent',
                            border: '1px solid ' + (row.color ? row.color : '#fff')
                        }"
            ></div>
            <br/>
            {{ row.color }}
            <div v-if="!row.color">'N / A'</div>
          </div>
          <div class="ml-3">
            <div class="label">{{ $t('TILING') }}</div>
            {{ row.tiling }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ColorPicker from "@/components/widgets/editors/ColorPicker";
import VectorWidget from "./VectorWidget";
import Icon from "@/components/Icon";
import LoadingSpinner from "../../LoadingSpinner";
import {mapGetters} from "vuex";

export default {
  name: "MatColorAdder",
  components: {
    ColorPicker,
    VectorWidget,
    Icon,
    LoadingSpinner
  },
  props: {
    value: {type: String, default: ""},
    editing: {type: Boolean, default: true},
    organizationIds: {type: Array, default: null},
    showSaveButton: {type: Boolean, default: true},
    defaultTiling: {type: Array, default: () => {return [1,1]}}
  },
  data() {
    return {
      rows: [],
      error: "",
      materialListName: "GlobalMaterialList",
      materials: [],
      editColor: false,
      colorPickrId: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getMaterialsByListName']),
    sortedItems() {
      const data = this.getMaterialsByListName(this.materialListName);
      const keys = Object.keys(data);
      if(!keys.length) {
        return [];
      }
      let f = keys.map(key => {
        return data[key]
      })
      if(f.length) {
        f = f.sort((a,b) => {
          if(a.displayName < b.displayName) {
            return -1
          } else {
            return 1;
          }
        })
      }
      return f;
    },
  },
  watch: {
    value() {
      this.init();
    }
  },
  beforeMount() {
    this.init();
    this.loadMaterials();
  },
  methods: {
    init() {
      if (this.value && this.value.length) {
        try {
          this.rows = JSON.parse(this.value);
        } catch {
          console.log('could not parse material vals')
        }
      } else {
        this.rows = [];
      }
    },
    getMaterial(uuid) {
      if (!uuid) {
        return 'N / A'
      }
      const matObject = this.materials.find((x) => x.id === uuid);
      if (matObject) {
        return matObject.displayName;
      } else {
        return uuid;
      }
    },
    async loadMaterials() {
      this.loading = true;
      let args = {
        listName: this.materialListName,
      };
      if (this.organizationIds) {
        args.add = {
          filter: {
            matcoloradder: this.organizationIds.length === 1 ? 'organizationId eq ' + this.organizationIds[0] : "organizationId in '" + this.organizationIds.join("' '") + "'"
          }
        };
      }
      await this.$store.dispatch("loadMaterials", args).then((data) => {
        this.materials.push({id: null, displayName: 'N/A'});

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.materials.push({id: element.id, displayName: element.name});
        }
        this.loading = false;
      });
    },
    updateTiling(size, index) {
      this.rows[index].tiling = size;
    },
    updateColor(color, index) {
      this.rows[index].color = color;
    },
    deleteRow(index) {
      this.rows.splice(index, 1);
    },
    addRow() {
      if (!this.rows) {
        this.rows = [];
      }
      this.rows.push({material: "", color: "", tiling: this.defaultTiling});
    },
    getValues() {
      console.log('gathering values...')
      let validRows = [];
      this.rows.forEach(element => {
        if (element.material || element.color) {
          validRows.push(element);
        }
      });
      console.log('validrows')
      console.log(validRows);
      return validRows;
    },
    save() {
      let validRows = this.getValues();
      this.$emit("save", JSON.stringify(validRows));
    },
  },
};
</script>
<style lang="scss" scoped>
.mat-table {
  td {
    padding-top: 8px;
    padding-bottom: 0;
  }
}
.mat-color-vector-widget {
  width: 120px;
  margin-top: -15px;

  .vector-container {
    .form-group {
      width: 44%;
    }
  }

  .fieldset {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.colormat-color-preview {
  width: 15px;
  height: 15px;
  display: inline-block;
  .color-pickr {
    top: 50%;
    transform: translateY(-50%);
  }
}

.mat-color-row {
  width: 100%;
  background-color: $panel-title-background-color;
  position: relative;
}

.material-select {
  height: 40px;
  margin: auto 0;
  width: 100%;
  font-size: 0.8rem;
}

.label {
  font-family: 'Inter medium', sans-serif;
  margin-top: 15px;
}
.clr, .del {
  width: 50px;
  min-width: auto;
}
.remove-color-button {
  border-radius: 3px;
}

.mat-display {
  min-width: 200px;
}
.mini-text {
  font-size: 0.8rem;
}
</style>
