<template>
    <main-table-layout>
            <div class="mt-5" slot="mainContent">
                <h2>vForms</h2>

            </div>
    </main-table-layout>
</template>

<script>
    import MainTableLayout from "../layouts/FullScreenLayout";
    export default {
        name: "Help",
        components: {
            MainTableLayout
        },
        data() {
            return {
                forceReRenderKey: 0,
            };
        },
        methods: {

        }
    }
</script>