<template>
  <div>
    <h1>
      <icon class="mr-2" size="0.9" type="app"/>
      {{ $t('createNewApp') }}
    </h1>
    <organization-switcher
        :org-selection-disabled="true"
        :pre-set-organization-id="organizationId"
        class="mb-2"
        @setOrganization="id => {localOrganizationId = id}"
        @setTeam="id => {teamId = id}"
        @unsetTeam="teamId = ''"/>
    <input
        :id="'name'"
        v-model="name"
        :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
        :placeholder="$t('enternamehere')"
        type="text"
        @input="delayTouch($v.name)"
    >

    <div v-if="$v.name.$dirty && !$v.name.required && $v.name.$error" class="form-error">{{
        $t('errors.required')
      }}
    </div>
    <div v-if="$v.name.$dirty && !$v.name.alphaNumSpace" class="form-error">{{ $t('errors.alphaNumSpaceOnly') }}</div>

    <div v-if="$v.name.$dirty && !$v.name.maxLength" class="form-error">{{
        $t('errors.atMostCharacters', {num: 128})
      }}
    </div>

    <div v-if="$v.name.$dirty && !$v.name.minLength" class="form-error">{{
        $t('errors.atLeastCharacters', {num: 3})
      }}
    </div>
    <div v-if="$v.name.$dirty && !$v.name.isUnique" class="form-error">{{ $t('errors.appNameAlreadyExists') }}</div>

    <div class="inline-edit-label">
      <b>{{ $t('description') }}</b>
    </div>
    <div>
      <textarea
          :id="'description'"
          v-model="description"
          :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
          :placeholder="$t('enterdeschere')"
          @input="delayTouch($v.description)"
      />
      <div v-if=" $v.description.$dirty && !$v.description.maxLength" class="form-error">
        {{ $t('errors.atMostCharacters', {num: 128}) }}
      </div>
    </div>
    <universal-selector
        :limit-by-organization-id="organizationId"
        @addItem="addProject"
        list-name="contentFilter"
        store-name="Project"
        filter-attribute="name"
        tag-icon="home"
    />
    <div class="mt-1" v-if="selectedProject">
      <span class="lighter">Selected:</span> {{ selectedProject.name }}
    </div>
    <div @click="save" class="settings-button d-inline-block mt-1 dark" v-if="enableSaving && selectedProject">{{ $t('save') }}</div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '@/customValidators';
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import UniversalSelector from "../organizations/UniversalSelector";
export default {
  name: 'AppAdd',
  components: {
    OrganizationSwitcher,
    Icon,
    UniversalSelector
  },
  mixins: [VuelidateMixin],
  props: {
    organizationId: {type: String, default: ""}
  },
  data() {
    return {
      nameEdit: true,
      descEdit: true,
      id: '',
      enableSaving: false,
      name: '',
      description: '',
      selectedProject: null,
      type: 'app',
      localOrganizationId: '',
      teamId: '',
      webappId: null,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfAppNameExists', {
          name: value,
          type: 'Project',
          value: value,
          organizationId: this.localOrganizationId,
        }).then(data => {
          bool = data.length === 0;
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    }
  },
  watch: {
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
  },
  methods: {
    addProject(project) {
      this.selectedProject = project;
    },
    updateType(args) {
      this.type = args.value;
    },
    save: async function () {
      if (this.enableSaving) {
        let args = {
          name: this.name,
          slug: this.name,
          description: this.description,
          type: this.type,
          //externalId: this.projectId
        };
        args.organizationId = this.localOrganizationId;
        if (this.teamId) {
          args.teams = [this.teamId];
        }
        if(this.selectedProject) {
          args.baseProjectId = this.selectedProject.id;
        }
        await this.$store.dispatch('createApp', args)
            .then(async app => {
              this.$emit('saved', app);
            });
      }
    },
  },
}
</script>

<style lang="scss">
.webapp-highlight {
  background-color: $highlight !important;
}
</style>