<template>
<popup @close="abort" v-if="$store.getters.getMfaQrCode || $store.getters.getMfaCodeEnter">
  <div slot="popupMainContent">
    <div v-if="$store.getters.getMfaQrCode">
      <div class="w-100 mb-2">
        Please scan the qr code with your auth app
      </div>
      <img :src="$store.getters.getMfaQrCode" /><br />
      <div class="settings-button d-inline-block mt-2" @click="goNext">Next</div>
    </div>
    <div v-else-if="$store.getters.getMfaCodeEnter">
     <div class="w-100">Please enter the code from your authenticator app</div>
      <input class="form-text form-text-dark mfa-input" v-model="mfaCode" />
      <div class="form-error mt-2" v-if="showError">{{ $t('errors.mfaCodeWrong') }}</div><br />
      <div class="settings-button d-inline-block mt-2" @click="login">Send</div>
    </div>
  </div>
</popup>
</template>

<script>
import Popup from "@/components/Popup.vue";
export default {
  name: "MFADisplay",
  props: {
    showError: {type: Boolean, default: false}
  },
  components: {Popup},
  data() {
    return {
      mfaCode: ''
    }
  },
  methods: {
    goNext() {
      this.$store.commit('setMfaQrCode', null);
      this.$store.commit('setMfaCodeEnter', true);
    },
    login() {
      // todo: set mfaCode and hit login
      this.$emit('login', this.mfaCode);
    },
    abort() {
      this.$store.commit('setMfaQrCode', null);
      this.$store.commit('setMfaCodeEnter', false);
      this.$emit('abort');
    }
  }
}
</script>

<style scoped lang="scss">
.mfa-input {
  width: 200px;
}
</style>