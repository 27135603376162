<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      :back-button-tab-selector="$route.name !== 'user_me' ? 'userbackbutton' : ''"
      :sub-menu-selector="$route.name !== 'user_me' ? 'adminpanel' : ''"
      :tab-selector="$route.name !== 'user_me' ? 'usertab' : ''"
  >

    <div slot="mainContent">
      <user-edit-form
          v-if="currentUserOrg"
          :user-id="$route.params.id"
          :has-super-perms="hasSuperPerms"
          @accessDenied="accessDenied"
          @checkPermission="checkPermission"
      >
        <div class="bg-dark border-radius p-2 user-edit-meta mb-4">
          <h3>{{ $t('Meta') }}</h3>
          <div class="vform-label">{{ $t('Created') }}</div>
          <div class="value">{{ $store.getters.getUserCreationDate($route.params.id) }}</div>
          <div class="vform-label">{{ $t('Last change') }}</div>
          <div class="value mb-2">{{ $store.getters.getUserChangeDate($route.params.id) }}</div>
        </div>
      </user-edit-form>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import {mapState} from 'vuex';
import OneColumnSidebarLeft from "../layouts/FullWidthSidebarLeft";
import UserEditForm from "@/components/forms/UserEditForm";
export default {
  name: 'UserEdit',
  components: {
    OneColumnSidebarLeft,
    UserEditForm,
  },
  computed: mapState({
    currentUserOrg: state => state.users.currentUserOrg,
  }),
  data() {
    return {
      hasSuperPerms: true
    };
  },
  methods: {
    accessDenied() {
      this.$router.push('/access-denied');
    },
    /**
     * Checks whether the user has access to the user edit page or not
     * current rule:
     *  - user is super admin
     *  - user is sfx member
     *  - the user edit page is from the user currently logged in
     *  - the user has writeOrganization permission on the home organization of the to be edited user
     * */
    async checkPermission(homeOrganizationId) {
      if (this.$store.getters.isSuperAdmin) {
        return true;
      }
      const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {
        op: 'writeOrganization',
        opOnly: true,
        orgPermissionOnly: true,
        organizationId: homeOrganizationId
      });
      if (!this.$store.getters.isSFXMember && this.$store.getters.getCurrentUserId !== this.$route.params.id) {
        if(!hasAccess || !homeOrganizationId) {
          this.accessDenied();
        }
      }
      if(this.$store.getters.getCurrentUserHomeOrg !== homeOrganizationId) {
        this.hasSuperPerms = false;
      }
      if(this.$store.getters.getCurrentUserId === this.$route.params.id) {
        if(!hasAccess) {
          this.hasSuperPerms = false;
        }
      }
    },
  }
}
</script>

<style lang="scss">
.user-edit-meta {
  max-width: 400px;

  .vform-label {
    font-size: 0.8rem;
    font-weight: bold;
    color: #91929d;
  }

  .value {
    color: #fff;
    margin-bottom: 1rem;
  }
}
</style>