<template>
<div>
    <div>
    <div class="mt-2">
      <div v-if="error" class="form-error p-1">
        {{ error }}
      </div>
      <universal-selector
          :limit-by-organization-id="$store.getters.getProjectOrganization(projectId)"
          :select-placeholder="$t('vform.selectTragetWebAppPlaceholder')"
          :show-organization="true"
          :after-query-filter="filterByCCLightOnly"
          class="mt-2"
          filter-attribute="name"
          include="instances"
          list-name="appList"
          store-name="App"
          tag-icon="folder"
          @addItem="showWebAppPublishTarget"
      />
      <tag v-if="publishTargetWebApp && publishTargetWebApp.name"
           :is-removable="false"
           :name="publishTargetWebApp.name"
           :tag="publishTargetWebApp.name"
           class="mt-1"
           icon-left="folder"
      >
      </tag>
    </div>
      <div  v-if="publishTargetWebApp.id" class="form-error p-2">
        {{ $t('publicWarning') }}
      </div>
    <div
        v-if="publishTargetWebApp.id"
        class="pl-3 pr-3 mb-2 settings-button clickable d-inline-block"
        @click="publishvFormLocal(publishTargetWebApp.id);"
    >
      {{ $t("vform.publishButton") }}
    </div>
      <div
          v-if="publishTargetWebApp.id"
          class="pl-3 pr-3 mb-2 settings-button clickable d-inline-block ml-1"
          @click="publishTargetWebApp = {}"
      >
        {{ $t("abort") }}
      </div>
    <div v-if="message" class="local-toaster mt-2 border-radius">
      {{ message }}
    </div>
    <div class="published-list" v-if="(showWebAppsList || publishedToWebApps.length > 0)">
      <p class="mt-3 mb-2 lighter uppercase">{{ $t("vform.listOfWebAppsWhereVformIsPublished") }}</p>
      <div :class="[index %2 === 0 ? 'even' : 'odd', 'item']" v-for="(webAppId, index) in publishedToWebApps" :key="webAppId" :text="webAppId">
        <div class="d-flex justify-content-between">
          <a :href="`${$store.getters.getAppHost}/${$store.getters.getAppSlug(webAppId)}/#/projects/${projectId}/vform-static?noNav=true`"
             class="app-link"
             target="_blank">
            {{ $store.getters.getAppName(webAppId) }} <icon class="lighter" size="0.8" type="external-link-alt" />
          </a>
          <div class="">
            <div
                class="pl-3 pr-3 ml-2 settings-button clickable float-right"
                @click="unpublishvform(webAppId, projectId);"
            >
              {{ $t("vform.unpublish") }}
            </div>
            <div
                class="pl-3 pr-3 ml-2 settings-button clickable float-right"
                @click="publishvFormLocal(webAppId);"
            >
              {{ $t("vform.republish") }}
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 mt-2 settings-button clickable d-inline-block"
           @click="getWebAppsWhereFormIsPublished(projectId);"
      >
        {{ $t("Refresh List") }} <icon type="redo" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UniversalSelector from "../organizations/UniversalSelector.vue";
import Tag from "../Tag";
import Icon from "../Icon.vue";
import {filterByCCLightOnly} from "@/utils";
import vFormPublishingMixin from "./vFormPublishingMixin.js.vue"
export default {
  name: "VFormPublisher",
  components: {UniversalSelector, Tag, Icon},
  props: {
    projectId: {type: String, required: true},
  },
  mixins: [vFormPublishingMixin],
  data() {
    return {
      publishTargetWebApp: {},
      publishedToWebApps: [],
      showWebAppsList: true,
      showPublishMessage: false,
      error: "",
      message: "",
      filterByCCLightOnly: filterByCCLightOnly
    }
  },
  mounted() {
    this.getWebAppsWhereFormIsPublished(this.projectId);
  },
  methods: {
    publishvFormLocal(webAppId) {
      this.showWebAppsList = true;
      if(webAppId) {
        this.publishvform(webAppId, this.projectId);
      }
    },
    showWebAppPublishTarget(webApp) {
      this.publishTargetWebApp = webApp;
    },
  }
}
</script>

<style lang="scss" scoped>
.local-toaster {
  width: 100%;
  background: var(--vform-editor-ui-secondary-color);
  padding: 10px;
}
.published-list {
  border-top: 1px solid rgba(255,255,255,0.2);
  margin-top: 15px;
  .even {
    background-color: rgba(0,0,0,0.3);
  }
  .odd {
    background-color: rgba(0,0,0,0.1);
  }
  .item {
    padding: 5px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .app-link {
    margin-top: 0.1rem;
    max-width: 40%;
  }
}
</style>