<template>
  <div>
    <div :key="index" v-for="(value, item, index) in config[type]">
      <div class="form-group">
        <input class="checkbox float-left mr-2" ref="checkbox" type="checkbox"
               v-model="config[type][item]">
        <div class="ml-4 inline-edit-label" for="checkbox">{{ $t(item) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {contentTypes} from "@/enum";
export default {
  name: "ChildResources",
  props: {
    targetId: {type: String, required: true},
    storeName: {type: String, required: true},
  },
  data() {
    return {
      types: contentTypes,
      type: '',
      config: {
        'Project': {
          'Instances': true,
          'MetaSets': true,
          'Assets': true,
          'ExportFilters': true, // per def Instanzen
        },
        'Asset': {

        },
      }
    };
  },
  beforeMount() {
    //this.type = contentTypes[this.storeName.toLowerCase()];
    //this[`load${this.type}Resources`]();
  },
  loadProjectResources() {
    this.$store.dispatch('clientLoadProjectInstances')
  },
  loadAssetResources() {

  }
}
</script>

<style scoped>
  .checkbox {

  }
</style>