// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

const state = {
    notification: '',
}
const getters = {
    getNotifications: function() {
        if(state.notification === '') {
            return false;
        }
        else {
            return state.notification;
        }
    }
}

const mutations = {
    updateNotification(state, data) {
       state.notification = data.text;
    },
}

const actions = {
    //expects args in the form {text: "notification text"}
    createNotification (context, args) {
        context.commit('updateNotification', args);
    },
    cancelNotification(context) {
        context.commit('updateNotification', {'text': ''});
    },
}
export default {
    state, getters, mutations, actions
}
