<template>
  <div></div>
</template>

<script>
let playMode = false;

function dragMoveListener(event) {
  if (!playMode) {
    let target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
    target = null;
  }
}

/*
    if you use this mixin, the following things are needed:
    a div:
    <div :id="uuId + 'dragHandle'">dragHandle</div>

    afterDraggedMethod: function() {
        do the things after finished dragging here...
    }

    Further Documentation:
    https://interactjs.io/
    * */
import interact from "interactjs";

export default {
  name: "VformInfoDotDraggableDroppableMixin",
  props: {},
  data() {
    return {
      draggableInfoDot: ".hotspot-dot-container",
    };
  },
  methods: {
    enableDragDropInfoDots() {
      let $this = this;
      interact(this.draggableInfoDot).unset();
      interact(this.draggableInfoDot).draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: ".hotspot-container",
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start(event) {
            let target = event.target;
            target.classList.add("info-dot-dragging");
            $this.state.dragging = true;
          },
          end(event) {
            $this.state.dragging = false;
            let target = event.target;
            target.classList.remove("info-dot-dragging");
            const element = document.getElementById('hotspot-container');
            const {width, height} = element.getBoundingClientRect()
            const scale = {x: width / element.offsetWidth, y: height / element.offsetHeight}
            const parentContainerRect = document.getElementById("hotspot-container").getBoundingClientRect();
            let newOffsetLeft = Number(target.offsetLeft) * scale.x + Number(target.getAttribute("data-x") * scale.x);
            if (newOffsetLeft < 0) {
              newOffsetLeft = 0;
            }

            let newOffsetTop = Number(target.offsetTop) * scale.y + Number(target.getAttribute("data-y")) * scale.y;
            if (newOffsetTop < 0) {
              newOffsetTop = 0;
            }

            let percentageOffsetLeft = parseFloat((newOffsetLeft * 100 / parentContainerRect.width).toFixed(2))
            let percentageOffsetTop = parseFloat((newOffsetTop * 100 / parentContainerRect.height).toFixed(2))
            target.style.transform = "";
            target.setAttribute("data-x", 0);
            target.setAttribute("data-y", 0);

            let slide = $this.config.slidesMeta.find((x) => x.uuid === target.getAttribute("slideUuid"));
            if (slide) {
              if (target.getAttribute("hotspotIndex") && slide.hotspots) {
                let hotspot = slide.hotspots[target.getAttribute("hotspotIndex")];
                if (hotspot) {
                  $this.$set(hotspot, "x", percentageOffsetLeft);
                  $this.$set(hotspot, "y", percentageOffsetTop);
                }
              }

              if (target.getAttribute("learningDotIndex") && slide.learningDots) {
                let learningDot = slide.learningDots[target.getAttribute("learningDotIndex")];
                if (learningDot) {
                  $this.$set(learningDot, "x", percentageOffsetLeft);
                  $this.$set(learningDot, "y", percentageOffsetTop);
                }
              }
            }
          },
        },
      })
    },
  },
};
</script>
<style lang="scss">
.element-dragging {
  border: 1px solid var(--vform-editor-gizmos-primary-color);
  background-color: var(--vform-editor-gizmos-primary-color-brighter);
  z-index: 10;
}

.panel-dragging {
  opacity: 0.8;
}

.info-dot-dragging {
  opacity: 0.5;
}
</style>
