<template>
<div>
  {{ $t('AUTHENTICATION') }}
  <drop-down
      :exclude-values="$store.getters.isSuperAdmin ? ['pipeline', 'b2c', 'none'] : ['pipeline', 'b2c', 'none']"
      :initial-active-value="authMethod"
      :labels="['Legacy', 'Normal', 'MFA']"
      :values="Object.values(AuthMethods)"
      class="w-100"
      @select="(value) => {setValue(value)}"
  />
  <div v-if="showReRegisterMfa" class="mt-2 settings-button d-inline-block" @click="resetMfaCode">Make user re-register MFA</div>
</div>
</template>

<script>
import DropDown from "@/components/forms/DropDown.vue";

const AuthMethods = Object.freeze({
  LEGACY:  'legacy',
  VHUB:  'vhub',
  VHUB_MFA: 'vhub-mfa',
  PIPELINE: 'pipeline',
  B2C: 'b2c',
  NONE: 'none'
});

export default {
  name: "UserIdpSetter",
  components: {DropDown},
  props: {
    userId: {type: String, required: true},
    userAuthMethod: {type: String, required: true},
    showReRegisterMfa: {type: Boolean, default: true}
  },
  data() {
    return {
      authMethod: 'legacy',
      AuthMethods,
    }
  },
  beforeMount() {
    this.authMethod = this.userAuthMethod;
  },
  methods: {
    setValue(method) {
      this.authMethod = method;
      this.updateUserMethod();
    },
    async updateUserMethod() {
      await this.$store.dispatch('updateUser', {
        id: this.userId,
        authMethod: this.authMethod
      })
    },
    async resetMfaCode() {
      await this.$store.dispatch('updateUser', {
        id: this.userId,
        mfaSetupComplete: false
      })
    }
  }
}
</script>