<template>
  <two-column-layout
      :id="$route.params.id"
      :tab-selector="$store.getters.getAppType($route.params.id) + 'tab'"
      :type="$store.getters.getAppType($route.params.id)"
      back-button-tab-selector="appbackbutton"
  >
    <template slot="sidebarLeft">
      <info-panel :id="$route.params.id"
                  :key="1"
                  slot="sidebarLeft"
                  :created="$store.getters.getAppCreationDate(id)"
                  :downloadable="true"
                  :image-editable="true"
                  :last-change="$store.getters.getAppChangeDate(id)"
                  :organization="$store.getters.getAppOrganization($route.params.id)"
                  :preview-uri="$store.getters.getAppPreviewUri($route.params.id)"
                  preview-id="appEdit"
                  upload-type="Project"
      />
      <team-attacher
          v-if="$store.getters.getAppOrganization($route.params.id)"
          :id="$route.params.id"
          slot="sidebarLeft"
          :limit-by-organization-id="$store.getters.getAppOrganization($route.params.id)"
          :teams="teams"
          class="mt-3"
          store-name="Project"
          @added="loadProjectTeams"
          @removed="loadProjectTeams"
      />
      <info-panel
          :id="$route.params.id"
          :key="2"
          slot="sidebarLeft"
          :limit-tags-to-organization="$store.getters.getAppOrganization($route.params.id)"
          :taggable="true"
          :tags="$store.getters.getAppTags($route.params.id)"
          preview-id="appEdit"
          @addTag="addTag"
          @removeTag="removeTag"
      />
    </template>

    <div slot="mainContent">
      <popup
          @close="() => {showAppPopup = false;}"
          v-if="showAppPopup"
      >
        <add-app slot="popupMainContent"
          :organization-id="$store.getters.getAppOrganization($route.params.id)"
                 @saved="showAppPopup = false; loadContextualInfo();"
        />
      </popup>
      <content-title-block
          :id="$route.params.id"
          :icon="$store.getters.getAppType($route.params.id)"
          :organization-id="$store.getters.getAppOrganization($route.params.id)"
          :value="$store.getters.getAppName($route.params.id)"
          store-name="App"
      />

      <a v-if="$store.getters.getAppType($route.params.id) === 'webapp'"
         :href="$store.getters.getAppHost + '/' + $store.getters.getAppSlug(id) + '/'"
         target="_blank">
        <div class="settings-button mb-2 clickable d-inline-block">
          {{ $t('goToApp') }}
          <icon class="ml-2" type="angle-right"/>
        </div>
      </a>

      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getAppDescription(id)"
          store-name="App"
      />
      <content-field-block
          :id="$route.params.id"
          :trim="true"
          :value="$store.getters.getAppSlug(id)"
          field-name="slug"
          input-type="text"
          label="slug"
          store-name="App"
      />

      <div v-if="$store.getters.getAppType($route.params.id) === 'webapp'" class="darker p-3 col-12 col-md-8 mt-3">
        <app-package-selector :app-id="$route.params.id"/>
        <div v-if="$store.getters.getAppType($route.params.id) === 'webapp'" :deactivated="publishing"
             class="settings-button d-inline-block mt-3" @click="publishChanges">
          {{ $t('Publish changes') }}
        </div>
        <div class="mt-3">
          <div v-if="publishError" class="form-error">{{ publishError }}</div>
          <div v-if="hitPublishButton" class="bg-highlight p-1 d-inline-block mt-1">{{ $t('publishHint') }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mt-3">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getAppMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Project"
                @added="loadApp"
                @removed="loadApp"
            />
          </info-panel>
        </div>
        <meta-panel
            v-for="(item) in $store.getters.getAppMetaSets(id)"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getAppOrganization($route.params.id)]"
            :title="item.description"
            class="col-12 col-lg-6 col-xl-11 col-xxl-8"
            icon-left="folder"
            store-name="App"
            type="app"
            @updated="loadApp"
        />
        <div v-if="($store.getters.isSuperAdmin || $store.getters.isSFXMember) && isAppPanel"
             class="col-12 mt-3">
          <div class="row">
            <div class="col-12 mb-1">
              POSSIBLE APPS SHOWN IN THIS WEBAPP (DEPENDING ON USER PERMISSION)
            </div>
            <div v-for="app in apps" :key="app.id" class="col-3 mb-4">
              <div class="bg-dark border-radius">
                <info-panel
                    :id="app.id"
                    :image-editable="true"
                    upload-type="Project"
                    :preview-id="app.id"
                    :preview-uri="app.previewUri"
                >
                  <div slot="info">
                    <div v-if="app.teams && compareTeams(app.teams)">
                      <icon type="check" />
                    </div>
                    <div v-else>
                      <icon type="times" /><br />
                    </div>
                    <router-link :to="'/app/'+app.id+'/general'">{{ app.name }}</router-link> <div v-if="app.baseProject">(Project name: {{ app.baseProject.name }})</div>
                    <div v-for="team in app.teams" :key="team.id" class="lighter">
                      {{ team.name }}
                    </div>
                  </div>
                </info-panel>
              </div>
            </div>
            <div class="col-3 mb-4">
              <div class="bg-dark p-3 border-radius">
                <div @click="showAppPopup = true"
                     class="settings-button d-inline-block">
                  {{ $t('addNewType', {type: 'app'}) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="mainContentTopRight">
      {{ $t('STATUS') }}<br>
      <toggle-button :labels="true"
                     :sync="true"
                     :value="$store.getters.getAppStatus(id)"
                     color="#DA5E06"
                     @change="updateContent({
                                            id: $route.params.id,
                                            state: $event.value?'a':'i'
                                    })"/>
    </div>
  </two-column-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import ContentFieldBlock from "../components/ContentFieldBlock";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import Icon from "@/components/Icon";
import AppPackageSelector from "../components/AppPackageSelector";
import {SpecialUuids} from "@/enum";
import Popup from "../components/Popup";
import AddApp from "../components/forms/AddApp";
export default {
  name: 'AppEdit',
  components: {
    ContentFieldBlock,
    MetaPanel,
    InfoPanel,
    TwoColumnLayout,
    ContentTitleBlock,
    ContentDescriptionBlock,
    MetaSetAttacher,
    TeamAttacher,
    Icon,
    AppPackageSelector,
    Popup,
    AddApp
  },
  data() {
    return {
      id: '',
      slug: '',
      teams: [],
      publishing: false,
      publishError: "",
      hitPublishButton: false,
      SpecialUuids: SpecialUuids,
      appType: '',
      apps: [],
      showAppPopup: false,
    };
  },
  computed: {
    isAppPanel() {
      return this.appType === 'appPanel';
    }
  },
  watch: {
    '$route'() {
      this.apps = [];
      this.appType = null;
      this.loadApp();
    }
  },
  mounted() {
    this.loadApp();
    this.loadProjectTeams();
    this.id = this.$route.params.id;
  },
  methods: {
    compareTeams(teams) {
      let hasTeam = false;
      for(let i = 0; i < teams.length; i++) {
        if(this.compareTeam(teams[i].id)) {
          hasTeam = true;
          break;
        }
      }
      return hasTeam;
    },
    compareTeam(teamId) {
      return this.teams ? this.teams.findIndex(team => {
        return team.id === teamId;
      }) !== -1 : true;
    },
    loadProjectTeams() {
      this.$store.dispatch('clientLoadProjectTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
        if(this.apps) {
          this.loadContextualInfo()
        }
      });
    },
    loadApp() {
      console.log('loading app...')
      this.$store.dispatch('loadApp', {
        id: this.$route.params.id,
        include: 'organization,tags,metaSets,instances'
      }).then(res => {
        const {instances} = res;
        if (instances && instances[0]) {
          if ([SpecialUuids.APP_PANEL_WEBAPP, SpecialUuids.APP_PANEL_WEBAPP_BETA].includes(instances[0].assetId)) {
            this.appType = 'appPanel';
          }
        }
        this.loadContextualInfo();
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    async loadContextualInfo() {
      if (this.isAppPanel) {
        await this.$store.dispatch('clientLoadProjects', {
          filter: 'type eq app,organizationId eq ' + this.$store.getters.getAppOrganization(this.$route.params.id),
          include: ['teams', 'baseProject']
        }).then(apps => {
          this.apps = apps;
        })
      }
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeAppTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addAppTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateContent: function (args) {
      this.$store.dispatch('updateApp', args);
    },
    setEditing(fieldName) {
      this.fieldEditing = fieldName;
    },
    isEditing(fieldName) {
      return this.fieldEditing === fieldName;
    },
    publishChanges() {
      this.publishing = true;
      this.hitPublishButton = true;
      this.publishError = "";
      this.updateContent({
        id: this.$route.params.id,
        state: 'a'
      });
      return fetch(process.env.VUE_APP_CONFIG)
          .then(r => r.json())
          .then(config => {
            const random = Math.floor(Math.random() * 10000);
            let url = `${config.sfx.project.webapp.appHost}/api/v2/apps/${this.$route.params.id}/publish?random=${random}`;
            const axios = require('axios');
            const authToken = this.$store.getters.authToken;

            return axios.post(url, JSON.stringify({}), {
                  headers: {
                    'Authorization': 'Bearer ' + authToken,
                    "Content-Type": "application/json"
                  }
                }
            );
          }).then(() => {
            this.publishing = false;
          }).catch(e => {
            this.publishing = false;
            this.publishError = e;
          });
    }
  },
}
</script>