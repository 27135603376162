<template>
    <tr>
        <popup
                @close="() => {showDeletePrompt = false;}"
                v-if="showDeletePrompt"
        >
            <delete-prompt slot="popupMainContent"
                           @abort="() => {showDeletePrompt = false;}"
                           @confirm="() => {showDeletePrompt = false; deleteDataRowFinally();}"
            />
        </popup>
      <popup
                @close="() => {showMetaForm = false;}"
                v-if="showMetaForm"
        >
        <div slot="popupMainContent">
          MetaForm for {{ dataRow.id }}
          <label>{{ $t('key and id') }}</label>
          <input type="text" class="form-text" v-model="row.key" />
           <label>{{ $t('task') }}</label>
          <select class="custom-select mb-2" v-model="row.data.task">
            <option :key="index" :value="value" v-for="(value, index) in Object.values(TaskManagerTaskTypes)">{{ value }}</option>
          </select>
          <label>{{ $t('targetType') }}</label>
          <input type="text" class="form-text" v-model="row.data.targetType" />
          <label>{{ $t('userId') }}</label>
          <input type="text" class="form-text" v-model="row.data.userId" />
          <label>{{ $t('prio') }}</label>
          <input type="text" class="form-text" v-model="row.data.prio" />
          <label>{{ $t('status') }}</label>
          <input type="text" class="form-text" v-model="row.data.status" />
          <vm-selector :pre-set-value="row.data.virtualMachine ? row.data.virtualMachine : null" class="mb-3 col-12 col-md-6" @selected="setVM" />
          <Button class="mt-2" @click="saveTask">{{ $t('saveTask') }}</Button>
        </div>
        </popup>
      <td style="min-width: 520px">
       <div v-if="row.key" class="small lighter ml-1 mt-1">{{ row.key }}</div>
        <p class="bg-bright p-1 mb-1 ml-1 mb-3">{{ $store.getters.getOrganizationDisplayName(organizationId) }}</p>
        <Button class="float-right" @click="row.data.userId = $store.getters.getCurrentUserId; showMetaForm = true"  type="icon-button">
                    <icon type="cog"/>
                  </Button>

        <h2 class="ml-1">{{ itemName ? itemName : '&nbsp;' }}</h2>
        <div class="form-error" v-if="error">{{ error }}</div>

        <div class="ml-1 mt-2 mb-1 form-section" v-if="row.data">
          <span class="lighter taskfield">currentProcessor:</span> {{ row.data.currentProcessor ? row.data.currentProcessor : "N/A" }}<br />
          <span class="lighter taskfield">currentScript:</span> {{ row.data.currentScript ? row.data.currentScript : "N/A" }}<br />
          <span class="lighter taskfield">scriptProgress:</span> {{ row.data.currentScriptProgress ? row.data.currentScriptProgress : "N/A" }}<br />
          <span class="lighter taskfield">lastUpdate:</span> {{ row.data && row.data.heartBeat ? dateFromISO(row.data.heartBeat) + ' ' +  timeFromISO(row.data.heartBeat) : "never"}}<br />
          <span class="lighter taskfield">Virtual Machine:</span><vm-selector :display-only="true" :pre-set-value="row.data.virtualMachine" /><br />
        </div>
        <div class="form-section">
          <h2>PROCESS NOTIFICATIONS</h2>
          <div v-if="waitingForResume">
            You just resumed the process – let's see if it resumes
          </div>
          <div v-else-if="((isOverDue(row.data && row.data.heartBeat ? row.data.heartBeat : null) || forceResume) && !resuming)" class="bg-highlight p-2 mt-2 mb-2">
            <div v-if="forceResume && !isOverDue(row.data.heartBeat)">You hit the forceResume-Button to show this panel here – make ABSOLUTELY SURE the task isn't running anymore, hit the reload button a few times, see it updates, wait at least for 1-2 minutes before doing anything</div>
            The last update for this process was <b>{{ row.data.heartBeat ? timeDifferenceFromTwoISOs(Date.now(), row.data.heartBeat).string : "N/A" }} ago</b><br /><br />
            <div v-if="isOverDue(row.data.heartBeat)">It seems like this process is stuck</div><br />
            <div v-if="row.data.prio == 666">ERROR: There was an error, you can resume, but it might be the case you'll just repeat the same error again, depending on what the exact error is (proxy errors are usually okay)</div><br />
            <div v-if="['vhubPostProcessorConfig', 'importHierarchy'].includes(row.data.currentProcessor)" @click="resume" class="settings-button p-1 mt-2 mb-2 d-inline-block">Resume</div>
            <div v-else>Sorry, only tasks after sucessful extraction can be resumed. If you're sure, extraction is stuck, you will need to open up "dangerous buttons" and hit the startTask button to restart the task</div>
          </div>
          <div v-else>
            No notifications at the moment
          </div>
        </div>
        <div class="form-section">
          <div class="ml-1" v-if="row.data && row.data.status"><span class="lighter">Last status:</span> {{ row.data.status }}</div>

        </div>
        <info-panel
            v-if="$store.getters.isSuperAdmin"
            class="mt-4"
              :info-panel-header="$t('Dangerous buttons')"
              :collapsible="true"
              :initially-collapsed="true"
              preview-id="none"
          >
          <div slot="info">
            <Button class="mb-2 mt-3 mr-2" @click="startTask" v-if="row.key">{{ $t('start Task') }} <icon type="angle-right"/></Button>
            <Button @click="finishTask" v-if="row.key">{{ $t('start PostProcessing') }} <icon type="box"/></Button>
          </div>
        </info-panel>
        <div class="ml-1 lighter" v-if="row.key">
          <router-link target="_blank" :to="'/library/3d-data/assembly/' + row.key">
           <icon type="external-link-alt" /> Go to resource
        </router-link>
        </div>
        <div class="ml-1 col-12 mt-3">
          This will only work if the VM is running<br />
          <loading-spinner v-if="fileLoading" />
          <div @click="listFiles" class="settings-button d-inline-block">{{ $t('loadLogFiles') }}</div>
          <select class="custom-select dark d-block mt-2" v-if="files.length" v-model="selectedFile">
            <option :key="file" :value="file" v-for="file in files">{{ file }}</option>
          </select>
          <div v-if="selectedFile" class="settings-button d-inline-block mt-2 mb-2" @click="loadFile">{{ $t('loadFile') }}</div>
          <codemirror v-if="fileContent" :value="fileContent" />
        </div>
        <Button class="mt-5 highlight" @click="setData">
          {{ $t('reload status') }}
                    <icon type="redo"/>
                  </Button>
        <div class="mt-5 force-resume clickable" @click="forceResume = !forceResume">.</div>
      </td>
        <td class="data-row-delete" v-if="deletable" @click="deleteDataRow">
            <Button type="icon-button" slot="buttonsRight"
            >
                <icon type="trash-alt"/>
            </Button>
        </td>
      <td class="data-row-delete" v-else>
        <!--empty placeholder because we have the Special Column left-->
      </td>
        <td v-if="!hiddenFields.includes(columnIndex)" :key="property" v-for="(field, property, columnIndex) in fields">
            <transition name="toggle" mode="out-in">
                <div :key="'edit'" class=" ml-3" v-if="type === 'tbl' ? columnIndex === currentlyEditingRow.index : property === currentlyEditingRow.index">
                    <date-picker-widget
                            class="mb-2"
                            v-if="field.type === 'timestamp'"
                            :value="currentlyEditingRow.value"
                            type="datetime"
                            @save="updateDataRow"
                    />
                    <input
                            v-else
                            class="form-text mb-2"
                            type="text"
                            :id="dataRow.id"
                            v-model="currentlyEditingRow.value"
                            :ref="'entry'"
                            @init="focusDataField"
                    >
                    <div class="edit-trigger mr-2" @click="finishEditingDataRow">
                        <icon :type="'times'"/>
                    </div>
                    <div class="edit-trigger" @click="saveDataRow">
                        <icon :type="'save'"/>
                    </div>
                </div>
            </transition>
            <div :key="'content'" class="table-filler" v-if="type === 'tbl' ? columnIndex !== currentlyEditingRow.index : property !==currentlyEditingRow.index"
                 @click="editDataRow(getIndexType === 'columnIndex' ? columnIndex : property)">
                        <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-if="field.type === 'timestamp' && dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dateTimeFromISO(dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]) }} <icon :type="'edit'"/>
                        </div>
                <div class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4" v-else-if="dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property]">
                            {{ dataRow.data[getIndexType === 'columnIndex' ? columnIndex : property] }} <icon :type="'edit'"/>
                        </div>
                <div v-else class="clickable withHoverBrightBackground pt-2 pb-2 pl-3 pr-4">
                    <span class="lighter">{{ $t('addData') }}</span> <icon :type="'plus'"/>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import DatePickerWidget from "../editors/DatePickerWidget";
    import DeletePrompt from "../../forms/DeletePrompt";
    import Popup from "../../Popup";
    import Icon from "../../Icon";
    import DateMixin from '@/components/mixins/DateMixin.js';
    import Button from "../../forms/Button";
    import {SpecialUuids, TaskManagerTaskTypes} from "@/enum";
    import InfoPanel from "@/components/InfoPanel";
    import vmSelector from "@/components/vmSelector";
    import Codemirror from "../editors/Codemirror";
    import LoadingSpinner from "../../LoadingSpinner";

    export default {
        name: "DatasetRowWidget",
        components: {
            DatePickerWidget,
            DeletePrompt,
            Popup,
            Icon,
            Button,
            InfoPanel,
            vmSelector,
            Codemirror,
            LoadingSpinner
        },
        mixins: [
            DateMixin,
        ],
        props: {
            dataRow: {type: Object || Array, default: null},
            fields: {type: Object || Array, default: null},
            hiddenFields: {type: Array, required: true},
            datasetId: {type: String, required: true,},
            type: {type: String, required: true},
            deletable: {type: Boolean, default: false},
        },
        data() {
            return {
              row: {},
              selectedFile: "",
              fileContent: "",
              fileLoading: false,
              files: [],
              itemName: '',
              SpecialUuids: SpecialUuids,
                currentlyEditingRow: {
                    id: '',
                    index: '',
                    value: '',
                },
                currentlyDeletingRow: '',
                showDeletePrompt: false,
                showMetaForm: false,
                organizationId: '',
                TaskManagerTaskTypes: TaskManagerTaskTypes,
                resuming: false,
                waitingForResume: null,
                forceResume: false,
                fetching: false,
                error: ""
            };
        },
        computed: {
            getIndexType: function () {
                return this.type === 'tbl' ? 'columnIndex' : 'property';
            },
        },
        beforeMount() {
          this.setData();
          // Special Code for Taskmanager
          if(!this.fetching) {
            this.continuouslyFetchingData();
          }
        },
        methods: {
          loadFile() {
            this.fileLoading = true;
            this.$store.dispatch('clientGetPipelineLogFile', {
              id: this.row.data.id,
              item: this.row.data.targetType.toLowerCase(),
              key: this.selectedFile
            }).then(content => {
              this.fileContent = content.text;
              this.fileLoading = false
            }).catch(e => {
              console.log(e);
              this.fileLoading = false;
            })
          },
          listFiles() {
            this.fileLoading = true;
            this.$store.dispatch('clientListPipelineLogFiles', {
              id: this.row.data.id,
              item: this.row.data.targetType.toLowerCase()
            }).then(files => {
              this.files = files.logFiles;
              this.fileLoading = false;
            }).catch(e => {
              console.log(e);
              this.fileLoading = false;
            })
          },
          isOverDue(heartBeat) {
            if(!heartBeat) {
              return false;
            }
            const pt = this.timeDifferenceFromTwoISOs(Date.now(), heartBeat);
            if(pt && pt.minutes > 15) {
              return true;
            }
            return false;
          },
          setVM(id) {
            this.row.data.virtualMachine = id;
          },
          continuouslyFetchingData() {
            const $this = this;
            this.fetching = true;
            setTimeout(() => {
              if(!$this.showMetaForm) {
                $this.setData();
              }
              $this.continuouslyFetchingData();
            }, 60000);
          },
          async setData() {
            const localDataRow = await this.$store.dispatch('clientLoadDatasetRows', {id: this.datasetId, filter: `id eq ${this.dataRow.id}`});
            if(!localDataRow || !localDataRow[0]) {
              this.row = {};
              return;
            }
            this.row = JSON.parse(JSON.stringify(localDataRow[0]));
            Object.keys(this.fields).map(item => {
              if(this.row.data[this.fields[item].columnIndex]) {
                const data = this.row.data[this.fields[item].columnIndex];
                delete this.row.data[this.fields[item].columnIndex];
                this.$set(this.row.data, item, data);
                //this.row.data[item] = data;
              } else {
                this.$set(this.row.data, item, '');
              }
            });
            if(!this.row.data) {
              this.row.data = {};
            }
            if(this.row.data.id) {
              if(this.row.data.targetType === 'Asset') {
                this.$store.dispatch('clientLoadAsset', {id: this.row.data.id}).then(data => {
                  this.itemName = data.name;
                  this.organizationId = data.organizationId;
                })
              } else {
                this.$store.dispatch('clientGetCrossProjectInstances', {filter: `id eq ${this.row.data.id}`, include: ['asset', 'project']}).then(data => {
                  if(data[0]) {
                    this.itemName = data[0].name;
                    this.organizationId = data[0].asset.organizationId;
                  }
                })
              }
            }
            if(this.waitingForResume && this.row.data && this.row.data.heartBeat) {
              if(!this.isOverDue(this.row.data.heartBeat)) {
                this.waitingForResume = null;
              } else {
                const pt = this.timeDifferenceFromTwoISOs(Date.now(), this.waitingForResume);
                if(pt.minutes > 3) {
                  this.waitingForResume = false;
                  this.error = "seems like resuming didn't work as expected – please make sure, priority is below 100 and try again";
                }
              }
            }
          },
          startTask() {
            if(this.row.data.prio == 666) {
              this.error = 'you cannot resume a task with prio 666, set it back to prio 4 and then try again'
              return;
            }
            this.row.data.status = 'new';
            this.saveTask();
            setTimeout(() => {}, 2000);
            let dispatchMethod = 'clientAssetToPipeline'
            if(this.row.data.targetType === 'Instance') {
              dispatchMethod = 'clientInstanceToPipeline';
            }
            this.$store.dispatch(dispatchMethod, {
                id: this.row.key,
                type: this.row.data.task,
                virtualMachine: this.row.data.virtualMachine
            });
          },
          async finishTask(resume = false) {
            this.error = "";
            let payload = {
              id: this.row.key
            };
            if(resume) {
              payload.resume = "true";
            }
            await this.$store.dispatch('clientFinishPipelineTask', payload)
                .then(() => {
                  this.resuming = false;
                  this.waitingForResume = Date.now();
                }).catch(e => {
                  this.error = e;
            });
          },
          async resume() {
            this.error = "";
            // leave this on ==, because it might be a number or a string!
            if(this.row.data.prio == 666) {
              this.row.data.status = 4;
              await this.saveTask(false);
              //this.error = 'you cannot resume a task with prio 666, set it back to prio 4 and then try again'
              //return;
            }
            this.resuming = true;
            await this.finishTask(true);
          },
          async saveTask (triggerReload = true) {
            return await this.$store.dispatch('clientUpdateDatasetRow', {
              id: this.datasetId,
              datarowId: this.row.id,
              key: this.row.key,
              listName: this.datasetId,
              data: {
                id: this.row.key,
                targetType: this.row.data.targetType,
                task: this.row.data.task,
                userId: this.row.data.userId,
                prio: this.row.data.prio,
                status: this.row.data.status,
                virtualMachine: this.row.data.virtualMachine
              }
            }).then(data => {
              if(triggerReload) {
                this.$emit('updateDatasetRow', data);
              }
              this.showMetaForm = false;
              return true;
            });
          },
            deleteDataRow: function(){
                this.showDeletePrompt = true;
            },
            deleteDataRowFinally: function() {
                this.$store.dispatch('deleteDatasetRow', {
                    id: this.datasetId,
                    args: [this.dataRow.id],
                    listName: this.datasetId,
                });
                this.$emit('updateDatasetRow', {id: this.dataRow.id, data: null})
            },
            editDataRow: function (index) {
                this.currentlyEditingRow.index = index;
                if(this.dataRow.data[index]) {
                    this.currentlyEditingRow.value = this.dataRow.data[index];
                }
            },
            focusDataField: function () {
                this.$refs.entry[0].$el.children[1].focus();
            },
            finishEditingDataRow: function () {
                this.currentlyEditingRow.index = '';
                this.currentlyEditingRow.value = '';
            },
            updateDataRow: function(val) {
                let value = val ? val : val.value;
                this.currentlyEditingRow.value = value;
            },
            saveDataRow: function () {
                let dataRowID = this.dataRow.id;
                //let value = val ? val : val.value;
                let value = this.currentlyEditingRow.value;
                let index = this.currentlyEditingRow.index;

                //make a deep copy
                let temp = JSON.parse(JSON.stringify(this.dataRow));
                if (!temp) {
                    temp = this.dataRow.filter(item => {
                        return item.id === this.dataRow.id
                    })[0];
                }
                let dataRow = temp.data;
                dataRow[index] = value;

                let keys = Object.keys(this.fields);
                let fieldName = '';
                for (let i = 0; i < keys.length; i++) {
                    if (this.fields[keys[i]].columnIndex === index) {
                        fieldName = keys[i];
                        break;
                    }
                }
                if (!fieldName) {
                    fieldName = index;
                }
                //find the fieldname end

                let params = {
                    id: this.datasetId,
                    data: {[fieldName]: value}, //replace by complete datarow
                    datarowId: dataRowID,
                    listName: this.datasetId,
                };
                this.$store.dispatch('clientUpdateDatasetRow', params).then(data => {
                  this.$emit('updateDatasetRow', data);
                });
                this.$emit('dataRowChange', dataRowID, index, value);
                this.finishEditingDataRow();
                this.setData();
            },
        }
    }
</script>

<style lang="scss">
    .taskfield {
      display:inline-block;
      min-width: 150px;
    }
    .force-resume {
      position: absolute;
      bottom:3px;
      right:3px;
      width: 20px;
      height: 20px;
      transition: all 300ms ease;
      &:hover {
        background-color: $highlight;
      }
    }
    //transition
    .toggle-enter-active, .toggle-leave-active {
        transition: all .3s ease-out;
        height: 80px;
        overflow:visible;
    }
    .toggle-enter, .toggle-leave-to {
        height: 0;
        overflow:hidden;
    }

    .data-row-delete {
        cursor:pointer;
        opacity:0.8;
        &:hover {
            opacity:1;
        }
    }
    .table-filler {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        position:relative;
        .icon {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            opacity: 0;
            position:absolute;
            right: 6px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-size: 0.8em;
        }
        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
</style>