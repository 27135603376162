<template>
    <div :class="['container-fluid main-container', backgroundImage ? 'with-background' : '']" :style="{ 'background-image': 'url(' + backgroundImage + ')' }">
        <div class="row sub-menu-panel">
          <navigation-panels-container
              id="library"
              :tab-selector="subMenuSelector"
              v-if="subMenuSelector"
          />
            <slot name="subMenu"/>
        </div>
        <div class="row">
            <div class="col ml-5">
                <slot name="buttonsLeft"/>
            </div>
            <div class="col"/>
            <div class="col mr-5 text-right create-new-button">
                <slot name="buttonsRight"/>
            </div>
        </div>
        <div class="row">
            <tabs slot="tabs"
                  v-if="backButtonTabSelector"
                  classes="col-12 col-md-2 col-lg-2 offset-md-1 backbutton"
                  :tab-selector="backButtonTabSelector"
                  :custom-tab-name="backButtonTabSelector"
                  :first-inactive="true"
            />
            <tabs
                v-if="tabSelector"
                :id="id"
                :tab-selector="tabSelector"
                :type="type"
            />
            <slot name="tabs"/>
        </div>
        <div class="row content-panel">
            <portal-target name="filter" multiple/>
            <div class="col">
                <div class="row">
                    <div class="col-6 col-md-4 col-lg-3 col-xl-2 offset-xl-1">
                        <slot name="sidebarLeft"/>
                        <portal-target name="sidebarLeft" multiple/>
                    </div>
                    <div class="col-12 col-sm-6 col-md-8 col-lg-9">
                        <slot name="mainContent"/>
                    </div>
                    <div class="col-2 col-md-1 content-top-right">
                        <slot name="mainContentTopRight"/>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script>
    import Tabs from "@/components/navigation/Tabs";
    import NavigationPanelsContainer from "@/components/navigation/NavigationPanelsContainer";
    export default {
        name: "OneColumnSidebarLeft",
        components: {
          Tabs,
          NavigationPanelsContainer
        },
        props: {
          backgroundImage: {type:String, default: ''},
          hasMarginTop: { type:Boolean, default: true},
          /**
           * @tabSelector String the tabSelector (e.g. "appstabs", set in router.js)
           * @backButtonTabSelector String the same but for the backButton
           * if set, tabs will be displayed automatically
           */
          tabSelector: { type:String, default: ''},
          backButtonTabSelector: { type:String, default: ''},
          subMenuSelector: { type:String, default: ''},
          /**
           * @id String the id to use for the tabs if tabSelector is given
           */
          id: { type:String, default: ''},
          /**
           * @type String the type to use for the tabs if tabSelector is given
           */
          type: { type:String, default: ''},
        },
    }
</script>

<style lang="scss" scoped>
    .content-top-right {
        position:absolute;
        right: 15px;
    }
</style>