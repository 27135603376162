<template>
    <div>
        <h1>
            <icon class="mr-2" type="align-left" size="0.9" />{{ $t("createNewQrCode") }}
        </h1>
        <organization-switcher
            v-if="canSelectOrganization"
            @setOrganization="
                (id) => {
                    organizationId = id;
                }
            "
            @setTeam="
                (id) => {
                    teamId = id;
                }
            "
            @unsetTeam="teamId = ''"
            class="mb-2"
        />
        <input
            type="text"
            :class="[
                'form-text mb-3 mt-3',
                $v.name.$dirty && $v.name.$error ? 'form-group--error' : '',
            ]"
            :id="'name'"
            v-model="name"
            :placeholder="$t('enternamehere')"
            @input="delayTouch($v.name)"
        />

        <div
            class="form-error"
            v-if="$v.name.$dirty && !$v.name.required && $v.name.$error"
        >
            {{ $t("errors.required") }}
        </div>
        <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">
            {{ $t("errors.alphaNumSpaceOnly") }}
        </div>

        <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">
            {{ $t("errors.atMostCharacters", { num: 128 }) }}
        </div>

        <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">
            {{ $t("errors.atLeastCharacters", { num: 3 }) }}
        </div>
        <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">
            {{ $t("errors.textNameAlreadyExists") }}
        </div>

        <div class="inline-edit-label">
            <b>{{ $t("description") }}</b>
        </div>
        <div>
            <textarea
                :class="[
                    'form-text',
                    $v.description.$dirty && $v.description.$error
                        ? 'form-group--error'
                        : '',
                ]"
                :id="'description'"
                v-model="description"
                :placeholder="$t('enterdeschere')"
                @input="delayTouch($v.description)"
            />
            <div
                class="form-error"
                v-if="$v.description.$dirty && !$v.description.maxLength"
            >
                {{ $t("errors.atMostCharacters", { num: 128 }) }}
            </div>
        </div>
        <div class="form-group mt-3">
            {{ $t("choseQrcodeType") }}
            <select v-model="type">
                <option :key="key" v-for="key in options" :value="key">
                    {{ $t(key) }}
                </option>
            </select>
        </div>
        <div v-if="type === 'sms' || type === 'tel'">
            <b>{{ $t("telnr") }}</b>
            <input
                class="form-text mb-3"
                type="text"
                ref="telnr"
                @keydown.enter="save"
                v-model="telnr"
            />
        </div>
        <div v-if="type === 'mail'">
            <b>{{ $t("email") }}</b>
            <input
                class="form-text mb-3"
                type="text"
                ref="email"
                @keydown.enter="save"
                v-model="email"
            />
        </div>
        <div v-if="type === 'sms' || type === 'mail'">
            <b>{{ $t("subject") }}</b>
            <textarea
                class="form-text mb-3"
                ref="subject"
                @keydown.enter="save"
                v-model="subject"
            />
        </div>
        <div v-if="type === 'sms' || type === 'mail'">
            <b>{{ $t("message") }}</b>
            <textarea
                class="form-text mb-3"
                ref="message"
                @keydown.enter="save"
                v-model="message"
            />
        </div>
    </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { alphaNumSpace } from "@/customValidators";
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
    name: "AddQrcodeForm",
    components: {
        OrganizationSwitcher,
    },
    mixins: [VuelidateMixin],
    props: {
        organizationId: { type: String, default: "" },
        teams: { type: Array, required: false, default: null },
        canSelectOrganization: { type: Boolean, required: false, default: true },
        afterSaveGoToGeneralPage: { type: Boolean, default: true },
    },
    data() {
        return {
            name: "",
            description: "",
            options: ["text", "sms", "tel", "mail"],
            type: "text",
            telnr: null,
            message: "",
            email: "",
            subject: "",
            teamId: "",
        };
    },
    validations: {
        name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(127),
            alphaNumSpace,
            async isUnique(value) {
                // standalone validator ideally should not assume a field is required
                if (value === "") {
                    return true;
                }
                let bool = true;
                await this.$store
                    .dispatch("checkIfQrcodeNameExists", {
                        name: value,
                        type: "Asset",
                        value: value,
                        organizationId: this.organizationId,
                    })
                    .then((data) => {
                        bool = data.length === 0;
                    });
                return bool;
            },
        },
        description: {
            maxLength: maxLength(1024),
        },
    },
    watch: {
        "$v.$invalid": function () {
            this.$emit("enableSaving", !this.$v.$invalid);
        },
    },
    methods: {
        save: function () {
            let args = {
                type: "qrcode",
                name: this.name,
                description: this.description,
                organizationId: this.organizationId,
            };

            if (this.teamId) {
                args.teams = [this.teamId];
            } else if (this.teams) {
                args.teams = this.teams.map((t) => t.id);
            }

            if (this.type) {
                args.content = this.getContentBasedOnType(this.type);
            }
            this.$store.dispatch("createQrcode", args).then((data) => {
                if (this.afterSaveGoToGeneralPage === true) {
                    this.$router.push(`/library/qrcodes/${data.id}/general`);
                } else {
                    this.$emit("saved");
                }
            });
        },
        getContentBasedOnType(type) {
            switch (type) {
                case "sms":
                    return "sms:" + this.telnr + "?body=" + this.message;
                case "tel":
                    return "PHONETO:" + this.telnr;
                case "mail":
                    return (
                        "MATMSG:TO:" +
                        this.email +
                        ";SUB:" +
                        this.subject +
                        ";BODY:" +
                        this.message +
                        ";"
                    );
            }
        },
    },
};
</script>
