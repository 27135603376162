<template>
  <div v-if="(compatibleTemplates && compatibleTemplates.length) || getTemplateId">
    <h3>{{ $t('vform.templates') }}</h3>
    <div class="form-error" v-if="object.templateId && !compatibleTemplates.find(item => {return item.key === object.templateId})">
      <icon type="exclamation-triangle" /> {{ $t('vform.missingTemplate') }}<br />
      <div @click="applyTemplate({}, true)" class="settings-button d-inline-block">{{ $t('vform.removeTemplate') }}</div>
    </div>
    <div class="mb-3">
        <text-input
            ref="textinput"
            :id="'tx-' + index"
            type="text"
            :placeholder="$t('typeToSelect')"
            @typingAlert="setSearchQuery"
        />
    </div>
    <div class="chooser-template-container mb-2">
      <div 
        v-if="filteredTemplates.length > 0"
        @click="applyTemplate(templ, object.templateId === templ.key)" 
        :key="templ.key" 
        v-for="templ in filteredTemplates"
        class="d-flex"
      >
        <div>
          <label class="container">
            <input
              v-if="object.templateId === templ.key"
              v-model="object.templateId"
              :id="'chkRadio' + index"
              type="checkbox"
              disabled="true"
            />
            <input 
              v-else
              :id="'chkRadio' + index"
              type="checkbox"
              disabled="true"
            />
            <span :class="type === 'checkbox' ? 'checkmark' : 'radio'"
            ></span>
          </label>
        </div>
        <div :class="['w-100 chooser-template-item p-1 clickable', object.templateId === templ.key ? 'selected' : '']">
          {{ templ.data.templateName }}
        </div>
      </div>
      <div v-if="filteredTemplates.length === 0">
        {{ $t('vform.noTemplatesFound') }}
      </div>
    </div>
    <span @click="templatePreviewing = true" class="settings-button d-inline-block">
      {{ $t('vform.viewAllTemplates') }}
    </span>
    <portal ref="form-popup" to="form-popup">
      <div id="template-preview-vforms" class="p-4" v-if="templatePreviewing">
        <div @click="templatePreviewing = false" class="settings-button d-inline-block mb-5 float-right switched-colors">{{ $t('vform.close') }}</div>
        <template-previewer
          :compatible-templates="compatibleTemplates"
          :selected-template-id="object.templateId"
          @switchToTemplate="applyTemplate"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {applyTemplate} from "@/components/vForm/templateFunctions";
import Icon from "@/components/Icon.vue";
import TemplatePreviewer from '@/components/vForm/TemplatePreviewer.vue';
import TextInput from "@/components/forms/TextInput";

export default {
  name: 'TemplateApplier',
  components: {
    Icon,
    TemplatePreviewer,
    TextInput,
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    templateAssetId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchQuery: '',
      templatePreviewing: false,
    };
  },
  computed: {
    ...mapState({
      templates: state => state.formpage.templates
    }),
    compatibleTemplates() {
      if(this.templates) {
        const entries = Object.values(this.templates);

        entries.sort((a, b) => {
          return a.data.templateName.localeCompare(b.data.templateName);
        });

        // check to see if a template has been selected, and if yes, put it at the top of the list
        if(this.object.templateId) {
          const selectedTemplate = entries.find(item => {
            return item.key === this.object.templateId;
          });

          if(selectedTemplate) {
            entries.splice(entries.indexOf(selectedTemplate), 1);
            entries.unshift(selectedTemplate);
          }
        }

        return entries.filter(item => {
          return item.data && item.data.formElementType === this.object.formElementType;
        });
      } else {
        return [];
      }
    },
    filteredTemplates() {
      if(this.searchQuery === '') {
        return this.compatibleTemplates;
      }

      return this.compatibleTemplates.filter(template => {
        return template.data.templateName.toLowerCase().includes(this.searchQuery.toLowerCase())
      });
    },
    getTemplateId() {
      return this.object.templateId;
    }
  },
  methods: {
    ...mapActions(['fetchTemplates']),
    setSearchQuery(input) {
      this.searchQuery = input.value;
    },
    applyTemplate(template, isSelected = false) {
      if(isSelected) {
        this.object.templateId = null;
      } else {
        applyTemplate(this, this.object, template);
        console.log('applying template', template)
      }
    },
  },
  watch: {
    // templates(newTemplates) {
    //   this.compatibleTemplates = newTemplates;
    // }
  },
  mounted() {
    this.$store.dispatch('loadBlockTemplates', {id: this.templateAssetId});
  },
};
</script>

<style lang="scss" scoped>
#template-preview-vforms {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
  height: auto;
  background-color: var(--vform-editor-ui-secondary-color);
  z-index: 655;
  box-shadow: -10px 5px 10px #1f1f1f;
  color: #fff;
}

.chooser-template {

  &-container {
    max-height: 100px;
    overflow-y: scroll;
  }

  &-item {
    border-bottom: 1px solid #262626;
    background-color: var(--vform-editor-ui-quaternary-color);
    &:hover, &.selected {
      background-color: var(--vform-editor-ui-secondary-color);
    }
  }
}

</style>
