<template>
  <main-table-layout>
    <div slot="buttonsRight"/>
    <content-filter
        :id="'projectList'"
        slot="filter"
        :initial-state-filter="['a', 'i', 'p', 'h']"
        :state-filter-items="Object.values(ProjectStates)"
        :team-filtering="true"
        store-name="Project"
        @filter="filtering"
    />
    <view-switcher
        :id="'mainContent'"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />
    <pagination
        :id="'projecttablepage'"
        ref="pagination"
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getProjectListOptions(listName, 'pagination_items')"
        @setActive="loadPaginatedData"
    />
    <Button
        v-if="$store.getters.isSuperAdmin"
        slot="mainContentButtons"
        class="full-height"
        icon="plus" link="/project/add" permission="createProject" type="action-button">
      {{ $t('createNewProject') }}
    </Button>
    <Button
        slot="mainContent"
        class="icon-button"
        icon="redo"
        type="reload"
        @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
    />
    <table-widget
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
        id="projectsDefaultTable"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getProjects"
        :deletable="true"
        :fields="tableFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :show-organization="true"
        :show-state="$store.getters.isSuperAdmin"
        :show-tags="true"
        :title-as-links="true"
        :use-team-permission="true"
        delete-permission="delete"
        edit-permission="write"
        resource="Project"
        @deleteRow="deleteRow"
        @sort="sort"
        :initial-sorting="sortObject"
    />
    <thumbnails
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
        :id="'projectsDefaultThumbnails'"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getProjects"
        :deletable="true"
        :fields="thumbnailFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :show-state="$store.getters.isSuperAdmin"
        :title-as-links="true"
        :use-team-permission="true"
        delete-permission="delete"
        edit-permission="write"
        resource="Project"
        @deleteRow="deleteRow"
        @sort="sort"
    />
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import ContentFilter from "../components/widgets/filter/ContentFilter";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import TableWidget from "@/components/widgets/TableWidget";
import {ProjectStates} from "@/enum";
import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

export default {
  name: "Projects",
  mixins: [LoadOverviewMixinJs],
  components: {
    Button,
    TableWidget,
    ContentFilter,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
  },
  data() {
    return {
      ProjectStates: ProjectStates,
      viewId: 'mainContent',
      forceReRenderKey: 0,
      include: 'teams,tags,versions',
      registerMethod: 'registerProjectsQueryParams',
      loadMethod: 'loadProjects',
      listName: 'projectList',
      deleteMethod: 'deleteProject',
      tableFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
      thumbnailFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Organization',
          id: 'organization',
          type: 'organization',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
        },
        {
          name: 'Tags',
          id: 'tags',
          type: 'tags',
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
    };
  },
}
</script>
