<template>
    <div class="dataset">
      <popup
          @close="() => {showDeletePrompt = false;}"
          v-if="showDeletePrompt"
      >
        <delete-prompt slot="popupMainContent"
                       @abort="() => {showDeletePrompt = false;}"
                       @confirm="() => {showDeletePrompt = false; deleteDataRowsFinally();}"
        />
      </popup>
      <popup
          @close="() => {showFullDeletePrompt = false;}"
          v-if="showFullDeletePrompt"
      >
        <delete-prompt slot="popupMainContent"
                       @abort="() => {showFullDeletePrompt = false;}"
                       @confirm="() => {showFullDeletePrompt = false; deleteAllRows();}"
        />
      </popup>
        <popup
                @close="finishEditingDataField"
                v-if="currentlyEditingField.oldId"
        >
            <div slot="popupMainContent">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <h1 v-if="currentlyEditingField.id">{{ $t('editfield', {field: currentlyEditingField.id }) }}</h1>
                        <h1 v-else>{{ $t('addnewcolumn') }}</h1>
                      <div class="form-section">
                        <label>{{ $t('fieldname') }}</label>
                        <input
                                class="form-text"
                                type="text"
                                :id="'fieldname'"
                                :label="'fieldname'"
                                v-model="currentlyEditingField.id"
                        >

                            <label class="mt-2">{{ $t('choseFieldType') }}</label><br>
                            <select class="custom-select" v-model="currentlyEditingField.type">
                                <option :key="key + Math.random().toString(36).substring(7)" v-for="key in fieldTypes" :value="key">{{ $t(key) }}</option>
                            </select>
                        </div>

                        <!--<div class="form-section">
                            <h2>{{ $t('order') }}</h2>
                            <label>{{ $t('insertBeforeField') }}</label><br>
                            <select class="custom-select" v-model="currentlyEditingField.columnIndex">
                                <option :key="item.columnIndex + Math.random().toString(36).substring(7)" v-for="(item, key) in $store.getters['getDatasetSchema'](id, listName)" :value="item.columnIndex">{{ $t(key) }}</option>
                            </select>
                        </div>-->
                        <Button
                                type="button"
                                class="mb-3"
                                :deactivated="!enableFieldSaving"
                                @click="saveDataField"
                        >
                            <icon v-if="enableFieldSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
                        </Button><br>

                        <Button v-if="fields[currentlyEditingField.id]" type="button" class="mb-3" @click="deleteDataField">
                            <icon type="minus-circle"/> {{ $t('deletefield') }}
                        </Button>
                    </div>
                </div>
            </div>
        </popup>
        <portal v-if="filterable" to="filter">
            <content-filter
                    to="filter"
                    @filter="filter"
                    @getMinMax="getMinMax"
                    :id="$route.params.id"
                    store-name="Dataset"
                    :text-filtering="false"
                    :tag-filtering="false"
                    :org-filtering="false"
                    :field-selector-list="fields ? fieldsToArray(fields) : []"
                    :field-push-selected-item="fieldPushSelectedItem"
            />
        </portal>

        <div class="d-flex mb-4 mt-5 align-items-center justify-content-between">
            <pagination
                    ref="pagination"
                    slot="mainContent"
                    :limit="limit"
                    :total="$store.getters.getDatasetRowListOptions(id, 'pagination_items')"
                    id="projecttablepage"
                    @setActive="loadPaginatedData"
            />
            <div class="button-top-right">
              <Button class="inline-block mr-2" type="button" @click="getCsvDataSet()">
                <icon type="file-download"/> {{ $t('downloadAsCsv') }}
              </Button>
                <Button class="inline-block" :deactivated="!columnsEditable" type="button" @click="editDataField('')">
                    <icon v-if="columnsEditable" type="plus"/> <icon v-else type="ban"/> {{ $t('addnewcolumn') }}
                </Button>
            </div>
        </div>
      <div class="mb-5">
        <div class="lighter mt-1">{{ $t('Export link all data') }}</div>
        <div class="w-100" />

        <input type="text" class="copy float-left lighter" ref="copyDownloadLink" :value="downloadLink" /><div class="clickable float-left lighter" @click="copyDownloadLink"><icon type="copy" /></div><br /><br />
        <div v-if="schemaName === schemaNames.EVENT">
          <div class="lighter mt-1">{{ $t('Export link sessions') }}</div>
          <input type="text" class="copy float-left lighter" ref="copyDownloadLink2" :value="downloadLink + '?mapName=formlogSessions'" /><div class="clickable float-left lighter" @click="copyDownloadLink(2, '?mapName=formlogSessions')"><icon type="copy" /></div><br /><br />
          <div class="lighter mt-1">{{ $t('Export link details') }}</div>
          <input type="text" class="copy float-left lighter" ref="copyDownloadLink3" :value="downloadLink + '?mapName=aggregatedFormlog'" /><div class="clickable float-left lighter" @click="copyDownloadLink(3, '?mapName=aggregatedFormlog')"><icon type="copy" /></div>
        </div>

      </div>
      <div class="settings-button d-inline-block mb-2" @click="showDeletePrompt = true">Delete all visible rows</div><br />
      <div class="settings-button d-inline-block mb-2" @click="showFullDeletePrompt = true">Delete all (!) rows</div>


      <div class="mb-3"><Button
                 slot="mainContent"
                 @click="loadPaginatedData(0, false); $refs.pagination.setActive(0)"
                 type="reload"
                 icon="redo"
                 class="icon-button"
             /></div>
      <div class="mt-3" v-if="[SpecialUuids['TASKMANAGER_DATASET']].includes(id)">
        <h3>SUMMARY</h3>
        <div :key="key + Math.random().toString(36).substring(7)" v-for="(dataContainer, key) in data">
          <span class="lighter">{{ dataContainer.data['0'] }} | {{ dataContainer.data['8'] }} |</span> VM: {{ dataContainer.data['10'] }}: {{ dataContainer.data['6'] }}<br />
        </div>
      </div>
        <div class="dataset-container fancy-scrollbar">
        <table class="dataset-table">
            <tr class="header">
                <th v-if="[SpecialUuids['TASKMANAGER_DATASET']].includes(id)">
                  <div class="ml-2">Special Column</div>
                </th>
                <th class="field-list-th">
                    <div class="field-list-container">
                        <Button type="icon-button" @click="showFieldList = !showFieldList">
                            <icon type="eye"/>
                        </Button>
                        <transition name="toggle-table-field-slideout" mode="out-in">
                            <div v-if="showFieldList" class="field-list">
                                <div class="p-2" @click="toggleFieldVisibility(index)" v-for="(item, index) in Object.keys($store.getters['getDatasetSchema'](id, listName))"
                                     :key="index">
                                    <icon v-if="!hiddenFields.includes(index)" type="eye"/>
                                    <icon v-else type="eye-slash"/>
                                    {{ item }}
                                </div>
                            </div>
                        </transition>
                    </div>
                </th>
                <th v-if="!hiddenFields.includes(index)" v-for="(item, index) in Object.keys($store.getters['getDatasetSchema'](id, listName))" :key="index">
                    <div class="dataset-field pl-3">{{ item }}</div>
                    <div class="table-td-overlay">
                        <div class="icons">
                            <div class="icon-container" @click="setFieldPushSelectedItem(fields[item])">
                                <icon type="filter" />
                            </div>
                            <div v-if="columnsEditable" class="icon-container" @click="editDataField(item)">
                                <icon class="edit" type="edit" />
                            </div>
                          <div class="icon-container" @click="setSorting(item)">
                            <icon type="sort" />
                          </div>
                        </div>
                    </div>
                    <transition name="toggle-table-field-slideout" mode="out-in">
                        <div v-if="showFilterForColumnIndex === index" class="table-td-slideout">
                            <div class="clickable" @click="() => {showFilterForColumnIndex = null;}"><icon type="times" /></div>
                            <input type="text" >
                        </div>
                    </transition>
                </th>
            </tr>
          <tbody v-if="loadingRows">
            <tr>
              <td>
                <loading-spinner />
              </td>
            </tr>
          </tbody>
            <tbody is="transition-group" name="togglerow" v-else-if="SpecialUuids['TASKMANAGER_DATASET'] === id && data">
              <dataset-row-taskmanager
                      :key="forceReRenderKey + dataContainer.id"
                      v-for="(dataContainer, key) in data"
                      :class="key%2 === 0 ? 'even' : 'odd'"
                      :data-row="dataContainer"
                      :hidden-fields="hiddenFields"
                      :fields="$store.getters['getDatasetSchema'](id, listName)"
                      :dataset-id="id"
                      :deletable="deletable"
                      :type="$store.getters['getDatasetType'](id, listName)"
                      @updateDatasetRow="updateDatasetRow"
              />
            </tbody>
          <tbody :key="forceReRenderKey" is="transition-group" name="togglerow" v-else-if="![SpecialUuids['TASKMANAGER_DATASET']].includes(id) && data">
            <dataset-row-widget
                    :key="forceReRenderKey + dataContainer.id"
                    v-for="(dataContainer, key) in data"
                    :class="key%2 === 0 ? 'even' : 'odd'"
                    :data-row="dataContainer"
                    :hidden-fields="hiddenFields"
                    :fields="$store.getters['getDatasetSchema'](id, listName)"
                    :dataset-id="id"
                    :deletable="deletable"
                    :type="$store.getters['getDatasetType'](id, listName)"
                    @updateDatasetRow="updateDatasetRow"
            />
            </tbody>
        </table>
        </div>

        <Button slot="buttonsRight"
                class="mb-3 mt-3"
                @click="addRow"
        >
            <icon type="plus"/>{{ $t('addrow') }}
        </Button>
    </div>
</template>

<script>

const schemaNames = Object.freeze({
  EVENT: 'sfx.event'
});

    import Button from "../../forms/Button";
    import Popup from "../../Popup";
    import Icon from "../../Icon";
    import DatasetRowWidget from "./DatasetRowWidget";
    import DatasetRowTaskmanager from "@/components/widgets/dataset/DatasetRowTaskmanager";
    import Pagination from "../../Pagination";
    import ContentFilter from "../filter/ContentFilter";
    import ArrayMixin from "../../mixins/ArrayMixin.js";
    import { saveAs } from 'file-saver';
    import {SpecialUuids} from "@/enum";
    import LoadingSpinner from "@/components/LoadingSpinner";
    import DeletePrompt from "../../forms/DeletePrompt";

    export default {
        name: 'Dataset',
        components: {
            Button,
            Popup,
            Icon,
            DatasetRowWidget,
            Pagination,
            ContentFilter,
            DatasetRowTaskmanager,
            LoadingSpinner,
            DeletePrompt
        },
        mixins: [
            ArrayMixin
        ],
        props: {
            id: {type: String, required: true,},
            deletable: {type: Boolean, default: false},
            filterable: {type: Boolean, default: false},
            hasPagination: {type: Boolean, default: true},
        },
        data() {
            return {
                showFullDeletePrompt: false,
                loadingRows: false,
                listName: 'DatasetList',
                SpecialUuids: SpecialUuids,
                downloadLink: '',
                filterArgs: {},
                limit: 20,
                offset: 0,
                forceReRenderKey: 1,
                order: '',
                queryRelevantComponents: 2, //pagination & filter
                queryRelevantInits: 0,
                enableFieldSaving: true,
                columnsEditable: true,
                showFieldList: false,
                fieldPushSelectedItem: null,
                currentlyEditingField: {
                    oldId: '',
                    id: '',
                    columnIndex: '',
                    type: '',
                },
                fieldTypes: [
                    'string',
                    'number',
                    'timestamp',
                    'time'
                ],
                fields: null,
                schemaName: "",
                hiddenFields: [],
                filteredFields: [],
                showFilterForColumnIndex: null,
                data: null,
                type: this.$store.getters['getDatasetType'](this.$route.params.id, this.listName),
                rowFilterFields: [],
                showDeletePrompt: false,
                schemaNames: schemaNames
            }
        },
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadDataRows();
                }
            },
            data: function() {
                if(Object.keys(this.data).length > 1 && this.type === 'obj') {
                    this.columnsEditable = false;
                }
                else {
                    this.columnsEditable = true;
                }
            },
            currentlyEditingField: {
                deep: true,
                handler() {
                    this.enableFieldSaving = !(!this.currentlyEditingField.id || this.currentlyEditingField.id.length === 0);
                }
            },
        },
        beforeMount(){
            if(this.filterable) {
                //this.queryRelevantComponents++;
            }
          this.loadData();
        },
        methods: {
          loadData() {
            this.$store.dispatch('loadDataset', {
              id: this.id,
              listName: this.id,
            }).then(() => {
              this.setData();
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              } else if(e.status === 404) {
                this.$router.push('/not-found')
              }
            });
            this.setDownloadLink();
          },
            async setDownloadLink() {
              this.downloadLink = await this.$store.getters.getClientURL + `datasets/${this.id}/json`;
            },
            copyDownloadLink(no) {
              let ref = 'copyDownloadLink';
              if(no) {
                ref += no;
              }
              const copyText = this.$refs[ref];
              copyText.select();
              copyText.setSelectionRange(0, 99999); /* For mobile devices */
              document.execCommand("copy");
              this.$store.dispatch('createNotification', {'text': this.$t('valueCopiedToClipboard')});
            },
            updateDatasetRow(params) {
              const index = this.data.findIndex(item => {return item.id === params.id});
              if(params.data) {
                this.data[index].data = params.data;
              }
              else {
                this.data.splice(index, 1);
              }
            },
            setSorting(item) {
              this.order = this.order.includes(item) && this.order.charAt(0) === '-' ? '+' + item : '-' + item;
              this.loadDataRows();
            },
            loadDataRows() {
              this.loadingRows = true;
              let args = {
                listName: this.id,
                keep: {
                  id: this.id,
                  datasetId: this.id,
                },
                add: {
                  offset: {
                    [this.id]: this.offset,
                  },
                  paging: {
                    [this.id]: true
                  },
                  limit: {
                    [this.id]: this.limit,
                  },
                  sort: {
                    [this.id]: '-createdAt'
                  }
                }
              };
              if(this.filterArgs) {
                Object.keys(this.filterArgs).forEach(key => {
                  args[key] = this.filterArgs[key];
                })
              }
              if(this.order) {
                  args.add.sort =  {
                    [this.id]: this.order
                  }
              }
              this.$store.dispatch('loadDatasetRows', args).then(data => {
                this.data = [];
                this.data = data;
                this.loadingRows = false;
                this.forceReRenderKey++;
              });
            },
            setData() {
                this.fields = this.$store.getters.getDatasetSchema(this.id, this.listName);
                this.type = this.$store.getters['getDatasetType'](this.$route.params.id, this.listName);
                this.schemaName = this.$store.getters.getDatasetSchemaName(this.id, this.listName);
            },
            toggleFieldVisibility: function(index) {
                if(this.hiddenFields.includes(index)) {
                    let arrIndex = this.hiddenFields.indexOf(index);
                    if (arrIndex > -1) {
                        this.hiddenFields.splice(arrIndex, 1);
                    }
                }
                else {
                    this.hiddenFields.push(index);
                }
            },
            toggleFilter: function(item) {
                let field = this.fields[item];
                this.showFilterForColumnIndex = this.showFilterForColumnIndex !== field.columnIndex ? field.columnIndex : null;
            },
            finishEditingDataField: function() {
                this.currentlyEditingField.id = '';
                this.currentlyEditingField.columnIndex = '';
                this.currentlyEditingField.type = '';
                this.currentlyEditingField.oldId = '';
            },
            editDataField: function(id) {
                if(!this.columnsEditable) {
                    this.$store.dispatch('createNotification', {'text': this.$t('errors.cantEditRowWhenData')});
                    return;
                }
                if(!id) {
                    this.currentlyEditingField = {
                        id: '',
                        type: 'string',
                        columnIndex: this.getMaxColumnIndex() + 1,
                        oldId: 'new',
                    };
                }
                else {
                    let field = this.fields[id];
                    this.currentlyEditingField = {
                        id: id,
                        type: field.type,
                        columnIndex: field.columnIndex,
                        oldId: id,
                    };
                }
            },
          async deleteAllRows() {
            this.$store.dispatch('clientLoadDatasetRows', {id: this.$route.params.id})
                .then(async data => {
                 return await this.$store.dispatch('deleteDatasetRow', {
                    id: this.id,
                    args: data.map(item => {return item.id}),
                    listName: this.id,
                  })
            }).then(() => {
              this.loadDataRows();
            })
          },
          async deleteDataRowsFinally() {
            await this.$store.dispatch('deleteDatasetRow', {
              id: this.id,
              args: this.data.map(item => {return item.id}),
              listName: this.id,
            }).then(() => {
              this.loadDataRows();
            });
          },
            deleteDataField: function() {
                delete this.fields[this.currentlyEditingField.id];
                let args = {
                    id: this.$route.params.id,
                    schema: {
                        columns: this.fields,
                    }
                };
                this.$store.dispatch('updateDataset', args);
                this.finishEditingDataField();
            },
            saveDataField: function() {
                if(this.enableFieldSaving) {
                    let tempField = {
                        type: this.currentlyEditingField.type,
                        columnIndex: this.currentlyEditingField.columnIndex,
                    };
                    let updatedFields = this.fields;
                    if(updatedFields[this.currentlyEditingField.oldId] && this.currentlyEditingField.oldId !== this.currentlyEditingField.id) {
                        delete updatedFields[this.currentlyEditingField.oldId];
                    }
                    updatedFields[this.currentlyEditingField.id] = tempField;

                    let array = this.fieldsToArray(updatedFields);
                    let sortedFields = this.arrayToFields(array);

                    let args = {
                        id: this.$route.params.id,
                        schema: {
                            columns: sortedFields
                        },
                    };
                    this.$store.dispatch('updateDataset', args);
                    this.finishEditingDataField();
                }

            },

            arrayToFields: function (array) {
                let fields = {};
                for (var i = 0; i < array.length; i++) {
                    let objKey = array[i].name;
                    delete array[i].name;
                    fields[objKey] = array[i];
                }
                return fields;
            },
            getMaxColumnIndex: function () {
                let maxIndex = 0;
                Object.keys(this.fields).forEach(key => {
                    let value = this.fields[key].columnIndex;
                    maxIndex = maxIndex < value ? value : maxIndex;
                });

                return maxIndex + 1;
            },
            addRow() {
                this.$store.dispatch('clientCreateDatasetRow', {
                    id: this.id,
                }).then(() => {
                  this.loadDataRows();
                });
            },
            async getCsvDataSet() {
              await this.$store.dispatch('clientLoadDatasetAsCsv', {
                id: this.id
              }).then(res => {
                const blob = new Blob([res], {type: "text/plain;charset=utf-8"});

                (async () => {
                  saveAs(blob, this.id + '.csv');
                })();
              });
            },
            loadPaginatedData: function(offset, initial) {
              this.offset = offset;
              if(!initial) {
                this.loadDataRows(initial);
              } else {
                this.queryRelevantInits++;
              }
            },
            filter(args, initial) {
              this.filterArgs = args;
              if(!initial) {
                this.loadDataRows();
              } else {
                this.queryRelevantInits++;
              }
            },
            getMinMax(fieldName) {
                let args = {
                    id: this.id,
                    listName: this.id,
                    fieldName: fieldName,
                    fieldFilter: {
                        minMax: fieldName
                    }
                };

                this.$store.dispatch('loadAggregatedDatasetRows', args);
            },
            setFieldPushSelectedItem(item) {
                this.fieldPushSelectedItem = item;
                const $this = this;
                setTimeout(function() {
                  $this.fieldPushSelectedItem = null;
                }, 100);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .dataset {
        .pagination {
            ul {
                padding-left: 0;
                margin-left: 0;
            }
        }
    }
    .button-top-right {
        width: 550px;
        text-align: right;
    }
    .field-list-container {
        display:inline-block;
        position: relative;
        z-index:2;
        .icon-button {
            position:relative;
            z-index: 2;
        }
    }
    .field-list {
        position:absolute;
        top:0;
        left:0;
        z-index:0;
        width: 300px;
        background-color: $table-header-darker-color;
        padding:8px;
        padding-top:50px;
        .icon {
            padding:4px;
            margin-right:6px;
            background-color: lighten($table-header-darker-color, 10%);
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            cursor:pointer;
            &:hover {
                background-color: $highlight;
            }
        }
    }

    //transition vue toggling row
    .togglerow-enter-active, .togglerow-leave-active {
        transition: all .2s ease-out;
        div {
            max-height: 100px;
        }
    }
    .togglerow-enter, .togglerow-leave-to {
        div {
            max-height: 0;
        }
    }

    //transition vue toggling visibility toggler
    .toggle-field-list-enter-active, .toggle-field-list-leave-active {
        transition: all .3s ease-out;
        width: 300px;
        max-height: 100vh;
        overflow:visible;
    }
    .toggle-field-list-enter, .toggle-field-list-leave-to {
        width: 0;
        overflow:hidden;
        max-height:0;
    }

    .table-filler {
        min-height:20px;
    }
    .dataset {
        width: 100%;
    }
    .dataset-container {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .dataset-table {
        table-layout: auto;
        position:relative;
        .header {
            background-color: $table-header-darker-color;
        }
        th {
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 0;
            z-index:2;
            background-color: $table-header-darker-color;
        }

        tr {
            td, th {
                border: 1px solid $navbarcolor;
                min-width: 120px;
                padding:10px;
            }
            th:first-child {
                min-width: auto;
                padding-left: 8px;
                width: 70px;
            }
            .data-row-delete {
                min-width: auto;
                max-width: none;
                width: 40px;
            }
        }
    }
    .dataset-content {
        width:100%;
        height:100%;
        cursor:pointer;
    }

    .dataset-field {
        padding: 8px 0px;
        cursor: pointer;
    }

    .table-td-overlay {
        .icons {
            position:absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
        .icon-container {
            padding: 3px;
            margin: 3px;
            cursor:pointer;
            display:inline-block;
            &:hover {
                background-color: darken($highlight, 10%);
            }
        }
    }

    #fieldEditPopup {
        width: 90vw;
        max-width: 1200px;
        height: 90vh;
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.9);
        padding: 45px;
        z-index: 5;
        color: #fff;

        .close-button {
            position: absolute;
            top: 25px;
            right: 25px;
            font-size: 1.5em;
            cursor: pointer;
        }
    }
</style>
