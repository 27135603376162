<template>
  <div id="app">
    <div v-if="isMaintenance">
     <maintenance />
    </div>
    <page v-else-if="allLoaded">
        <router-view />
    </page>
  </div>
</template>

<script>
import Page from './components/Page.vue'
import Maintenance from "@/components/Maintenance.vue";
export default {
  name: 'App',
  components: {
    Page,
    Maintenance
  },
  data() {
    return {
      isMaintenance: false,
      allLoaded: false,
    };
  },
  beforeMount() {
    this.loadConfig();
  },
  methods: {
     /**
    * Loads identity provider module
    * **/
     loadConfig() {
      fetch(process.env.VUE_APP_CONFIG)
      .then(r => r.json())
      .then(async config => {
          const {appHost, isMaintenance} = config.sfx.project.webapp;
          this.environment = config.sfx.environment;
          this.$store.dispatch('setAppHostAction', appHost);
          this.isMaintenance = isMaintenance;
      }).then(async () => {
          this.allLoaded = true;
      });
    },
  }
}
</script>

