<template>
  <component
      :is="fullScreenMode ? 'fullScreenLayout' : 'mainTableLayout'"
      :id="$route.params.id"
      :has-top-bar="false"
      back-button-tab-selector="formbackbutton"
      class="newStyle form-edit-local"
      sub-menu-selector="librarypanel"
      tab-selector="formtab"
      type="form"
  >
    <div slot="mainContent">
      <popup v-if="showFormSettings" @close="showFormSettings = false">
        <div slot="popupMainContent">
          <div
              v-if="$store.getters.getFormOrganization($route.params.id)"
              class="mb-3"
          >
            <a v-if="$store.getters.isSuperAdmin"
                @click="$router.push(`/admin/organizations/${$store.getters.getFormOrganization($route.params.id)}/general`)">
              <tag
                  :is-removable="false"
                  :tag="{name: $store.getters.getOrganizationDisplayName($store.getters.getFormOrganization($route.params.id))}"
                  icon-left="home"
              />
            </a>
            <span v-else>
              <tag
                  :is-removable="false"
                  :tag="{name: $store.getters.getOrganizationDisplayName($store.getters.getFormOrganization($route.params.id))}"
                  icon-left="home"
              />
            </span>
          </div>
          <name-display-edit-block
              v-if="$store.getters.getFormName(id)"
              :id="$route.params.id"
              :organization-id="$store.getters.getFormOrganization($route.params.id)"
              :name="$store.getters.getFormName(id)"
              :display-name="$store.getters.getFormDisplayName(id)"
              store-name="Form"
          />
          <content-description-block
              :id="$route.params.id"
              :value="$store.getters.getFormDescription(id)"
              store-name="Form"
          />
          <div class="row">
            <div class="mb-4 col-6">
              <div class="darker p-3 border-radius">
                <h2>{{ $t("baseProject") }}</h2>
                <span class="lighter">{{
                    $t("selectedType", {type: $t("type.project")})
                  }}</span
                ><br/>
                <p>{{ $t("vFormProjectHint") }}</p>
                <br/>
                <div v-if="error" class="form-error">{{ error }}</div>
                <project-linker
                    ref="projectLinker"
                    :organization-id="
                                    $store.getters.getFormOrganization($route.params.id)
                                "
                    :project-id="projectId"
                    @addProject="addProject"
                    @removeProject="removeProject()"
                />
              </div>
            </div>
            <div class="mb-4 col-6">
              <div class="darker p-3 border-radius">
                <h2>{{ $t("publishing") }}</h2>
                <!--PUBLISH FROM COMPONENT-->
                <v-form-publisher
                    v-if="projectId"
                    :project-id="projectId"
                />
                <!--/PUBLISH FROM COMPONENT-->
              </div>
            </div>
            <div class="mb-4 col-6">
              <div class="darker p-3 border-radius">
                <h2>Info</h2>
                <div class="form-section">
                  <h3>Logging</h3>
                  <div class="settings-button d-inline-block mt-2" @click="showLogReplacePrompt = true;">
                    {{ $t('attachNewLog') }}
                    <icon class="ml-2" type="plus"/>
                  </div>
                  <div v-if="!logId">No log attached</div>
                  <div v-else>
                    <div><span class="lighter">LogID:</span><br/>{{ logId }}</div>
                    <router-link
                        :to="'/library/dataset/' + logId + '/general'"
                        class="settings-button d-inline-block mt-2"
                    >Go to log
                      <icon class="ml-2" type="angle-right"/>
                    </router-link>
                    <br/>
                  </div>

                  <div class="mt-3"><span class="lighter">Project ID: </span><br/>{{ projectId }}</div>
                  <router-link
                      v-if="projectId"
                      :to="'/project/' + projectId + '/general'"
                      class="settings-button d-inline-block mt-2">Go to project
                    <icon class="ml-2" type="angle-right"/>
                  </router-link>
                  <br/>
                </div>
              </div>
            </div>
            <div class="col-6">
              <info-panel
                  :id="$route.params.id"
                  slot="mainContent"
                  :collapsible="true"
                  :initially-collapsed="false"
                  :limit-tags-to-organization="$store.getters.getFormOrganization($route.params.id)"
                  :taggable="true"
                  :tags="$store.getters.getFormTags($route.params.id)"
                  preview-id="formEdit"
                  @addTag="addTag"
                  @removeTag="removeTag"
              >
                <div slot="info" class="mt-3">
                  <h3>{{ $t('teams') }}</h3>
                  <team-attacher
                      v-if="$store.getters.getFormOrganization($route.params.id)"
                      :id="$route.params.id"
                      slot="mainContent"
                      :initially-collapsed="false"
                      :limit-by-organization-id="$store.getters.getFormOrganization($route.params.id)"
                      :show-as-info-panel="false"
                      :teams="teams"
                      class=""
                      storeName="Asset"
                      @added="loadAssetTeams"
                      @removed="loadAssetTeams"
                  />

                </div>
              </info-panel>
            </div>
            <div class="col-6">

            </div>
          </div>
        </div>
      </popup>
      <popup
          v-if="showProjectRemovePrompt"
          :elevated-z-index="10005"
          @close="showProjectRemovePrompt = false"
      >
        <div slot="popupMainContent">
          {{
            $t(
                "If you remove or change the project, the form might not work anymore."
            )
          }}
          <delete-prompt
              @abort="
                            () => {
                                showProjectRemovePrompt = false;
                            }
                        "
              @confirm="
                            () => {
                                removeProject();
                                showProjectRemovePrompt = false;
                            }
                        "
          />
        </div>
      </popup>
      <popup
          v-if="showLogReplacePrompt"
          :elevated-z-index="10005"
          @close="showLogReplacePrompt = false"
      >
        <div slot="popupMainContent">
          <delete-prompt
              :custom-message="$t('Do you really want to create a new log? If you already have an existing log, it will continue to exist, but won\'t receive any data from the form anymore. Do not forget to save after this process or the old log will still be connected.')"
              @abort="
                            () => {
                                showLogReplacePrompt = false;
                            }
                        "
              @confirm="
                            () => {
                                replaceLog();
                                showLogReplacePrompt = false;
                            }
                        "
          />
        </div>
      </popup>
      <div class="row pl-3">
        <div class="col-9">
          <h3 class="d-inline-block bright">vFORM CREATOR</h3>
          <h3 class="title-addition d-inline-block">
            <span class="smaller ml-1">{{ $store.getters.getFormName(id) }}</span> <span
              v-if="config">(v{{ config ? config.version : '1' }}.0)</span>
          </h3>
          <div
              class="settings-button action-button float-right ml-3"
              @click="$refs.form.saveContent()"
          >
            <icon class="mr-1" type="save"/>
            {{ $t("save") }}
          </div>
          <div
              class="settings-button float-right ml-3 d-flex"
              @click="$refs.form.exportToPDF()"
          >
            <icon v-if="!$refs.form || !$refs.form.pdfExportProcessing" class="mr-2" type="pdf"/>
            <loading-spinner v-if="pdfGenerating" class="white mr-2"/>
            {{ $t("PDF") }}
            <div class="d-inline-block ml-1" v-if="pdfGenerating"> ({{ currentPDFPage }} / {{ totalPDFPages }})</div>
          </div>
          <div
              class="settings-button float-right ml-3 d-flex"
              @click="$refs.form.showLogSelection()"
              v-if="logId"
          >
            <icon v-if="!$refs.form || !$refs.form.pdfExportProcessing" class="mr-2" type="pdf"/>
            <loading-spinner v-if="pdfReportGenerating" class="white mr-2"/>
            {{ $t("PDFReport") }}
            <div class="d-inline-block ml-1" v-if="pdfReportGenerating"> ({{ currentPDFPage }} / {{
                totalPDFPages
              }})
            </div>
          </div>

          <div
              class="settings-button float-right ml-3"
              @click="showFormSettings = true"
          >
            <icon class="mr-1" type="cog"/>
            {{ $t("formSettings") }}
          </div>
          <div class="float-right">
            <language-selector
                :use-plain-language="true"
                :value="lang"
                class="mr-2"
                open-direction="bottom"
                @update="setLanguage"
            />
          </div>
          <div class="d-inline-block float-right mr-4">
            <screen-size-selector
                class="vform-screen-size-selector"
                @setOrientation="setScreenOrientation"
                @setSize="setScreenSize"
            />
          </div>
        </div>
        <div class="col-3">
          <div
              class="settings-button float-right mr-2"
              @click="fullScreenMode = !fullScreenMode"
          >
            <icon
                :type="fullScreenMode ? 'fal-normal-screen' : 'fal-full-screen'"
            />
          </div>
          <div class="settings-button float-right mr-3" @click="playSlides()">
            <icon :type="editorMode === 'view' ? 'fal-stop' : 'fal-play'"/>
          </div>
        </div>
      </div>

      <v-form-editor
          v-if="projectId && organizationId"
          :key="forceReRenderKey"
          ref="form"
          :asset-name="$store.getters.getFormName($route.params.id)"
          :external-language="lang"
          :form-id="$route.params.id"
          :organization-id="$store.getters.getFormOrganization($route.params.id)"
          :project-id="projectId"
          :target-teams="teams"
          :editor-mode="editorMode"
          class="mt-2"
          @clearError="resetError"
          @dirty="(dirty) => {this.dirty = dirty;}"
          @logId="setLogId"
          @projectPackageError="setProjectLoadError"
          @setConfig="setConfig"
          @setSlides="setSlides"
          @setLanguage="setLanguage"
          @pdfGenerating="setPDFGenerating"
          @pdfReportGenerating="setPdfReportGenerating"
          @addingPage="addingPage"
          @totalPages="totalPages"
      />
      <div
          class="settings-button d-inline-block ml-3 mt-2"
          @click="$refs.form.showLogCorrector = true"
          v-if="logId"
      >
        <icon type="form" class="mr-2"/>
        {{ $t("Log for step") }}
      </div>
      <codemirror
          v-if="
              !fullScreenMode &&
              editorMode === 'edit'"
          :do-not-format-on-startup="true"
          :value="JSON.stringify(config)"
          class="mt-5"
          @save="(content) => {this.$refs.form.saveContent(false, JSON.parse(content), false)}"
      />
      <div @click="showGraph = !showGraph" class="settings-button d-inline-block" v-if="$store.getters.isSuperAdmin">{{ $t('showGraph') }}</div>
      <form-node-editor class="col-12" v-if="showGraph && config && slides.length > 0" :vform="config" :slides="slides" />
    </div>
  </component>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import MainTableLayout from "../layouts/MainTableLayout";
import FullScreenLayout from "../layouts/FullScreenLayout";
import LoadingSpinner from "@/components/LoadingSpinner";
//import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import Codemirror from "@/components/widgets/editors/Codemirror";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import vFormEditor from "@/components/vForm/vFormEditor";
import Icon from "@/components/Icon";
import vFormMixin from "@/components/vForm/vFormMixin.js";
import Popup from "../components/Popup";
import Tag from "../components/Tag";
import ProjectLinker from "@/components/vForm/ProjectLinker";
import DeletePrompt from "../components/forms/DeletePrompt";
import LanguageSelector from "../components/forms/LanguageSelector";
import Component from "vue-class-component";
import VueSlider from "vue-slider-component";
import ScreenSizeSelector from "../components/vForm/viewer/ScreenSizeSelector";
import UniversalSelector from "@/components/organizations/UniversalSelector";
import vFormPublisher from "@/components/vFormPublisher/vFormPublisher";
import ThemeSetterMixinJs from "../components/mixins/ThemeSetterMixin.js.vue";
import {SpecialUuids, vformModes} from "../enum";
import FormNodeEditor from "@/components/nodeEditors/FormNodeEditor.vue";
// Register the router hooks with their names
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

import NameDisplayEditBlock from "@/components/NameDisplayEditBlock.vue";

export default {
  name: "FormEdit",
  components: {
    //MetaPanel,
    NameDisplayEditBlock,
    InfoPanel,
    MainTableLayout,
    ContentTitleBlock,
    ContentDescriptionBlock,
    Codemirror,
    TeamAttacher,
    vFormEditor,
    Icon,
    Popup,
    Tag,
    ProjectLinker,
    DeletePrompt,
    FullScreenLayout,
    LanguageSelector,
    VueSlider,
    ScreenSizeSelector,
    UniversalSelector,
    LoadingSpinner,
    vFormPublisher,
    FormNodeEditor
  },
  mixins: [vFormMixin, ThemeSetterMixinJs],
  data() {
    return {
      organizationId: null,
      lang: "Unknown",
      id: "",
      teams: [],
      project: null,
      instance: {},
      projectId: "",
      projectEditing: false,

      forceReRenderKey: 0,
      error: "",

      // popups
      showFormSettings: false,
      showProjectRemovePrompt: false,
      fullScreenMode: false,
      editorMode: "edit",
      dirty: false,
      logId: "",

      showLogReplacePrompt: false,

      config: {},
      slides: [],
      pdfGenerating: false,
      pdfReportGenerating: false,
      currentPDFPage: 1,
      totalPDFPages: 0,
      vformModes,
      showGraph: false
    };
  },
  watch: {
    fullScreenMode(val) {
      this.$store.dispatch("setNavHideStatus", val);
    },
  },
  beforeMount() {
    this.loadAssetTeams();
    this.id = this.$route.params.id;
    this.loadInstances();

    if (this.$cookies.get("vFormLanguage")) {
      // eslint-disable-next-line no-undef
      this.lang = this.$cookies.get("vFormLanguage");
    }
    this.$store.dispatch('setvFormMode', vformModes.TEST);
  },
  beforeRouteEnter(to, from, next) {
    next();
  },

  beforeRouteUpdate(to, from, next) {
    next();
  },

  beforeRouteLeave(to, from, next) {
    const $this = this;
    if ($this.dirty) {
      const answer = window.confirm($this.$t("vform.loseChangesWarning"));
      // cancel the navigation and stay on the same page
      if (!answer) return false;
    }
    next();
  },
  methods: {
    totalPages(pageNo) {
      this.totalPDFPages = pageNo;
    },
    addingPage(pageNo) {
      this.currentPDFPage = pageNo;
    },
    setPdfReportGenerating(bool) {
      this.pdfReportGenerating = bool;
    },
    setPDFGenerating(bool) {
      this.pdfGenerating = bool;
    },
    replaceLog() {
      this.$refs.form.replaceLog(true);
    },
    setScreenSize(size, customSize) {
      this.$refs.form.screenSize = size;
      this.$refs.form.customScreenSize = customSize;
    },
    setScreenOrientation(orientation) {
      this.$refs.form.screenOrientation = orientation;
    },
    setLogId(logId) {
      this.logId = logId;
    },
    setConfig(config) {
      this.config = config;
      if (this.config.version && this.config.version > 2) {
        this.$store.dispatch('loadForm', {
          id: this.$route.params.id
        })
      }
    },
    setSlides(slides) {
      console.log('setting slides: ' + slides.length)
      this.slides = slides;
    },
    setLanguage(lang) {
      this.lang = lang;
      this.$cookies.set("vFormLanguage", lang);
      this.$refs.form.addTranslation(lang);
    },
    resetError() {
      this.error = "";
    },
    setProjectLoadError() {
      this.error = this.$t("projectFilesNotAvailable");
    },
    loadAssetTeams() {
      this.$store
          .dispatch("clientLoadAssetTeams", {
            id: this.$route.params.id,
          })
          .then((data) => {
            this.teams = data;
          });
    },
    removeTag: function (tag) {
      this.$store.dispatch("removeFormTag", {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch("addFormTag", {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateTags: function (tags) {
      this.$store.dispatch("updateForm", {
        id: this.$route.params.id,
        tags: tags,
      });
    },
    loadInstances() {
      this.$store.dispatch("clientGetCrossProjectInstances", {
        filter: "assetId eq " + this.$route.params.id,
      }).then((instances) => {
        if (instances.length) {
          // vForm is connected to only one single project
          this.instance = instances[0];
          this.projectId = instances[0].projectId;
        }
        return this.$store.dispatch('loadProject', {
          id: this.projectId,
          include: ['metaValues']
        })
      }).then(proj => {
        this.organizationId = proj.organizationId;
        if(proj.metaValues) {
          const ccLightId = proj.metaValues.find(item => {
            return item.metaFieldId === SpecialUuids.CC_LIGHT_ID;
          })
          if(ccLightId && ccLightId.value && ccLightId.value !== SpecialUuids.DEFAULT_CC_LIGHT_ID) {
            console.log('found item, loading colors')
            this.loadCClightColors(ccLightId.value);
          }
        }
      });
    },
    async loadCClightColors(appId) {
      await this.$store.dispatch('clientLoadProjectMetaValues', {
        id: appId
      }).then(values => {
        const color = values.find(item => {
          return item.metaFieldId === SpecialUuids.CC_LIGHT_THEME_COLOR_METAFIELD;
        })
        if(color) {
          this.setThemeColors({themeColor: color.value ? color.value : color.defaultValue})
        }
      })
    },
    removeProject() {
      if (!this.showProjectRemovePrompt) {
        this.showProjectRemovePrompt = true;
      } else {
        this.showProjectRemovePrompt = false;
        this.error = null;
        this.$refs.projectLinker.setProjectId(null);
        this.removeProjectFromvForm(this.projectId, this.instance.id).then(() => {
          this.projectId = null;
          this.instance = null;
          this.forceReRenderKey++;
        });
      }
    },
    addProject(id) {
      this.error = null;
      return this.addProjectTovForm(id, this.$route.params.id).then((instance) => {
        this.instance = instance;
        this.projectId = this.instance.projectId;
        this.projectEditing = false;
        this.forceReRenderKey++;
        // reload asset teams so they bubble down to the vFormEditor
        this.loadAssetTeams();
      });
    },
    playSlides() {
      if (this.editorMode === "view") {
        this.editorMode = "edit";
      } else {
        this.editorMode = "view";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../scss/newdesign/newGlobal";

.form-edit-local {
  .main-content-panel {
    padding-left: unset;
    margin: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.font-size {
  padding-left: 7px;
  padding-right: 7px;
  margin: 1px;
  cursor: pointer;
  line-height: 30px;

  &.active {
    font-weight: bold;
    color: $highlight;
  }
}

.app-link {
  &:hover {
    color: $highlight;
  }
}

.vform-font-size-button {
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: transparent;
}

.font-size-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.vform-language-selector {
  z-index: 555;
}
</style>
