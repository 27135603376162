export const vFormRealms = Object.freeze({
    STEP: 'step',
    GLOBAL: 'global',
    HOTSPOT: 'hotspot',
});

/**
 * vForm variables
 * */
export const projectLoadingModes = Object.freeze({
    OFFLINE: 'offline',
    LEGACY: 'legacy',
    CURRENT: 'current',
    LOCAL_FILE: 'localFile'
});

/**
 * vForm variables
 * */
export const authStrategies = Object.freeze({
    LEGACY: 'legacy',
    DEFAULT: 'default',
    B2C: 'b2c'
});

export const ErrorCodes = Object.freeze({
    USER_BLOCKED: 4004,
    INVALID_MFA_CODE: 4005
});

export const vformLoadingModes = Object.freeze({
    OFFLINE: 'offline',
    CONTENT: 'content',
    FILE: 'file',
});

export const vFormFiles = Object.freeze({
    VIDEO_CONF: 'videoconfig.json',
    FONT_CONF: 'fonts.json',
    VFORM_CONF: 'vformConfig.json'
});

export const vFormButtonActionModes = Object.freeze({
    DEFAULT: 'default',
    RULES_BASED: 'rulesBased',
});

export const vformButtonActions = Object.freeze({
    NO_ACTION: "NoAction",
    NEXT_SLIDE: "NextSlide",
    GO_TO_SLIDE: "GoToSlide",
    GO_TO_STEP: "GoToStep",
    HISTORY_BACK: "HistoryBack",
    FIRST_SLIDE: "FirstSlide",
    LAST_SLIDE: "LastSlide",
    FIRST_STEP: "FirstStep",
    LAST_STEP: "LastStep",
    CHECKOUT: "Checkout",
});

export const vformTargetTypes = Object.freeze({
    STEP: "step",
    SLIDE: "slide",
    PROJECT: "project",
    NO_TARGET: "none"
});

export const newProjectStructureVersion = '3.0.3'; // 3.0.3 and newer are the new structure


/**
 * meta field variables
 * */
export const MetaFieldTypes = Object.freeze({
    Boolean: 'bool',
    String: 'str',
    Uuid: 'uuid',
    Integer: 'int',
    Float: 'float',
    Color: 'clr',
    Vector2: 'v2',
    Vector3: 'v3',
    Vector4: 'v4',
    xForm2: 'xform2',
    xForm3: 'xform3', // position, rotation, scale (3x v3)
    Enum: 'enum',
    Date: 'date',
    Time: 'time',
    DateTime: 'datetime',
    Binary: 'binary',
    Uri: 'uri',
    Email: 'email',
    Range: 'range',
    Flags: 'flags',
    Password: 'password',
    Ip: 'ip',
    Ipv6: 'ipv6',
    List: 'lst',
    Text: 'txt',
    Json: 'json',
    DataSet: 'dataset',
    DataRow: 'datarow',
    Asset: 'asset',
    Project: 'project'
});

export const MetaFieldStores = Object.freeze({
    feed: {
        name: 'Datasource',
        subType: 'feed'
    },
    sensor: {
        name: 'Datasource',
        subType: 'sensor'
    },
    table: 'Dataset',
    dataset: 'Dataset',
    project: 'Project',
    app: 'App',
    scene: 'Scene',
    model: 'Sfxdata',
    assembly: 'Sfxdata',
    node: 'Sfxdata',
    media: 'Media',
    user: 'User',
    organization: 'Organization',
    form: 'Form',
    qrcode: 'Qrcodes',
    material: 'Material',
    texture: 'Texture',
    webapp: 'Webapp',
    webappProject: 'App',
});

export const VectorConfigs = Object.freeze({
    v2: {
        fieldsets: [['x', 'y']],
    },
    v3: {
        fieldsets: [['x', 'y', 'z']],
    },
    v4: {
        fieldsets: [['x', 'y', 'z', 'w']],
    },
    xform2: {
        fieldsets: [['x', 'y'], ['x', 'y'], ['x', 'y']],
    },
    xform3: {
        fieldsets: [['x', 'y', 'z'], ['x', 'y', 'z'], ['x', 'y', 'z']],
    },
});


export const contentTypes = Object.freeze({
    app:  'Project',
    webapp: 'Project',
    model: 'Asset',
    helper: 'Asset',
    assembly: 'Asset',
    media: 'Asset',
    project: 'Project',
    material: 'Asset',
    filter: 'Asset',
    team: 'Team',
    scene: 'Asset',
    sfxdata: 'Asset',
    instance: 'Instance',
    form: 'Asset',
    font: 'Asset',
    texture: 'Asset',
    template: 'Asset'
});

export const ProjectTypes = Object.freeze({
    vStage:  'vstage',
    WebApp: 'webapp',
    App: 'app',
    Assembly: 'assembly',
    Library: 'lib'
});

export const UserRoles = Object.freeze({
    Viewer: 'viewer',
    Editor:  'editor',
    Manager: 'manager',
    Admin: 'admin',
    None: 'none'
});

export const OrganizationVisibility = Object.freeze({
    0: 'private',
    1:  'organizationLevel',
    2: 'public',
});

export const PlainLanguages = Object.freeze({
    'Unknown': {name: 'Unknown', active: true },
    'English': {name: 'English', active: true },
    'German': {name: 'German', active: true },
    'French': {name: 'French', active: true },
    'Italian': {name: 'Italian', active: true },
    'Spanish': {name: 'Spanish', active: true },
    'Portuguese': {name: 'Portuguese', active: true },
    'Greek': {name: 'Greek', active: true },
    'Dutch': {name: 'Dutch', active: true },
    'Swedish': {name: 'Swedish', active: true },
    'Norwegian': {name: 'Norwegian', active: true },
    'Polish': {name: 'Polish', active: true },
    'SerboCroatian': {name: 'SerboCroatian', active: true },
    'Romanian': {name: 'Romanian', active: true },
    'Turkish': {name: 'Turkish', active: true },
    'Russian': {name: 'Russian', active: true },
    'Ukrainian': {name: 'Ukrainian', active: true }
});

export const Languages = Object.freeze({
    'af': { name: 'Afrikaans', active: false},
    'af-ZA': { name: 'Afrikaans - South Africa', active: false},
    'ar': { name: 'Arabic', active: false},
    'ar-AE': { name: 'Arabic - United Arab Emirates', active: false},
    'ar-BH': { name: 'Arabic - Bahrain', active: false},
    'ar-DZ': { name: 'Arabic - Algeria', active: false},
    'ar-EG': { name: 'Arabic - Egypt', active: false},
    'ar-IQ': { name: 'Arabic - Iraq', active: false},
    'ar-JO': { name: 'Arabic - Jordan', active: false},
    'ar-KW': { name: 'Arabic - Kuwait', active: false},
    'ar-LB': { name: 'Arabic - Lebanon', active: false},
    'ar-LY': { name: 'Arabic - Libya', active: false},
    'ar-MA': { name: 'Arabic - Morocco', active: false},
    'ar-OM': { name: 'Arabic - Oman', active: false},
    'ar-QA': { name: 'Arabic - Qatar', active: false},
    'ar-SA': { name: 'Arabic - Saudi Arabia', active: false},
    'ar-SY': { name: 'Arabic - Syria', active: false},
    'ar-TN': { name: 'Arabic - Tunisia', active: false},
    'ar-YE': { name: 'Arabic - Yemen', active: false},
    'az': { name: 'Azeri', active: false},
    'az-Cyrl': { name: 'Azeri (Cyrillic)', active: false},
    'az-Cyrl-AZ': { name: 'Azeri (Cyrillic) - Azerbaijan', active: false},
    'az-Latn': { name: 'Azeri (Latin)', active: false},
    'az-Latn-AZ': { name: 'Azeri (Latin) - Azerbaijan', active: false},
    'be': { name: 'Belarusian', active: false},
    'be-BY': { name: 'Belarusian - Belarus', active: false},
    'bg': { name: 'Bulgarian', active: false},
    'bg-BG': { name: 'Bulgarian - Bulgaria', active: false},
    'bs': { name: 'Bosnian', active: false},
    'bs-Cyrl': { name: 'Bosnian (Cyrillic)', active: false},
    'bs-Cyrl-BA': { name: 'Bosnian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'bs-Latn': { name: 'Bosnian (Latin)', active: false},
    'bs-Latn-BA': { name: 'Bosnian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'ca': { name: 'Catalan', active: false},
    'ca-ES': { name: 'Catalan - Catalan', active: false},
    'cs': { name: 'Czech', active: false},
    'cs-CZ': { name: 'Czech - Czech Republic', active: false},
    'da': { name: 'Danish', active: false},
    'da-DK': { name: 'Danish - Denmark', active: false},
    'de': { name: 'German', active: true},
    'de-AT': { name: 'German - Austria', active: false},
    'de-CH': { name: 'German - Switzerland', active: false},
    'de-DE': { name: 'German - Germany', active: false},
    'de-LI': { name: 'German - Liechtenstein', active: false},
    'de-LU': { name: 'German - Luxembourg', active: false},
    'dv': { name: 'Dhivehi', active: false},
    'dv-MV': { name: 'Dhivehi - Maldives', active: false},
    'el': { name: 'Greek', active: false},
    'el-GR': { name: 'Greek - Greece', active: false},
    'en': { name: 'English', active: true},
    'en-AU': { name: 'English - Australia', active: false},
    'en-BZ': { name: 'English - Belize', active: false},
    'en-CA': { name: 'English - Canada', active: false},
    'en-029': { name: 'English - Caribbean', active: false},
    'en-GB': { name: 'English - United Kingdom', active: false},
    'en-IE': { name: 'English - Ireland', active: false},
    'en-JM': { name: 'English - Jamaica', active: false},
    'en-NZ': { name: 'English - New Zealand', active: false},
    'en-PH': { name: 'English - Philippines', active: false},
    'en-TT': { name: 'English - Trinidad and Tobago', active: false},
    'en-US': { name: 'English - United States', active: false},
    'en-ZA `': { name: 'English - South Africa', active: false},
    'en-ZW': { name: 'English - Zimbabwe', active: false},
    'es': { name: 'Spanish', active: false},
    'es-AR': { name: 'Spanish - Argentina', active: false},
    'es-BO': { name: 'Spanish - Bolivia', active: false},
    'es-CL': { name: 'Spanish - Chile', active: false},
    'es-CO': { name: 'Spanish - Colombia', active: false},
    'es-CR': { name: 'Spanish - Costa Rica', active: false},
    'es-DO': { name: 'Spanish - Dominican Republic', active: false},
    'es-EC': { name: 'Spanish - Ecuador', active: false},
    'es-ES': { name: 'Spanish - Spain', active: false},
    'es-GT': { name: 'Spanish - Guatemala', active: false},
    'es-HN': { name: 'Spanish - Honduras', active: false},
    'es-MX': { name: 'Spanish - Mexico', active: false},
    'es-NI': { name: 'Spanish - Nicaragua', active: false},
    'es-PA': { name: 'Spanish - Panama', active: false},
    'es-PE': { name: 'Spanish - Peru', active: false},
    'es-PR': { name: 'Spanish - Puerto Rico', active: false},
    'es-PY': { name: 'Spanish - Paraguay', active: false},
    'es-SV': { name: 'Spanish - El Salvador', active: false},
    'es-UY': { name: 'Spanish - Uruguay', active: false},
    'es-VE': { name: 'Spanish - Venezuela', active: false},
    'et': { name: 'Estonian', active: false},
    'et-EE': { name: 'Estonian - Estonia', active: false},
    'eu': { name: 'Basque', active: false},
    'eu-ES': { name: 'Basque - Basque', active: false},
    'fa': { name: 'Farsi', active: false},
    'fa-IR': { name: 'Farsi - Iran', active: false},
    'fi': { name: 'Finnish', active: false},
    'fi-FI': { name: 'Finnish - Finland', active: false},
    'fo': { name: 'Faroese', active: false},
    'fo-FO': { name: 'Faroese - Faroe Islands', active: false},
    'fr': { name: 'French', active: true},
    'fr-BE': { name: 'French - Belgium', active: false},
    'fr-CA': { name: 'French - Canada', active: false},
    'fr-CH': { name: 'French - Switzerland', active: false},
    'fr-FR': { name: 'French - France', active: false},
    'fr-LU': { name: 'French - Luxembourg', active: false},
    'fr-MC': { name: 'French - Monaco', active: false},
    'gl': { name: 'Galician', active: false},
    'gl-ES': { name: 'Galician - Galician', active: false},
    'gu': { name: 'Gujarati', active: false},
    'gu-IN': { name: 'Gujarati - India', active: false},
    'he': { name: 'Hebrew', active: false},
    'he-IL': { name: 'Hebrew - Israel', active: false},
    'hi': { name: 'Hindi', active: false},
    'hi-IN': { name: 'Hindi - India', active: false},
    'hr': { name: 'Croatian', active: false},
    'hr-HR': { name: 'Croatian - Croatia', active: false},
    'hu': { name: 'Hungarian', active: false},
    'hu-HU': { name: 'Hungarian - Hungary', active: false},
    'hy': { name: 'Armenian', active: false},
    'hy-AM': { name: 'Armenian - Armenia', active: false},
    'id': { name: 'Indonesian', active: false},
    'id-ID': { name: 'Indonesian - Indonesia', active: false},
    'is': { name: 'Icelandic', active: false},
    'is-IS': { name: 'Icelandic - Iceland', active: false},
    'it': { name: 'Italian', active: true},
    'it-CH': { name: 'Italian - Switzerland', active: false},
    'it-IT': { name: 'Italian - Italy', active: false},
    'iu': { name: 'Inuktitut', active: false},
    'iu-Latn': { name: 'Inuktitut (Latin)', active: false},
    'iu-Latn-CA': { name: 'Inuktitut (Latin) - Canada', active: false},
    'ja': { name: 'Japanese', active: false},
    'ja-JP': { name: 'Japanese - Japan', active: false},
    'ka': { name: 'Georgian', active: false},
    'ka-GE': { name: 'Georgian - Georgia', active: false},
    'kk': { name: 'Kazakh', active: false},
    'kk-KZ': { name: 'Kazakh - Kazakhstan', active: false},
    'kn': { name: 'Kannada', active: false},
    'kn-IN': { name: 'Kannada - India', active: false},
    'ko': { name: 'Korean', active: false},
    'kok': { name: 'Konkani', active: false},
    'kok-IN': { name: 'Konkani - India', active: false},
    'ko-KR': { name: 'Korean - Korea', active: false},
    'ky': { name: 'Kyrgyz', active: false},
    'ky-KG': { name: 'Kyrgyz - Kyrgyzstan', active: false},
    'lt': { name: 'Lithuanian', active: false},
    'lt-LT': { name: 'Lithuanian - Lithuania', active: false},
    'lv': { name: 'Latvian', active: false},
    'lv-LV': { name: 'Latvian - Latvia', active: false},
    'mk': { name: 'Macedonian', active: false},
    'mk-MK': { name: 'Macedonian - Former Yugoslav Republic of Macedonia', active: false},
    'mn': { name: 'Mongolian', active: false},
    'mn-MN': { name: 'Mongolian - Mongolia', active: false},
    'mr': { name: 'Marathi', active: false},
    'mr-IN': { name: 'Marathi - India', active: false},
    'ms': { name: 'Malay', active: false},
    'ms-BN': { name: 'Malay - Brunei', active: false},
    'ms-MY': { name: 'Malay - Malaysia', active: false},
    'nb-NO': { name: 'Norwegian (Bokm?l) - Norway', active: false},
    'nl': { name: 'Dutch', active: false},
    'nl-BE': { name: 'Dutch - Belgium', active: false},
    'nl-NL': { name: 'Dutch - The Netherlands', active: false},
    'nn-NO': { name: 'Norwegian (Nynorsk) - Norway', active: false},
    'no': { name: 'Norwegian', active: false},
    'pa': { name: 'Punjabi', active: false},
    'pa-IN': { name: 'Punjabi - India', active: false},
    'pl': { name: 'Polish', active: false},
    'pl-PL': { name: 'Polish - Poland', active: false},
    'pt': { name: 'Portuguese', active: false},
    'pt-BR': { name: 'Portuguese - Brazil', active: false},
    'pt-PT': { name: 'Portuguese - Portugal', active: false},
    'ro': { name: 'Romanian', active: true},
    'ro-RO': { name: 'Romanian - Romania', active: false},
    'ru': { name: 'Russian', active: true},
    'ru-RU': { name: 'Russian - Russia', active: false},
    'sa': { name: 'Sanskrit', active: false},
    'sa-IN': { name: 'Sanskrit - India', active: false},
    'sk': { name: 'Slovak', active: false},
    'sk-SK': { name: 'Slovak - Slovakia', active: false},
    'sl': { name: 'Slovenian', active: false},
    'sl-SI': { name: 'Slovenian - Slovenia', active: false},
    'sq': { name: 'Albanian', active: false},
    'sq-AL': { name: 'Albanian - Albania', active: false},
    'mn-Cyrl': { name: 'Mongolian (Cyrillic)', active: false},
    'sr': { name: 'Serbian', active: false},
    'sr-Cyrl': { name: 'Serbian (Cyrillic)', active: false},
    'sr-Cyrl-BA': { name: 'Serbian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'sr-Cyrl-CS': { name: 'Serbian (Cyrillic) - Serbia', active: false},
    'sr-Latn': { name: 'Serbian (Latin)', active: false},
    'sr-Latn-BA': { name: 'Serbian (Latin) - Bosnia and Herzegovina', active: false},
    'sr-Latn-CS': { name: 'Serbian (Latin) - Serbia', active: false},
    'sv': { name: 'Swedish', active: true},
    'sv-FI': { name: 'Swedish - Finland', active: false},
    'sv-SE': { name: 'Swedish - Sweden', active: false},
    'sw': { name: 'Swahili', active: false},
    'sw-KE': { name: 'Swahili - Kenya', active: false},
    'syr': { name: 'Syriac', active: false},
    'syr-SY': { name: 'Syriac - Syria', active: false},
    'ta': { name: 'Tamil', active: false},
    'ta-IN': { name: 'Tamil - India', active: false},
    'te': { name: 'Telugu', active: false},
    'te-IN': { name: 'Telugu - India', active: false},
    'th': { name: 'Thai', active: false},
    'th-TH': { name: 'Thai - Thailand', active: false},
    'tr': { name: 'Turkish', active: false},
    'tr-TR': { name: 'Turkish - Turkey', active: false},
    'tt': { name: 'Tatar', active: false},
    'tt-RU': { name: 'Tatar - Russia', active: false},
    'uk': { name: 'Ukrainian', active: false},
    'uk-UA': { name: 'Ukrainian - Ukraine', active: false},
    'ur': { name: 'Urdu', active: false},
    'ur-PK': { name: 'Urdu - Pakistan', active: false},
    'uz': { name: 'Uzbek', active: false},
    'uz-Cyrl': { name: 'Uzbek (Cyrillic)', active: false},
    'uz-Latn': { name: 'Uzbek (Latin)', active: false},
    'uz-Cyrl-UZ': { name: 'Uzbek (Cyrillic) - Uzbekistan', active: false},
    'uz-Latn-UZ': { name: 'Uzbek (Latin) - Uzbekistan', active: false},
    'vi': { name: 'Vietnamese', active: false},
    'vi-VN': { name: 'Vietnamese - Vietnam', active: false},
    'zh': { name: 'Chinese', active: false},
    'zh-CHT': { name: 'Chinese (Traditional)', active: false},
    'zh-CHS': { name: 'Chinese (Simplified)', active: false},
    'zh-CN': { name: 'Chinese - China', active: false},
    'zh-HK': { name: 'Chinese - Hong Kong SAR', active: false},
    'zh-MO': { name: 'Chinese - Macao SAR', active: false},
    'zh-SG': { name: 'Chinese - Singapore', active: false},
    'zh-TW': { name: 'Chinese - Taiwan', active: false},
    'zh-Hans': { name: 'Chinese (Simplified, Hans)', active: false},
    'zh-Hant': { name: 'Chinese (Traditional, Hans)', active: false},
});

export const AdminData = {
    vHubOrganizationId: '7bc33721-622f-4b87-b9a4-333170f9fa93',
    vHubAdminUserId: '8a26c307-0b45-4420-b3cc-16db98bbfe7d'
};

export const stepFileStati = [
    'initiallyUploaded',
    'transferringData',
    'transferComplete',
    'convertingData',
    'deliveringConvertedData',
    'processingFinished'
];

export const TaskManagerTaskTypes = Object.freeze({
    EXTRACT: 'extract',
    UPDATE: 'update',
    FILTER: 'filter',
    REIMPORT: 'reimport',
    DATAPROCESSING: 'dataprocessing',
    INSTANCE_UPDATING: 'instanceUpdating',
    NOTASK: 'notask',
    VERSION_CREATE: 'createVersion',
    VERSION_RESTORE:'restoreVersion',
    VERSION_DELETE: 'deleteVersion',
    APPLY_MATERIAL: 'applyMaterial',
    CONVERT_HELPER: 'convertHelper',
    CONVERT_ASSEMBLY: 'convertAssembly'
});

/**
 * The allowed formats for meta data enrichment via XML or similar file which will be saved
 * in the assembly's instances metaSets
 * The key of the object must match the name of the pre or postprocessor file in vhub
 * */
export const metaDataFileFormats = Object.freeze({
    'xmlWindchillSearchRes': {
        format: 'xml',
        name: 'Windchill Search Result'
    },
    'csvParser': {
        format: 'csv',
        name: 'CSV'
    },
    'genericXMLParser': {
        format: 'xml',
        name: 'Generic XML'
    }
});

/***
 *
 * */
export const typeMatching = Object.freeze({
    'mp4': 'video',
    //'mov': 'video',
    'png' : 'image',
    'jpg' : 'image',
    'gif' : 'image',
    'jpeg' : 'image',
    'pdf' : 'pdf',
    'fbx' :  'model',
    'glb': 'model',
    'docx': 'word',
    'doc' : 'word',
    'webm' : 'video',
    'mp3' : 'audio',
    'aiff' : 'audio',
    'aif' : 'audio',
    'ogg' : 'audio',
    'wav' : 'audio',
    'step' : 'model',
    'stp': 'model',
    'svg': 'image',
});

export const previewTypes = Object.freeze({
    'model': 'Three',
    'pdf': 'PDFPreview',
    'svg': 'SVGPreview',
    'image': 'ImagePreview',
    'audio': 'AudioPreview',
    'video': 'VideoPreview',
});

export const mimeTypes = Object.freeze({
    ['image/jpeg']: 'image',
    ['image/jpg']: 'image',
    ['image/png']: 'image',
    ['image/gif']: 'image',
    ['image/svg+xml']: 'svg',
    ['application/pdf']: 'multipart',
    ['video/mp4']: 'video',
    ['video/webm']: 'video',
    ['audio/webm']: 'audio',
    ['audio/mp3']: 'audio',
    ['audio/ogg']: 'audio',
    ['audio/wav']: 'audio',
    ['audio/aiff']: 'audio',
    ['audio/aif']: 'audio',
});

export const defaultKeys = Object.freeze({
    'png': 'preview.png',
    'jpg': 'preview.jpg',
    'jpeg': 'preview.jpg',
    'pdf': 'pdfdoc.pdf',
    'fbx' : 'lod0.fbx',
    'glb': 'lod0.glb'
});

export const allowPreviewsFor = [
    'mp4', 'png', 'jpg', 'gif', 'jpeg',
    'pdf', 'fbx', 'mp3', 'wav', 'svg', 'glb'
];

export const textFileTypes = [
    'json', 'txt'
];

export const mediaCategories = {
    'audio': 1,
        'font': 2,
        'image': 3,
        'message': 4,
        'model': 5,
        'multipart': 6,
        'pdf': 6,
        'text': 7,
        'video': 8,
};

export const metaSetUpdateModes = [
    'extend',
    'overwrite',
    'reset'
];

export const available3DOutputFormats = Object.freeze({
    fbx: "Autodesk",
    glb: "glb",
});

export const available3DFiles = Object.freeze({
    fbx: "Autodesk",
    '3ds': "3D Studio Mesh",
    abc: "Alembic",
    dae: "Autodesk Collada",
    dwg: "AutoCAD Drawing",
    ige: "IGES",
    iges: "IGES",
    igs: "IGES",
    ipt: "Autodesk Inventor",
    iam: "Autodesk Inventor",
    jt: " Siemens JT",
    obj: "Wavefront OBJ",
    asm: "ProE",
    prt: "ProE",
    skp: "SketchUp",
    sldasm: "SolidWorks",
    sldprt: "SolidWorks",
    stl: "StereoLitho",
    stp: "Step",
    step: "Step",
    wrl: "VRML",
    max: '3DS Max',
    zip: 'ZIP'
});

// those will be used to select the root node, do not remove any of those
export const importable3DFiles = Object.freeze({
    //asm: "ProE",
    //sldasm: "SolidWorks",
    fbx: "Autodesk",
    jt: "Siemens JT",
    dae: "Autodesk Collada",
    max: '3DS Max',
    prt: "ProE",
    sldprt: "SolidWorks",
    step: "Step",
    stp: "Step",
    zip: 'ZIP',
    obj: "Obj",
    ipt: "Autodesk Inventor",
    dwg: "AutoCAD Drawing",
    ige: "IGES",
    iges: "IGES",
    skp: "SketchUp",
    //ige: "IGES",
});


/**
* which files can be used as root files
* */
export const importableRootFiles = Object.freeze({
    asm: "ProE",
    sldasm: "SolidWorks",
    fbx: "Autodesk",
    jt: "Siemens JT",
    max: '3DS Max',
    prt: "ProE",
    step: "Step",
    stp: "Step",
    zip: 'ZIP',
    obj: "Obj",
    CATProduct: "Cat Product"
    //ige: "IGES",
});

export const SpecialUuids = Object.freeze({
    PROJECT_VERSION_METAFIELD: '5c9a2c74-5a3b-43bb-8bac-60c14e5325e3',
    LOGGER_DATASET: '23d1648c-3afe-4fe8-8da2-4b0900247ba9',
    LICENCE_METASET: 'c02ee4af-2438-4f0e-a91d-a7c1d76f2937',
    SFX_PROJECT_WEBAPP_METASET: 'f16fc02e-f77f-4476-a7ba-2ccd808ebfb6',
    NODE: 'b2ecddce-ea35-41b1-96be-305f8b88d3d0',
    VHUB_ORGANIZATION: '7bc33721-622f-4b87-b9a4-333170f9fa93',
    VHUB_SYSTEM_PUBLIC: '849ec1b4-d39a-4fb4-ae73-ff5e3fb2b41f',
    VHUB_SYSTEM_PUBLIC_USER: 'f2b03d52-ade9-446f-b16b-0caf72aa1274',
    ASSEMBLY_METASET: '0ff675f7-d539-497b-921b-29d865d9c936',
    VSTAGE_WINDOWS: 'acf22edb-6c80-4d17-b43a-05ef1a9001ac',
    VSTAGE_IOS: 'b5d25df4-3b21-4a45-8689-0f20681e5270',
    VSTAGE_ANDROID: '9c8140be-6658-43b2-9827-62dad412d497',
    VSTAGE_OSX: 'e6d8d676-4b1b-4d71-8511-864deed212c0',
    VSTAGE_WINDOWS_NO_INSTALLER: '10f4fbec-b6a8-43a6-bfeb-8a2f3f660971',
    VSTAGE_OSX_NO_INSTALLER: '27412dc3-0161-40e5-a439-2e6a3adff4c8',
    TASKMANAGER_DATASET: '6ec87ac9-1074-4579-a9df-083ab8ff53c8',
    TASKMANAGER_ORGANIZATION: '4092ae55-2937-46d5-9518-0caa570e5c8c',
    TASKMANAGER_USER: 'd6f59a81-d30c-408d-9e54-66f0faba85d5',
    VHUB_GLOBAL_TEAM: 'c6665612-b335-4426-ab0d-dfcff359c5e8',
    IFRAME_WHITELISTING_METAFIELD: '53df9c53-564a-4a23-8eae-e5d50859d780',
    VFORMS_PUBLISHED_METAFIELD: '3e3c02a6-682e-4502-a52c-43a65def8d8f',
    MATERIAL_METAFIELD: 'c2b71aad-0cbf-412f-9f2c-44b1fa46b85e',
    ASSET_LIMIT_METAFIELD: '27d7f28a-4964-4ce1-b5ed-35e72d6e3de7',
    STORAGE_LIMIT_METAFIELD: '27d7f28a-4964-4ce1-b5ed-35e72d6e3de7',
    VIEWER_LIMIT_METAFIELD: '88b5339e-7ce6-4f7f-a845-25dcce75b447',
    EDITOR_LIMIT_METAFIELD: '67d915a6-d2a3-4dd5-9ad3-3ad8917d504d',
    ADMIN_LIMIT_METAFIELD: '5b4e176e-6758-4820-bd51-0b75399e598f',
    MATERIAL_METASETID: '6af9b366-3de2-4448-9255-1345fb7bc264',
    APP_PANEL_WEBAPP: '729a0de9-fe22-4854-aa1b-3ef2d0d4cd99',
    APP_PANEL_WEBAPP_BETA: '70f5f9f9-c588-4f8b-ad78-3bcdd567ccde',
    ASSEMBLY_UNIT_METAFIIELD: '6390efaa-fc15-4140-ac66-4cc45ffa6051',
    CC_LIGHT_ID: 'c1fcb25f-71a7-4fc6-b78b-b5ea9dc2c615',
    DEFAULT_CC_LIGHT_ID: '4fc86f0e-0308-4113-841a-b9715057003b',
    CC_LIGHT_THEME_COLOR_METAFIELD: 'bb3176a2-79da-4e79-b621-ff85d188c1cd',
    CC_LIGHT_APP_PKG: '5446b563-6e12-41ef-94eb-aec90b65c7f7',
    CC_LIGHT_APP_PKG_BETA: 'e838322c-d599-4fa4-a51c-30a305179047',
    CC_LIGHT_APP_PKG_ALPHA: '58d2b92c-9e7d-4d5f-9c24-00ddbb837265',
    RULES_META_FIELD: 'f13cf8d5-b01b-4c76-a719-6059e42a98ee',
    DEFAULT_TILING_META_FIELD: '91d638fb-067a-4c23-8249-65edf10a14e9',
    CONTEXT_META_FIELD: 'fd779a63-7a62-470e-8bdd-2e7c0d1827ca',
    REGEX_METASET: 'bc6ca818-9b32-49b3-8e3a-583e3ffdb987'
});

export const PivotPositions = Object.freeze({
    SOURCE: 'source',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    BASE: 'base',
    FRONT: 'front',
    BACK: 'back',
});

/**
 * Project state
 * @enum {string}
 * @property ACTIVE - Project is active (`a`)
 * @property INACTIVE - Project is inactive (`i`)
 * @property PROCESSING - Project is processing (`p`)
 * @property HIDDEN - Project is hidden but still usable (`h`)
 */
export const ProjectStates = Object.freeze({
    ACTIVE:   'a',
    INACTIVE: 'i',
    PROCESSING: 'p',
    HIDDEN: 'h'
});

export const VMStates = Object.freeze({
    ACTIVE: 'a',
    MAINTENANCE: 'm'
});

export const VMLogStates = Object.freeze({
    STARTED: 's',
    FINISHED: 'f',
    ERRORED: 'e',
});

export const BillingStates = Object.freeze({
    NONE: 'none',
    BILLED: 'billed',
    PAYED: 'payed',
    DEMAND: 'demand', // Mahnung
    DROPPED: 'dropped',
});

/**
 * Asset state
 * @enum {string}
 * @property ACTIVE - Asset is active (`a`)
 * @property INACTIVE - Asset is inactive (`i`)
 * @property PROCESSING - Asset is processing (`p`)
 * @property HIDDEN - Asset is hidden but still usable (`h`)
 */
export const AssetStates = Object.freeze({
    ACTIVE: 'a',
    INACTIVE: 'i',
    PROCESSING: 'p',
    HIDDEN: 'h'
});

/**
 * Those are the simplified steps for the pipeline (EXTRACT) which will be shown to the user in the progress section
 * in the control center
 * (used for vmlog)
 * **/
export const PipelineSimplifiedExtractSteps = Object.freeze({
    STARTED: 'Started',
    DATA_ARRIVED_ON_VM: 'ArrivedOnVm',
    CAD_IMPORT: 'Importing',
    SINGLE_EXTRACT: 'SingleExtractProgress',
    GROUP_EXTRACT: 'GroupExtractProgress',
    TRANSFER_DATA_TO_VHUB: 'TransferDataToVHub',
    CREATING_ASSETS: 'CreatingAssets',
    CREATING_INSTANCES: 'CreatingInstances',
    ATTACHING_METADATA: 'AttachingMetadata',
    SETTING_METAVALUES: 'SettingMetaValues',
    SETTING_CUSTOM_META_VALUES: 'SettingCustomMetaValues',
    FINISHED: 'Finished',
});

/**
 * Those are the simplified steps for the pipeline (FILTER) which will be shown to the user in the progress section
 * in the control center
 * (used for vmlog)
 * **/
export const PipelineFilterSteps = Object.freeze({
    STARTED: 'Started',
    DATA_ARRIVED_ON_VM: 'ArrivedOnVm',
    CAD_IMPORT: 'Importing',
    MODIFY_3D_DATA: 'Modify3dData',
    TRANSFER_DATA_TO_VHUB: 'TransferDataToVHub',
    FINISHED: 'Finished',
});

/**
 * Asset types
 * @enum {string}
 * @property UNKNOWN - Unknown asset type, only used for temp. creation
 * @property MEDIA - Media asset like image, docs, video, audio
 * @property TEXT - Text asset
 * @property FEED - Feed
 * @property WEBAPP - Web application package
 * @property SENSOR - Senor asset
 * @property HELPER - vHelper
 * @property SCENERY - vStage scenery
 * @property NODE - 3D node
 * @property SKELETON - 3D blueprint
 * @property MODEL - 3D object
 * @property ASSEMBLY - 3D assembly artifact
 * @property FORM - vStage Form
 * @property MATERIAL - Material asset
 * @property QRCODE - QR code asset
 * @property LINK - External data link
 * @property REPORT - vReport object
 */
export const AssetTypes = Object.freeze({
    MEDIA:    'media',
    TEXT:     'text',
    FEED:     'feed',
    WEBAPP:   'webapp',
    SENSOR:   'sensor',
    HELPER:   'helper',
    SCENERY:  'scenery',
    NODE:     'node',
    MODEL:    'model',
    ASSEMBLY: 'assembly',
    FORM:     'form',
    MATERIAL: 'material',
    QRCODE:   'qrcode',
    LINK:     'link',
    REPORT:   'report',
    FILTER:   'filter',
    REGEX: 'regex'
});

export const RegexContexts = Object.freeze({
    NONE: 'none',
    SFX_UPDATE: 'sfx.update',
});

export const SchemaNames = Object.freeze({
    MATERIAL_MAPPER: 'sfx.materialMapper',
});

export const updatableInstanceFields = Object.freeze({
    /*EXTERNAL_ID: {
        key: 'externalId',
        name: 'External ID (not unique)',
    },*/
    DISPLAY_NAME: {
        key: 'displayName',
        name: 'Display Name',
        description: 'Only for better readability'
    },
    /*NAME: {
        key: 'name',
        name: 'Name',
        description: 'Relevant for updates'
    },*/
    /*REFERENCE_ID: {
        key: 'referenceId',
        name: 'Reference ID (unique)'
    }*/
});

export const vFormControls = Object.freeze({
    FORM_SUMMARY: 'FormSummary',
    SHORT_ANSWER: 'Short Answer',
    CHECKBOXES: 'Multiple-Choice',
    CHECKBOXES2: 'Checkboxes',
    RADIOBUTTONS: 'Radiobuttons',
    SEPARATOR: 'Separator',
    HEADLINE: 'Headline',
    SLIDE_CONTROL: 'SlideControl',
    QR_CODE: 'QR-Code',
    TEXT: 'Text',
    PROJECT_SWITCH: 'Project-Switch',
    IMAGE: 'Image',
    STEP_CONTROL: 'StepControl',
    WEBSITE: 'Website',
    META_LIST_FILTER: 'Meta-List-Filter',
    MEDIA_LOADER: 'Media-Loader',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    BUTTON: 'Button',
    VERTICAL_SPACE: 'Vertical Space',
    VIDEO: 'Video',
    SENSOR: 'Sensor',
    AUDIO: 'Audio',
    PDF: 'Pdf',
    PART_INFO: 'Part Info',
    CODE_SNIPPET: "Code Snippet",
    LIST_MATCH: "List Match",
    LIST_MATCH_TEXT_INPUT: "List Match Text Input",
    PROGRESS_TRACKER: "Progress Tracker"
});

export const placeholders = Object.freeze({
    LONG: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
    SHORT: 'Lorem ipsum dolor sit amet',
    EMBED: 'HTML / Iframe embed ..'
});

export const BrowserTypes = Object.freeze({
    VSTAGE: 'vStage',
    DEFAULT: 'default',
    WEBAPP: 'Webapp',
});
export const textureFileTypes = Object.freeze({
    JPEG: 'jpeg',
    JPG: 'jpg',
    PNG: 'png',
    TGA: 'tga',
    BMP: 'bmp',
    TIF: 'tif',
    TIFF: 'tiff',
});

/**
 *
 * */
export const screenSizes = Object.freeze({
    IPAD_AIR: {
        name: "iPad Air",
        height: 820,
        width: 1180,
        hasLandscape: true,
        hasPortrait: true
    },
    IPAD_MINI: {
        name: "iPad Mini",
        height: 768,
        width: 1024,
        hasLandscape: true,
        hasPortrait: true
    },
    SURFACE_DUO: {
        name: "Surface Duo",
        width: 720,
        height: 540,
        hasLandscape: true,
        hasPortrait: true
    },
    IPHONE_XR: {
        name: "iPhone XR",
        width: 896,
        height: 414,
        hasLandscape: true,
        hasPortrait: true
    }
});

export const footerLinks = Object.freeze({
    DOCUMENTATION_LINK: 'https://vhub-documentation.azurewebsites.net/'
});

export const extractComplexityLevels = Object.freeze({
    '-10': 'Ultra low, this might reduce in a lightweight, but crude model',
    '-9': 'Very low 1, this might reduce in a lightweight, but crude model',
    '-8': 'Very low 2, this might reduce in a lightweight, but crude model',
    '-7': 'Very low 3',
    '-6': 'Reduced',
    '-5': 'Optimal (default)',
    '-4': 'Medium 1',
    '-3': 'Medium 2',
    '-2': 'Medium 3',
    '-1': 'Medium 4',
    '0': 'High 1',
    '1': 'High 2',
    '2': 'High 3',
    '3': 'Very high 1',
    '4': 'Very high 2',
    '5': 'Ultra high 1 (this might result in a heavy model)',
    '6': 'Ultra high 1 (this might result in a heavy model)',
    '7': 'Ultra high 2 (this might result in a heavy model)',
    '8': 'Ultra high 3 (this might result in a heavy model)',
    '9': 'Extremely high, this might result in a very heavy model',
    '10': 'Extremely high, this might result in a very heavy model',
})

export const filterModifiers = Object.freeze({
        'greatherThan': 'gt',
        'lessThan': 'lt',
        'equals': 'eq',
        'contains': 'like',
})

export const lodExport = Object.freeze({
    0: 'Maximum quality only',
    1: 'Maximum and medium quality',
    2: 'Maximum, medium and low quality',
    3: 'Maximum, medium, low quality and placeholder'
})

export const lodComplexity = Object.freeze({
    'lod0.glb': 'Maximum Quality GLB',
    'lod1.glb': 'Reduction Level 1 GLB',
    'lod2.glb': 'Reduction Level 2 GLB',
    'lod0.fbx': 'Maximum Quality FBX',
    'lod1.fbx': 'Reduction Level 1 FBX',
    'lod2.fbx': 'Reduction Level 2 FBX',
})

export const fontCuts = Object.freeze({
        NORMAL: 'normal',
        LIGHT: 'light',
        MEDIUM: 'medium',
        BOLD: 'bold',
        EXTRABOLD: 'extrabold',
        ITALIC: 'italic'
})

export const supportedFontTypes = Object.freeze({
    WOFF: 'woff',
    WOFF2: 'woff2',
    TTF: 'ttf',
    OTF: 'otf',
})

export const vformModes = Object.freeze({
    PRODUCTION: 'production',
    TEST: 'test'
})

export const vFormEvents = Object.freeze({
    STARTED: "Started",
    NEXT_SLIDE: "NextSlide",
    NEXT_STEP: "NextStep",
    PREVIOUS_SLIDE: "PreviousSlide",
    PREVIOUS_STEP: "PreviousStep",
    HISTORY_FORWARD: "HistoryForward",
    HISTORY_BACK: "HistoryBack",
    GO_TO_SLIDE: "GoToSlide",
    GO_TO_PROJECT: "GoToProject",
    GO_TO_STEP: "GoToStep",
    NEUTRAL_ANSWER: "NeutralAnswer",
    AGGREGATION: "Aggregation",
    CORRECT_ANSWER: "CorrectAnswer",
    WRONG_ANSWER: "WrongAnswer",
    MULTIPLE_CHOICE_ANSWER: "MultipleChoiceAnswer",
    SUCCESS: "Success",
    FAILED: "Failed",
    FINISH: "Finish",
    CANCELLED: "Cancelled",
    TIMEOUT: "TimeOut",
});


export const templateTypes = Object.freeze({
    FORM_BLOCK: "formblock",
    FORM_PANEL: "formpanel",
});

export const vformTemplatableFields = Object.freeze([
    'color',
    'backgroundColor',
    'fontColor',
    'borderColor',
    'formElementType',
    'formElementTypeString',
    'textAlignment',
    'alignment',
    'buttonStyle',
    'showastiles',
    'width',
    'height',
    'headingCategory',
])

/**
 * If the component has any subfields that should be templatable, add them here
 */
export const vFormTemplatableSubFields = Object.freeze([
    'loginButton',
    'fields',
])

export const vformTemplatablePanelFields = Object.freeze([
    // 'identifierColor',
    // 'borderRadius',
    'backgroundColor',
    // 'bottomBackgroundColor',
    // 'fontColor',
    // 'hideTopPadding',
])