<template>
  <div class="leaf p-3">
    <div class="float-right clickable"><div @click="editing = item.id"><icon :style="editing ? 'color: #e24d5e' : 'color: #baa53d'" class="p-1" type="paint-roller" />Edit</div></div>
    <div v-if="!editing">{{ item.displayName ? item.displayName : item.name }}</div>
    <div v-if="editing">
      <input class="form-text w-80" type="text" v-model="item.name" />
      <div @click="editing = null; $emit('updateItem', item)">save<icon type="disk" /></div>
    </div>
    <div class="p-3" @click="$emit('addChild', item.id)">
      <Button>Add Child <icon style="margin-top: -5px;" type="baby-carriage" size="1.3"/></Button>
    </div>
    <div :key="index + 1000" v-for="(subItem, index) in item.children">
      <simple-node
        @addChild="addChild"
        @updateItem="updateItem"
        :item="subItem" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import SimpleNode from "@/components/tree/SimpleNode";
export default {
  name: "SimpleNode",
   components: {
    Button,
    Icon,
    SimpleNode
  },
  props: {
    item: {type: Object, required: true},
  },
  data() {
    return {
      editing: false,
    }
  },
  methods: {
    addChild(parentId) {
      this.$emit('addChild', parentId);
    },
    updateItem(item) {
      this.$emit('updateItem', item);
    }
  }
}
</script>

<style scoped>

</style>