<template>
    <div :class="['half-circle-spinner loading-spinner-widget', size]">
        <div class="circle circle-1"/>
        <div class="circle circle-2"/>
    </div>
</template>

<script>
    export default {
        name: "LoadingSpinner",
        props: {
          /**
           * Possible values are:
           * - small
           * -default
           * -big
           * **/
          size: {type: String, default: "default"}
        }
    }
    // styles are in loadingSpinner.scss
</script>