<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt
          slot="popupMainContent"
          @abort="() => {showDeletePrompt = false; toBeDeleted = null;}"
          @confirm="() => {deleteEntry(); showDeletePrompt = false;}"
      />
    </popup>
    <div v-if="$store.getters.getvFormMode !== vformModes.TEST" @click="saveEntry()"
         class="uppercase p-1 pr-2 pl-2 d-inline-block border-radius float-right save-button-logger clickable">
      <icon type="save" size="1.1"/>
      {{ $t('save') }}
    </div>
    <div class="w-100 clearfix"/>
    <div v-if="editMode && element.showLogEntries" class="mt-3">
      {{ dateTimeFromUnix(new Date()) }}: example data 1<br/>
      {{ dateTimeFromUnix(new Date()) }}: example data 2
    </div>
    <div class="log-entries-component-logger w-90 mt-2" v-else-if="!editMode">
      <div class="mb-2" :key="entry.id" v-for="entry in entries">
        <div @click="deleteEntry(entry.id)" class="d-inline-block lighter clickable"><icon size="0.9" type="trash-alt" /></div>
        <div class="date-item">{{ dateTimeFromUnix(entry.createdAt) }} <span v-if="entry.data.userId">({{ entry.data.userId }})</span></div>
        {{ entry.data.data }}
      </div>
    </div>
  </div>
</template>

<script>
import DateMixinJs from "../../mixins/DateMixin.js.vue";
import Icon from "../../Icon.vue";
import DeletePrompt from "../../forms/DeletePrompt.vue";
import Popup from "../../Popup.vue";
import {vformModes} from "@/enum";
export default {
  name: "ComponentLoggerViewer",
  components: {Icon, DeletePrompt, Popup},
  mixins: [DateMixinJs],
  props: {
    editMode: {type: Boolean, default: false},
    element: {type: Object, required: true},
    formData: {type: String, default: ""},
    userId: {type: String, default: ""},
    sessionId: {type: String, default: ""},
    projectName: {type: String, default: ""},
    elementLabel: {type: String, default: ""},
  },
  data() {
    return {
      entries: [],
      showDeletePrompt: false,
      toBeDeleted: null,
      vformModes: vformModes
    }
  },
  beforeMount() {
    this.loadEntries();
  },
  methods: {
    async deleteEntry(id = null) {
      if(id) {
        this.toBeDeleted = id;
        this.showDeletePrompt = true;
      } else {
        await this.$store.dispatch('clientDeleteDatasetRow', {
          id: this.element.logId,
          args: [this.toBeDeleted]
        })
        await this.loadEntries();
      }
    },
    async saveEntry() {
      if (!this.element.logId) {
        console.log('the element given to component logger viewer has no log attached')
      }
      await this.$store.dispatch('createDatasetRow', {
        data: {
          userId: this.userId,
          sessionId: this.sessionId,
          containerLabel: this.projectName,
          elementLabel: this.elementLabel,
          data: this.formData,
          time: new Date()
        },
        key: this.element.uuid,
        id: this.element.logId
      });
      this.loadEntries();
    },
    async loadEntries() {
      this.entries = await this.$store.dispatch('clientLoadDatasetRows', {
        id: this.element.logId,
        filter: 'key eq ' + this.element.uuid,
        sort: '-createdAt'
      })
    },

  }
}
</script>

<style scoped lang="scss">
.save-button-logger {
  color: #fff;
  background-color: #aaa;

  &:hover {
    background-color: darken(#aaa, 5%);
  }
}
.log-entries-component-logger {
  font-size: 0.9em;
}
.date-item {
  margin-bottom: -4px;
  font-size: 0.85em;
  opacity: 0.8;
}
</style>