<template>
<div>

</div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";

export default {
  name: "META_LIST_FILTER",
  mixins: [ElementMixin],
}
</script>

<style scoped>

</style>