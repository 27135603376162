<template>
    <div/>
</template>

<script>
    export default {
        name: 'DateMixin',
        components: {
        },
        data() {
            return {
              moment: '',
            };
        },
        beforeMount() {
            // eslint-disable-next-line no-undef
            this.moment = require('moment-timezone');
        },
        methods: {
            momentDateTimeFromISO:function(str) {
                if(!str || str === '' || str === 'undefined') {
                    return '';
                }
                const tz = this.moment.tz.guess();
                const date = this.$moment(str);
                return this.moment.tz(date, tz);
            },
            dateTimeFromISO: function(str) {
                if(!str || str === '' || str === 'undefined') {
                    return '';
                }
                const tz = this.moment.tz.guess();
                const date = this.$moment(str);
                return this.moment.tz(date, tz).format('DD.MM.YYYY HH:mm');
            },
            dateFromISO: function(str) {
                if(!str || str === '' || str === 'undefined') {
                    return '';
                }
                const tz = this.moment.tz.guess();
                const date = this.$moment(str);
                return this.moment.tz(date, tz).format('DD.MM.YYYY');
            },
            timeFromISO: function(str) {
                if(!str || str === '' || str === 'undefined') {
                    return '';
                }
                const tz = this.moment.tz.guess();
                const date = this.$moment(str);
                return this.moment.tz(date, tz).format('HH:mm:ss');
            },
            unixFromISO: function(str) {
                if(!str || str === '' || str === 'undefined') {
                    return '';
                }
                const tz = this.moment.tz.guess();
                const date = this.$moment(str);
                return this.moment.tz(date, tz).unix();
            },
            timeDifferenceFromTwoISOs(iso1, iso2) {
                const date1 = this.$moment(iso1);
                const date2 = this.$moment(iso2);
                const minutes = date1.diff(date2, 'minutes');
                const hours = date1.diff(date2, 'hours');
                const diffMinutes = minutes - hours * 60;
                return {
                  minutes: minutes,
                  string: hours + 'h ' + diffMinutes + 'min'
                };
              },
            dateTimeFromUnix(unix) {
              const date = new Date(unix);
              return ""+('0' + date.getDate()).slice(-2)+
          "."+('0' + (date.getMonth()+1)).slice(-2)+
          "."+date.getFullYear()+
          " "+('0' + date.getHours()).slice(-2)+
          ":"+('0' + date.getMinutes()).slice(-2)+
          ":"+('0' + date.getSeconds()).slice(-2)
            },
          dateFromUnix(unix) {
            const date = new Date(unix);
            return ""+date.getDate()+
                "."+(date.getMonth()+1)+
                "."+date.getFullYear()
          }
        },

    }
</script>
