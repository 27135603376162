<template>
    <filter-section :id="id" :collapsible="collapsible">
        <template slot="name">{{ $t('filter.' + filterType + 'Select') }}</template>
        <tile-button
              class="mt-1"
                slot="content"
                :make-compact-display="true"
                ref="tiles"
                :initial-active-tiles="checkedFilterTypes"
                :is-multi-select="true"
                :icons="true"
                :tiles="Object.values(typeFilterItems)"
                :values="Object.values(typeFilterItems)"
                :icons-only="iconsOnly"
                @trigger="setActive" />
    </filter-section>
</template>

<script>
    import TileButton from "../../forms/TileButton";
    import FilterSection from "./FilterSection";

    /**
     * A filter component (Checkboxes) for asset types
     * @displayName Type Filter
     */
    export default {
        name: "TypeFilter",
        components: {
            TileButton,
            FilterSection
        },
        props: {
            /**
             * An array of type names
             * @values asset-names e.g. tbl, obj
             * */
            typeFilterItems: {type: Array, required: true},
            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0},
            /**
             * Which content field needs to be filtered
             * */
            filterType: {type: String, default: 'type'},
            collapsible: {type: Boolean, default: false},
            initialTypeFilter: {type: Array, default: () => {}},
            iconsOnly: {type: Boolean, default: false},
        },
        data() {
            return {
                checkedFilterTypes: [],
            };
        },
        computed: {
            getId: function() {
                return this.$route.name + this.id  + 'TypeFilter';
            }
        },
        watch: {
            clear() {
                this.clearValue();
            },
        },
        beforeMount() {
            let localFilter = JSON.parse(window.localStorage.getItem(this.getId));
            if(localFilter) {
                this.checkedFilterTypes = localFilter;
            } else if(this.initialTypeFilter) {
              this.checkedFilterTypes = JSON.parse(JSON.stringify(this.initialTypeFilter));
            }
        },
        mounted() {
            this.filter(null, true);
        },
        methods: {
            setActive: function(args) {
                if(args.operation === 'add') {
                    if(!this.checkedFilterTypes.includes(args.value)) {
                        this.checkedFilterTypes.push(args.value);
                    }
                }
                else {
                   this.checkedFilterTypes.splice(this.checkedFilterTypes.indexOf(args.value), 1);
                }
                this.filter();
            },
            clearValue: function() {
                this.$refs.tiles.clearActiveStatus();
                this.filter(null, true, true);
            },
            filter(mouseEvent = null, initial = false, selectAll = false) {
                let filterParamId = this.filterType === 'type' ? 'default' : this.filterType + this.id;
                this.$log.warn(mouseEvent);
                let params = {};
                let toBeFilteredTypes = this.checkedFilterTypes;
                if(toBeFilteredTypes.length === 0 || selectAll) {
                    toBeFilteredTypes = this.typeFilterItems; //this.getToBeFilteredTypes(true);
                }
                if (toBeFilteredTypes.length > 0) {
                    let filterString = '';
                    if (toBeFilteredTypes.length === 1) {
                        filterString = this.filterType + ' eq ' + toBeFilteredTypes[0];
                    } else {
                        filterString = this.filterType + ' in ';
                        for (let i = 0; i < toBeFilteredTypes.length; i++) {
                            filterString += toBeFilteredTypes[i];
                            if(i !== toBeFilteredTypes.length - 1) {
                                filterString += ' ';
                            }
                        }
                    }
                    params = {
                        add: {
                            filter: {
                                [filterParamId]: filterString
                            }
                        }
                    };
                }
                else {
                    params = {
                        remove: {
                            filter: [filterParamId]
                        }
                    };
                }
                if(params) {
                    /**
                     * sends the filtering content to the parent component
                     * ready to use in a register or loading method
                     * */
                    this.$emit('filter', params, initial, this.filterType);
                }
                window.localStorage.setItem(this.getId, JSON.stringify(this.checkedFilterTypes));
            },
        },
    }
</script>