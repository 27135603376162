<template lang="html">
  <section class="user-form mb-5">
    <!--<div style="width: 200px;" class="bg-dark pl-3 pr-3 pt-3 pb-2 border-radius mb-1">
      <div class="uppercase">{{ $t('newLogin') }}</div>
      <div class="lighter mb-1">vSTAGE x.x.x or newer</div>
      <toggle-button :labels="true"
                     :sync="true"
                     :value="isNewLogin"
                     :color="{checked: '#DA5E06', unchecked: '#000', disabled: '#CCCCCC'}"
                     switch-color="#ddd"
                     @change="isNewLogin = !isNewLogin"/>
    </div>-->
    <m-f-a-display
      @login="doLoginForReal"
      :show-error="showMfaError"
    />
    <form id="user-form-standard">
      <div v-if="error" class="form-error">{{ error }}</div>
      <label>Username</label>
      <div class="form-group form-item with-prepend-icon">
        <div class="input-prepend-icon no-border no-background">
          <icon class="mt-1 ml-1" size="1" type="user"/>
        </div>
        <input
            id="username"
            ref="username"
            v-model="username"
            class="form-text"
            label="username"
            name="username"
            type="text"
        >
      </div>
      <div class="w-100"/>
      <label>Password</label>
      <div class="form-group form-item with-prepend-icon">
        <div class="input-prepend-icon no-border no-background">
          <icon class="mt-1 ml-1" size="1" type="lock"/>
        </div>
        <input
            id="password"
            ref="password"
            class="form-text"
            label="password"
            name="password"
            type="password"
            @keydown.enter="doLoginForReal()"
        >
      </div>

      <!--Do not remove login-button class, necessary for testing -->
      <div v-if="standardButton" class="settings-button d-inline-block" @click="doLoginForReal()">{{ $t('SignIn') }}
        <loading-spinner v-if="loading" class="white float-right ml-1"/>
      </div>
      <!--Do not remove login-button class, necessary for testing -->
      <Button v-else class="highlight login-button border-radius" @click.native="doLoginForReal()">{{ $t('SignIn') }}
        <loading-spinner v-if="loading" class="white float-right ml-1"/>
      </Button>
      <br />
      <!--<div class="settings-button d-inline-block mt-1">Login with azure b2c (not functional yet)</div>-->
    </form>
  </section>
</template>

<script>
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import LoadingSpinner from "@/components/LoadingSpinner";
import MFADisplay from "@/components/MFADisplay.vue";
export default {
  name: 'LoginFormVHUB',
  components: {
    Button,
    Icon,
    LoadingSpinner,
    MFADisplay
  },
  props: {
    standardButton: {type: Boolean, default: false}
  },
  data() {
    return {
      username: null,
      password: null,
      error: null,
      loading: false,
      isNewLogin: true,
      showMfaError: false,
    }
  },
  beforeMount() {
    this.$emit('stopLoading', "login form stopped loading");
  },
  methods: {
    async doLoginForReal(mfaCode = null) {
      this.$emit('setLoading', "checking login");
      this.loading = true;
      this.error = '';
      this.showMfaError = false;
      const username = this.$refs.username.value;
      const password = this.$refs.password.value;
      let args = {username, password};
      if(mfaCode) {
        args.mfaCode = mfaCode;
      }
      await this.$store.dispatch('authLogin', args)
          .then(() => {
            this.loading = false;
            if(mfaCode) {
              this.$store.commit('setMfaCodeEnter', false);
            }
          })
          .catch(e => {
            if(mfaCode) {
              this.showMfaError = true;
            }
            this.error = this.$t('errors.' + e.message);
            this.loading = false;
            this.$emit('stopLoading', "auth error");
          });
    }
  }
}
</script>

<style lang="scss">
#user-form-standard {
  width: 200px;

  label {
    margin-bottom: 0.1rem;
  }

  .button {
    min-width: auto;
  }

  .form-text {
    border-radius: 3px;
    background-color: $inputcolor;

    &:-webkit-autofill,
    &:-internal-autofill-selected,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: $inputcolor !important;
      -webkit-box-shadow: 0 0 0 30px $inputcolor inset !important;
      color: #fff !important;
      outline: none;
      -webkit-text-fill-color: #fff !important;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
