<template>
    <one-column-sidebar-left
        back-button-tab-selector="datasourcebackbutton"
        sub-menu-selector="librarypanel"
    >
<Button slot="buttonsRight"
                class="mb-3 mt-5"
                :deactivated="!enableSaving"
                @click="save">
            <icon v-if="enableSaving" type="save"/>
            <icon v-else type="ban"/> {{ $t('SAVE') }}
        </Button>

        <div slot="mainContent">
            <h1><icon class="mr-2" type="database" size="0.9" />{{ $t('createNewDataSource') }}</h1>
            <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2" />
            <input
                    type="text"
                    :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.datasourceNameAlreadyExists') }}</div>

            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>
            <div>
               <textarea
                       :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                       :id="'description'"
                       v-model="description"
                       :placeholder="$t('enterdeschere')"
                       @input="delayTouch($v.description)"
               />
                <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            <div>
                <div class="inline-edit-label mb-1">
                    {{ $t('choseDataSourceType') }}
                </div>
                <div class="col-12 col-md-4 neutralize-margin-left">
                    <tile-button :rows="3" :icons="true" :initial-active-tiles="['sensor']" :tiles="['sensor', 'feed']" :values="['sensor', 'feed']" @trigger="updateType" />
                </div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import TileButton from "../components/forms/TileButton";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: 'DataSourceAdd',
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
            OrganizationSwitcher,
            TileButton
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                nameEdit: true,
                descEdit: true,
                id: '',
                enableSaving: false,
                name: '',
                description: '',
                type: 'sensor',
                organizationId: '',
                teamId: ''
            };
        },
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfDatasourceNameExists', {name: value, type: 'Asset', value: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            }
        },
        methods: {
            updateType: function(args) {
                this.type = args.value;
            },
            updateContent: function(args) {
                this[args.field] = args.value;
                this.checkSaving();
            },
            checkSaving: function() {
                if(this.name && this.type/*&& this.description*/) {
                    this.enableSaving = true;
                }
                else {
                    this.enableSaving = false;
                }
            },
            save: async function() {
                if(this.enableSaving) {
                    let args = {
                        name: this.name,
                        description: this.description,
                        type: this.type,
                    };
                    args.organizationId = this.organizationId;
                    if(this.teamId) {
                      args.teams = [this.teamId];
                    }
                    await this.$store.dispatch('createDatasource', args).then(item => {
                      this.$router.push('/library/datasource/'+item.id+'/general');
                    })
                }
            },
        },
    }
</script>
