// store/modules/identityProvider.js
import Vue from 'vue';
import DefaultAuthStrategy from '@/store/identityProviders/DefaultAuthStrategy';
import LegacyAuthStrategy from '@/store/identityProviders/LegacyAuthStrategy';
import B2CAuthStrategy from '@/store/identityProviders/B2CAuthStrategy';
import { authStrategies } from '@/enum';

function getStrategy(idp) {
    switch (idp) {
        case authStrategies.DEFAULT:
            return new DefaultAuthStrategy();
        case authStrategies.LEGACY:
            return new LegacyAuthStrategy();
        case authStrategies.B2C:
            return new B2CAuthStrategy();
        default:
            throw new Error('Unsupported IDP!');
    }
}

const state = {
    strategy: getStrategy(authStrategies.DEFAULT),
    strategyName: authStrategies.DEFAULT,
};

const getters = {
    getStrategy: state => state.strategy,
    getStrategyName: state => state.strategyName
};

const mutations = {
    changeStrategy(state, name) {
        console.log('Setting new strategy:', name);
        Vue.set(state, 'strategy', getStrategy(name));
        state.strategyName = name;
    }
};

const actions = {
    changeAuthStrategy: ({ commit }, strategyName) => {
        console.log('Setting new strategy via action:', strategyName);
        commit('changeStrategy', strategyName);
    },
    idpLogin: async ({ state }, credentials) => {
        const strategy = state.strategy;
        return strategy.authenticate(credentials);
    },
    idpLogout: async ({ commit, dispatch }) => {
        dispatch('authLogout')
        commit('changeStrategy', authStrategies.DEFAULT);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
