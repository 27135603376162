<template>
  <main-table-layout
      subMenuSelector="adminpanel"
  >
    <div slot="buttonsRight"/>
    <content-filter
        :id="'projectList'"
        slot="filter"
        store-name="VMLog"
        text-filtering-field-name=""
        @filter="filter"
    />
    <view-switcher
        :id="'mainContent'"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />
    <Button
        slot="mainContent"
        class="icon-button"
        icon="robot"
        type="reload"
        @click="loadPaginatedData(0);"
    />
    <div slot="mainContent" class="col-12">
      <div class="col-6">
        <h2>This will be a calculator, but it is not functional yet...</h2>
        Vertices:
        <select v-model="verticesVal" class="custom-select dark">
          <option v-for="item in Object.keys(vertices)" :key="item" :value="vertices[item]">{{ item }}
            ({{ makeREadable(vertices[item]) }})
          </option>
        </select>
        <input class="form-text dark" v-model="verticesVal" /><br />
        Groups:
        <select v-model="groupsVal" class="custom-select dark">
          <option v-for="item in Object.keys(groups)" :key="item" :value="groups[item]">{{ item }} ({{
              makeREadable(groups[item])
            }})
          </option>
        </select>
        <input class="form-text dark" v-model="groupsVal" /><br />

        Geometries:
        <select v-model="geometriesVal" class="custom-select dark">
          <option v-for="item in Object.keys(geometries)" :key="item" :value="geometries[item]">{{ item }}
            ({{ makeREadable(geometries[item]) }})
          </option>
        </select>
        <input class="form-text dark" v-model="geometriesVal" /><br />

        Lod:
        <select v-model="lodLevelsVal" class="custom-select dark">
          <option v-for="item in Object.keys(lodLevels)" :key="item" :value="lodLevels[item]">{{ item }}
            ({{ lodLevels[item] }})
          </option>
        </select>

        OutputFormats:
        <select v-model="outputFormatsVal" class="custom-select dark">
          <option v-for="item in Object.keys(outputFormats)" :key="item" :value="outputFormats[item] === 'fbx' ? 1 : 2">{{ item }}
            ({{ outputFormats[item] }})
          </option>
        </select>

        Estimated time:
        {{ calculatedTime }} min
      </div>
      <table v-if="assetsByVertices.length" class="mt-3">
        <tr>
          <th>Vertices</th>
          <th>Groups</th>
          <th>GroupTime (min)</th>
          <th>Singles</th>
          <th>Singletime (min)</th>
          <th>VM time</th>
          <th>Group Extract Progress</th>
          <th>Name</th>
          <th>LOD</th>
          <th>Format</th>
          <th>Factor</th>
          <th>VM</th>
          <th>Datum</th>
        </tr>
        <tr v-for="(item, index) in assetsByVertices" :key="index">
          <td>{{ item.vertices }}</td>
          <td>{{ item.groups }}</td>
          <td>{{ item.groupTime }}</td>
          <td>{{ item.geometries }}</td>
          <td>{{ item.singleTime }}</td>
          <td>{{ getTime(item, true) }} <br />{{ getTime(item).minutes }}</td>
          <td>{{
            item.stats && item.stats.filter(sub => {
              return sub.taskName == 'GroupExtractProgress'
            }).length ? item.stats.filter(sub => {
              return sub.taskName == 'GroupExtractProgress'
            })[0].value * 100 : ''
          }}%</td>
          <td><span class="lighter">{{ item.asset }}</span></td>
          <td>{{ getLod(item) }}</td>
          <td>{{ getOutputFormats(item) }}</td>
          <td>{{ makeREadable(factor(item))}}</td>
          <td>{{ item.vmId.startsWith('313') ? 'a' : 'b'}}</td>
          <td>{{ item.startedAt }}</td>
        </tr>
      </table>
    </div>


  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import ContentFilter from "../components/widgets/filter/ContentFilter";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import DateMixinJs from "../components/mixins/DateMixin.js";

export default {
  name: "VMLogs",
  components: {
    Button,
    ContentFilter,
    MainTableLayout,
    ViewSwitcher,
  },
  mixins: [DateMixinJs],
  data() {
    return {
      listName: 'VMLogListStats',
      loading: true,
      viewId: 'mainContent',
      forceReRenderKey: 0,
      logs: [],
      verticesVal: 1000000,
      groupsVal: 1,
      lodLevelsVal: 2,
      geometriesVal: 6596,
      outputFormatsVal: 1,
      vertices: {
        small: 1000,
        medium: 10000,
        large: 100000,
        xxl: 1000000,
        xxxl: 10000000,
        maximum: 10000000
      },
      groups: {
        small: 10,
        medium: 50,
        large: 100,
        xxl: 500,
        xxxl: 1000,
        xxxxl: 2000,
        maximum: 7000,
      },
      geometries: {
        small: 10,
        medium: 100,
        large: 1000,
        xxl: 2000,
        xxxl: 4000,
        xxxxl: 6000,
        reallyHuge: 8000,
        maximum: 22000
      },
      lodLevels: {
        small: 0,
        medium: 1,
        large: 2,
        xxl: 3
      },
      outputFormats: {
        fbxOnly: 'fbx',
        fbxAndGlb: 'fbx/glb'
      }

    };
  },
  computed: {
    calculatedTime() {
      //parseInt(item.vertices) * parseInt(item.groups) * parseInt(item.geometries) * getLod(item) * getOutputFormats(item).length
      return this.factor({
        vertices: this.verticesVal,
        groups: this.groupsVal,
        geometries: this.geometriesVal,
        lodLevels: this.lodLevelsVal,
        outputFormatsRaw: this.outputFormatsVal
      }) * 1.7;
    },
    assetsByVertices() {
      let res = this.logs && this.logs.length ? this.logs.map(item => {
        return {
          //...item,
          outputFormats: item && item.config && item.config.pipeline ? item.config.pipeline.outputFormats : null,
          asset: item.asset ? item.asset.name : 'no-asset',
          rawVertices: item.vertices,
          vertices: this.makeREadable(item.vertices),
          groups: this.makeREadable(item.groups),
          groupTime: this.getSingleTime(item.stats, 'GroupExtractProgress'),
          singleTime: this.getSingleTime(item.stats, 'SingleExtractProgress'),
          geometries: item.geometries,
        }
      }) : [];
      if (res) {
        res = res.sort((a, b) => {
          return b.rawVertices - a.rawVertices
        })
        res = res.sort((a,b) => {
          return this.getTime(b).minutes - this.getTime(a).minutes
        })
      }
      if (res) {
        res = res.filter(item => {
          return !!item.stoppedAt && !!this.getOutputFormats(item).length && item.vmId.startsWith('313');
        })
      }
      let top = []
      for (let i = 0; i < 100; i++) {
        if (res[i]) {
          top.push(res[i])
        }
      }
      return top;
    },

  },
  beforeMount() {
    this.loadPaginatedData(0, false);
  },
  methods: {
    getSingleTime(stats, string) {
      if(!stats || !stats.length || !Array.isArray(stats)) {
        return null;
      }
      const singleDate = stats.filter(item => {
        return item.taskName === string;
      });
      if(singleDate.length) {
        const res = this.timeDifferenceFromTwoISOs(singleDate[0].updatedAt, singleDate[0].createdAt);
        const {minutes} = res;
        if(minutes) {
          return minutes;
        }
      }
      return null;
    },
    getTime(item, string = false) {
      const res = this.timeDifferenceFromTwoISOs(item.vmStoppedAt, item.vmStartedAt);
      return string ? res.string : res;
    },
    factor(item) {
      const groups = parseInt(item.groups);
      const geometries = parseInt(item.geometries);

      return (groups * 6) + (geometries * 163);
      // eslint-disable-next-line no-unused-vars
      /*const outputFormats = item.outputFormatsRaw ? item.outputFormatsRaw : this.getOutputFormats(item).length;
      const vertices = 100 / parseInt(item.vertices) * 1.1;
      const groups = 100 / parseInt(item.groups) * 1.5;
      const geometries = 100 / parseInt(item.geometries) * 1.5;
      //const groupTimeInMinutes = item.groupTime;
      //const singleTimeInMinutes = item.singleTime;
      return (vertices + (groups * 6) + (geometries * 163) + (outputFormats * 1.3) * 500) * (this.getLod(item) * 0.5)  /** this.getLod(item) * outputFormats;*/
    },
    getLod(item) {
      return item.config && item.config.pipeline && item.config.pipeline.lod ? item.config.pipeline.lod : item.lodLevels;
    },
    getOutputFormats(item) {
      return item.config && item.config.pipeline && item.config.pipeline.outputFormats ? item.config.pipeline.outputFormats : [];
    },
    makeREadable(num) {
      return new Intl
          .NumberFormat('en-US')
          .format(num)
          .replaceAll(',', '\'')
          .replaceAll(',', '.');
    },
    loadPaginatedData: function (offset, initial) {
      this.loading = true;
      let args = {
        include: 'instance,logAsset',
        limit: 0,
      };
      if (initial) {
        this.queryRelevantInits++;
        this.$store.dispatch('registerVMLogQueryParams', args);
      } else {
        this.$store.dispatch('clientLoadVMLogs', args).then(data => {
          this.logs = data.filter(item => {return item.task === 'extract'});
          this.loading = false;
          this.forceReRenderKey++;
        })
      }
    },
    deleteRow: function () {
      this.loadPaginatedData();
      this.forceReRenderKey++;
    },
    filter: function (args, initial) {
      if (initial) {
        this.queryRelevantInits++;
        this.$store.dispatch('registerVMLogQueryParams', args);
      } else {
        this.loading = true;
        this.$store.dispatch('clientLoadVMLogs', args).then(() => {
          this.loading = false;
        })
      }
    },
    setFilterParams: function (args) {
      this.$store.dispatch('submitQueryParams', {
        storeType: 'organization',
        listName: this.$route.params.id,
        params: args,
      });
    },
  }
}
</script>
