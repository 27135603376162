<template>
  <div>
    <!--    DISPLAY    -->
    <div
        v-for="(option, index) in element.codes"
        :key="index + forceReRenderKey"
        class="dflex"
    >
      <div v-if="option.assetId" class="col p-0 dflex mt-3 qr-code-container">
        <div
            v-if="option.assetId"
            :class="['p-0 col-12 d-flex', addEditIndex === index ? 'last' : '']"
        >
          <div class="col-auto p-2">
            <qrcode-view
                v-if="option.assetId"
                :id="option.assetId"
                :file-name="option.qrCodeName"
                class="qr-code-thumbnail"
            />
          </div>
          <div class="col p-0 mt-2">
            <label class="vform-editor-body">{{ option.qrCodeName }}</label><br />
            <div v-if="option.selectedAction === 'GoToStep' && option.targetStep">
              <label class="vform-editor-body">
                {{ $t("vform.GoToStep") }}
                <span v-if="option.stepName">{{ option.stepName }}</span>
              </label>
            </div>
            <div v-if="option.selectedAction === 'GoToSlide' && option.targetSlide">
              <label class="vform-editor-body">
                {{ $t("vform.GoToSlide") }}:
                {{ option.targetSlideName }}
              </label>
            </div>
            <div v-if="option.selectedAction === 'NextSlide'">
              <label class="vform-editor-body">
                {{ $t("vform.NextSlide") }}
              </label>
            </div>
          </div>
          <div class="float-right" style="position: relative; top: -4px">
            <div @click="removeItem(index)">
              <icon :size="'1.4'" type="fat-square-minus"/>
            </div>
            <div v-if="addEditIndex === index || addEditIndex === null"
                 @click="editItem(index, option)"
            >
              <icon :size="'1.4'" type="editLight"/>
            </div>
          </div>
        </div>

        <div
            v-if="index === addEditIndex && element.codes[addEditIndex].assetId"
            class="col-12 p-2"
        >
          <div
              v-if="element.codes[addEditIndex].assetId && !targetStepSaved"
              class=""
              style="position: relative; top: -4px"
          >
            <label class="vform-editor-label section mt-2">{{
                $t("Action")
              }}</label>
            <select
                :value="option.selectedAction"
                @change="(e) => {setSelectedAction(e.target.value, option)}"
                class="custom-select form-text-dark"
            >
              <option
                  v-for="(action, index) in actions"
                  :key="index"
                  :value="action"
              >
                {{ $t("vform." + action) }}
              </option>
            </select>
            <v-form-project-switch
                v-if="option.selectedAction === 'GoToStep' || option.selectedAction === 'GoToSlide'"
                :element="element"
                :initial-target-step="option.targetStep"
                :linked-project-id="option.linkedProjectId"
                :organization-id="organizationId"
                :project-id="projectId"
                :selected-action="option.selectedAction"
                :slides-current-project="slides"
                :steps="steps"
                class="mt-2"
                @projectSelected="projectSelected"
                @slideSelected="slideSelected"
                @stepSelected="stepSelected"
            />
            <div class="mt-2">
              <div class="settings-button d-inline-block" style="" @click="cancelQrCode(addEditIndex)"
              >{{ $t("cancel") }}
                <icon type="cancel"
                />
              </div>
              <div
                  :deactivated="!canSave || !$v.$invalid"
                  class="settings-button d-inline-block"
                  style="float: right"
                  @click="setAction(addEditIndex, true)"
              >{{ $t("save") }}
                <icon class="ml-2" type="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="addEditIndex === null" class="w-100 text-right mr-2">
      <div @click="addOption">
        <icon :size="'2.0'" type="fad-square-plus"/>
      </div>
    </div>
    <div
        v-if="addEditIndex != null && !element.codes[addEditIndex].assetId"
        class="mt-3"
    >
      <div
          :class="['settings-button d-inline-block mb-1 mr-2', mode == 'select' ? 'active' : '']"
          style=""
          @click="mode = 'select'"
      >{{ $t("addExisting") }}
        <icon class="ml-2" type="qrcode"
        />
      </div>
      <div
          :class="['settings-button d-inline-block mb-1', mode == 'add' ? 'active' : '']"
          style=""
          @click="mode = 'add'"
      >{{ $t("create") }}
        <icon class="ml-2" type="plus"
        />
      </div>
    </div>

    <div v-if="element.codes && element.codes[addEditIndex]" class="col p-0">
      <div
          v-if="mode === 'select' && !element.codes[addEditIndex].assetId"
          class=""
      >
        <label class="vform-editor-label section">{{ $t("SelectQrCode") }}</label>
        <universal-selector
            :limit-by-organization-id="organizationId"
            class="vform-editor-universal-select"
            filter-attribute="name"
            list-name="contentFilter"
            store-name="Qrcode"
            tag-icon="home"
            @addItem="addItem"
        />
      </div>
      <div v-if="mode === 'add' && !element.codes[addEditIndex].assetId">
        <label class="vform-editor-label section">{{ $t("Name") }}</label>
        <input
            v-model="qrcodeName"
            class="mb-1 form-text v-form-label form-text-dark"
            placeholder="Name"
            type="text"
            @input="delayTouch($v.qrcodeName)"
        />
        <div
            v-if="$v.qrcodeName.$dirty && !$v.qrcodeName.alphaNumSpace"
            class="form-error mt-1"
        >
          {{ $t("errors.alphaNumSpaceOnly") }}
        </div>

        <div
            v-if="$v.qrcodeName.$dirty && !$v.qrcodeName.maxLength"
            class="form-error mt-1"
        >
          {{ $t("errors.atMostCharacters", {num: 128}) }}
        </div>

        <div
            v-if="$v.qrcodeName.$dirty && !$v.qrcodeName.minLength"
            class="form-error mt-1"
        >
          {{ $t("errors.atLeastCharacters", {num: 3}) }}
        </div>
        <div
            v-if="$v.qrcodeName.$dirty && !$v.qrcodeName.isUnique"
            class="form-error mt-1"
        >
          {{ $t("errors.textNameAlreadyExists") }}
        </div>
        <div v-if="error" class="form-error mt-1">{{ error }}</div>
        <div class="mt-2">
          <Button style="" @click="cancelQrCode(addEditIndex)"
          >{{ $t("cancel") }}
            <icon type="cancel"
            />
          </Button>
          <Button style="float: right" @click="createQrCode"
          >{{ $t("save") }}
            <icon type="save"
            />
          </Button>
          <!-- <div class="mini-button mt-1" >save</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The QR-Code component uses the camera to scan a QR code and perform actions defined by this component
 * in the codes array there can be one or multiple qr codes with their own actions, their structure is the same as a regular button:
 * {
 *   codes: [
 *     {
 *         targetStep: null,
 *         targetSlide: null,
 *         stepName: "",
 *         selectedAction: "NextSlide"
 *       }
 *   ]
 * }
 * **/
import {vFormControls} from "@/enum";
import UniversalSelector from "@/components/organizations/UniversalSelector";
import vFormProjectSwitch from "./vFormPROJECT_SWITCH";
//import {v4 as uuidv4} from "uuid";
import Icon from "@/components/Icon";
import QrcodeView from "@/components/widgets/QrcodeView";
import Button from "@/components/forms/Button";
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {alphaNumSpace} from "@/customValidators";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
  name: "vFormQR_CODE",
  components: {
    UniversalSelector,
    Icon,
    QrcodeView,
    Button,
    vFormProjectSwitch,
  },
  props: {
    element: {type: Object, required: true},
    organizationId: {type: String, default: ""},
    teams: {
      type: Array,
      default: () => {
        return [];
      },
    },
    projectId: {type: String, default: null},
    steps: {type: Array, default: null},
    slides: {type: Array, default: null},
  },
  data() {
    return {
      vFormControls: vFormControls,
      forceReRenderKey: 7770,
      error: "",
      selectedSlideId: null,
      targetStepSaved: true,
      qrcodeName: "",

      // editing
      addEditIndex: null,
      actions: ["NextSlide", "GoToSlide", "GoToStep"],
      selectedAction: "GoToStep",
      mode: "select",
      canSave: false,
    };
  },
  mixins: [VuelidateMixin],
  validations: {
    qrcodeName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === "") {
          return true;
        }
        let bool = true;
        await this.$store
            .dispatch("checkIfQrcodeNameExists", {
              name: value,
              type: "Asset",
              value: value,
              organizationId: this.organizationId,
            })
            .then((data) => {
              bool = data.length === 0;
            });
        return bool;
      },
    },
  },
  beforeMount() {
    if (this.element.selectedAction) {
      this.selectedAction = this.element.selectedAction;
    }

    this.element.formElementType = vFormControls["QR_CODE"];
    if (!this.element.codes) {
      this.element.codes = [];
      this.addOption();
    }
    for (let i = 0; i < this.element.codes.length; i++) {
      this.loadQrCode(i);
    }

    this.element.codes.forEach((option) => {
      this.updateStepName(option);
    });
  },
  watch: {
    selectedAction() {
      console.log('selected action changed');
      this.forceReRenderKey++;
    },
  },
  methods: {
    setSelectedAction(value, option) {
      this.$set(option, "selectedAction", value);
      if (option.selectedAction === 'NextSlide') {
        this.$set(option, "isCurrentProject", true);
        this.$set(option, "linkedProjectId", null);
        this.$set(option, "linkedProjectName", null);
        this.$set(option, "targetStep", null);
        this.$set(option, "targetSlide", null);
      }
      this.forceReRenderKey++;
    },
    stepSelected(val) {
      this.element.codes[this.addEditIndex].targetStep = val;
      this.element.codes[this.addEditIndex].targetSlide = null;
      this.updateStepName(this.element.codes[this.addEditIndex]);

      if (val) {
        this.canSave = true;
      }
      this.setAction(this.addEditIndex, false);
    },
    slideSelected(val, slideName) {
      this.element.codes[this.addEditIndex].targetSlide = val;
      this.element.codes[this.addEditIndex].targetSlideName = slideName;
      this.element.codes[this.addEditIndex].targetStep = null;
      if (val) {
        this.canSave = true;
      }

      this.setAction(this.addEditIndex, false);
    },
    projectSelected(val) {
      this.$set(this.element.codes[this.addEditIndex], "linkedProjectId", val);
      this.$set(this.element.codes[this.addEditIndex], "isCurrentProject", val === this.projectId);
      //this.element.codes[this.addEditIndex].targetStep = null;
      this.canSave = false;
    },
    createQrCode() {
      if (!this.projectId) {
        this.error = "please connect the form with a project first";
        return;
      }
      this.$store
          .dispatch("clientCreateAsset", {
            type: "qrcode",
            organizationId: this.organizationId,
            teams: this.teams
                ? this.teams.map((item) => {
                  return item.id;
                })
                : [],
            name: this.qrcodeName,
          })
          .then((qrcode) => {
            this.addItem(qrcode);
          })
          .catch((er) => {
            this.error = er;
          });
    },
    loadQrCode(index) {
      const option =
          this.element.codes && this.element.codes[index]
              ? this.element.codes[index]
              : null;
      if (option && option.assetId) {
        this.$store
            .dispatch("loadQrcode", {
              id: option.assetId,
            }).then(data => {
          option.qrCodeName = data.name;
        }).catch(() => {
        });
      }
    },
    addOption() {
      this.element.linkedProjectId = null;
      this.element.linkedProjectName = null;
      this.element.targetStep = null;
      this.element.codes.push({
        targetStep: null,
        stepName: "",
        selectedAction: "NextSlide"
      });
      this.addEditIndex = this.element.codes.length - 1;
      this.targetStepSaved = false;
    },
    cancelQrCode() {
      this.addEditIndex = null;
      this.forceReRenderKey++;
    },
    addItem(asset) {
      console.log(asset);
      this.error = "";
      this.targetStepSaved = false;
      if (!this.projectId) {
        this.error = "please connect the form with a project first";
        return;
      }

      this.$set(this.element.codes[this.addEditIndex], "assetId", asset.id);
      this.save();
      this.$set(this.element.codes[this.addEditIndex], "qrCodeName", asset.name);
      this.forceReRenderKey++;
    },
    reset(index) {
      this.addEditIndex = null;
      this.element.codes.splice(index, 1);
      this.targetStepSaved = false;
      this.forceReRenderKey++;
    },
    async removeItem(index) {
      this.element.codes.splice(index, 1);
    },
    editItem(index, option) {
      console.log(option);
      console.log(option.selectedAction);
      this.addEditIndex = index;
      this.selectedAction = option.selectedAction;
      this.targetStepSaved = false;
      this.$set(this.element, "targetStep", option.targetStep);
      this.$set(this.element, "targetSlide", option.targetSlide);
      this.$set(this.element, "isCurrentProject", option.isCurrentProject);
    },
    async setAction(index, markAsSaved = false) {
      if (markAsSaved) {
        this.targetStepSaved = true;
        this.addEditIndex = null;
        this.forceReRenderKey++;
      }
    },
    updateStepName(option) {
      let projectId = option.linkedProjectId;
      let uuid = option.targetStep;
      console.log(this.projectId);
      console.log(projectId);
      if (this.projectId === projectId) {
        this.$set(
            option,
            "stepName",
            this.steps.find((x) => x.uuid === uuid).name
        );
      } else {
        //console.log("get step name " + projectId + "   " + uuid);
        this.$store
            .dispatch("clientLoadProjectInstances", {
              id: projectId,
              filter: "type eq form",
              include: "asset",
            })
            .then((res) => {
              if (res && res.length) {
                const asset = res[0].asset; //there is never more than 1 vform attached to a project
                const content = asset.content;
                try {
                  let projectConfig = JSON.parse(content);
                  if (projectConfig.steps && projectConfig.steps.length) {
                    const index = projectConfig.steps.findIndex(
                        (item) => {
                          return item.uuid === uuid;
                        }
                    );
                    //console.log("found index: " + index + " for uuid " + uuid);
                    this.$set(
                        option,
                        "stepName",
                        projectConfig.steps[index].name
                    );
                    if (index !== -1) {
                      return option.stepName;
                    }
                    return "";
                  }
                  return "";
                } catch (e) {
                  console.log(e);
                }
              }
            }).catch(() => {});
      }
    },
    save() {
      this.$emit("save", this.element);
    },
  },
};
</script>

<style lang="scss" scoped>

.settings-button.active {
  background-color: var(--vform-editor-accent-color);
}

.qr-code-container {
  border: solid 1px var(--vform-editor-ui-secondary-color);
  border-radius: 2px;
}

.qr-code-container.last {
  border-bottom: solid 1px transparent;
  border-radius: 2px;
}

.action-container {
  border: solid 1px var(--vform-editor-ui-secondary-color);
  border-radius: 2px;
  border-top: solid 1px transparent;
}

.qr-code-thumbnail {
  width: 50px;
}

.mini-button {
  background-color: #1f1f1f;
  color: #d4d4d5;
  border-color: #1f1f1f;
  border-radius: 4px;
  font-size: 13px;
  padding: 4px;
  height: 25px;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  display: inline-block;

  &:hover {
    background-color: lighten(#1f1f1f, 10%);
  }
}
</style>

<style lang="scss">
.qr-code-thumbnail > a {
  font-size: 12px;
}
</style>
