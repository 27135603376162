<template>
    <div class="notification-container" v-if="$store.getters.getNotifications">
        <div class="notification-close" @click="cancelNotification">
            <icon type="times" />
        </div>
        <div class="notification-text">
            <p>{{ this.$store.getters.getNotifications }}</p>
        </div>
    </div>
</template>

<script>
    import Icon from "./Icon";
    import { mapState } from 'vuex';

    export default {
        name: 'Notification',
        components: {
            Icon,
        },
        props: {
            duration: { type: Number, default: 12000}
        },
        computed: mapState({
            notification: state => state.notifications.notification,
        }),
        watch: {
            notification: function(newval){
                if(newval.length > 0) {
                    this.setNotificationTimeout();
                }
            },
        },

        mounted() {
            this.setNotificationTimeout();
        },
        methods: {
            cancelNotification: function () {
                this.$store.dispatch('cancelNotification', {'text': ''});
            },
            setNotificationTimeout: function() {
                setTimeout(()=>{
                    this.cancelNotification();
                }, this.duration);
            }
        }
    }
</script>
<style lang="scss">
    .notification-container {
        position: fixed;
        bottom: 15px;
        right: 15px;
        width: 300px;
        background-color: #000;
        padding:25px;
        z-index:1000;
       /* animation:fadeOut  0.5s 1;
        -webkit-animation:fadeOut  0.5s 1;
        animation-fill-mode: forwards;*/

        /*animation-delay:10s;
        -webkit-animation-delay:10s;
        -webkit-animation-fill-mode: forwards;*/

    }
    .notification-close {
        color: #fff;
        position:absolute;
        top:15px;
        right:15px;
        cursor:pointer;
        padding:5px;
    }
    @keyframes fadeOut {
        from {opacity :1;}
        to {opacity :0;}
    }
</style>
