import UserAuthStrategy from './UserAuthStrategy';

class B2CAuthStrategy extends UserAuthStrategy {
    constructor() {
        super();
    }
    authenticate(credentials) {
        console.log('authenticating in process...')
        console.log(credentials)
        // todo: implement!
    }
}

export default B2CAuthStrategy;