<template>
    <one-column-sidebar-left>
        <h1 slot="mainContent">{{ $t('AccessDenied') }}</h1>
        <div slot="mainContent" v-if="this.$store.getters.getUserOrganizations(this.$store.getters.getCurrentUserId).length > 1">
            {{ $t('noAccessChangeOrg') }}
        </div>
        <div slot="mainContent" v-else>
            {{ $t('noAccess') }}
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    export default {
        name: 'AccessDenied',
        components: {
            OneColumnSidebarLeft
        },
    }
</script>
