<template>
    <div>
        <loading-spinner v-if="loading" class="white mt-1" />
        <div v-else-if="!loading && !message">
            {{ $t('from') }}
            <date-picker-widget
                    :value="localValue.min"
                    :type="type === 'timestamp' ? 'datetime' : 'time'"
                    @save="updateMin"
            />
            {{ $t('until') }}
            <date-picker-widget
                    :value="localValue.max"
                    :type="type === 'timestamp' ? 'datetime' : 'time'"
                    @save="updateMax"
            />
        </div>
        <div class="p-1 mt-1 bg-beige" v-if="message">{{ message }}</div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import DatePickerWidget from "../../editors/DatePickerWidget";
    import DateMixin from '../../../mixins/DateMixin.js.vue';
    import LoadingSpinner from "../../../LoadingSpinner";
    export default {
        name: "NumberRangeFilter",
        components: {
            DatePickerWidget,
            LoadingSpinner
        },
        mixins: [DateMixin],
        props: {
            /**
             * The min value of numbers available through the whole table
             * */
            minValue: {type: Number, default: null},
            /**
             * The max value of numbers available through the whole table
             * */
            maxValue: {type: Number, default: null},
            /**
             * The name of the to be searched field
             * */
            fieldName: {type: String, required: true},
            /**
             * The id of the content (e.g. Asset, Project)
             * */
            contentId: {type: String, required: true},
            /**
             * The type of date: either date, datetime or time
             * */
            type: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0}
        },
        data() {
            return {
                localValue: {
                    min: null,
                    max: null,
                },
                minMaxImported: false,
                loading: true,
                message: ''
            };
        },
        computed: {
            ...mapState({datasetRowListOptions: state => state.datasetrows.datasetRowListOptions}),
                getId: function() {
                    return this.$route.name + this.id  + 'DateRangeFilter';
            },
        },
        watch: {
            datasetRowListOptions: {
                deep: true,
                handler(val) {
                    if(val && !this.minMaxImported) {
                        this.localValue.min = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'min', this.fieldName, 'date');
                        this.localValue.max = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'max', this.fieldName, 'date');
                        if(this.localValue.max) {
                            this.minMaxImported = true;
                            this.filter(true);
                        } else {
                          this.message = this.$t('noFilterData');
                        }
                        this.loading = false;
                    }
                }
            },
            localValue: {
                deep: true,
                handler() {
                    this.filter(false);
                }
            },
            clear() {
                this.clearValue();
            }
        },
        beforeMount() {
            this.$emit('getMinMax', this.fieldName);
            let localFilter = window.localStorage.getItem(this.getId);
            if(localFilter && localFilter.min && localFilter.max) {
                this.localValue = localFilter;
                this.minMaxImported = true;
            }
        },
        methods: {
            clearValue: function(initial = true) {
                let filterParamId = this.getId;
                let params = {
                    remove: {
                        dateSearch: [filterParamId]
                    }
                };
                this.localValue.min = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'min', this.fieldName, 'date');
                this.localValue.max = this.$store.getters['getDatasetRowListOptions'](this.contentId, 'max', this.fieldName, 'date');
                this.$emit('filter', params, initial, this.getId);
            },
            updateMin: function(val) {
                this.localValue.min = val;
                this.filter();
            },
            updateMax: function(val) {
                this.localValue.max = val;
                this.filter();
            },
            filter: function (initial = false) {
                let filterParamId = this.getId;
                let params = {
                    addObject: {
                        dateSearch: {
                            [filterParamId]: {
                                [this.fieldName]: {
                                    value: this.localValue.min,
                                    value2: this.localValue.max,
                                    operator: "bt"
                                }
                            }
                        }
                    },
                };
                /**
                 * sends the filtering content to the parent component
                 * ready to use in a register or loading method
                 * */
                this.$emit('filter', params, initial, this.getId);
                window.localStorage.setItem(this.getId, this.localValue);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>