<template>
  <div class="dropzone-new-style ticketing-dropzone">
    <div class="inner">
      <div class="instruction text-center">
        {{ $t('DROP FILES HERE') }}<br/>
        <icon type="file" size="1.3"/>
      </div>
      <form :id="'dropzone_' + formId" :class="['dropzonejs']">
        <div class="fallback">
          <input name="file" type="file">
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import * as dropzone from '@/dropzone';
import Vue from 'vue';
import Icon from "@/components/Icon";
import {mapState} from "vuex";

Vue.use(dropzone);
export default {
  name: "MultiDropZone",
  components: {
    Icon
  },
  props: {
    formId: {type: String, required: true},
    /**
     * The endpoint URL which means: localhost:3001</this/is/the/endpoint/url>
     * */
    endpointURL: {type: String, required: true},
    /**
     * An array of objects:
     * {
     * key: 'mykey',
     * value: 'myvalue'
     * }
     * */
    attachedData: {
      type: Object, default: () => {
      }
    },
  },
  data() {
    return {
      uploadDropzone: null,
      defaultArgs: {
        url: 'http://www.placeholder.ch',
        timeout: 3600000,
        uploadMultiple: true,
        autoProcessQueue: false,
        headers: {
          "Authorization": 'Bearer ' + this.$store.getters.authToken,
          "Access-Control-Allow-Headers": "*",
        },
      }
    };
  },
  computed: {
    ...mapState({storageClient: state => state.client.storageClient}),
    storageClientURL: function () {
      return this.storageClient && this.storageClient.url ?
          this.storageClient.url.replace('api-docs/storage.yaml', '').split("?")[0] : '';
    },
  },
  beforeMount() {
    if (!this.storageClient || !this.storageClient.url) {
      this.$store.dispatch('loadStorageClient');
    }
  },
  mounted() {
    this.initDropZone();
  },
  methods: {
    initDropZone() {
      const $this = this;
      // eslint-disable-next-line no-undef
      this.uploadDropzone = new Dropzone("#dropzone_" + this.formId, this.defaultArgs);
      this.uploadDropzone.options.maxFilesize = this.maxFileSize;
      this.uploadDropzone.on('addedfile', function (file) {
        $this.uploadDropzone.options.url = $this.storageClientURL + $this.endpointURL;
        console.log(file.name);
        $this.$emit('gotFile');
      });
      this.uploadDropzone.on('sending', function (file, xhr, formData) {
        //$this.uploadDropzone.options.headers.Authorization = 'Bearer ' + $this.$store.getters.authToken;
        if ($this.attachedData) {
          for (const [key, value] of Object.entries($this.attachedData)) {
            formData.append(key, value);
          }
        }
      })
      // eslint-disable-next-line no-unused-vars
      this.uploadDropzone.on("success", function (file, res) {
        $this.$emit('uploadSuccess');
      });
    },
    /**
     * Resumes the stopped process queue
     * (only necessary if autoUpload = false)
     */
    resumeUpload() {
      this.uploadDropzone.processQueue();
    },
    destroyExistingDropZone() {
      if (this.uploadDropzone) {
        this.uploadDropzone.destroy();
        this.uploadDropzone = null;
      }
    },
  }
}
</script>
<style lang="scss">
.ticketing-dropzone {
  .dz-preview {
    background-color: #5b6167;
    max-width: 100px;
    float: left;
    margin: 2px;
    text-align: center;
    border-radius: 6px;
    padding: 3px;
  }

  .dz-image {
    display: none;
  }

  .dz-progress {
    height: 4px;
    background: transparent;
  }

  .dz-success-mark, .dz-error-mark {
    display: inline-block;

    svg {
      width: 15px;
    }
  }

  .dz-error-mark {
  }

  .dz-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss" scoped>
.dropzone-new-style {
  width: 100%;
  padding: 15px;
  background-color: #3B434D;
  border-radius: 4px;

  .inner {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='2' stroke-dasharray='6%2c 11' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 15px;
    min-height: 200px;
    padding: 15px;
    position: relative;
  }

  .dropzonejs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 8px;
  }

  .instruction {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
</style>