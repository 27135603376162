<template>
  <div class="row no-gutters">
    <div class="col-12 mb-2 mt-2" :key="index" v-for="(ticket, index) in tickets">
      <div @click="$emit('selectTicket', ticket.id)" class="the-ticket">
        <div class="ticket-category">{{ ticket['IterationPath']}}</div>
        {{ dateFromISO(ticket['CreatedDate']) }} | <span class="lighter">{{ ticket['id'] }}</span><br />
        <div :class="['ticket-state d-inline-block mr-2', ticket['State'].toLowerCase()]">{{ ticket['State'] }}</div><div :class="['priority priority-' + ticket['Priority']]">{{ ticket['Priority']}}</div>
          <div class="ticket-type mt-2">
            <icon size="1.4" :type="ticket['WorkItemType'].toLowerCase() === 'bug' ? 'mosquito' : 'flask'" /> {{ ticket['Title'] }} <div class="lighter smaller" v-if="getAttachments(ticket)"><icon type="paperclip" /> {{ getAttachments(ticket) }}</div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import DateMixinJs from "@/components/mixins/DateMixin.js";
export default {
  name: "TicketOverView",
  components: {
    Icon
  },
  mixins: [DateMixinJs],
  props: {
    tickets: {
      type: Array, default: () => {
      }
    },
  },
  methods: {
    getAttachments(ticket) {
      return ticket.relations ? ticket.relations.filter(item => {return !!item.attachmentId}).length : 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.the-ticket {
  background-color: #bbb;
  border-radius: 4px;
  position:relative;
}
.ticket-state {
  padding: 3px;
  border-radius: 4px;
  background-color: #be638f;
  &.new {
    background-color: #aaa;
  }
}
.priority {
  padding: 3px;
  border-radius: 4px;
  background-color: #aaa;
  display: inline-block;
}
.the-ticket:hover .priority {
 &.priority-1 {
    background-color: red;
  }
  &.priority-2 {
    background-color: #be638f;
  }
  &.priority-3 {
    background-color: #63be9e;
  }
}
.ticket-row {
   position:relative;
}
.ticket-type {
  padding: 8px;
  position:relative;
  font-size:1rem;
  border-radius: 4px;
  min-height:25px;
  color: #000;
  font-weight: bold;
}
.ticket-category {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4e4f50;
  border-radius: 4px;
  padding: 2px;
}
.smaller {
  font-size: 0.9rem;
}
</style>