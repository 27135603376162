<template>
  <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      tab-selector="permissiontab"
      type="permission"
      back-button-tab-selector="permissionsbackbutton"
      :id="$route.params.id"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                upload-type="Permission"
                :created="$store.getters.getPermissionCreationDate($route.params.id)"
                :last-change="$store.getters.getPermissionChangeDate($route.params.id)"
                :downloadable="true"
                :organization="$store.getters.getPermissionOrganization($route.params.id)"
                preview-id="permissionEdit"
                :removable="true"
    />


    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :taggable="false"
        preview-id="permissionEdit"
    />

        <div slot="mainContent">
            <h1>{{ $store.getters.getPermissionDisplayName(id) }}</h1>
          <p>({{ $store.getters.getPermissionName(id) }})</p>
            <content-field-block
                class="mb-2 mt-3"
                :value="$store.getters.getPermissionDisplayName(id)"
                :id="$route.params.id"
                store-name="Permission"
                field-name="displayName"
                label="displayName"
                input-type="text"
                @update="loadPermission"
            />
            <content-description-block
                    :value="$store.getters.getPermissionDescription(id)"
                    :id="$route.params.id"
                    store-name="Permission"
            />


        </div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import ContentFieldBlock from "@/components/ContentFieldBlock";

    export default {
        name: 'PermissionEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            ContentDescriptionBlock,
            ContentFieldBlock
        },
        data() {
            return {
                id: '',
            };
        },
        beforeMount() {
          this.loadPermission();
          this.id = this.$route.params.id;
        },
        methods: {
          loadPermission() {
              this.$store.dispatch('loadPermission', {
                id: this.$route.params.id,
              }).catch(e => {
                if(e.status === 401) {
                  this.$router.push('/access-denied');
                } else if(e.status === 404) {
                  this.$router.push('/not-found')
                }
              });
            },

  },
}
</script>