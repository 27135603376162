<template>
  <div
      :class="[
            'vform-slide-control-element',
            tiles ? 'vform-tiles' : '',
            !tiles ? config.alignment : '',
            'border-radius'
        ]"
      :style="{
            paddingTop: config.height && config.showastiles ? config.height / 2 + 'vh' : '',
            backgroundColor: config.backgroundColor && tiles ? config.backgroundColor : '',
            borderColor: config.borderColor ? config.borderColor : (config.backgroundColor ? config.backgroundColor : ''),
            color: config.fontColor && tiles ? config.fontColor : (fontColor ? fontColor : '')
        }"
      @click="handleGoTo(null, 'checkout')"
      @mouseenter="tiles ? setColors(buttonId) : () => {}"
      @mouseleave="tiles ? unsetColors(buttonId) : () => {}"
      :id="buttonId"
  >
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :has-template="!!config.templateId"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />

    <div
        :style="{
                'justify-content': 'center',
                'align-items': 'flex-start',
            }"
        v-if="tiles"
        :class="[
                'clickable inner d-flex BUTTON_ITEM',
                tiles ? 'p-2' : 'simple-button',
                config.bottomDropZone ? 'next-button' : '',
                !tiles ? config.alignment : '',
            ]"
    >
      <preview
          v-if="getImage(config, lang)"
          :may-load-now="isActive"
          :preview-id="getImage(config, lang)"
          source="asset"
          :asset-id="getImage(config, lang)"
          :removable="false"
          class="vform-icon-img"
          classes="square-image"
          :style="{
                    width: config.iconSize ? config.iconSize + '%' : '40%',
                }"
          :offline-mode="offlineMode"
          spinner-size="default"
                :show-spinner-message="false"
      />
      <div class="tile-button-text">
        {{ getLabel(config, lang) ? getLabel(config, lang) : $t("logout") }}
      </div>
    </div>
    <div
        v-else
        :class="[
                'clickable inner d-flex align-items-center BUTTON_ITEM',
                tiles ? 'p-2' : 'pt-2 pb-2 pr-3 pl-3',
                config.bottomDropZone ? 'next-button' : '',
                !tiles ? config.alignment : '',
                config.textAlignment ? `text-${config.textAlignment}` : '',
            ]"
        :style="{
              backgroundColor: config.backgroundColor ? config.backgroundColor : '',
              borderColor: config.backgroundColor ? config.backgroundColor : '',
              color: config.fontColor ? config.fontColor : (fontColor ? fontColor : '')
          }"
        :id="buttonId"
        @mouseenter="!tiles ? setColors(buttonId) : () => {}"
        @mouseleave="!tiles ? unsetColors(buttonId) : () => {}"
    >
      <preview
          v-if="getImage(config, lang)"
          :may-load-now="isActive"
          :preview-id="getImage(config, lang)"
          source="asset"
          :asset-id="getImage(config, lang)"
          :removable="false"
          class="mini vform-preview-img vform-icon-img mr-1"
          :style="{
                    flexBasis: config.iconSize ? getInlineIconSize(config.iconSize) + '%' : '40%',
                }"
          :offline-mode="offlineMode"
          @contentLoaded="increaseCounter"
      />
      <div :class="['text', getImage(config, lang) ? '' : 'without-icon']">
        <template v-if="!config.buttonStyle ||  config.buttonStyle === 'Text' || config.buttonStyle === 'TextAndArrow'">
        {{ getLabel(config, lang) ? getLabel(config, lang) : $t("logout") }}
        </template>
        <template v-else><div v-html="'&nbsp;'"></div></template>
      </div>
      <div class="ml-3" v-if="config.buttonStyle === 'Arrow' || config.buttonStyle === 'TextAndArrow'">
        <icon type="angle-right" />
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "./Preview";
import EditorOverlay from "./EditorOverlay";
import Icon from "@/components/Icon";
import ColorContrastMixinJs from "../../mixins/ColorContrastMixin.js";

export default {
  name: "LOGOUT",
  components: {
    Preview,
    EditorOverlay,
    Icon
  },
  mixins: [ColorContrastMixinJs, ElementMixin],
  data() {
    return {
      tiles: false,
      logData: {},
      buttonId: null,
    };
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        if(val) {
          this.tiles = this.isTiles(this.config);
          this.checkColors();
          this.forceReRenderKey++;
        }
      }
    }
  },
  beforeMount() {
    this.tiles = this.isTiles(this.config);
    this.setLogoutData();
    this.buttonId = 'button' + this.config.uuid;
  },
  mounted() {
    if(!this.getImage(this.config, this.lang)) {
      this.increaseCounter();
    }
    this.checkColors();
  },
  methods: {
    checkColors() {
      if (this.config.backgroundColor) {
        this.setInitialColors(this.buttonId);
      }
    },
    setLogoutData() {
      this.logData = {
        data: [],
        type: 'checkout'
      };
    },
    resetLogoutData() {
      this.logData = {};
    },
    getJumpTarget() {
      return this.getJumpTargets();
    },
    getData() {
      if(!this.disabled) {
        return this.logData;
      }
      return {};
    }
  }
}
</script>
