<template>
<div>
  <div class="mb-2 filter-row pb-1 pt-1" :key="index + forceReRenderKey" v-for="(item, index) in filter">
    <div class="d-inline-block mr-2 vform-label white">{{ $t('name') }}</div>
    <select
        @change="(evt) => {setModifier(index, evt.target.value)}"
        class="custom-select form-text-dark mod-selector mr-2"
        :value="filter[index].modifier">
      <option v-if="!['gt', 'lt'].includes(filterModifiers[key])" :key="index2" :value="filterModifiers[key]" v-for="(key, index2) in Object.keys(filterModifiers)">{{ $t(key) }}</option>
    </select>
    <div class="d-inline-block">
      <input type="text" class="form-text form-text-dark" :value="filter[index].val" @input="(evt) => {setString(index, evt.target.value)}" />
    </div>
    <div @click="removeItem(index)" class="d-inline-block ml-5 lighter clickable"><icon size="1.4" type="fat-square-minus" /></div>
  </div>
  <div @click="addRow" class="d-inline-block clickable p-1"><icon type="square-plus" /></div>
</div>
</template>

<script>
import { filterModifiers } from "../../enum";
import Icon from "../Icon";
import {mapState} from "vuex";

export default {
  name: "StringFilter",
  components: {Icon},
  props: {
    filterId: {type: String, default: ''},
    type: {type: String, default: 'filter'}, // filter or merge
  },
  data() {
    return {
      forceReRenderKey: 0,
      filterModifiers: filterModifiers,
      filter: [],
      // this property prevents an endless loop, sending up the newest filter,
      // receiving the newest filter again from parent
      doNotUpdate: false,
      timeout: null
    };
  },
  computed: mapState({exportProfiles: state => state.exportprofile.exportProfiles,}),
  watch: {
    exportProfiles(val) {
      if(val[this.filterId]) {
        this.setFilter();
      }
    },
    filter: {
      deep: true,
      handler() {
        this.$store.dispatch(this.type === 'filter' ? 'overWriteFilterStringArray' : 'overWriteMergeStringArray', {id: this.filterId, value: this.filter});
      }
    },
    type() {
      this.setFilter();
    }
  },
  beforeMount() {
    if(this.exportProfiles[this.filterId]) {
      this.setFilter();
    }
  },
  methods: {
    setString(index, value) {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
      const $this = this;
      this.timeout = setTimeout(() => {
        $this.filter[index].val = value;
      }, 300);
    },
    setFilter() {
      const typer = this.type.charAt(0).toUpperCase() + this.type.slice(1);
      this.filter = this.$store.getters['getExport' + typer +'StringById'](this.filterId);
    },
    setModifier(index, value) {
      this.$set(this.filter[index], "modifier", value);
    },
    addRow() {
      this.filter.push({field: "name", modifier: "like", val: this.$t('filterSearchSomething')});
      this.doNotUpdate = true;
      this.forceReRenderKey++;
    },
    removeItem(index) {
      this.filter.splice(index, 1);
      this.forceReRenderKey++;
    },
  }
}
</script>

<style scoped>
.mod-selector {
  width: 200px;
  display: inline-block;
}
.form-text-dark {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
</style>