<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                       @filter="filtering"
                        :id="listName"
                        store-name="Material"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getMaterialListOptions(listName, 'pagination_items')"
                id="materialstablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />

      <div
        slot="mainContentButtons"
      >
        <div v-if="$store.getters.isSuperAdmin" class="float-right" style="position: relative; z-index: 500">
          <button-field
              class="float-right"
              size="medium"
              item-id="maintable"
          >
            <div class="p-3">
              <Button
              permission="create"
              type="action-button" link="/library/3d-data/add-asset-bundle" icon="robot">
              {{ $t('createNewAssetBundle') }}
            </Button>
            </div>
          </button-field>
        </div>
        <Button
            :class="$store.getters.isSuperAdmin ? 'mr-5' : ''"
            permission="create"
            type="action-button" link="/library/materials/add" icon="plus">
            {{ $t('createNewType', {type: 'material'}) }}
        </Button>
        </div>


      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'sfxdataDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                @deleteRows="deleteRows"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                :permission-by-type="{'model': 'write', 'node': 'write', 'assembly':'createMetaSet'}"
                delete-permission="delete"
                :fields="tableFields"
                :data="$store.getters.getMaterialsByListName(listName)"
                :title-as-links="true"
                :use-team-permission="true"
                :show-state="$store.getters.isSuperAdmin"
                :loading="loading"
                @sort="sort"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'sfxdataDefaultThumbnails'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="thumbnailFields"
                :data="$store.getters.getMaterialsByListName(listName)"
                :title-as-links="true"
                :permission-by-type="{'model': 'write', 'node': 'write', 'assembly':'createMetaSet'}"
                :show-state="$store.getters.isSuperAdmin"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import ButtonField from "@/components/forms/ButtonField";
    import {AssetStates} from "@/enum";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

    export default {
        name: "SFXData",
      mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
            ButtonField
        },
        data() {
            return {
                AssetStates: AssetStates,

                listName: 'MaterialList',
              registerMethod: 'registerMaterialQueryParams',
              loadMethod: 'loadMaterials',
              include: 'tags,teams',
              deleteMethod: 'deleteMaterial',
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'DisplayName',
                        id:'displayName',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                      sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                  {
                    name: 'DisplayName',
                    id:'displayName',
                    type: 'string',
                    sortable: true
                  },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
    }
</script>