<template>
  <div
      :class="['w-100 vform-text-display mb-3 position-relative', config.emphasized ? 'vform-emphasized font-weight-bold' : '']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <!--    <canvas :id="chartId" :title="'test'" height="200" width="400"></canvas>-->
    <div v-if="!config.sensors">
      NO SENSOR SELECTED
    </div>
    <div v-else-if="config.display === 'text' || config.display === ''">
      <div v-if="name" class="inter-bold">{{ name }}</div>
      <div
          v-for="(sensor, index) in sensors"
          :key="sensor.id + forceReRenderKey"
          class="current-sensor-value d-flex mb-1"
      >
        <div class="box" :style="{ borderColor: colors[index]}">
        </div>
        <span class="ml-1" :key="forceReRenderKey" v-if="sensor.currentValue">
          {{ sensor.name }}
          <span v-if="sensor.currentValue.unit">{{ sensor.currentValue.unit }}:</span>{{ sensor.currentValue.value }}
        </span>
        <span v-else>
          Waiting for data
        </span>
      </div>
    </div>
    <div v-else-if="config.display === 'line'">
      <div v-if="name" class="inter-bold">{{ name }}</div>

      <div
          v-for="(sensor, index) in sensors"
          :key="sensor.id + forceReRenderKey"
          class="current-sensor-value d-flex mb-1"
      >
        <div class="box" :style="{ borderColor: colors[index]}">
        </div>
        <span class="ml-1" :key="forceReRenderKey" v-if="sensor.currentValue">
          {{ sensor.name }}
          <span v-if="sensor.currentValue.unit">{{ sensor.currentValue.unit }}:</span>{{ sensor.currentValue.value }}
        </span>
        <span v-else>
          Waiting for data
        </span>
      </div>
      <canvas :id="chartId" height="200" width="400"></canvas>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js";
import Chart from 'chart.js/auto';

export default {
  name: "Sensor",
  components: {
    EditorOverlay,
  },
  mixins: [ElementMixin, MetaSetsMixin],
  data() {
    return {
      forceReRenderKey: 0,

      log: [],
      type: 'string',
      dataSetId: '',
      name: '',
      isLogging: false,
      stopLogging: false,

      myChart: null,
      chartId: null,
      passedSeconds: 0,
      canvas: '',
      labels: [],
      lastHash: null,
      yAxisUnit: '',

      isFirstFeedRead: true,
      isInitialLabelSet: false,
      sensors: [],
      sensorsDataSet: [],
      colors: ['rgb(75, 192, 192)', 'rgb(255, 45, 45)', 'rgb(55, 255, 45)'],

    };
  },
  computed: {
    currentMeasurement() {
      if (!this.log || !this.log.length) {
        return null;
      }

      return this.log[this.log.length - 1];
    },
    currentValue() {
      if (!this.log || !this.log.length) {
        return '';
      }

      let lastSensorData = this.log[this.log.length - 1];
      let value = lastSensorData.value;
      let momentDate = this.$moment(lastSensorData.timestamp, 'DD-MM-YYYYThh:mm:ss', false);
      if (this.type === 'string') {
        value = value.trim();
        //console.log(momentDate);
        return `${lastSensorData.unit}: ${unescape(value)} at ${momentDate.format("DD.MM.YYYY HH:mm:ss")}`;
      } else if (this.type === 'number') {
        return `${lastSensorData.unit}: ${value} at ${momentDate.format("DD.MM.YYYY HH:mm:ss")}`;
      }
      return "";
    },
    datasets() {
      return this.log && this.log.length ? this.log.map(item => {
        return {data: [{y: item.value, x: this.$moment(item.timestamp).format('HH:mm:ss')}]}
      }) : [];
    }
  },
  watch: {
    config: {
      deep: true,
      async handler(val) {
         if(!val.sensors) {
           this.sensors = [];
         } else {
           await this.init();
           // eslint-disable-next-line no-unreachable
           /*for(let i = 0; i < this.sensors.length; i++) {
             const {id} = this.sensors[i];
             if(!val.sensors.includes(id)) {
               const index = this.sensors.findIndex(item => {return item.id === id});
               this.sensors.splice(index, 1);
             }
           }
           for(let i = 0; i < val.sensors.length; i++) {
             const id = val.sensors[i];
             if(!id) {
               continue;
             }
             if(!this.sensors.filter(item => {return item.id === id}).length) {
               await this.loadSensor(id);
             }
           }
           this.forceReRenderKey++;
           this.initChart();*/
         }
      }
     },
    // isActive(val) {
    //   if (val) {
    //     //this.loadSensor();
    //   } else {
    //     this.stopLogging = true;
    //   }
    // },
  },
  beforeMount() {
    this.chartId = Math.random().toString(36).substring(2, 6);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.stopLogging = true;
  },
  methods: {
    async init() {
      this.stopLogging = true;
      if (this.myChart) {
        this.myChart.destroy();
        this.myChart = null;
      }
      this.log = [];
      this.labels = [];
      await this.loadSensors();
      this.stopLogging = false;
      await this.logFeeds();
      this.forceReRenderKey++;
    },
    initChart() {
      if (this.myChart) {
          this.myChart.destroy();
      }
      const ctx = document.getElementById(this.chartId).getContext('2d');

      //create datasets
      let sensorDatasets = [];
      this.sensors.forEach((sensor) => {
        let dataset = {
          label: sensor.name,
          data: sensor.chartData,
          fill: false,
          borderColor: this.colors[this.sensors.findIndex(x => x.id === sensor.id)],
          tension: 0.1,
        };
        sensorDatasets.push(dataset);
      });

      this.myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: sensorDatasets,
        },
        options: {
          scales: {
            // x: {
            //   title: {
            //     display: true,
            //     text: 'Month'
            //   }
            // },
            y: {
              title: {
                display: true,
                text: decodeURIComponent(this.yAxisUnit)
              },
               min: this.config.yAxisMinCustom ? parseInt(this.config.yAxisMinCustom) : this.config.yAxisMinDefault ? parseInt(this.config.yAxisMinDefault) : null,
               max: this.config.yAxisMaxCustom ? parseInt(this.config.yAxisMaxCustom) : this.config.yAxisMaxDefault ? parseInt(this.config.yAxisMaxDefault) : null,
               //max: parseInt(this.config.yAxisMax),
              // ticks: {
              //   // forces step size to be 50 units
              //   stepSize: 50
              // }
            }
          }
        }
      });
    },
    async updateChartData(sensor, data) {
      this.yAxisUnit = data.unit;
      if (this.sensors.length > 1) {
        this.labels.push(this.$moment().format("hh:mm:ss"));
      } else {
        const timestamp = this.$moment(data.timestamp, 'DD-MM-YYYYThh:mm:ss', true);
        if (timestamp.isValid()) {
          this.labels.push(timestamp.format("hh:mm:ss"));
        } else {
          this.labels.push(this.$moment().format("hh:mm:ss"));
        }
      }

      if (this.type === 'number') {
        //console.log('is number')
        if (!this.myChart && document.getElementById(this.chartId)) {
          //console.log('no chart, starting init...')
          this.initChart();
        }
        console.log('push chart data for' + sensor.name)
        sensor.chartData.push(parseInt(data.value));

        //push for all other sensors copy of last logged data
        this.sensors.filter(x => {
          return x.id !== sensor.id
        }).forEach((s) => {
          if (s.log && s.log.length) {
            s.chartData.push(s.log[s.log.length - 1].value);
          }
        });

        if (this.myChart) {
          this.myChart.update();
        }
        this.forceReRenderKey++;
      }
    },

    /**
     * Loads a sensor asset and retrieves the dataset in which the data is stored
     * and sets the scale accordingly to the min/max info
     * */
    async loadSensor(sensorId) {
      let sensorAssetId = '';
      if (sensorId) {
        return await this.$store.dispatch('clientLoadAsset', {
          id: sensorId,
          include: 'metaSets'
        }).then(asset => {
          sensorAssetId = asset.id;
          const {metaSets} = asset;
          asset.chartData = [];
          asset.log = [];
          this.sensors.push(asset);
          const metaObj = this.smashMetaSets(metaSets);
          this.applySensorMetaInfo(metaObj);
          const {feed} = metaObj;
          if (feed) {
            return this.$store.dispatch('clientLoadAsset', {
              id: feed,
              include: 'metaSets'
            });
          }
        }).then(feed => {
          const {metaSets} = feed;
          const metaObj = this.smashMetaSets(metaSets);
          const {sensorDataSet} = metaObj;
          if (sensorDataSet) {
            let sensor = this.sensors.find((s) => s.id === sensorAssetId);
            if (sensor) {
              sensor.sensorDataSet = sensorDataSet;
            }
          }
        })
      }
    },
    async applySensorMetaInfo(sensorMetaInfo){
      if (sensorMetaInfo.threshold1 && (!this.config.yAxisMinDefault || this.config.yAxisMinDefault > sensorMetaInfo.threshold1)){
        this.config.yAxisMinDefault = sensorMetaInfo.threshold1;
      }
      if (sensorMetaInfo.threshold2 && (!this.config.yAxisMaxDefault || this.config.yAxisMaxDefault < sensorMetaInfo.threshold2)){
        this.config.yAxisMaxDefault = sensorMetaInfo.threshold2;
      }
    },
    /**
     * Loads the sensor assets
     * **/
    async loadSensors() {
      this.config.yAxisMaxDefault = null;
      this.config.yAxisMinDefault = null;
      if (!this.logging && this.config.sensors) {
        this.sensors = [];
        const allSensorsLoad = [];
        for(let i = 0; i < this.config.sensors.length; i++) {
          const sensorId = this.config.sensors[i];
          if(sensorId) {
            allSensorsLoad.push(this.loadSensor(sensorId))
          }
        }
        return await Promise.all(allSensorsLoad); // resolving all promises
      } else {
        this.$emit('increasePostLoadingCounter')
      }
    },

    async loadFeedValue(sensor) {
      this.$store.dispatch('clientLoadDatasetRows', {
        id: sensor.sensorDataSet,
        filter: 'key eq ' + sensor.id
      }).then(rows => {
        if (rows && rows[0]) {
          const {timestamp, value} = rows[0].data;
          if (!sensor.chartData.length || (timestamp !== sensor.lastHash)) {
            //console.log('pushing to logs...');
            if (parseFloat(value)) {
              this.type = 'number';
            }

            sensor.currentValue = rows[0].data;
            sensor.log.push(rows[0].data);
            sensor.lastHash = timestamp;
            sensor.triggerChartUpdate = true;
          }
        }
      }).catch(e => {
        console.log(e);
      })
    },
    /**
     * logs the feeds
     * */
    async logFeeds() {
      this.isLogging = true;
      // loads current sensor values
      const allFeedsData = [];
      for(let i = 0; i < this.sensors.length; i++) {
        allFeedsData.push(this.loadFeedValue(this.sensors[i]));
      }
      await Promise.all(allFeedsData); // resolving all promises

      // update chart data
      const toBeUpdatedSensors = this.sensors.filter((s) => {
        return s.triggerChartUpdate;
      });
      for(let i = 0; i < toBeUpdatedSensors.length; i++) {
        let sensor = toBeUpdatedSensors[i];
        sensor.triggerChartUpdate = false;
        await this.updateChartData(sensor, sensor.log[sensor.log.length - 1]);
      }

      const $this = this;
      this.timeout = setTimeout(() => {
        this.isFirstFeedRead = false;
        if ($this.stopLogging) {
          $this.stopLogging = false;
          return;
        }
        //$this.passedSeconds = $this.passedSeconds + 1;
        $this.logFeeds();
      }, 2000);

    },
    validate() {
      return true;
    },
    checkRequired() {
      return true;
    },
    getData() {
      //return {data: this.getLabel(this.config, this.lang), type: 'sensor'};
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;
}

.current-sensor-value .box{
  border-width: 2px;
  border-style: solid;
  width: 25px;
}
</style>