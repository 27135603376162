<template>
  <one-column-sidebar-left
      back-button-tab-selector="appbackbutton"
  >
    <div slot="buttonsRight">
      <Button
          :deactivated="!enableSaving"
          class="mb-3"
          link="/app/add"
          @click="save">
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>

    <div slot="mainContent">
      <h1>
        <icon class="mr-2" size="0.9" type="app"/>
        |
        <icon class="mr-2 ml-2" size="0.9" type="webapp"/>
        {{ $t('createNewApp') }}
      </h1>

      <organization-switcher
          class="mb-2"
          @setOrganization="id => {organizationId = id}"
          @setTeam="id => {teamId = id}"
          @unsetTeam="teamId = ''"/>
      <input
          :id="'name'"
          v-model="name"
          :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
          :placeholder="$t('enternamehere')"
          type="text"
          @input="delayTouch($v.name)"
      >

      <div v-if="$v.name.$dirty && !$v.name.required && $v.name.$error" class="form-error">{{
          $t('errors.required')
        }}
      </div>
      <div v-if="$v.name.$dirty && !$v.name.alphaNumSpace" class="form-error">{{ $t('errors.alphaNumSpaceOnly') }}</div>

      <div v-if="$v.name.$dirty && !$v.name.maxLength" class="form-error">{{
          $t('errors.atMostCharacters', {num: 128})
        }}
      </div>

      <div v-if="$v.name.$dirty && !$v.name.minLength" class="form-error">{{
          $t('errors.atLeastCharacters', {num: 3})
        }}
      </div>
      <div v-if="$v.name.$dirty && !$v.name.isUnique" class="form-error">{{ $t('errors.appNameAlreadyExists') }}</div>

      <div class="inline-edit-label">
        <b>{{ $t('description') }}</b>
      </div>
      <div>
                 <textarea
                     :id="'description'"
                     v-model="description"
                     :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                     :placeholder="$t('enterdeschere')"
                     @input="delayTouch($v.description)"
                 />
        <div v-if=" $v.description.$dirty && !$v.description.maxLength" class="form-error">
          {{ $t('errors.atMostCharacters', {num: 128}) }}
        </div>
      </div>
      <div>
        <div class="inline-edit-label mb-1">
          <b>{{ $t('chooseAppType') }}</b>
        </div>
        <br>
        <div class="col-12 col-md-4 neutralize-margin-left">
          <tile-button :icons="true" :initial-active-tiles="['app']" :rows="2" :tiles="['app', 'webapp']"
                       :values="['app', 'webapp']" @trigger="updateType"/>
        </div>
        <div v-if="type === 'webapp'" class="mt-3">
          {{ $t('pleaseSelectAType') }}
          <div class="w-100"/>
          <div class="row col-12 mt-2">
            <div
                v-for="(webapp) in $store.getters.getWebappsByListName('GlobalWebapps')"
                :key="webapp.id"
                :class="['col-3 bg-beige p-3 mb-1 mr-1 d-inline-block', webappId === webapp.id ? 'webapp-highlight' : '']">
              <div v-if="webappId !== webapp.id" class="bg-dark p-1 border-radius d-inline-block clickable"
                   @click="webappId = webapp.id;">CHOOSE
              </div>
              <div class="label mb-3">{{ webapp.name.toUpperCase() }}</div>
              <div class="smaller">{{ webapp.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import {mapState} from 'vuex';
import Button from "../components/forms/Button";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import Icon from "../components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../customValidators';
import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
import TileButton from "../components/forms/TileButton";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
  name: 'AppAdd',
  components: {
    OrganizationSwitcher,
    Button,
    OneColumnSidebarLeft,
    Icon,
    TileButton,
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      nameEdit: true,
      descEdit: true,
      id: '',
      enableSaving: false,
      name: '',
      description: '',
      type: 'app',
      organizationId: '',
      teamId: '',
      webappId: null,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfAppNameExists', {
          name: value,
          type: 'Project',
          value: value,
          organizationId: this.organizationId,
        }).then(data => {
          bool = data.length === 0;
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    }
  },
  computed: mapState({appsTmp: state => state.apps.appsTmp,}),
  watch: {
    appsTmp(newValue) {
      if (newValue['last_inserted']) {
        let id = newValue['last_inserted'];
        if (this.type === 'app') {
          this.$router.push('/app/' + id + '/project');
        } else {
          this.$router.push('/app/' + id + '/linked_data');
        }
      }
    },
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
  },
  beforeMount() {
    this.$store.dispatch('loadWebapps', {listName: 'GlobalWebapps'});
  },
  methods: {
    updateType(args) {
      this.type = args.value;
    },
    save: async function () {
      if (this.enableSaving) {
        let args = {
          name: this.name,
          slug: this.name,
          description: this.description,
          type: this.type,
          //externalId: this.projectId
        };
        args.organizationId = this.organizationId;
        if (this.teamId) {
          args.teams = [this.teamId];
        }
        await this.$store.dispatch('createApp', args)
            .then(async app => {
              if (app.type === 'webapp') {
                return await this.$store.dispatch('clientCreateProjectInstances', {
                  id: app.id,
                  args: [{assetId: this.webappId}]
                });
              }
            });
      }
    },
  },
}
</script>

<style lang="scss">
.webapp-highlight {
  background-color: $highlight !important;
}
</style>