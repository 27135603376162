<template>
  <div class="form-section">
    <div class="row">
      <template
          v-if="content.pipeline">
        <div v-for="(item, key) in content.pipeline"
             v-if="showFields.includes(key)" :key="key"
             class="w-100 d-flex">
          <div class="col-12 col-md-2 mt-2 lighter">{{ $t(key) }}:</div>
          <div class="col-12 col-md-6 mt-2">{{ Array.isArray(item) ? item.join(', ') : item }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportViewer",
  props: {
    content: {type: Object, default: () => {return {}}}
  },
  data() {
    return {
      showFields: [
        "rootNodeFile", "email", "outputFormats", "lod", "files"
      ],
    }
  },
}
</script>

<style scoped>

</style>