<template>
<div class="maintenance-page">
  <logo />
  <div class="maintenance-msg mt-3 form-error p-2">
    <icon type="robot" size="2" class="float-left mr-3" />
    {{ $t('We are currently maintaining this application, please try again later') }}
  </div>
</div>
</template>

<script>
import Logo from "@/assets/svgs/Logo";
import Icon from "@/components/Icon.vue";
export default {
  name: "Maintenance",
  components: {Logo, Icon}
}
</script>

<style scoped lang="scss">
.maintenance-page {
  width: 300px;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.maintenance-msg {

}
</style>