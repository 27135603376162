<template>
<div class="lighter">
  {{ $t('SetByTemplate') }}
</div>
</template>

<script>
export default {
  name: "SetByTemplate"
}
</script>