<template>
  <div>
    <select v-model="role">
      <option :key="index" v-for="(role, index) in possibleRoles" :value="role">{{ $t(role) }}</option>
    </select>
  </div>
</template>

<script>
import {UserRoles} from "@/enum";

export default {
name: "RoleSelector",
  props: {
    preSelectedRole: {type: String, default: 'viewer'}
  },
  data() {
    return {
      possibleRoles: UserRoles,
      role: '',
    };
  },
  watch: {
    role() {
      this.$emit('update', this.role);
    }
  },
  beforeMount() {
    this.role = this.preSelectedRole;
  }
}
</script>

<style scoped>

</style>