<template>
    <div class="filterSection">
        <h3>{{ $t('filter.fieldSelector') }}</h3><br>
        <div class="d-flex">
            <select class="custom-select"
                    ref="fieldSwitch"
                    v-model="selectedIndex"
                    @change="setField"
                    placeholder="Select a value"
            >
                <option v-for="(field, index) in selectableFieldList"
                        :key="index"
                        :value="index"
                        v-if="!selectedFieldList.filter(item => {return item.columnIndex === field.columnIndex}).length"
                >
                    {{ field.name }}
                </option>
            </select>
        </div>
        <div class="filterSection mt-3 mr-2" :key="index + field.name" v-for="(field, index) in selectedFieldList">
            {{ field.name }} <div @click="removeField(field)" class="ml-3 float-right clickable"><icon type="times" /></div>
            {{ $store.getters['getDatasetRowListOptions'](id, 'max', field.name) }}
            <component
                    @getMinMax="getMinMax"
                    @filter="filter"
                    :is="getComponent(field.type)"
                    :id="field.name + field.type"
                    :content-id="contentId"
                    :ref="field.name + 'Filter'"
                    :field-name="field.name"
                    :type="field.type"
                    :clear="clear"
            />
        </div>
    </div>
</template>

<script>
    import Icon from "../../../Icon";
    import NumberFilter from "./NumberRangeFilter";
    import TextFieldFilter from "./TextFieldFilter";
    import DateRangeFilter from "./DateRangeFilter";

    export default {
        name: "FieldSelector",
        components: {
            Icon,
            NumberFilter,
            TextFieldFilter,
            DateRangeFilter
        },
        props: {
            fieldList: {type: Array, required: true},
            id: {type: String, required: true}, //the id used as a reference by all widgets
            fieldPushSelectedItem: {type: Object, default: null},
            contentId: {type: String, required: true}, //the id of the asset/project/dataset etc.
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0}
        },
        data() {
            return {
                selectedIndex: null,
                selectedFieldList: [],
                selectableFieldList: [],
            };
        },
        computed: {
            getId: function() {
                return this.$route.params.id + this.id  + 'FieldSelector';
            }
        },
        watch: {
            //if an outside component wants to push a filter field
            fieldPushSelectedItem: function(field) {
                if(field) {
                    if(!this.selectedFieldList.filter(item => {return item.columnIndex === field.columnIndex}).length) {
                        this.selectedFieldList.push(field);
                        this.saveSelectedList();
                    }
                }
            },
            fieldList: function(list) {
                if(list.length) {
                    this.selectableFieldList = this.fieldList;
                }
            }
        },
        beforeMount() {
            this.selectableFieldList = this.fieldList;
            let tempSelectedFieldList = JSON.parse(window.localStorage.getItem(this.getId));
            if(tempSelectedFieldList && tempSelectedFieldList.length) {
                this.selectedFieldList = tempSelectedFieldList;
                    //fieldSelector has its own counter in content filter
                if(tempSelectedFieldList.length - 1 > 0) {
                    this.$emit('registerComponent', tempSelectedFieldList.length - 1);
                }
            }
           if(!tempSelectedFieldList || tempSelectedFieldList.length === 0) {
               this.filter({}, true, 'fieldSelector');
           }
        },
        methods: {
            getMinMax: function(fieldName) {
                this.$emit('getMinMax', fieldName);
            },
            saveSelectedList: function() {
                window.localStorage.setItem(this.getId,  JSON.stringify(this.selectedFieldList));
            },
            setField: function() {
                let i = this.selectedIndex;
                let field = this.fieldList[i];
                if(field) {
                    this.selectedFieldList.push(field);
                    /*this.selectableFieldList.splice(i,1);*/
                    this.selectedIndex = null;
                    this.saveSelectedList();
                    this.$emit('registerComponent');
                }
            },
            removeField: function(field) {
                let refName = field.name + 'Filter';
                this.$refs[refName][0].clearValue(false); //every filter component must implement a method to remove its values from the vuex-store before it gets destroyed
                let index = this.selectedFieldList.findIndex(item => {return field.columnIndex === item.columnIndex});
                this.selectedFieldList.splice(index, 1);
                this.saveSelectedList();
                this.$emit('unregisterComponent');
            },
            getComponent: function(type) {
                switch(type) {
                    case 'number':
                        return 'NumberFilter';
                    case 'string':
                        return 'TextFieldFilter';
                    case 'text':
                        return 'TextFieldFilter';
                    case 'timestamp':
                        return 'DateRangeFilter';
                    case 'time':
                        return 'DateRangeFilter';
                }
            },
            filter: function(params, initial = false, componentName) {
                this.$emit('filter', params, initial, componentName);
            }
        },
    }
</script>

