var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('one-column-sidebar-left',{attrs:{"back-button-tab-selector":"materialmapperbackbutton"}},[_c('Button',{staticClass:"mb-3 mt-5",attrs:{"slot":"buttonsRight","deactivated":!_vm.enableSaving},on:{"click":_vm.save},slot:"buttonsRight"},[(_vm.enableSaving)?_c('icon',{attrs:{"type":"save"}}):_c('icon',{attrs:{"type":"ban"}}),_vm._v(_vm._s(_vm.$t("SAVE"))+" ")],1),_c('navigation-panels-container',{attrs:{"slot":"subMenu","id":"library","tab-selector":"librarypanel"},slot:"subMenu"}),_c('div',{attrs:{"slot":"mainContent"},slot:"mainContent"},[_c('h1',[_c('icon',{staticClass:"mr-2",attrs:{"type":"table","size":"0.9"}}),_vm._v(_vm._s(_vm.$t("createNewMaterialMapper"))+" ")],1),_c('organization-switcher',{staticClass:"mb-2",on:{"setOrganization":function (id) {
                    _vm.organizationId = id;
                },"setTeam":function (id) {
                    _vm.teamId = id;
                },"unsetTeam":function($event){_vm.teamId = ''}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:[
                'form-text mb-3 mt-3',
                _vm.$v.name.$dirty && _vm.$v.name.$error ? 'form-group--error' : '' ],attrs:{"type":"text","id":'name',"placeholder":_vm.$t('enternamehere')},domProps:{"value":(_vm.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.name=$event.target.value},function($event){return _vm.delayTouch(_vm.$v.name)}]}}),(_vm.$v.name.$dirty && !_vm.$v.name.required && _vm.$v.name.$error)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.required"))+" ")]):_vm._e(),(_vm.$v.name.$dirty && !_vm.$v.name.alphaNumSpace)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.alphaNumSpaceOnly"))+" ")]):_vm._e(),(_vm.$v.name.$dirty && !_vm.$v.name.maxLength)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.atMostCharacters", { num: 128 }))+" ")]):_vm._e(),(_vm.$v.name.$dirty && !_vm.$v.name.minLength)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.atLeastCharacters", { num: 3 }))+" ")]):_vm._e(),(_vm.$v.name.$dirty && !_vm.$v.name.isUnique)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.materialmapperNameAlreadyExists"))+" ")]):_vm._e(),_c('div',{staticClass:"inline-edit-label"},[_c('b',[_vm._v(_vm._s(_vm.$t("description")))])]),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],class:[
                    'form-text mb-3',
                    _vm.$v.description.$dirty && _vm.$v.description.$error
                        ? 'form-group--error'
                        : '' ],attrs:{"id":'description',"placeholder":_vm.$t('enterdeschere')},domProps:{"value":(_vm.description)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.description=$event.target.value},function($event){return _vm.delayTouch(_vm.$v.description)}]}}),_vm._v(" "),(_vm.$v.description.$dirty && !_vm.$v.description.maxLength)?_c('div',{staticClass:"form-error"},[_vm._v(" "+_vm._s(_vm.$t("errors.atMostCharacters", { num: 128 }))+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }