<template>
  <div class="row">
    <div :class="[classes]">
      <!--SELECTION import CAD | import Hierarchy-->
      <tile-button
          v-if="showToggler && values.length > 1 && (!assetId || $store.getters.getSfxdataPipelineStatus($route.params.id) !== 'processing')"
          :icons="true"
          :initial-active-tiles="[tiles[0]]"
          :rows="2"
          :tiles="tiles"
          :values="values"
          @trigger="updateAction"
      />
    </div>
    <div class="w-100 mt-3 mb-3"/>
    <!--UPLOAD DROPZONE-->
    <upload-drop-zone
        v-if="!assetId || $store.getters.getSfxdataPipelineStatus($route.params.id) !== 'processing'"
        :ref="refId"
        :accepted-file-types="fileConfig[action]"
        :asset-description="assetDescription"
        :asset-id="assetId"
        :asset-name="assetName"
        :attributes='JSON.stringify({"type": "VHUB_cad"})'
        :auto-update-preview="false"
        :auto-upload="autoUpload"
        :class="[!file ? classes : 'col-6 col-md-6 col-lg-4']"
        :downloadable="false"
        :image-editable="true"
        :is-loading="isLoading"
        :make-chunks="makeChunks"
        :max-file-size="1000000"
        :organization-id="organizationId"
        :removable="false"
        :target-type="targetConfig[action]"
        :team-id="teamId"
        asset-type="assembly"
        form-id="uploadSfx"
        @data="setData"
        @fileReceived="fileReceived"
        @filesInQueue="filesInQueue"
        @queueEmpty="queueEmpty"
        @ready="showToggler = true"
    >
      <div v-if="action === 'uploadImport'" class="mb-3">
        **{{ $t('attentionUploadStartsProcess') }}
      </div>
    </upload-drop-zone>
    <div class="w-100"/>
    <div v-if="file && !hideForm && isZip && action === 'pipeline'" class="col-12 col-md-8 col-lg-6 mt-1">
      <!--:allowed-file-types="zipSelectablerootNodeFiles"-->
      <zip-file-selector
          :zip="file"
          class="mt-5"
          description="pleaseChooseRoot"
          label="rootFile"
          @selected="(id) => {setProperty(id, 'rootNodeFile'); checkSaving();}"
          @unselected="setProperty(null, 'rootNodeFile'); setProperty(null, 'clientMetaFile'); checkSaving();"
      />
      <zip-file-selector
          :allowed-file-types="['xml', 'json', 'txt']"
          :file-stream-on-selection="true"
          :zip="file"
          class="mt-5"
          description="pleaseChooseDataFile"
          label="dataDescriptionFile"
          @getFileAsString="getMetaFile"
          @selected="(id) => {setProperty(id, 'clientMetaFile'); checkSaving();}"
          @unselected="unsetMetaFile(); setProperty(null, 'clientMetaFile'); checkSaving();"
      />
      <div v-if="validationError" class="form-error mt-3">{{ $t(validationError) }}</div>
    </div>
    <div v-if="rootNodeFile || clientMetaFile" class="col-12 mt-3">
      <div v-if="clientMetaFile" class="row">
        <div class="col-12 col-md-4 mb-5">
          <label class="lighter mt-2">{{ $t('PostProcessingForMeta') }}</label><br>
          <select v-model="vhubPostProcessor" class="custom-select" @change="checkSaving">
            <option v-for="(key, value) in vhubPostProcessorOptions" :key="key" :value="value">{{
                $t(key.name)
              }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="rootNodeFile" class="lighter">{{ $t('rootFile') }}</div>
      {{ rootNodeFile }}<br>
      <div v-if="clientMetaFile" class="lighter mt-2">{{ $t('metaFile') }}</div>
      {{ clientMetaFile }}
    </div>
    <div v-if="assetId && $store.getters.getSfxdataPipelineStatus(this.$route.params.id) === 'processing'"
         class="col-12 col-md-8">
      {{ $t('fileProcessingCannotUpload') }}
    </div>
    <div class="col-12">
      <div v-if="error" class="form-error mt-3">{{ $t(error) }}</div>
    </div>
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone";
import TileButton from "@/components/forms/TileButton";
import ZipFileSelector from "@/components/files/ZipFileSelector";
import {getColumnNames} from "../fileProcessors/xmlWindchillSearchRes";
import FileTypeMixin from "@/components/files/FileTypeMixin.js.vue";
import {importable3DFiles, metaDataFileFormats, available3DFiles} from '@/enum';
import {importableRootFiles} from "../../enum";

export default {
  name: "AssemblyUpload",
  components: {
    UploadDropZone,
    TileButton,
    ZipFileSelector
  },
  mixins: [
    FileTypeMixin
  ],
  props: {
    /**
     * @assetId Text the assetId
     */
    assetId: {type: String, default: null},
    /**
     * @organizationId String the organizationId
     */
    organizationId: {type: String, default: null},

    teamId: {type: String, default: null},
    /**
     * @autoUpload Boolean Whether or not to upload the file right after drag and drop event
     */
    autoUpload: {type: Boolean, default: true},
    /**
     * If an asset needs to be created: type, name and description can be set
     * @assetName String a name
     * @assetDescription String an optional description
     */
    assetName: {type: String, default: ''},
    assetDescription: {type: String, default: ''},

    classes: {type: String, default: 'col-12 col-md-8 col-lg-6 mt-1'},

    /**
     * @makeChunks Whether or not to upload the file in chunks
     * */
    makeChunks: {type: Boolean, default: false},
    /**
     * If update-process: add the id of the existing root node here
     * */
    existingRootNode: {type: String, default: ''},
  },
  data() {
    return {
      error: '',
      validationError: '',
      vhubPostProcessorOptions: {
        'none': {
          name: 'none'
        },
        ...metaDataFileFormats
      },
      vhubPostProcessor: 'none',
      refId: Date.now() + 'ref',
      enableSaving: false,
      previewUri: '',
      description: '',
      content: '',
      tiles: ['cadConvert'],
      values: ['pipeline'],
      action: 'pipeline',
      fileConfig: {
        'pipeline': Object.keys(this.$store.getters.isSuperAdmin ? available3DFiles : importable3DFiles).map(item => {
          return '.' + item
        }).join(','),
        'uploadImport': '.zip',
      },
      targetConfig: {
        'pipeline': 'Asset',
        'uploadImport': 'assemblyZipImport',
      },
      showToggler: false,
      file: null,
      isLoading: false,
      rootNodeFile: null,
      clientMetaFile: null,
      hideForm: false,
      metaFileText: '',
      metaFileName: '',
      isZip: false,
      zipSelectablerootNodeFiles: [],
    };
  },
  beforeMount() {
    this.zipSelectablerootNodeFiles = Object.keys(importableRootFiles).map(item => {
      return item.toLowerCase();
    });
    if (this.$store.getters.isSuperAdmin) {
      this.tiles.push('importHierarchy');
      this.values.push('uploadImport');
    }
  },
  methods: {
    /**
     * This method checks that there is at least one rootNode selected
     * and that there is either no meta file or the meta file is properly configured
     * */
    checkSaving: function () {
      this.error = '';
      this.enableSaving = this.checkMetaFile() && this.checkRootNode() && !this.validationError;
      this.$emit('enableSaving', {value: this.enableSaving});
    },
    /**
     * Checks that if a meta file is given, the user also selected an xml processor
     * */
    checkMetaFile() {
      if (this.validationError) {
        this.vhubPostProcessor = 'none';
      }
      if (this.validationError && !this.clientMetaFile) {
        this.validationError = '';
        this.vhubPostProcessor = 'none';
      }
      if (!this.clientMetaFile || this.vhubPostProcessor !== 'none') {
        return true;
      }
      this.error = this.$t('errors.PleaseSelectTheFormatOfYourMetaFile');
      return false;
    },
    /**
     * Checks the root node
     * If the CAD is a zip file, a rootNode must be selected manually
     * if the CAD is a single file, that will automatically be the rootNode
     * */
    checkRootNode() {
      if (!this.isZip || (this.isZip && this.action !== 'pipeline') || (this.isZip && this.action === 'pipeline' && this.rootNodeFile)) {
        return true;
      }
      this.error = this.$t('errors.PleaseSelectARootNode');
      return false;
    },
    /**
     * Remove the meta file info
     * */
    unsetMetaFile() {
      this.metaFileText = '';
      this.metaFileName = '';
    },
    /**
     * Parses the metaFile according to the given xml parser
     * */
    async getMetaFile(text, fileName) {
      this.metaFileText = text;
      this.metaFileName = fileName;
      this.validationError = '';
      try {
        const columns = await getColumnNames(this.metaFileText);
        if (columns) {
          this.columns = columns;
        }
      } catch (e) {
        this.validationError = 'The xml is not in a valid format';
      }

      this.checkSaving();
    },
    /**
     * Sets the selected action -
     * this can be importing a CAD file or importing a hierarchy directly
     * */
    updateAction(args) {
      this.action = args.value;
      this.checkSaving();
    },
    /**
     * CASE 1: Import CAD file(s)
     * The asset is generated first through the uploadDropzone and the it returned from there
     * @params assetId {uuid} - the id of the assembly asset
     * @params fileName {string} - the name of the file
     * **/
    async cadImport(assetId, fileName) {
      let args = {
        pipeline: {
          files: [`${assetId}/${fileName}`],
          status: 'initiallyUploaded',
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
          rootNodeFile: !this.isZip && !this.isMultiple ? fileName : this.rootNodeFile
        }
      };
      if (this.existingRootNode) {
        args.pipeline.files.push(`${this.existingRootNode}/map.yml`);
        args.pipeline.isUpdate = true;
      }
      // Post processors are e.g. for the xml parser
      if (this.vhubPostProcessor && this.vhubPostProcessor !== 'none') {
        args.pipeline.vhubPostProcessors = [{name: this.vhubPostProcessor}];
      }
      if (this.clientMetaFile) {
        args.pipeline.meta = {};
        args.pipeline.meta.clientMetaFile = this.clientMetaFile;
      }
      if (this.metaFileText) {
        if (this.columns) {
          args.pipeline.meta.columns = this.columns;
        }
      }

      await this.$store.dispatch('updateSfxdata', {
        id: assetId,
        state: this.existingRootNode ? 'a' : 'i',
        content: JSON.stringify(args),
      }).then(() => {
        this.$emit('finished', assetId, true);
      });
    },
    /**
     * CASE 1: Import hierarchy package directly
     * */
    async directHierarchyUpload(assetId, fileName) {
      let args = {
        pipeline: {
          files: [`${assetId}/${fileName}`],
          status: 'initiallyUploaded',
          lang: this.$i18n.locale ? this.$i18n.locale : 'en'
        }
      };
      args.pipeline.vhubPostProcessors = [{name: 'hierarchyOnly'}];
      await this.$store.dispatch('updateSfxdata', {
        id: assetId,
        state: 'i',
        content: JSON.stringify(args),
      }).then(async () => {
        await this.$store.dispatch('clientFinishPipelineTask', {id: assetId})
        this.$emit('finished', assetId, true);
      });
    },
    /**
     * This method is triggered when the upload is complete
     * @params assetId {uuid} – the id of the asset
     * @params fileName {string} - the name of the file
     * */
    fileReceived: function (assetId, fileName) {
      if (this.action === 'pipeline') {
        this.cadImport(assetId, fileName);
      } else {
        this.directHierarchyUpload(assetId, fileName);
      }
    },
    /**
     * The upload is queued so the user can still modify the form,
     * this method resumes the upload which triggeres "fileReceived" when finished
     * */
    resumeUpload: function () {
      this.$refs[this.refId].resumeUpload();
      this.hideForm = true;
    },
    /**
     * This is emitted by the upload dropzone, sets the fileName
     * */
    setData(data) {
      this.file = data;
      this.isLoading = false;
    },
    /**
     * This is emitted by the upload dropzone
     * */
    filesInQueue(queue) {
      this.$emit('filesInQueue', queue);
      const fileName = queue[0].name;
      this.isZip = this.getExtension(fileName).toLowerCase() === 'zip';
      if (this.data) {
        this.isLoading = true;
      }
      this.checkSaving();
    },
    /**
     * This is emitted by the upload dropzone to say the queue is empty again
     * */
    queueEmpty: function () {
      this.$emit('queueEmpty');
      this.file = null;
      this.checkSaving();
    },
    setProperty(id, propertyName) {
      this[propertyName] = id;
      this.checkSaving();
    }
  }
}
</script>