<template>
  <div></div>
</template>

<script>
export default {
  name: "LoadOverviewMixin.js",
  data() {
    return {
      offset: 0,
      filter: {},
      sorting: '-updatedAt',
      sortObject: {
        fieldname: 'updatedAt',
        direction: '-',
      },
      include: 'teams',
      loading: true,
      paginationLimit: 10,
      queryRelevantComponents: 2, //pagination and filter
      queryRelevantInits: 0,

      // please implement those
      listName: 'pleaseOverwriteListNameLocally',
      registerMethod: null,
      loadMethod: null,
      deleteMethod: null,
      includeTeamsFiltering: false
    }
  },
  watch: {
    queryRelevantInits(newval) {
      if(newval === this.queryRelevantComponents) {
        this.loadData( false);
      }
    },
    '$route.query': function() {
      this.loadPaginatedData(0);
    }
  },
  beforeMount() {
    const sorting = window.localStorage.getItem('globalSorting');
    if(sorting) {
      this.sortObject = JSON.parse(sorting);
      this.sorting = this.sortObject.direction + this.sortObject.fieldname
    }
    console.log('this sorting ' + this.sorting)
  },
  methods: {
    loadPaginatedData(offset, initial) {
      this.offset = offset;
      this.loadData(initial);
    },
    filtering(args, initial) {
      this.filter = args;
      this.loadData(initial);
    },
    sort(args) {
      const {fieldname, direction} = args;
      const sortString = direction + fieldname;
      if(this.sorting !== sortString) {
        this.sorting = sortString;
      }
      window.localStorage.setItem('globalSorting', JSON.stringify(args));
      this.loadData(false);
    },
    loadData(initial = false) {
      this.loading = true;
      let args = {
        listName: this.listName,
      }
      const paginationAddOn = {
        paging: {
          [this.listName]: true,
        },
        offset: {
          [this.listName]: this.offset,
        },
        limit: {
          [this.listName]: this.paginationLimit,
        },
        include: {
          [this.listName]: this.include,
        },
        sort: {
          [this.listName]: this.sorting,
        }
      };

      args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
      if(this.filter && this.filter.remove) {
        args.remove = this.filter.remove;
      }

      if(this.includeTeamsFiltering) {
        if(this.$route.query && this.$route.query.teams) {
          args.add.teams = {[this.listName]: this.$route.query.teams};
        } else {
          args.remove = args.remove ? {...args.remove, teams: [this.listName]} : {teams: [this.listName]}
        }
      }

      if(initial) {
        this.queryRelevantInits++;
        this.$store.dispatch(this.registerMethod, {listName: this.listName, params: args});
      }
      else {
        this.$store.dispatch(this.loadMethod, args)
            .then(async data => {
              this.loading = false;
              if(data && data.length) {
                if(this.afterLoadingFunction) {
                  for(let i = 0; i < data.length; i++) {
                    await this.afterLoadingFunction(data[i]);
                  }
                  //this.forceReRenderKey++;
                }
              }
        });
      }
    },
    deleteRows(ids) {
      this.$store.dispatch(this.deleteMethod, {
        args: ids
      }).then(() => {
        this.loadPaginatedData(0);
      });
    },
    deleteRow(id) {
      this.$store.dispatch(this.deleteMethod, [id])
          .then(() => {
            this.loadPaginatedData(0);
      });
    },
  }
}
</script>