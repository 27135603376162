<template>
  <div>
    <div v-if="previewData && !limitedPreview" class="square-audio"/>
    <div v-if="previewData && !limitedPreview">
      <audio ref="audio" class="audio-preview" controls autoplay v-if="autoPlay">
        <source :src="previewData" :type="mimeType">
        Your browser does not support the audio element.
      </audio>
      <audio ref="audio" v-else class="audio-preview" controls>
        <source :src="previewData" :type="mimeType">
        Your browser does not support the audio element.
      </audio>
    </div>
    <div v-else class="square-image lighter">
      <icon class="preview-icon" type="audio" size="1.8" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "VideoPreview",
  components: {
    Icon,
  },
  props: {
    previewData: {type: String, default: ''},
    mimeType: {type: String, default: 'video/mp4'},
    limitedPreview: {type: Boolean, default: false},
    autoPlay: {type: Boolean, default: false},
  },
  methods: {
    start() {
      if(this.$refs.audio) {
        this.$refs.audio.play();
      }
    },
    stop() {
      if(this.$refs.audio) {
        this.$refs.audio.pause();
      }
    }
  },
}
</script>

<style scoped>
  .audio-preview {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    width: 100%;
  }
</style>