<template>
  <!--Single choice list match-->
  <div
      :class="['mb-3 position-relative w-100 vform-checkboxes', config.emphasized ? 'vform-emphasized' : '']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <div
        v-for="(option, index) in getOptions(config)"
        :key="index + forceReRenderKey + Math.random().toString(36).substring(7)"
        :class="['mt-3 pb-1', !editable && ((!option.isCorrectAnswer && option.selectedAnswer) || (isAnswerRequired && !option.selectedAnswer)) ? 'vform-error-hide' : '']">
      <template v-if="config.choiceLocation === 'panel'">
        <div> {{ getText(option, lang) ? getText(option, lang) : placeholders.SHORT }}</div>
        <div class="d-flex justify-content-start">
          <div
              v-for="(item) in getAnswersDataSource(lang)"
              :key="`item${item}`">
            <div :class="['answer-number-select d-flex mr-2',
                        !editorMode && option.selectedAnswer === item ? 'selected': '',
                        editorMode && option.correctAnswer === item ? 'selected-editor-mode': '']"
                 @click="selectAnswer(option, item)">
              {{ item }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="config.choiceLocation === 'image'">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            [ {{ option.correctAnswer }} ]
          </div>
          <div>
            {{ getText(option, lang) ? getText(option, lang) : placeholders.SHORT }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import LearningDotsMixin from "@/components/vForm/viewer/LearningDotsMixin.js";
import EditorOverlay from "./EditorOverlay";

export default {
  name: "LIST_MATCH",
  components: {
    EditorOverlay,
  },
  mixins: [ElementMixin, LearningDotsMixin],
  data() {
    return {
      selected: {},
      isUserChanged: false,
      isAnswerValid: true,
      isAnswerRequired: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        if (!this.isUserChanged) {
          //this.setListMatchPreSelected(this.config);
        }
      }
    },
  },
  beforeMount() {
    if (!this.config.choiceLocation){
      this.$set(this.config, "choiceLocation", 'panel');
    }
  },
  mounted() {
    this.increaseCounter();
    this.validate();
  },
  methods: {
    selectAnswer(option, answer) {
      console.log('select answer ' + answer);
      this.$set(option, "selectedAnswer", answer);
      option.selectedAnswer = answer;
      console.log(option);
    },
    validate() {
      let isValid = this.validateListMatch();
      this.isAnswerValid = isValid;

      return isValid;
    },
    checkRequired() {
      const options = this.getOptions(this.config);
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (!option.selectedAnswer) {
          this.isAnswerRequired = true;
          return !this.isAnswerRequired;
        }
      }

      return true;
    },
    fillData(data) {
      if (data) {
        this.setOptions(this.config, data);
      } else {
        this.setListMatchPreSelected(this.config);
      }
    },
    retrieveData() {
      return this.getOptions(this.config);
    },
    getData() {
      if (!this.disabled) {
        return {
          data: {
            value: this.getListMatchData(this.config, this.lang),
            question: this.getLabel(this.config, this.lang),
            elementId: this.config.uuid
          }, type: 'answers'
        };
      }
      return null;
    },
    getListMatchData(config, lang) {
      const options = this.getOptions(config);
      let data = {
        receivedPoints: 0,
        totalPossiblePoints: options.length,
        correctAnswers: [],
        wrongAnswers: []
      };
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.correctAnswer && option.selectedAnswer) {
          data.correctAnswers.push({message: this.getText(option, lang)});
          data.receivedPoints++;
        } else {
          data.wrongAnswers.push({message: this.getText(option, lang)});
        }
      }
      return data;
    },
    validateListMatch() {
      this.validationIsCorrect = true;
      if (this.isCheckForCorrectness(this.config)) {
        const options = this.getOptions(this.config);
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          this.$set(option, "isCorrectAnswer", true);
          if (option.selectedAnswer && option.correctAnswer !== option.selectedAnswer) {
            this.validationIsCorrect = false;
            option.isCorrectAnswer = false;
          }
        }
      }

      return this.validationIsCorrect;
    },
    setListMatchPreSelected(config) {
      let options = this.getOptions(this.config);
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        option.selectedAnswer = '';
        this.$set(option, "isCorrectAnswer", true);
      }
      config.options = options;
    },
    // setOptionPreSelected(config) {
    //   let options = this.getOptions(config);
    //   for(let i = 0; i < options.length; i++) {
    //     let option = options[i];
    //     option.selectedAnswer = '';
    //     this.$set(option, "isCorrectAnswer", true);
    //   }
    //
    //   config.options = options;
    // },
  }
}
</script>

<style lang="scss" scoped>
.answer-number-select {
  background-color: #cccccc;
  border-radius: 10px;
  min-width: 40px;
  width: fit-content;
  padding: 0 8px 0 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &.selected {
    background-color: cornflowerblue;
    color: white;
  }

  &.selected-editor-mode {
    background-color: #518000;
    color: white;
    opacity: 0.8;
  }
}
</style>

<style lang="scss">


</style>