<template>
    <one-column-sidebar-left
        back-button-tab-selector="metasetbackbutton"
        tab-selector="metasettab"
        sub-menu-selector="organizingpanel"
    >
    <info-panel slot="sidebarLeft"
                    :id="$route.params.id"
                    :created="$store.getters.getMetaSetCreationDate(id)"
                    :last-change="$store.getters.getMetaSetChangeDate(id)"
                    preview-id="metasetEdit"
        />
      <team-attacher
          v-if="$store.getters.getMetaSetOrganization($route.params.id)"
          class="mt-3"
          slot="sidebarLeft"
          :id="$route.params.id"
          :teams="teams"
          store-name="MetaSet"
          :limit-by-organization-id="$store.getters.getMetaSetOrganization($route.params.id)"
          @added="loadMetaSetTeams"
          @removed="loadMetaSetTeams"
      />

        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getMetaSetTitle($route.params.id)"
                    :value="$store.getters.getMetaSetTitle($route.params.id)"
                    :id="$route.params.id"
                    store-name="MetaSet"
                    :organization-id="$store.getters.getMetaSetOrganization($route.params.id)"
                    icon="list-ul"
            />
            <content-description-block
                    :value="$store.getters.getMetaSetDescription($route.params.id)"
                    :id="$route.params.id"
                    store-name="MetaSet"
            />

            <meta-field-edit
                    :meta-set-id="$route.params.id"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import TeamAttacher from "@/components/organizations/TeamAttacher";

    export default {
        name: 'SFXEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            MetaFieldEdit: () => import("../components/widgets/meta/AdminMetaFieldsEdit"),
            TeamAttacher
        },
        data() {
            return {
                id: '',
                teams: []
            };
        },
        beforeMount() {
            this.$store.dispatch('loadMetaSet', {
                id: this.$route.params.id,
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              } else if(e.status === 404) {
                this.$router.push('/not-found')
              }
            });
            this.loadMetaSetTeams();
            this.id = this.$route.params.id;
        },
        methods: {
            loadMetaSetTeams() {
              this.$store.dispatch('clientLoadMetaSetTeams', {
                id: this.$route.params.id
              }).then(data => {
                this.teams = data;
              });
            },
            removeTag: function (tag) {
                this.$store.dispatch('removeMetaSetTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            addTag: function (tag) {
                this.$store.dispatch('addMetaSetTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
        },
    }
</script>
