<template>
    <div class="filterSection">
        <text-input
                :class="localValue.length > 0 ? 'filter-active' : ''"
                @typingAlert="triggerFilter"
                :has-keyup="true"
                :id="'contentFilterText'"
                :placeholder="$t('filter.placeholder')"
                :label="label"
                :value="localValue"
                ref="textSearch"
                :debounce-time="200"
        />
        <span class="small lighter">{{ $t('searchMode') }}</span>
        <tile-button :ref="'tiles' + getId" :initial-active-tiles="['exactMatch']" :tiles="items" :values="values" @trigger="changeValuePrefix" />
        <div v-if="message" class="form-small-message">{{ message }}</div>
    </div>
</template>

<script>
    import TextInput from "../../../forms/TextInput";
    import TileButton from "../../../forms/TileButton";
    /**
     * A text-filter component (Textinput) that prepares the queryParams for registerin or loading
     * @displayName Text Filter
     */
    export default {
        name: "TextFilter",
        components: {
            TileButton,
            TextInput,
        },
        props: {
            /**
             * Value – an initial value set by the parent component, if necessary (overwrites localFiltering)
             */
            value: {type: String, default: ''},
            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * The label for the textfield
             * */
            label: {type: String, default: ''},
            /**
             * The name of the to be searched field
             * */
            fieldName: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0}
        },
        data() {
            return {
                contentFilterText: '',
                message: '',
                localValue: '',
                timeout: null,
                values: ['=', '', '^', '!'],
                items: ['exactMatch', 'similar', 'beginsWith', 'doesNotContain']
            };
        },
        computed: {
            getId: function() {
                return this.$route.name + this.id  + 'TextFilter';
            },
        },
        watch: {
            clear() {
                this.clearValue();
            },
        },
        beforeMount() {
            let localFilter = window.localStorage.getItem(this.getId);
            if(this.value && this.value.length) {
                this.localValue = this.value;
            }
            else if(localFilter) {
                this.localValue = localFilter;
            } else {
              this.localValue = '='
            }

            if(this.localValue) {
                this.filter({value: this.localValue}, true);
            }
            else {
                this.filter({value: ''}, true);
            }
        },
        methods: {
            clearValue: function(initial = true) {
                this.filter({value: ''}, initial);
            },
            changeValuePrefix: function(args) {
                let prefix = args.value;
                let firstChar = this.localValue.charAt(0);
                if(this.values.includes(firstChar)) {
                    this.localValue = this.localValue.slice(1, this.localValue.length);
                }
                this.localValue = prefix + this.localValue;
                this.filter({value: this.localValue}, false);
            },
            triggerFilter: function(args, initial = false) {
                if(this.localValue !== args.value) {
                  clearTimeout(this.timeout);
                  let firstChar = args.value.charAt(0);
                  if(!this.values.includes(firstChar)) {
                      this.$refs['tiles' + this.getId].trigger('', 'standard');
                  }
                  else {
                      this.$refs['tiles' + this.getId].trigger(firstChar, this.items[this.values.indexOf(firstChar)]);
                  }
                  const $this = this;
                  this.timeout = setTimeout(function() {
                    $this.filter(args, initial)
                  }, 300);
                }

            },
            filter: function (args, initial = false) {
                let filterValue = args.value;
                this.localValue = args.value;
                let params = {};
                let filterParamId = this.getId;
                if (filterValue.length > 1) {
                    this.message = '';
                    params = {
                        addObject: {
                            textSearch: {
                                [filterParamId]: {
                                    [this.fieldName]: filterValue
                                }
                            },
                            textSearchOptions: {
                                [filterParamId]: {
                                    searchMode: 'and'
                                }
                            }
                        },
                    };
                } else if (filterValue.length === 0) {
                    this.message = '';
                    params = {
                        remove: {
                            textSearch: [filterParamId],
                            textSearchOptions: [filterParamId]
                        }
                    };
                }
                /**
                 * sends the filtering content to the parent component
                 * ready to use in a register or loading method
                 * */
                this.$emit('filter', params, initial, this.getId);
                window.localStorage.setItem(this.getId, this.localValue);
            },
        },
    }
</script>