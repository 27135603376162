<template>
    <div>
        <label class="vform-editor-label section">Height</label>
        <label class="section ml-4 vform-editor-body" style="font-size: 0.733rem"
            >{{ height }} {{ element.showAsPercentage ? '%' : 'px'}}</label
        >
        <slider :default-value="20" :initial-value="height"
              :interval="5" :max="100" :min="0"
              @change="(val) => { this.$set(this, 'height', val) }"></slider>
      <label :for="'showAsPercentage' + $vnode.key" class="container" style="padding-left: 5px !important">
        <div class="col pl-0">
          <label class="mt-3 vform-editor-label section">{{ $t("showAsPercentage") }}</label>
        </div>
        <div class="col-auto pl-0 pr-0" style="margin: auto">
          <div v-if="true" class="col-12 dflex row">
            <label :for="'showAsPercentage' + $vnode.key" class="container" style="padding-left: 5px !important">
              <input
                  :id="'showAsPercentage' + $vnode.key"
                  v-model="element.showAsPercentage"
                  @change="$set(element, 'showAsPercentage', element.showAsPercentage)"
                  type="checkbox"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </label>
    </div>
</template>

<script>
import { vFormControls } from "@/enum";
import Slider from "@/components/widgets/editors/Slider";

export default {
    name: "vFormVERTICAL_SPACE",
    components: {
        Slider
    },
    props: {
        element: {type: Object, required: true},
    },
    data() {
        return {
            vFormControls: vFormControls,
            height: 20,
        };
    },
    watch: {
        height() {
            this.$set(this.element, "height", this.height);
        },
    },
    beforeMount() {
        this.element.formElementType = vFormControls["VERTICAL_SPACE"];
        this.height = this.element.height ? this.element.height : this.height;
        if(!this.element.showAsPercentage) {
          this.$set(this.element, "showAsPercentage", false);
        }
    },
    methods: {
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
