import {SpecialUuids} from "./enum";

/**
 * Used for reading the files returned by uploadDropzone when auto-upload = false
 * @param {string} content Base64String
 * */
export const decodeArrayBuffer = (content) => {
    return new TextDecoder("utf-8").decode(content);
}

/**
 * Compares two numbers in the format 0.0.0
 * @returns a numeric value:
 *  > 0 means: b is an older version than a
 *  = 0 means: a and b are equal
 *  < 0 means: b is a newer version than a
 * */
export const compareVersion = (a, b) => {
  const aArr = a.split('.');
  const bArr = b.split('.');
  if(Number(aArr[0]) !== Number(bArr[0])) {
    return Number(aArr[0]) - Number(bArr[0]);
  }
  else if(Number(aArr[1]) !== Number(bArr[1])) {
    return Number(aArr[1]) - Number(bArr[1]);
  }
  else {
    return Number(aArr[2]) - Number(bArr[2]);
  }
}

export const combineAssetAndInstanceMetaValues = (assetMetaSets, instanceMetaValues) => {
    const arr = assetMetaSets;
      const targetArr = [];
      for(let i = 0; i < arr.length; i++) {
        //current MetaSet
        let rawMetaSet = JSON.parse(JSON.stringify(arr[i]));
        rawMetaSet.metaFields = [];
        targetArr.push(rawMetaSet);
        const metaFields = arr[i].metaFields;
        for(let j = 0; j < metaFields.length; j++) {
          let rawMetaField = JSON.parse(JSON.stringify(metaFields[j]));
          rawMetaField.metaValues = [];
          targetArr[i].metaFields.push(rawMetaField);
          const item = instanceMetaValues.filter(item => {return item.metaFieldId === metaFields[j].id});
          targetArr[i].metaFields[j].metaValues.push(item[0] ? item[0] : []);
          if(item.length) {
            assetMetaSets[i].metaFields[j].overwritten = true;
          }
        }
      }
     return targetArr;
}

export const combineAssetAndInstanceMetaValues_v2 = (assetMetaSets, instanceMetaValues) => {
  const arr = assetMetaSets;
  const targetArr = [];
  for(let i = 0; i < arr.length; i++) {
    //current MetaSet
    let rawMetaSet = JSON.parse(JSON.stringify(arr[i]));
    rawMetaSet.metaFields = [];
    targetArr.push(rawMetaSet);
    const metaFields = arr[i].metaFields;
    for(let j = 0; j < metaFields.length; j++) {
      let rawMetaField = JSON.parse(JSON.stringify(metaFields[j]));
      //rawMetaField.metaValues = [];
      targetArr[i].metaFields.push(rawMetaField);
      const item = instanceMetaValues.filter(item => {return item.metaFieldId === metaFields[j].id});
      if (item.length) {
        rawMetaField.metaValues = [];
        targetArr[i].metaFields[j].metaValues.push(item[0] ? item[0] : []);
        if(item.length) {
          assetMetaSets[i].metaFields[j].overwritten = true;
        }
      }
    }
  }
  return targetArr;
}

export const sleep = (ms) =>{
  return new Promise(resolve=> {
    setTimeout(resolve,ms)
  })
};

export const getWindowAppLoadingClasses = () => {
  return window.location.href.includes('simpleLoadingScreen=true') ? 'simple' : window.location.href.includes('hideLoadingScreen=true') ? 'hide' : ''
}

export const filterByCCLightOnly = (item) =>  {
  return item.instances && item.instances.length && item.instances.filter(app => {
    return [SpecialUuids.CC_LIGHT_APP_PKG, SpecialUuids.CC_LIGHT_APP_PKG_BETA, SpecialUuids.CC_LIGHT_APP_PKG_ALPHA].includes(app.assetId);
  }).length
}

export const getCCLightAssetIds = () => {
  return [SpecialUuids.CC_LIGHT_APP_PKG, SpecialUuids.CC_LIGHT_APP_PKG_BETA, SpecialUuids.CC_LIGHT_APP_PKG_ALPHA];
}

export const filterByAppPanelOnly = (item) =>  {
  return item.instances && item.instances.length && item.instances.filter(app => {
    return [SpecialUuids.APP_PANEL_WEBAPP, SpecialUuids.APP_PANEL_WEBAPP_BETA].includes(app.assetId);
  }).length
}

export const getAppPanelAssetIds = () => {
  return [SpecialUuids.APP_PANEL_WEBAPP, SpecialUuids.APP_PANEL_WEBAPP_BETA]
}