<template>
  <div>
    <select v-model="selectedFont" class="custom-select dark" @change="change">
      <option
          v-for="font in fonts"
          v-if="font.systemFont || font.numOfFiles > 0"
          :key="font.id"
          :value="font.id"
      >
        {{ font.name }} <span v-if="font.systemFont">(default)</span>
      </option>
    </select>
    <div v-if="useFontAssets" @click="loadFontAssets">
      <icon type="redo"/>
    </div>
  </div>
</template>

<script>
import {mediaCategories} from "@/enum";
import Icon from "./Icon";

export default {
  name: "FontSelector",
  components: {Icon},
  props: {
    initialSelectedFont: {type: Object, default: () => {return {}}},
    useFontAssets: {type: Boolean, default: false}
  },
  data() {
    return {
      selectedFont: "",
      fonts: [],
      defaultFonts: [
        {id: 'Inter', name: 'Inter', systemFont: true},
        {id: 'Arial', name: 'Arial', systemFont: true},
        {id: 'Verdana', name: 'Verdana', systemFont: true},
        {id: 'Georgia', name: 'Georgia', systemFont: true},
        {id: 'Courier New', name: 'Courier New', systemFont: true}
      ],
    };
  },
  watch: {
    initialSelectedFont() {
      this.setInitialFont();
    }
  },
  beforeMount() {
    this.setInitialFont();
    if (this.useFontAssets) {
      this.loadFontAssets();
    }
  },
  methods: {
    loadFontAssets() {
      this.$store.dispatch('clientLoadAssets', {
        filter: 'type eq media, mediaCategory eq ' + mediaCategories.font
      }).then(async fonts => {
        for (let i = 0; i < fonts.length; i++) {
          const {id} = fonts[i];
          const files = await this.$store.dispatch('loadAssetFiles', {
            id: id
          });
          fonts[i].files = files;
          fonts[i].numOfFiles = files.length;
        }
        this.fonts = fonts ? [...fonts, ...this.defaultFonts] : this.defaultFonts;
      })
    },
    change() {
      const index = this.fonts.findIndex(item => {
        return item.id === this.selectedFont;
      })
      const font = this.fonts[index];
      const {systemFont} = font;
      this.$emit('change', {
        id: this.selectedFont,
        name: font.name,
        systemFont: systemFont
      })
    },
    setInitialFont() {
      if (this.initialSelectedFont && this.initialSelectedFont.id) {
        this.selectedFont = this.initialSelectedFont.id;
      } else {
        this.selectedFont = 'Inter';
      }
    }
  }
}
</script>

<style scoped>

</style>