<template>
  <div>
    <info-panel
        :collapsible="true"
        :info-panel-header="title"
        :initially-collapsed="initiallyCollapsed"
        preview-id="metapanel"
        :class="classes"
    >
      <div slot="info">
        <table class="table-fixed mt-3">
          <meta-field
              v-if="checkRequirement(item)"
              :organization-ids="organizationIds"
              :teams="teams"
              :store-name="storeName"
              :key="item.id"
              :cid="cid"
              v-for="(item) in sortedFields"
              :label="item.description"
              :target-id="targetId ? targetId : $route.params.id"
              :field="item"
              :value="item.metaValues[0] ? item.metaValues[0].value : item.metaValues.value"
              icon-left="folder"
              @updated="updated"
              :editable="editable"
          />
        </table>
      </div>
    </info-panel>
  </div>
</template>

<script>
    import MetaField from "./MetaField";
    import InfoPanel from "@/components/InfoPanel";

    export default {
        name: "MetaPanel",
        components: {
          MetaField,
          InfoPanel
        },
        props: {
            title: {type: String, required: true},
            id: {type: String, default: null},
            fields: {type: Array, default: null},
            storeName: {type: String, required: true},
            cid: {type: String, default: null},
            organizationIds: {type: Array,  default: () => {return []}},
            teams: {type: Array, default: null},
            targetId: {type: String, default: null},
            editable: {type: Boolean, default: true},
            initiallyCollapsed: {type: Boolean, default: true},
            classes: {type: String, default: ""}
        },
        data() {
          return {
            sortedFields: []
          };
        },
        watch: {
          fields() {
            this.setFields();
          }
        },
        beforeMount() {
          this.setFields();
        },
        methods: {
            setFields() {
              this.sortedFields = JSON.parse(JSON.stringify(this.fields));
              this.sortedFields = this.sortedFields.sort((a,b) => {
                return parseInt(a.position) - parseInt(b.position);
              })
            },
            updated(args) {
                this.$emit('updated', args);
                let index = this.fields.findIndex(item => {return item.id === args.metaFieldId});
                if(!this.fields[index].metaValues) {
                    this.fields[index].metaValues = [];
                    this.fields[index].metaValues.push({value: args.value});
                }
                else {
                    this.fields[index].metaValues[0].value = args.value;
                }
                this.$forceUpdate();
            },
            checkRequirement(item) {
                if(item.config && item.config.requirements) {
                    let metaField = item.config.requirements.metaField ? item.config.requirements.metaField : null;
                    let value = item.config.requirements.value;
                    let target = this.fields.filter(item => {return item.key === metaField});
                    if(target && target[0] && target[0].metaValues && target[0].metaValues[0]) {
                        return target[0].metaValues[0].value === value;
                    }
                    else {
                        return false;
                    }
                }
                return true;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .meta-panel {
        background-color: $meta-panel-background-color;
        margin-bottom:15px;
        margin-top:15px;
        h4 {
            padding:15px;
            padding-left:15px;
            margin-bottom:0;
        }
    }
</style>
