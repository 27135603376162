<template>
  <main-table-layout sub-menu-selector="librarypanel">
    <content-filter
        id="materialmapperList"
        slot="filter"
        :tag-filtering="false"
        store-name="Materialmapper"
        @filter="filtering"
    />
    <view-switcher
        id="mainContent"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />
    <pagination
        id="materialmappertablepage"
        ref="pagination"
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getDatasetListOptions(listName, 'pagination_items')"
        @setActive="loadPaginatedData"
    />
    <Button
        slot="mainContentButtons"
        icon="plus"
        link="/library/materialmappers/add"
        permission="createDataSet"
        type="action-button"
    >
      {{ $t("createNewMaterialMapper") }}
    </Button>

    <Button
        slot="mainContent"
        class="icon-button"
        icon="redo"
        type="reload"
        @click="
                loadPaginatedData(0);
                $refs.pagination.setActive(0);
            "
    />
    <div slot="mainContent">
      <table-widget
          v-if="$store.getters.getViewSwitcher(this.$route.name, 'mainContent') ==='table'"
          id="appsDefaultTable"
          :data="$store.getters.getMaterialmappersByListName(listName)"
          :deletable="true"
          :fields="fields"
          :loading="loading"
          :nav-by-clicking-row="true"
          :show-organization="true"
          :title-as-links="true"
          :use-team-permission="true"
          delete-permission="deleteDataSet"
          edit-permission="writeDataSet"
          @deleteRow="deleteRow"
          @sort="sort"
          :initial-sorting="sortObject"
      />
      <thumbnails
          v-if="$store.getters.getViewSwitcher(this.$route.name,'mainContent') === 'thumbnails'"
          id="appsDefaultTable"
          :data="$store.getters.getMaterialmappersByListName(listName)"
          :deletable="true"
          :fields="thumbnailFields"
          :loading="loading"
          :nav-by-clicking-row="true"
          :title-as-links="true"
          :use-team-permission="true"
          delete-permission="deleteDataSet"
          edit-permission="writeDataSet"
          @deleteRow="deleteRow"
          @sort="sort"
      />
    </div>
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import TableWidget from "../components/widgets/TableWidget";
import ContentFilter from "../components/widgets/filter/ContentFilter";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

export default {
  name: "MaterialMappers",
  components: {
    Button,
    TableWidget,
    ContentFilter,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
  },
  mixins: [LoadOverviewMixinJs],
  data() {
    return {
      listName: "MaterialmapperList",
      registerMethod: 'registerMaterialmapperQueryParams',
      loadMethod: 'loadMaterialmappers',
      include: 'teams',
      deleteMethod: 'deleteMaterialmappers',
      fields: [
        {
          name: "",
          id: "icon",
          type: "icon",
          virtual: true,
          fieldId: "type",
        },
        {
          name: "Name",
          id: "name",
          type: "string",
          sortable: true,
        },
        {
          name: "Teams",
          id: "teams",
          type: "teams",
        },
        {
          name: "Type",
          id: "type",
          type: "string",
        },
        {
          name: "Last Update",
          id: "updatedAt",
          type: "date",
          sortable: true,
        },
        {
          name: "Edit",
          type: "editButton",
        },
      ],
      thumbnailFields: [
        {
          name: "",
          id: "icon",
          type: "icon",
          virtual: true,
          fieldId: "type",
        },
        {
          name: "Organization",
          id: "organization",
          type: "organization",
          sortable: true,
        },
        {
          name: "Type",
          id: "type",
          type: "string",
        },
        {
          name: "Last Update",
          id: "updatedAt",
          type: "date",
          sortable: true,
        },
        {
          name: "Name",
          id: "name",
          type: "string",
          sortable: true,
        },
        {
          name: "Teams",
          id: "teams",
          type: "teams",
        },
        {
          name: "Edit",
          type: "editButton",
        },
      ],
    };
  },
  methods: {
    deleteRow(id) {
      this.$store.dispatch("deleteMaterialmapper", {id: id}).then(() => {
        this.loadPaginatedData(0);
      });
    },
  }
};
</script>
