<template>
    <div>
        <portal v-if="organizationId" to="filter">
            <content-filter
                ref="filterComponent"
                class=""
                @filter="filter"
                :id="'assettable'"
                store-name="Project"
                :limit-to-organization-ids="[
                    organizationId,
                    '7bc33721-622f-4b87-b9a4-333170f9fa93',
                ]"
                :key="forceFilterReRenderKey"
                :org-filter-local="true"
            />
        </portal>
        <tab-switcher
            :id="'projectLinkedDataTab'"
            :tabs="tabs"
            @setSelected="setSelected"
        />
        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <div class="row">
                    <view-switcher
                        v-if="getSelectedTab()"
                        :id="getSelectedTab().name"
                        :table="true"
                        :thumbnails="true"
                        :key="forceReRenderKey + 2"
                        class="col-4"
                    />
                    <pagination
                        :limit="paginationLimit"
                        :total="
                            $store.getters['get' + storeName + 'ListOptions'](
                                id,
                                'pagination_items'
                            )
                        "
                        :id="storeName + 'pagination'"
                        @setActive="loadPaginatedData"
                        class="col-4"
                        :key="storeName"
                        v-if="organizationId"
                    />
                </div>
            </div>
            <div class="w-100" />
            <div class="col-6 mt-3">
                <div class="form-error" v-if="error">{{ error }}</div>
            </div>
            <div class="w-100" />
            <portal-target name="linkerHeaderSection" slim />
            <asset-table
                class="col-6 col-xl-6"
                v-if="getSelectedTab() && organizationId"
                @selectCheckbox="selectAsset"
                :id="id"
                :show-organization="true"
                :key="forceReRenderKey + 1"
                @updateTable="update"
                @deleteRow="deleteRow"
                :store-name="getSelectedTab().storeName"
                :name="getSelectedTab().name"
                :organization-id="organizationId"
                :project-instance-limit="
                    getSelectedTab().limit ? getSelectedTab().limit : null
                "
                :table-fields="
                    getSelectedTab().tableFields ? getSelectedTab().tableFields : []
                "
                :thumbnail-fields="
                    getSelectedTab().thumbnailFields
                        ? getSelectedTab().thumbnailFields
                        : []
                "
                :pre-content="
                    getSelectedTab().preContent ? getSelectedTab().preContent : null
                "
                :show-add-button="
                    getSelectedTab().showAddButton
                        ? getSelectedTab().showAddButton
                        : false
                "
                :show-instances="true"
                :teams="teams"
            />
            <instance-table
                ref="instanceTable"
                class="col-6 col-xl-6 d-none d-md-block"
                v-if="getSelectedTab().name"
                :id="id"
                :key="forceReRenderKey"
                :name="getSelectedTab().name"
                :store-name="getSelectedTab().storeName"
            />
        </div>
    </div>
</template>

<script>
import TabSwitcher from "../widgets/TabSwitcher";
import ViewSwitcher from "../widgets/ViewSwitcher";
import Pagination from "../Pagination";
import AssetTable from "./assets/AssetTable";
import { defaultConfig } from "./dataLinkerConfig";
import InstanceTable from "./projects/InstanceTable";
import ContentFilter from "../widgets/filter/ContentFilter";

export default {
    name: "ProjectLinker",
    components: {
        InstanceTable,
        AssetTable,
        TabSwitcher,
        ViewSwitcher,
        Pagination,
        ContentFilter,
    },
    props: {
        toBeLinkedTypes: { type: Array, required: true },
        linkingLimits: { type: Object, default: null },
    },
    data() {
        return {
            paginationLimit: 15,
            queryRelevantComponents: 2, //pagination and filter
            queryRelevantInits: 0,
            forceReRenderKey: 0,
            forceFilterReRenderKey: 0,
            error: false,
            previewPath: null,
            selected: null,
            selectedInstanceComponent: "",
            store: "",
            organizationId: "",
            tabs: [],
            teams: [],
            offset: 0,
        };
    },
    watch: {
        queryRelevantInits: function (newval) {
            if (newval === this.queryRelevantComponents) {
                //let args = this.getBasicQueryParams();
                //let selectedTab = this.tabs.find(x => x.name === this.selected);
                //this.$store.dispatch(selectedTab.loadingMethod, args);
                this.loadPaginatedData(this.offset, false);
            }
        },
    },
    beforeMount() {
        this.tabs = JSON.parse(JSON.stringify(defaultConfig));
        for (let i = 0; i < this.tabs.length; i++) {
            if (this.linkingLimits && this.linkingLimits[this.tabs[i].name]) {
                this.tabs[i].limit = this.linkingLimits[this.tabs[i].name];
            }
            if (!this.toBeLinkedTypes.includes(this.tabs[i].name)) {
                this.tabs.splice(i, 1);
                i -= 1;
            }
        }
        this.$store.subscribe((mutation) => {
            if (
                mutation.type === "loadProjectMutation" &&
                mutation.payload.id === this.$route.params.id
            ) {
                this.organizationId = mutation.payload.organizationId;
                //this.teams = mutation.poayload.teams;
            }
        });
        this.id = this.$route.params.id;
        /*
         * The data for this component are loaded from the subcomponents (the tables inside of linkedData) like datasources, media etc. themselves
         * */
        this.$store.dispatch("loadProject", {
            id: this.$route.params.id,
            listName: this.$route.params.id,
            include: "organization,metaSets,teams",
        });
        if (this.$route.query.projectLinkedDataTab) {
            this.setSelected(this.$route.query.projectLinkedDataTab);
        } else {
            this.setSelected(this.tabs[0].name);
        }
        this.loadProjectTeams();
    },
    methods: {
        update: function () {
            this.loadPaginatedData(0);
            this.forceReRenderKey++;
        },
        setSelected: function (selected) {
            this.queryRelevantInits = 0;
            this.selected = selected;
            let selectedTab = this.getSelectedTab();
            this.selectedInstanceComponent = selectedTab.instanceComponent;
            this.storeName = selectedTab.storeName;
            this.offset = 0;
            if (
                this.$store.getters.getPagination(
                    this.$route.name,
                    this.storeName + "pagination"
                )
            ) {
                let page = this.$store.getters.getPagination(
                    this.$route.name,
                    this.storeName + "pagination"
                );
                this.offset = (page - 1) * this.paginationLimit;
            }
            //this.loadPaginatedData(offset);

            this.forceFilterReRenderKey++;
        },
        deleteRow: function (id) {
            let selectedTab = this.getSelectedTab();
            if (selectedTab) {
                this.$store
                    .dispatch("delete" + selectedTab.storeName, { args: [id] })
                    .then(() => {
                        this.loadPaginatedData(0);
                        this.forceReRenderKey++;
                    });
            }
        },
        selectAsset: function (linkedDataId, operation, item) {
            this.error = "";
            if (linkedDataId) {
                if (operation === "add") {
                    if (this.getSelectedTab().limit) {
                        if (
                            this.$refs.instanceTable.getNumberOfInstances() >=
                            this.getSelectedTab().limit
                        ) {
                            this.error = this.$t("errors.connectionLimit", {
                                num: this.getSelectedTab().limit,
                            });
                            return;
                        }
                    }
                    this.$store.dispatch("createNotification", {
                        text: this.$t("addconnection"),
                    });
                    let args2 = {
                        args: [
                            {
                                assetId: linkedDataId,
                                name: item.name,
                            },
                        ],
                        id: this.$route.params.id,
                    };
                    this.$store
                        .dispatch("clientCreateProjectInstances", args2)
                        .then(() => {
                            //setDatasourceProperty
                            this.$log.warn(
                                "trying to call: " + "set" + this.storeName + "Property"
                            );
                            this.$store.dispatch("set" + this.storeName + "Property", {
                                id: linkedDataId,
                                listName: this.$route.params.id,
                                property: "selected",
                                value: true,
                            });
                            //sortDatasourceList
                            this.$log.warn(
                                "trying to call: " + "sort" + this.storeName + "List"
                            );
                            this.$store.dispatch("sort" + this.storeName + "List", {
                                listName: this.$route.params.id,
                                order: "selected",
                            });
                            this.forceReRenderKey++;
                        })
                        .catch(() => {
                            this.error = this.$t("errors.linkingNotPossible");
                        });
                } else if (operation === "remove") {
                    this.$store.dispatch("createNotification", {
                        text: this.$t("removeconnection"),
                    });
                    let args2 = {
                        args: [linkedDataId],
                        id: this.$route.params.id,
                    };
                    this.$store.dispatch("clientRemoveProjectAsset", args2).then(() => {
                        //this.loadData();
                        this.$log.warn(
                            "trying to call: " + "set" + this.storeName + "Property"
                        );
                        this.$store.dispatch("set" + this.storeName + "Property", {
                            id: linkedDataId,
                            listName: this.$route.params.id,
                            property: "selected",
                            value: false,
                        });
                        this.forceReRenderKey++;
                    });
                }
            }
        },

        /*
         * Data loading
         * */
        loadPaginatedData: function (offset, initial) {
            let args = this.getBasicQueryParams();
            if (args) {
                if (!args.add) {
                    args.add = {};
                }
                args.add.offset = { assettable: offset };
                this.loadDataWithParams(args, initial);
            }
        },
        sort: function (params) {
            let args = this.getBasicQueryParams();
            args[params.action] = {
                sort: [args.direction + args.fieldname],
            };
            this.loadDataWithParams(args);
        },
        filter: function (params, initial) {
            let args = this.getBasicQueryParams();
            if (args) {
                Object.keys(params).forEach((key) => {
                    args[key] = params[key];
                });
                this.loadDataWithParams(args, initial);
            }
        },
        getBasicQueryParams: function () {
            if (this.$route.params.id) {
                let args = {
                    id: this.$route.params.id,
                    listName: this.$route.params.id,
                };
                args.add = {
                    offset: {
                        assettable: this.offset ? this.offset : 0,
                    },
                    limit: {
                        default: this.paginationLimit,
                    },
                    paging: {
                        assettable: true,
                    },
                    include: {
                        assettable: "instances",
                    },
                };
                return args;
            }
        },
        loadDataWithParams: function (args, initial) {
            let selectedTab = this.getSelectedTab();
            if (!args.add) {
                args.add = {};
            }
            args.add.includes = "instances";
            if (initial && selectedTab.registerMethod) {
                this.queryRelevantInits++;

                if (selectedTab.name === "sfxdata") {
                    if (!args.add) {
                        args.add = {};
                    }
                    // args.add.filter = {default: selectedTab.filter}
                }
                this.$store.dispatch(selectedTab.registerMethod, {
                    listName: this.$route.params.id,
                    params: args,
                });
            } else if (!initial && selectedTab.loadingMethod) {
                this.$store.dispatch(selectedTab.loadingMethod, args).then((data) => {
                    for (let i = 0; i < data.length; i++) {
                        if (this.hasProjectInstance(data[i])) {
                            this.$store.dispatch(`mark${this.storeName}Selected`, {
                                data: { [data[i].id]: { id: data[i].id } },
                                listName: this.$route.params.id,
                            });
                        }
                    }
                });
            }
            this.forceReRenderKey++;
        },
        hasProjectInstance(item) {
            return (
                item.instances &&
                item.instances.length &&
                item.instances.filter((instance) => {
                    return instance.projectId === this.$route.params.id;
                }).length > 0
            );
        },
        getSelectedTab: function () {
            return this.tabs.find((x) => x.name === this.selected);
        },
        loadProjectTeams() {
            this.$store
                .dispatch("clientLoadProjectTeams", {
                    id: this.$route.params.id,
                })
                .then(async (data) => {
                    this.teams = data;
                });
        },
    },
};
</script>
