<template>
  <div class="container-fluid main-container">
    <div class="row sub-menu-panel">
      <navigation-panels-container
          v-if="subMenuSelector"
          id="library"
          :tab-selector="subMenuSelector"
      />
      <slot name="subMenu"/>
    </div>
    <div class="row">
      <div class="col ml-5">
        <slot name="buttonsLeft"/>
      </div>
      <div class="col"/>
      <div class="col mr-5 text-right create-new-button">
        <slot name="buttonsRight"/>
      </div>
    </div>
    <div class="row">
      <tabs v-if="backButtonTabSelector"
            slot="tabs"
            :custom-tab-name="backButtonTabSelector"
            :first-inactive="true"
            :tab-selector="backButtonTabSelector"
            classes="col-12 col-md-2 col-lg-2 offset-md-1 backbutton"
      />
      <tabs
          v-if="tabSelector"
          :id="id"
          :tab-selector="tabSelector"
          :type="type"
      />
      <slot name="tabs"/>
    </div>
    <div class="top-content-bar content-panel-color pt-5 pb-1">
      <slot name="topContent" />
    </div>
    <div class="">
      <div id="threeDragZone" :style="{width: dragZoneWidth + 'vw', float: 'left', paddingBottom: '15px'}">
        <slot name="outerLeftContent"/>
      </div>
    </div>
    <div class="content-panel content-panel-color no-padding-top">
      <div class="row">
        <div class="col-12 col-md-5 col-lg-5 col-xl-4">
          <div class="sticky-top">
            <div class="row">
              <div class="col-12 col-md-12">
                <slot name="leftContent"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-7 col-xl-8">
          <div class="sub-sub-panel-background">
            <div class="row p-sm-4 p-lg-4">
              <div class="col-12">
                <slot name="fullContent"/>
              </div>
              <div :class="[!makeMiddleColLarger ? 'col-12 col-lg-12 col-xl-5 col-xxl-5' : 'col-12 col-lg-12 col-xl-7 col-xxl-7']">
                <slot name="middleContent"/>
              </div>

              <div :class="[!makeMiddleColLarger ? 'col-lg-12 col-xl-7 col-xxl-7' : 'col-lg-12 col-xl-5 col-xxl-5']">
                <slot name="rightContent"/>
              </div>
              <div class="col-12">
                <slot name="fullContentAfter"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Tabs from "@/components/navigation/Tabs";
import NavigationPanelsContainer from "@/components/navigation/NavigationPanelsContainer";
import interact from "interactjs";

export default {
  name: "ThreeColumns",
  components: {
    Tabs,
    NavigationPanelsContainer
  },
  props: {
    /**
     * @tabSelector String the tabSelector (e.g. "appstabs", set in router.js)
     * @backButtonTabSelector String the same but for the backButton
     * if set, tabs will be displayed automatically
     */
    tabSelector: {type: String, default: ''},
    backButtonTabSelector: {type: String, default: ''},
    subMenuSelector: {type: String, default: ''},
    /**
     * @id String the id to use for the tabs if tabSelector is given
     */
    id: {type: String, default: ''},
    /**
     * @type String the type to use for the tabs if tabSelector is given
     */
    type: {type: String, default: ''},
    initDragZoneWidth: {type: Number, default: 0},
    makeMiddleColLarger: {type: Boolean, default: false}
  },
  data() {
    return {
      dragZoneWidth: 0,
      dragZone: "#threeDragZone",
      dragHandle: "#threeDragHandle"
    };
  },
  beforeMount() {
    this.dragZoneWidth = this.initDragZoneWidth;
  },
  mounted() {
    this.enableDragDropSidePanel();
  },
  methods: {
    enableDragDropSidePanel() {

      const $this = this;
      interact(this.dragZone).unset();
      interact(this.dragZone)
          .resizable({
            //manualStart:true,
            preserveAspectRatio: false,
            edges: {left: true, right: true, bottom: true, top: true},
            listeners: {
              end() {
                $this.$emit('endDrag');
               },
            },
          })
          .on('resizemove', function (event) {
            var target = event.target,
                x = (parseFloat(target.getAttribute('data-x')) || 0),
                y = (parseFloat(target.getAttribute('data-y')) || 0);

            // update the element's style
            target.style.width = event.rect.width + 'px';
            target.style.height = event.rect.height + 'px';

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.webkitTransform = target.style.transform =
                'translate(' + x + 'px,' + y + 'px)';

            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);

          });
    },
  }
}
</script>
<style lang="scss">
#threeDragZone {
  position: relative;
  z-index: 1055;
  background-color: #e5e5f7;
  opacity: 0.8;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(-45deg, #34383e 0, #626974 1px, #21252b 0, #21252b 50%)
}
</style>