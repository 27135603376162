<template>
    <div>
        <label class="vform-editor-label section">Url</label>
        <div
            v-if="element.currentLang === key"
            class="dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.url.dix)"
        >
            <div class="col-12 p-0">
                <input
                    :placeholder="
                        (key === 'Unknown' ? '' : key) + ' ' + $t('https://example.com')
                    "
                    class="mb-1 form-text v-form-label form-text-dark"
                    type="text"
                    v-model="url"
                    @keydown="$v.url.$touch"
                />
                <div class="form-error" v-if="$v.url.$error">
                    {{ $t("errors.mustBeAValidURL") }}
                </div>
            </div>
            <label class="vform-editor-label section">Text</label>
            <div class="col-12 p-0">
                <input
                    :title="element.label.dix['Unknown']"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.SHORT
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    class="mb-1 form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[key]"
                />
            </div>
        </div>
        <div class="dflex mt-3">
            <div class="col row">
                <label class="vform-editor-label section mt-0">Display As</label>
            </div>
            <div
                v-for="(displayOption, index) in displayOptions"
                :class="[
                    'vform-text-option',
                    displayOption === element.displayOption ? 'active' : '',
                    index === 0 ? 'left' : 'right'
                ]"
                :key="index"
                @click="setDisplayOption(displayOption)"
            >
                <p>{{ $t("vform." + displayOption) }}</p>
            </div>
        </div>
        <label class="vform-editor-label section">Height</label>
        <label class="section ml-2 vform-editor-body slider-label"
            >{{  this.height ? `${this.height}%` : 'AUTO' }}</label
        >
        <slider :default-value="null" :initial-value="height"
              :interval="10" :max="100" :min="0"
              @change="(val) => { this.$set(this, 'height', val) }"></slider>
        <label class="vform-editor-label section">Options</label>
        <div class="col row dflex">
            <div class="col-12 pl-0 dflex">
                <label
                    class="container vform-editor-body"
                    :for="'chkEmphasized' + $vnode.key"
                    >{{ $t("emphasized") }}
                    <input
                        type="checkbox"
                        :id="'chkEmphasized' + $vnode.key"
                        v-model="element.emphasized"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-12 pl-0 dflex">
                <label
                    class="container vform-editor-body"
                    :for="'chkBackground' + $vnode.key + 1"
                    >{{ $t("addBackgroundColor") }}
                    <input
                        type="checkbox"
                        :id="'chkBackground' + $vnode.key + 1"
                        v-model="element.backgroundColor"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-12 pl-0 dflex">
                <label
                    class="container vform-editor-body"
                    :for="'chkOpenInNewTab' + $vnode.key + 1"
                    >{{ $t("vform.newTab") }}
                    <input
                        type="checkbox"
                        :id="'chkOpenInNewTab' + $vnode.key + 1"
                        v-model="element.openInNewTab"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { vFormControls, placeholders } from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import { url } from "vuelidate/lib/validators";
import "vue-slider-component/theme/default.css";
import Slider from "@/components/widgets/editors/Slider";

export default {
    name: "vFormWEBSITE",
    components: {
        Slider
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
    },
    mixins: [vFormElementMixin],
    data() {
        return {
            forceReRenderKey: 0,
            vFormControls: vFormControls,
            placeholders: placeholders,
            url: "",
            height: 0,
            displayOptions: ["link", "iframe"],
        };
    },
    beforeMount() {
        if (!this.element.url) {
            this.$set(this.element, "url", {
                dix: {
                    Unknown: "",
                },
            });
        }

        this.element.formElementType = vFormControls["WEBSITE"];
        this.height = this.element.height ? this.element.height : this.height;
        this.url = this.element.url.dix[this.element.currentLang];
        this.element.backgroundColor = this.element.backgroundColor
            ? this.element.backgroundColor
            : false;

        if (!this.element.displayOption) {
            this.$set(this.element, "displayOption", "link");
        }

        this.element.openInNewTab =
            this.element.openInNewTab === undefined ? true : this.element.openInNewTab;
    },
    watch: {
        url() {
            this.element.url.dix[this.element.currentLang] = this.url;
        },
        height() {
            this.$set(this.element, "height", this.height);
        },
    },
    validations: {
        url: {
            url,
        },
    },
    methods: {
        setDisplayOption(val) {
            this.$set(this.element, "displayOption", val);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
