<template>
    <one-column-sidebar-left>
        <h1 slot="mainContent">{{ $t('404') }}</h1>
        <div slot="mainContent">
            {{ $t('theRequestedResourceWasNotFound') }}
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    export default {
        name: 'NotFound',
        components: {
            OneColumnSidebarLeft
        },
    }
</script>
