<template>
<div class="mr-2">
  <!---STEP VIEW MODE -->
  <div
      v-if="step && step.uuid !== editModeStepUuid"
      :id="slide.id"
      :class="['carousel-step-title draggable-step', 'vform-editor-body', editModeStepUuid ? 'fade' : '',]"
      :step_uuid="step ? step.uuid : ''"
  >
    <div :class="['dflex', 'position-relative',
        tooltipActive ? 'tooltip-active': '',]">
      <label class="label-step-number">
        {{ step.step }}
      </label>
      <label
          class="label-step-name clickable col"
          @click.stop="editStep()"
          @mouseout="$emit('hideTooltip')"
          @mouseover="$emit('showTooltip', slide.id, step)"
      >
        {{ titleEllipsis(step.name) }}
      </label>
      <div
          id="step-delete-xmark"
          class="clickable vform-element-actions"
          @click.stop="$emit('deleteStep', step ? step.uuid : '')"
      >
        <icon type="xmark"/>
      </div>
    </div>
  </div>
  <!---STEP DRAG MODE -->
  <div
      v-else-if="!step"
      :class="['drop-zone-step', draggedStep ? 'highlight' : '']"
      :slide_uuid="slide.id"
  >
    <label v-if="!step"/>
  </div>
  <!---STEP EDIT MODE -->
  <div
      v-if="step && step.uuid && editModeStepUuid === step.uuid"
      class="edit-step-title d-flex vform-editor-body step-title-height"
  >
    <label class="label-step-number">
      {{ step.step }}
    </label>
    <input
        ref="stepEdit"
        v-model="step.name"
        class="mt-0 mb-1 form-text v-form-label form-text-dark step-title-input"
        type="text"
        @focusout="$emit('finishEditStep', step.name)"
        @keydown.enter="$emit('finishEditStep', step.name)"
        @keydown.esc="$emit('cancelEditStep')"
    />
  </div>
  <!---THUMBS -->
  <div
      ref="slide"
      :class="['thumb-img-container pl-2 pr-2 mt-1 ml-1 mr-1 position-relative',
      slide.hasOwnProperty('active') && !slide.active ? 'slide-inactive' : '',
      step && editModeStepUuid === step.uuid ? 'thumb-title-active' : '',]"
      :style="{
        top: step && draggedStep === step.uuid? '25px': '',
       }">
    <div
        v-if="!step"
        class="add-step-diamond"
        @click="$emit('addStep', slide.id)"
        @mouseout="$emit('hideNewStepTooltip')"
        @mouseover="$emit('showNewStepTooltip', $refs.slide, slide.id)"
    >
      <div id="diamond"></div>
    </div>
    <div v-if="!slide.ghostSlide" class="slide-number vform-editor-body">
      {{ index + 1 }}
    </div>
    <div v-if="showTransitionButton" class="transition-adder d-inline-block ml-4 clickable"
         @click.stop="$emit('addTransition', slide.id)">
      <icon type="square-plus"/>
    </div>
    <img
        v-if="slideURL"
        :class="['slide-img', activeSlideUuid === slide.id ? 'selected' : '',]"
        :src="slideURL"
        @click="$emit('selectSlide', slide.id)"
    />
    <div v-else-if="slide.ghostSlide" :class="['ghost-slide', activeSlideUuid === slide.id ? 'selected' : '',]"
         @click="$emit('selectSlide', slide.id)">
      Non existing<br/>slide
    </div>
    <div v-else-if="!slideURL && loadingThumbsInProgress" class="spinner"
         @click="$emit('selectSlide', slide.id)">
      <loading-spinner/>
    </div>
    <div v-else-if="!loadingThumbsInProgress" class="spinner" @click="$emit('selectSlide', slide.id)">
      <div class="not-found-text">Not found</div>
    </div>
  </div>
</div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import Icon from "@/components/Icon";

export default {
  name: "SlidesCarouselItem",
  components: {LoadingSpinner,Icon},
  props: {
    index: {type: Number, default: 0},
    step: {type: Object, default: null},
    slide: {type: Object, required: true},
    slideURL: {type: String, default: ""},
    loadingThumbsInProgress: {type: Boolean, default: true},
    activeSlideUuid: {type: String, default: ""},
    showTransitionButton: {type: Boolean, default: false},
    tooltipActive: {type: Boolean, default: false},
    editModeStepUuid: {type: String, default: ""},
    draggedStep: {type: String, default: ""},
    titleEllipsis: {type: Function, default: () => {}}
  },
  watch: {
    editModeStepUuid(val) {
      if(val && this.step && val === this.step.uuid) {
        this.startEdit();
      }
    }
  },
  methods: {
    startEdit() {
      const $this = this;
      setTimeout(() => {
        $this.$refs.stepEdit.focus();
        $this.$refs.stepEdit.select();
      }, 200)
    },
    editStep() {
      this.$emit('editStep', this.step);
    },
  }
}
</script>

<style lang="scss" scoped>
$slide-height: 70px;
$slide-width: 117px;

.slide-img {
  cursor: pointer;
  width: 125px;
  height: $slide-height;

  &:hover {
    opacity: 0.7;
  }

  border: solid 1px var(--vform-editor-ui-tertiary-color);
  border-radius: 2px;
}

.slide-img, .ghost-slide {
  &.selected {
    outline: solid 2px var(--vform-editor-accent-color);
    border: solid 1px var(--vform-editor-accent-color);
    border-radius: 2px;
  }
}

.thumb-img-container {
  position: relative;
  transition: opacity 300ms ease;
  &:hover {
    .slide-img {
      opacity: 0.7;
    }
  }
}

.slide-not-found {
  min-width: $slide-width;
  width: $slide-width;
  height: 120px;
  background: transparent;
  border: solid 1px $highlight !important;
  color: white;
  font-size: 14px;
}

.carousel-step-title {
  line-height: 25px;
  background: var(--vform-editor-ui-quinary-color);
  border-radius: 3px;
  color: #fff;

  &.fade {
    opacity: 0.3;
  }

  position: relative;
  margin: 0 2px 0 2px;
}

.edit-step-title {
  overflow: show;
  position: absolute;
  top: 0;
  width: 400px;
  left: 2px;
  height: 16px;
  z-index: 100;
  background: var(--vform-editor-ui-quinary-color);
  border-radius: 3px;
  line-height: 25px;
}

.thumb-title-active {
  margin-top: 29px !important;
}

.step-title-input {
  height: 22px !important;
  margin: 1px 2px 0 5px !important;
  border: solid 1px white !important;
  background: var(--vform-editor-ui-quinary-color) !important;
}

.label-step-number {
  margin: auto 0 auto 5px !important;
  background: var(--vform-editor-ui-secondary-color) !important;
  padding: 3px;
  line-height: 10px;
  cursor: move;
}

.label-step-name {
  margin: auto 0 auto 5px !important;
  padding: 3px;
  line-height: 10px;
  font-size: 0.7rem;
}
#step-delete-xmark {
  margin-left: auto;
  margin-right: 6px;

  &:hover {
    color: var(--vform-editor-accent-color);
  }
}
.add-step-diamond {
  position: absolute;
  left: -9px;
  top: -0px;
  cursor: pointer;
}

.add-step-diamond:hover {
  left: -10px;
  top: -2px;
}

#diamond {
  background: var(--vform-editor-layout-secondary-color);
  height: 9px;
  text-align: center;
  transform: rotate(45deg);
  border: solid 1px var(--vform-editor-ui-tertiary-color);
  width: 9px;
}

#diamond:hover {
  background: var(--vform-editor-accent-color);
  height: 12px;
  text-align: center;
  transform: rotate(45deg);
  border: solid 1px var(--vform-editor-accent-color);
  width: 12px;
}

.slide-number {
  opacity: 0.6;
  background: var(--vform-editor-ui-quaternary-color);
  color: var(--vform-editor-ui-primary-color);
  width: auto;
  min-width: 20px;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 9px;
}

.transition-adder {
  opacity: 0.6;
  background: var(--vform-editor-ui-quaternary-color);
  position: absolute;
  right: 9px;
  bottom: 1px;
  font-size: 0.8rem;
  z-index: 50;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 2px;
  transition: all 300ms ease;

  &:hover {
    background: #000;
  }
}

.step-title-height {
  height: 25px;
}

.ghost-slide {
  width: $slide-width;
  min-height: $slide-height;
  background-color: var(--vform-editor-ui-quaternary-color);
  border-radius: 3px;
  font-size: 0.8rem;
  padding: 4px;
}
.spinner {
  background-color: #0f0f10;
  border-radius: 2px;
  width: $slide-width;
  height: $slide-height;
  color: #fff;

  .not-found-text {
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 90%;

    text-align: center;
  }

  .loading-spinner-widget, .not-found-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.slide-inactive {
  opacity: 0.3;
  .slide-img {
    border: 1px solid #000;
  }
}
</style>