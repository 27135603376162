<template>
    <filter-section :id="id" :collapsible="collapsible">
        <template slot="name">{{ $t('filter.typeSelect') }}</template>
        <tile-button
                slot="content"
                :make-compact-display="true"
                ref="tiles"
                :initial-active-tiles="selectedTiles"
                :is-multi-select="true"
                :icons="true"
                :tiles="Object.values(typeFilterItems)"
                :values="typeFilterValues && typeFilterValues.length ? Object.values(typeFilterValues) : Object.values(typeFilterItems)"
                @trigger="setActive" />
    </filter-section>
</template>

<script>
    import TileButton from "../../forms/TileButton";
    import FilterSection from "./FilterSection";

    /**
     * A filter component (Checkboxes) for asset types
     * @displayName Type Filter
     */
    export default {
        name: "TypeFilter",
        components: {
            TileButton,
            FilterSection
        },
        props: {
            /**
             * An array of type names
             * @values asset-names e.g. tbl, obj
             * */
            typeFilterItems: {type: Array, required: true},
            /**
             * An array of type values in case the names aren't the valeu
             * @values like 5, 6
             * */
            typeFilterValues: {type: Array, default: null},
            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0},
            collapsible: {type: Boolean, default: false},
            /**
             * @param typeExcludeValues if you wish to exclude some items from the filtered result
             * */
            typeExcludeValues: {type: Array, default: null},
            preSelectedValues: {type: Array, default: null}
        },
        data() {
            return {
                checkedFilterTypes: [],
                selectedTiles:[]
            };
        },
        computed: {
            getId: function() {
                return this.$route.name + this.id  + 'MediaCategoryFilter';
            }
        },
        watch: {
            clear() {
                this.clearValue();
            },
            preSelectedValues() {
                 this.setPreSelection();
            }
        },
        beforeMount() {
            let localFilter = JSON.parse(window.localStorage.getItem(this.getId));
            if(localFilter) {
                this.checkedFilterTypes = localFilter;
                for(let i = 0; i < localFilter.length; i++) {
                  if(localFilter[i])  {
                    const val = this.typeFilterItems[this.typeFilterValues.indexOf(localFilter[i])];
                    if(!this.selectedTiles.includes(val)) {
                      this.selectedTiles.push(val);
                    }
                  }
                }
            }
            this.setPreSelection();
            this.filter(null, true);
        },
        methods: {
            setPreSelection() {
              if(this.preSelectedValues) {
                for(let i = 0; i < this.preSelectedValues.length; i++) {
                    this.checkedFilterTypes.push(this.typeFilterValues[this.typeFilterItems.indexOf(this.preSelectedValues[i])])
                    this.selectedTiles.push(this.preSelectedValues[i]);
                }
              }
            },
            setActive: function(args) {
                if(args.operation === 'add') {
                    if(!this.checkedFilterTypes.includes(args.value)) {
                        this.checkedFilterTypes.push(args.value);
                    }
                }
                else {
                   this.checkedFilterTypes.splice(this.checkedFilterTypes.indexOf(args.value), 1);
                }
                this.filter();
            },
            clearValue: function() {
                this.$refs.tiles.clearActiveStatus();
                this.filter(null, true, true);
            },
            // eslint-disable-next-line no-unused-vars
            filter(mouseEvent = null, initial = false, selectAll = false) {
                let filterParamId = 'mediaCatFilter';
                let params = {};
                let toBeFilteredTypes = this.checkedFilterTypes;
                if(toBeFilteredTypes.length === 0 || selectAll) {
                    toBeFilteredTypes = [];
                }
                if (toBeFilteredTypes.length > 0) {
                    let filterString = this.makeFilterString(toBeFilteredTypes, false);
                    params = {
                        add: {
                            filter: {
                                [filterParamId]: filterString
                            }
                        }
                    };
                }
                else if(this.typeExcludeValues && this.typeExcludeValues.length) {
                  let filterString = this.makeFilterString(this.typeExcludeValues, true);
                  params = {
                    add: {
                      filter: {
                        [filterParamId]: filterString
                      }
                    }
                  };
                }
                else {
                    params = {
                        remove: {
                            filter: [filterParamId]
                        }
                    };
                }
                if(params) {
                    /**
                     * sends the filtering content to the parent component
                     * ready to use in a register or loading method
                     * */
                    this.$emit('filter', params, initial, 'mediaCat');
                }
                window.localStorage.setItem(this.getId, JSON.stringify(this.checkedFilterTypes));
            },
            makeFilterString(filterArr, negation = false) {
              let filterString = '';
              if (filterArr.length === 1) {
                filterString = `mediaCategory ${negation ? 'not' : ''} eq ` + filterArr[0];
              } else {
                filterString = `mediaCategory ${negation ? 'not' : ''} in `;
                for (let i = 0; i < filterArr.length; i++) {
                  filterString += filterArr[i];
                  if(i !== filterArr.length - 1) {
                    filterString += ' ';
                  }
                }
              }
              return filterString;
            }
        },
    }
</script>