<template>
<div>
  <span class="lighter uppercase">Profile Version {{ content.filterVersion ? content.filterVersion : '1' }}</span><br /><br />

  <h4 class="uppercase">1 {{ $t('Filter') }}</h4>
  <span class="lighter uppercase">{{ $t('Mode') }}:</span> {{ content.pipeline.subVersion.mode }}
  <div v-if="content.filterStringArray" v-for="(value, key) in content.filterStringArray" :key="key">
    - {{ value.field }} {{ getModifierName(value.modifier) }} "{{ value.val }}"
  </div>
  <div v-if="content.filter" v-for="(value, key) in content.filter" :key="key">
    - {{ value.metaSet }} {{ value.metaField }} {{ getModifierName(value.modifier) }} "{{ value.val }}"
  </div>

  <h4 class="mt-3 uppercase">2 {{ $t('Merge') }}</h4>
  <div v-if="content.mergeStringArray" v-for="(value, key) in content.mergeStringArray" :key="key + 'adflaksjdfa'">
    - {{ value.field }} {{ getModifierName(value.modifier) }} "{{ value.val }}"
  </div>
  <span v-else>-</span>
  <div v-if="content.merge" v-for="(value, key) in content.merge" :key="key">
    - {{ value.metaSet }} {{ value.metaField }} {{ getModifierName(value.modifier) }} "{{ value.val }}"
  </div>

  <h4 class="mt-3 uppercase">3 {{ $t('Corrections') }}</h4>
  <span v-if="content.pipeline.axisMultiplier && JSON.stringify(content.pipeline.axisMultiplier) !== '[0,0,0]'"><span class="lighter">{{ $t('axisMultiplier') }}:</span> {{ content.pipeline.axisMultiplier }}</span><br>
  <span v-if="content.pipeline.pivotPlacement && content.pipeline.pivotPlacement !== 'source'"><span class="lighter">{{ $t('pivotPlacement') }}:</span> {{ content.pipeline.pivotPlacement }}</span><br>
  <span v-if="!!content.pipeline.pivotField"><span class="lighter uppercase">{{ $t('pivotsField') }}:</span> {{ content.pipeline.pivotField }}</span>
</div>
</template>

<script>
import {filterModifiers} from "@/enum";

export default {
  name: "FilterDisplay",
  props: {
    content: {type: Object, required: true}
  },
  methods: {
    getModifierName(mod) {
      const item = Object.entries(filterModifiers).find(item => {
        return item[1] === mod;
      });
      return item[0] ? item[0] : 'unknown';
    },
  }
}
</script>