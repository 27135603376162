<template>
    <div>
      <popup
                    @close="() => {showDeletePrompt = false;}"
                    v-if="showDeletePrompt"
            >
                <delete-prompt
                        v-if="showDeletePrompt"
                        slot="popupMainContent"
                               @abort="() => showDeletePrompt = false"
                               @confirm="() => {deleteRows();}"
                        custom-message="WillDeleteAllRows"
                />
            </popup>
        <div class="table-outer scroll-container-horizontal">
          <table v-if="!loading" class="">
              <tr v-if="!noHeader" class="header">
                  <th :class="selectableStyle === 'default' ? 'selectLinking' : ''" v-if="selectableCheckbox || topRowsSelectableCheckbox"/>
                  <th :class="selectableStyle === 'default' ? 'selectLinking' : ''" v-if="canUnlink"/>
                  <th v-for="(field, fieldIndex) in fields" :key="fieldIndex" :class="[field.sortable ? 'sortable' : '', sort.fieldname === field.id ? 'active' : '', field.type ? field.type : '', field.name.replace(' ', '').toLowerCase(), field.id ? 'field-id-'+ field.id : '']"
                      v-on="field.sortable ? {click: () => sortTable(field.id) } : {}">
                      {{ $t(field.name) }}
                      <icon v-if="field.name && field.sortable && sort.fieldname === field.id" :type="sort.fieldname === field.id && sort.direction === '-' ? 'angle-down' : 'angle-up'"/>
                  </th>
                  <th v-if="selectableRows">

                  </th>
                  <th class="buttonField" v-if="deletable && $store.getters.isSuperAdmin">
                    <Button class="hidden" type="action-button" @click="deleteRows" slot="mainContentButtons" icon="trash-alt">
                      {{ $t('delete') }}
                    </Button>
                  </th>
                  <th class="buttonField" v-else-if="deletable"/>
              </tr>
              <table-row
                      v-for="(item, index) in insertedTopRows"
                      :item="item"
                      :key="item.id + index + forceReRenderKey"
                      :fields="fields"
                      :deletable="deletable"
                      :resource="resource"
                      :show-organization="showOrganization"
                      :edit-permission="editPermission"
                      :view-permission="viewPermission"
                      :delete-permission="deletePermission"
                      :custom-edit-permission-function="customEditPermissionFunction"
                      :custom-delete-permission-function="customDeletePermissionFunction"
                      :selectable-checkbox="topRowsSelectableCheckbox"
                      :add-placeholder-start="!topRowsSelectableCheckbox && selectableCheckbox"
                      :can-unlink="canUnlink"
                      :show-tags="showTags"
                      :selectable-rows="selectableRows"
                      :nav-by-clicking-row="navByClickingRow"
                      :full-row-selectable="fullRowSelectable"
                      @updateColor="updateColor"
                      @deleteRow="deleteRow"
                      @selectCheckbox="selectCheckbox"
                      @unlink="unlink"
                      @edit="$emit('edit', item.id)"
                      @updateContent="updateContent"
                      @selectRow="selectRow"
                      class="highlighted"
                      :show-instances="showInstances"
                      :permission-by-type="permissionByType"
                      :show-state="showState"
                      :selectable-style="selectableStyle"
                      :hide-edit-button="topRowsHideEditButton"
              />

              <table-row
                      v-for="(item, key, index) in data"
                      :item="item"
                      :key="item.id + forceReRenderKey"
                      :fields="fields"
                      :deletable="deletable"
                      :resource="resource"
                      :show-organization="showOrganization"
                      :edit-permission="editPermission"
                      :view-permission="viewPermission"
                      :delete-permission="deletePermission"
                      :custom-edit-permission-function="customEditPermissionFunction"
                      :custom-delete-permission-function="customDeletePermissionFunction"
                      :selectable-checkbox="selectableCheckbox"
                      :add-placeholder-start="topRowsSelectableCheckbox && !selectableCheckbox"
                      :can-unlink="canUnlink"
                      :show-tags="showTags"
                      :selectable-rows="selectableRows"
                      :nav-by-clicking-row="navByClickingRow"
                      :full-row-selectable="fullRowSelectable"
                      @updateColor="updateColor"
                      @deleteRow="deleteRow"
                      @selectCheckbox="selectCheckbox"
                      @unlink="unlink"
                      @edit="$emit('edit', item.id)"
                      @updateContent="updateContent"
                      @selectRow="selectRow"
                      :class="[index % 2 === 0 ? 'even' : 'odd', item.selected ? 'selected' : '', selectedRows && selectedRows.length && selectedRows.includes(item.id) ? 'active' : '']"
                      :show-instances="showInstances"
                      :permission-by-type="permissionByType"
                      :show-state="showState"
                      v-if="!insertedTopRows || (insertedTopRows.length && !insertedTopRows.map(item => {return item.id}).includes(item.id))"
                      :selectable-style="selectableStyle"
                      :hide-edit-button="hideEditButton"
              />
          </table>
          <div class="table-empty" v-if="!data || Object.keys(data).length === 0 || loading">
              <loading-spinner v-if="!hasGivenUp || loading" />
              <span v-else>
                  {{ $t('nodata') }}
              </span>
          </div>
        </div>
    </div>
</template>

<script>
    import Icon from "../Icon";
    import TableRow from "./TableRow";
    import LoadingSpinner from "../LoadingSpinner";
    import Button from "../forms/Button";
    import DeletePrompt from "../forms/DeletePrompt";
    import Popup from "../Popup";

    export default {
        name: 'TableWidget',
        components: {
            Icon,
            TableRow,
            LoadingSpinner,
            Button,
            DeletePrompt,
            Popup
        },
        props: {
            resource: {type: String, default: 'asset'},
            navByClickingRow: {type: Boolean, default: false,},
            //if true, parent item has to implement deleteRow in order to work
            deletable: {type: Boolean, default: false,},
            fields: {type: Array, required: true,},
            data: {type: Object, default: null},
            selectableCheckbox: {type: Boolean,},
            canUnlink: {type: Boolean, default: false},
            /**
             * todo: rename variable
             * @selectableRows Boolean Whether or not to make row-preview selectable
             */
            selectableRows: {type: Boolean,},
            /**
             * todo: rename variable
             * @selectableRows Boolean Whether or not to make row-preview selectable
             */
            fullRowSelectable: {type: Boolean, default: false},
            /**
             * @id String The unique identifier for this table for this page
             */
            id: {type: String, required: true,},
            /**
             * @selectedRows Object The selected rows (ids)
             * example: {'asdf-24234','234234-dfsd'}
             */
            selectedRows: {type: Array, default: null},
            /**
             * @showTags Boolean Whether or not to display the tags in the title field
             */
            showTags: {type: Boolean, default: false},
            /**
             * @showOrganization Boolean Whether or not to display the organization in the title field
             */
            showOrganization: {type: Boolean, default: false},
            showState: {type: Boolean, default: false},
            /**
             * @editPermission String the permission string to check for the current user (check permission store for all available permissions)
             */
            editPermission: {type: String, default: ""},
            viewPermission: {type: String, default: ""},
            customEditPermissionFunction: {required: false, default: null},
            customDeletePermissionFunction: {required: false, default: null},
            /**
             * @permissionByType If there are multiple permissions per type, use this one
             */
            permissionByType: {type: Object, default: () => {}},
            /**
             * @deletePermission String the permission string to check for the current user (check permission store for all available permissions)
             */
            deletePermission: {type: String, default: null},
            /**
             * @noHeader Boolean Whether or not to display the table header
             */
            noHeader: {type: Boolean, default: false},

            // wheter or not to show instances in case the item is an asset
            showInstances: {type: Boolean, default: false},

            /**
             * @loading sets the table state to loading
             * */
            loading: {type: Boolean, default: false},

            /**
            * Those top rows come from the parent component
            * and should be the same format as the other data
            * they will be highlighted
            * */
            insertedTopRows: {type: Array, default: null},
            topRowsSelectableCheckbox: {type: Boolean, default:false},
            topRowsHideEditButton: {type: Boolean, default: true},
            hideEditButton: {type: Boolean, default: false},

            /***
            * How the selectableCheckbox should be styled
            * */
            selectableStyle: {type: String, default: "default"},
            initialSorting: {type: Object, default: () => {return null}}
        },
        data() {
            return {
                hasGivenUp: false,
                sort: {
                    fieldname: 'updatedAt',
                    direction: '-',
                },
                showDeletePrompt: false,
                forceReRenderKey: 0
            }
        },
        watch: {
            hasGivenUp() {
              this.$emit('hasGivenUp', !this.data || Object.keys(this.data).length === 0 );
            },
            insertedTopRows: {
              deep:true,
              handler() {
                this.forceReRenderKey++;
              }
            },
          initialSorting: {
            deep:true,
            handler() {
              this.setSorting();
            }
          },
        },
        mounted() {
            this.setSorting();
            let $this = this;
            setTimeout(function() {
                $this.hasGivenUp = true;
            }, 3000);
        },
        methods: {
          setSorting() {
            console.log('initial ')
            console.log(this.initialSorting)
            if(this.initialSorting && this.initialSorting.fieldname) {
              this.sort = this.initialSorting;
              console.log('initial sorting found ' + this.initialSorting)
            }
          },
            updateColor(args) {
                this.$emit('updateColor', args);
            },
            sortTable(fieldname) {
                if (this.sort.fieldname === fieldname) {
                    if (this.sort.direction === '-') {
                        this.sort.direction = '+';
                    } else {
                        this.sort.direction = '-';
                    }
                    this.$emit('sort', {fieldname: fieldname, action: 'remove', direction: this.sort.direction});
                }
                this.sort.fieldname = fieldname;
                this.$emit('sort', {fieldname: fieldname, action: 'add', direction: this.sort.direction});
            },
            deleteRows() {
              if(!this.showDeletePrompt) {
                this.showDeletePrompt = true;
              } else {
                this.$emit('deleteRows', Object.keys(this.data));
                this.showDeletePrompt = false;
              }
            },
            deleteRow(id) {
                this.$emit('deleteRow', id);
            },
            selectCheckbox: function (id, action, item) {
                this.$emit('selectCheckbox', id, action, item);
            },
            unlink: function (id) {
                this.$emit('unlink', id);
            },
            //used if a content gets modified in the table directly
            updateContent(args) {
                this.$emit('updateContent', args);
            },
            selectRow: function(args) {
                this.$emit('selectRow', args);
            },
        }
    }
</script>
<style lang="scss">
    .table-empty {
        width: 100%;
        padding:15px;
        background-color: $table-row-even-background;
    }
    table {
        table-layout: fixed;
        .sensor-icon {
            width: 20px;

            path {
                fill: #fff;

            }
        }
        .color-preview {
            position: absolute;
            z-index: 5;

            &.selected {
                z-index: 6;
            }
        }

        .table-row-title {
            font-size: 1.1em;
            display: block;
        }

        .tag {
            margin-top: 8px;
        }

        .selectLinking {
            cursor: pointer;
            width: 48px;
            min-width: auto;
            max-width:48px;
            .selected svg, svg:hover {
                color: $highlight;
            }
        }

        .table-row-instances {
            .icon {
                float:left;
            }
            .link-name {
                line-break: anywhere;
                max-width: 80%;
            }
        }

        .checkbox {
            width: 20px;
            height: 20px;
            margin: auto;
            border: 1px solid $brighter-border-color;
            background-color: #444;
            position: relative;
            cursor: pointer;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            &.selected, &:hover {
                border-color: $highlight;
                background-color: $highlight;
            }
        }

        a:hover {
            color: #fff;
        }

        td, th {
            padding: 5px;
            min-width: 80px;
            position: relative;
            word-break: break-all;
          &.editButton {
            width: 100px;
            padding-left:5px;
            padding-right:5px;
          }
          &.date {
            width: 120px;
          }
          &.type {
            width: 120px;
          }
          &.buttonField {
            width: 60px;
          }
          &.field-id-name {
            width: 200px;
          }
          &.icon {
            width: 6.5vw;
            max-width: 120px;
            min-width:auto;
            .preview {
                width: 100%;
            }
          }
          &.image {
            width: 80px;
          }
        }

        th {
          background-color: #1E2022;
        }
        th.sortable {
            cursor: pointer;
          &:hover {
            background-color: #34383e;
          }
            &.active {
              background-color: #34383e;
            }
        }

        tr {
            border: 1px solid $table-border-color;
            width: 100%;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;

            .button.show-button-on-hover {
                opacity: 0;
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;
            }

            &.even {
                background-color: $table-row-even-background;
                .image, > .icon {
                    background-color: $preview-in-table-border-color-even;
                }
            }

            &.odd {
                background-color: $table-row-odd-background;
                .image, > .icon {
                    background-color: $preview-in-table-border-color-odd;
                }
            }

            &.theader {
                background-color: $table-header-color;
                color: $textcolor-bright;
                margin-bottom: 12px;
                margin-top: 12px;
                border: 0px;
            }

            &.even, &.odd {
                &.no-access {
                    background-color: $table-inactive-row-background;
                }

                &.hoverRow {
                    cursor: pointer;

                    &:hover {
                        background-color: $table-row-hover-background;
                    }
                }
              &.hoverRowHighlight {
                cursor: pointer;

                &:hover {
                  background-color: $highlight;
                }
              }

                &:hover {
                    .button {
                        opacity: 1;
                    }
                }
            }

            &.active {
                //background-color: $highlight;
                .rowSelector {
                    color: $highlight;
                }
            }
            &.highlighted {
              background-color:  $highlight;
            }
        }

        .header {
            border: 1px solid transparent;
            border-bottom: 1px solid $table-border-color;
        }

        .rowSelector {
            font-size: 1.8em;
            cursor: pointer;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            color: #fff;

            &:hover {
                color: $highlight;
            }
        }
    }
    @media (min-width: 1680px) {
        table {
             td, th {
              &.field-id-name {
                width: 300px;
              }
              &.image {
                width: 130px;
              }
            }
        }
    }
    @media (min-width: 1920px) {
        table {
             td, th {
              &.field-id-name {
                width: auto;
              }
              &.image {
                width: 130px;
              }
            }
        }
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait), screen and (max-width: 300px) {
        #vhub {
          background-color: red;
        }
    }
    @media (max-width: 768px) {
      .table-outer {
        max-width: 91vw;
        overflow: scroll;
        table {
          table-layout: auto;
          th, td {
            min-width: 110px;
          }
        }
      }
    }

</style>
