<template>
  <div>
    <div class="mb-1">{{ $t('pleaseChooseAPresentation') }}<info-helper text="vformProjectLinkerHelptext"/></div>
    <tag
        :icon-left="null"
        :tag="{color: '#666'}"
        v-if="localProjectId"
        @removeTag="$emit('removeProject')"
    >
      <content-display
          :list-name="localProjectId"
          type="project"
          :value="localProjectId"
          class="d-inline-block"
      />
    </tag>
    <universal-selector
        v-if="!localProjectId"
        class="mt-2"
        @addItem="addProject"
        :exclude-from-item-list="localProjectId ? [localProjectId] : []"
        list-name="formProjectList"
        store-name="Project"
        filter-attribute="name"
        :limit-by-organization-id="organizationId"
        tag-icon="folder"
        :show-organization="true"
        include="instances"
        :after-query-filter="filterWithForms"
    />
  </div>
</template>

<script>
import UniversalSelector from "@/components/organizations/UniversalSelector";
import ContentDisplay from "../widgets/editors/ContentDisplay";
import Tag from "../../components/Tag";
import InfoHelper from "../InfoHelper";
export default {
  name: "ProjectLinker",
  components: {
    UniversalSelector,
    ContentDisplay,
    Tag,
    InfoHelper
  },
  props: {
    organizationId: {type: String, required: true},
    projectId: {type: String, default: null}
  },
  watch: {
    projectId() {
      this.setInitialProject();
    }
  },
  data() {
    return {
      localProjectId: null,
    };
  },
  beforeMount() {
    this.setInitialProject();
  },
  methods: {
    setProjectId(id) {
      this.localProjectId = id;
    },
    setInitialProject() {
      if(this.projectId) {
        this.localProjectId = this.projectId;
      }
    },
    addProject(project) {
      this.localProjectId = project.id;
      const canAdd = this.checkvFormAttachementStatus();
      if(canAdd) {
        this.$emit('addProject', project.id);
      } else {
        this.$emit('cannotAddProject', project.id);
      }
    },
    checkvFormAttachementStatus() {
      // todo: check if project has already a vform attached – if yes, reject project
      return true;
    },
    filterWithForms(item) {
      return !item.instances || item.instances.filter(instance => {
        return instance.type === "form";
      }).length === 0;
    }
  }
}
</script>