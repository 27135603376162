<template>
    <div class="checkbox-container">
        <input id="checkbox" class="form-text checkbox mr-2" ref="checkbox" type="checkbox"
               :checked="Number(value)" >
        <label for="checkbox">{{ label }}</label>
        <div class="w-100" />
        <Button
                class="mt-1"
                @click="save"
        >
            <icon type="save"/> {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    export default {
        name: "BooleanWidget",
        components: {
            Button,
            Icon
        },
        props: {
            value: {type: String, default: '0'},
            label: {type: String, default: ''}
        },
        methods: {
            save: function () {
                this.$emit('save', this.$refs.checkbox.checked);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .checkbox-container {
        .checkbox {
            float:left;
            width: 20px;
            height: 23px;
            margin-right: 3px;
        }
    }
</style>