<template>
    <div class="p-5">

        </div>
</template>

<script>
    export default {
       name: 'INFO',

      methods: {

      }
    }
</script>