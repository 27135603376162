<template>
  <div class="password-checker">
    <div v-for="(cond) in conditions" :key="cond.string" :class="['p-1 mt-1', cond.valid ? 'bg-green' : 'bg-gray']">
      <icon :type="cond.valid ? 'check' : 'times'"/>
      {{ cond.valid }} {{ $t('pwdConditions.' + cond.string, cond.args ? cond.args : {}) }}
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

/**
 * Receives a password, checks it for complexity and displays error messages and checkboxes
 * **/
export default {
  name: "PasswordChecker",
  components: {
    Icon
  },
  props: {
    password: {type: String, default: ''}
  },
  data() {
    return {
      isValid: false,
      conditions: [{
        string: 'atLeastOneDigit',
        regex: /^(?=.*\d).+$/,
        valid: false,
      },
        {
          string: 'atLeastOneUppercaseLetter',
          regex: /^(?=.*[A-Z])(.*){12,64}$/,
          valid: false,
        },
        {
          string: 'atLeastOneSpecialCharacter',
          args: {
            characters: '=;?!@#$%^&*-_',
          },
          regex: /[-=;?!@#$%^&_*]+/,
          valid: false,
        },
        {
          string: 'atLeastCharacters',
          args: {
            min: 12,
            max: 64
          },
          regex: /[a-zA-Z\d=;?!@#$%^&*-_]{12,64}$/,
          valid: false,
        },
      ]
    }
  },
  watch: {
    password() {
      for (let i = 0; i < this.conditions.length; i++) {
        this.conditions[i].valid = this.checkComplexity(this.conditions[i].regex);
      }
      this.isValid = this.checkPasswordComplexity(this.password);
    },
  },
  methods: {
    checkComplexity(regex) {
      console.log('testing: ')
      return regex.test(this.password);
    },
    /**
     * password must have:
     *  - one digit
     *  - one uppercase letter
     *  - one special character
     *  - length between 12 and 64
     *  @returns {object}
     *  {
     *      string: 'translationstring',
     *      args: 'translationargs'
     *  }
     */
    checkPasswordComplexity() {
      /* if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[*=;?!@#$%^&*\-_]).{12,64}$/.test(password)) {
           this.$emit('status', false);
         } else */
      if (!this.checkComplexity(this.conditions[0].regex)) {
        this.$emit('status', false);

        return {string: 'errors.password.atLeastOneDigit'};
      } else if (!this.checkComplexity(this.conditions[1].regex)) {
        this.$emit('status', false);

        return {string: 'errors.password.atLeastOneUppercaseLetter'};
      } else if (!this.checkComplexity(this.conditions[2].regex)) {
        this.$emit('status', false);

        return {string: 'errors.password.atLeastOneSpecialCharacter', args: {characters: this.characters}};
      } else if (!this.checkComplexity(this.conditions[3].regex)) {
        this.$emit('status', false);

        return {string: 'errors.password.atLeastCharacters', args: {min: 12, max: 64}};
      }
      this.$emit('status', true);
      return '';
    }
  }
}
</script>

<style>
.password-checker {
  max-width: 350px;
}

.bg-green {
  background-color: #38884d;
}

.bg-gray {
  background-color: #777676;
}
</style>