<template>
<div>
  <label class="vform-label white">{{ $t('FindInstances') }}</label>
  <input type="text" class="form-text form-text-dark" v-model="stringFilter" />
  <loading-spinner v-if="loading" />
  <div class="dflex ml-1 mt-2">
    <label :for="'showPreview' + $vnode.key" class="container"
    >{{ $t('SelectUnselectAll')}} <div v-if="getSelectedInstances().length">{{ getSelectedInstances().length }} selected</div>
      <input
          :id="'showPreview' + $vnode.key"
          v-model="selectAll"
          type="checkbox"
          @change="selectAllItems()"
      />
      <span class="checkmark"></span>
    </label>
  </div>
  <div>
    <div v-for="instance in filteredInstances" :key="instance.id">
      <div class="dflex ml-3">
        <label :for="'select' + $vnode.key + instance.id" class="container"
        >{{instance.displayName ? instance.displayName : instance.name}}
          <input
              :id="'select' + $vnode.key + instance.id"
              v-model="instance.checked"
              type="checkbox"
              @change="emitChange()"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "InstanceFilter",
  components: {LoadingSpinner},
  props: {
    projectId: {type: String, required: true}
  },
  data() {
    return {
      instances: [],
      stringFilter: '',
      selectAll: false,
      loading: false
    }
  },
  computed: {
    filteredInstances() {
      if(!this.stringFilter) {
        return [];
      }
      return this.instances.filter(item => {
        if ((item.name && item.name.includes(this.stringFilter)) || (item.displayName && item.displayName.includes(this.stringFilter))) {
          // The stringFilter is found within the item's name or displayName
          return true;
        } else {
          // The stringFilter is not found within the item's name or displayName
          return false;
        }
      })
    }
  },
  watch: {
    stringFilter() {
      if(!this.instances || !this.instances.length) {
        this.loadInstances();
      }
    }
  },
  methods: {
    getSelectedInstances() {
      return this.filteredInstances.filter(item => {return item.checked});
    },
    emitChange() {
      const filtered = this.getSelectedInstances();
      this.$emit('selected', filtered ? filtered.map(item => {return item.id}) : []);
    },
    selectAllItems() {
      this.filteredInstances.forEach(instance => {
        instance.checked = this.selectAll;
      });
      this.emitChange();
    },
    async loadInstances() {
      this.loading = true;
      this.instances = await this.$store.dispatch('clientLoadProjectInstances', {
        id: this.projectId,
        limit: 0
      })
      this.instances = this.instances.map(item => {
        item.checked = false;
        return item;
      })
      this.loading = false;
    }
  }
}
</script>