<template>
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.96424 10.58L3.42526 6L7.96425 1.41L6.56687 1.04438e-06L0.620604 6L6.56687 12L7.96424 10.58Z" fill="white"/>
  </svg>
</template>


<script>
export default {
  name: 'SfxIconArrowLeft',
}

</script>