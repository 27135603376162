<template>
  <main-table-layout
      sub-menu-selector="adminpanel"
  >
    <div slot="buttonsRight"/>

    <content-filter
        id="userOverview"
        slot="filter"
        :limit-to-organization-ids="$store.getters.getCurrentUserOrganizations.map(item => {return item.id}).filter(item => {return item !== $store.getters.getSuperOrg})"
        :org-filtering="false"
        :tag-filtering="false"
        store-name="User"
        @filter="filtering"
    >
      <organization-switcher
          @setOrganization="(id) => {organizationId = id; showAllUsers = false;}"
          class="mt-3"
          :show-teams="false"
      />

      <div class="dflex mt-3" v-if="$store.getters.isSuperAdmin">
        <label class="container" :for="'showAllUsers' + $vnode.key"
        >{{ $t("show all users") }}
          <input
              type="checkbox"
              :id="'showAllUsers' + $vnode.key"
              v-model="showAllUsers"
              @change="loadData"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </content-filter>

    <view-switcher
        :id="'mainContent'"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />
    <pagination
        :id="'usertablepage'"
        ref="pagination"
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getUserListOptions(listName, 'pagination_items')"
        @setActive="loadPaginatedData"
    />
    <div slot="mainContentButtons">
      <Button
          icon="plus"
          link="/admin/users/add"
          permission="createUser"
          type="action-button">
        {{ $t('createNewUser') }}
      </Button>
    </div>
    <Button
        slot="mainContent"
        class="icon-button"
        icon="redo"
        type="reload"
        @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
    />
    <table-widget
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
        :id="'usersDefaultTable'"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getUsersByListName(listName)"
        :deletable="true"
        :fields="tableFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :show-tags="true"
        :title-as-links="true"
        :custom-edit-permission-function="customEditPermissionFunction"
        :custom-delete-permission-function="customEditPermissionFunction"
        delete-permission="readUser"
        edit-permission="writeUser"
        @deleteRow="deleteRow"
        @sort="sort"
    />
    <thumbnails
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
        :id="'usersDefaultThumbnails'"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getUsersByListName(listName)"
        :deletable="false"
        :fields="thumbnailFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :title-as-links="true"
        delete-permission="readUser"
        edit-permission="writeUser"
        @deleteRow="deleteRow"
        @sort="sort"
    />
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import TableWidget from "../components/widgets/TableWidget";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import ContentFilter from "@/components/widgets/filter/ContentFilter";
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";

export default {
  name: "Users",
  components: {
    Button,
    TableWidget,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
    ContentFilter,
    OrganizationSwitcher
  },
  data() {
    return {
      showAllUsers: this.$store.getters.isSuperAdmin,
      loading: true,
      listName: 'userOverview',
      organizationId: '',
      paginationLimit: 15,
      viewId: 'mainContent',
      forceReRenderKey: 0,
      filter: {},
      offset: 0,
      sorting: '-updatedAt',
      tableFields: [
        {
          name: 'image',
          id: 'image',
          type: 'image',
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'id',
          id: 'id',
          type: 'string',
          sortable: true
        },
        {
          name: 'Internal',
          id: 'internal',
          type: 'string',
        },
        {
          name: 'Blocked',
          id: 'blocked',
          type: 'boolean',
          sortable: true
        },
        {
          name: 'App Panel',
          id: 'appPanel',
          type: 'string',
        },
        {
          name: 'Home Organization',
          id: 'homeOrganizationId',
          type: 'organization',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
      thumbnailFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'Blocked',
          id: 'blocked',
          type: 'boolean',
        },
        {
          name: 'Tags',
          id: 'tags',
          type: 'tags',
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
    };
  },
  watch: {
    organizationId() {
      this.loadPaginatedData(0);
      // setting current user org because it will be needed for access check in userEdit view
      this.$store.dispatch('setUserCurrentOrg', this.organizationId);
    }
  },
  methods: {
    async customEditPermissionFunction() {
      console.log('checking custom edit...')
      return this.$store.getters.isSuperAdmin || await this.$store.dispatch('checkOrgAccess', {
        op: 'writeOrganization',
        organizationId: this.organizationId,
        opOnly: false
      })
    },
    loadPaginatedData(offset) {
      this.offset = offset;
      this.loadData();
    },
    loadData: function () {
      this.loading = true;
      let loadingMethod = 'loadUsers';
      let args = {
        listName: this.listName,
      }
      const paginationAddOn = {
        paging: {
          [this.listName]: true,
        },
        offset: {
          [this.listName]: this.offset,
        },
        limit: {
          [this.listName]: this.paginationLimit,
        },
        sort: {
          [this.listName]: this.sorting,
        }
      };
      args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add} : paginationAddOn;
      if (this.filter && this.filter.remove) {
        args.remove = {...this.filter.remove};
      }
      /*if (this.$store.getters.isSuperAdmin) {
        loadingMethod = 'loadUsers';
        if (!args.remove) {
          args.remove = {}
        }
        args.remove.include = [this.listName]
      } else {*/
        //args.add.id = {[this.listName]: this.organizationId};
      if(!this.showAllUsers) {
        const orgF = {
          [this.listName + 'specialOrgFilter']: 'homeOrganizationId eq ' + this.organizationId
        };
        args.add.filter = args.add.filter ? {...args.add.filter, ...orgF[this.listName + 'specialOrgFilter']} : orgF;
      } else {
        if (!args.remove) {
          args.remove = {}
        }
        args.remove.filter = [this.listName + 'specialOrgFilter']
      }

        /*args.add.include = {
          [this.listName]: ['users'],
        }*/
     // }
      if (this.organizationId || this.$store.getters.isSuperAdmin) {
        this.$store.dispatch(loadingMethod, args).then(() => {
          this.loading = false;
        })
      }
    },
    deleteRow: function (id) {
      this.$store.dispatch('deleteUser', {
        id: id
      }).then(() => {
        this.loadData();
      })
    },
    filtering: function (args) {
      this.filter = args;
      this.loadData();
    },
    sort(args) {
      const {fieldname, direction} = args;
      const sortString = direction + fieldname;
      if (this.sorting !== sortString) {
        this.sorting = sortString;
      }
      this.loadData(false);
    }
  }
}
</script>