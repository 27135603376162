<template>
  <div :class="['devops-container-container mb-3', isActive ? 'col' : 'devops-container-closed']">
    <div :class="['devops-container position-relative pl-1 pr-1 pb-3', isInbox ? 'inbox' : 'non-inbox']">
        <!--<div class="lighter">{{container['CreatedDate']}}</div>-->
        <div class="header">
        <div @click="loadItems" class="reload"><icon type="redo" /></div>
         <div class="opener-closer" @click="isActive = !isActive"><icon type="robot" /></div>
          <div class="lighter">{{ container.id }}</div>
          <h2>{{ container.Title }}</h2>
        </div>
        <loading-spinner class="mt-3" v-if="loading" />
        <ticket-over-view
          @selectTicket="setSelectedTicket"
          v-else-if="isActive"
          :tickets="filteredItems" />
    </div>
  </div>
</template>

<script>
import TicketOverView from "@/components/ticketing/TicketOverView";
import Icon from "@/components/Icon";
import loadingSpinner from "@/components/LoadingSpinner";
export default {
  name: "containerOverView",
  components: {
    TicketOverView,
    Icon,
    loadingSpinner
  },
  props: {
   projectId: {type: String, required: true},
   teamId: {type: String, required: true},
   container: {type: Object, required: true},
   reloadKey: {type: Number, default: 0},
   filterString: {type: String, default: ""}
  },
  data() {
    return {
      items: [],
      filteredItems: [],
      isActive: true,
      loading: false
    };
  },
  computed: {
    isInbox() {
      return this.container.Title === 'Inbox'
    }
  },
  watch: {
    reloadKey() {
      this.loadItems();
    },
    filterString() {
      if(!this.filterString) {
        this.filteredItems = this.items;
      }
      this.filteredItems = this.items.filter(item => {
        return item.Title.toLowerCase().includes(this.filterString);
      })
    }
  },
  beforeMount() {
    this.loadItems();
  },
  methods: {
    setSelectedTicket(id) {
      const selTicket = this.items.filter(item => {return item.id === id});
      this.$emit('setSelectedTicket', selTicket[0] ? selTicket[0] : null);
    },
    loadItems() {
      this.loading = true;
      this.$store.dispatch('clientLoadTicketingItems', {
        projectId: this.projectId,
        teamId: this.teamId,
        containerId: this.container.id
      })
      .then(items => {
        this.items = items.reverse();
        this.filteredItems = this.items;
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container-row {
   background-color: #343536;
   position:relative;
}
.container-category {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4e4f50;
}
.devops-container-container {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-right: 15px;
  padding-top: 80px;
}
.devops-container {
  background-color: #ddd;
  border-radius: 4px;
  position:relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding-top: 80px;
  .header {
    position:absolute;
    top:0;
    left: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    height: 80px;
    padding: 15px;
    color: #000;
    background-color: #bbb;
  }
}
.inbox .header {
  background-color: var(--ccdefault-ticketing-container-header-color);
  color: #fff;
}
.devops-container-closed {
  display: flex;
  flex-direction: column;
  width: 110px;
}

.opener-closer, .reload {
  position:absolute;
  top: -25px;
  left:0;
  background-color: var(--ccdefault-ticketing-container-header-color);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor:pointer;
   -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid var(--ccdefault-ticketing-container-header-color-hover);
  &:hover {
    background-color: var(--ccdefault-ticketing-container-header-color-hover);
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #fff;
  }
}
.reload {
  top: 0;
  right:0;
  left: auto;
}
</style>