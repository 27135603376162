import Vue from 'vue'
import $router from '@/router'
import _ from 'lodash';
const state = {
    hideFilter: false,
    hideNav: false,
    pages: {
        /*routeName: {
            viewSwitcher: 'table',
            pagination: 5
         },
        */
    },
    routes: [],
    logo: "",
    logoFileName: "",
    logoObjectUrl: "",
    colors: {
        mainColor: ""
    },
    appHost: "",
    appOrganization: "",
    appId: ""
};

const getters = {
    getPageAppOrganization() {
      return state.appOrganization;
    },
    getAppId() {
        return state.appId;
    },
    getAppHost() {
        return state.appHost
    },
    getLogo() {
        return state.logo;
    },
    getLogoFileName() {
        return state.logoFileName;
    },
    getLogoObjectUrl() {
        return state.logoObjectUrl;
    },
    getColors() {
        return state.colors;
    },
    hideNav() {
        return state.hideNav;
    },
    getFilterStatus() {
        return state.hideFilter;
    },
    getViewSwitcher: (state) => (routeName, id) => {
        if(state.pages[routeName] && state.pages[routeName].viewSwitcher && state.pages[routeName].viewSwitcher[id]) {
            let res = Object.keys(state.pages[routeName].viewSwitcher[id])[0];
            return res && res !== 0 && res !== '0' ? res : 'table';
        }
        else {
            return 'table';
        }
    },

    getPagination: (state) => (routeName, id) => {
        if(state.pages[routeName] && state.pages[routeName].pagination && state.pages[routeName].pagination[id]) {
            return parseInt(Object.keys(state.pages[routeName].pagination[id])[0]);
        }
        else {
            return 1;
        }
    },
    getRoutes() {
        return state.routes;
    },
};

const mutations = {
    setAppHost(state, host) {
        state.appHost = host;
    },
    setAppOrganization(state, orgId) {
        state.appOrganization = orgId;
    },
    setAppId(state, appId) {
        state.appId = appId;
    },
    setPageLogo(state, logo) {
        state.logo = logo;
        //state.logoObjectUrl = logo;
    },
    setPageLogoFileName(state, fileName) {
        state.logoFileName = fileName;
    },
    setPageLogoObjectUrl(state, logoUrl) {
        state.logoObjectUrl = logoUrl;
    },
    setNavHideStatusMutation(state, status) {
        state.hideNav = status;
    },
    setFilterMutation (state, status) {
        state.hideFilter = status;
        // eslint-disable-next-line no-undef
        $cookies.set('hideFilter', status);
    },
    setViewSwitcherMutation(state, args) {
        if(!state.pages[args.routeName]) {
            Vue.set(state.pages, args.routeName, {});
        }
        if(!state.pages[args.routeName][args.id]) {
            Vue.set(state.pages[args.routeName], 'viewSwitcher', {});
        }
        let viewType = args.viewType && args.viewType !== 0 ? args.viewType : 'table';
        Vue.set(state.pages[args.routeName].viewSwitcher, args.id, {});
        Vue.set(state.pages[args.routeName].viewSwitcher[args.id], viewType, viewType);
        window.localStorage.setItem(args.routeName + 'ViewSwitcher' + args.id, viewType);
    },
    setPaginationMutation(state, args) {
        if(!state.pages[args.routeName]) {
            Vue.set(state.pages, args.routeName, {});
        }
        if(!state.pages[args.routeName][args.id]) {
            Vue.set(state.pages[args.routeName], 'pagination', {});
        }
        let pagination = args.pagination ? args.pagination : 0;
        Vue.set(state.pages[args.routeName].pagination, args.id, {});
        Vue.set(state.pages[args.routeName].pagination[args.id], pagination, pagination);
        window.localStorage.setItem(args.routeName + 'Pagination' + args.id, pagination);
    },
    setRoutesMutation(state, routes) {
        state.routes = routes;
    }
};

const actions = {
    setAppHostAction: ({commit}, host) => {
        commit('setAppHost', host);
    },
    setAppOrganizationAction: ({commit}, appOrgId) => {
        commit('setAppOrganization', appOrgId);
    },
    setAppIdAction: ({commit}, appOrgId) => {
        commit('setAppId', appOrgId);
    },
    setLogo: ({commit}, logo) => {
        commit('setPageLogo', logo);
    },
    setLogoFileName: ({commit}, logo) => {
        commit('setPageLogoFileName', logo);
    },
    setPageLogoObjectUrlAction: ({commit}, logoUrl) => {
        commit('setPageLogoObjectUrl' ,logoUrl);
    },
    setNavHideStatus: ({commit}, status) => {
        commit('setNavHideStatusMutation', status);
    },
    checkFilterSettings: function({commit}) {
        // eslint-disable-next-line no-undef
        const hideFilter = $cookies.get('hideFilter');
        commit('setFilterMutation', hideFilter === 'true');
    },
    setFilter: ({commit}, status) => {
        commit('setFilterMutation', status);
    },
    //routeName, id, viewType
    checkPageViewSwitcher: function({commit}, args) {
      let options = window.localStorage.getItem(args.routeName + 'ViewSwitcher' + args.id);
      let viewType = 'table';
      if(options && options.length > 0) {
          viewType = options;
      }
      let args2 = {
          routeName: args.routeName,
          viewType: viewType,
          id: args.id,
      };
      commit('setViewSwitcherMutation', args2);
    },
    checkPagePagination: function({commit}, args) {
        // eslint-disable-next-line no-undef
        let options = window.localStorage.getItem(args.routeName + 'Pagination' + args.id);
        let pagination = 0;
        if(options && options.length > 0) {
            pagination = options;
        }
        let args2 = {
            routeName: args.routeName,
            pagination: pagination,
            id: args.id,
        };

        commit('setPaginationMutation', args2);
    },
    setViewSwitcher: ({commit}, args) => {
        commit('setViewSwitcherMutation', args);
    },
    setPagination: ({commit}, args) => {
        commit('setPaginationMutation', args);
    },
    loadAndSetRoutingPermissions: async ({commit, dispatch}) => {
        let routes = _.cloneDeep( $router.options.routes );
        for(let i = 0; i < routes.length; i++) {
            routes[i].meta.access = true;
             if(routes[i].meta.accessPermission && routes[i].meta.staticPermission) {
                routes[i].meta.access = await dispatch('checkTeamOrgPermission', {op: routes[i].meta.accessPermission});
             } else {
                 routes[i].meta.access = 'undefined';
             }
        }
        commit('setRoutesMutation', routes);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}