<template>
  <one-column-sidebar-left
    tab-selector="qrcodetab"
    type="qrcode"
    back-button-tab-selector="qrcodebackbutton"
    :id="$route.params.id"
    sub-menu-selector="librarypanel"
  >
    <info-panel
      slot="sidebarLeft"
      :id="$route.params.id"
      :image-editable="false"
      upload-type="Asset"
      :created="$store.getters.getQrcodeCreationDate($route.params.id)"
      :last-change="$store.getters.getQrcodeChangeDate($route.params.id)"
      :downloadable="true"
      :organization="$store.getters.getQrcodeOrganization($route.params.id)"
      preview-id="qrCodeEdit"
      :removable="true"
    />
    <team-attacher
      slot="sidebarLeft"
      class="mt-3"
      :id="$route.params.id"
      :teams="teams"
      store-name="Asset"
      v-if="$store.getters.getQrcodeOrganization($route.params.id)"
      :limit-by-organization-id="$store.getters.getQrcodeOrganization($route.params.id)"
      @added="loadAssetTeams"
      @removed="loadAssetTeams"
    />
    <info-panel
      slot="sidebarLeft"
      :id="$route.params.id"
      :tags="$store.getters.getQrcodeTags($route.params.id)"
      :taggable="true"
      preview-id="qrcodeEdit"
      @removeTag="removeTag"
      @addTag="addTag"
      :key="'infopanel2'"
      :limit-tags-to-organization="$store.getters.getQrcodeOrganization($route.params.id)"
    />
    <div slot="mainContent">
      <content-title-block
        v-if="$store.getters.getQrcodeName($route.params.id)"
        :value="$store.getters.getQrcodeName($route.params.id)"
        :id="$route.params.id"
        store-name="Qrcode"
        :organization-id="$store.getters.getQrcodeOrganization($route.params.id)"
      />
      <content-description-block
        :value="$store.getters.getQrcodeDescription($route.params.id)"
        :id="$route.params.id"
        store-name="Qrcode"
      />
      <div class="inline-edit-label">
        <b>{{ $t("qrcode") }}</b>
      </div>
      <div class="qrcode-container mt-2">
        <qrcode-view
          class="table-thumbnail"
          source="asset"
         :id="$route.params.id"
          :file-name="$store.getters.getQrcodeName($route.params.id)"
        />
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import QrcodeView from "@/components/widgets/QrcodeView";

export default {
  name: "QrCodeEdit",
  components: {
    InfoPanel,
    OneColumnSidebarLeft,
    ContentTitleBlock,
    ContentDescriptionBlock,
    TeamAttacher,
    QrcodeView,
  },
  data() {
    return {
      id: "",
      teams: [],
    };
  },
  beforeMount() {
    this.loadQrCode();
    this.loadAssetTeams();
  },
  methods: {
    loadQrCode() {
      this.$store
        .dispatch("loadQrcode", {
          id: this.$route.params.id,
          include: "metaSets,tags,teams"
        })
        .catch((e) => {
          if (e.status === 401) {
            this.$router.push("/access-denied");
          } else if(e.status === 404) {
            this.$router.push('/not-found')
          }
        });
    },
    loadAssetTeams() {
      this.$store
        .dispatch("clientLoadAssetTeams", {
          id: this.$route.params.id,
        })
        .then((data) => {
          this.teams = data;
        });
    },
    removeTag: function (tag) {
      this.$store.dispatch("removeQrcodeTag", {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch("addQrcodeTag", {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .qrcode-container
  {
    max-width: 250px;
  }
</style>