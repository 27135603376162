<template>
    <div class="container-fluid footer pt-3 pb-3">
        <div class="row">
            <div class="col-12 col-md-4">
                <h3>{{ $t('Info') }}</h3>
                <b class="uppercase">{{ $t('CC-Version') }}:</b> <span class="lighter">{{ this.$store.getters.appVersion }}</span><br /><br />
              <a :href="footerLinks.DOCUMENTATION_LINK" target="_blank">{{ $t('Documentation') }} <icon size="0.9" type="external-link-alt" /></a><br />
              <router-link to="/gdpr" target="_blank">{{ $t('gdpr_link') }} <icon size="0.9" type="external-link-alt" /></router-link>
            </div>
          <div class="col-md-4"></div>
          <div class="col-12 col-md-4 justify-content-end">
            <download-section class="d-inline-block float-right" />
          </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/Icon";
    import {footerLinks} from "@/enum";
    import DownloadSection from "./DownloadSection";
    export default {
        name: "PageFooter",
        components: {
          Icon,
          DownloadSection
      },
      data() {
        return {
          footerLinks: footerLinks
        }
      },
    }
</script>