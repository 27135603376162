<template>
  <two-column-layout
      tab-selector="texturetab"
      type="texture"
      back-button-tab-selector="texturebackbutton"
      :id="$route.params.id"
      sub-menu-selector="librarypanel"
  >
    <div slot="buttonsLeft"/>

    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :upload-type="'Asset'"
        :created="$store.getters.getTextureCreationDate(id)"
        :last-change="$store.getters.getTextureChangeDate(id)"
        :downloadable="true"
        preview-id="textureEdit"
        :organization="$store.getters.getTextureOrganization($route.params.id)"
    />
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getTextureTags($route.params.id)"
        :taggable="true"
        preview-id="textureEdit"
        @removeTag="removeTag"
        @addTag="addTag"
        :key="'infopanel2'"
        :limit-tags-to-organization="$store.getters.getTextureOrganization($route.params.id)"
    />
    <team-attacher
        v-if="$store.getters.getTextureOrganization($route.params.id)"
        class="mt-3"
        slot="sidebarLeft"
        store-name="Asset"
        :id="$route.params.id"
        :teams="teams"
        :limit-by-organization-id="$store.getters.getTextureOrganization($route.params.id)"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />

        <div slot="mainContent">
          <content-title-block
                  v-if="$store.getters.getTextureName(id)"
            :value="$store.getters.getTextureName(id)"
            :id="$route.params.id"
                  store-name="Texture"
                  :organization-id="$store.getters.getTextureOrganization($route.params.id)"

          />
          <content-description-block
                  :value="$store.getters.getTextureDescription(id)"
                  :id="$route.params.id"
                  store-name="Texture"
          />
          <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
            <state-setter
                class="col-12 col-md-4 mb-2 mt-2"
                :target-id="$route.params.id"
                store-name="Texture"
                :given-state="$store.getters.getTextureState($route.params.id)"
            />
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="mt-5">{{ $t('textureEditFile') }}</h2>
              <p>{{ $t('uploadDisclaimer') }}</p>
              <div class="row">
                <upload-drop-zone
                    class="col-12 col-md-8 mb-5 mt-4"
                    :asset-id="$route.params.id"
                    :preview-uri="$store.getters.getTexturePreviewUri($route.params.id)"
                    target-type="Asset"
                    form-id="uploadTexture"
                    :downloadable="true"
                    :removable="true"
                    :make-chunks="true"
                />
              </div>
            </div>
            <div class="w-100"/>
            <div class="col-12 col-md-6 no-gutters">
              <info-panel
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Asset"
                    @added="loadTexture"
                    @removed="loadTexture"
                    :given-meta-sets="$store.getters.getTextureMetaSets(id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-6"
                :key="item.id"
                v-for="(item) in $store.getters.getTextureMetaSets(id)"
                :title="item.description"
                :fields="item.metaFields"
                icon-left="folder"
                store-name="Texture"
                :organization-ids="[$store.getters.getTextureOrganization(id)]"
                @updated="loadTexture"
            />
          </div>
    </div>
  </two-column-layout>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import UploadDropZone from "../components/files/UploadDropZone";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import StateSetter from "@/components/StateSetter";
export default {
  name: 'TextureEdit',
  components: {
        MetaPanel,
        InfoPanel,
        TwoColumnLayout,
        ContentTitleBlock,
        ContentDescriptionBlock,
        UploadDropZone,
        MetaSetAttacher,
        TeamAttacher,
        StateSetter
  },
        data() {
            return {
                id: '',
                teams: [],
            };
        },
        beforeMount() {
            this.loadTexture();
            this.id = this.$route.params.id;
            this.loadAssetTeams();
        },
  methods: {
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    loadTexture() {
      this.$store.dispatch('loadTexture', {
        id: this.$route.params.id,
        include: 'metaSets,tags'
      }).catch(e => {
        if(e.status === 401) {
          this.$router.push('/access-denied');
        } else if(e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function(tag) {
      this.$store.dispatch('removeTextureTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function(tag) {
      this.$store.dispatch('addTextureTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
  },
}
</script>