<template>
    <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      back-button-tab-selector="userbackbutton"
      tab-selector="usertab"
    >
        <div slot="buttonsRight">
            <Button
                    type="button"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>
        <div slot="mainContent">
            <add-user
            ref="addUserForm"
            @enableSaving="doEnableSaving"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import AddUser from "@/components/forms/UserAdd";
    export default {
        name: "UserAdd",
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
            AddUser,
        },
        data() {
            return {
                enableSaving: false,
            };
        },
        methods: {
            doEnableSaving: function(bool){
                this.enableSaving = bool;
            },
            save: function() {
                this.$refs.addUserForm.save();
            },
        }
    }
</script>