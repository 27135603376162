<template>
    <div>
        <div v-if="datasets[0].value && $store.getters.getDatasetRows(datasets[0].value + 'chart') && $store.getters.getDatasetSchema(datasets[0].value)">
            <Button
                    type="button"
                    :class="['mb-3', pdfPrintingActive ? 'highlight' : '']"
                    @click="makePDFFromCanvases('statistics')"
            >
                <icon v-if="!pdfPrintingActive" type="file-pdf"/> <icon v-else type="file-download"/>
                <span v-if="!pdfPrintingActive">{{ $t('ExportAsPDF') }}</span>
                <span v-else>{{ $t('justWaitAMinute') }}</span>
            </Button>
    <info-panel
            :info-panel-header="getChartConfig(chart).name ? getChartConfig(chart).name : 'statistics ' + index"
            :collapsible="true"
            preview-id="'chartInfoPanel'"
            v-for="(chart, index) in Object.keys(localConfig)"
            :key="index + forceReRenderKey"
    >
        <div class="float-right mt-3" slot="info">
            <router-link target="_blank" :to="'/library/datasets/' + datasets[0].value + '/general'">{{ $t('linkToOriginalTable') }} <icon size="0.9" type="angle-right" /></router-link>
        </div>
            <chart
                    :ref="'chartCanvas' + index"
                    slot="info"
                    :unique-identifier="'chartCanvas' + index"
                    :fields="$store.getters.getDatasetSchema(datasets[0].value)"
                    :data="dataToArray($store.getters.getDatasetRows(datasets[0].value + 'chart'))"
                    :config="getChartConfig(chart)"
                    :show-config="true"
                    :show-save-button="true"
                    :show-delete-button="index !== 0"
                    @saveConfig="saveConfig"
                    @removeConfig="removeConfig"
                    :chart-id="chart"
                    :key="forceReRenderKey"
            />
    </info-panel>
        </div>
    </div>
</template>

<script>
    import InfoPanel from "../../InfoPanel";
    import Chart from "./Chart";
    import ArrayMixin from "../../../components/mixins/ArrayMixin.js";
    import Icon from "../../Icon";
    import Button from "../../../components/forms/Button";
    import CanvasToPDFMixin from "../../../components/mixins/CanvasToPDFMixin.js";

    export default {
        name: "MultiChart",
        components: {
            InfoPanel,
            Chart,
            Icon,
            Button,
        },
        mixins: [
            ArrayMixin,
            CanvasToPDFMixin
        ],
        props: {
            config: {type: String, default: ''},
            datasets: {type: Array, required: true},
        },
        data() {
            return {
                localConfig: '',
                forceReRenderKey: 0,
            };
        },
        watch: {
            config: function() {
                this.localConfig = JSON.parse(this.config);
            },
            datasets: function() {
                this.loadData();
                this.forceReRenderKey++;
            }
        },
        beforeMount() {
            this.localConfig = JSON.parse(this.config);
            this.loadData();
        },
        methods: {
            loadData() {
                for(let i = 0; i < this.datasets.length; i++) {
                    let value = this.datasets[i].value;
                    this.$store.dispatch('loadDataset', {
                        id: value,
                        listName: value
                    });
                    this.$store.dispatch('loadDatasetRows', {
                        keep: {
                            id: value,
                        },
                        add: {
                            limit: {
                                multiChart: 100000,
                            }
                        },
                        listName: value + 'chart'
                    });
                }
            },
            getChartConfig(key) {
                return this.localConfig && this.localConfig[key] ? this.localConfig[key] : {};
            },
            saveConfig(config, chartId) {
                this.$emit('saveConfig', config, chartId);
            },
            removeConfig(chartId) {
                this.$emit('removeConfig', chartId);
            },
        },

    }
</script>

<style scoped>

</style>