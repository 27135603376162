<template>
  <div class="position-relative w-100">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <div :style="{height: config.height + 'px' }" :class="['mt-2 mb-2 separator', editable ? 'edit-mode' : '']">
      <div class="separator-line"></div>
    </div>
  </div>
</template>

<script>
import EditorOverlay from "./EditorOverlay";
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
export default {
  name: "Separator",
  components: {EditorOverlay},
  mixins: [ElementMixin],
  mounted() {
    this.increaseCounter();
  }
}
</script>

<style lang="scss" scoped>
  .separator {
    width: 100%;
    min-height: 15px;
    position: relative;
    .separator-line {
      position: absolute;
      width: 100%;
      height: 2px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &.edit-mode {
      background-color: #e5e5f7;
      opacity: 0.8;
      background: repeating-linear-gradient( -45deg, var(--vform-editor-logo-color), var(--vform-editor-logo-color) 1px, #e5e5f7 1px, transparent 5px );
    }
  }
</style>