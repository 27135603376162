import Vue from 'vue'
//import {_} from 'vue-underscore';

const state = {
    /*
    * datasetRowLists contains all lists (e.g. if there is a box with the datasets per organization, you would find it
    * in here with the id of the organization as the listname
    * */
    datasetRowLists: {
        datasetRowList: {},
    },
    datasetRowListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '200',
        },
        filter: {
            //default: 'type in sensor, feed'
        }
    },
    queryParams: {
        datasetList: {
            limit: {
                default: '200',
            },
            filter: {
                //default: 'type in sensor, feed'
            }
        },
    },
    combinedQueryParams: {},
    temp: {}
};
const getters = {
    getDatasetRows: (state) => (listName = 'datasetRowList') => {
        return state.datasetRowLists[listName] ? state.datasetRowLists[listName] : {};
    },
    getDatasetRowOriginalQueryParams: function() {
        return state.queryParams;
    },
    getDatasetRowQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getDatasetRowListOptions: (state) => (listName, option = '', option2 = '', option3 = '') => {
        let optionList = 'datasetRowListOptions';
        if(option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].previewPath ? Object.keys(state[optionList][listName].previewPath)[0] : '';
        }
        else if(option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        else if(option === 'min' && option2) {
            if(option3 && option3 === 'date') {
                return state[optionList][listName] && state[optionList][listName][option2] ? state[optionList][listName][option2].min : null;
            }
            return state[optionList][listName] && state[optionList][listName][option2] ? parseInt(state[optionList][listName][option2].min) : null;
        }
        else if(option === 'max' && option2) {
            if(option3 && option3 === 'date') {
                return state[optionList][listName] && state[optionList][listName][option2] ? state[optionList][listName][option2].max : null;
            }
            return state[optionList][listName] && state[optionList][listName][option2] ? parseInt(state[optionList][listName][option2].max) : null;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};
const mutations = {
    loadDatasetRowsMutation(state, data) {
        let listName = 'datasetRowList';
        if(data.listName) {
            listName = data.listName;
            delete data.listName;
        }
        Vue.set(state.datasetRowLists, listName, {});

        let arrayLength = data.data.length;
        for (let i = 0; i < arrayLength; i++) {
            let obj = data.data[i];
            Vue.set(state.datasetRowLists[listName], obj.id, obj);
        }
    },
    deleteDatasetRowMutation(state, data) {
        let listName = data.listName ? data.listName : 'datasetRowList';
        Vue.delete(state.datasetRowLists[listName], data.id);
    },
    updateDatasetRowMutation(state, data) {
        let listName = data.listName ? data.listName : 'datasetRowList';
        Vue.set(state.datasetRowLists[listName], data.datarowId, data.dataRow);
    },
    updateDatasetRowsMutation(state, data) {
        let listName = 'datasetRowList';
        if(data.listName) {
            listName = data.listName;
            delete data.listName;
        }
        //Vue.set(state.datasetRowLists, listName, {});

        let arrayLength = data.data.length;
        for (var i = 0; i < arrayLength; i++) {
            let obj = data.data[i];
            //Do something
            Vue.set(state.datasetRowLists[listName], obj.id, obj);
        }
    },
    updateDatasetRowListOptionMutation(state, args) {
        let optionList = 'datasetRowListOptions';
        //reset all data:
        if (!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if (args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if (args.optionKey === 'selected' || args.optionKey === 'preview') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if (!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if (state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        } else {
            if(typeof(args.value) === 'object') {
                let keys = Object.keys(args.value);
                for(let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    Vue.set(state[optionList][args.listName][ args.optionKey], key, args.value[key]);
                }
            }
            else {
                Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
            }
        }
    },

};
const actions = {
    registerDatasetRowsQueryParams: ({commit}, args) => {
        let listName = args.listName ? args.listName : 'datasetRowList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'datasetrows', listName: listName, params: args});
        }
    },
    loadDatasetRowsFiltered: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'datasetRowList';

        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'datasetrows', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'datasetrows', listName: listName});
        dispatch('clientLoadDatasetRows',  getters.getDatasetRowQueryParams(listName)).then(data => {
            args.data = data;
            commit('loadDatasetRowsMutation', args);
            if(getters.getDatasetRowQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateDatasetRowListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
        });
    },
    loadAggregatedDatasetRows: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'datasetRowList';
        //let params = getters.getDatasetRowQueryParams(listName);
        if(args.fieldFilter) {
            args.dataFilter = JSON.stringify({fieldFilter: args.fieldFilter})
        }
        dispatch('clientLoadAggregatedDatasetRows', args).then(data => {
            let result = data;
            commit('updateDatasetRowListOptionMutation', {optionKey: args.fieldName, value: {min: result.min, max: result.max}, listName: listName, override: true});
            return result;
        });
    },
    loadDatasetRows: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'datasetRowList';

        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'datasetrows', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'datasetrows', listName: listName});
        return dispatch('clientLoadDatasetRows',  getters.getDatasetRowQueryParams(listName)).then(data => {

            args.data = data;
            commit('loadDatasetRowsMutation', args);
            if(getters.getDatasetRowQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateDatasetRowListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });

    },

    createDatasetRow: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateDatasetRow', args).then(data => {
            let listName = args.listName ? args.listName : 'datasetRowList';
            commit('updateDatasetRowMutation', {listName: listName,dataRowId: data.id, dataRow: data });
            //commit('updateDatasetRowsMutation', {id: id, data: [data], listName: listName});
            return data;
        });
    },

    /*
    * required: args.id (dataset-id), args.datarowId (datarow-id)
    * **/
    updateDatasetRow: async ({commit, dispatch}, args) => {
        let id = args.id;
        return dispatch('clientUpdateDatasetRow', args).then(data => {
            let listName = args.listName ? args.listName : 'datasetRowList';

            commit('updateDatasetRowsMutation', {id: id, data: [data], listName: listName});
        });
    },

    deleteDatasetRow: async ({commit, dispatch}, args) => {
        return dispatch('clientDeleteDatasetRow', args).then(() => {
            let listName = args.listName ? args.listName : 'datasetRowList';
            commit('deleteDatasetRowMutation', {id: args.args[0], listName: listName});
        });
    },



};
export default{
    state,getters,mutations,actions
}
