<template>
    <main-table-layout
        sub-menu-selector="organizingpanel"
    >
        <div slot="buttonsRight"/>
        <content-filter slot="filter"
                       @filter="filtering"
                        :id="listName"
                        store-name="MetaSet"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="false"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getMetaSetListOptions(listName, 'pagination_items')"
                :id="listName"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createMetaSet"
            type="action-button" slot="mainContentButtons" link="/organizing/metaSets/add" icon="plus">
            {{ $t('createnew') }}
        </Button>
      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'metaSetDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="writeMetaSet"
                delete-permission="deleteMetaSet"
                :fields="tableFields"
                :data="$store.getters.getMetaSets"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'sfxdataDefaultThumbnails'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="writeMetaSet"
                delete-permission="deleteMetaSet"
                :fields="thumbnailFields"
                :data="$store.getters.getMetaSets"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
        />
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";

    import Pagination from "../components/Pagination";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

    export default {
        name: "SFXData",
      mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
              registerMethod: 'registerMetaSetsQueryParams',
              loadMethod: 'loadMetaSets',
              include: 'teams',
              deleteMethod: 'deleteMetaSet',
                listName: 'metaSetList',

                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        translate: true,
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
        methods: {
            deleteRow: function (id) {
                this.$store.dispatch('deleteMetaSet', {
                    args: [id]
                });
            },
        }
    }
</script>
