<template>
    <div :class="['tag mb-1', viewStyle === 'table' ? 'd-flex w-100 long-tag' : '']" :style="'background-color: ' + team.color ">
        <div :class="['', viewStyle === 'table' ? 'col-3 p-2 mr-5' : 'team-row pl-2 pr-2 mb-3']">
            <icon :type="iconLeft" size="1.1" class="float-left" />
            <div class="ml-4">
              <b>{{ team.displayName ? team.displayName : team.name }} <span class="lighter" v-if="Number(team.visibility) === 0"> (private)</span></b><br />
              <p v-if="organizationId" class="lighter smaller">{{ $store.getters.getOrganizationDisplayName(organizationId) }}</p>
              <div class="mt-3 mr-1 lighter">{{ $t('Role') }}:</div>
              <div class="w-100" v-if="isEditable && !lock"></div>
              <select class="custom-select" v-if="isEditable && !lock" @change="update('role')" v-model="role">
                <option :key="role" :value="role" v-for="(role, value) in possibleRoles">{{ value }}</option>
              </select>
              <span v-else>{{ role }}</span>
            </div>
        </div>
        <div v-if="isRemovable && !isLocalDefault" class="icon-right" @click="$emit('removeteam', team)"><icon type="timesCircle" size="1" /></div>
        <div :class="['', viewStyle === 'table' ? 'align-self-end col-3 p-2' : 'mt-4']">
          <div v-if="isEditable && !lock">
                <div class="lighter">{{ $t('forms.default') }}<span v-if="organizationId"> {{ $t('for') }} {{ $store.getters.getOrganizationDisplayName(organizationId) }}</span>:</div>
                <div class="custom-checkbox" @click="() => {this.default = !this.default; update('default')}">
                    <div :class="['custom-checkbox', isLocalDefault ? 'active' : '' ]" ref="checkbox" type="checkbox"
                    >
                    <div class="inner"><icon type="check"/></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2" v-if="showDetails && isDefault">
            <b>{{ $t('forms.default') }}</b>
        </div>
    </div>
</template>

<script>
    import Icon from "../Icon"
    import {UserRoles} from '../../enum';

    export default {
        name: "TeamTag",
        components: {
            Icon,
        },
        props: {
            team : { type: Object, required: true},
            isRemovable : { type: Boolean, default: true},
            iconLeft: {type: String, default: "faHome"},
            isEditable : { type: Boolean, default: false},
            showDetails : { type: Boolean, default: true},
            isDefault: { type: Boolean, default: false},
            viewStyle: {type: String, default: "thumbnails"},
            showAppPanel: {type: Boolean, default: true},
            acceptNoneAsRole: {type: Boolean, default: true},
            organizationId: {type: String, default: ''},
        },
        data() {
            if(this.showDetails) {
                return {
                    role:  '',
                    possibleRoles: {},
                    lock: false,
                    appPanel: '',
                    appPanelEditing: false,
                    isLocalDefault: false,
                };
            }
            return {
                lock: false,
                appPanel: '',
                appPanelEditing: false,
                isLocalDefault: false,
            };
        },
        beforeMount() {
            this.possibleRoles = JSON.parse(JSON.stringify(UserRoles));
            delete this.possibleRoles['None'];
            this.role = this.team.members && this.team.members[0] && this.team.members[0].relation ? this.team.members[0].relation.role : this.team.role;
            this.isLocalDefault = this.team.members && this.team.members[0] && this.team.members[0].relation.default ? this.team.members[0].relation.default : false;
        },
        methods: {
            update(field) {
                let member = {};
                member[field] = this[field];
                if(field === 'default') {
                    this.$emit('setDefaultItem', this.team.id);
                }
                else {
                    this.$emit('update', this.team.id, member);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .tag {
        background-color: $tag-background-color;
        display: inline-block;
        padding: 3px 35px 3px 8px;
        margin-right:8px;
        position:relative;
        cursor:pointer;
        word-break: break-all;
        .icon-right {
            position:absolute;
            right:10px;
            top: 15px;
            opacity:0.8;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            cursor:pointer;
            &:hover {
                opacity: 1;
            }
        }
        .tag-details {
            background-color: darken($tag-background-color, 10%);
            padding:3px;
            width: 100%;
            margin-top:6px;
        }
    }
    .team-row {
        width: 100%;
        background: rgba(0,0,0,0.3);
        padding: 1px;
    }
    .long-tag {
      margin-right:0;
    }
</style>