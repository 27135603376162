<template>
<div></div>
</template>

<script>
import vFormAndProjectMixin from "@/components/vForm/vFormAndProjectMixin.js.vue";
import {vFormButtonActionModes, vFormControls, vformTargetTypes} from "@/enum";
import ElementMethodMixinJs from "@/components/vForm/mixins/ElementMethodMixin.js.vue";
export default {
  name: "FormTreeTraverserMixin.js",
  mixins: [vFormAndProjectMixin, ElementMethodMixinJs],
  data() {
    return {
      targetStack: [], // lifo
      visitedStack: [],
      connections: [],
      vFormControls
    };
  },
  methods: {
    traverseTree(vform, slides, addConnectionFunction = null, addSlideConnectionFunction = null) {
      // in case this method is called multiple times, reset everything
      this.targetStack = [];
      this.visitedStack = [];
      this.connections = [];
      this.getAllConnectionsForSlide(vform, slides, vform.steps, slides[0].id, addConnectionFunction);
      if(addSlideConnectionFunction) {
        addSlideConnectionFunction(this.connections);
      }
      return this.connections;
    },
    getAllConnectionsForSlide(vform, slides, steps, slideId, addConnectionFunction = null) {
      console.log('visiting slide ' + slideId)
      const step = this.getStepForSlide(slides, vform.steps, slideId, slideId);

      // connection between two nodes
      if(step) {
        console.log('checking step for this slide')
        let connectionItems = this.getConnectionItems(step, slideId, vform.global, slides, steps);
        connectionItems = connectionItems.filter(item => {return !!item })
        console.log('connectionItems found: ')
        console.log(connectionItems)
        // this makes all targets being slides
        for(let i = 0; i < connectionItems.length; i++) {
          let targetSlide = null;
          /// todo: move this mapping to getConnectionItems
          console.log('conn item:')
          console.log(connectionItems[i]);
          const {target, targetId, label, elementId} = connectionItems[i];
          if(target === vformTargetTypes.SLIDE) {
            targetSlide = targetId;
          } else if(target === vformTargetTypes.STEP) {
            const targetStep = this.getStepById(targetId, steps);
            if(!targetStep) {
              continue;
            }
            targetSlide = targetStep.linkedSlide;
          } else if(target === vformTargetTypes.PROJECT) {
            console.log('project not covered yet')
          } else if(target === vformTargetTypes.NO_TARGET) {
            targetSlide = null;
          }
          if(addConnectionFunction) {
            addConnectionFunction(slideId, targetSlide, label, elementId, step.uuid);
          }
          if(targetSlide) {
            console.log('checking transition 1 from ' + slideId + ' to ' + targetSlide)
            const exists = this.checkIfConnectionExists(slideId, targetSlide);
            console.log('it exists: ' + exists)
            if(!exists) {
              console.log('checking transition returned false, creating connection')
              this.connections.push({
                source: slideId,
                target: targetSlide
              })
            }
            if(!this.targetStack.includes(targetSlide) && !this.visitedStack.includes(targetSlide)) {
              this.targetStack.push(targetSlide);
            }
          }
        }
      }

      if(!this.visitedStack.includes(slideId)) {
        this.visitedStack.push(slideId)
      }
      if(this.targetStack.length) {
        const poppedSlide = this.targetStack.pop();
        this.visitedStack.push(poppedSlide);
        this.getAllConnectionsForSlide(vform, slides, steps, poppedSlide, addConnectionFunction)
      }
    },
    /**
     * if allowOnlyOneWay is set to true, it will check both ways and only return true if neither a -> b nor b -> a is available
     * */
    checkIfConnectionExists(sourceId, targetId, checkBothWays = true) {
      console.log('checking transition: ' + sourceId + ' to ' + targetId)
      if(sourceId === targetId) {
        return true;
      } else if(!this.connections.length) {
        return false;
      } else {
        return this.connections.filter(item => {
          return (item.source === sourceId && item.target === targetId) || (checkBothWays && item.target === sourceId && item.source === targetId)
        }).length > 0;
      }
    },
    /**
     * Get all Panels for a certain step
     * */
    getStepPanels(step, globalPanels) {
      const globalStepPanels = [];
      for(let i = 0; i < globalPanels.length; i++) {
        const {globalVisibleOnSteps} = globalPanels[i];
        if (globalVisibleOnSteps) {
          if (globalVisibleOnSteps.find((x) => x === step.uuid)) {
            globalStepPanels.push(globalPanels[i]);
          }
        }
      }

      return [...step.panels, ...globalStepPanels];
    },
    /**
     * The following items can connect to another place – they will be mapped into standardized objects
     * - Button
     * - Login
     * - Logout
     * - QRCode
     * - Radiobuttons
     *
     * Their sources can be:
     * - vform.step.elements
     * - vform.globals.elements (IF a certain panel is visible on this step)
     *
     * They can either point to a source inside this project or to another project
     *
     * @returns
     * [{
     *   targetId: "targetSlideOrStep",
     *   target: "Slide" // can be step, slide, project or notarget
     *   label
     * }]
     *
     * */
    getConnectionItems(step, slideId, globals, slides, steps) {
      const connections = [];
      const panels = this.getStepPanels(step, globals.panels ? globals.panels : []);
      let elements = [];
      for(let i = 0; i < panels.length; i++) {
        const {uuid, hideBottomBar} = panels[i];
        let els = this.getElementsByPanel(uuid, globals, step, i === 0);

        // this excludes items added in the hidden bottom bar
        if(hideBottomBar) {
          els = els.filter(item => {
            return !item.bottomDropZone
          })
        }
        elements.push(...els);
      }
      if(elements.length) {
        elements = this.filterByTargetElements(elements);
        for(let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const targets = this.getTargetsForElement(element, slideId, slides, steps);
          if(targets && targets.length) {
            connections.push(...targets)
          } else {
            const label = this.getLabel(element, 'Unknown');
            connections.push({
              targetId: null,
              target: vformTargetTypes.NO_TARGET,
              label: label ? label : element.formElementTypeString
            })
          }
        }
      }
      return connections;
    },
    /**
     * Filter by elements which can trigger going to another step, slide or project
     * */
    filterByTargetElements(elements) {
      if(!elements.length) {
        return [];
      }
      return elements.filter(item => {
        return [
          vFormControls.BUTTON,
          vFormControls.LOGIN,
          vFormControls.LOGOUT,
          vFormControls.QR_CODE,
          vFormControls.RADIOBUTTONS
        ].includes(item.formElementTypeString);
      })
    },
    getTargetsForElement(item, slideId, slides, steps) {
      const {formElementTypeString} = item;
      let targets = [];
      switch(formElementTypeString) {
        case vFormControls.BUTTON: {
          let targets = [];
          if(item.actionMode === vFormButtonActionModes.RULES_BASED && item.rules.length) {
            for(let i = 0; i < item.rules.length; i++) {
              let target = this.getTargetByElement(item.rules[i], slideId, slides, steps);
              if(target) {
                target.elementId = item.uuid;
              }
              targets.push(target);
            }
          } else if(item.actionMode !== vFormButtonActionModes.RULES_BASED) {
            let target = this.getTargetByElement(item, slideId, slides, steps);
            if(target) {
              target.elementId = item.uuid;
            }
            targets.push(target);
          }
          return targets ? targets : null;
        }
        case vFormControls.LOGIN: {
          let target = this.getTargetByElement(item.loginButton, slideId, slides, steps);
          if(target) {
            target.elementId = item.uuid;
          }
          return target ? [target] : null;
        }
        case vFormControls.LOGOUT: {
          item.action = item.selectedAction;
          let target = this.getTargetByElement(item, slideId, slides, steps);
          if(target) {
            target.elementId = item.uuid;
          }
          return target ? [target] : null;
        }
        case vFormControls.QR_CODE: {
          targets = this.getQrCodeTargets(item, slideId, slides, steps);
          targets = targets.map(subitem => {
            subitem.elementId = item.uuid;
            return subitem;
          })
          return targets;
        }
        case vFormControls.RADIOBUTTONS: {
          targets = this.getRadiobuttonTargets(item, slideId, slides, steps);
          targets = targets.map(subitem => {
            subitem.elementId = item.uuid;
            return subitem;
          })
          return targets;
        }
        default:
          return null;
      }
    },
    getRadiobuttonTargets(item, slideId, slides, steps) {
      const {formElementTypeString} = item;
      const targets = [];
      const options = this.getOptions(item);
      for(let i = 0; i < options.length; i++) {
        const option = options[i];
        let target = this.getTargetByElement(option, slideId, slides, steps);
        const label = this.getText(option, 'Unknown');
        const targetLabel = label ? label : formElementTypeString;
        if(target) {
          target.label = targetLabel;
          targets.push(target);
        } else {
          console.log('NO TARGET')
          targets.push({
            targetId: null,
            target: vformTargetTypes.NO_TARGET,
            label: targetLabel
          })
        }
      }
      return targets;
    },
    getQrCodeTargets(item, slideId, slides, steps) {
      const {formElementTypeString} = item;
      const targets = [];
      let codes = item.codes;
      const label = this.getLabel(item, 'Unknown');
      const targetLabel = label ? label : formElementTypeString;
      for(let i = 0; i < codes.length; i++) {
        let code = codes[i];
        code.action = code.selectedAction; // this is an inconsistency here, that's why we need to clear things up
        let target = this.getTargetByElement(code, slideId, slides, steps);
        if(target) {
          target.label = targetLabel + ' ' + (i+1);
          targets.push(target);
        } else {
          targets.push({
            targetId: null,
            target: vformTargetTypes.NO_TARGET,
            label: targetLabel + ' ' + (i+1)
          })
        }
      }
      return targets;
    }
  }
}
</script>