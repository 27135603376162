<template>
<div>
  <h2>{{ $t('ActiveServices') }}</h2>
  <tile-button
      v-if="ready"
      :values="all.map(item => {return item.id})"
      :tiles="all.map(item => {return item.displayName ? item.displayName : item.name})"
      :initial-active-tiles="used.map(item => {return item.id})"
      :is-multi-select="true"
      :view-only="!$store.getters.isSuperAdmin"
      :rows="3"
      @trigger="setService"
  />
</div>
</template>

<script>
import TileButton from "@/components/forms/TileButton";
export default {
  name: "ServiceToOrganization",
  components: {
    TileButton
  },
  props: {
    organizationId: {type: String, required: true}
  },
  data() {
    return {
      used: [],
      all: [],
      ready: false,
    };
  },
  beforeMount() {
    this.loadServices();
  },
  methods: {
    loadServices() {
      this.$store.dispatch('clientGetAccessibleServices', {id: this.organizationId}).then(data => {
        this.used = data;
      }).then(() => {
        return this.$store.dispatch('clientLoadServices');
      }).then(data => {
        this.all = data;
        this.ready = true;
      });
    },
    setService(params) {
      const dispatchMethod = params.operation === 'add' ? 'clientAddServiceAccess' : 'clientRemoveServiceAccess';
      this.$store.dispatch(dispatchMethod, {
        id: params.value,
        cid: this.organizationId
      }).then(() => {
        this.loadServices();
      })
    }
  }
}
</script>

<style scoped>

</style>