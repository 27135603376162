<template>
  <div></div>
</template>

<script>
function dragMoveListener(event) {
  let target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

  // translate the element
  target.style.transform = "translate(" + x + "px, " + y + "px)";

  // update the posiion attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);

  let targetInitialX = parseInt(target.style.left.replace("px", ""));
  const carouselWidth = document.getElementsByClassName("carousel")[0].clientWidth;
  let scrollSpeed = (10 + event.speed / 10);
  if (targetInitialX + x > carouselWidth - 100) {
    document.getElementsByClassName("carousel")[0].scrollLeft += scrollSpeed;
  } else if (targetInitialX + x < 80) {
    document.getElementsByClassName("carousel")[0].scrollLeft -= scrollSpeed;
  }
  target = null;
}

/*
    if you use this mixin, the following things are needed:
    a div:
    <div :id="uuId + 'dragHandle'">dragHandle</div>

    afterDraggedMethod: function() {
        do the things after finished dragging here...
    }

    Further Documentation:
    https://interactjs.io/
    * */
import interact from "interactjs";

export default {
  name: "VformCarouselDraggableDroppableMixin",
  props: {},
  data() {
    return {
      draggableTarget: ".draggable-step",
      draggableTarget2: ".drop-zone-step",
    };
  },
  methods: {
    disableDragDropCarousel() {
      interact(this.draggableTarget).unset();
    },
    enableDragDropCarousel() {
      let $this = this;
      interact(this.draggableTarget).unset()
      interact(this.draggableTarget).draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: ".carousel",
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start(event) {
            let target = event.target;
            let position = target.getBoundingClientRect();
            target.style.height = position.height + "px";
            target.style.width = position.width + "px";
            target.style.position = "fixed";
            target.style.top = position.top + "px";
            target.style.left = position.left + "px";
            $this.dragging = true;
            $this.draggedStep = target.getAttribute("step_uuid");
            target = null;
          },
          end(event) {
            let target = event.target;
            target.style = {};
            target.setAttribute("data-x", 0);
            target.setAttribute("data-y", 0);
            $this.dragging = false;
            $this.draggedStep = "";
            target = null;
          },
        },
      });
      interact.dynamicDrop(true);
      interact(this.draggableTarget2).unset()
      // enable draggables to be dropped into this
      interact(this.draggableTarget2).dropzone({
        // only accept elements matching this CSS selector
        accept: ".draggable-step",
        // Require a 75% element overlap for a drop to be possible
        overlap: "pointer",

        // listen for drop related events:

        ondropactivate: function (event) {
          // add active dropzone feedback
          event.target.classList.add("drop-active");
        },
        ondragenter: function (event) {
          var dropzoneElement = event.target;
          dropzoneElement.classList.add("drop-active");

          // feedback the possibility of a drop
        },
        ondragleave: function (event) {
          event.target.classList.remove("drop-active");
        },
        ondrop: function (event) {
          let draggableElement = event.relatedTarget;
          let dropzoneElement = event.target;

          let step_uuid = draggableElement.getAttribute("step_uuid");
          let slide_uuid = dropzoneElement.getAttribute("slide_uuid");
          $this.dragging = false;
          let step = $this.steps.find((x) => x.uuid === step_uuid);
          if (step) {
            $this.$emit('cleanUpGhostSlides', step.linkedSlide, slide_uuid)
            step.linkedSlide = slide_uuid;
            step.linkedSlides = [slide_uuid];
          }

          $this.state.dirty = true;
          $this.$emit('setStepNumbers');

          step = null;
          draggableElement = null;
          dropzoneElement = null;
        },
        ondropdeactivate: function (event) {
          // remove active dropzone feedback
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-target");
        },
      });
    },

  },
};
</script>
<style lang="scss">
.drop-zone-step {
  line-height: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.drop-zone-step.highlight {
  line-height: 23px;
  border: dotted 1px var(--vform-editor-accent-color);
  border-radius: 3px;
}

.drop-zone-step.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
}
</style>
