<template>
  <div :class="['mb-3 position-relative vform-slide-control-element']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <preview
        v-if="getImage(config, lang)"
        :may-load-now="isActive"
        :preview-id="getImage(config, lang)"
        :preview-uri="$store.getters['getMediaPreviewUri'](
                        getImage(config, lang)
                    )"
        :size="config.size"
        source="asset"
        :asset-id="getImage(config, lang)"
        :removable="false"
        :class="['vform-image']"
        @preview="previewIsHere = true"
        :background-mode="false"
        :type="'pdf'"
        :offline-mode="offlineMode"
        :key="forceReRenderKey + 900"
        @contentLoaded="increaseCounter"
    />
    <div class="text-center" v-else>
      {{ $t('noPdfSelected') }}<br />
      <icon class="lighter" type="image" size="3" />
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import Icon from "../../Icon";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "PDFDISPLAY",
  components: {
    Preview,
    Icon,
    EditorOverlay,
  },
  mixins: [ElementMixin],
  data() {
    return {
      forceReRenderKey: 0,
      previewIsHere: false,
    };
  },
  watch: {
  },
  beforeMount() {
  },
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
      return true;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>