<template>
  <ul :class="[
                level ? 'level-' + level : '',
                getOpenStatus(id) || level <= maxInitDepth ? 'open' : 'closed',
                id
            ]">
    <li>
      <slot name="loadless"/>
    </li>
    <li>
      <slot name="loadingspinner"/>
    </li>

    <li v-for="(item, key) in data"
        :key="key">
      <div :key="item.id + 'text' + forceReRenderMeta"
           :class="[`text too-long-text-break`,
            item.type === 'node' ? 'has-children' : '',
            $store.getters.getAssemblyListOptions($route.params.id).selected && item.id in $store.getters.getAssemblyListOptions($route.params.id).selected ? 'active' : '',
            getOpenStatus(item.id) || level === 1 ? 'open' : 'closed',
            showMetaFixed === item.id ? 'meta-fixed' : '',
            ]"
           :style="getOpenStatus(item.id) || level === 1 ? `z-index: ${level}` : 'z-index: 0'"
           @click="setActiveItem(item.id, item.assetId, item.asset.type, item.asset, item)"
      >
        <!--PRE ICONS-->
        <icon v-if="item.type === 'node' && (getOpenStatus(item.id) || level === 1 || level === 2)" class="icon-left" type="folder-open"/>
        <icon v-else-if="item.type === 'node'" class="icon-left" type="folder" size="0.9"/>
        <icon v-else class="icon-left" type="cube" size="0.9"/>

        <div class="node-name inline-block ml-1">
          <!--TEXT-->
          <div><div class="d-inline-block node-subname"> {{ getItemName(item, false) }}
            <div v-if="item.name && item.name.length >= 25 " class="node-name-hover bg-dark border-radius">{{ getItemName(item, true) }}</div>
            </div>
            <div v-if="showMeta" class="lazy-meta-box d-inline-block ml-2">
              <div @click="showMetaFixedAction(item.id)"><icon type="list-ul" size="0.9"/></div>
              <div
                  v-if="showMetaFixed === item.id"
                  :class="[
                    'hidden bg-dark p-2 border-radius',
                    showMetaFixed === item.id ? 'fixed-meta' : ''
                    ]"
              >
                {{ item.displayName ? item.displayName : item.name }}<br />
                <lightweight-meta-panel
                  :instance-meta-fields="item.metaFields"
                  :asset-meta-sets="item.asset.metaSets"
                  :asset="item.asset"
                  :instance="item"
                />


                <!--<meta-panel
                    :key="metaSet.id"
                    v-for="(metaSet) in item.asset.metaSets"
                    :title="metaSet.description ? metaSet.description : metaSet.name"
                    :fields="item.metaFields.filter(metafield => {metafield.metaSetId === metaSet.id})"
                    icon-left="folder"
                    store-name="Instance"
                    :cid="metaSet.id"
                    :target-id="projectId"
                    @updated="loadInstanceMetaValues"
                    :editable="metaSet.name !== 'sfx.asset.model'"
                />-->
                <div class="meta-fixed-closer" @click="showMetaFixedAction(null)"><icon type="times" size="0.9" /></div>
                <div @click="reloadItem(item.id)" class="meta-fixed-editor"><icon type="edit" size="0.9" /></div>
              </div>

            </div>
            <div v-if="item.type === 'node'" class="lighter d-inline-block ml-2 ">
              {{ parseInt(offset) ? parseInt(offset) : 0 }} - {{
                (parseInt(offset) ? parseInt(offset) : 0) + Object.keys($store.getters.getAssemblyTreeLevel(item.id, projectId)).length
              }} / {{ $store.getters.getAssemblyListOptions(item.id, 'pagination_items') }}
            </div>
          </div>

          <div v-if="false">
            asdf
          </div>

          <!--POST ICONS-->
          <div class="hover-icons">
            <!--<div class="icon-container mr-2" @click.stop="$emit('materialEdit', item)">
              <icon class="lighter" type="game-board-simple" size="0.9"/>
            </div>-->
            <div class="icon-container mr-2" @click.stop="editItem(item.id)">
              <icon class="lighter" type="cog" size="0.9"/>
            </div>
            <div v-if="item.type !== 'node'" class="icon-container">
              <router-link :to="'/library/3d-data/model/' + item.asset.id + '/general'">
                <icon class="lighter" type="external-link-alt" size="0.9"/>
              </router-link>
            </div>
            <div v-else-if="item.type === 'node'" class="icon-container">
              <router-link :to="'/library/3d-data/instance/' + item.id + '/general'">
                <icon class="lighter" type="external-link-alt" size="0.9"/>
              </router-link>
            </div>
          </div>


          <loading-spinner v-if="getLoadingItemStatus(item.id)" class="white"/>
          <span v-if="item.sources && item.sources.length > 0">
                      <span class="linked-data"><icon type="paperclip"/> {{ item.sources.length }}</span>
                    </span>
          <div
              v-if="item.type === 'node'" class="item-count"
              @click.stop="true ? toggleOpenClosed(item.id) : null">
            <icon class="lighter" size="1.1" type="angle-right"/>
          </div>
        </div>
        <!--ASSET/INSTANCE EDITING-->
        <div v-if="isEditing || item.id === isEditing">
          <portal to="aboveTreeSection">
            <model-edit-view-popup
                :instance-id="isEditing"
                :project-id="projectId"
                :has-write-access="hasWriteAccess"
                @abort="() => {isEditing = null;}"
                @updateName="updateItemName"
            />
          </portal>

          <!--<div @click.stop="updateItemName(item.id)" class="mr-3 float-right editing-icon"><icon type="save" /></div>
          <div @click.stop="() => {isEditing = null; newName = null;}" class="mr-3 float-right editing-icon"><icon type="times" /></div>
          <input @keydown.enter="updateItemName(item.id)" class="form-text" v-model="newName" />-->
        </div>
      </div>
      <node
          :id="item.id"
          :key="item.id"
          :data="$store.getters.getAssemblyTreeLevel(item.id, projectId)"
          :fields="fields"
          :level="level + 1"
          :max-init-depth="maxInitDepth"
          :node-close-handler="nodeCloseHandler"
          :node-open-handler="nodeOpenHandler"
          :node-selection-handler="nodeSelectionHandler"
          :open-items="openItems"
          :project-id="projectId"
          :show-meta="showMeta"
          :has-write-access="hasWriteAccess"
          @materialEdit="(mat) => {$emit('materialEdit', mat)}"
      >
        <div v-if="item.type === 'node' && $store.getters.getAssemblyListOptions(item.id, 'pagination_items') > limit && getOpenStatus(item.id)" slot="loadmore"
             class="text">
          <div class="lighter">{{ $t('jumpTo') }}</div>
          <input v-model="offset" class="form-text" type="number" @keydown.enter.stop="loadOffset(item.id)">
          <div @click="loadMore(item.id)">load more<br>
            <icon type="angle-down"/>
          </div>
        </div>
        <div v-if="item.type === 'node' && offset > 0" slot="loadless" class="text" @click="loadLess(item.id)">
          <p>
            <icon type="angle-up"/>
            <br>load before
          </p>
        </div>
        <div v-if="getLoadingItemStatus(item.id)" slot="loadingspinner" class="text-center pt-2 pb-2">
          <loading-spinner class="white"/>
        </div>
      </node>
    </li>

    <li :class="[
                level ? 'level-' + level : ''
            ]">
      <slot name="loadmore"/>
    </li>
  </ul>
</template>

<script>
import Icon from "../Icon";
import LoadingSpinner from "../LoadingSpinner";
import ModelEditViewPopup from "@/components/tree/ModelEditViewPopup";
//import MetaPanel from "../widgets/meta/MetaPanel";
import LightweightMetaPanel from "../widgets/meta/LightweightMetaPanel";
export default {
  name: 'Node',
  components: {
    LoadingSpinner,
    Node,
    Icon,
    ModelEditViewPopup,
    LightweightMetaPanel,
    //MetaPanel
  },
  props: {
    fields: {type: Array, required: true,},
    data: {type: Array, default: null},
    level: {type: Number, default: 0},
    name: {type: String, default: ''},
    nodeSelectionHandler: {type: String, default: ''},
    nodeOpenHandler: {type: String, default: ''},
    nodeCloseHandler: {type: String, default: ''},
    projectId: {type: String, default: ''},
    id: {type: String, default: ''},
    previewPath: {type: String, default: null},
    maxInitDepth: {type: Number, default: 2},
    openItems: {type: Array, default: null},
    showMeta: {type: Boolean, default: false},
    hasWriteAccess: {type: Boolean, default: true}
  },
  data() {
    return {
      showChildren: false,
      loadingData: false,
      loadingItem: null,
      newName: '',
      isEditing: null,
      offset: 0,
      limit: 200,
      showMetaFixed: false,
      forceReRenderMeta: 0
    };
  },
  watch: {
    projectId(newValue) {
      if (newValue) {
        if ((this.level + 1) <= this.maxInitDepth && this.projectId) {
          this.loadData();
        }
      }
    },
  },
  beforeMount() {
    if (this.getOpenStatus(this.id)) {
      this.loadData(this.id, this.level);
    }
  },
  methods: {
    getItemName(item, getFullNameAnyway = false) {
      let tmpName = item.displayName ? item.displayName : item.name;
      return tmpName.length >= 35 && !getFullNameAnyway ? (tmpName.substring(0,35) + '...') : tmpName;
    },
    loadInstanceMetaValues() {
      //tbd
    },
    showMetaFixedAction(id) {
      this.showMetaFixed = this.showMetaFixed === id ? null : id;
    },
    editItem(id) {
      //this.newName = name;
      this.isEditing = id;
    },
    updateItemName(id, name) {
      /*this.$store.dispatch('clientUpdateProjectInstance', {
          id: this.projectId,
          cid: id,
          name: this.newName
        });*/
      let index = this.data.findIndex(item => {
        return item.id === id;
      });
      this.data[index].name = name;
      //this.isEditing = null;
      //this.newName = '';
    },
    getOpenStatus(id) {
      return this.openItems.length > 0 && this.openItems.includes(id);
    },
    getLoadingItemStatus(id) {
      return this.loadingItem && this.loadingItem.includes(id);
    },
    toggleOpenClosed: function (id) {
      this.$emit('click');
      if (this.getOpenStatus(id)) {
        this.$store.dispatch(this.nodeCloseHandler, {
          listName: this.$route.params.id,
          id: id,
        });
        this.unloadData(id);
      } else {
        this.loadingItem = id;
        this.$store.dispatch(this.nodeOpenHandler, {
          listName: this.$route.params.id,
          id: id,
        });
        this.loadData(id);
      }
    },
    setActiveItem: function (id, assetId, type, asset, item) {
      this.$store.dispatch(this.nodeSelectionHandler, {
        listName: this.$route.params.id,
        id: id,
        assetId: assetId,
        type: type,
        asset: asset,
        item: item
      });
    },
    loadOffset(id) {
      this.unloadData(id);
      this.loadData(id, null, parseInt(this.offset));
    },
    loadMore(id) {
      this.offset = parseInt(this.offset) + this.limit;
      this.loadOffset(id);
    },
    loadLess(id) {
      if (this.offset > 0) {
        this.offset = parseInt(this.offset) - this.limit;
        if (this.offset < 0) {
          this.offset = 0;
        }
      }
      this.loadOffset(id);
    },
    getLoadingArgs(id = null, level = null, offset = null) {
      let parentId = id;
      if (!id) {
        parentId = this.id;
      }
      let args = {
        id: this.projectId,
        include: 'assetAndMetaSets,sources,metaFields',
        limit: this.limit,
        level: this.level + 1,
        parentId: id,
        filter: 'level eq ' + [level ? level : this.level + 1] + ', type in model node, parentId eq ' + parentId,
      };
      if (offset) {
        args.offset = offset;
      }
      return args;
    },
    reloadItem(id) {
      let parentId = id;
      if (!id) {
        parentId = this.id;
      }
      this.$store.dispatch('loadAssemblyTreeLevelItem', {
        id: this.projectId,
        cid: id,
        include: 'assetAndMetaSetsBugfix,sources,metaFields',
        parentId: parentId
      }).then(() => {
        this.forceReRenderMeta++;
      });
    },
    loadData(id = null, level = null, offset = null) {
      this.loadingData = true;
      this.loadingItem = id;
      let args = this.getLoadingArgs(id, level, offset);
      this.$store.dispatch('loadAssemblyTreeLevel', args).then(() => {
        this.showChildren = true;
        this.loadingData = false;
        this.loadingItem = '';
      });
    },
    unloadData: function (id) {
      let parentId = id;
      if (!id) {
        parentId = this.id;
      }
      this.$store.dispatch('unloadAssemblyTreeLevel', {
        listName: this.projectId,
        level: this.level + 1,
        id: id,
        parentId: parentId,
      }).then(() => {
        this.showChildren = false;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.text.meta-fixed {
  z-index: 80 !important;
}
.node-subname {
  .node-name-hover {
    display:none;
  }
  &:hover {
    .node-name-hover {
      position: absolute;
      z-index: 60;
      bottom: 0;
      left: 0;
      display: block;
      -webkit-box-shadow: 0 8px 6px -5px black;
      box-shadow: 0 5px 6px -5px black;
    }
  }
}
.lazy-meta-box {
  .hidden {
    position: fixed;
    z-index: 90;
    bottom: 0;
    left: 0;
    display: none;
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    width: 450px;
    height: 300px;
    overflow-y: scroll;
    &.fixed-meta {
      display: block;
    }
    .meta-fixed-closer, .meta-fixed-editor {
      position: absolute;
      top:5px;
      right: 15px;
      cursor: pointer;
    }
    .meta-fixed-editor {
      top: 25px;
    }
  }
  &:hover {
    .hidden {
      //display: block;
    }
  }
}

</style>