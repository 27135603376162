<template>
    <div class="buttonField">
        <div class="opener" @click.self="toggleOpenClosed"><icon class="noPointerEvent" type="ellipsis-v" /></div>
        <div :class="['buttonFieldContent', open ? 'open' : '', size]">
            <div class="buttonIcon" title="delete" v-if="deletable" @click="deleting = true; $emit('deleteRow', itemId)">
              <Button>
                <icon
                        type="times"
                /> {{ $t('delete') }}
                <loading-spinner v-if="deleting" class="white float-left ml-1" />
              </Button>
            </div>
          <slot />
        </div>
    </div>
</template>

<script>
    import Icon from "../Icon";
    import LoadingSpinner from "@/components/LoadingSpinner";
    import Button from "../forms/Button";
    export default {
        name: "ButtonField",
        components: {
            Icon,
            LoadingSpinner,
            Button,
        },
        props: {
            itemId: {type: String, required: true},
            deletable: {type: Boolean, default: false},
            deleting: {type: Boolean, default: false},
            size: {type: String, default: 'auto'},
        },
        data() {
            return {
                open: false,
            };
        },
        methods: {
            toggleOpenClosed: function(e) {
                e.stopPropagation();
                this.open = !this.open;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .buttonField {
        position:absolute;
        right:4px;
        top: 50%;
        padding-top:15px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width:100%;
        height: 90%;
        z-index:50;
        .opener {
            padding:15px;
            position:absolute;
            top:0;
            right:0;
            z-index: 5;
            &:hover {
                color: $highlight;
            }
        }
    }
    .buttonFieldContent {
        position:absolute;
        top:0;
        right:0;
        padding-right:25px;
        width:0;
        height:0;
        overflow:hidden;
        background-color: #494d54;
        z-index:0;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        opacity: 0;
        &.open {
            width:400px;
            height:100%;
            min-height: 50px;
            opacity: 1;
          &.large {
            min-height:500px;
          }
          &.medium {
            min-height: 300px;
          }
        }
        .buttonIcon {
            padding:15px;
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
            display:inline-block;
            &:hover {
                background: $nav-item-background-active;
            }
        }
    }
    .noPointerEvent {
        pointer-events: none;
    }
</style>