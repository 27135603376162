<template>
  <div class="ticket-form">
    <div class="p-3 mb-5" v-if="project">
      <h2>Create new ticket for {{ project.name }}</h2>
      <select class="custom-select darker" v-model="ticketType">
        <option value="Bug"><icon type="bug" />Bug</option>
        <option value="Task"><icon type="ticket" />Feature</option>
      </select>
      <div class="w-100"/>
      <div class="vform-label">Name</div>
      <input class="form-text" type="text" v-model="ticketName"/>
      <div class="w-100"/>
      <div class="vform-label">Description</div>
      <textarea class="form-text ticketing-textarea" v-model="ticketDesc"/>

      todo: add multiple input fields and combine them into ticket desc
      <div v-if="project.name === 'vSTAGE'">{{ $t('In case of a crash, please attach the Player.log and the Player-prev.log file from the folder C:/Users/yourUser/AppData/LocalLow/SideEffects/vStage') }}</div>
      <multi-drop-zone
          ref="ticketUpload"
          class="mt-3"
          :auto-upload="false"
          target-type="none"
          form-id="myform"
          :endpoint-u-r-l="'/ticketing/projects/' + project.id + '/tickets'"
          :attached-data="{
            projectId: project.id,
            name: ticketName,
            description: ticketDesc,
            type: ticketType
          }"
          @uploadSuccess="$emit('creationSuccess')"
          @gotFile="fileUpload = true"
       />
      <div @click="createTicket()" class="settings-button d-inline-block mt-3">save</div>
      {{ saved }}
    </div>
  </div>
</template>

<script>
import MultiDropZone from "@/components/ticketing/MultiDropZone";
import Icon from "@/components/Icon";
export default {
  name: "TicketForm",
  components: {
    MultiDropZone,
    Icon
  },
  props: {
    project: {type: Object, required: true},
    teamId: {type: String, required: true}
  },
  data() {
    return {
      ticketType: 'Bug',
      ticketName: '',
      ticketDesc: '',
      saved: '',
      fileUpload: false
    };
  },
  watch: {
    project() {
      this.setTicketTemplate();
    }
  },
  beforeMount() {
    this.setTicketTemplate();
  },
  methods: {
      setTicketTemplate() {
        if(this.project.name === 'vSTAGE' && this.ticketType === 'Bug') {
          this.ticketDesc = 'Version: \n\nWhat happened: \n\nHow to reproduce the bug: \n';
        } else if(this.ticketType === 'Bug') {
          this.ticketDesc = 'What happened: \n\nHow to reproduce the bug: \n';
        }
      },
     createTicket() {
      if(this.fileUpload) {
        this.$refs.ticketUpload.resumeUpload();
      } else {
        this.$store.dispatch('clientCreateTicket', {
          projectId: this.project.id,
          description: this.ticketDesc,
          name: this.ticketName,
          type: this.ticketType
        }).then(() => {
          this.$emit('creationSuccess')
        });
      }

    },
  }
}
</script>

<style lang="scss" scoped>
  .form-text {
    border-radius: 4px;
  }
  .custom-select {
    border-radius: 4px;
  }
  .ticketing-textarea {
    min-height: 300px;
  }
  .ticket-form {
    background-color: var(--vform-editor-ui-secondary-color);
    border-radius: 4px;
    box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -9px 6px 21px 0px rgba(0,0,0,0.75);
  }
  .vform-label {
    color: var(--vform-editor-ui-primary-color);
  }
</style>