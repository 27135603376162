<template>
  <div>
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeletedAsset = null;}"
                     @confirm="() => {showDeletePrompt = false; if(toBeDeletedAsset) {deleteFilter();}}"
      >
        <loading-panel v-if="loading"/>
        <div v-else-if="instanceList && instanceList.length">
          <br>{{ $t('willAlsoBeDeleted') }}
          <div v-for="(item, key) in instanceList" :key="key">
            {{ $t('ExportedProfiles') }}: {{ item.name ? item.name : item.asset.name }}<br>
            <span class="lighter">{{ $t('Created') }}:</span> {{ dateTimeFromISO(item.createdAt) }}<br><br>
          </div>
        </div>
      </delete-prompt>
    </popup>
    <h2 v-if="!formEmbedMode && filterId !== 'new'">{{ $t('createNewType', {type: $t('type.exportProfile')}) }}</h2>
    <h2 v-else-if="!formEmbedMode">{{ $t('updateType', {type: $t('type.exportProfile')}) }}</h2>
    <organization-switcher
        v-if="!organizationId"
        class="mb-2"
        @setOrganization="id => localOrganizationId = id"
        @setTeam="id => teamId = id"
        @unsetTeam="teamId = ''"
    />
    <input
        v-if="!formEmbedMode"
        :id="'name'"
        v-model="name"
        :class="['form-text v-form-label form-text-dark', $v.name.$error ? 'form-group--error' : '']"
        :placeholder="$t('enternamehere')"
        type="text"

    >
    <div v-if="!formEmbedMode && !$v.name.required && $v.name.$error" class="form-error">{{ $t('errors.required') }}</div>
    <div v-if="!formEmbedMode && !$v.name.alphaNumSpace" class="form-error">{{ $t('errors.alphaNumSpaceOnly') }}</div>

    <div v-if="!formEmbedMode && !$v.name.maxLength" class="form-error">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <div v-if="!formEmbedMode && !$v.name.minLength" class="form-error">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
    <div v-if="!formEmbedMode && !$v.name.isUnique" class="form-error">{{ $t('errors.sfxdataNameAlreadyExists') }}</div>
    <div v-if="error" class="form-error">{{ $t(error) }}</div>

    <div v-if="!formEmbedMode" class="inline-edit-label mt-3 vform-label white">
      <b>{{ $t('description') }}</b>
    </div>

    <textarea
        v-if="!formEmbedMode"
        :id="'description'"
        v-model="description"
        :class="['mb-1 form-text v-form-label form-text-dark', $v.description.$error ? 'form-group--error' : '']"
        :placeholder="$t('enterdeschere')"
    />
    <div v-if="!$v.description.maxLength" class="form-error">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

    <team-attacher
        v-if="!formEmbedMode && filterId !== 'new' && $store.getters.getFilterOrganization(filterId)"
        :id="filterId"
        slot="info"
        :limit-by-organization-id="$store.getters.getFilterOrganization(filterId)"
        :teams="localTeams"
        class="mt-3 mb-3 col-6 darker new-style bright"
        :collapsible="false"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />
    <div class="d-flex w-100 justify-space-between">
      <div :class="['filter-tab', activeTab === 'corr' ? 'active' : '']" @click="setTabActive('corr')">{{ $t('Corrections').toUpperCase() }}</div>
      <div :class="['filter-tab', activeTab === 'filter' ? 'active' : '']" @click="setTabActive('filter')">
        {{ $t('Simplify').toUpperCase() }}<br/>{{ $t('RemoveParts') }}
      </div>
      <div :class="['filter-tab', activeTab === 'merge' ? 'active' : '']" @click="setTabActive('merge')">
        {{ $t('Merge').toUpperCase() }}<br/>{{ $t('MergeParts') }}
      </div>
      <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" :class="['filter-tab', activeTab === 'advanced' ? 'active' : '']" @click="setTabActive('advanced')">
        {{ $t('Advanced').toUpperCase() }}
      </div>
    </div>
    <div class="tab-view">
      <div v-if="activeTab === 'corr'" class="tab-item">
        <h2>Pivots</h2>
        <div class="lighter mb-2">Pivots mark the point around which an object rotates – this might be on the side for the hinge on a door or centered for an office chair</div>
        <label>{{ $t('pivotPlacement') }} (global)</label>
        <info-helper class="ml-1" text="pivotComment"/><br />
        <select v-model="pivotPlacement" class="custom-select form-text-dark mod-selector">
          <option v-for="(key) in pivotPositions" :key="key" :value="key">{{ key }}</option>
        </select>
        <div class="w-100 mt-3" />
        <label>{{ $t('pivotPlacement') }} (local)</label>
        <div class="form-group">
          <input id="checkbox" ref="checkbox" v-model="usePivotValues" class="checkbox float-left mr-2"
                 type="checkbox">
          <div class="ml-4 inline-edit-label" for="checkbox">{{ $t('UseMetaSetValuesForPivots') }}</div>
        </div>
        <div class="w-100 mb-5"/>
        <h2>Rotation</h2>
        <div class="lighter mb-3">It might be a good idea to import an object with the correct initial rotation. Otherwise it is possible to change the rotation here. Just enter 90 or -90 into the x field to rotate it 90 degrees around the x axis.</div>
        <label class="mr-1">{{ $t('axisMultiplier') }}</label>
        <info-helper text="axisMultiplierDesc"/>
        <br>
        <div class="d-flex">
          <div class="mr-1">
            <label>x</label>
            <input v-model="axisMultiplier[0]" class="form-text form-text-dark" type="number">
          </div>
          <div>
            <label>y</label>
            <input v-model="axisMultiplier[1]" class="form-text form-text-dark" type="number">
          </div>
          <div class="ml-1">
            <label>z</label>
            <input v-model="axisMultiplier[2]" class="form-text form-text-dark" type="number">
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'advanced'" class="tab-item">
        <codemirror
            :value="JSON.stringify($store.getters.getExportProfilesById(filterId))"
            @save="updateContent"
        />
      </div>
      <!--MERGE AND FILTER SHARE THIS ONE--->
      <div v-if="['filter', 'merge'].includes(activeTab)" class="tab-item">
        <h2 v-if="activeTab === 'filter'">{{ $t('Filter') }}</h2>
        <h2 v-else>{{ $t('Merge') }}</h2>
        <div class="darker p-2 border-radius" v-if="activeTab === 'merge'">
          (Only groups can be merged, not single parts)<br />
        </div>
        <div v-if="activeTab === 'filter'" class="darker border-radius p-2">Filtering allows you to:
          <ul>
            <li>Remove all screws</li>
            <li>Remove the hull</li>
            <li>Remove all parts except the hull</li>
            <li>And much, much more</li>
          </ul>
          Try it out:
          <div @click="removeAllSmallParts" class="settings-button d-inline-block mr-2">{{ $t('removeAllSmallParts') }}</div>
          <div @click="removeTheHull" class="settings-button d-inline-block mr-2">{{ $t('removeTheHull') }}</div>
        </div>
        <div class="include-exclude mb-3 mt-2">
          <tile-button
              ref="tiles"
              v-if="$store.getters.getExportFilterModeById(filterId) && activeTab === 'filter'"
              :initial-active-tiles="[$store.getters.getExportFilterModeById(filterId)]"
              :is-multi-select="false"
              :icons="false"
              :tiles="['include', 'exclude']"
              :values="['include', 'exclude']"
              :update-mode="true"
              @trigger="setActive"
          />
        </div>

        <label class="vform-label white mb-0">{{ $t('stringSearch') }} (OR)</label>
        <string-filter
          :filter-id="filterId"
          :type="activeTab"
        />
        <div class="mt-3">
          <label class="vform-label white mb-0">{{ $t('metaSearch') }} (AND)</label>

          <meta-field-filter-2
              ref="metaFieldFilter"
              v-if="metaSets"
              :filter-id="filterId"
              :type="activeTab"
              :meta-sets="metaSets"
              :organization-id="localOrganizationId"
              @setMode="setActive"
          />
        </div>
      </div>
    </div>
    <div class="button-box mt-3">
      <div
          :deactivated="!enableSaving"
          class="settings-button d-inline-block mr-3"
          @click="() => { if(filterId !== 'new') update(); else save(); }">
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t(!filterId ? 'SAVE' : 'SAVE') }}
      </div>
      <div
          v-if="!formEmbedMode"
          class="settings-button d-inline-block mr-3"
          @click="abort">
        <icon type="times"/>
        {{ $t('abort') }}
      </div>
      <div
          v-if="!formEmbedMode && filterId"
          class="settings-button d-inline-block mr-3 float-right"
          @click="deleteFilter(filterId)"
      >
        {{ $t('delete') }}
        <icon type="trash-alt"/>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../../customValidators';
import MetaFieldFilter2 from "./MetaFieldFilter2";
import StringFilter from "./StringFilter";
import Icon from "@/components/Icon";
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";
//import MetaSetSelector from "@/components/widgets/meta/MetaSetSelector";
import {mapState} from 'vuex';
//import ByMetaFieldsFilter from "@/components/widgets/meta/ByMetaFieldsFilter";
//import InfoPanel from "@/components/InfoPanel";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Popup from "@/components/Popup";
import DateMixin from "@/components/mixins/DateMixin.js";
import LoadingPanel from "@/components/LoadingPanel";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import {PivotPositions} from "@/enum";
import Codemirror from "../widgets/editors/Codemirror";

export default {
  name: "FilterAddForm",
  components: {
    Icon,
    OrganizationSwitcher,
    //MetaSetSelector,
    //ByMetaFieldsFilter,
    //InfoPanel,
    InfoHelper,
    DeletePrompt,
    Popup,
    LoadingPanel,
    TeamAttacher,
    MetaFieldFilter2,
    TileButton,
    StringFilter,
    Codemirror
  },
  mixins: [DateMixin],
  props: {
    organizationId: {type: String, default: ''},
    teams: {
      type: Array, default: () => {
      }
    },
    /**
     * @param filterId add-form switches into edit mode of filter ID given
     * */
    filterId: {type: String, default: ""},
    formEmbedMode: {type: Boolean, default: false}
  },
  data() {
    return {
      pivotPositions: PivotPositions,
      enableSaving: false,
      error: '',
      localOrganizationId: this.organizationId,
      metaSetId: '', // deprecated
      mergeMetaSetId: '', // deprecated
      hasLoaded: false,

      // creation
      name: '',
      type: 'filter',
      description: '',


      // deleting
      toBeDeletedAsset: null,
      showDeletePrompt: false,

      // loading
      instanceList: {},
      loading: false,
      localTeams: [],
      teamId: '',

      // corrections
      pivotMetaSetId: '',
      pivotField: '',
      usePivotValues: false,
      axisMultiplier: [0, 0, 0],
      pivotPlacement: 'source',

      // tabs
      activeTab: 'filter',

      // active filtering
      filter: {},
      filterArray: [],
      merge: {},
      mergeArray: [],
      filterStringArray: [],
      mergeStringArray: [],
      metaSets: [],
      mode: null,

      state: {},
    }
  },
  computed: mapState({
    currentUserOrg: state => state.users.currentUserOrg,
    exportProfiles: state => state.exportprofile.exportProfiles
  }),
  watch: {
    name() {
      this.error = null;
    },
    '$v.$invalid': function () {
      this.checkSaving();
    },
    organizationId(val) {
      if (val) {
        this.localOrganizationId = this.organizationId;
      }
    },
    currentUserOrg(val) {
      if (!this.organizationId) {
        this.localOrganizationId = val;
      }
    },
    filterId(val) {
      if (val) {
        this.loadFilterData();
      }
    },
    usePivotValues() {
      if (this.usePivotValues) {
        this.pivotField = 'sfx.asset.transform.preferredPivot'
      } else {
        this.pivotField = '';
      }
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfFilterNameExists', {type: 'Asset', name: value}).then(data => {
          bool = (data.length === 0 || (data[0].id === this.filterId));
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    },
  },
  beforeMount() {
    if (this.filterId !== 'new') {
      this.loadFilterData();
      this.loadAssetTeams();
    } else {
      this.setBasicData();
      this.localTeams = this.teams;
    }
    this.loadMetaSets();
  },
  methods: {
    removeTheHull() {
      this.$refs.metaFieldFilter.removeTheHull();
    },
    removeAllSmallParts() {
      this.$refs.metaFieldFilter.removeAllSmallParts();
    },
    async updateContent(contentString) {
      let parsed = {};
      try {
        parsed = JSON.parse(contentString);
      } catch {
        console.log('parsing failed')
      }
      await this.$store.dispatch('importProfile', {value: parsed, id: this.filterId});
    },
    setActive(mode) {
      this.$store.dispatch('overWriteFilterMode', {id: this.filterId, value: mode.value});
    },
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: 'organizationId in ' + this.$store.getters.getSuperOrg + ' ' + this.localOrganizationId ? this.localOrganizationId : this.organizationId
      }).then(metaSets => {
        this.metaSets = metaSets;
      })
    },
    async setTabActive(tabName) {
      this.activeTab = tabName;
      if(this.activeTab === 'merge') {
        this.mode = 'include';
      }
      this.$emit('activeTab', tabName);
    },
    // deprecated
    setMetaSet(metaSetName, metaSetId) {
      this.metaSetId = metaSetId;
    },
    // deprecated
    setMergingMetaSet(metaSetName, metaSetId) {
      this.mergeMetaSetId = metaSetId;
    },
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.filterId
      }).then(data => {
        this.localTeams = data;
      });
    },
    getEmptyObject() {
      return {
        filterStringArray: [],
        filter: [],
        merge: [],
        mergeStringArray: [],
        pipeline: {
          subVersion: {
            mode: 'include',
            //axisMultiplier: null,
            //pivotField: null,
            //pivotMetaSetId: null,
            //usePivotValues: true,
          }
        }
      }
    },
    setBasicData() {
      const content = this.getEmptyObject();
      this.$store.dispatch('importProfile', {value: content, id: this.filterId});
    },
    loadFilterData() {
      this.$store.dispatch('loadFilter', {
        id: this.filterId,
      }).then(data => {
        let content;
        if (data) {
          this.name = data.name;
          this.description = data.description;
          if (data.content) {
            try {
              content = JSON.parse(data.content);
            } catch {
              content = this.getEmptyObject();
            }
            const {pipeline} = content;
            const {subVersion, axisMultiplier, pivotField, pivotMetaSetId, pivotPlacement} = pipeline;
            //this.filter = filter;

            if (subVersion.mode) {
              this.mode = subVersion.mode;
            } else {
              content.pipeline.subVersion.mode = 'include';
            }
            if (axisMultiplier) {
              this.axisMultiplier = axisMultiplier;
            }
            if (pivotField) {
              this.pivotMetaSetId = pivotMetaSetId;
              this.pivotField = pivotField;
              this.usePivotValues = true;
            }
            if (pivotPlacement) {
              this.pivotPlacement = pivotPlacement;
            }
          } else {
            content = this.getEmptyObject();
          }
          if(!content.filterStringArray) {
            content.filterStringArray = [];
          }
          if(!content.mergeStringArray) {
            content.mergeStringArray = [];
          }
          if(!content.mode) {
            content.mode = 'include';
          }
          if(!content.filter) {
            content.filter = [];
          }
          if(!content.merge) {
            content.merge = [];
          }
          this.$store.dispatch('importProfile', {value: content, id: this.filterId});
        }
        this.hasLoaded = true;
      });
    },
    loadFilterInstances() {
      this.loading = true;
      let args = {
        listName: this.listId + 'filterinstances',
        add: {
          filter: {
            filterAddForm: 'assetId eq ' + this.filterId
          },
          include: {
            filterAddForm: ['asset', 'project'],
          }
        }
      };
      this.$store.dispatch('loadAllFilterInstances', args).then(data => {
        this.instanceList = data;
        this.loading = false;
      });
    },
    checkSaving() {
      this.enableSaving = this.name && !this.$v.$invalid;
    },
    /**
     * Gets the args to deliver to update or save function
     * */
    getArgs() {
      return {
        name: this.name,
        description: this.description,
        type: 'filter',
        content: JSON.stringify({
          filterVersion: 1.1,
          pipeline: {
            axisMultiplier: [Number(this.axisMultiplier[0]), Number(this.axisMultiplier[1]), Number(this.axisMultiplier[2])],
            pivotPlacement: this.pivotPlacement,
            pivotField: this.pivotField,
            pivotMetaSetId: this.pivotMetaSetId,
            // subVersion is legacy
            subVersion: {
              mode: this.$store.getters.getExportFilterModeById(this.filterId)
            },
          },
          mode: this.$store.getters.getExportFilterModeById(this.filterId),
          filter: this.$store.getters.getExportFilterById(this.filterId),
          filterStringArray: this.$store.getters.getExportFilterStringById(this.filterId),
          merge: this.$store.getters.getMergeById(this.filterId),
          mergeStringArray: this.$store.getters.getExportMergeStringById(this.filterId),
        })
      };
    },
    /**
     * Updates a filter item
     * */
    async update() {
      let args = this.getArgs();
      args.id = this.filterId;
      await this.$store.dispatch('updateFilter', args).then(() => {
        this.$emit('finished');
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
    /**
     * Creates a new filter item
     * */
    async save () {
      console.log('saving...')
      let args = this.getArgs();
      args.organizationId = this.localOrganizationId;
      if (this.teams) {
        args.teams = this.teams;
      }
      await this.$store.dispatch('createFilter', args).then(() => {
        this.$emit('finished');
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
    /**
     * Aborts editing
     * */
    abort() {
      this.$emit('finished');
    },
    /**
     * Deletes a filter item
     * */
    deleteFilter(id) {
      if (this.toBeDeletedAsset) {
        this.$store.dispatch('deleteFilters',
            [this.toBeDeletedAsset]
        ).then(() => {
          this.$emit('finished');
        });
        this.showDeletePrompt = false;
        this.toBeDeletedAsset = null;
      } else {
        this.toBeDeletedAsset = id;
        this.loadFilterInstances();
        this.showDeletePrompt = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tab-item {
  padding: 15px;
  transition: all 300ms ease;
}
.filter-tab {
  padding: 5px 10px;
  margin-right: 3px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  background-color: var(--vform-editor-ui-tertiary-color);
  color: #000;
  z-index: 0;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 300ms ease;

  &:hover, &.active {
    background-color: var(--vform-editor-ui-secondary-color);
    color: #fff;
  }
}

.tab-view {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
  margin-top: -3px;
  z-index: 1;
  position: relative;
}

.search-field {
  width: 200px;
}
.include-exclude {
  max-width: 200px;
}
.mod-selector {
  width: 200px;
  display: inline-block;
}
.form-text-dark {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
}
</style>