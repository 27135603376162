<template>
  <div>
    <div class="mb-3">
      <label class="vform-editor-label section">{{ $t('Choose display information') }}</label>
      <select v-model="element.partInfo" class="custom-select form-text-dark">
        <option v-for="view in infoTypesDataSource" :key="view" :value="view">
          {{ $t(`vform.${view}`) }}
        </option>
      </select>
    </div>
    <div v-for="metaset in metaSets" :key="metaset.id" :value="metaset"
         class="col row dflex flex-column">
      <div class="d-flex">
        <div class="mr-3" @click="toggleMetaset(metaset)">
          <icon v-if="!metaset.collapsed" class="tree-collapse" size="1.4" type="angle-down"></icon>
          <icon v-if="metaset.collapsed" class="tree-collapse" size="1.4" type="angle-right"></icon>
        </div>
        <label :for="'chkMetaSet' + metaset.id" class="container vform-editor-body ml-2"
        >{{ metaset.name }}
          <input
              :id="'chkMetaSet' + metaset.id"
              v-model="metaset.selected"
              type="checkbox"
              @change="onChange(metaset)"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div v-for="metaField in metaset.metaFields" v-if="!metaset.collapsed" :key="metaField.id" :value="metaField"
           class="col row dflex ml-4">
        <div class="dflex ml-3">
          <label :for="'chkMetaField' + metaField.id" class="container vform-editor-body"
          >{{ metaField.description }}
            <input
                :id="'chkMetaField' + metaField.id"
                v-model="metaField.selected"
                type="checkbox"
                @change="updateMetaFieldSelection"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import ArrayMixin from "@/components/mixins/ArrayMixin.js";
import {vFormControls, placeholders} from "@/enum";
import Icon from "@/components/Icon";

export default {
  name: "vFormPART_INFO",
  components: {
    Icon
  },
  props: {
    element: {type: Object, required: true},
    organizationId: {type: String, default: ""},
  },
  mixins: [vFormElementMixin, ArrayMixin],
  data() {
    return {
      forceReRenderKey: 0,
      vFormControls: vFormControls,
      placeholders: placeholders,
      infoTypesDataSource: ['metaInfo'],
      metaSets: null,
      includeSuperOrgMetasets: ["sfx.asset.model", "sfx.asset.transform", "sfx.asset.assembly"]
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls["PART_INFO"];
    if (!this.element.partInfo) {
      this.element.partInfo = "metaInfo";
    }

    this.loadMetaSets();
  },
  computed: {},
  methods: {
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: "organizationId in '" + this.organizationId + "' '" + this.$store.getters.getSuperOrg + "'"
        //filter: 'organizationId eq ' + this.organizationId
      }).then(metaSets => {
        this.metaSets = metaSets.filter((ms) => {
          return ms.organizationId === this.organizationId || (this.includeSuperOrgMetasets.find((x) => x === ms.name))
        });

        if (this.element.metaFields) {
          //load selection from vform
          this.metaSets.forEach((metaset) => {
            this.$set(metaset, 'selected', true);
            this.$set(metaset, 'collapsed', true);
            metaset.metaFields.forEach((field) => {
              if (this.element.metaFields.find((x) => {
                return x === field.id;
              })) {
                field.selected = true;
                metaset.collapsed = false;
              } else {
                metaset.selected = false;
              }
            });
          });
        } else {
          //initial add, pre-select sfx.asset.model
          this.metaSets.forEach((metaset) => {
            this.$set(metaset, 'selected', false);
            this.$set(metaset, 'collapsed', true);
          });

          let sfxAssetModel = this.metaSets.find((ms) => ms.name === "sfx.asset.model");
          if (sfxAssetModel) {
            sfxAssetModel.selected = true;
            sfxAssetModel.metaFields.forEach((field) => {
              this.$set(field, 'selected', true);
            });

            this.updateMetaFieldSelection();
          }
        }

        this.metaSets = this.metaSets.sort(this.objectComparer("name", "asc"));
      })
    },
    onChange(metaset) {
      metaset.metaFields.forEach((field) => {
        field.selected = metaset.selected
      });
      this.updateMetaFieldSelection();
    },
    toggleMetaset(metaset) {
      metaset.collapsed = !metaset.collapsed;
    },
    updateMetaFieldSelection() {
      this.element.metaFields = [];
      this.metaSets.forEach((metaset) => {
        metaset.selected = true;
        metaset.metaFields.forEach((field) => {
          if (field.selected) {
            this.element.metaFields.push(field.id);
          } else {
            metaset.selected = false;
          }
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.tree-collapse {
  position: absolute;
  top: -2px;
}
</style>