<template>
    <div class="filterSection">
        <text-input
                :class="['full-width-input', localValue.length > 0 ? 'filter-active' : '']"
                @typingAlert="filter"
                :has-keyup="true"
                :id="'contentFilterText'"
                :placeholder="$t('filter.placeholderSearch')"
                :label="label"
                :value="localValue"
                ref="textSearch"
        />
        <div v-if="message" class="form-small-message">{{ message }}</div>
    </div>
</template>

<script>
    import TextInput from "../../forms/TextInput";
    /**
     * A text-filter component (Textinput) that prepares the queryParams for registerin or loading
     * @displayName Text Filter
     */
    export default {
        name: "TextFilter",
        components: {
            TextInput,
        },
        props: {
            /**
             * Value – an initial value set by the parent component, if necessary (overwrites localFiltering)
             */
            value: {type: String, default: ''},
            /**
             * An id in order to have a unique identifier when saving filter contents to storage
             * */
            id: {type: String, required: true},
            /**
             * The label for the textfield
             * */
            label: {type: String, default: ''},
            /**
             * If value changes, the textFilter clears its value
             * */
            clear: {type: Number, default: 0},
            /**
             * If you want to search in a field instead of q, add a fieldName
             * */
            fieldName: {type: String, default: null},
        },
        data() {
            return {
                contentFilterText: '',
                message: '',
                localValue: '',
            };
        },
        computed: {
            getId: function() {
                return window.location.href + this.$route.name + this.id  + 'TextFilter';
            },
        },
        watch: {
            clear() {
                this.clearValue();
            },
        },
        beforeMount() {
            let localFilter = window.localStorage.getItem(this.getId);
            if(this.value && this.value.length) {
                this.localValue = this.value;
            }
            else if(localFilter) {
                this.localValue = localFilter;
            }
        },
        mounted() {
            if(this.localValue) {
                this.filter({value: this.localValue}, true);
            }
            else {
                this.filter({value: ''}, true);
            }
        },
        methods: {
            clearValue: function() {
                this.filter({value: ''}, true);
            },
            getFilterParams(args) {
              let filterValue = args.value;
              this.localValue = args.value;
              this.message = '';
              /*if(filterValue.length < 2 && filterValue.length > 0) {
                this.message = this.$t('errors.atLeastThreeCharacters');
                return;
              }*/
              if(this.fieldName) {
                if (filterValue.length > 0) {
                  return {
                    add: {
                      filter: {
                        [this.getId]: `${this.fieldName} like *${filterValue}*`
                      }
                    }
                  };
                } else if (filterValue.length === 0) {
                  return {
                    remove: {
                      filter: [this.getId]
                    }
                  };
                }
              }
              else {
                if (filterValue.length > 2) {
                  return {
                    add: {
                      q: {
                        [this.getId]: '' + filterValue + ''
                      }
                    }
                  };
                } else if (filterValue.length === 0) {
                  return {
                    remove: {
                      q: [this.getId]
                    }
                  };
                }
              }

            },
            filter: function (args, initial = false) {
                let params = this.getFilterParams(args);
                /**
                 * sends the filtering content to the parent component
                 * ready to use in a register or loading method
                 * */
                this.$emit('filter', params, initial, this.getId);
                window.localStorage.setItem(this.getId, this.localValue);
            },
        },
    }
</script>