<template>
<div class="form-wizard">
  <div :class="`form-part form-part-${index} ${index === currentSlot ? 'active' : ''}`" v-for="index in numberOfSlots" :key="index">
    <slot :name="`slot-${index}`"/>
  </div>
</div>
</template>

<script>
export default {
  name: "FormWizard",
  props: {
    numberOfSlots: {type: Number, default: 1},
    currentSlot: {type: Number, default: 1}
  }
}
</script>

<style lang="scss" scoped>
  .form-part {
    position:absolute;
    left: -100000px;
    opacity: 0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    &.active {
      opacity: 1;
      position:static;
      left: auto;
    }
  }
</style>