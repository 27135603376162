<template>
    <div>
        <popup
                @close="$emit('abort')"
        >
            <div slot="popupMainContent">
                <h1>{{ $t('edit' + storeName) }}</h1>
               <!--<div class="inline-edit-label">
                    <b>{{ $t('Title') }}</b>
                    <div class="inline-edit">
                        <Icon :type="'edit'"/>
                    </div>
                </div>
                <input class="form-text"
                       :type="text"
                       ref="title"
                       @keydown.enter="save"
                       v-model="title"
                />

                <div class="inline-edit-label">
                    <b>{{ $t('description') }}</b>
                    <div class="inline-edit">
                        <Icon :type="'edit'"/>
                    </div>
                </div>
                <div>
                    <input class="form-text"
                           :type="text"
                           ref="desc"
                           @keydown.enter="save"
                           v-model="description"
                    />
                </div>-->
                <div class="row" v-if="metaSets">
                    <meta-panel
                               class="col-12 col-md-6 col-xl-10 col-xxl-8"
                               :store-name="storeName + 'Instance'"
                               :cid="id"
                               :key="item.id"
                               v-for="(item) in metaSets"
                               :title="item.description"
                               :fields="item.metaFields"
                               icon-left="folder"
                               :organization-ids="[$store.getters['get' + storeName + 'Organization'](id)]"
                                @updated="load"
                               :initially-collapsed="false"
                    />
                </div>
            </div>

            <!--<Button
                    slot="popupFooter"
                    class="mb-3"
                    :deactivated="!enableSaving"
                    v-on:click="save"
            >
                <i v-if="enableSaving" class="far fa-save"></i><i v-else class="fa fa-ban" aria-hidden="true"></i>
                {{$t('SAVE')}}
            </Button>-->
        </popup>
    </div>
</template>

<script>
    import Popup from "../Popup";
    import MetaPanel from "../widgets/meta/MetaPanel";
    import {combineAssetAndInstanceMetaValues} from "@/utils";

    export default {
        name: "EditInstanceForm",
        components: {
            Popup,
            MetaPanel,
        },
        props: {
            id: {type: String, required:true},
            projectId: {type: String, required: true},
            storeName: {type: String, required: true},
        },
        data() {
            return {
                listName: this.projectId + 'SingleInstance',
                title: '',
                description: '',
                enableSaving: false,
                metaSets: null,
            };
        },
        watch: {
            title() {
                if(this.title.length > 0) {
                    this.enableSaving = true;
                }
            },
        },
        beforeMount() {
            this.load();
        },
        methods: {
            load: function() {
                this.$store.dispatch('load' + this.storeName + 'Instance', {
                    id: this.projectId,
                    cid: this.id,
                    listName: this.listName,
                    include: ['assetAndMetaSetsBugfix', 'metaValues'],
                }).then(instance => {
                  this.metaSets = combineAssetAndInstanceMetaValues(instance.asset.metaSets, instance.metaValues);
                });
            },
        },
    }
</script>

<style scoped>

</style>