class DataProcessor {
    constructor(type) {
        this.type = type;
    }
    async init() {
        this.processor = await import(`@/components/fileProcessors/${this.type}.js`);
    }
    async getColumnNames(data) {
        return await this.processor.getColumnNames(data);
    }
    async getData(data) {
        return await this.processor.getData(data);
    }
}

export default DataProcessor;