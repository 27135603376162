<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-9 mt-5 offset-md-2 offset-lg-3">
                <div v-if="localRoutes && finished" id="library-tabs" class="float-right float-lg-none d-flex">
                    <navigation-panel
                            :class="[item.meta.spacer ? 'mr-3' : '']"
                            v-for="(item, key) in localRoutes"
                            v-if="item.access"
                            :title="item.name"
                            :link="item.path"
                            :routename="item.name"
                            :pid="id"
                            :translate="true"
                            :disabled="disabled"
                            :active-class="firstActive && item.firstItem ? 'active' : ''"
                            :icon="item.meta.icon"
                            :key="key"
                            :props="item.params"
                    />
                </div>
            </div>
            <div class="col-12 sub-menu-panel-background"/>
        </div>
    </div>
</template>

<script>
    import NavigationPanel from "./NavigationPanel";
    export default {
        name: 'NavigationPanelsContainer',
        components: {
            NavigationPanel,
        },
        props: {
            id: {type: String, required: true},
            disabled: {type: Boolean, default: false},
            arrowStyle: {type: Boolean, default: false},
            firstActive: {type: Boolean, default: false},
            tabSelector: {type: String, required: true}
        },
        data() {
          return {
            localRoutes: [],
            finished: false,
          };
        },
        beforeMount() {
          if(!this.localRoutes.length) {
            this.setRoutes();
          }
        },
        methods: {
           async setRoutes() {
              this.finished = false;
              const routes = this.$router.options.routes;
              this.localRoutes = routes.filter(item => {
                  return item[this.tabSelector];
              });
              for(let i = 0; i < this.localRoutes.length; i++) {
                const route = this.localRoutes[i];
                const meta = route.meta;
                route.access = true;
                if(meta.accessPermission && meta.staticPermission) {
                  route.access = await this.$store.dispatch('checkTeamOrgPermission', {
                    op: this.localRoutes[i].meta.accessPermission,
                    opOnly: true,
                    orgPermissionOnly: meta.orgPermissionOnly ? meta.orgPermissionOnly : false,
                    allowSFXUsers: meta.allowSFXUsers,
                    allowSFXBilling: meta.allowSFXBilling
                  });
                } else if(meta.servicePermission) {
                  route.access = (this.$store.getters.isSuperAdmin || this.$store.getters.isSFXMember) ? true : await this.$store.dispatch('checkIfService', {
                    organizationIds: this.$store.getters.getCurrentUserOrgs.map(item => {return item.id}),
                    serviceName: meta.servicePermission
                  });

                }
              }
              this.finished = true;
            },
        }
    }
</script>