import Vue from 'vue'
import i18n from "../translation";

const state = {
    tagLists: {
        tagList: {},
    },
    tagListOptions: {},
    defaultQueryParams: {

    },
    queryParams: {
        tagList: {
            limit: {
                default: '50',
            },
            sort: {
                default: '-updatedAt',
            },
        }
    },
    combinedQueryParams: {},
    /**
     * Used keys so far:
     * last_inserted
     * */
    tagTmp: {
    },
};
const getters = {
    getTagQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getTagOriginalQueryParams: function() {
        return state.queryParams;
    },
    getTagName: (state) => (id, listName = 'tagList') => {
        return state.tagLists[listName] && state.tagLists[listName][id] && state.tagLists[listName][id].name ? state.tagLists[listName][id].name : '';
    },
    getTags() {
        return state.tagLists.tagList;
    },
};
const mutations = {
    loadTagsMutation(state, data) {
        Vue.delete(state.tagLists.tagList);
        Vue.set(state.tagLists, 'tagList', {});
        for (var i = 0; i < data.length; i++) {
            let tag = data[i];
            Vue.set(state.tagLists.tagList, tag.id, tag);
        }
    },
};
const actions = {
    registerTagsQueryParams: ({commit}, args) => {
        let listName = 'tagList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'tag', listName: listName, params: args});
        }
    },
    loadTags: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName : 'tagList';
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'tag', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'tag', listName: listName});
        dispatch('clientLoadTags', getters.getTagQueryParams(listName)).then(data => {

            /*for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/tags/' + data[i].id + '/general';
            }*/
            commit('loadTagsMutation', data);

            /*added pagination part*/
            if(getters.getTagQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateTagListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
        });
    },
    createTag: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateTag', args)
            .then(data => {
                commit('loadTagsMutation', data);
                //commit('setTagTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.tagCreated')});
                return data;
            });
    },
    updateTag: async ({dispatch}, args) => {
        return dispatch('clientUpdateTag', args)
            .then(() => {
                dispatch('loadTags');
                dispatch('createNotification', {'text': i18n.t('events.tagUpdated')});
            });
    },
    deleteTag: async ({dispatch}, args) => {
        return dispatch('clientDeleteTag', args)
            .then(() => {
                dispatch('loadTags');
                //commit('setTagTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.tagDeleted')});
            });
    },
    checkIfTagNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        return dispatch('clientLoadTags', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId],
            fields: 'name,id',
        });
    },
};
export default {
    state, getters, mutations, actions
}
