<template>
  <div class="row">
    <div v-if="!displayName" class="col-12 col-md-8 mb-3">
      <div class="name-placeholder d-inline-block mt-2"/>
      <loading-spinner class="mt-2"/>
    </div>
    <div v-else class="col-12 col-md-8 mb-3">
      <div v-if="!nameEdit" class="name-edit">
        <h1 class="inline-block mr-2">
          <icon v-if="icon" :type="icon" class="mr-2" size="0.9"/>
          {{ displayName }}
        </h1>
        <div v-if="editable" class="edit-trigger mb-1 lighter" @click.prevent="editname">
          <icon type="edit"/>
        </div>
      </div>
      <div v-else>
        <div class="vform-label white">{{ $t('displayName') }}</div>
        <input ref="displayName"
               v-model="updateDisplayName"
               :class="['form-text', error ? 'form-group--error' : '']"
               type="text"
               @input="delayTouch($v.updateDisplayName)"
               @keyup.enter="updateContent"
               @keyup.esc="cancelEditing"
        >
        <div v-if="error" class="form-error">{{ $t(error) }}</div>
        <div v-if="$v.updateDisplayName.$dirty && !$v.updateDisplayName.required && $v.updateDisplayName.$error"
             class="form-error">
          {{ $t('errors.required') }}
        </div>
        <div v-if="$v.updateDisplayName.$dirty && !$v.updateDisplayName.alphaNumSpace" class="form-error">
          {{ $t('errors.alphaNumSpaceOnly') }}
        </div>

        <div v-if="$v.updateDisplayName.$dirty && !$v.updateDisplayName.maxLength" class="form-error">
          {{ $t('errors.atMostCharacters', {num: 128}) }}
        </div>

        <div v-if="$v.updateDisplayName.$dirty && !$v.updateDisplayName.minLength" class="form-error">
          {{ $t('errors.atLeastCharacters', {num: 3}) }}
        </div>

        <div class="vform-label white mt-3">{{ $t('Name') }}</div>
        <input ref="name"
               v-model="updateName"
               :class="['form-text', error ? 'form-group--error' : '']"
               type="text"
               @input="delayTouch($v.updateName)"
               @keyup.enter="updateContent"
               @keyup.esc="cancelEditing"
        >
        <div v-if="$v.updateName.$dirty && !$v.updateName.isUnique" class="bg-dark p-1 mb-1">
          {{ $t('Suggestion') }}: {{ proposedNewName }}
        </div>
        <div v-if="error" class="form-error">{{ $t(error) }}</div>
        <div v-if="$v.updateName.$dirty && !$v.updateName.required && $v.updateName.$error" class="form-error">
          {{ $t('errors.required') }}
        </div>
        <div v-if="$v.updateName.$dirty && !$v.updateName.alphaNumSpace" class="form-error">
          {{ $t('errors.alphaNumSpaceOnly') }}
        </div>

        <div v-if="$v.updateName.$dirty && !$v.updateName.maxLength" class="form-error">
          {{ $t('errors.atMostCharacters', {num: 128}) }}
        </div>

        <div v-if="$v.updateName.$dirty && !$v.updateName.minLength" class="form-error">
          {{ $t('errors.atLeastCharacters', {num: 3}) }}
        </div>
        <div v-if="$v.updateName.$dirty && !$v.updateName.isUnique" class="form-error">
          {{ $t('errors.objectNameAlreadyExists') }}
        </div>

        <div class="edit-trigger mr-2" @click="cancelEditing">
          <icon :type="'times'"/>
        </div>
        <div v-if="enableSaving" class="edit-trigger" @click="updateContent">
          <icon :type="'save'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../customValidators';
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import LoadingSpinner from "./LoadingSpinner";

export default {
  name: "NameDisplayEditBlock",
  components: {
    Icon,
    LoadingSpinner
  },
  mixins: [VuelidateMixin],
  props: {
    name: {type: String, default: ''},
    displayName: {type: String, default: ''},
    id: {type: String, required: true},
    storeName: {type: String, required: true},
    organizationId: {type: String, default: ''}, //necessary in most cases /except users
    icon: {type: String, default: null},
    editable: {type: Boolean, default: true}
  },
  data() {
    return {
      nameEdit: false,
      error: null,
      updateDisplayName: null,
      updateName: null,
      enableSaving: true,
      proposedNewName: null
    };
  },
  validations: {
    updateDisplayName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace
    },
    updateName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '' || value === this.name) {
          return true;
        }
        let bool = true;
        let args = {value: value, name: value};
        if (this.organizationId) {
          args.organizationId = this.organizationId;
        }
        await this.$store.dispatch('checkIf' + this.storeName + 'NameExists', args).then(data => {
          bool = data.length === 0;
        });
        if (!bool) {
          await this.proposeNewName(value);
        }
        return bool;
      },
    }
  },
  watch: {
    displayName(val) {
      if (val) {
        this.setValue();
      }
    },
    updateDisplayName() {
      this.error = null;
    },
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    async proposeNewName(name) {
      this.proposedNewName = "";
      let nameBase = name.split('-')[0];
      let counter = name.split('-')[1] || 0;
      let tmpString = name;
      let p;
      let args = {value: tmpString, name: tmpString};
      if (this.organizationId) {
        args.organizationId = this.organizationId;
      }
      /* eslint-disable no-constant-condition */
      while (true) {
        p = await this.$store.dispatch('checkIf' + this.storeName + 'NameExists', args);

        if (p.length) {
          counter++;
          tmpString = `${nameBase}-${counter}`;
          args.value = tmpString;
          args.name = tmpString;
        } else {
          break;
        }
      }
      this.proposedNewName = tmpString;
      return tmpString;
    },
    setValue() {
      this.updateDisplayName = this.displayName;
      this.updateName = this.name;
      this.proposedNewName = "";
    },
    editname: function () {
      this.nameEdit = true;
    },
    cancelEditing: function () {
      this.nameEdit = false;
      this.updateDisplayName = this.displayName;
    },
    updateContent() {
      if (this.enableSaving) {
        let args = {
          id: this.id,
          displayName: this.updateDisplayName,
          name: this.updateName,
        };
        this.error = null;
        this.$store.dispatch('update' + this.storeName, args)
            .then(data => {
              console.log(data);
              const {name, displayName} = data;
              this.updateName = name;
              this.updateDisplayName = displayName;
              if (!this.error) {
                this.nameEdit = false;
              }
              this.$emit('updated');
            }).catch(res => {
              if (res.response.errors.errorCode === 1003) {
                this.error = this.$t('errors.duplicateNameForAsset');
              } else {
                this.error = res.response.errors.message;
              }
        })
      } else if (this.updateDisplayName === this.displayName) {
        this.nameEdit = false;
      }
      this.proposedNewName = "";
    }
  },
}
</script>

<style lang="scss" scoped>
.name-edit {
  position: relative;
  word-break: break-all;
  display: inline-block;
  padding-right: 20px;

  .edit-trigger {
    right: 0;
    position: absolute;
    margin-top: -3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }

}

.form-text {
  width: 100%;
}

.name-placeholder {
  width: 250px;
  height: 40px;
  background-color: var(--vform-editor-ui-secondary-color);
  filter: blur(1px);
}
</style>