<template>
    <div class="p-5">
           <language-switcher pre-set-lang="en" class="ml-3 mb-3 mt-5" />
           <div v-if="$store.getters.getCurrentUserId && !$store.getters.getCurrentUserGDPR" class="col-12 col-md-8 mt-5 bg-beige p-3 ml-3 mb-3">{{ $t('gdprHint') }}</div>
            <div class="col-12 col-md-8" v-html="$t('gdpr')"></div>
          <div v-if="$store.getters.getCurrentUserId && !$store.getters.getCurrentUserGDPR" class="col-12 col-md-8 mt-5 bg-beige p-3 ml-3">
            <div class="mb-3" v-html="$t('gdprExplanation')"></div>
            <Button class="mr-3" @click="logout">{{ $t('Deny') }}</Button><Button @click="accept">{{ $t('Accept') }}</Button>
          </div>
        </div>
</template>

<script>
    import Button from "@/components/forms/Button";
    import LanguageSwitcher from "@/components/navigation/LanguageSwitcher";
    export default {
        name: 'GDPR',
        components: {
          Button,
          LanguageSwitcher
        },
      methods: {
      logout() {
        this.$router.push('/');
        this.$store.dispatch('authLogout');
      },
      accept() {
        this.$store.dispatch('clientSaveUser', {
          id: this.$store.getters.getCurrentUserId,
          gdprAccepted: true
        }).then(() => {
          this.$store.dispatch('checkLogin');
        }).then(() => {
          this.$router.push('/project');
        })
      }
    }
    }
</script>