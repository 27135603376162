<template>
  <div>
    <div class="inline-edit-label">
      {{ $t(label) }}
      <div v-if="!descEdit" class="edit-trigger" @click.prevent="editDesc">
        <icon :type="'edit'"/>
      </div>
    </div>
    <div v-if="!descEdit">
      <p>{{ value }}</p>
    </div>
    <div class="mb-3" v-else>
      <input
          v-if="inputType === 'text'"
          type="text"
          ref="description"
          class="form-text"

          @keydown.esc="cancelEditing"
          v-model="updateValue">
      <textarea v-else-if="inputType === 'textarea'"
                type="text"
                ref="description"
                class="form-text"
                @keydown.esc="cancelEditing"
                v-model="updateValue" />
      <div class="edit-trigger mr-2" @click="cancelEditing">
        <icon :type="'times'"/>
      </div>
      <div class="edit-trigger" @click="updateContent">
        <icon :type="'save'"/>
      </div>
      <div class="form-error" v-if="error">{{ $t(error) }}</div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";
export default {
  name: "ContentTitleBlock",
  components: {
    Icon,
  },
  props: {
    value: { type: String, default: null },
    id: {type: String, required: true},
    storeName: {type: String, required: true},
    fieldName: {type: String, required: true},
    label: {type: String, default: 'field'},
    listName: {type: String, default: null},
    inputType: {type: String, default: 'text'},
    trim: {type: Boolean, default: false},
    icon: {type: String, default: null},
  },
  data() {
    return {
      descEdit: false,
      updateValue: null,
      error: ''
    };
  },
  watch: {
    value(val) {
      if(val) {
        this.updateValue = this.trim ? this.value.trim() : this.value;
      }
    }
  },
  beforeMount() {
    this.updateValue = this.value;
  },
  methods: {
    editDesc: function () {
      this.descEdit = true;
    },
    cancelEditing: function () {
      this.descEdit = false;
      this.updateValue = this.value;
    },
    updateContent() {
      this.error = '';
      let args = {
        id: this.id,
        [this.fieldName]: this.trim ? this.updateValue.trim() : this.updateValue,
      };
      if(this.listName) {
        args.listName = this.listName;
      }
      this.$store.dispatch('update' + this.storeName, args).catch(e => {
        //console.log(e.message);
        this.error = e;
      });
      this.descEdit = false;
      this.$emit('update');
    }
  },
}
</script>

<style scoped>

</style>