<template>
  <div>
    <info-helper v-if="editable" class="ml-1 float-right" text="Set all to 0 for lifetime that lasts forever" />
    <label-with-edit-icon-and-content
                label="RowLifetime"
                :value="value"
                :show-value="false"
                @edit="editMode = true"
                :editable="editable"
                v-if="!hideLabel"
        />
    <div v-if="editMode || editing">
      <div class="d-flex">
          <div class="mr-1">
          {{ $t('Days') }}
          <input class="form-text" type="number" v-model="days">
        </div>
        <div class="mr-1">
          {{ $t('Hours') }}
          <input class="form-text" type="number" v-model="hours">
        </div>
        <div class="mr-1">
          {{ $t('Minutes') }}
          <input class="form-text" type="number" v-model="minutes">
        </div>
        <div class="mr-1">
          {{ $t('Seconds') }}
          <input class="form-text" type="number" v-model="seconds">
        </div>
      </div>
      <div class="w-100 mb-1" />
       <div class="edit-trigger mr-2" @click="editMode = false">
        <icon :type="'times'"/>
      </div>
      <div class="edit-trigger" @click="saveValue">
        <icon :type="'save'"/>
      </div>
    </div>
    <div v-else-if="compact">
      {{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s<br />
      until: {{ dateTimeFromUnix(parseInt(Date.now()) + parseInt(total()) * 1000) }}
    </div>
    <div v-else>{{ days }} {{ $t('Days') }}<br/>{{ hours }} {{ $t('Hours') }}<br/>{{ minutes }} {{ $t('Minutes') }}<br/>{{ seconds }} {{ $t('Seconds') }}</div>
  </div>
</template>

<script>
import LabelWithEditIconAndContent from "@/components/LabelWithEditIconAndContent";
import Icon from "@/components/Icon";
import InfoHelper from "@/components/InfoHelper";
import DateMixin from "@/components/mixins/DateMixin.js";
export default {
  name: "LifetimeSetter",
  components: {
    LabelWithEditIconAndContent,
    Icon,
    InfoHelper
  },
  mixins: [DateMixin],
  props: {
    value: {type: Number, default: 0},
    editable: {type: Boolean, default: true},
    compact: {type: Boolean, default: false},
    editing: {type: Boolean, default: false},
    hideLabel: {type: Boolean, default: false},
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      editMode: false,
    };
  },
  watch: {
    value() {
      this.setTime();
    }
  },
  beforeMount () {
    this.setTime();
  },
  methods: {
    total() {
      return ((this.days * 24 * 60 * 60) + (this.hours * 60 * 60) + (this.minutes * 60) + (this.seconds * 1))
    },
    setTime() {
      if(this.value) {
        this.days = this.hours = this.minutes = this.seconds = 0;
        let tmp = this.value;
      this.days = Math.floor(tmp / 24 / 60 / 60);
      tmp = tmp - this.days * 24 * 60 * 60;
      this.hours = Math.floor(tmp / 60 / 60);
      tmp = tmp - this.hours * 60 * 60;
      this.minutes = Math.floor(tmp / 60);
      tmp = tmp - this.minutes * 60;
      this.seconds = tmp;
    }
    },
    saveValue() {
      this.$emit('setValue', this.total());
      this.editMode = false;
    }
  }
}
</script>
