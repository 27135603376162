<template>
  <div class="col-12">
    <upload-drop-zone
        ref="metaDataUpload"
        :accepted-file-types="Object.values(metaDataFileFormats).map((item) => {return '.' + item.format;}).join(',')"
        :asset-id="$route.params.id"
        :auto-update-preview="false"
        :auto-upload="false"
        :class="[!metaDataColumns ? 'col-12 col-md-8' : 'col-6 col-md-6', 'mt-3']"
        :downloadable="false"
        :image-editable="true"
        :is-loading="isLoading"
        :removable="false"
        asset-type="assembly"
        form-id="assemblyUpdate"
        target-type="Asset"
        @data="setData"
        @fileReceived="fileReceived"
        @filesInQueue="filesInQueue"
        @queueEmpty="queueEmpty"
    >
      <div class="mt-3">
        {{ $t("uploadMetaFile") }}
      </div>
    </upload-drop-zone>
    <div v-if="fileContent" class="col-12 col-md-8">
      <label class="lighter mt-2">{{ $t("PostProcessingForMeta") }}</label><br/>
      <select
          v-model="vhubPreProcessor"
          class="custom-select dark"
          @change="parseData"
      >
        <option
            v-for="(key, value) in vhubPreProcessorOptions"
            :key="key"
            :value="value"
        >
          {{ $t(key.name) }}
        </option>
      </select>
      <assembly-config
          v-if="metaDataColumns.length"
          ref="AssemblyConfig"
          :meta-data-columns="metaDataColumns"
          :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
          :teams="$store.getters.getAssemblyTeams($route.params.id)"
          permission="writeMetaSet"
          @setConfig="setMetaConfig"
      />
    </div>
    <div
        v-if="Object.keys(metaConfig).length"
        class="col-12 col-md-6">
      <label>{{ $t("Modus") }} </label>
      <info-helper class="ml-1" text="MetaUpdateMode"/>
      <tile-button
          ref="tiles"
          :initial-active-tiles="[metaSetUpdateModes[0]]"
          :tiles="metaSetUpdateModes"
          :values="metaSetUpdateModes"
          @trigger="selectMode"
      />
    </div>
    <div
        v-if="Object.keys(metaConfig).length"
        class="form-section col-12"
    >
      <div class="form-item mt-3">
        <input
            id="sendMails"
            v-model="sendMails"
            name="sendMails"
            type="checkbox"
        />
        <label class="ml-1" for="sendMails">{{
            $t("GetEmailNotification")
          }}</label
        >
        <info-helper
            class="ml-1"
            text="Emails werden nur beim Extract-Prozess versendet, nicht beim Reimport"
        />
      </div>
      <label>{{ $t("email") }}</label>
      <input
          v-model="email"
          :class="['form-text w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '',]"
          type="text"
          @input="delayTouch($v.email)"
      />
      <div
          v-if="$v.email.$dirty && !$v.email.email"
          class="form-error mb-2"
      >
        {{ $t("errors.mustBeAValidEmailAdress") }}
      </div>
      <Button
          :deactivated="!this.$v.email.email || partIdentifierColumnMissing"
          class="mt-3 icon-rotate-animation make-longer-animation"
          @click="startMetaSetUpdate"
      >{{ $t("XMLUpdateStart") }}
        <icon type="cube"/>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "../forms/Button";
import Icon from "../Icon";
import AssemblyConfig from "@/components/assemblies/MetaConfig";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import UploadDropZone from "@/components/files/UploadDropZone";
import {decodeArrayBuffer} from "@/utils";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {metaDataFileFormats, metaSetUpdateModes, TaskManagerTaskTypes} from "@/enum";
import {email} from "vuelidate/lib/validators";
import DataProcessor from "../fileProcessors/DataProcessor";

export default {
  name: "MetaDataUpdater",
  components: {
    AssemblyConfig,
    UploadDropZone,
    TileButton,
    InfoHelper,
    Button,
    Icon,
  },
  mixins: [VuelidateMixin],
  props: {
    remoteContent: {type: Object, required: true},
    canUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      partIdentifierColumnMissing: true,
      /**
       * will be set by the meta-config component – is the content.pipeline.meta-Object
       * */
      metaConfig: {},
      /**
       * the columns from the meta file (which is usually a table of some sort)
       * */
      metaDataColumns: [],
      /**
       * Sets the dropzone to loading-state while processing data
       * */
      isLoading: false,
      /**
       * the available file formats = processors for meta-files (currently Windchill Search Res only)
       * */
      metaDataFileFormats: metaDataFileFormats,
      vhubPreProcessorOptions: metaDataFileFormats,

      /**
       * The preprocessor will be set as
       * */
      vhubPreProcessor: "", //Object.keys(metaDataFileFormats)[0],

      /**
       * the mode to use for either overwriting/extending or reset the meta-data
       * */
      mode: "extend",
      metaSetUpdateModes: metaSetUpdateModes,

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
      sendMails: false,

      content: {},
      dataProcessor: null,
      fileContent: null
    };
  },
  validations: {
    email: {
      email,
    },
  },
  mounted() {
    this.content = this.remoteContent;
  },
  methods: {
    setMetaConfig(metaConfig) {
      this.metaConfig = metaConfig;
      this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
    },
    // 1 resume upload-dropzone upload which triggers 2
    startMetaSetUpdate() {
      this.$refs.metaDataUpload.resumeUpload();
    },
    // 2 when file is uploaded, start update of sfx content and
    // 3 start the pipeline task "dataprocessing"
    async fileReceived(assetId, fileName) {
      this.content.pipeline.files = [`${assetId}/${fileName}`];
      this.$emit('processing')
      await this.updateAssemblyContent(fileName)
          .then(async () => {
            await this.startUpdate();
            this.isLoading = false;
          });
    },
    async startUpdate() {
      return await this.$store.dispatch("clientAssetToPipeline", {
        id: this.$route.params.id,
        type: TaskManagerTaskTypes.DATAPROCESSING,
      })
    },
    setData(data) {
      this.fileContent = data;
      this.isLoading = false;
    },
    async parseData() {
      this.dataProcessor = new DataProcessor(this.vhubPreProcessor);
      await this.dataProcessor.init();
      const file = decodeArrayBuffer(this.fileContent);
      this.metaDataColumns = await this.dataProcessor.getColumnNames(file);
    },
    // todo: needed?
    filesInQueue: function () {
      this.isLoading = true;
    },
    queueEmpty: function () {
      this.metaConfig = {};
      this.metaDataColumns = [];
    },
    async updateAssemblyContent(clientMetaFile) {
      if (this.$refs.AssemblyConfig) {
        await this.$refs.AssemblyConfig.save();
      }
      if (this.vhubPreProcessor && this.vhubPreProcessor !== "none") {
        this.content.pipeline.dataProcessor = this.vhubPreProcessor;
      }
      this.content.pipeline.sendMails = this.sendMails;
      this.content.pipeline.email = this.email;
      this.content.pipeline.lang = this.$i18n.locale ? this.$i18n.locale : "en";
      this.content.pipeline.meta = {
        updateMode: this.mode,
        clientMetaFile: clientMetaFile,
        ...this.metaConfig,
      };
      return await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id
      }).then(async () => {
        return await this.$store.dispatch("updateAssembly", {
          id: this.$route.params.id,
          content: JSON.stringify(this.content),
        });
      });
    },
    selectMode(params) {
      this.mode = params.value;
    },
  }
}
</script>