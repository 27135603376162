<template>
    <div class="row">
        <!--<content-title-block
                :value="$store.getters.getOrganizationName(organizationId)"
                :id="$route.params.id"
                store-name="Organization"
        />-->
      <div class="col-12 col-lg-3 col-xxl-4">
        <h1>{{ $store.getters.getOrganizationDisplayName(organizationId) }}</h1>
        <p>({{ $store.getters.getOrganizationName(organizationId) }})</p>
        <org-visibility-dropdown
            class="mt-1 mb-3"
            :initial-value="visibility"
            @update="update"
            v-if="$store.getters.isSuperAdmin"
        />
        <slot />
        <label-with-edit-icon-and-content
            class="mt-5"
            label="displayName"
            :value="displayName"
            v-if="!isEditing('displayName')"
            @edit="setEditing('displayName')"
        />
        <div v-if="isEditing('displayName')">
          <input
              type="text"
              :class="['form-text', $v.displayName.$error ? 'form-group--error' : '']"
              v-model="displayName"
              @blur="$v.displayName.$touch"
          >
          <div class="edit-trigger mr-2" @click="cancelEditing()">
            <icon :type="'times'"/>
          </div>
          <div class="edit-trigger" @click="update('displayName')">
            <icon :type="'save'"/>
          </div>
          <div class="form-error" v-if="!$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
        </div>

        <div class="w-100"/>

        <div class="">
          <label-with-edit-icon-and-content
              label="url"
              :value="url"
              v-if="!isEditing('url')"
              @edit="setEditing('url')"
          />
          <div v-if="isEditing('url')">
            <input
                type="text"
                :class="['form-text', $v.url.$error ? 'form-group--error' : '']"
                v-model="url"
                @keydown="$v.url.$touch">
            <div class="edit-trigger mr-2" @click="cancelEditing()">
              <icon :type="'times'"/>
            </div>
            <div class="edit-trigger" @click="update('url')">
              <icon :type="'save'"/>
            </div>
            <div class="form-error" v-if="$v.url.$error">{{ $t('errors.mustBeAValidURL') }}</div>
          </div>
          <label-with-edit-icon-and-content
              label="owner"
              v-if="!isEditing('ownerId')"
              @edit="setEditing('ownerId')"
              :make-placeholder-space="false"
              :show-value="false"
          />
          <!--<content-display
              class="label-with-icon"
              :list-name="$route.params.id"
              type="user"
              :value="ownerId"
              v-if="!isEditing('ownerId') && ownerId"
          />
          <div v-if="isEditing('ownerId')">
            <content-list
                :value="ownerId"
                type="user"
                :list-name="$route.params.id"
                @save="updateOwner"
            />
          </div>-->
        </div>
        <div class="">
          <label-with-edit-icon-and-content
              label="email"
              :value="email"
              v-if="!isEditing('email')"
              @edit="setEditing('email')"
          />
          <div v-if="isEditing('email')">
            <input
                type="text"
                :class="['form-text', $v.email.$error ? 'form-group--error' : '']"
                v-model="email"
                @blur="$v.email.$touch"
            >
            <div class="edit-trigger mr-2" @click="cancelEditing()">
              <icon :type="'times'"/>
            </div>
            <div class="edit-trigger" @click="update('email')">
              <icon :type="'save'"/>
            </div>
            <div class="form-error" v-if="!$v.email.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
          </div>

          <label-with-edit-icon-and-content
              label="billingEmail"
              :value="billingEmail"
              v-if="!isEditing('billingEmail')"
              @edit="setEditing('billingEmail')"
          />
          <div v-if="isEditing('billingEmail')">
            <input
                type="text"
                :class="['form-text', $v.billingEmail.$error ? 'form-group--error' : '']"
                v-model="billingEmail"
                @blur="$v.billingEmail.$touch"
            >
            <div class="edit-trigger mr-2" @click="cancelEditing()">
              <icon :type="'times'"/>
            </div>
            <div class="edit-trigger" @click="update('billingEmail')">
              <icon :type="'save'"/>
            </div>
            <div class="form-error" v-if="!$v.billingEmail.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
          </div>
        </div>
        <div>
          <div class="mt-3">
            <label-with-edit-icon-and-content
                label="appPanel"
                :value="appPanel"
                v-if="!isEditing('appPanel')"
                @edit="setEditing('appPanel')"
                :make-placeholder-space="false"
            />
            <div v-if="isEditing('appPanel')">
              <input
                  class="form-text"
                  type="text"
                  v-model="appPanel" >
              <div class="edit-trigger mr-2" @click="cancelEditing()">
                <icon :type="'times'"/>
              </div>
              <div class="edit-trigger" @click="update('appPanel')">
                <icon :type="'save'"/>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <info-panel
                preview-id="metasetattacher"
                :collapsible="true"
                :initially-collapsed="true"
                info-panel-header="sfx edit"
                v-if="$store.getters.isSuperAdmin"
            >
              <meta-set-attacher
                  :target-id="$route.params.id"
                  store-name="Organization"
                  @added="loadOrganization"
                  @removed="loadOrganization"
                  slot="info"
                  :given-meta-sets="metaSets"
              />
            </info-panel>
          </div>
          <meta-panel
              v-if="metaSets && (item.id !== 'c02ee4af-2438-4f0e-a91d-a7c1d76f2937' || $store.getters.isSFXMember || $store.getters.isSuperAdmin)"
              :key="item.id" v-for="(item) in metaSets"
              :title="item.description" :fields="item.metaFields" icon-left="folder"
              store-name="Organization"
              :organization-ids="[$route.params.id]"
              @updated="loadOrganization"
          />
          <info-panel
              class="mt-4"
              preview-id="storage"
              collapsible="true"
              :initially-collapsed="true"
              v-if="$store.getters.isSuperAdmin"
          >
            <div slot="info">
              <div class="settings-button d-inline-block" @click="loadStorageUsage">{{ $t('LoadStorageUsage') }} <loading-spinner class="float-right ml-2" v-if="loadingStorageUsage" /></div><br />
              <div class="mt-2"><div class="lighter">{{ $t('StorageUsage') }}</div><icon type="hdd" /> {{ storageUsage ? storageUsage : '-' }}</div>
              <div class="settings-button d-inline-block mt-3" @click="loadStats">{{ $t('LoadStats') }} <loading-spinner class="float-right ml-2" v-if="loadingStats" /></div><br />
              <div class="mt-2">
                <table class="mt-1 mb-1">
                  <tr>
                    <th>{{ $t('Assembly') }}</th>
                    <th>{{ $t('3D-Instances') }}</th>
                  </tr>
                  <tr :key="index" v-for="(project, key, index) in projects">
                    <td><span class="lighter">{{ project.name }}:</span></td><td>{{ project.length }}</td>
                  </tr>
                </table>
                <span class="lighter"><icon type="cube" /> {{ $t('Total 3D-Instances') }}: </span>{{ totalProjects }}<br />
                <span class="lighter"><icon type="cube" /> {{ $t('3D-Assets') }}:</span> {{ totalAssets }}<br />
                <span class="lighter"><icon type="cube" /> {{ $t('Media-Assets') }}: </span>{{ mediaAssets }}<br />
              </div>
            </div>
          </info-panel>
        </div>
        </div>
      <div class="col-12 col-lg-9 col-xxl-8">

      <div class="">
        <service-to-organization :organization-id="organizationId" />
      </div>
      <div class="mt-3" v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember">
        <h3>{{ $t('users') }}</h3>
        <tag
            :key="user.id"
            v-for="user in users"
            :tag="user"
            name-property="name"
            @removeTag="removeUser"
        />
        <universal-selector
            class="mt-2"
            @addItem="addUser"
            :exclude-from-item-list="users"
            list-name="userOrgList"
            store-name="User"
            filter-attribute="displayName"
            tag-icon="users"
        />
      </div>



      </div>
    </div>
</template>

<script>
    import { required, minLength, email, url } from 'vuelidate/lib/validators'
    import Icon from "../Icon";
    import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
    import OrgVisibilityDropdown from "@/components/organizations/OrgVisibilityDropdown";
    import {alphaNum} from "@/customValidators";
    import ServiceToOrganization from "@/components/organizations/ServiceToOrganization";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import MetaPanel from "@/components/widgets/meta/MetaPanel";
    import InfoPanel from "@/components/InfoPanel";
    import UniversalSelector from "@/components/organizations/UniversalSelector";
    import Tag from "@/components/Tag";
    import LoadingSpinner from "@/components/LoadingSpinner";
    export default {
        name: "OrganizationEditForm",
        components: {
          LoadingSpinner,
            Icon,
            LabelWithEditIconAndContent,
            OrgVisibilityDropdown,
            ServiceToOrganization,
            MetaSetAttacher,
            MetaPanel,
            InfoPanel,
            UniversalSelector,
            Tag,
        },
        props: {
            organizationId: {type: String, default: null},
        },
        data() {
            return {
                loadingStorageUsage: false,
                loadingStats: false,
                storageUsage: '',
                fieldEditing: '',
                enableSaving: false,
                name: '',
                displayName: '',
                visibility: 0,
                url: '',
                email: '',
                billingEmail: '',
                ownerId: '',
                groupRootId: '',
                error: '',
                visibilities: [],
                appPanel: '',
                metaSets: [],
                users: [],
                projects: {},
                totalAssets: null,
                totalProjects: null,
                mediaAssets: null,
            };
        },
        beforeMount() {
          this.loadOrganization();
          this.loadOrganizationUsers();
        },
        validations: {
            name: {
                required,
                alphaNum,
                minLength: minLength(3),
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfOrganizationNameExists', {value: value}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                }
            },
            displayName: {
                minLength: minLength(3)
            },
            url: {
                url,
            },
            email: {
                required,
                email,
            },
            billingEmail: {
                email,
            },
        },
        methods: {
          loadStorageUsage() {
            this.loadingStorageUsage = true;
            this.$store.dispatch('clientListStorageUsage', {
              id: this.$route.params.id,
            }).then(data => {
              if(data && data.body) {
                this.storageUsage = data.body.readableSize;
              }
              this.loadingStorageUsage = false;
            });
          },
          addUser(user) {
            this.$store.dispatch('addOrganizationUser', {
              id: this.$route.params.id,
              args: [{userId: user.id}]
            }).then(() => {
              this.loadOrganizationUsers();
            });
          },
          removeUser(user) {
            this.$store.dispatch('clientRemoveOrganizationMember', {
              id: this.$route.params.id,
              args: [user.id]
            }).then(() => {
              this.loadOrganizationUsers();
            });
          },
          loadOrganizationUsers() {
            this.$store.dispatch('clientLoadOrganizationUsers', {id: this.$route.params.id, include: 'users'}).then(users => {
              if(users) {
                this.users = users.map(item => {item.user.organizationId = item.organizationId; return item.user});
              }
            });
          },
          loadOrganization() {
            this.$store.dispatch('loadOrganization', {
              id: this.organizationId,
              include: 'metaSets'
            }).then(data => {
              this.name = data.name;
              this.displayName = data.displayName;
              this.ownerId = data.ownerId;
              this.groupRootId = data.groupRootId;
              this.visibility = data.visibility;
              if(!this.visibility) {
                this.visibility = 0;
              }
              this.email = data.email;
              this.billingEmail = data.billingEmail;
              this.url = data.url;
              this.appPanel = data.appPanel;
              this.metaSets = data.metaSets;
            })
          },
            async loadStats() {
              this.loadingStats = true;
              await this.$store.dispatch('clientLoadProjects', {
                filter: 'organizationId eq ' + this.$route.params.id + ',type eq assembly',
                fields: ['id', 'name']
              })
            .then(async projects => {
                let total = 0;
                if(projects) {
                  for(let i = 0; i < projects.length; i++) {
                    await this.$store.dispatch('clientLoadProjectInstances',
                {
                      id: projects[i].id,
                      fields: ['id'],
                      paging: true,
                      limit: 0
                    }).then(res => {
                       this.projects[projects[i].id] = {name: projects[i].name, length: res.length};
                       total += res.length;
                    })
                  }
                }
                this.totalProjects = total;
             }).then(async () => {
                await this.$store.dispatch('clientLoadAssets', {
                  filter: 'type in node model helper, organizationId eq ' + this.$route.params.id,
                  fields: ['id', 'type'],
                  paging: true,
                  limit: 1
                  }).then(assets => {
                    this.totalAssets = assets.headers()['x-count'];
                })
                await this.$store.dispatch('clientLoadAssets', {
                  filter: 'type in node model helper, organizationId eq ' + this.$route.params.id,
                  fields: ['id', 'type'],
                  paging: true,
                  limit: 1
                  }).then(assets => {
                    this.totalAssets = assets.headers()['x-count'];
                })
                await this.$store.dispatch('clientLoadAssets', {
                  filter: 'type eq media, organizationId eq ' + this.$route.params.id,
                  fields: ['id', 'type'],
                  paging: true,
                  limit: 1
                  }).then(assets => {
                    this.mediaAssets = assets.headers()['x-count'];
                })
                this.loadingStats = false;
             })
            },
            setEditing(fieldName) {
                this.fieldEditing = fieldName;
            },
            isEditing(fieldName) {
                return this.fieldEditing === fieldName;
            },
            cancelEditing: function () {
                this.fieldEditing = '';
                this.loadOrganization();
            },
            updateOwner: function(id) {
                this.ownerId = id;
                this.update('ownerId');
            },
            update: function (fieldName, newVal = null) {
                if (!this.$v.$anyError) {
                    let value = newVal || newVal === 0 ? newVal : this[fieldName];
                    let args = {
                        id: this.organizationId,
                        [fieldName]: value,
                    };
                    this.$store.dispatch('updateOrganization', args).then(() => {
                      this.loadOrganization();
                    });
                    this.fieldEditing = '';
                }
            },
        },
    }
</script>