<template>
  <div :ref="uniqueId" :class="['form-switch', closed ? 'closed' : 'open']">
    <div class="any-selector item-selector-dropdown pl-1 pr-1"
    >
      <div @click.stop="toggleOpenClosed()" class="selected">
        <icon class="lighter up-down" type="angle-up" size="0.8" />{{ $t(translationPrefix + updateValue) }}

        <!-- This is a placeholder to make the correct with for the collapsed item (because it must be as wide as the lengthest language string) -->
        <div v-for="(value, key) in values"
             :key="key"
             class="invisible"
        >
          <icon type="check" size="0.8" :class="['mr-1 check', updateValue === value ? 'visible' : '']" />{{ $t(translationPrefix + value) }}
        </div>
        <!--  this is the actual options array -->
        <div :class="['options fancy-scrollbar', openDirection, closed ? 'closed' : 'open']">
          <div v-for="(value, key, index) in values"
               :key="index"
               v-if="!includeValues.length || includeValues.includes(value)"
               @click.stop="setValue(value); closed = true;"
          >
            <icon type="check" size="0.8" :class="['mr-1 check', updateValue === value ? 'visible' : '']" />{{ !showKeyInsteadOfValue ? $t(translationPrefix + [value]) : value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "Selector",
  components: {
    Icon
  },
  props: {
    /**
     * whether the opened options should expand to the top or to the bottom
     * **/
    openDirection: {type: String, default: "bottom"},
    values: {type: Array || Object, default: () => {}},
    preSelectedValue: {type: String, default: null},
    translationPrefix: {type: String, default: ""},
    includeValues: {type: Array, default: () => {return []}},
    showKeyInsteadOfValue: {type: Boolean, default: false}
  },
  data() {
    return {
      updateValue: '',
      closed: true,
      uniqueId: ""
    };
  },
  watch: {
    preSelectedValue(val) {
      this.updateValue = val;
    }
  },
  created () {
    document.addEventListener('click', this.documentClick)
  },
  destroyed () {
    // important to clean up!!
    document.removeEventListener('click', this.documentClick)
  },
  beforeMount() {
    this.updateValue = this.preSelectedValue;
    this.uniqueId = Math.floor(Math.random() * 16777215).toString(16);
  },
  methods: {
    documentClick(e) {
      let el = this.$refs[this.uniqueId]
      let target = e.target;
      if ((el !== target) && !el.contains(target)) {
        this.closed = true;
      }
    },
    toggleOpenClosed() {
      console.log('toggeling')
      this.closed = !this.closed;
    },
    setValue(val) {
      this.updateValue = val;
      this.$emit('setValue', val);
    },
  }
}
</script>
<style lang="scss" scoped>
.invisible {
  visibility: hidden;
  height:0;
  overflow:hidden;
}
.form-switch {
  border-radius: 3px;
  background-color: var(--vform-editor-layout-secondary-color);
  color: #fff;
  position: relative;
  z-index: 0;
  &.open {
    z-index: 505;
  }
}
.any-selector {
  border-radius: 3px;
  background-color: var(--vform-editor-layout-secondary-color);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 500;
  width: 100%;

  .selected {
    color: #fff;
  }
  .options {
    min-width: 170px;
  }
}
</style>