<template>
  <div>
  {{ ticket.id }}
  <div class="ticket-category">{{ ticket['IterationPath']}}</div>
        {{ dateFromISO(ticket['CreatedDate']) }} | <span class="lighter">{{ ticket['id'] }}</span><br />
    <h2>{{ ticket.Title }}</h2>
    {{ ticket.Description }}<br />
    Attachments: {{ getAttachments() }}
    {{ attachments }}
  </div>
</template>

<script>
import DateMixinJs from "@/components/mixins/DateMixin.js";
export default {
  name: "TicketDetailView",
  mixins: [DateMixinJs],
  props: {
    ticket: {type: Object, required: true}
  },
  data() {
    return {
      attachments: []
    };
  },
  methods: {
    getAttachments() {
      const atts = this.ticket.relations ? this.ticket.relations.filter(item => {return !!item.attachmentId}) : [];
      if(atts.length) {
        return atts.map(item => {return item.attachmentId});
      }
      return [];
    },
    async loadAttachments() {
      const attachments = this.getAttachments();
      for(let i = 0; i < attachments.length; i++) {
        await this.$store.dispatch('clientLoadTicketAttachment', {id: attachments[i]})
          .then(data => {
            this.attachments.push(data);
          })
      }
    }
  }
}
</script>

<style scoped>

</style>