<template>
  <div>
    <div v-if="showLabel">{{ $t('visibility') }}</div>
    <select class="custom-select narrow" v-model.number="visibility" @change="$emit('update', 'visibility', visibility)">
      <option :key="value" :value="value" v-for="(key, value) in visibilities"> {{ $t('orgVisibilityTypes.' + value) }}</option>
    </select>
  </div>
</template>

<script>
import {OrganizationVisibility} from '../../enum';

export default {
  name: "OrgVisibilityDropdown",
  props: {
    initialValue: {type: Number, default: 0},
    showLabel: {type: Boolean, default: true}
  },
    data () {
      return {
        visibility: 0,
      };
    },
    watch: {
      initialValue() {
        this.visibility = this.initialValue;
      }
    },
    beforeMount() {
      this.visibilities = OrganizationVisibility;
      this.visibility = this.initialValue;
    },
}
</script>

<style lang="scss" scoped>
select {
  height: $input-default-height;
}
</style>