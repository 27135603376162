<template>
  <div>
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeleted = null;}"
                     @confirm="() => {showDeletePrompt = false; deleteFile();}"
      />
    </popup>
    <popup
        v-if="deleteMultiple"
        @close="() => {deleteMultiple = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {deleteMultiple = false;}"
                     @confirm="() => {deleteAllSelected();}"
      />
    </popup>
    <div slot="mainContent" class="row">

      <div class="col-12">
        <div
            class="mb-4 settings-button d-inline-block"
            @click="createFileName = 'default.txt'; createFileContent = 'hello world'; creating = true;">
          {{ $t('createNewTypeFile', {type: 'text'}) }}
          <icon class="ml-1" type="align-left"/>
        </div>
        <div v-if="creating">
          <input v-model="createFileName" class="form-text" type="text"/>
          <component
              :is="'codemirror'"
              v-if="createFileName && createFileContent"
              key="new"
              :value="createFileContent"
              @abort="createFileName = ''; createFileContent = ''; creating = false;"
              @save="saveFileContent"
          />
        </div>

        <download-button
            :div-instead-of-button="true"
            :file-key-only="true"
            :object-id="targetId"
            file-key=""
        >
          <div class="settings-button d-inline-block ml-1 pt-1 pb-1">
            <span>{{ $t('download') }}</span>
            <icon class="ml-1" size="0.9" type="cloud"/>
          </div>
        </download-button>
      </div>
      <div class="dflex ml-3">
        <label :for="'showPreview' + $vnode.key" class="container"
        >{{ $t("showPreview") }}
          <input
              :id="'showPreview' + $vnode.key"
              v-model="showPreview"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-8">
        <div class="vform-label white">{{ $t('Filter') }}</div>
        <input v-model="regex" class="form-text form-text-dark" type="text" @keyup="getFilesByRegex"/>
      </div>

    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-8">
        <div class="float-right">
          <div class="dflex ml-3">
            <label :for="'selectUnselect' + $vnode.key" class="container"
            >{{ $t("selectUnselectAll") }}
              <input
                  :id="'selectUnselect' + $vnode.key"
                  v-model="selectUnselectAll"
                  type="checkbox"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="w-100"/>
          <div class="settings-button d-inline-block float-right" @click="deleteMultiple = true">
            {{ $t('deleteAllSelected') }}
          </div>
        </div>
        <div class="settings-button d-inline-block" @click="loadFiles">
          <icon type="redo"/>
        </div>
      </div>
    </div>
    <div class="w-100"/>
    <loading-panel v-if="loading" class="mt-1 mb-1 col-12 col-md-8"/>
    <div class="w-100"/>
    <div v-for="(file, index) in filteredFiles" v-if="files" :key="file.key + index + 239402" class="row mb-4">
      <preview
          v-if="editFileName !== file.key && showPreview"
          :downloadable="true"
          :preview-id="`${targetId}/${file.key}`"
          :preview-uri="`${targetId}/${file.key}`"
          :removable="false"
          :source="storeName"
          class="col-12 col-md-2 align-self-start"
      />
      <div :class="[editFileName === file.key ? 'col-12 col-md-12' : 'col-12 col-md-8']">
        <div class="bg-dark p-2 border-radius">
          <div class="dflex ml-3 float-right">
            <label :for="'fileSelected' + $vnode.key + file.key" class="container"
            >
              <input
                  :id="'fileSelected' + $vnode.key + file.key"
                  v-model="file.selected"
                  type="checkbox"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div
              v-if="previewUri && previewUri.split('/')[0] === $route.params.id && previewUri.split('/')[1] === file.key"
              class="darker float-right p-1 pl-2 pr-2 border-radius pseudo-button ml-2">
            <icon type="eye"/>
            {{ $t('isPreview') }}
          </div>
          <div v-else-if="['png', 'jpg', 'jpeg'].includes(getExtension(file.key).toLowerCase())">
            <div
                class="float-right settings-button d-inline-block"
                style="margin-top: -5px;"
                @click="setPreview(file.key)">
              <icon class="mr-1" type="camera-retro"/>
              {{ $t('setAsPreview') }}
            </div>
          </div>
          <div v-if="['json', 'txt', 'yml'].includes(getExtension(file.key).toLowerCase())">
            <Button v-if="!editFileName" class="float-right ml-2" style="margin-top: -5px;"
                    @click="editFileName = file.key; loadFileContent(file.key)">
              <icon type="edit"/>
              {{ $t('editFile') }}
            </Button>
            <component
                :is="'codemirror'"
                v-if="editFileName === file.key && editFileContent"
                :key="file.key"
                :value="editFileContent"
                @abort="editFileName = ''; editFileContent = ''"
                @save="saveFileContent"
            />
          </div>
          {{ file.key }}
          <div>
          </div>

          <span class="lighter">{{ $t('lastModified') }}:</span> {{ dateTimeFromISO(file.lastModified) }}<br/>

          <span class="lighter">{{ $t('original filename') }}:</span> {{ file.metaData.filename }}<br/>
          <span class="lighter">{{ $t('key') }}:</span> {{ file.key }}<br/>
          <span class="lighter">{{ $t('size') }}:</span> {{ file.size / 1000000 }} MB<br/>
          <span class="lighter">{{ $t('content-type') }}:</span> {{ file.metaData['content-type'] }}<br/>

          <span v-if="file.metaData && file.metaData.attributes" class="lighter">{{ $t('attributes') }}:</span>
          <div v-if="file.metaData && file.metaData.attributes">
            <div
                v-for="(val, key) in file.metaData.attributes"
                :key="key"
                class="slightly-darker p-2 col-12 mt-1"
            >
              {{ key }}: {{ val }}
            </div>
          </div>
        </div>
        <div class="d-flex mt-1">
          <download-button
              :div-instead-of-button="true"
              :file-key="file.key"
              :file-key-only="true"
              :object-id="targetId"
          >
            <div class="settings-button d-inline-block mr-1 pt-1 pb-1">
              <span>{{ $t('download') }}</span>
              <icon class="ml-1" size="0.9" type="cloud"/>
            </div>
          </download-button>
          <div
              class="mr-1 settings-button d-inline-block"
              @click="deleteFile(file.key)">{{ $t('delete') }}
            <icon class="ml-1" type="times"/>
          </div>
          <div
              v-if="['fbx', 'FBX'].includes(getExtension(file.key).toLowerCase()) && storeName === 'Asset' && ($store.getters.isSFXMember || $store.getters.isSuperAdmin)"
              class="mr-1 settings-button d-inline-block" @click="createDefaultJSON(file.key)">
            <icon type="camera-retro"/>
            {{ $t('createUpdateDefaultJson') }}
          </div>
          <div v-if="copying !== file.key" class="mr-1 settings-button d-inline-block" @click="copying = file.key">
            <icon class="mr-2" type="copy"/>
            {{ $t('duplicateFile') }}
          </div>
          <div v-else-if="copying === file.key">
            <div class="d-flex position-relative"><input v-model="copyFileName" class="form-text"
                                                         style="padding-right: 53px"
                                                         type="text"/>
              <div class="center-horizontally" style="right:5px; padding-left: 5px;">.{{ getExtension(file.key) }}</div>
            </div>
            <div class="edit-trigger mr-2" @click="copyFileName = ''; copying = ''">
              <icon :type="'times'"/>
            </div>
            <div v-if="copyFileName && (copyFileName + '.' + getExtension(file.key)) !== file.key" class="edit-trigger"
                 @click="copy(file.key)">
              <icon :type="'save'"/>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-5"/>
    <div v-if="specialFiles.length">
      <h2>CAD or CAD related files</h2>
      <span class="lighter">
        These files cannot be downloaded for security reasons.
      </span>
    </div>
    <div v-for="(file) in specialFiles" :key="file.key + 80000" class="row mb-1">
      <div class="col-12">
        <icon type="file"/>
        {{ file.key ? file.key : 'no-name' }}
      </div>
    </div>
    <!-- SIDEBAR LEFT -->
    <portal to="sidebarLeft">
      <div class="row special">
        <upload-drop-zone
            v-if="storeName !== 'Instance' || (storeName === 'Instance' && instanceProjectId)"
            :key="forceReRenderKey"
            ref="uploader"
            :asset-id="storeName === 'Asset' ? targetId : null"
            :attributes="attributes"
            :auto-update-preview="false"
            :auto-upload="false"
            :downloadable="false"
            :instance-id="storeName === 'Instance' ? targetId : null"
            :make-chunks="true"
            :project-id="storeName === 'Project' ? targetId : (instanceProjectId ? instanceProjectId : null)"
            :removable="true"
            :target-type="storeName"
            :use-default-key="false"
            class="col-12"
            form-id="uploadAnything"
            @fileReceived="loadFiles"
        />
        <codemirror
            :key="forceReRenderKey + 10000"
            ref="attributes"
            slot="info"
            :show-save-button="false"
            :value="attributes ? attributes : '{}'"
            class="col-12"
            @change="setAttributes"
        />
        <div class="settings-button d-inline-block ml-3 mt-1" @click="setAttributes">
          Set attributes
        </div>
        <div class="col-12">
          <div
              class="mt-3 settings-button d-inline-block"
              @click="startUpload">{{ $t('upload') }}
            <icon class="ml-1" type="cloud"/>
          </div>
        </div>
      </div>

      <div v-if="storeName === 'Project' && ($store.getters.isSuperAdmin || $store.getters.isSFXMember)" class="mt-3">
        <h3>Deploy</h3>
        <div class="row">
          <!--This seems not to be working if make chunks = true-->
          <upload-drop-zone
              :key="forceReRenderKey + 90908098"
              ref="uploader2"
              :attributes="attributes"
              :auto-update-preview="false"
              :auto-upload="true"
              :downloadable="false"
              :make-chunks="false"
              :project-id="targetId"
              :removable="true"
              :use-default-key="false"
              class="col-12"
              form-id="deployItem"
              target-type="Deploy"
              @fileReceived="loadFiles"
          />
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone";
import Codemirror from "@/components/widgets/editors/Codemirror";
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Preview from "@/components/preview/Preview";
import FileTypeMixin from "@/components/files/FileTypeMixin.js";
import DownloadButton from "../forms/DownloadButton";
import LoadingPanel from "../LoadingPanel.vue";

export default {
  name: "FileBrowser",
  components: {
    UploadDropZone,
    Codemirror,
    Button,
    Icon,
    Popup,
    DeletePrompt,
    Preview,
    DownloadButton,
    LoadingPanel
  },
  mixins: [DateMixin, FileTypeMixin],
  props: {
    storeName: {type: String, required: true},
    targetId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      regex: '',
      showPreview: false,
      specialFiles: [],
      files: [],
      filteredFiles: [],
      attributes: '',
      showDeletePrompt: false,
      forceReRenderKey: 0,
      previewUri: '',
      copying: '',
      copyFileName: '',
      editFileName: '',
      editFileContent: '',
      createFileContent: '',
      creating: false,
      createFileName: '',
      instanceProjectId: '',
      selectUnselectAll: false,
      deleteMultiple: false
    };
  },
  watch: {
    selectUnselectAll(val) {
      if (val) {
        this.setAllUnselected();
        this.setAllFilteredSelected();
      } else {
        this.setAllUnselected();
      }
    }
  },
  beforeMount() {
    this.loadFiles();
    this.loadItem();
    //this.$store.dispatch();
  },
  methods: {
    setAttributes() {
      this.attributes = this.$refs.attributes.save();
    },
    setAllFilteredSelected() {
      this.filteredFiles = this.filteredFiles.map(item => {
        item.selected = true;
        return item;
      })
    },
    setAllUnselected() {
      this.files = this.files.map(item => {
        item.selected = false;
        return item;
      })
      this.getFilesByRegex();
    },
    getFilesByRegex() {
      if (this.regex) {
        //const expressionRegExp = new RegExp(`${this.regex}/gi`);
        this.filteredFiles = this.files.filter(item => {
          return item.key.includes(this.regex);
        })
      } else {
        this.filteredFiles = this.files;
      }
    },
    loadFiles() {
      this.loading = true;
      this.$store.dispatch(`load${this.storeName}Files`, {
        id: this.targetId
      }).then(async data => {
        this.files = data;
        this.files = this.files.map(item => {
          item.selected = false;
          return item;
        })
        this.filteredFiles = this.files;

        return this.$store.dispatch(`load${this.storeName}Files`, {
          id: this.targetId,
          storageLocation: 'NonBackupLocation'
        })
      }).then(data => {
        this.specialFiles = data;
        this.forceReRenderKey++;
        this.attributes = {};
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    async loadItem() {
      await this.$store.dispatch(`clientLoad${this.storeName}`, {id: this.targetId})
          .then(data => {
            //console.log(data);
            this.previewUri = data.previewUri;
            if (data[0] && data[0].projectId) {
              this.instanceProjectId = data[0].projectId;
            }
          })
    },
    startUpload() {
      this.attributes = this.$refs.attributes.save();
      setTimeout(() => {
        this.$refs.uploader.resumeUpload();
      }, 400);
    },
    async deleteFile(key = '', ignorePrompt = false) {
      if (this.toBeDeleted || ignorePrompt) {
        return await this.$store.dispatch(`clientDelete${this.storeName}Part`, {
          id: this.targetId,
          key: ignorePrompt ? key : this.toBeDeleted
        }).then(() => {
          if (!ignorePrompt) {
            this.loadFiles();
          }
          this.toBeDeleted = null;
        })
      } else {
        this.toBeDeleted = key;
        this.showDeletePrompt = true;
      }
    },
    async deleteAllSelected() {
      if (this.deleteMultiple) {
        for (let i = 0; i < this.filteredFiles.length; i++) {
          const {selected, key} = this.filteredFiles[i];
          if (selected) {
            await this.deleteFile(key, true)
          }
        }
        this.deleteMultiple = false;
      } else {
        this.deleteMultiple = true;
      }
      this.loadFiles()
    },
    setPreview(key) {
      this.$store.dispatch(`clientSave${this.storeName}`, {id: this.targetId, previewUri: `${this.targetId}/${key}`})
          .then(data => {
            this.previewUri = data.previewUri;
          })
    },
    createDefaultJSON(key) {
      this.$store.dispatch('clientCreateDefaultJson', {
        id: this.targetId,
        jsonFileName: 'default.json',
        transformFileName: key
      })
          .then(() => {
            const $this = this;
            setTimeout(function () {
                  $this.loadFiles()
                },
                3000);
          })
    },
    loadFileContent(key) {
      this.$store.dispatch(`clientDownload${this.storeName}`, {
        id: this.targetId,
        key: key
      }).then(data => {
        this.editFileContent = JSON.stringify(data.body ? data.body : data.text);
      })
    },
    saveFileContent(value) {
      this.$store.dispatch(`create${this.storeName}TextFile`, {
        id: this.targetId,
        fileName: this.creating ? this.createFileName : this.editFileName,
        fileContent: value
      }).then(() => {
        this.editFileContent = '';
        this.editFileName = '';
        this.creating = false;
        this.createFileContent = '';
        this.createFileName = '';
      })
    },
    copy(originalFileName) {
      this.$store.dispatch(`clientDuplicate${this.storeName}File`, {
        id: this.targetId,
        fileName: originalFileName,
        newFileName: this.copyFileName + '.' + this.getExtension(originalFileName),
      }).then(() => {
        this.copyFileName = '';
        this.copying = '';
        const $this = this;
        setTimeout(function () {
              $this.loadFiles()
            },
            3000);
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.pseudo-button {
  margin-top: -5px;
}

.form-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>