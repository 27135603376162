<template>
    <div class="">
        <portal to="linkerHeaderSection" class="row" v-if="preContent">
            <div class="col-12">
                {{ $t(preContent) }}
            </div>
        </portal>
        <portal to="linkerHeaderSection" class="row" v-if="showAddButton">
            <div class="col-12">
                <Button
                    style="float: none"
                    class="d-inline-block mb-2"
                    type="action-button"
                    @click="showAddForm = true"
                    icon="plus"
                    >{{ $t("add" + storeName) }}</Button
                >
            </div>
        </portal>
        <portal to="linkerHeaderSection" class="row" v-if="showAddForm">
            <add-qrcode-popup
                @abort="showAddForm = false"
                @saved="$emit('updateTable')"
                :organization-id="organizationId"
                :teams="teams"
            />
        </portal>
        <table-widget
            v-if="$store.getters.getViewSwitcher($route.name, name) === 'table'"
            :id="'datasetsPerSfxdataTable'"
            :fields="tableFields"
            :data="$store.getters['get' + storeName + 'sByListName'](id)"
            @sort="sort"
            @selectCheckbox="selectCheckbox"
            :selectable-checkbox="true"
            :key="forceReRenderKey"
            :show-organization="showOrganization"
            :show-instances="showInstances"
        />
        <thumbnails
            v-if="$store.getters.getViewSwitcher($route.name, name) === 'thumbnails'"
            @sort="sort"
            :id="'datasetsPerSfxdataTable'"
            @selectCheckbox="selectCheckbox"
            :selectable-checkbox="true"
            :col-classes="'col-12 col-md-4'"
            :fields="thumbnailFields"
            :data="$store.getters['get' + storeName + 'sByListName'](id)"
            :title-as-links="true"
        />
    </div>
</template>

<script>
import TableWidget from "../../widgets/TableWidget";
import Thumbnails from "../../widgets/ThumbnailView";
import Button from "../../forms/Button";
import AddQrcodePopup from "@/components/forms/AddQrcodePopup";

export default {
    name: "AssetTable",
    components: {
        TableWidget,
        Thumbnails,
        AddQrcodePopup,
        Button,
    },
    props: {
        id: { type: String, required: true },
        storeName: { type: String, required: true },
        connectedInstance: { type: String, default: null },
        name: { type: String, default: null },
        tableFields: { type: Array, required: true },
        thumbnailFields: { type: Array, required: true },
        preContent: { type: String, default: null },
        showAddButton: { type: Boolean, default: false },
        organizationId: { type: String, default: "" },
        projectInstanceLimit: { type: Number, default: 0 }, //the maximum of items that can be linked of this type for one project
        showOrganization: { type: Boolean, default: false },
        showInstances: { type: Boolean, default: false },
        teams: { type: Array, required: false },
    },
    data() {
        return {
            showAddForm: false,
            forceReRenderKey: 0,
            titleEdit: false,
            descEdit: false,
        };
    },
    watch: {
        connectedInstance: function () {
            this.forceReRenderKey++;
        },
    },
    methods: {
        selectCheckbox: function (id, operation, item) {
            this.$emit("selectCheckbox", id, operation, item);
        },
        sort: function (args) {
            let params = {
                listId: this.id,
                id: this.id,
                listName: this.id,
                [args.action]: {
                    sort: [args.direction + args.fieldname],
                },
            };
            this.$store.dispatch("loadProject" + this.storeName + "s", params);
        },
        filter: function (args) {
            args.id = this.id;
            args.listName = this.id;
            this.$store.dispatch("loadProject" + this.storeName + "s", args);
        },
    },
};
</script>
