<template>
  <div :class="['mb-3 position-relative vform-slide-control-element', tiles ? 'vform-image-tiles' : '', config.alignment]">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <!--<div @click="forceReRenderKey++">force </div>-->
    <template v-if="config && (config.displayOption === 'upload' || !config.displayOption)">
      <preview
          ref="player"
          :key="config.uuid + forceReRenderKey"
          v-if="getImage(config, lang)"
          :may-load-now="isActive"
          :preview-id="getImage(config, lang)"
          :preview-uri="$store.getters['getMediaPreviewUri'](getImage(config, lang))"
          :size="config.size"
          source="asset"
          :asset-id="getImage(config, lang)"
          :removable="false"
          :class="['vform-image video-preview-block', config.alignment]"
          @preview="previewIsHere = true"
          :background-mode="false"
          :auto-play="canAutoPlay && activeMode"
          :muted="config.muted"
          :type="'video'"
          :offline-mode="offlineMode"
          @contentLoaded="increaseCounter"
      />
      <div class="text-center" v-else>
        {{ $t('noVideoSelected') }}<br />
        <icon class="lighter" type="image" size="3" />
      </div>
    </template>
    <div
        :style="{
                width: config.size ? config.size + '%' : '100%',
                flexBasis: config.size ? config.size + '%' : '100%',
            }"
        class="video-container-outer"
         v-else-if="config.displayOption === 'embed'">
      <div v-if="config.embedOption && ['youtube', 'vimeo'].includes(config.embedOption)" class="video-container">
        <iframe
            class="vimeo video bg-slight"
            ref="video"
            v-if="config.embedOption === 'vimeo' && getVideo(config, lang).videoId"
            title="vimeo-player"
            :src="'https://player.vimeo.com/video/' + getVideo(config, lang).videoId + '?' + (canAutoPlay && activeMode ? 'autoplay=1' : 'autoplay=0') + '&' + (config.muted ? 'muted=1' : 'muted=0') "
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen>
        </iframe>
          <iframe
              ref="video"
              class="youtube video bg-slight"
              v-if="config.embedOption === 'youtube' && getVideo(config, lang).videoId"
              :src="'https://www.youtube.com/embed/' + getVideo(config, lang).videoId + '?' + (canAutoPlay && activeMode ? 'autoplay=1' : 'autoplay=0') + '&' + (config.muted ? 'muted=1' : 'muted=0') + '&enablejsapi=1'"
              width="640"
              height="360"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
          </iframe>
         <div class="special-image bg-slight"><loading-spinner size="big" class="white" /></div>
      </div>
      <div :id="uniqueId" class="video-container" v-html="fullEmbedCode" v-if="config.embedOption === 'other'">
        <!--Showing full embed here via v-html -->
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import LoadingSpinner from "../../LoadingSpinner";
import Icon from "../../Icon";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "VIDEO",
  components: {
    Preview,
    Icon,
    EditorOverlay,
    LoadingSpinner
  },
  mixins: [ElementMixin],
  data() {
    return {
      forceReRenderKey: 0,
      previewIsHere: false,
      tiles: false,
      canAutoPlay: false,
      muted: false,
      uniqueId: ""
    };
  },
  computed: {
    fullEmbedCode() {
      const embed = this.getVideo(this.config, this.lang);
      // eslint-disable-next-line no-constant-condition
      if(embed && embed.embedCode) {
        var wrapper= document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.innerHTML= embed.embedCode;
        let div= wrapper.firstChild;
        const {src} = div;
        if(src && src.includes('youtube') && !src.includes('enablejsapi=1')) {
          div.src += (src.includes('?') ? '&' : '?') + 'enablejsapi=1'
        }
        return div.outerHTML;
      } else {
        return '<div class="special-image bg-slight"><div class="white half-circle-spinner loading-spinner-widget big">\n' +
            '        <div class="circle circle-1"></div>\n' +
            '        <div class="circle circle-2"></div>\n' +
            '    </div></div>';
      }
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.tiles = this.isTiles(this.config);
        this.forceReRenderKey++;
      }
    },
    editorMode: {
      handler() {
        this.canAutoPlay = !this.editorMode && this.config.autoPlay;
        this.muted =
        this.forceReRenderKey++;
      }
    },
    activeMode(val) {
      if(!val) {
        this.stopVideo();
      }
    }
  },
  beforeMount() {
    this.uniqueId = Math.random().toString(36).substring(2, 7);
    this.tiles = this.isTiles(this.config);
    this.canAutoPlay = !this.editorMode && this.config.autoPlay && this.isActive;
    this.muted = this.editorMode || this.muted || !this.isActive;
    if(this.config.displayOption !== "upload") {
      this.increaseCounter();
    }
  },
  methods: {
    stopVideo() {
      this.stopIt(this.$refs.video);
      if(this.$refs.player) {
        this.$refs.player.stop();
      }
      const el = document.getElementById(this.uniqueId);
      if(el) {
        const iframes = el.getElementsByTagName('iframe');
        for(let i = 0; i < iframes.length; i++) {
          console.log('stopping embed')
          console.log(iframes[i])
          this.stopIt(iframes[i])
        }
      }
    },
    stopIt(el) {
      if(!el) {return;}
      // youtube
      el.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
      // vimeo
      el.contentWindow.postMessage('{"method":"pause"}', '*')
    },
  }
}
</script>

<style lang="scss">
.video-container-outer {
  position: relative;
  width: 100%;
  z-index:0;
  .video-container {
    width: 100%;
    padding-top: 57.25%;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
  }
  .video, iframe {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .special-image {
    border-radius: 4px;
    width: 100%;
    height:100%;
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .loading-spinner-widget {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
.video-preview-block {
  width: 100%;
}
</style>