<template>
    <div class="w-100">
        <div
            slot="box"
            :class="['pr-1 pb-4','form-elements-container', state && state.dragging ? 'drop-area-outer' : '']">
          <div class="position-relative">
            <div class="vform-element col-12 mt-2">
              <div>
                <h2 class="pt-2 pl-0 col-12 mb-0 vform-editor-section-title">
                  {{getFormElementTitle(selectedElement.formElementTypeString) }}
                </h2>
              </div>
              <template-applier
                  class="mt-3"
                  v-if="!templateEditing && selectedElement"
                  :template-asset-id="templateAssetId"
                  :object="selectedElement"
              />
              <!--EDITOR HERE-->
              <component
                  v-if="selectedElement && activeStepObject"
                  :id="'elem-' + selectedElement.uuid"
                  v-show="selectedElement.expanded === true"
                  :is="'vForm' + getComponent(selectedElement)"
                  :element="selectedElement"
                  :step-no="activeStepObject.step"
                  :organization-id="organizationId"
                  :project-id="projectId"
                  :teams="targetTeams"
                  :thumbs="thumbs"
                  :slides="slides"
                  :state="state"
                  :steps="config.steps"
                  :globals="config.global"
                  :vform-config="config"
                  @dirty="() => (state.dirty = true)"
                  :v-s-t-a-g-e-context="vSTAGEContext"
                  :current-lang="currentLang"
                  :active-step-object="activeStepObject"
                  :selectedTemplate="selectedTemplate ? selectedTemplate.data : {}"
                  :template-editing="templateEditing"
              />
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import vFormHEADLINE from "@/components/vForm/vFormHEADLINE";
import vFormWEBSITE from "@/components/vForm/vFormWEBSITE";
import vFormTEXT from "@/components/vForm/vFormTEXT";
import vFormRADIOBUTTONS from "@/components/vForm/vFormRADIOBUTTONS";
import vFormCHECKBOXES from "@/components/vForm/vFormCHECKBOXES";
import vFormSEPARATOR from "@/components/vForm/vFormSEPARATOR";
import vFormVERTICAL_SPACE from "@/components/vForm/vFormVERTICAL_SPACE";
import vFormSHORT_ANSWER from "@/components/vForm/vFormSHORT_ANSWER";
import vFormQR_CODE from "@/components/vForm/vFormQR_CODE";
import vFormIMAGE from "@/components/vForm/vFormIMAGE";
import vFormVIDEO from "@/components/vForm/vFormVIDEO";
import vFormAUDIO from "@/components/vForm/vFormAUDIO";
import vFormMEDIA_LOADER from "@/components/vForm/vFormMEDIA_LOADER";
import vFormMETA_LIST_FILTER from "@/components/vForm/vFormMETA_LIST_FILTER";
import vFormBUTTON from "@/components/vForm/vFormBUTTON";
import vFormLOGIN from "@/components/vForm/vFormLOGIN";
import vFormLOGOUT from "@/components/vForm/vFormLOGOUT";
import vFormSENSOR from "@/components/vForm/vFormSENSOR";
import vFormPDF from "@/components/vForm/vFormPDF";
import vFormPART_INFO from "@/components/vForm/vFormPART_INFO";
import vFormCODE_SNIPPET from "@/components/vForm/vFormCODE_SNIPPET";
import vFormLIST_MATCH from "@/components/vForm/vFormLIST_MATCH";
import vFormLIST_MATCH_TEXT_INPUT from "@/components/vForm/vFormLIST_MATCH_TEXT_INPUT";
import vFormFORM_SUMMARY from "./vFormFORM_SUMMARY";
import vFormPROGRESS_TRACKER from "@/components/vForm/vFormPROGRESS_TRACKER";
import { vFormControls, placeholders } from "@/enum";
import TemplateApplier from "@/components/vForm/TemplateApplier.vue";
export default {
    name: "PropertiesPanel",
    components: {
      vFormHEADLINE,
      vFormWEBSITE,
      vFormTEXT,
      vFormCHECKBOXES,
      vFormRADIOBUTTONS,
      vFormSEPARATOR,
      vFormVERTICAL_SPACE,
      vFormSHORT_ANSWER,
      vFormQR_CODE,
      vFormIMAGE,
      vFormVIDEO,
      vFormMETA_LIST_FILTER,
      vFormPROGRESS_TRACKER,
      vFormMEDIA_LOADER,
      vFormBUTTON,
      vFormLOGIN,
      vFormLOGOUT,
      vFormSENSOR,
      vFormAUDIO,
      vFormPDF,
      vFormPART_INFO,
      vFormCODE_SNIPPET,
      vFormLIST_MATCH,
      vFormLIST_MATCH_TEXT_INPUT,
      vFormFORM_SUMMARY,
      TemplateApplier
    },
    props: {
        config: { type: Object, default: () => {return {}} },
        activeStepObject: {type: Object, default: () => {return {}}},
        state: { type: Object, default: null },
        slides: { type: Array, default: null },
        thumbs: {type: Object, default: null},
        targetTeams: {type: Array, default: null},
        projectId: {type: String, default: null},
        organizationId: {type: String, default: null},
        vSTAGEContext: {type: Boolean, default: false},
        activeSlideUuid: {type: String, default: null},
        currentLang: {type: String, required: true},
        templateAssetId: {type: String, required: true},
        selectedElement: {type: Object, required: true},
        selectedTemplate: {type: Object, default: () => {return {}}},
        templateEditing: {type: Boolean, default: false}
    },
    data() {
        return {
          placeholders: placeholders,
          vFormControls: vFormControls,
          forceReRenderKey: 0,
        };
    },
    computed: {
      hotspotElements(){
        if (this.state.selectedHotspot && this.state.selectedHotspot.elements){
          return this.state.selectedHotspot.elements;
        }
        return [];
      },
      globalElements() {
        if (this.config.global && this.config.global.elements){
          return this.config.global.elements;
        }
        return [];
      }
    },
    methods: {
      getSelectedElement() {
        const els = this.allElements();
        const index =  els.findIndex(item => {
          return item.uuid === this.state.activeElement;
        })
        return index !== -1 ? els[index] : {};
      },
      allElements() {
        return [...this.activeStepObject.elements, ...this.hotspotElements, ...this.globalElements];
      },
      getComponent(element) {
        const editorName = element.formElementTypeString;
        const component = Object.keys(vFormControls).filter((key) => {
          return vFormControls[key] === editorName;
        })[0];
        return component;
      },
      getFormElementTitle(formElementType) {
        if (formElementType === vFormControls.RADIOBUTTONS) {
          return this.$t("vform.singlechoice");
        }
        if (formElementType === vFormControls.CHECKBOXES) {
          return this.$t("vform.multichoice");
        }

        if (formElementType === vFormControls.TEXT) {
          return this.$t("vform.textfield");
        }

        if (formElementType === vFormControls.SHORT_ANSWER) {
          return this.$t("vform.shortanswer");
        }

        if (formElementType === vFormControls.CODE_SNIPPET) {
          return this.$t("vform.codeSnippet");
        }

        if (formElementType === vFormControls.FORM_SUMMARY) {
          return this.$t("vform.formSummary");
        }

        return formElementType;
      },
    }
};
</script>
<style lang="scss">
.vform-container {
    .step-settings-color-pickr {
        .form-submit-button {
            border: 0 solid transparent;
            font-size: 0.9rem;
            .a {
                border: 0 solid transparent;
                border-radius: 4px;
            }
            .content {
                padding: 5px 8px;
                height: auto;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.vform-panel {
    background-color: var(--vform-editor-layout-tertiary-color);
    border-radius: 5px;
    position: relative;
    color: var(--vform-editor-ui-tertiary-color);
    label:nth-child(1) {
        color: var(--vform-editor-ui-tertiary-color);
    }
}
.color-remover {
    border-radius: 3px;
}
.panel-remover {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
</style>
