<template>
  <div :class="['mb-3 position-relative vform-slide-control-element', tiles ? 'vform-image-tiles' : '', !tiles ? config.alignment : '']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <preview
        ref="audio"
      v-if="getImage(config, lang)"
      :may-load-now="isActive"
      :preview-id="getImage(config, lang)"
      :preview-uri="$store.getters['getMediaPreviewUri'](getImage(config, lang))"
      :size="config.size"
      source="asset"
      :asset-id="getImage(config, lang)"
      :removable="false"
      :class="['vform-image', !tiles ? config.alignment : '']"
      @preview="previewIsHere = true"
      :background-mode="false"
      type="audio"
      :offline-mode="offlineMode"
      :auto-play="canAutoPlay && activeMode"
      :key="forceReRenderKey + 900"
      @contentLoaded="increaseCounter"
     />
    <div class="text-center" v-else>
      {{ $t('noAudioSelected') }}<br />
      <icon class="lighter" type="image" size="3" />
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import Icon from "../../Icon";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "AUDIO",
  components: {
    Preview,
    Icon,
    EditorOverlay
  },
  mixins: [ElementMixin],
  data() {
    return {
      forceReRenderKey: 0,
      previewIsHere: false,
      tiles: false,
      canAutoPlay: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        console.log("change image")
        this.tiles = this.isTiles(this.config);
        this.forceReRenderKey++;
      }
    },
    editorMode: {
      handler() {
        this.canAutoPlay = !this.editorMode && this.config.autoPlay;
        this.forceReRenderKey++;
      }
    },
    activeMode(val) {
      if(!val) {
        this.stopAudio();
      }
    }
  },
  beforeMount() {
    this.tiles = this.isTiles(this.config);
    this.canAutoPlay = !this.editorMode && this.config.autoPlay;
  },
  methods: {
    stopAudio() {
      if(this.$refs.audio) {
        this.$refs.audio.stop();
      }
    },
    startAudio() {
      if(this.$refs.audio) {
        this.$refs.audio.start();
      }
    },
    validate() {
        return true;
    },
    checkRequired() {
      return true;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>