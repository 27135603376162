<template>
  <div>
    <div class="form-section">
      <label>{{ $t('name') }}*</label>
      <input type="text"
             :class="['form-text', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
             v-model="name"
             @input="delayTouch($v.name)"
      >
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.orgName">{{ $t('errors.orgNameOnly') }}</div>
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.organizationNameAlreadyExists') }}</div>
      <span class="lighter">{{ $t('NameIsDefinitive') }}</span><br /><br />
      <label>{{ $t('displayName') }}</label>
      <input type="text"
             :class="['form-text', $v.displayName.$dirty && $v.displayName.$error ? 'form-group--error' : '']"
             v-model="displayName"
             @input="delayTouch($v.displayName)"
      >

      <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
    </div>
    <div class="form-section">
      <label>{{ $t('url') }}</label>
      <input type="text"
             :class="['form-text mb-3', $v.url.$dirty && $v.url.$error ? 'form-group--error' : '']"
             v-model="url"
             @input="delayTouch($v.url)"
      >
      <div class="form-error" v-if="$v.url.$dirty && $v.url.$error">{{ $t('errors.mustBeAValidURL') }}</div>

      <div class="w-100" />
      <label>{{ $t('email') }}*</label>
      <input type="text"
             v-model="ownerEmail"
             :class="['form-text mb-3', $v.ownerEmail.$dirty && $v.ownerEmail.$error ? 'form-group--error' : '']"
             @input="delayTouch($v.ownerEmail)"
      >
      <div class="form-error" v-if="$v.ownerEmail.$dirty && !$v.ownerEmail.required && $v.ownerEmail.$error">{{ $t('errors.required') }}</div>
      <div class="form-error" v-if="$v.ownerEmail.$dirty && !$v.ownerEmail.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>

      <label>{{ $t('billingEmail') }}</label>
      <input type="text"
             :class="['form-text mb-3', $v.billingEmail.$dirty && $v.billingEmail.$error ? 'form-group--error' : '']"
             v-model="billingEmail"
             @input="delayTouch($v.billingEmail)"
      >
      <div class="form-error" v-if="$v.billingEmail.$dirty && !$v.billingEmail.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
    </div>
    <div class="form-section">
      <div class="mb-1">{{ $t('visibility') }}</div>
      <select class="custom-select narrow mt-1 mb-3" v-model="visibility">
        <option :key="value" :value="value" v-for="(key, value) in visibilities">{{ $t('orgVisibilityTypes.' + value) }}</option>
      </select>
      <br >
      <!--<div class="row">
        <div class="col-12 col-md-7">
          <div class="mb-1">{{ $t('owner') }} (optional)</div>
          <div class="bg-highlight p-3">
            <content-display
                class="mb-3"
                list-name="generalList"
                type="user"
                :value="ownerId"
                v-if="!ownerEditing && ownerId"
            />
          </div>

          <content-list
              type="user"
              list-name="generalList"
              @save="updateOwner"
              :pagination-limit="5"
          />
        </div>
      </div>-->
    </div>

    <div class="w-100" />
    <Button
        type="button"
        class="mb-3 mt-3"
        :deactivated="!enableSaving"
        @click="save"
    >
      <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
    </Button>
  </div>
</template>

<script>
import { required, minLength, email, url } from 'vuelidate/lib/validators'
import { mapState } from 'vuex';
import Button from "./Button";
import Icon from "../Icon";
import {OrganizationVisibility} from '../../enum';
import {orgName} from "../../customValidators";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
  name: "AddOrganization",
  components: {
    Button,
    Icon,
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      visibilities: [],
      enableSaving: false,
      ownerEditing: true,
      name: '',
      displayName: '',
      visibility: 0,
      url: '',
      ownerEmail: '',
      billingEmail: '',
      ownerId: '',
      groupRootId: '',
      error: '',
    };
  },
  computed: mapState({organizationTmp: state => state.organization.organizationTmp,}),
  watch: {
    '$v.$invalid': function() {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
    organizationTmp(newValue) {
      if(newValue['last_inserted']) {
        let id = newValue['last_inserted'];
        this.$router.push('/admin/organizations/'+id+'/general');
      }
    },
  },
  validations: {
    name: {
      required,
      orgName,
      minLength: minLength(3),
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfOrganizationNameExists', {value: value}).then(data => {
          bool = data.length === 0;
        });
        return bool;
      }
    },
    displayName: {
      minLength: minLength(3)
    },
    url: {
      url,
    },
    ownerEmail: {
      required,
      email,
    },
    billingEmail: {
      email,
    },
  },
  beforeMount() {
    this.visibilities = OrganizationVisibility;
  },
  methods: {
    updateOwner: function(ownerId) {
      this.ownerId = ownerId;
      this.ownerEditing = false;
    },
    save: function() {
      if(!this.$v.$anyError) {
        let args = {
          visibility: this.visibility,
          name: this.name,
          email: this.ownerEmail
        };
        if(this.displayName) {
          args.displayName = this.displayName;
        }
        if(this.url) {
          args.url = this.url;
        }
        if(this.billingEmail) {
          args.billingEmail = this.billingEmail;
        }
        if(this.ownerId) {
          args.ownerId = this.ownerId;
        }
        if(this.groupRootId) {
          args.groupRootId = this.groupRootId;
        }
        if(!this.displayName) {
          args.displayName = this.name;
        }
        this.$store.dispatch('createOrganization', args);
      }
    },
  },
}
</script>