import Vue from 'vue'
import {_} from "vue-underscore";
import i18n from "../translation";

const state = {
    reportLists: {
        reportList: {},
    },
    reportInstancesLists: {
        reportInstances: {},
    },
    reportListOptions: {},
    defaultQueryParams: {
        limit: {
            default: '50',
        },
        filter: {
            default: 'type eq report',
        },
        sort: {
            default: '-updatedAt',
        }
    },
    queryParams: {
        reportList: {
            limit: {
                default: '50',
            },
            filter: {
                default: 'type eq report',
            },
            sort: {
                default: '-updatedAt',
            },
        }
    },
    combinedQueryParams: {},
    reportTmp: {},
};

const getters = {
    getReports() {
        return state.reportLists.reportList;
    },
    getReportsByListName: (state) => (listName) => {
        return state.reportLists[listName] ? state.reportLists[listName] : {};
    },
    getReportInstancesByListName: (state) => (listName) => {
        return state.reportInstancesLists[listName] ? state.reportInstancesLists[listName] : {};
    },
    getReportById: (state) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id];
    },
    getReportOrganization: (state) => (id, listName = 'reportList') => {
        let listContainer = 'reportLists';
        return state[listContainer][listName][id] && state[listContainer][listName][id].organizationId ? state[listContainer][listName][id].organizationId : '';
    },
    getReportCreationDate: (state, rootGetters) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] ? rootGetters['convertDate'](state.reportLists[listName][id].createdAt) : '';
    },
    getReportChangeDate: (state, rootGetters) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] ? rootGetters['convertDate'](state.reportLists[listName][id].updatedAt) : '';
    },
    getReportPreviewUri: (state) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] && state.reportLists[listName][id].previewUri ? state.reportLists[listName][id].previewUri : '';
    },
    getReportName: (state) => (id, listName = 'reportList') => {
        if (state.reportLists[listName][id]) {
            return state.reportLists[listName][id].name;
        } else {
            return '';
        }
    },
    getReportDescription: (state) => (id, listName = 'reportList') => {
        if (state.reportLists[listName][id]) {
            return state.reportLists[listName][id].description;
        } else {
            return '';
        }
    },
    getReportTags: (state) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] && state.reportLists[listName][id].tags ? state.reportLists[listName][id].tags : [];
    },
    getReportMetaSets: (state) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] && state.reportLists[listName][id].metaSets ? state.reportLists[listName][id].metaSets : {};
    },
    getReportContent: (state) => (id, listName = 'reportList') => {
        return state.reportLists[listName][id] && state.reportLists[listName][id].content ? state.reportLists[listName][id].content : {};
    },
    getReportOriginalQueryParams: function() {
        return state.queryParams;
    },
    getReportQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },
    getReportListOptions: (state) => (listName, option = '') => {
        let optionList = 'reportListOptions';
        if (option === 'preview') {
            return state[optionList][listName] && state[optionList][listName].preview ? Object.keys(state[optionList][listName].preview)[0] : '';
        } else if (option === 'fbxPreview') {
            return state[optionList][listName] && state[optionList][listName].fbxPreview ? Object.keys(state[optionList][listName].fbxPreview)[0] : '';
        }
        else if(option === 'pagination_items') {
            return state[optionList][listName] && state[optionList][listName].pagination_items ? parseInt(Object.keys(state[optionList][listName].pagination_items)[0]) : 0;
        }
        return state[optionList][listName] ? state[optionList][listName] : {};
    },
};

const mutations = {
    setReportTmpMutation(state, data) {
        if (state.reportTmp[data.key]) {
            Vue.delete(state.reportTmp, data.key);
        }
        Vue.set(state.reportTmp, data.key, data.value);
    },
    loadReportsMutation(state, data) {
        let listName = 'reportList';
        if (data.listName) {
            listName = data.listName;
            delete data.listName;
        }
        Vue.set(state.reportLists, listName, {});
        for (var i = 0; i < data.length; i++) {
            let project = data[i];
            Vue.set(state.reportLists[listName], project.id, project);
        }
    },
    deleteReportMutation(state, data) {
        let listName = data.listName ? data.listName : 'reportList';
        let id = data.id;
        if(state.reportLists[listName] && state.reportLists[listName][id]) {
            Vue.delete(state.reportLists[listName], id);
        }
    },
    loadReportInstancesMutation (state, data) {
        let listName = 'reportInstances';

        if(data.listName) {
            listName = data.listName;
            delete data.listName;
        }

        if(!state.reportInstancesLists[listName]) {
            Vue.set(state.reportInstancesLists, listName, {});
        }
        if(data.instanceArray) {
            let instanceArray = data.instanceArray;
            Vue.set(state.reportInstancesLists, listName, {});
            for (var i = 0; i < instanceArray.length; i++) {
                let asset = instanceArray[i].asset;
                delete instanceArray[i].asset;
                let instance = instanceArray[i];
                let mixed = _.extendNonNull(asset, instance);
                Vue.set(state.reportInstancesLists[listName], instance.id, mixed);
            }
        }
        else {
            let asset = data.asset;
            delete data.asset;
            let instance = data;
            data = _.extendNonNull(asset, instance);
            Vue.set(state.reportInstancesLists[listName], data.id, data);
        }
    },
    deleteReportInstanceMutation (state, args) {
        if(state.reportInstancesLists[args.listName][args.id]) {
            Vue.delete(state.reportInstancesLists[args.listName], args.id);
        }
    },
    loadReportMutation(state, data) {
        let listName = 'reportList';
        if (data.listName) {
            listName = data.listName;
            delete data.listName;
        }
        if(!state.reportLists[listName]) {
            Vue.set(state.reportLists, listName, {});
        }
        Vue.set(state.reportLists[listName], data.id, data);
    },
    updateReportMutation(state, data) {
        for (var key in data) {
            var value = data[key];
            Vue.set(state.reportLists.reportList[data.id], key, value);
        }
    },
    reportUpdateTagsMutation(state, data) {
        let listName = data.listName ? data.listName : 'reportList';
        Vue.set(state.reportLists[listName][data.id], 'tags', data.tags);
    },

    /*
    * args:
    *   assetListName (mandatory)
    *   instanceListName (mandatory)
    * */
    markReportListSelectedMutation (state, args){
        let assetLists = 'reportLists';
        let instanceLists = 'reportInstancesLists';
        let assetListName = args.assetListName;
        let instanceListName = args.instanceListName;

        Object.keys(state[assetLists][assetListName]).forEach(key => {
            Vue.set(state[assetLists][assetListName][key], 'selected', false);
        });

        if(state[assetLists][assetListName] && state[instanceLists][instanceListName]) {
            Object.keys(state[instanceLists][instanceListName]).forEach(key => {
                let assetId = state[instanceLists][instanceListName][key].assetId;
                if(state[assetLists][assetListName][assetId]) {
                    Vue.set(state[assetLists][assetListName][assetId], 'selected', true);
                }
            });
        }
    },
    /**
     * listName: the list to be ordered
     * order: the key to be ordered by (see switch statement)
     */
    sortReportListMutation(state, args) {
        let assetLists = 'reportLists';
        let assetListName = args.listName;
        switch( args.order ){
            case 'selected':
                /*
                  Sort cafes by most liked.
                */
                // eslint-disable-next-line no-case-declarations
                let temp = state[assetLists][assetListName];
                // eslint-disable-next-line no-case-declarations
                let keys = Object.keys(state[assetLists][assetListName]).sort( function( a, b ){
                    if(temp[a].selected === temp[b].selected) {
                        return 0;
                    }
                    else if(temp[a].selected && !temp[b].selected) {
                        return -1;
                    }
                    else if(!temp[a].selected && temp[b].selected) {
                        return 1;
                    }
                });
                Vue.set(state[assetLists], assetListName, {});
                for(let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    Vue.set(state[assetLists][assetListName], key, temp[key]);
                }

            /*break;
        case 'most-liked':

            break;*/
        }
    },
    markReportSelectedMutation(state, args) {
        let listName = args.listName;
        Object.keys(args.data).forEach(key => {
            let id = args.data[key].id;
            if (state.reportLists[listName] && state.reportLists[listName][id]) {
                Vue.set(state.reportLists[listName][id], 'selected', true);
            }
        });
    },
    setReportPropertyMutation (state, args) {
        let listName = args.listName;
        let id = args.id;
        if(state.reportLists[listName][id]) {
            Vue.set(state.reportLists[listName][id], args.property, args.value);
        }
    },
    setReportTopOfListMutation(state, args) {
        let listName = args.listName;
        let id = args.id;
        if(state.reportLists[listName][id]) {
            let tempObj = state.reportLists[listName][id];
            Vue.delete(state.reportLists[listName], id);
            let newList = Object.assign(tempObj, state.reportLists[listName]);
            Vue.set(state.reportLists, listName, newList);
        }
    },
    updateReportListOptionMutation(state, args) {
        let optionList = 'reportListOptions';
        //reset all data:
        if (!state[optionList][args.listName]) {
            Vue.set(state[optionList], args.listName, {});
        }

        if (args.override) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        //reset all other options
        if (args.optionKey === 'selected' || args.optionKey === 'preview') {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }

        if (!state[optionList][args.listName][args.optionKey]) {
            Vue.set(state[optionList][args.listName], args.optionKey, {});
        }
        if (state[optionList][args.listName][args.optionKey][args.value]) {
            Vue.delete(state[optionList][args.listName][args.optionKey], args.value);
        } else {
            Vue.set(state[optionList][args.listName][args.optionKey], args.value, args.value2);
        }
    },
};

const actions = {
    /*
   * ------------------------ ASSETS --------------------------
   * **/
    registerReportsQueryParams: ({commit}, args) => {
        let listName = 'reportList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'report', listName: listName, params: args});
        }
    },
    loadReports: async ({commit, dispatch, getters}, args = {}) => {
        let listName = 'reportList';
        if (args.listName) {
            listName = args.listName;
        }
        if (Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'report', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'report', listName: listName});
        return dispatch('clientLoadAssets', getters.getReportQueryParams(listName)).then(data => {
            data.listName = listName;
            for (let i = 0; i < data.length; i++) {
                data[i]['editLink'] = '/report/' + data[i].id + '/general';
            }
            commit('loadReportsMutation', data);

            /*added pagination part*/
            if(getters.getReportQueryParams(listName).paging === true && data.headers()) {
                let count = data.headers()['x-count'] ? parseInt(data.headers()['x-count']) : 0;
                commit('updateReportListOptionMutation', {optionKey: 'pagination_items', value: count, listName: listName, override: true});
            }
            return data;
        });
    },
    loadReport: async ({commit, dispatch,}, args) => {
        dispatch('clientLoadAsset', args).then(data => {
            commit('loadReportMutation', data);
            //dispatch('loadReportImagePreview', {id: args.id});
        });
    },
    reportExternallyCreated: ({commit}, args) => {
        commit('setReportTmpMutation', {key: args.name, value: args.id});
    },
    createReport: async ({commit, dispatch}, args) => {
        return dispatch('clientCreateAsset', args)
            .then(data => {
                commit('loadReportMutation', data);
                commit('setReportTmpMutation', {key: 'last_inserted', value: data.id});
                dispatch('createNotification', {'text': i18n.t('events.reportCreated')});
            });
    },
    updateReport: async ({commit, dispatch}, args) => {
        commit('updateReportMutation', args);
        dispatch('clientSaveAsset', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.reportUpdated')});
        });
    },
    deleteReport: async ({commit, dispatch}, args = {}) => {
        let listName = 'reportList';
        if(args.listName) {
            listName = args.listName;
        }
        let queryArgs = args.args;
        dispatch('clientDeleteAssets', queryArgs).then(() => {
            commit('deleteReportMutation', {listName: listName, id: queryArgs});
            dispatch('createNotification', {'text': i18n.t('events.reportDeleted')});
        });
    },
    addReportTag: async ({commit, dispatch}, args) => {
        dispatch('clientAddAssetTags', args).then(() => {
            dispatch('clientLoadAssetTags', args).then(data => {
                commit('reportUpdateTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'reportList',
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsAdded')});
        });
    },
    removeReportTag:  async ({commit, dispatch}, args) => {
        dispatch('clientRemoveAssetTags', args).then(() => {
            dispatch('clientLoadAssetTags', args).then(data => {
                commit('reportUpdateTagsMutation', {
                    id: args.id,
                    listName: args.listName ? args.listName : 'reportList',
                    tags: data,
                });
            });
            dispatch('createNotification', {'text': i18n.t('events.tagsRemoved')});
        });
    },

    setReportListSelected: ({commit}, args) => {
        args.optionKey = 'selected';
        args.value = args.id;
        args.value2 = args.assetId;
        args.override = true;
        commit('updateReportListOptionMutation', args);

        commit('updateReportListOptionMutation', {
            id: args.id,
            listName: args.listName,
            value: args.previewUri,
            optionKey: 'preview',
        });
    },
    setReportMetaValues: async ({dispatch}, args) => {
        //commit('updateReportMutation', args);
        return dispatch('clientSaveAssetMetaSetValues', args).then(() => {
            dispatch('createNotification', {'text': i18n.t('events.reportMetaValuesUpdated')});
        });
    },
    checkIfReportNameExists: async ({dispatch, getters}, args) => {
        let value = args.value;
        let orgId = args.organizationId ? args.organizationId : getters.getCurrentUserOrg;
        return dispatch('clientLoadAssets', {
            filter: ['name eq ' + value + ', organizationId eq ' + orgId /*+ ', type eq report'*/],
            fields: 'name,id',
        });
    },
    /*
    * Attention: this method is vuex-store only (no database-connection)
    * id
    * listName
    * property (string with keyname)
    * value
    * */
    setReportProperty: ({commit}, args) => {
        commit('setReportPropertyMutation', args);
    },

    /*
    * Attention: this method is vuex-store only (no database-connection)
    * id
    * listName
    * */
    setReportTopOfList: ({commit}, args) => {
        commit('setReportTopOfListMutation', args);
    },

    /*
    * Attention: this method is vuex-store only (no database-connection)
    * listName
    * order (property name)
    * */
    sortReportList: ({commit}, args) => {
        commit('sortReportListMutation', args);
    },

    /*
   * ------------------------ INSTANCES --------------------------
   * **/
    loadReportInstances: async ({commit, dispatch, getters}, args = {}) => {
        let listName = args.listName ? args.listName :'reportList';

        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'report', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'report', listName: listName});
        dispatch('clientLoadProjectInstances', getters.getReportQueryParams(listName)).then(data => {
            let args = {
                listName: listName,
                instanceArray: data,
            };
            commit('loadReportInstancesMutation', args);
        });
    },
    loadReportInstance: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'reportInstanceList';

        dispatch('clientLoadProjectInstance', args).then(data => {
            //merge asset data with instance data
            /*data.listName = listName;
            let asset = data.asset;
            delete data.asset;
            let instance = data;
            data = _.extendNonNull(asset, instance);*/

            data.listName = listName;

            //todo: replace by filtering method in query (not available in api)
            if(data.asset.type === 'report') {
                commit('loadReportInstancesMutation', data);
            }

        });
    },


    /*
    * loads all connections for a spezified target/source and its projects
    * */
    loadReportConnectionsWithInstances: async ({dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'reportInstanceList';

        return dispatch('getProjectInstanceConnections', args).then(connections => {
            for (let i = 0; i < connections.length; i++) {
                let connection = connections[i];
                if(connection.targetId) {
                    let args2 = {
                        id: args.id,
                        cid: connection.targetId,
                        listName: listName,
                        include: ['asset'],
                        //filter: 'filter='+state.defaultQueryParams.filter.default,
                        //default: 'type in sensor, feed'
                    };

                    //dispatch('clientLoadProjectInstances', getters.getDatasourceQueryParams(listName)).
                    dispatch('loadReportInstance', args2);
                }
            }
        });
    },
    /*
   *   args
   *       id (required)
   *       cid (instanceId, required)
   * **/
    loadReportConnections: async ({commit, dispatch}, args = {}) => {
        let listName = args.listName ? args.listName : 'reportConnections';
        args.include = 'instanceAndAsset';
        return dispatch('getProjectInstanceConnections', args).then(data => {
            let instances = [];
            for (let i = 0; i < data.length; i++) {
                if(data[i].target) {
                    let obj = data[i].target;
                    //todo: replace by filtering method in query (not available in api)

                    if(obj.asset.type === 'report') {
                        instances.push(obj);
                    }
                }
            }
            let args = {
                listName: listName,
                instanceArray: instances,
            };
            commit('loadReportInstancesMutation', args);
        });
    },
    /*
   * args
   *   id (project id)
   *   cid (source instance id)
   *
   * **/
    loadReportWithInstanceConnections: ({commit, dispatch}, args = {}) => {
        dispatch('loadReports', args)
            .then(() => {
                args.listName = args.cid;
                args.includes = 'instanceAndAsset';
                dispatch('loadReportConnections', args)
                    .then(() => {
                        commit('markReportListSelectedMutation', {
                            assetListName: args.id,
                            instanceListName: args.cid,
                        });
                    }).then(() => {
                    commit('sortReportListMutation', {'listName': args.id, order: 'selected'});
                });
            });
    },

    /*
   * Attention: this method is vuex-store only (no database-connection)
   * id
   * listName
   * */
    removeReportInstance: ({commit}, args) => {
        commit('deleteReportInstanceMutation', args);
    },

    /*
  * ------------------------ PROJECTS --------------------------
  * **/

    loadProjectReports: async ({commit, dispatch}, args) => {
        let listName = args.listName ? args.listName : 'reportList';

        dispatch('loadReports', args).then(() => {
            dispatch('clientLoadProjectAssets', /*getters.getReportQueryParams(listName)*/ args)
                .then(data => {
                    data.listName = listName;
                    data.id = args.id;
                    for (let i = 0; i < data.length; i++) {
                        data[i]['editLink'] = '/report/' + data[i].id + '/general';
                    }
                    commit('markReportSelectedMutation', {listName: listName, data: data});
                }).then(() => {
                commit('sortDatasourceListMutation', {listName: listName, order: 'selected'});
            });
        });
    },
    addProjectReport: async ({dispatch}, args) => {
        dispatch('clientAddProjectAsset', args)
            .then(() => {
                dispatch('loadProjectReports', args);
            });
    },
    removeProjectReport: async ({dispatch}, args) => {
        dispatch('clientRemoveProjectAsset', args)
            .then(() => {
                dispatch('loadProjectReports', args);
            });
    },
    loadSfxdataReports: async ({commit, dispatch}, args) => {
        let listName = args.listName;
        //todo: this is a quickfix, send query params via save-query-params method
        args.filter = 'type eq report';
        dispatch('loadReports', args).then(() => {
            dispatch('clientLoadProjectAssets', args).then(data => {
                commit('markReportSelectedMutation', {listName: listName, data: data});
            });
        });
    },
};
export default {
    state, getters, mutations, actions
}
