<template>
    <two-column-layout
        :tab-selector="$store.getters.getAppType($route.params.id) + 'tab'"
        :type="$store.getters.getAppType($route.params.id)"
        back-button-tab-selector="appbackbutton"
        :id="$route.params.id"
        :has-top-bar="false"
    >
          <content-filter
              v-if="organizationId"
              slot="filter"
              :id="listName"
              @filter="filtering"
              store-name="Project"
              :limit-to-organization-ids="[organizationId]"
        />

      <div slot="mainContent">
        <h1>{{ this.$store.getters.getAppName(id) }}</h1>
        <h2 class="mt-5"> {{ $t('ChooseProjectForApp') }} </h2>
        <pagination
            :limit="paginationLimit"
            :total="$store.getters.getProjectListOptions(listName, 'pagination_items')"
            :id="listName"
            @setActive="loadPaginatedData"
            ref="pagination"
            slot="mainContentButtons"
        />
            <table-widget
                    id="projectsPerAppTable"
                    :fields="fields"
                    :data="$store.getters.getProjectsByListName(listName)"
                    :selectable-checkbox="true"
                    @selectCheckbox="selectCheckbox"
                    :key="forceReRenderKey"
                    :show-organization="true"
                    @hasGivenUp="(bool) => hasGivenUp = bool"
            />
        <div class="mt-3" v-if="hasGivenUp">
          {{ $t('noDataYet') }}<br />
          {{ $t('uploadvStageProject') }}
          <!--<router-link :to="`/`">
            <Button class="mt-2"><icon type="folder" />{{ $t('createNewType', {type: 'Project'}) }}</Button>
          </router-link>-->
        </div>
        </div>
    </two-column-layout>
</template>

<script>
    import TableWidget from "../components/widgets/TableWidget";
    import TwoColumnLayout from "../layouts/MainTableLayout";
    import ContentFilter from "@/components/widgets/filter/ContentFilter";
    import Pagination from "@/components/Pagination";
    //import Button from "@/components/forms/Button";
    //import Icon from "@/components/Icon";

    export default {
        name: 'AppEditProject',
        components: {
            TableWidget,
            TwoColumnLayout,
            ContentFilter,
            Pagination,
            //Button,
            //Icon
        },
        data() {
            return {
                hasGivenUp: false,
                listName: 'appEditProject',
                filter: {},
                organizationId: '',
                paginationLimit: 15,
                offset: 0,
                id: '',
                forceReRenderKey: 0,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                fields: [
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                    },
                    {
                        name: 'Type',
                        id:'type',
                        type: 'string',
                    },
                ],
            };
        },
        watch: {
          organizationId() {
            // this.loadPaginatedData(0);
          },
        },
        beforeMount() {
            this.id = this.$route.params.id;
            this.$store.dispatch('loadApp', {
                id: this.$route.params.id,
            }).then(data => {
              this.organizationId = data.organizationId;
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              } else if(e.status === 404) {
                this.$router.push('/not-found')
              }
            });
            this.$store.subscribe((mutation) => {
                if (mutation.type === 'loadProjectsMutation' && mutation.payload.listName === this.$route.params.id) {
                    this.$store.dispatch('markProjectsSelected', {
                        listName: this.$route.params.id,
                        id: this.$store.getters.getAppBaseProjectId(this.$route.params.id),
                    });
                }
            });
        },
        methods: {
            loadPaginatedData(offset) {
              this.offset = offset;
              this.loadData();
            },
            loadData() {
              let args = {
                  listName: this.listName,
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter.remove) {
                    args.remove = {...this.filter.remove};
                  }
                this.$store.dispatch('loadProjects', args).then(() => {
                  this.markSelected();
                });
            },
            selectCheckbox: function (id, action) {
                if (action === 'add') {
                    if(this.$store.getters.getAppBaseProjectId(this.$route.params.id)) {
                      this.markUnselected(this.$store.getters.getAppBaseProjectId(this.$route.params.id));
                    }
                    this.$store.dispatch('updateApp', {
                        id: this.$route.params.id,
                        baseProjectId: id,
                    });
                    this.markSelected();
                } else if (action === 'remove') {
                    this.$store.dispatch('updateApp', {
                        id: this.$route.params.id,
                        baseProjectId: null,
                    });
                    this.markUnselected(id);
                }
                this.forceReRenderKey++;
            },
            markUnselected(id) {
              this.$store.dispatch('markProjectsUnselected', {
                    listName: this.listName,
                    id: id,
                });
            },
            markSelected() {
              this.$store.dispatch('markProjectsSelected', {
                    listName: this.listName,
                    id: this.$store.getters.getAppBaseProjectId(this.$route.params.id),
                });
            },
            filtering: function(args) {
              this.filter = args;
              this.loadData();
            },
        },
    }
</script>
