export const defaultConfig = [
        {
            name: 'media',
            assetComponent: 'media',
            instanceComponent: 'mediaInstances',
            loadingMethod: 'loadMedias',
            registerMethod: 'registerMediaQueryParams',
            storeName: 'Media',
            tableFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Name',
                    id:'name',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
            ]
        },
        {
            name: 'datasources',
            assetComponent: 'datasources',
            instanceComponent: 'datasourcesInstances',
            loadingMethod: 'loadDatasources',
            registerMethod: 'registerDatasourceQueryParams',
            storeName: 'Datasource',
            tableFields: [
                {
                    name: '',
                    id: '',
                    type: 'icon',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
            ]
        },
        {
            name: 'sfxdata',
            assetComponent: 'sfxdatalist',
            instanceComponent: 'sfxdatalistInstances',
            loadingMethod: 'loadSfxdatas',
            registerMethod: 'registerSfxdataQueryParams',
            storeName: 'Sfxdata',
            filter: 'type in assembly, helper, model',
            tableFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Type',
                    id: 'type',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
            ]
        },
        {
            name: 'qrcodes',
            showAddButton: true,
            assetComponent: 'qrcodes',
            instanceComponent: 'qrcodesInstances',
            loadingMethod: 'loadQrcodes',
            registerMethod: 'registerQrcodeQueryParams',
            storeName: 'Qrcode',
            tableFields: [
                {
                    name: '',
                    id: 'qrcode',
                    type: 'qrcode',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id: 'qrcode',
                    type: 'qrcode',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
                {
                    name: 'Edit',
                    type: 'editButton'
                },

            ]
        },
        {
            name: 'webapps',
            assetComponent: 'webapps',
            instanceComponent: 'webappInstances',
            loadingMethod: 'loadWebapps',
            registerMethod: 'registerWebappQueryParams',
            storeName: 'Webapp',
            limitToSingleConnection: true,
            tableFields: [
                {
                    name: '',
                    id: 'webapp',
                    type: 'webapp',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id: '',
                    type: 'image',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
                {
                    name: 'Edit',
                    type: 'editButton'
                },
            ]
        },
        {
            name: 'vforms',
            preContent: 'onlySingleLinkingDisclaimer',
            assetComponent: 'forms',
            instanceComponent: 'formInstances',
            loadingMethod: 'loadForms',
            registerMethod: 'registerFormQueryParams',
            storeName: 'Form',
            tableFields: [
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Description',
                    id: 'description',
                    type: 'string',
                },
            ],
            thumbnailFields: [
                {
                    name: '',
                    id:'',
                    type: 'image',
                },
                {
                    name: 'Last Update',
                    id: 'updatedAt',
                    type: 'date',
                },
                {
                    name: 'Name',
                    id: 'name',
                    type: 'string',
                },
                {
                    name: 'Organization',
                    id:'organization',
                    type: 'organization',
                    sortable: true
                },
            ]
        },
    ];

export const sfxConfig = [
    {
        name: 'datasources',
        assetComponent: 'datasources',
        storeName: 'Datasource',
        tableFields: [
            {
                name: '',
                id: '',
                type: 'icon',
            },
            {
                name: 'Name',
                id: 'name',
                type: 'string',
            },
        ],
        thumbnailFields: [
            {
                name: '',
                id:'',
                type: 'image',
            },
            {
                name: 'Last Update',
                id: 'updatedAt',
                type: 'date',
            },
            {
                name: 'Name',
                id: 'name',
                type: 'string',
            },
            {
                name: 'Organization',
                id:'organization',
                type: 'organization',
                sortable: true
            },
        ]
    },
    {
        name: 'media',
        assetComponent: 'media',
        storeName: 'Media',
        tableFields: [
            {
                name: '',
                id:'',
                type: 'image',
            },
            {
                name: 'Name',
                id:'name',
                type: 'string',
            },
        ],
        thumbnailFields: [
            {
                name: '',
                id:'',
                type: 'image',
            },
            {
                name: 'Last Update',
                id: 'updatedAt',
                type: 'date',
            },
            {
                name: 'Name',
                id: 'name',
                type: 'string',
            },
            {
                name: 'Organization',
                id:'organization',
                type: 'organization',
                sortable: true
            },
        ]
    },
];