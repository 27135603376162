<template>
  <div>
    <component
        v-if="getSelectedComponent"
        :is="getSelectedComponent"
        :editing="editing"
        :config="field.config"
        :type="field.type"
        :hide-save-button="hideSaveButton"
        @update="(val) => {$emit('update', val)}"
    />
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
import Icon from "../../Icon";
import {MetaFieldTypes} from '../../../enum';
import ContentList from "../editors/ContentList";
import ContentDisplay from "../editors/ContentDisplay";
import Enum from "../editors/Enum";
import Number from "../editors/FloatWidget";
import TextWidget from "../editors/TextWidget";
import BooleanWidget from "../editors/BooleanEditor";
import VectorWidget from "../editors/VectorWidget";
import DateMixin from '@/components/mixins/DateMixin.js';
import VectorDisplay from "../editors/VectorDisplay";
import EmailWidget from "../editors/EmailWidget";
import IPWidget from "../editors/IPWidget";
import UriWidget from "../editors/UriWidget";
import RangeWidget from "../editors/RangeWidget";
import ListWidget from "../editors/ListWidget";
import IntegerWidget from "../editors/IntegerWidget";
import UuidWidget from "../editors/UuidWidget";
import DatePickerWidget from "@/components/widgets/editors/DatePickerWidget";
import ColorPicker from "@/components/widgets/editors/ColorPicker";
import Codemirror from "@/components/widgets/editors/Codemirror";
import JsonAdder from "@/components/widgets/editors/JsonAdder";
import MatColorAdder from "@/components/widgets/editors/MatColorAdder";
import LifetimeSetter from "@/components/LifetimeSetter";

/**
 * Returns the raw value or '(empty)' if value
 * is undefined or null.
 *
 * @param {string} v - Value
 * @param dft - Default value
 * @returns {string}
 */
const emptyOrValue = (v, dft) => {
  const d = dft || '(empty)';
  return v ? v : d;
};

/**
 *
 */
export default {
  name: 'LightweightMetaField',
  components: {
    DatePickerWidget,
    ColorPicker,
    Icon,
    Codemirror,
    ContentList,
    ContentDisplay,
    Enum,
    Number,
    TextWidget,
    BooleanWidget,
    VectorWidget,
    VectorDisplay,
    EmailWidget,
    IPWidget,
    UriWidget,
    RangeWidget,
    ListWidget,
    UuidWidget,
    IntegerWidget,
    JsonAdder,
    MatColorAdder,
    LifetimeSetter
  },
  mixins: [DateMixin],
  props: {
    label: {type: String, required: true},
    value: {type: String, required: false, default: null},
    field: {type: Object, required: true},
    /**
     * The id of the target (e.g. of the Asset)
     * */
    targetId: {type: String, default: null},
    /**
     * The storeName to update the item (e.g. Project)
     * */
    storeName: {type: String, default: ''},
    /**
     * If required by the store (e.g. setting the metaValue of an Instance which needs id = projectId and cid = instanceId)
     * */
    cid: {type: String, required: false, default: null},
    organizationIds: {
      type: Array, default: () => {
        return []
      }
    },
    teams: {type: Array, default: null},
    doEmitOnly: {type: Boolean, default: false},
    editable: {type: Boolean, default: true},
    editModeOnly: {type: Boolean, default: false},
    hideSaveButton: {type: Boolean, default: false},
  },
  data() {
    return {
      editing: false,
      updatedValue: [],
      selectedComponent: null,
    }
  },
  computed: {
    getSelectedComponent() {
      if(this.isVector) {
        return 'VectorWidget';
      }
      /*if(this.isVector && !this.editing) {
        return 'VectorDisplay';
      } else if(this.isText && this.editing) {
        return 'TextWidget';
      } else if(this.isJson && this.field.widget === 'MatColorAdder') {
        return 'MatColorAdder';
      }*/
      return null;
    },
    isText() {
      return this.field.type === MetaFieldTypes.String || this.field.type === MetaFieldTypes.Text;
    },
    isIP() {
      return this.field.type === MetaFieldTypes.Ip || this.field.type === MetaFieldTypes.Ipv6;
    },
    isUri() {
      return this.field.type === MetaFieldTypes.Uri
    },
    isEmail() {
      return this.field.type === MetaFieldTypes.Email
    },
    isUuid() {
      return this.field.type === MetaFieldTypes.Uuid;
    },
    isDateOrTime() {
      return this.field.type === MetaFieldTypes.Date || this.field.type === MetaFieldTypes.Time || this.field.type === MetaFieldTypes.DateTime;
    },
    isDate() {
      return this.field.type === MetaFieldTypes.Date;
    },
    isTime() {
      return this.field.type === MetaFieldTypes.Time;
    },
    isDateTime() {
      return this.field.type === MetaFieldTypes.DateTime;
    },
    isUiOverlay() {
      return this.field.key === 'uiOverlay';
    },
    isJson() {
      return this.field.type === MetaFieldTypes.Json;
    },
    isAsset() {
      return this.field.type === MetaFieldTypes.Asset || this.field.type === MetaFieldTypes.DataSet;
    },
    isProject() {
      return this.field.type === MetaFieldTypes.Project;
    },
    isVector() {
      return this.field.type === MetaFieldTypes.Vector2 || this.field.type === MetaFieldTypes.Vector3 || this.field.type === MetaFieldTypes.Vector4 || this.field.type === MetaFieldTypes.xForm2 || this.field.type === MetaFieldTypes.xForm3;
    },
    isRange() {
      return this.field.type === MetaFieldTypes.Range;
    },
    isCheckbox() {
      return (this.field.type === MetaFieldTypes.Boolean);
    },
    isList() {
      return (this.field.type === MetaFieldTypes.List);
    },
    isColor() {
      return this.field.type === MetaFieldTypes.Color;
    },
    isEnum() {
      return this.field.type === MetaFieldTypes.Enum;
    },
    isPassword() {
      return this.field.type === MetaFieldTypes.Password;
    },
    isFloat() {
      return this.field.type === MetaFieldTypes.Float;
    },
    isInteger() {
      return this.field.type === MetaFieldTypes.Integer;
    },
    typeI18nKey() {
      let key = 'editors.type.' + this.field.type;
      if (this.field.type === MetaFieldTypes.Asset) {
        if (this.field.config) {
          key = key + '.' + this.getConfigField('type');
        }
      }
      return key;
    },
    renderConfig() {
      if (typeof this.field.config === 'string') {
        return JSON.parse(this.field.config);
      } else {
        return this.field.config;
      }
    },
    /**
     * Render the current value according the type
     * @returns {string}
     */
    renderValue() {
      const v = this.getValue();
      const d = this.field.defaultValue;
      switch (this.field.type) {
        case MetaFieldTypes.Integer:
        case MetaFieldTypes.String : {
          return emptyOrValue(v, d);
        }
        case MetaFieldTypes.Vector2 : {
          return v;
        }
        case MetaFieldTypes.Vector3 : {
          return v;
        }
        case MetaFieldTypes.Vector4 : {
          return v;
        }
        case MetaFieldTypes.xForm2 : {
          return v;
        }
        case MetaFieldTypes.xForm3 : {
          return v;
        }
        case MetaFieldTypes.Boolean: {
          if (v === undefined) {
            if (d === undefined) {
              return false;
            }
          }
          return v;
        }
        case MetaFieldTypes.Json: {
          return emptyOrValue(v, d);
        }
        default:
          return emptyOrValue(v, d);
      }
    },
    rawValue() {
      return this.getValue();
    },
  },
  beforeMount() {
    if (!this.value) {
      this.updatedValue = '';
    }
    if(this.editModeOnly) {
      this.editing = true;
    }
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
    },
    getConfigField(fieldName) {
      if (this.field.config) {
        let config = this.field.config;
        try {
          let tmp = JSON.parse(this.field.config);
          config = tmp;
        } catch {
          // do nothing
        }
        return config[fieldName] ? config[fieldName] : null;
      }
    },
    updateValue(value) {
      let args = {
        id: this.targetId ? this.targetId : null,
        values: [{metaFieldId: this.field.id, value: value}]
      };
      if (this.cid) {
        args.cid = this.cid;
      }

      const updated = {
        metaFieldId: this.field.id,
        value: value,
        cid: this.cid,
      };

      if (this.doEmitOnly) {
        this.$emit('updated', updated);
      } else {
        this.$store.dispatch('set' + this.storeName + 'MetaValues', args).then(() => {
          this.$emit('updated', updated)
        });
      }
      this.setValue(value);
      this.editing = false;
    },
    setValue(value) {
      if (!this.updatedValue) {
        this.updatedValue = value;
      }
      this.updatedValue = value
    },
    getValue() {
      if (this.hasUpdateValue()) {
        return this.updatedValue;
      }

      if (this.hasValue()) {
        return this.value;
      }

      if (this.hasDefault()) {
        return this.field.default;
      }
      return null;
    },
    hasValue() {
      return this.value && this.value.length > 0;
    },
    hasUpdateValue() {
      return this.updatedValue && this.updatedValue.length > 0;
    },
    hasDefault() {
      return !!this.field.default;
    },
  },
}
</script>

<style lang="scss" scoped>
.meta-field {
  background-color: $meta-field-background-color;
}

pre {
  color: #fff;
}

.meta-field-label {
  font-size: 1em;
  margin-right: 10px;
  width: 20%;
  min-width: 100px;

  &.editing {
    background-color: $input-background-color;
  }
}

.meta-field-value {
  background-color: darken($meta-field-background-color, 10%);
  border: 1px solid darken($meta-field-background-color, 15%);
}

td, th {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

td.default-value {
  width: 100px;
}

table tr {
  border: 1px solid darken($meta-field-background-color, 10%);
}

.table-edit {
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  border-right: 1px solid $input-background-color;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 0.8;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}
</style>
