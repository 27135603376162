<template>
  <two-column-layout
      :id="$route.params.id"
      back-button-tab-selector="sfxbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="assemblytab"
  >
    <template slot="mainContent">
      <div class="row">
        <h1 class="mb-3 ml-3">{{ name ? name : $store.getters.getAssemblyName($route.params.id) }}</h1>
        <div class="w-100" />
        <div class="ml-3 mb-2 d-inline-block settings-button" @click="loadSfx">{{ $t('reload') }}</div>
        <div class="w-100" />

        <!--UPDATE 3D Data-->
        <div
            v-if="$store.getters.getAssemblyPipelineStatus($route.params.id) ==='finished' && canUpdateAssemblyMetaData"
            class="col-12 col-md-8"
        >
          <info-panel
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="UpdateSfxData"
              preview-id="sfxImport"
          >
            <div slot="info" class="col-12 col-md-8 mb-3 bg-beige p-3">
              <div v-if="!versionStarted && !startingVersion ">
                <div class="col-12 col-md-8 form-error border-radius">
                  {{ $t('makeAVersionFirst') }}
                </div>
                <div class="settings-button d-inline-block mr-1 mb-2"
                     @click="createVersion">
                  {{ $t('createNewType', {type: 'backup'}) }}
                </div>
                <br/>
                {{ $t('Update3dHint') }}<br/>
                <div class="settings-button d-inline-block mt-3"
                     @click="$router.push('/library/3d-data/' + $route.params.id +'/update-cad')">
                  {{ $t('Update3dData') }}
                </div>
              </div>
              <loading-panel v-else-if="startingVersion"
                             :time-in-seconds="0"
                             message="pleaseWait"/>

              <div v-else-if="versionStarted">
                <div class="col-12 col-md-8 bg-dark p-3">
                  {{ $t('versioningProcessStarted') }}
                </div>
              </div>
            </div>
          </info-panel>

        </div>

        <!--UPDATE META DATA-->
        <div
            v-if="$store.getters.getAssemblyPipelineStatus($route.params.id) ==='finished' && canUpdateAssemblyMetaData"
            class="col-12 col-md-8"
        >
          <info-panel
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="UpdateMetaData"
              preview-id="fileProcessing"
          >
            <div slot="info" class="row p-2">
              <meta-data-updater
                  v-if="content"
                  :remote-content="content"
                  @processing="showTheDamnMessage"
              />
            </div>
          </info-panel>
        </div>


        <!--UPDATE INSTANCE DATA-->
        <div
            v-if="$store.getters.getAssemblyPipelineStatus($route.params.id) ==='finished' && canUpdateAssemblyMetaData"
            class="col-12 col-md-8"
        >
          <info-panel
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="UpdateHierarchy"
              preview-id="fileProcessing"
          >
            <div slot="info" class="row p-2">
              <instance-updater
                  v-if="content"
                  :remote-content="content"
                  @processing="showTheDamnMessage"
              />
            </div>
          </info-panel>
        </div>

        <!--APPLY MATERIAL MAPPING-->
        <div
            v-if="$store.getters.getAssemblyPipelineStatus($route.params.id) === 'finished'"
            class="col-12 col-md-8 meta-field-value"
        >
          <info-panel
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="Apply Material Mapping"
              preview-id="matmap"
          >
            <div slot="info" class="pl-3">
              <apply-material-mapping
                  v-if="$store.getters.getAssemblyOrganization($route.params.id)"
                  :asset-id="$route.params.id"
              >
              </apply-material-mapping>
            </div>
          </info-panel>
        </div>

        <!--ITEM IS PROCESSING-->
        <div v-if="showMessage" class="col-12 col-md-8">
          {{ $t("UpdateProcessStarted") }}
        </div>
        <div
            v-else-if="$store.getters.getAssemblyPipelineStatus($route.params.id) === 'processing'"
            class="col-12 col-md-8"
        >
          {{ $t("AssemblyProcessing") }}
        </div>
        <!--Please wait message while loading pipeline status-->
        <div class="col-12 col-md-8">
          <loading-panel
              v-if="loading"
              message="loading"
          />
        </div>
      </div>

    </template>
  </two-column-layout>
</template>

<script>
import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
import InfoPanel from "@/components/InfoPanel";
import {email} from "vuelidate/lib/validators";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

import ApplyMaterialMapping from "@/components/assemblies/ApplyMaterialMapping";
import LoadingPanel from "../components/LoadingPanel";
import MetaDataUpdater from "../components/assemblies/MetaDataUpdater";
import InstanceUpdater from "../components/assemblies/InstanceUpdater";
export default {
  name: "SFXEdit",
  components: {
    TwoColumnLayout,
    InfoPanel,
    ApplyMaterialMapping,
    LoadingPanel,
    MetaDataUpdater,
    InstanceUpdater
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      /**
       * Display only: name of the assembly
       * */
      name: '',

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),

      content: {},
      canUpdateHierarchy: false,
      canUpdateAssemblyMetaData: false,
      teams: [],

      showMessage: false,
      hasNoVersions: true,
      versionStarted: false,
      startingVersion: false,
      loading: false,
    };
  },
  validations: {
    email: {
      email,
    },
  },
  beforeMount() {
    this.loadSfx();
  },
  methods: {
    showTheDamnMessage() {
      console.log('showing message...')
      this.showMessage = true;
    },
    async createVersion() {
      this.startingVersion = true;
      await this.$store.dispatch('clientAssetToPipeline', {
        id: this.$route.params.id,
        type: 'createVersion',
        virtualMachine: null,
        config: JSON.stringify({
          email: this.email,
          sendMails: true,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        })
      }).then(() => {
        this.startingVersion = false;
        this.versionStarted = true;
      }).catch(() => {
        // todo: show error
      })
    },
    confirmUpdate() {
      this.$store.dispatch('clientFinishPipelineTask', {
        id: this.$route.params.id
      });
    },
    setVM(id) {
      this.vm = id;
    },
    async setPermissions() {
      this.canUpdateHierarchy = await this.$store.dispatch(
          "checkTeamOrgPermission",
          {
            teams: this.teams,
            op: "updateSfxHierarchy",
            organizationId: this.$store.getters.getAssemblyOrganization(this.$route.params.id),
          }
      );
      this.canUpdateAssemblyMetaData = await this.$store.dispatch("checkTeamOrgPermission",
          {
            teams: this.teams,
            op: "write",
            organizationId: this.$store.getters.getAssemblyOrganization(
                this.$route.params.id
            ),
          }
      );
    },
    async loadSfx() {
      this.loading = true;
      this.showMessage = false;
      await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id,
        include: 'tags,metaSets,teams,versions',
        addPipelineState: true,
      })
          .then((data) => {
            this.setContent();
            this.teams = data.teams ? data.teams : [];
            this.hasNoVersions = data.versions.length === 0;
            this.name = data.name;
            this.loading = false;
          })
          .catch((e) => {
            if (e.status === 401) {
              this.$router.push("/access-denied");
            } else if (e.status === 404) {
              this.$router.push('/not-found')
            }
          });
    },
    setContent() {
      if (this.$store.getters.getAssemblyContent(this.$route.params.id)) {
        // this.content = this.$store.getters.getAssemblyContent(this.$route.params.id);
        this.content = {pipeline: {sendMails: true, email: ""}};
        if (this.content.pipeline.sendMails !== undefined) {
          this.sendMails = this.content.pipeline.sendMails;
        } else {
          this.sendMails = true;
        }
        if (this.content.pipeline.email) {
          this.email = this.content.pipeline.email;
        }
      }
      this.setPermissions();
    },

  },
};
</script>

<style lang="scss" scoped>
.connection-delete {
  cursor: pointer;
}
</style>
