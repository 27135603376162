<template>
  <div>
    <tabs slot="tabs"
          :classes="'col-12 col-md-2 col-lg-2 offset-md-1 backbutton'"
          :id="$route.params.id"
          tab-selector="vmbackbutton"
          custom-tab-name="vmbackbutton"
          :first-inactive="true"
    />
    <div slot="mainContent">
      <h1>
        <icon class="mr-2" type="desktop" size="0.9"/>
        {{ createMode ? $t('createNewType', {type: 'VirtualMachine'}) : name }}
      </h1>
      {{ id }}

      <div class="lighter" v-if="!createMode">
        {{ $t('state') }}
      </div>
      <select style="max-width: 200px;" class="custom-select" @change="update('state')" v-if="!createMode" v-model="state">
        <option :key="index" :value="item" v-for="(item, key, index) in VMStates">{{ key }}</option>
      </select>

      <p>Attention: the name has to match the name of the virtual machine in the azure portal and is case-sensitive</p>
      <label-with-edit-icon-and-content
          class="mt-3"
          label="name"
          :value="name"
          v-if="!isEditing('name')"
          @edit="setEditing('name')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('name')"
          type="text"
          :class="['form-text mt-1 mb-1', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
          :id="'name'"
          v-model="name"
          :placeholder="$t('enternamehere')"
          @input="delayTouch($v.name)"
      >
      <div v-if="!createMode && isEditing('name')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('name')">
          <icon :type="'save'"/>
        </div>
      </div>

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{
          $t('errors.required')
        }}
      </div>
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNum">{{ $t('errors.alphaNumOnly') }}</div>

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{
          $t('errors.atMostCharacters', {num: 128})
        }}
      </div>

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{
          $t('errors.atLeastCharacters', {num: 3})
        }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="displayName"
          :value="displayName"
          v-if="!isEditing('displayName')"
          @edit="setEditing('displayName')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('displayName')"
          type="text"
          :class="['form-text mt-1 mb-1', $v.displayName.$dirty && $v.displayName.$error ? 'form-group--error' : '']"
          id="displayName"
          v-model="displayName"
          :placeholder="$t('enternamehere')"
          @input="delayTouch($v.displayName)"
      >
      <div v-if="!createMode && isEditing('displayName')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('displayName')">
          <icon :type="'save'"/>
        </div>
      </div>

      <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.required && $v.displayName.$error">{{
          $t('errors.required')
        }}
      </div>
      <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

      <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.maxLength">{{
          $t('errors.atMostCharacters', {num: 128})
        }}
      </div>

      <div class="form-error" v-if="$v.displayName.$dirty && !$v.displayName.minLength">{{
          $t('errors.atLeastCharacters', {num: 3})
        }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="description"
          :value="description"
          v-if="!isEditing('description')"
          @edit="setEditing('description')"
          :editable="!createMode"
          :show-value="!createMode"
      />
      <div>
        <textarea
            v-if="createMode || isEditing('description')"
            :class="['form-text mb-1', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
            id="description"
            v-model="description"
            :placeholder="$t('enterdeschere')"
            @input="delayTouch($v.description)"
        />
        <div v-if="!createMode && isEditing('description')">
          <div class="edit-trigger mr-2" @click="cancelEditing()">
            <icon :type="'times'"/>
          </div>
          <div class="edit-trigger" @click="update('description')">
            <icon :type="'save'"/>
          </div>
        </div>
        <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">
          {{ $t('errors.atMostCharacters', {num: 128}) }}
        </div>
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="realPricePerMinute"
          :value="realPricePerMinute"
          v-if="!isEditing('realPricePerMinute')"
          @edit="setEditing('realPricePerMinute')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('realPricePerMinute')"
          type="number"
          :class="['form-text mt-1 mb-1', $v.realPricePerMinute.$dirty && $v.realPricePerMinute.$error ? 'form-group--error' : '']"
          id="realPricePerMinute"
          v-model="realPricePerMinute"
          :placeholder="$t('realPricePerMinute')"
          @input="delayTouch($v.realPricePerMinute)"
      >
      <div v-if="!createMode && isEditing('realPricePerMinute')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('realPricePerMinute')">
          <icon :type="'save'"/>
        </div>
      </div>
      <div class="form-error"
           v-if="$v.realPricePerMinute.$dirty && !$v.realPricePerMinute.required && $v.realPricePerMinute.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="clientPricePerMinute"
          :value="clientPricePerMinute"
          v-if="!isEditing('clientPricePerMinute')"
          @edit="setEditing('clientPricePerMinute')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('clientPricePerMinute')"
          type="number"
          :class="['form-text mt-1 mb-1', $v.clientPricePerMinute.$dirty && $v.clientPricePerMinute.$error ? 'form-group--error' : '']"
          id="clientPricePerMinute"
          v-model="clientPricePerMinute"
          :placeholder="$t('clientPricePerMinute')"
          @input="delayTouch($v.clientPricePerMinute)"
      >
      <div v-if="!createMode && isEditing('clientPricePerMinute')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('clientPricePerMinute')">
          <icon :type="'save'"/>
        </div>
      </div>
      <div class="form-error"
           v-if="$v.clientPricePerMinute.$dirty && !$v.clientPricePerMinute.required && $v.clientPricePerMinute.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="executionSlots"
          :value="executionSlots"
          v-if="!isEditing('executionSlots')"
          @edit="setEditing('executionSlots')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('executionSlots')"
          type="number"
          :class="['form-text mt-1 mb-1', $v.executionSlots.$dirty && $v.executionSlots.$error ? 'form-group--error' : '']"
          id="executionSlots"
          v-model="executionSlots"
          :placeholder="$t('executionSlots')"
          @input="delayTouch($v.executionSlots)"
      >
      <div v-if="!createMode && isEditing('executionSlots')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('executionSlots')">
          <icon :type="'save'"/>
        </div>
      </div>
      <div class="form-error"
           v-if="$v.executionSlots.$dirty && !$v.executionSlots.required && $v.executionSlots.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="url"
          :value="url"
          v-if="!isEditing('url')"
          @edit="setEditing('url')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />
      <input
          v-if="createMode || isEditing('url')"
          type="text"
          :class="['form-text mt-1 mb-1', $v.url.$dirty && $v.url.$error ? 'form-group--error' : '']"
          id="url"
          v-model="url"
          :placeholder="$t('url')"
          @input="delayTouch($v.url)"
      >
      <div v-if="!createMode && isEditing('url')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('url')">
          <icon :type="'save'"/>
        </div>
      </div>
      <div class="form-error"
           v-if="$v.url.$dirty && !$v.url.required && $v.url.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="tenantId"
          :value="tenantId"
          v-if="!isEditing('tenantId')"
          @edit="setEditing('tenantId')"
          :editable="!createMode"
          :show-value="!createMode"
      />
      <input
          v-if="createMode || isEditing('tenantId')"
          type="text"
          :class="['form-text mt-1 mb-1']"
          id="tenantId"
          v-model="tenantId"
          :placeholder="$t('tenantId')"
      >
      <div v-if="!createMode && isEditing('tenantId')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('tenantId')">
          <icon :type="'save'"/>
        </div>
      </div>
      <label-with-edit-icon-and-content
          class="mt-3"
          label="resourceGroupName"
          :value="resourceGroupName"
          v-if="!isEditing('resourceGroupName')"
          @edit="setEditing('resourceGroupName')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />

      <input
          v-if="createMode || isEditing('resourceGroupName')"
          type="text"
          :class="['form-text mt-1 mb-1']"
          id="resourceGroupName"
          v-model="resourceGroupName"
          :placeholder="$t('resourceGroupName')"
      >
      <div v-if="!createMode && isEditing('resourceGroupName')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('resourceGroupName')">
          <icon :type="'save'"/>
        </div>
      </div>
       <div class="form-error"
           v-if="$v.resourceGroupName.$dirty && !$v.resourceGroupName.required && $v.resourceGroupName.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="subscriptionId"
          :value="subscriptionId"
          v-if="!isEditing('subscriptionId')"
          @edit="setEditing('subscriptionId')"
          :editable="!createMode"
          :show-value="!createMode"
          :mandatory="true"
      />

      <input
          v-if="createMode || isEditing('subscriptionId')"
          type="text"
          :class="['form-text mt-1 mb-1']"
          id="subscriptionId"
          v-model="subscriptionId"
          :placeholder="$t('subscriptionId')"
      >
      <div v-if="!createMode && isEditing('subscriptionId')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('subscriptionId')">
          <icon :type="'save'"/>
        </div>
      </div>
      <div class="form-error"
           v-if="$v.subscriptionId.$dirty && !$v.subscriptionId.required && $v.subscriptionId.$error">
        {{ $t('errors.required') }}
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="clientId"
          :value="clientId"
          v-if="!isEditing('clientId')"
          @edit="setEditing('clientId')"
          :editable="!createMode"
          :show-value="!createMode"
      />
      <input
          v-if="createMode || isEditing('clientId')"
          type="text"
          :class="['form-text mt-1 mb-1']"
          id="clientId"
          v-model="clientId"
          :placeholder="$t('clientId')"
      >
      <div v-if="!createMode && isEditing('clientId')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('clientId')">
          <icon :type="'save'"/>
        </div>
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="secret"
          :value="secret"
          v-if="!isEditing('secret')"
          @edit="setEditing('secret')"
          :editable="!createMode"
          :show-value="!createMode"
      />
      <input
          v-if="createMode || isEditing('secret')"
          type="password"
          :class="['form-text mt-1 mb-1']"
          id="secret"
          v-model="secret"
          :placeholder="$t('secret')"
      >
      <div v-if="!createMode && isEditing('secret')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('secret')">
          <icon :type="'save'"/>
        </div>
      </div>

      <label-with-edit-icon-and-content
          class="mt-3"
          label="principalName"
          :value="principalName"
          v-if="!isEditing('principalName')"
          @edit="setEditing('principalName')"
          :editable="!createMode"
          :show-value="!createMode"
      />
      <input
          v-if="createMode || isEditing('principalName')"
          type="text"
          :class="['form-text mt-1 mb-1']"
          id="principalName"
          v-model="principalName"
          :placeholder="$t('principalName')"
      >
      <div v-if="!createMode && isEditing('principalName')">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="update('principalName')">
          <icon :type="'save'"/>
        </div>
      </div>

      <Button
          type="button"
          class="mt-3 mb-1"
          :deactivated="!enableSaving"
          @click="save"
          v-if="createMode"
      >
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import { alphaNumSpace, alphaNum } from '@/customValidators';
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
import { VMStates } from "@/enum";

export default {
  name: "VirtualMachineEdit",
  components: {
    Button,
    Icon,
    LabelWithEditIconAndContent
  },
  mixins: [VuelidateMixin],
  props: {
    id: {type: String, default: ''}
  },
  data() {
    return {
      VMStates: VMStates,
      enableSaving: false,
      name: '',
      displayName: '',
      description: '',
      realPricePerMinute: 0,
      clientPricePerMinute: 0,
      executionSlots: 2,
      url: '',
      tenantId: '',
      resourceGroupName: '',
      subscriptionId: '',
      clientId: '',
      secret: '',
      principalName: '',
      state: 'm',

      createMode: true,
      fieldEditing: '',
    };
  },
  watch: {
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
    id() {
      this.createMode = !this.id;
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNum,
    },
    displayName: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
    },
    description: {
      maxLength: maxLength(1024),
    },
    realPricePerMinute: {
      required
    },
    clientPricePerMinute: {
      required
    },
    executionSlots: {
      required
    },
    url: {
      required,
    },
    resourceGroupName: {
      required
    },
    subscriptionId: {
      required
    }
  },
  beforeMount() {
    this.createMode = !this.id;
    if (this.id) {
      this.loadVirtualMachine();
    }
  },
  methods: {
    setEditing(fieldName) {
      this.fieldEditing = fieldName;
    },
    isEditing(fieldName) {
      return this.fieldEditing === fieldName;
    },
    cancelEditing: function () {
      this.fieldEditing = '';
      this.loadVirtualMachine();
    },
    loadVirtualMachine() {
      this.$store.dispatch('loadVirtualMachine', {
        id: this.id,
      }).then(data => {
        let $this = this;
        Object.keys(data).map(key => {
          $this[key] = data[key];
        })
      });
    },
    save: function () {
      if (this.enableSaving) {
        let args = {
          name: this.name,
          displayName: this.displayName,
          description: this.description,
          realPricePerMinute: this.realPricePerMinute,
          clientPricePerMinute: this.clientPricePerMinute,
          state: this.state,
          executionSlots: this.executionSlots,
          url: this.url,
          tenantId: this.tenantId,
          resourceGroupName: this.resourceGroupName,
          subscriptionId: this.subscriptionId,
          clientId: this.clientId,
          secret: this.secret,
          principalName: this.principalName
        };
        if (this.teamId) {
          args.teams = [this.teamId];
        }
        this.$store.dispatch('createVirtualMachine', args).then(vm => {
          this.$router.push('/admin/vm/' + vm.id + '/general');
        });
      }
    },
    update: function (fieldName, newVal = null) {
      if (!this.$v.$anyError) {
        let value = newVal || newVal === 0 ? newVal : this[fieldName];
        let args = {
          id: this.id,
          [fieldName]: value,
        };
        this.$store.dispatch('updateVirtualMachine', args).then(() => {
          this.loadVirtualMachine();
        });
        this.fieldEditing = '';
      }
    },
  },
}
</script>

<style scoped>

</style>