<template>
    <one-column-sidebar-left
      back-button-tab-selector="formbackbutton"
      sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight">
            <Button
                    type="button"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <v-form-creation-form
              @enableSaving="item => enableSaving = item"
              ref="form"
              :show-codemirror="$store.getters.isSuperAdmin"
              @created="redirect"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import vFormCreationForm from "@/components/vForm/vFormCreationForm"
    export default {
        name: "FormAdd",
        components: {
            Button,
            OneColumnSidebarLeft,
            Icon,
            vFormCreationForm
        },
        data() {
          return {
            enableSaving: false,
          };
        },
        methods: {
            save: async function() {
                this.$refs.form.save();
            },
            redirect(id) {
              this.$router.push('/library/form/' + id + '/general');
            }
        },
    }
</script>