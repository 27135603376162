<template>
  <div :class="['vform-header position-relative w-100', config.emphasized ? 'vform-emphasized' : '']">
    <slot name="dropzone"></slot>
    <div class="w-100"/>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <div class="text"
         contenteditable="false"
         @input="onInput"
         @keydown.enter="leaveEditing(uniqueId)"
         :id="uniqueId"
         @click.stop="doNothing"
    >
      {{ state.progress }} / {{ state.totalSlides }}
      <!--<component
          :is="config.headingCategory ? config.headingCategory : 'h3'"
          :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: !config.emphasized && config.fontColor ? config.fontColor : fontColor ? fontColor : '',
            fontFamily: getFontName()
          }"
          :class="[config.backgroundColor ? 'with-bg' : 'without-bg', config.emphasized ? 'vform-emphasized' : '']"
      >{{ getLabel(config, lang) ? getLabel(config, lang) : placeholders.SHORT }}</component>-->
      </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "Headline",
  components: {
    EditorOverlay
  },
  mixins: [ElementMixin],
  props: {
    state: {type: Object, required: true}
  },
  data() {
    return {
      localString: ""
    };
  },
  beforeMount() {
    this.setUniqueId();
    this.setLocalString();
  },
  mounted() {
    this.increaseCounter();
    this.addListener(document, "click", this.documentClick, 'headline-click');
  },
  methods: {
    setLocalString() {
      this.localString = this.getLabel(this.config, this.lang);
    },
    documentClick() {
      this.leaveEditing(this.uniqueId);
    },
    onInput(e) {
      this.setLabel(this.config, this.lang, e.target.innerText);
    },
    save() {
      this.editing = false;
    },
    setEditing() {
      if(this.editable) {
        this.editing = true;
        this.setCursor(this.uniqueId);
      }
    },
    validate() {
        return true;
    },
    checkRequired() {
        return true;
    },
    getData() {
      return {data: this.getLabel(this.config, this.lang), type: 'headline'};
    }
  }
}
</script>

<style lang="scss" scoped>
  .vform-header {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    margin-bottom: 0.7em;
    h1, h2, h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      text-transform: none;
      font-weight: 700;
      color: #000;
      &.with-bg {
        width: calc(100.5% + 42px);
        margin-left: -21px;
        padding: 1em 21px;
      }
    }
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.7em;
    }
    h3 {
      font-size: 1.3em;
    }
    &.emphasized {
      background-color: var(--vform-editor-layout-accent-color);
      color: var(--vform-editor-emphasized-font-color);
      padding: 0.5rem 1rem;
    }
  }
</style>