<template>
<div>
  <div class="mb-2">
    Log: {{ logId }} / for step: {{ activeStepObject.uuid }}

  </div>
  <div class="form-error p-2" v-if="error">
    {{ error }}
  </div>
  <div class="mb-4" :key="row.id + forceReRenderKey" v-for="row in rows">
    {{ row.createdAt }}<br />
    {{ row.data[11] }}<br />{{ dateTimeFromUnix(row.data[3]) }}<br />
    sessionId: {{ row.data[1] }}<br />
    userId: {{row.data[0]}}<br />
    {{ row.data[2]}} {{ row.data[15] }} <br />answer: {{ row.data[10]}}
  </div>
  <div class="settings-button d-inline-block dark" @click="createAggregationRow" v-if="rows && rows.length">Create aggregation row</div>
  <div class="mb-4" :key="log.id + forceReRenderKey" v-for="log in logs">
    {{ log.createdAt }} // {{ log.key }} // {{ log.data[11]}}<br />
    <span class="lighter">Date:</span> {{ dateTimeFromUnix(log.data[3]) }}<br />
    <span class="lighter">User ID:</span> {{ log.data[0] }}<br />
    <span class="lighter">Session ID:</span> {{ log.data[1] }}<br />
    <span class="lighter">Project:</span> {{ log.data[12] }}<br />
    <span class="lighter">Type:</span> {{ log.data[2] }}<br />

    <div class="mt-1 mb-2 darker p-2 border-radius" :key="index + log.id" v-for="(item, index) in getLogDataArray(log)">
      <span class="lighter">Question:</span> {{ item.question }} <br />
      <span class="lighter">Element ID:</span> {{ item.elementId ? item.elementId : 'N/A' }} <br />
      <!--:initial-active-value="tryGetResponse(item.question)"-->
      <div class="vform-label white mt-3">Connected element</div>
      <drop-down
          class="dark"
          :initial-active-value="item.elementId ? item.elementId : null"
          :values="stepElements.map(item => {return item.uuid})"
          :labels="stepElements.map(item => {return item.label.dix.Unknown + ' | (' + item.formElementTypeString + ')'})"
          @select="(uuid) => {setQuestionValue(log.id, index, uuid, getLogDataArray(log))}"
      />

      <div class="lighter mt-3">Answers:</div>
      <div class="mb-5" :key="answer + answerIndex" v-for="(answer, answerIndex) in item.answers">
       {{ answerIndex }}: {{ answer.answer ? answer.answer : 'N/A' }} <span v-if="getAnswerSelected(answer) !== 'noselect'">{{ getAnswerSelected(answer) }}</span>
        <template  v-if="item.elementId && getAnswerSelected(answer) !== 'noselect'">
          <div class="vform-label white mt-3">Connected answer</div>
          <drop-down
              class="dark"
              :initial-active-value="answer.optionId ? answer.optionId : null"
              :values="getOptionUuids(item.elementId)"
              :labels="getOptionLabels(item.elementId)"
              @select="(uuid) => {setOptionValue(log.id, index, answerIndex, uuid, getLogDataArray(log))}"
          />
        </template>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DateMixin from "@/components/mixins/DateMixin.js";
import loggingMixin from "./viewer/loggingMixin.vue";
import { vFormControls } from "@/enum";
import DropDown from "../forms/DropDown.vue";
export default {
  name: "LogCorrector",
  components:  {
    DropDown
  },
  mixins: [DateMixin, loggingMixin],
  props: {
    logId: {type: String, required: true},
    activeStepObject: {type: Object, required: true},
    globals: {type: Object, default: () => {return {}}}
  },
  data() {
    return {
      error: "",
      stepElements: [],
      vFormControls: vFormControls,
      logs: [],
      rows: [],
      forceReRenderKey: 0
    }
  },
  beforeMount() {
    this.setStepElements();
    this.getLogEntriesForStep();
  },
  methods: {
    getAnswerSelected(answer) {
      return typeof answer.selected === 'boolean' ? (answer.selected ? 'selected' : 'not selected') : 'noselect'
    },
    getOptionUuids(uuid) {
      const el = this.stepElements.find(item => {
        return item.uuid === uuid;
      })
      if(el && el.options) {
        return el.options.map(item => {
          return item.uuid;
        })
      }
    },
    getOptionLabels(uuid) {
      const el = this.stepElements.find(item => {
        return item.uuid === uuid;
      })
      if(el && el.options) {
        return el.options.map(item => {
          return item.text.dix.Unknown;
        })
      }
    },
    async setOptionValue(logEntryId, questionIndex, answerIndex, answerUuid, data) {
      const index = this.logs.findIndex(item => {
        return item.id === logEntryId;
      })
      data[questionIndex].answers[answerIndex].optionId = answerUuid;
      this.$set(this.logs[index].data, "data", data);
      await this.$store.dispatch('clientUpdateDatasetRow', {
        id: this.logId,
        datarowId: logEntryId,
        data: {
          data: data
        }
      }).then(() => {
        this.forceReRenderKey++;
      })
    },
    async setQuestionValue(logEntryId, questionIndex, questionUuid, data) {
      const index = this.logs.findIndex(item => {
        return item.id === logEntryId;
      })
      data[questionIndex].elementId = questionUuid;
      this.$set(this.logs[index].data, "data", data);
      await this.$store.dispatch('clientUpdateDatasetRow', {
        id: this.logId,
        datarowId: logEntryId,
        data: {
          data: data
        }
      }).then(() => {
        this.forceReRenderKey++;
      })
    },
    tryGetResponse(question) {
      const index = this.stepElements.findIndex(item => {
        return item.label.dix.Unknown === question;
      })
      if(index !== -1) {
        return this.stepElements[index].uuid;
      }
      return null;
    },
    setStepElements() {
      const elements = this.globals && this.globals.elements ? [...this.globals.elements, ...this.activeStepObject.elements] : this.activeStepObject.elements;
      this.stepElements = elements.filter(item => {
        return [vFormControls.CHECKBOXES, vFormControls.CHECKBOXES2, vFormControls.RADIOBUTTONS, vFormControls.SHORT_ANSWER, vFormControls.LOGIN].includes(item.formElementTypeString)
      })
    },
    getLogEntriesForStep() {
      this.loadLog();
    },
    getLogDataArray(entry) {
      const data = entry.data[15];
      for(let i = 0; i < data.length; i++) {
        const item = data[i];
        if(Array.isArray(item)) {
          data[i] = item[0];
        }
      }
      return data;
    },
    async createAggregationRow() {
      const uniqueSessions = [];
      this.rows.map(item => {
        const sessionId = item.data[1]
        if(!uniqueSessions.includes(sessionId)) {
          uniqueSessions.push(sessionId);
        }
      });
      console.log('unique Sessions')
      console.log(uniqueSessions)
      const aggregationRows = [];
      for(let i = 0; i < uniqueSessions.length; i++) {
        const sessionId = uniqueSessions[i]
        const targetRows = this.rows.filter(item => {
          return item.data[1] === sessionId
        })
        let baseRow = {};
        for(let j = 0; j < targetRows.length; j++) {
          const row = targetRows[j]
          if(!baseRow.createdAt) {
            baseRow = JSON.parse(JSON.stringify(row));
            baseRow.data[2] = 'Aggregation';
            baseRow.data[15] = [];
          }
          const values = row.data[10];
          const valueArr = values.split(';')
          baseRow.data[15].push({
            question: valueArr[0],
            answers: [{
              answer: valueArr[1] !== 'null'  ? valueArr[1] : null
            }]
          })
        }
        if(baseRow && baseRow.createdAt) {
          aggregationRows.push(baseRow)
        }
      }
      for(let i = 0; i < aggregationRows.length; i++) {
        await this.$store.dispatch('createDatasetRow', {...aggregationRows[i], id: this.logId})
            .then(async datarow => {
              return await this.$store.dispatch('clientUpdateDatasetRow', {
                id: this.logId,
                datarowId: datarow[0].id,
                key: this.activeStepObject.uuid,
                createdAt: aggregationRows[i].createdAt
              })
            })
      }
      await this.loadLog();
      console.log(aggregationRows)
    },
    loadLog() {
      this.error = "";
      this.rows = []
      this.$store.dispatch('clientLoadDatasetRows', {
        id: this.logId,
        limit: 0,
        sort: '-createdAt'
      }).then(rows => {
        if(!rows || !rows.length) {
          this.error = this.$t('logIsEmpty')
          return;
        }
        rows = rows.filter(item => {
          return item.data[11] === this.activeStepObject.uuid || item.key === this.activeStepObject.uuid;
        })
        this.logs = rows.filter(item => {
          return item.data[2] === "Aggregation"
        });
        // eslint-disable-next-line no-constant-condition
        if(!this.logs.length) {
          console.log('no logs found')
          this.rows = rows.filter(item => {
            return (["NeutralAnswer", "Checkbox"].includes(item.data[2]) || !item.data[2]) && !!item.data[0]
          });
          console.log(this.rows)
        }
        if(!this.rows.length) {
          console.log('nothing found??')
          console.log(rows)
        }
      }).catch(e => {
        console.log(e);
        // todo: fehlermeldungen anzeigen
      })
    }
  }
}
</script>