<template>
  <main-table-layout
      sub-menu-selector="appspanel"
  >
    <div slot="buttonsRight"/>
    <content-filter
        id="appList"
        slot="filter"
        :type-filter-items="['app', 'webapp']"
        :team-filtering="true"
        store-name="App"
        @filter="filtering"
    />
    <view-switcher
        id="mainContent"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />
    <pagination
        id="projecttablepage"
        ref="pagination"
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getAppListOptions(listName, 'pagination_items')"
        @setActive="loadPaginatedData"
    />
    <div slot="mainContentButtons">
      <Button
          icon="plus"
          link="/app/add"
          permission="create" type="action-button">
        {{ $t('createNewApp') }}
      </Button>
    </div>

    <Button
        slot="mainContent"
        class="icon-button"
        icon="redo"
        type="reload"
        @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
    />
    <table-widget
        :key="forceReRenderKey"
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
        id="appsDefaultTable"
        slot="mainContent"
        :data="$store.getters.getAppsByListName(listName)"
        :deletable="true"
        :fields="tableFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :show-organization="true"
        :show-tags="true"
        :title-as-links="true"
        :use-team-permission="true"
        delete-permission="delete"
        edit-permission="write"
        resource="Project"
        :initial-sorting="sortObject"
        @deleteRow="deleteRow"
        @sort="sort"
        @updateContent="updateContent"
    />

    <thumbnails
        :key="forceReRenderKey"
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
        id="appsDefaultTable"
        slot="mainContent"
        :data="$store.getters.getAppsByListName(listName)"
        :deletable="true"
        :fields="thumbnailFields"
        :loading="loading"
        :nav-by-clicking-row="true"
        :title-as-links="true"
        :use-team-permission="true"
        delete-permission="delete"
        edit-permission="write"
        resource="Project"
        @sort="sort"
        @updateContent="updateContent"
        @delete-row="deleteRow"
    />
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import TableWidget from "../components/widgets/TableWidget";
import ContentFilter from "../components/widgets/filter/ContentFilter";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
import {getCCLightAssetIds, getAppPanelAssetIds} from "@/utils"
export default {
  name: 'Apps',
  components: {
    Button,
    TableWidget,
    ContentFilter,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
  },
  mixins: [LoadOverviewMixinJs],
  data() {
    return {
      registerMethod: 'registerAppsQueryParams',
      loadMethod: 'loadApps',
      include: 'tags,teams,instances',
      deleteMethod: 'deleteApp',
      listName: 'appList',
      type: 'app',
      forceReRenderKey: 0,
      tableFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
        },
        {
          name: 'Type',
          id: 'type',
          type: 'string',
        },
        {
          name: 'Slug',
          id: 'slug',
          type: 'appSlug',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Status',
          id: 'state',
          type: 'state',
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
      thumbnailFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Organization',
          id: 'organization',
          type: 'organization',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
        },
        {
          name: 'Tags',
          id: 'tags',
          type: 'tags',
        },
        {
          name: 'Status',
          id: 'state',
          type: 'state',
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
    }
  },
  watch: {
    '$route.path'() {
     this.init();
    }
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.setType();
      this.setFilter();
      this.loadData(false);
    },
    setType() {
      this.type = this.$route.meta.type;
      if(this.type) {
        this.listName = 'apps-' + this.type;
      } else {
        this.type = null;
        this.listName = 'appList';
      }
      this.forceReRenderKey++;
    },
    updateContent(args) {
      this.$store.dispatch('updateApp', args);
    },
    setFilter() {
      let instances = []
      switch(this.type) {
        case 'appPanel':
          console.log('filtering by app panel')
          instances = getAppPanelAssetIds();
          break;
        case 'cclight':
          console.log('filtering by cclight')
          instances = getCCLightAssetIds()
          break;
        default:
          console.log('setting instances to null')
          instances = []
          break;
      }
      if(instances.length) {
        this.filter = {add: {
            instances: {
              [this.listName]: instances
            }
          }
        }
      } else {
        delete this.filter.add.instances;
      }
      console.log(this.filter)
    }
  }
}
</script>
