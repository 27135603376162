<template>
    <div>
        <label v-if="!templateEditing" class="vform-editor-label section">Label</label>
        <div v-if="!templateEditing" class="float-right dflex">
            <label class="vform-editor-label section">Identifier </label>
            <info-helper text="isUserIdentifierHint" />
        </div>
        <div v-if="!templateEditing" class="d-flex mb-1 row" :key="index" v-for="(field, index) in element.fields">
                <div class="label-field ml-3 mr-3">
                    <input
                        :title="field.label.dix[currentLang]"
                        :placeholder="
                            currentLang === 'Unknown'
                                ? 'USERID'
                                : $t('vform.translationMessage', {
                                      key: currentLang,
                                      content: field.label.dix['Unknown'],
                                  })
                        "
                        class="mb-2 form-text v-form-label form-text-dark"
                        type="text"
                        v-model="field.label.dix[currentLang]"
                    />
                    <div
                        class="icon-delete-option"
                        @click="removeField(index)"
                    >
                      <icon :size="'1.85'" type="fat-square-minus" />
                    </div>
            </div>
            <div class="right-of-label-field justify-content-center">
              <label
                  class="container"
                  style=""
                  :for="'chkID' + $vnode.key + index"
              >
                <input
                    type="checkbox"
                    :id="'chkID' + $vnode.key + index"
                    v-model="field.isID"
                    @change="isIdUpdated(field)"
                />
                <span class="radio"></span>
              </label>
            </div>
        </div>
        <div v-if="!templateEditing" class="w-100 mt-1 text-right col" style="padding-right: 34px">
            <span @click="addField(element.currentLang)">
                <icon :size="'2.0'" type="fad-square-plus" />
            </span>
        </div>
        <label class="label-prop-tab-separator mt-4"> {{ $t("Button") }}</label>
        <div v-if="!templateEditing" class="prop-tab-separator" />
        <div v-else class="prop-tab-separator mb-4" />
        <v-form-button
            :element="element.loginButton"
            :step-no="stepNo"
            :project-id="projectId"
            :current-lang="currentLang"
            :organization-id="organizationId"
            :allow-tile="false"
            :steps="steps"
            :template-editing="templateEditing"
            :selected-template="selectedTemplate.loginButton"
        />
      <div v-if="!templateEditing" class="col-12 pl-0 dflex mt-4">
        <label
            :for="'ckNotify' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("askForTestOrLiveMode") }}
          <input
              :id="'ckNotify' + $vnode.key"
              v-model="element.askForTestOrLiveMode"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
</template>
<script>
import { vFormControls, placeholders } from "@/enum";
import vFormButton from "@/components/vForm/vFormBUTTON";
import { v4 as uuidv4 } from "uuid";
import Icon from "@/components/Icon";
import InfoHelper from "@/components/InfoHelper";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";

export default {
    name: "vFormLOGIN",
    components: {
        Icon,
        vFormButton,
        InfoHelper
    },
    mixins: [vFormElementMixin],
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
        projectId: { type: String, default: "" },
        steps: { type: Array, default: null },
    },
    data() {
        return {
            forceReRenderKey: 0,
            vFormControls: vFormControls,
            placeholders: placeholders,
        };
    },
    beforeMount() {
        this.element.formElementType = this.vFormControls["LOGIN"];
        let buttonElement = {
            currentLang: this.element.currentLang,
            formElementTypeString: this.vFormControls["LOGIN"],
            formElementType: this.vFormControls["LOGIN"],
            //linkedSlide: step.linkedSlides ? { uuid: step.linkedSlides[0] } : "",
            label: {
                dix: {
                    Unknown: "",
                },
                imageDix: {
                    Unknown: "",
                },
            },
            expanded: true,
            uuid: uuidv4(),
            askForTestOrLiveMode: false,
        };

        if (!this.element.loginButton) {
            this.$set(this.element, "loginButton", buttonElement);
        }

        if (!this.element.fields) {
            let field = {
                label: {
                    dix: {
                        Unknown: "",
                    },
                },
                isID: true,
            };

            this.$set(this.element, "fields", [field]);
        }

      if (!this.element.loginButton.buttonStyle){
        this.$set(this.element.loginButton, "buttonStyle", "Text");
      }
      if(!this.element.askForTestOrLiveMode) {
        this.$set(this.element, "askForTestOrLiveMode", false)
      }
    },
    methods: {
        addField() {
            let field = {
                label: {
                    dix: {
                        Unknown: "",
                    },
                },
                isID: false,
            };
            this.element.fields.push(field);
        },
        removeField(index) {
            this.element.fields.splice(index, 1);
        },
        isIdUpdated(fieldItem) {
            if (fieldItem.isID) {
                this.element.fields.forEach((field) => {
                    if (field !== fieldItem) {
                        field.isID = false;
                    }
                });
            }

            this.forceReRenderKey++;
        },
    },
};
</script>
<style lang="scss">
.vform-container {
    .info-helper {
        margin: auto 2px 4px 8px;
        cursor:pointer;
        .tooltip {
            left: -270px;
            width: 300px;
            min-width: 10px;
        }
    }
}
</style>