<template>
    <div @click.prevent="$emit('click')">
        <datetime
                v-model="date"
                :use12-hour="true"
                :type="type"
                :auto="true"
                :value-zone="moment.tz.guess()"
                @close="save(date)"
                auto-continue
                auto-close
                @created="openPicker(e)"
                ref="datepicker"
        />
    </div>
</template>

<script>
    import DateMixin from '@/components/mixins/DateMixin.js';
    import Vue from 'vue';
    import {Datetime} from 'vue-datetime'
    // You need a specific loader for CSS files
    import 'vue-datetime/dist/vue-datetime.css'
    Vue.use(Datetime);

    export default {
        name: 'DatePickerWidget',
        components: {
            datetime: Datetime,
        },
        mixins: [
            DateMixin,
        ],
        props:{
            value: {
                type:String,
                default: null
            },
            open: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                default: 'datetime',
            }
        },
        data() {
            return {
                date: null,
            };
        },
        watch: {
            value(val) {
                if(val) {
                    this.date = val;
                }
            }
        },
        beforeMount() {
            if (this.value === '' ||
                this.value === 'undefined') {
                this.date = this.$moment();
            } else {
                this.date = this.value;
            }
        },
        methods: {
            openPicker: function(e){
                this.$refs.datepicker.open(e);
            },
            save: function (date = null) {
                if(date) {
                    if(this.type === 'time') {
                      this.$emit('save', this.timeFromISO(date))
                    } else {
                      this.$emit('save', date);
                    }
                }
                else {
                    this.$emit('save', this.date);
                }

            },
        },
    }
</script>
