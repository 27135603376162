<template>
    <div>
        <!--IMAGEDIX-->
        <div
            style="cursor: pointer"
            v-if="element.currentLang === key"
            :key="index3 + 1000000000 + forceReRenderKey"
            v-for="(key, index3) in Object.keys(imageDix)"
            class="d-flex"
        >
            <div v-if="showMediaNameOnly() && (imageDix['Unknown'] || imageDix[key])" class="mr-1 col row">
              <label class="vform-editor-label">{{ mediaName }} </label>
            </div>
          <div @click="selectImage(index3)">
            <preview v-if="!showMediaNameOnly() && (imageDix['Unknown'] || imageDix[key])"
                :class="[
                    previewSize + '-thumbnail mb-1',
                    showDefaultImage ? 'default-imageDix' : '',
                ]"
                :asset-or-project-id="imageDix[key]"
                :preview-uri="
                    $store.getters['getMediaPreviewUri'](
                        showDefaultImage ? imageDix['Unknown'] : imageDix[key]
                    )
                "
                :type="'image'"
                :preview-id="showDefaultImage ? imageDix['Unknown'] : imageDix[key]"
                :removable="false"
                :title="
                    showDefaultImage
                        ? 'Please select ' +
                          key +
                          ' image. Will use default image otherwise'
                        : ''
                "
            />
          </div>
          <div class="vform-alignment-button" v-if="!vSTAGEContext && !imageDix['Unknown'] && !imageDix[key]" @click="uploadImage">
            <icon type="upload" size="1" />
          </div>
          <div class="vform-alignment-button" v-if="showMediaNameOnly()  && (imageDix['Unknown'] || imageDix[key])" @click="selectImage(index3)">
            <icon type="folder" size="1" />
          </div>
          <div v-if="!imageDix['Unknown'] && !imageDix[key]" class="vform-alignment-button" @click="selectImage(index3)">
            <icon type="folder" size="1" />
          </div>
        </div>
        <popup
            @close="
                () => {
                    showSelect = false;
                }
            "
            v-if="showSelect"
        >
            <div slot="popupMainContent" v-if="showSelect" class="w-100">
                    <!-- <h2>{{ $t("image") }}</h2> -->
                    <!--FOR EACH TRANSLATION ONE FIELD-->
                    <div
                        v-if="element.currentLang === key"
                        :key="index3 + 1000000000 + forceReRenderKey"
                        v-for="(key, index3) in Object.keys(imageDix)"
                        class="row"
                    >
                            <div class="col-12 col-md-3 col-xl-4 col-xxl-3">
                                <content-display
                                    class="bg-dark"
                                    list-name="generalList"
                                    type="media"
                                    :value="imageDix[key]"
                                    :show-preview="true"
                                    :hide-upper-name="true"
                                />
                                <span v-if="imageDix[key]" @click="
                                        imageDix[key] = '';
                                        forceReRenderKey++;
                                        showSelect = false;
                                    ">
                                    <icon :size="'1.5'" type="trash" />
                                </span>
                            </div>
                            <content-list
                                class="col-12 col-md-9 col-lg-8"
                                v-if="editImage === '/' + index3"
                                @save="
                                    (id) => {
                                        imageDix[key] = id;
                                        editImage = null;
                                        showSelect = false;
                                        loadImage();
                                    }
                                "
                                :media-category="mediaCategory"
                                type="media"
                                :organization-ids="[organizationId]"
                                :list-name="'vformImages' + mediaCategory"
                                :show-preview="true"
                                :thumbnail-view="true"
                            />
                        </div>
                    </div>
        </popup>
        <popup
            @close="
                  () => {
                      showUpload = false;
                  }
              "
            v-if="showUpload"
        >
          <div slot="popupMainContent" v-if="showUpload" class="w-100">
              <media-add-form :organization-id="organizationId" @saved="mediaAdded"></media-add-form>
          </div>
        </popup>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import ContentList from "../widgets/editors/ContentList";
import ContentDisplay from "../widgets/editors/ContentDisplay";
import Popup from "@/components/Popup";
import Preview from "@/components/preview/Preview";
import MediaAddForm from "@/components/forms/MediaAddForm";

export default {
    name: "VFormImageDixCell",
    components: {
      MediaAddForm,
        Icon,
        //Button,
        ContentList,
        ContentDisplay,
        Popup,
        Preview,
    },
    props: {
        imageDix: { type: Object, required: true },
        element: { type: Object, required: true },
        previewSize: { type: String, required: false, default: "small" },
        organizationId: { type: String, default: "" },
        mediaCategory: {type: Number, default: null,},
        vSTAGEContext: {type: Boolean, default: false}
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: null,
            showSelect: false,
            showUpload: false,
            mediaName: "",
            showFileNameForMediaTypes: [1, 6, 8]
        };
    },
    computed: {
      showDefaultImage() {
        return this.imageDix[this.element.currentLang].length === 0;
      },
    },
    beforeMount() {
        this.loadImage();
    },
    methods: {
        showMediaNameOnly(){
          return this.showFileNameForMediaTypes.includes(this.mediaCategory);
        },
        loadImage() {
            if (this.imageDix[this.element.currentLang]) {
                this.$store.dispatch("loadMedia", {
                    id: this.imageDix[this.element.currentLang],
                }).then((data) => {
                  this.mediaName = data.name;
                });
            }
        },
        addImgLang(lang) {
            this.imageDix[lang] = "";
            this.forceReRenderKey++;
        },
        selectImage(index) {
            this.editImage = "/" + index;
            this.showSelect = true;
            this.forceReRenderKey++;
        },
        uploadImage(){
            this.showUpload = true;
        },
        mediaAdded(assetId){
          this.imageDix[this.element.currentLang] = assetId;
          this.showUpload = false;
          this.loadImage();
          this.forceReRenderKey++;
        }
    },
};
</script>

<style lang="scss" scoped>
.small-thumbnail {
    width: 40px;
}
.default-imageDix {
    opacity: 0.3;
}

</style>
