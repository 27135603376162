<template>
    <div>
        <div :key="index" class="form-group" v-for="(field, index) in values">
            <label for="from">{{ $t(labels[index]) }}</label>
            <input
                    class="form-text"
                    id="from"
                    ref="from"
                    @keydown.enter="save"
                    type="number"
                    v-model.number="values[index]">
        </div>
        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    export default {
        name: "VectorWidget",
        components: {
            Button,
            Icon
        },
        props: {
            value: {type: Array || String, default: [0, 0, 0]},
        },
        data() {
            return {
                values: [0, 0],
                labels: ['from', 'to'],
            };
        },
        beforeMount() {
            let arr = this.value;
            if (!Array.isArray(this.value)) {
                arr = JSON.parse(this.value)
            }
            this.values = arr;

        },
        methods: {
            save: function () {
                this.$emit('save', this.values);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .form-group {
        position: relative;
        margin-right: 15px;
    }

    label {
        margin-bottom: 0;
    }

    .fieldset {
        background-color: $panel-title-background-color;
        padding:15px;
        margin-bottom:15px;
    }

    .label-left {
        float: left;
        margin: 0;
        padding-top: ceil($text-input-padding / 2);
        margin-right: 15px;
    }
</style>