<template>
<div>
  <div v-if="editMode">
    <div class="p-1 bg-beige border-radius mb-2" :key="index" v-for="(context, index) in contexts">
      <span class="lighter">Context {{ index + 1 }}: </span>
        <drop-down
            :exclude-values="contexts.map(item => {return item.realm})"
            :initial-active-value="contexts[index].realm"
            :labels="Object.values(RegexContexts)"
            :values="Object.values(RegexContexts)"
            class="w-100"
            @select="(value) => {setContextName(value, index)}"
        />
      <div @click="removeContext(index)" class="d-inline-block ml-2 clickable float-right mt-2"><icon type="trash" /></div>

      <checkbox-widget :initial-value="context.autoApply"
                       class="vform-checkbox-label-text mt-2"
                       :label-for="'chkRadio' + index + Math.random().toString(36).substring(2, 7)"
                       label-text="Auto apply"
                       type="checkbox"
                       @change="(val) => {setAutoApply(index, val)}"
      />
    </div>
    <div class="settings-button d-inline-block mt-2" @click="addContext">add context</div>
  </div>
  <div v-else>
    <div class="d-flex context-item" :key="index" v-for="(context, index) in contexts">
      <div class="name">{{ context.realm }}</div>
      <checkbox-widget :initial-value="context.autoApply"
                       class="vform-checkbox-label-text d-inline-block lighter"
                       :label-for="'chkRadio' + index + Math.random().toString(36).substring(2, 7)"
                       label-text="Auto apply"
                       type="checkbox"
                       :disabled="true"
      />
    </div>
  </div>
</div>
</template>
<script>
import {SpecialUuids, RegexContexts} from "@/enum";
import Icon from "@/components/Icon.vue";
import DropDown from "@/components/forms/DropDown.vue";
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";
export default {
  name: "ContextEditor",
  components: {CheckboxWidget, DropDown, Icon},
  props: {
    metaValues: {type: Array, default: () => []},
    assetId: {type: String, required: true},
    editMode: {type: Boolean, default: false}
  },
  data() {
    return {
      contexts: [],
      RegexContexts
    };
  },
  watch: {
    metaValues() {
      this.getContextMetaValue();
    }
  },
  beforeMount() {
   this.getContextMetaValue();
  },
  methods: {
    setAutoApply(index, value) {
      this.$set(this.contexts[index], 'autoApply', value);
    },
    setContextName(realm, index) {
      this.contexts[index].realm = realm;
    },
    removeContext(index) {
      this.contexts.splice(index, 1);
    },
    addContext() {
      this.contexts.push({
        realm: RegexContexts.SFX_UPDATE + Date.now(),
        autoApply: false
      })
    },
    async saveContextValue() {
        return this.$store.dispatch('clientSaveAssetMetaSetValues', {
          id: this.assetId,
          values: [{
            metaFieldId: SpecialUuids.CONTEXT_META_FIELD,
            value: JSON.stringify(this.contexts)
          }]
        })
    },
    getContextMetaValue() {
        if(this.metaValues && this.metaValues.length) {
          const contexts = this.metaValues.find(item => {
            return item.metaFieldId === SpecialUuids.CONTEXT_META_FIELD
          })
          if(contexts) {
            try {
              this.contexts = JSON.parse(contexts.value);
            } catch (e) {
              console.log(e);
              console.log('could not parse context value')
            }
          }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.context-item {
  max-width: 300px;
  .name {
    width: 120px;
  }
}
</style>