<template>
  <div class="w-100">
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Text"
        :initially-collapsed="false"
        preview-id="textElements"
    >
    <div class="block-container" slot="info">
      <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
        <div
            :class="['form-block', getBlockDraggableClasses(vFormControls.HEADLINE)]"
            :elementType="vFormControls.HEADLINE"
        >
          <h2 class="vform-editor-label">
            {{ $t("vform.headline") }}
          </h2>
          <h3 class="mt-3 mb-0">
            {{ $t("vform.helloworld") }}
          </h3>
        </div>
      </div>
      <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
        <div
            :class="['form-block', getBlockDraggableClasses(vFormControls.WEBSITE)]"
            :elementType="vFormControls.WEBSITE"
        >
          <h2 class="mb-2 vform-editor-label">
            {{ $t("vform.website") }}
          </h2>
          <a
              target="_blank"
              class="mt-1 mb-0 dark-ui-primary"
          >http://example.com</a
          >
        </div>
      </div>
      <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
        <div
            :class="['form-block', getBlockDraggableClasses(vFormControls.TEXT)]"
            :elementType="vFormControls.TEXT"
        >
          <h2 class="vform-editor-label">
            {{ $t("vform.textfield") }}
          </h2>
          <label>{{ placeholders.LONG }}</label>
        </div>
      </div>
    </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Form"
        :initially-collapsed="false"
        preview-id="textElements"
    >
      <div class="block-container" slot="info">
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.RADIOBUTTONS)]"
              :elementType="vFormControls.RADIOBUTTONS"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.singlechoice") }}
            </h2>
            <div class="pl-0 mt-2">
              <label class="container"
              >Option 1
                <input type="checkbox" disabled />
                <span class="radio" />
              </label>
            </div>
            <div class="pl-0">
              <label class="container"
              >Option 2
                <input
                    type="checkbox"
                    disabled
                    checked="checked"
                />
                <span class="radio" />
              </label>
            </div>
            <div class="pl-0">
              <label class="container mb-0"
              >Option 3
                <input type="checkbox" disabled />
                <span class="radio" />
              </label>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.CHECKBOXES)]"
              :elementType="vFormControls.CHECKBOXES"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.multichoice") }}
            </h2>
            <div class="pl-0">
              <label class="container"
              >Option 1
                <input type="checkbox" disabled />
                <span class="checkmark" />
              </label>
            </div>
            <div class="pl-0">
              <label class="container"
              >Option 2
                <input
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <span class="checkmark" />
              </label>
            </div>
            <div class="pl-0">
              <label class="container mb-0"
              >Option 3
                <input
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <span class="checkmark" />
              </label>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.SHORT_ANSWER)]"
              :elementType="vFormControls.SHORT_ANSWER"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.shortanswer") }}
            </h2>
            <textarea rows="3" cols="" class="form-text-dark mt-2" disabled>Lorem ipsum dolor sit amet</textarea>
          </div>
        </div>

        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.FORM_SUMMARY)]"
              :elementType="vFormControls.FORM_SUMMARY"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.formSummary") }}
            </h2>
          </div>
        </div>

        <!--Those are basu blocks-->
        <div v-if="false" class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.LIST_MATCH)]"
              :elementType="vFormControls.LIST_MATCH"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.listMatch") }}
            </h2>
            <div class="form-block-image">
              <icon type="list-ul" size="3.3" />
            </div>
          </div>
        </div>
        <div v-if="false" class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.LIST_MATCH_TEXT_INPUT)]"
              :elementType="vFormControls.LIST_MATCH_TEXT_INPUT"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.listMatchTextInput") }}
            </h2>
            <div class="form-block-image">
              <icon type="list-dropdown" size="3.3" />
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.BUTTON)]"
              :elementType="vFormControls.BUTTON"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.button") }}
            </h2>
            <div class="form-block-image">
              <button>NEXT ></button>
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.LOGIN)]"
              :elementType="vFormControls.LOGIN"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.login") }}
            </h2>
            <!-- <div class="form-block-image">
                <button>LOGIN</button>
            </div> -->
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses()]"
              :elementType="vFormControls.LOGOUT"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.logout") }}
            </h2>
            <!-- <div class="form-block-image">
                <button>LOGOUT</button>
            </div> -->
          </div>
        </div>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Media"
        :initially-collapsed="false"
        preview-id="mediaElements"
    >
      <div class="block-container" slot="info">
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.IMAGE)]"
              :elementType="vFormControls.IMAGE"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.image") }}
            </h2>
            <div class="form-block-image">
              <icon type="image" size="3.3" />
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.VIDEO)]"
              :elementType="vFormControls.VIDEO"
          >
            <h2 class="vform-editor-label">
              {{ $t("video") }}
            </h2>
            <div class="form-block-image">
              <icon type="video" size="3.3" />
            </div>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.AUDIO)]"
              :elementType="vFormControls.AUDIO"
          >
            <h2 class="vform-editor-label">
              {{ $t("audio") }}
            </h2>
            <div class="form-block-image">
              <icon type="audio" size="3.3" />
            </div>
          </div>
        </div>
<!--        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">-->
<!--          <div-->
<!--              class="form-block draggable"-->
<!--              :elementType="vFormControls.PDF"-->
<!--          >-->
<!--            <h2 class="vform-editor-label">-->
<!--              {{ $t("pdf") }}-->
<!--            </h2>-->
<!--            <div class="form-block-image">-->
<!--              <icon type="pdf" size="3.3" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.QR_CODE)]"
              :elementType="vFormControls.QR_CODE"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.qrcode") }}
            </h2>
            <div class="form-block-image">
              <icon type="qrcode" size="3.3" />
            </div>
          </div>
        </div>

        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.SENSOR)]"
              :elementType="vFormControls.SENSOR"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.sensor") }}
            </h2>
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
              :class="['form-block', getBlockDraggableClasses(vFormControls.CODE_SNIPPET)]"
              :elementType="vFormControls.CODE_SNIPPET"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.codeSnippet") }}
            </h2>
            <div class="form-block-image">
              <icon type="code" size="3.3" />
            </div>
          </div>
        </div>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Layout"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              class="form-block draggable"
              :class="['form-block', getBlockDraggableClasses(vFormControls.SEPARATOR)]"
              :elementType="vFormControls.SEPARATOR"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.divider") }}
            </h2>
            <div class="mt-3 separatorBlock" />
          </div>
        </div>
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              class="form-block draggable"
              :class="['form-block', getBlockDraggableClasses(vFormControls.VERTICAL_SPACE)]"
              :elementType="vFormControls.VERTICAL_SPACE"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.verticalSpace") }}
            </h2>
            <div class="mt-3 verticalSpaceBlock">
              <icon
                  :size="'1.55'"
                  class=""
                  type="arrows-up-down"
              />
            </div>
          </div>
        </div>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Live Data"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.PART_INFO)]"
              :elementType="vFormControls.PART_INFO"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.partInfo") }}
            </h2>
          </div>
        </div>
        <div
            v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
            class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.PROGRESS_TRACKER)]"
              :elementType="vFormControls.PROGRESS_TRACKER"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.progressTracker") }}
            </h2>
          </div>
        </div>
      </div>
    </info-panel>
  </div>
</template>

<script>
import { vFormControls, placeholders } from "@/enum";
import Icon from "../Icon";
import InfoPanel from "../InfoPanel";
export default {
    name: "BlockPanel",
    components: {
      InfoPanel,
      Icon,
    },
    props: {
      state: {type: Object, required: true},
      hotspotPopupAllowedElementTypes: {type: Array, default: null},
    },
    data() {
        return {
          vFormControls: vFormControls,
          placeholders: placeholders,
        };
    },
    methods: {
      getBlockDraggableClasses(elementType){
        if (this.state.hotspotPopupVisible) {
          if (this.hotspotPopupAllowedElementTypes.find((x) => {
            return x === elementType
          })) {
            return "draggable";
          }
          else{
            return "disabled"
          }
        }
        else{
          return "draggable";
        }
      }
    }
};
</script>

<style lang="scss" scoped>
.block-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.form-block .checkmark {
  background: var(--vform-editor-ui-tertiary-color);
  border: solid 1px var(--vform-editor-ui-secondary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .checkmark {
  background-color: gray;
}
.form-block .radio {
  background: var(--vform-editor-ui-tertiary-color);
  //border: solid 1px var(--vform-editor-ui-quaternary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .radio {
  background-color: gray;
}
.form-block {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  //border: solid 4px var(--vform-editor-panel-active-background-color;
  border-radius: 8px;
  min-height: 50px;
  height: 100%;
  padding: 12px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  -moz-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 8px 6px -5px black;
  //box-sizing: border-box;
  //margin: 3px;

  &.disabled {
    opacity: 0.2;
  }
}
.form-block-image {
  display: flex;
  justify-content: center;
  line-height: 30px;
  color: var(--vform-editor-ui-tertiary-color);
  button {
    color: var(--vform-editor-ui-quaternary-color);
    background-color: var(--vform-editor-ui-tertiary-color);
    border-radius: 4px;
    border: solid 1px var(--vform-editor-ui-secondary-color);
    font-size: 0.95rem;
    min-width: 100px;
    line-height: 25px;
  }
}
.form-block a {
  text-decoration: underline;
  font-size: 10px;
}
.form-block input,
textarea {
  width: 100%;
  cursor: pointer;
}
.form-block {
  &:not(.disabled):hover {
    // border: solid 2px #da5e06;
    cursor: move;
    background-color: var(--vform-editor-ui-quinary-color);
    opacity: 0.99;
    //padding: 14px;
  }
  &.disabled {
    cursor: not-allowed;

    > textarea, span, label{
      cursor: not-allowed;
    }
  }
}
.form-block h2 {
  //font-weight: 700;
  //font-size: 13px;
}
.form-block {
  h3,
  label {
    color: var(--vform-editor-ui-tertiary-color);
    font-weight: 400;
    //font-size: 0.8rem;
  }
}
.form-block input,
textarea {
  border-width: 1px !important;
  color: var(--vform-editor-ui-quaternary-color) !important;
  background-color: var(--vform-editor-ui-tertiary-color) !important;
}
.form-block label.container {
  font-size: 10px;
}
.draggable {
  z-index: 10000;
}
.info-panel {
  background-color: var(--vform-editor-panel-active-background-color);
}
.separatorBlock {
    background-color: var(--vform-editor-ui-tertiary-color);
    height: 3px;
}

.verticalSpaceBlock {
    border-top: solid 1px var(--vform-editor-ui-tertiary-color);
    border-bottom: solid 1px var(--vform-editor-ui-tertiary-color);
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.block-container {
    .form-block input,
    textarea,
    span,
    label {
        cursor: inherit;
        font-size: 10px;
    }
}
</style>
