<template>
    <main-table-layout
            :has-top-bar="false"
            :tab-selector="!$route.params.type || $route.params.type !== 'taskmanager' ? 'datasettab' : ''"
            type="dataset"
            :back-button-tab-selector="!$route.params.type || $route.params.type !== 'taskmanager' ? 'datasetbackbutton' : ''"
            :id="$route.params.id"
    >
        <navigation-panels-container
            slot="subMenu"
            id="library"
            :tab-selector="!$route.params.type || $route.params.type !== 'taskmanager' ? 'librarypanel' : 'adminpanel'"
        />
        
        <info-panel
                slot="sidebarLeft"
                :created="$store.getters.getDatasetCreationDate(this.$route.params.id)"
                :last-change="$store.getters.getDatasetChangeDate(this.$route.params.id)"
                :organization="$store.getters.getDatasetOrganization($route.params.id)"
                preview-id="datasetEdit"
                :limit-tags-to-organization="$store.getters.getDatasetOrganization($route.params.id)"
                :type="$store.getters.getDatasetType(this.$route.params.id)"
        />

        <div class="row" slot="mainContent">
          <div class="col-12">
            <content-title-block
                    v-if="$store.getters.getDatasetName(this.$route.params.id)"
                    :value="$store.getters.getDatasetName(this.$route.params.id)"
                    :id="$route.params.id"
                    store-name="Dataset"
                    :organization-id="$store.getters.getDatasetOrganization($route.params.id)"
                    icon="table"
            />
          <div class="w-100"/>
            <content-description-block
                    :value="$store.getters.getDatasetDescription(this.$route.params.id)"
                    :id="$route.params.id"
                    store-name="Dataset"
            />
          </div>

          <div class="w-100"/>
          <div class="col-12 col-md-6">
            <info-panel
                    :collapsible="true"
                    :initially-collapsed="false"
                    class="main-content-info-panel"
                    :created="$store.getters.getDatasetCreationDate(this.$route.params.id)"
                    :last-change="$store.getters.getDatasetChangeDate(this.$route.params.id)"
                    :organization="$store.getters.getDatasetOrganization($route.params.id)"
                    preview-id="datasetEdit"
                    :limit-tags-to-organization="$store.getters.getDatasetOrganization($route.params.id)"
                    :type="$store.getters.getDatasetType(this.$route.params.id)"
            >
              <div slot="info">
                <lifetime-setter
                    class="mt-3"
                    @setValue="updateLifetime"
                    :value="$store.getters.getDatasetRowLifetime(this.$route.params.id)"
                />
              </div>
            </info-panel>
          </div>

          <div class="col-12 col-md-6">
            <team-attacher
                v-if="$store.getters.getDatasetOrganization($route.params.id)"
                  :id="$route.params.id"
                  :teams="teams"
                  store-name="DataSet"
                  :limit-by-organization-id="$store.getters.getDatasetOrganization($route.params.id)"
                  @added="loadDataSetTeams"
                  @removed="loadDataSetTeams"
              />
          </div>
          <div class="col-12">
            <dataset
                    :deletable="true"
                    :id="$route.params.id"
                    :filterable="true"
            />
          </div>
        </div>
    </main-table-layout>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import MainTableLayout from "../layouts/MainTableLayout";
    import NavigationPanelsContainer from "../components/navigation/NavigationPanelsContainer";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import Dataset from "@/components/widgets/dataset/DatasetWidget";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import LifetimeSetter from "@/components/LifetimeSetter";
    export default {
        name: 'DatasetEdit',
        components: {
            InfoPanel,
            MainTableLayout,
            Dataset,
            NavigationPanelsContainer,
            ContentTitleBlock,
            ContentDescriptionBlock,
            TeamAttacher,
            LifetimeSetter,
        },
        data() {
            return {
                id: '',
                teams:[],
                forceReRenderKey: 0
            };
        },
      beforeMount() {
        this.loadDataSetTeams();
      },
      methods: {
          async loadDataSetTeams() {
            this.$store.dispatch('clientLoadDataSetTeams', {
              id: this.$route.params.id
            }).then(data => {
              this.teams = data;
            });
          },
        async updateLifetime(lifetime) {
          this.$store.dispatch('clientSaveDataset', {id: this.$route.params.id, rowLifetime: lifetime}).then(() => {
            this.forceReRenderKey++;
          });
        }
      }
    }
</script>
