<script>
import vFormBUTTONMixin from "./vFormBUTTONMixin.js";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";

export default {
  name: "vFormLOGOUT",
  mixins: [vFormElementMixin, vFormBUTTONMixin],
  beforeMount() {
    this.element.formElementType = this.vFormControls["LOGOUT"];
  },
}
</script>