<template>
<div></div>
</template>

<script>
export default {
  name: "listenerMixin.js",
  data() {
    return {
      listeners: []
    };
  },
  beforeDestroy() {
    this.removeAllListeners();
  },
  methods: {
    addListener(element, event, func, listenerId = null) {
      // prevent the same listener being attached twice
      if(listenerId) {
        const index = this.listeners.findIndex(item => {
          return item.listenerId === listenerId;
        })
        if(index !== -1) {
          return;
        }
      }
      const outerfunc = (e) => {func(e);};
      element.addEventListener(event, outerfunc, true);
      this.listeners.push({
        element,
        event,
        func: outerfunc,
        listenerId
      });
    },
    removeAllListeners() {
      for(let i = 0; i < this.listeners.length; i++) {
        const {element, event, func} = this.listeners[i];
        element.removeEventListener(event, func, true);
      }
      this.listeners = [];
    },
  }
}
</script>