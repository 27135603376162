<template>
  <ul
    id="sfx-menu-content"
    class="row no-gutters"
    v-if="localRoutes && finished"
  >
    <navigation-tab
      v-for="item in localRoutes"
      v-if="item.access"
      :title="item.name"
      :routename="item.name"
      :translate="true"
      :active-class="subIsActive(item.path) ? 'active' : ''"
      :key="item.name"
    />
  </ul>
</template>

<script>
    import {mapState} from 'vuex';
    import NavigationTab from "./NavigationTab";
    export default {
        name: 'MainNavigation',
        components: {
            NavigationTab
        },
        data() {
          return {
            localRoutes: [],
            finished: false,
          };
        },
        computed:
        mapState({
          globalUser: state => state.users.globalUser,
        }),
        watch: {
          globalUser() {
            this.setRoutes();
          }
        },
        beforeMount() {
          if(!this.localRoutes.length) {
            this.setRoutes();
          }
        },
        methods: {
           async setRoutes() {
              this.finished = false;
              const routes = this.$router.options.routes;
              this.localRoutes = routes.filter(item => {
                  return item.navbar;
              });
              for(let i = 0; i < this.localRoutes.length; i++) {
                this.localRoutes[i].access = true;
                if(this.localRoutes[i].meta.accessPermission && this.localRoutes[i].meta.staticPermission) {
                  const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {op: this.localRoutes[i].meta.accessPermission, opOnly: true});
                  this.localRoutes[i].access = hasAccess;
                }
              }
              this.finished = true;
            },
            subIsActive(input) {
                const paths = input.split('/');
                paths.shift();
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 1 // current path starts with this path string
                })
            },
        }
    }
</script>


