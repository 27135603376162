<template>
    <div>
<div class="form-group form-item">
            <div :class="['input-prepend-icon', 'interactive', showPassword ? 'active' : '']" @click="togglePasswordVisibility"><icon :type="showPassword ? 'eye-slash' : 'eye'" /></div>
            <input class="form-text"
                   :type="showPassword ? 'text' : 'password'"
                   ref="text"
                   @keydown.enter="save"
                   :value="value"
            >
        </div>

        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    export default {
        name: "TextWidget",
        components: {
            Icon,
            Button,
        },
        props: {
            value: {type: String, default: null},
        },
        data() {
            return {
                showPassword: false,
            };
        },
        methods: {
            save: function() {
                this.$emit('save', this.$refs.text.value);
            },
            togglePasswordVisibility() {
                this.showPassword = !this.showPassword;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>