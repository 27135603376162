<template>
    <three-column-layout
        tab-selector="projecttab"
        type="project"
        back-button-tab-selector="projectbackbutton"
        :id="$route.params.id"
    >
        <div slot="buttonsLeft"/>
        <div slot="buttonsRight">
            <Button
                    v-if="$route.query.wizard"
                    class="mb-3"
                    :link="'/project/' + this.$route.params.id + '/sfxdata'"
                    :deactivated="false"
            >
                <icon type="save"/> {{ $t('SAVE') }}
            </Button>
        </div>

        <content-filter
            v-if="$store.getters.getProjectOrganization($route.params.id)"
            slot="filter"
            class="mt-3 mb-5"
            @filter="filtering"
            :id="'projectEditSceneFilter'"
            store-name="Project"
            :org-filtering="true"
            :tag-filtering="false"
            :limit-to-organization-ids="[$store.getters.getProjectOrganization($route.params.id), $store.getters.getSuperOrg]"
            :org-filter-local="true"
        />

        <div slot="middleContent">
            <h1 class="mb-5">{{ this.$store.getters.getProjectName(id) }}</h1>
             <pagination
              :limit="paginationLimit"
              :total="$store.getters.getScenesListsOptions(listName, 'pagination_items')"
              :id="listName"
              @setActive="loadPaginatedData"
              ref="pagination"
              slot="mainContentButtons"
          />
            <table-widget
                    class=""
                    :id="'assetsPerProjectTable1'"
                    :fields="fields"
                    :data="$store.getters.getScenesByListName(listName)"
                    :selectable-checkbox="true"
                    :selectable-rows="true"
                    :selected-rows="selectedRows"
                    @selectCheckbox="selectAsset"
                    @selectRow="selectRow"
                    :select-distinct-checkbox="true"
                    :show-organization="true"
                    :show-instances="false"
            />
        </div>
        <div slot="rightContent">
            <preview
                    :removable="false"
                    :preview-uri="previewUri"
                    :key="forceReRenderKey"
                    preview-id="'scenepreview'"
            />
        </div>
    </three-column-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import ThreeColumnLayout from "../layouts/TwoColumns";
    import Preview from "../components/preview/Preview";
    import Pagination from "@/components/Pagination";

    export default {
        name: 'ProjectEditScene',
        components: {
            Button,
            TableWidget,
            ContentFilter,
            ThreeColumnLayout,
            Preview,
            Pagination
        },
        data() {
            return {
                selectedRows: {},
                listName: this.$route.params.id,
                filter: {},
                organizationId: '',
                paginationLimit: 15,
                offset: 0,
                id: null,
                forceReRenderKey: 0,
                previewUri: '',
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                fields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                ],
            }
        },
        watch: {
              queryRelevantInits: function(val) {
                  if(val === this.queryRelevantComponents) {
                      this.loadPaginatedData(this.offset, false);
                  }
              }
        },
        beforeMount() {
            this.id = this.$route.params.id;
            this.$store.dispatch('loadProject', {
                id: this.$route.params.id,
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              } else if(e.status === 404) {
                this.$router.push('/not-found')
              }
            });
        },
        methods: {
            loadPaginatedData(offset, initial) {
              this.offset = offset;
              this.loadData(initial);
            },
            loadData(initial = false) {
              let args = {
                  listName: this.listName,
                  id: this.$route.params.id,
                  keep: {
                    id: this.$route.params.id
                  },
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter.remove) {
                    args.remove = {...this.filter.remove};
                  }
                  args.add.include = {[this.listName]: 'instances'};
                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerScenesQueryParams', args);
                }
                else {
                  args.projectInstanceId = this.$route.params.id;
                  this.$store.dispatch('loadScenes', args);
                }
            },
            filtering: function(args, initial) {
              this.filter = args;
              this.loadData(initial);
            },
            async selectAsset (id, action) {
                if (action === 'add') {
                    await this.$store.dispatch('addProjectScene', {
                        id: this.$route.params.id,
                        listName: this.listName,
                        args: [id],
                    });
                    await this.$store.dispatch('setSceneProperty', {
                        id: id,
                        listName: this.listName,
                        property: 'selected',
                        value: true,
                    });
                } else if (action === 'remove') {
                    await this.$store.dispatch('removeProjectScene', {
                        id: this.$route.params.id,
                        listName: this.listName,
                        args: [id],
                    });
                    /*this.$store.dispatch('setSceneProperty', {
                        id: id,
                        listName: this.listName,
                        property: 'selected',
                        value: false,
                    });*/
                }
                await this.loadPaginatedData(0, false);
            },
            selectRow: function(args) {
                this.previewUri = args.asset.previewUri ? args.asset.previewUri : '';
            }
        },
    }
</script>
