<template>
    <div>
        <label v-if="!templateEditing" class="vform-editor-label section">Text</label>
        <div
            v-if="!templateEditing && element.currentLang === key"
            class="dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="col p-0">
                <textarea
                    :title="element.label.dix['Unknown']"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.LONG
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    rows="300"
                    class="mb-1 form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[key]"
                />
            </div>
        </div>
      <template v-if="!element.emphasized">
        <label class="vform-editor-label section">Background Color</label>
        <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
        <div
            v-else
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="(event) => {
              setColorPickrId(event, 'backgroundColor');
            }"
            class="clickable"
        />
        <label class="vform-editor-label section">Font Color</label>
        <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
        <div
            v-else
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="(event) => {
              setColorPickrId(event, 'fontColor');
            }"
            class="clickable"
        />

        <color-picker
            v-if="!templateEditing"
            v-show="colorPickrId"
            :key="colorPickrId"
            ref="colorpickr"
            :popup-style="true"
            :show-save-button="true"
            :template-editing="templateEditing"
            :value="element[colorPickrId] ? element[colorPickrId] : '#000'"
            class="step-settings-color-pickr"
            @changeColor="(color) => { setItemColor(colorPickrId, color, false); }"
            @close="() => { colorPickrId = null;}"
            @save="(color) => { setItemColor(colorPickrId, color); }"
        />
        <portal to="colorPickerOverlay" :key="index + colorPickrId" v-else>
            <color-picker
                v-show="colorPickrId"
                ref="colorpickr"
                :key="colorPickrId"
                :popup-style="true"
                :show-save-button="true"
                :value="element[colorPickrId] ? element[colorPickrId] : '#fff'"
                class="step-settings-color-pickr"
                @changeColor="(color) => { setItemColor(colorPickrId, color, false);}"
                @close="() => { colorPickrId = null;}"
                @save="(color) => { setItemColor(colorPickrId, color); }"
            />
        </portal>

        <div
            v-if="(!selectedTemplate.backgroundColor && !selectedTemplate.fontColor) && (element.backgroundColor || element.fontColor)"
            class="settings-button d-inline-block mt-2"
            @click="element.backgroundColor = null; element.fontColor = null;">
          {{ $t('resetColor') }}
        </div><br />
      </template>

        <label v-if="!templateEditing" class="vform-editor-label section">Options</label>
        <div v-if="!templateEditing" class="col row dflex">
            <div class="dflex">
                <label
                    class="container vform-editor-body"
                    :for="'chkEmphasized' + $vnode.key"
                    >{{ $t("emphasized") }}
                    <input
                        type="checkbox"
                        :id="'chkEmphasized' + $vnode.key"
                        v-model="element.emphasized"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js.vue";
import { vFormControls, placeholders } from "@/enum";
import ColorPicker from "../widgets/editors/ColorPicker";
import SetByTemplate from "../vForm/SetByTemplate.vue";
export default {
    name: "vFormTEXT",
    components: {
        ColorPicker,
        SetByTemplate
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
    },
    mixins: [vFormElementMixin],
    data() {
        return {
            forceReRenderKey: 0,
            colorPickrId: null,
            vFormControls: vFormControls,
            placeholders: placeholders,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["TEXT"];
    },
};
</script>
