<template>
<div>

</div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";

export default {
  name: "MEDIA_LOADER",
  mixins: [ElementMixin],

}
</script>

<style scoped>

</style>