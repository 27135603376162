<template>
    <div class="tab-switcher">
        <ul class="nav-tabs tabs">
            <li class="nav-item" :key="tab.id" v-for="(tab) in tabs">
                <a @click="setSelected(tab.name)" :class="['nav-link', tab.name === selected ? 'active' : '']">
                    <span>{{ $t(tab.name) }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'TabSwitcher',
        props: {
            id: {type:String, required: true},
            tabs: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                selected: null,
            };
        },
        beforeMount() {
            if(this.$route.query[this.id]) {
                this.setSelected(this.$route.query[this.id]);
            }
            else if(!this.selected) {
                let selected = this.tabs[0].name;
                this.setSelected(selected);
            }
        },
        methods: {
            setSelected: function(selected){
                this.selected = selected;
                if(this.$route.query[this.id] !== selected) {
                    const newQuery = {};
                    Object.assign(newQuery, this.$route.query);
                    newQuery[this.id] = selected;
                    this.$router.push({ path: this.$router.path, query: newQuery });
                    this.$emit('setSelected', selected);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .view-switcher {
        display:inline-flex;
    }
    .view-option {
        background-color: #2a2a2a;
        max-width:35px;
        padding:5px 8px;
        text-align: center;
        font-size:1em;
        border-right: 1px solid #000;
        cursor:pointer;
        &:last-child {
            border-right: 0;
        }
        &:hover, &.active {
            background-color: $highlight;
        }
    }
</style>
