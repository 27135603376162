var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.organizationId)?_c('portal',{attrs:{"to":"filter"}},[_c('content-filter',{key:_vm.forceFilterReRenderKey,ref:"filterComponent",attrs:{"id":'assettable',"store-name":"Project","limit-to-organization-ids":[
                _vm.organizationId,
                '7bc33721-622f-4b87-b9a4-333170f9fa93' ],"org-filter-local":true},on:{"filter":_vm.filter}})],1):_vm._e(),_c('tab-switcher',{attrs:{"id":'projectLinkedDataTab',"tabs":_vm.tabs},on:{"setSelected":_vm.setSelected}}),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[(_vm.getSelectedTab())?_c('view-switcher',{key:_vm.forceReRenderKey + 2,staticClass:"col-4",attrs:{"id":_vm.getSelectedTab().name,"table":true,"thumbnails":true}}):_vm._e(),(_vm.organizationId)?_c('pagination',{key:_vm.storeName,staticClass:"col-4",attrs:{"limit":_vm.paginationLimit,"total":_vm.$store.getters['get' + _vm.storeName + 'ListOptions'](
                            _vm.id,
                            'pagination_items'
                        ),"id":_vm.storeName + 'pagination'},on:{"setActive":_vm.loadPaginatedData}}):_vm._e()],1)]),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"col-6 mt-3"},[(_vm.error)?_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()]),_c('div',{staticClass:"w-100"}),_c('portal-target',{attrs:{"name":"linkerHeaderSection","slim":""}}),(_vm.getSelectedTab() && _vm.organizationId)?_c('asset-table',{key:_vm.forceReRenderKey + 1,staticClass:"col-6 col-xl-6",attrs:{"id":_vm.id,"show-organization":true,"store-name":_vm.getSelectedTab().storeName,"name":_vm.getSelectedTab().name,"organization-id":_vm.organizationId,"project-instance-limit":_vm.getSelectedTab().limit ? _vm.getSelectedTab().limit : null,"table-fields":_vm.getSelectedTab().tableFields ? _vm.getSelectedTab().tableFields : [],"thumbnail-fields":_vm.getSelectedTab().thumbnailFields
                    ? _vm.getSelectedTab().thumbnailFields
                    : [],"pre-content":_vm.getSelectedTab().preContent ? _vm.getSelectedTab().preContent : null,"show-add-button":_vm.getSelectedTab().showAddButton
                    ? _vm.getSelectedTab().showAddButton
                    : false,"show-instances":true,"teams":_vm.teams},on:{"selectCheckbox":_vm.selectAsset,"updateTable":_vm.update,"deleteRow":_vm.deleteRow}}):_vm._e(),(_vm.getSelectedTab().name)?_c('instance-table',{key:_vm.forceReRenderKey,ref:"instanceTable",staticClass:"col-6 col-xl-6 d-none d-md-block",attrs:{"id":_vm.id,"name":_vm.getSelectedTab().name,"store-name":_vm.getSelectedTab().storeName}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }