<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="projectbackbutton"
      tab-selector="projecttab"
      type="project"
  >
    <info-panel :id="$route.params.id"
                slot="sidebarLeft"
                :created="$store.getters.getProjectCreationDate($route.params.id)"
                :downloadable="true"
                :image-editable="true"
                :last-change="$store.getters.getProjectChangeDate($route.params.id)"
                :organization="$store.getters.getProjectOrganization($route.params.id)"
                :preview-uri="$store.getters.getProjectPreviewUri(this.$route.params.id)"
                :removable="true"
                preview-id="projectEdit"
                upload-type="Project"
    >
      <div v-if="$store.getters.isSuperAdmin" slot="info" class="form-section">
        <div class="mt-2 mb-2"><span class="lighter"><icon type="folder"/> STORAGE</span><br/><span class="small">{{
            `${$store.getters.getProjectOrganization($route.params.id)}/projects/${this.$route.params.id}`
          }}</span></div>
      </div>
    </info-panel>

    <team-attacher
        v-if="$store.getters.getProjectOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getProjectOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="Project"
        @added="loadProjectTeams"
        @removed="loadProjectTeams"
    />
    <info-panel
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getProjectOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getProjectTags($route.params.id)"
        preview-id="projectEdit"
        @addTag="addTag"
        @removeTag="removeTag"
    />
    <template slot="mainContent">
      <content-title-block
          v-if="$store.getters.getProjectName(id)"
          :id="$route.params.id"
          :organization-id="$store.getters.getProjectOrganization($route.params.id)"
          :value="$store.getters.getProjectName(id)"
          icon="folder"
          store-name="Project"
      />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getProjectDescription(id)"
          store-name="Project"
      />
      <div class="row">
        <state-setter
            v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember"
            :given-state="$store.getters.getProjectState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="Project"
        />
        <div class="w-100"/>
        <div class="settings-button d-inline-block ml-3" @click="initGraph" v-if="$store.getters.isSuperAdmin && !showGraph">Show graph</div>
        <project-node-editor
            v-if="$store.getters.isSuperAdmin && showGraph && $store.getters.getProjectById($route.params.id)"
            :project="$store.getters.getProjectById($route.params.id)"
            :connected="instances"
            class="ml-3"
        />
        <div class="w-100"/>
        <div class="col-12 col-md-6 mb-2 mt-2">
          <info-panel
              v-if="hasvform"
              class="new-style bright"
              :collapsible="true"
              info-panel-header="publish"
              preview-id="publishpanel">
            <div slot="info">
              <info-panel
                  class="new-style dark"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="TO SITE (WEBAPP)"
                  preview-id="webapppublish">
                <div slot="info">
                  PUBLISHING TO SITE (WEBAPP)
                  <v-form-publisher :project-id="$route.params.id" />
                </div>
              </info-panel>
              <info-panel
                  v-if="canMsGuides || $store.getters.isSuperAdmin"
                  class="new-style dark"
                  :collapsible="true"
                  :initially-collapsed="true"
                  :info-panel-header="$t('365 DYNAMICS GUIDE')"
                  preview-id="webapppublish2">
                <div slot="info">
                      <div class="form-error border-radius p-2" v-html="$t('MsGuidesHint')"></div>
                      <div class="settings-button d-inline-block mb-3" @click="msGuideGetFolders()">Load Folders</div>
                      <loading-spinner v-if="msguidesloading"/>
                      <div v-if="msguideserror" class="form-error">{{ msguideserror }}</div>
                      <div class="w-100"/>
                      <select v-if="msGuidesFolders.length" v-model="selectedFolder" class="custom-select mb-2">
                        <option v-for="(folder, index) in msGuidesFolders" :key="index" :value="folder.msdyn_mrfolderid">
                          {{ folder.msdyn_name }}
                        </option>
                      </select>
                      <Button v-if="selectedFolder || (foldersLoaded && msGuidesFolders.length === 0)" class="mb-2" @click="msGuidePublish">Publish to 365 Dynamics</Button>
                      <div v-if="msGuideMessage" class="mb-2">{{ msGuideMessage }}</div>
                    </div>
              </info-panel>
            </div>
          </info-panel>
        </div>

      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getProjectMetaSets(id)"
                :target-id="$route.params.id"
                store-name="Project"
                @added="loadProject"
                @removed="loadProject"
            />
          </info-panel>
        </div>
        <div class="w-100"/>
        <meta-panel
            v-for="(item) in $store.getters.getProjectMetaSets(id)"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getProjectOrganization($route.params.id), $store.getters.getSuperOrg]"
            :title="item.description" class="col-12 col-md-6"
            icon-left="folder"
            store-name="Project"
            @updated="loadProject"
        />
        <div class="w-100"/>
        <div v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember"
             class="settings-button d-inline-block ml-3 mb-1"
             @click="showInstanceViewer = true">{{ $t('showInstanceViewer') }}</div>
        <project-instance-viewer
            v-if="showInstanceViewer"
            :organization-id="$store.getters.getProjectOrganization($route.params.id)"
            :item-id="$route.params.id"
            class="col-12 col-md-12"
        />
      </div>

    </template>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import Button from "@/components/forms/Button";
import ProjectInstanceViewer from "../components/ProjectInstanceViewer";
import LoadingSpinner from "../components/LoadingSpinner";
import ProjectNodeEditor from "../components/nodeEditors/ProjectNodeEditor.vue";
import vFormPublisher from "@/components/vFormPublisher/vFormPublisher";
export default {
  name: 'ProjectEdit',
  components: {
    MetaPanel,
    InfoPanel,
    OneColumnSidebarLeft,
    ContentTitleBlock,
    ContentDescriptionBlock,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    Button,
    ProjectInstanceViewer,
    LoadingSpinner,
    ProjectNodeEditor,
    vFormPublisher
  },
  data() {
    return {
      id: '',
      teams: [],
      msGuideMessage: "",
      msGuidesFolders: [],
      selectedFolder: "",
      canMsGuides: false,
      msguidesloading: false,
      msguideserror: "",
      foldersLoaded: false,
      showInstanceViewer: false,
      instances: [],
      showGraph: false,
      hasvform: false,
    };
  },
  beforeMount() {
    this.loadProject();
    this.loadProjectTeams();
    this.id = this.$route.params.id;
  },
  mounted() {
    this.loadInstances();
  },
  methods: {
    initGraph() {
      this.showGraph = true;
      this.loadInstances();
    },
    loadInstances() {
      this.$store.dispatch('clientLoadProjectInstances', {id: this.id})
          .then(async instances => {
            this.instances = instances;
            this.hasvform = instances && instances.length ? !!instances.find(item => {return item.type === 'form'}) : false;
          })
    },
    async loadServicePermission(organizationId) {
      this.canMsGuides = await this.$store.dispatch('checkIfService', {
        organizationIds: [organizationId],
        serviceName: 'ms_guides_service'
      });
    },
    loadProjectTeams() {
      this.$store.dispatch('clientLoadProjectTeams', {
        id: this.$route.params.id
      }).then(async data => {
        this.teams = data;
      });
    },
    loadProject() {
      this.$store.dispatch('loadProject', {
        id: this.$route.params.id,
        include: 'organization,tags,metaSets'
      }).then(data => {
        this.loadServicePermission(data.organizationId);
      }).catch(e => {
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeProjectTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addProjectTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateTags: function (tags) {
      this.$store.dispatch('updateProject', {
        id: this.$route.params.id,
        tags: tags,
      });
    },
    msGuidePublish() {
      this.msGuideMessage = "";
      let payload = {
        id: this.$route.params.id,
      }
      if (this.selectedFolder) {
        payload.folderId = this.selectedFolder;
      }
      this.$store.dispatch('clientPublishToMsGuide', payload).then(() => {
        this.msGuideMessage = "Request sent, this can take up to a few minutes. You'll get an email when the process is finished;"
      }).catch(() => {
        this.msguideserror = 'an error ocurred';
      })
    },
    msGuideGetFolders() {
      this.msguidesloading = true;
      this.msguideserror = "";
      this.$store.dispatch('clientGetMsGuidesFolders', {id: this.$route.params.id})
          .then(folders => {
            this.msGuidesFolders = folders;
            this.foldersLoaded = true;
            this.msguidesloading = false;
          }).catch(() => {
            this.msguideserror = 'an error ocurred';
            this.msguidesloading = false;
      })
    },
  },
}
</script>