<template>
  <div class="p-2 mb-3">
    <div class="col-3 pt-2 mb-2">
    </div>
    <div class="col-12"><slot /></div>
  </div>
</template>

<script>
import {vFormControls} from '@/enum';

export default {
  name: "vFormMETA_LIST_FILTER",
  components: {
  },
  props: {
    element: {type: Object, required: true},
  },
  data() {
    return {
      vFormControls: vFormControls
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls['META_LIST_FILTER'];
  },
  methods: {
  }
}
</script>

<style scoped>

</style>