<template>
    <main-table-layout class="newStyle">
      <div class="col-12" slot="mainContent">
      <popup
        @close="() => {selectedTicket = null;}"
        v-if="selectedTicket"
    >
        <div slot="popupMainContent">
          <ticket-detail-view :ticket="selectedTicket" />
        </div>
      </popup>

        <!--Project Selection-->
          <div class="hexenkessel mr-2">
          <div class="project-selector">
            <div :class="['p-2 border-radius d-inline-block clickable project', projectId === project.id ? 'active' : 'passive']" @click="projectId = project.id" :value="project.id" :key="index" v-for="(project, index) in projects">
              <span :class="projectId === project.id ? '' : 'lighter'">{{ project.name }}</span>
            </div>
          </div>
            <icon class="float-left mr-1" size="2.8" type="cauldron"/>
            <h2>Let's cook our little tickets, shall we?</h2>
            <div class="smaller"><i>Please be aware that developers are sensitive creatures</i></div>
          </div>
          <!--Ticket form-->
          <div :class="['ticket-form-wrapper', showTicketForm ? 'active' : 'inactive']">
            <div class="leash clickable" @click="showTicketForm = !showTicketForm">
              <icon type="ticket" size="1.5" /> <div class="text d-inline-block">Ticket-o-mat</div>
            </div>
             <ticket-form
                v-if="projectId"
                :project="projects.filter(item => {return item.id === projectId})[0]"
                :teamId="teamId"
                class="ticket-form-overview"
                @creationSuccess="forceReRenderKey++"
                :key="forceReRenderKey"
           />
          </div>
           <!--<div :class="['filter-wrapper', showFilter ? 'active' : 'inactive']">
            <div class="leash clickable" @click="showFilter = !showFilter">
              <icon type="filter" size="1.5" /> <div class="text d-inline-block">Filter-o-mat</div>
            </div>
             here comes the filter
          </div>-->
          <div class="row">
            <div class="col-12">
              <input type="text" v-model="filterString" />
            </div>
          </div>
          <div class="row" v-if="teamId && projectId">
            <container
              :key="container.id"
              v-for="(container) in containers"
              :container="container"
              :team-id="teamId"
              :project-id="projectId"
              :reload-key="forceReRenderKey"
              :filter-string="filterString"
              @setSelectedTicket="(ticket) => {selectedTicket = ticket}"
          />
          </div>

            </div>
    </main-table-layout>
</template>

<script>
    import MainTableLayout from "../layouts/FullScreenLayout";
    import TicketForm from "@/components/ticketing/TicketForm";
    import Container from "@/components/ticketing/Container";
    import Icon from "@/components/Icon";
    import Popup from "@/components/Popup";
    import TicketDetailView from "@/components/ticketing/TicketDetailView";
    /*const workItemTypes = Object.freeze({
      GRAND_GRAND_PARENT: 'Epic',
      GRAND_PARENT: 'Feature',
      PARENT: 'User Story',
      BUG: 'Bug',
      TASK: 'Task'
  });*/
    export default {
        name: "Help",
        components: {
            MainTableLayout,
            TicketForm,
            Icon,
            Container,
            Popup,
            TicketDetailView
        },
        data() {
            return {
                forceReRenderKey: 0,
                projects: [],
                numOfProjects: 0,
                projectId: null,

                teams: [],
                numOfTeams: 0,
                teamId: null,

                containers: [],
                numOfTickets: 0,

                showTicketForm: false,
                showFilter: false,

                selectedTicket: null,
                filterString: "",
            };
        },
        watch: {
          projectId(val) {
            if(val) {
              this.loadTeams();
            }
          },
        },
        beforeMount() {
          this.$store.dispatch("setNavHideStatus", true);
          this.loadProjects()
        },
        methods: {
          loadProjects() {
            this.$store.dispatch('clientLoadTicketingProjects')
            .then(data => {
              this.projects = data.value;
              this.numOfProjects = data.count;
              if(this.projects.length) {
                this.projectId = this.projects[0].id;
                if(this.projectId) {
                  this.loadTeams();
                }
              }
            })
          },
          loadTeams() {
            this.$store.dispatch('clientLoadTicketingTeams', {projectId: this.projectId})
            .then(data => {
              this.teams = data.value;
              this.numOfTeams = data.count;
              if(this.teams.length === 1) {
                this.teamId = this.teams[0].id;
                this.loadContainers();
              }
            })
          },
          async loadContainers(type) {
             return this.$store.dispatch('clientLoadContainers', {
              projectId: this.projectId,
              teamId: this.teamId,
              type: type,
             }).then(async data => {
              const inbox = data.filter(item => {return item.Title === "Inbox"});
              const release =  data.filter(item => {return item.Title !== "Inbox" && item.Title !== "Backlog"});
              const backlog = data.filter(item => {return item.Title === "Backlog"});
              this.containers =  [...inbox, ...release, ...backlog];
            })
          }
        }
    }
</script>

<style lang="scss" scoped>
  .smaller {
    font-size: 0.9em;
  }
  .custom-select {
    border-radius: 4px;
  }
  .ticket-form-wrapper, .filter-wrapper {
    position: fixed;
    right: -800px;
    width: 800px;
    top: 30px;
    z-index:55;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    &.active {
      right: 0;
    }
    .leash {
      z-index:0;
      position: absolute;
      left: -55px;
      top: 200px;
      background-color: var(--vform-editor-ui-secondary-color);
      padding:8px 12px;
      transform: rotate(-90deg);
      transform-origin: top left;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 1.1rem;
      box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: -8px -8px 6px 0px rgba(0,0,0,0.75);
      .ticket {
        position: absolute;
        left: 12px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      .text {
        margin-top: -3px;
      }
    }
    .ticket-form-overview {
      z-index:1;
      max-height: 90vh;
      overflow: scroll;
    }
  }
  .filter-wrapper {
    .leash {
      top: 390px;
    }
  }
  .hexenkessel {
    padding: 15px;
    padding-left: 60px;
    position: relative;
    background-color: #4d4286;
    border: 1px solid var(--ccdefault-ticketing-container-header-color);
    display: inline-block;
    border-radius: 4px;
    min-width: 60%;
    padding-right: 150px;
    .project-selector {
      position: absolute;
      top: 50%;
      right: 15px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .project {
        &.active, &:hover {
          background-color: #3a3067;
          border: 1px solid #6552ba;
          box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
          -webkit-box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
          -moz-box-shadow: 2px 1px 3px 0px rgb(0 0 0 / 75%) inset;
          color: #ceced1;
        }
        &.passive {
          background-color: #4e4286;
          border: 1px solid #3a3067;
        }
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 15px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    h2 {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
</style>