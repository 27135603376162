<template>
  <one-column-sidebar-left
    sub-menu-selector="adminpanel"
    back-button-tab-selector="vmbackbutton"
  >
    <div slot="buttonsRight">
      <Button
          v-if="!$route.params.id"
          type="button"
          class="mb-3 mt-5"
          :deactivated="!enableSaving"
          @click="save"
      >
        <icon v-if="enableSaving" type="save"/>
        <icon v-else type="ban"/>
        {{ $t('SAVE') }}
      </Button>
    </div>
    <virtual-machine-edit
        ref="vmForm"
        :id="$route.params.id ? $route.params.id : ''"
        slot="mainContent"
        @enableSaving="doEnableSaving"
    />
  </one-column-sidebar-left>
</template>

<script>
import Button from "../components/forms/Button";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import Icon from "../components/Icon";
import VirtualMachineEdit from "@/components/forms/VirtualMachineEdit";

export default {
  name: "VirtualMachineAdd",
  components: {
    Button,
    OneColumnSidebarLeft,
    Icon,
    VirtualMachineEdit
  },
  data() {
    return {
      enableSaving: false,
    }
  },
  methods: {
    doEnableSaving() {
      this.enableSaving = true;
    },
    save() {
      this.$refs.vmForm.save();
    }
  }

}
</script>