<template>
    <main-table-layout
      subMenuSelector="adminpanel"
    >
        <div slot="mainContent">
          <div class="row">
          <div class="col-4 mb-3">
            <div class="settings-button d-inline-block mb-2" @click="loadOrgs">Reload</div><br />
            <input class="mb-2" type="checkbox" v-model="showPrivate" @change="loadOrgs" /> Private Orgs anzeigen
            <div class="vform-label white">Filter by name</div>
            <input class="form-text form-text-dark" type="text" v-model="searchOrg" />
          </div>
          </div>
          <div class="bg-dark p-2 mb-1 d-flex" :key="org.id + forceReRenderKey" v-for="(org) in filtered">
              <div class="bg-beige orgname">{{ org.displayName }}<br /><span class="lighter">({{ org.name }})</span></div>
              <div class="year-section p-2">
                <storage-stats :organization-id="org.id" />
              </div>
              <div class="year-section p-2">
                <component-stats :organization-id="org.id" />
              </div>
              <div class="user-view">
                <user-viewer :organization-id="org.id" :auto-load="true" />
              </div>
              <log-summary :organization-id="org.id" :auto-load="true" />
           </div>
        </div>

    </main-table-layout>
</template>

<script>
   // import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import DateMixin from "@/components/mixins/DateMixin.js";
    //import ViewSwitcher from "../components/widgets/ViewSwitcher";
    //import Pagination from "../components/Pagination";
    //import LogViewer from "@/components/dashboard/LogViewer";
    import UserViewer from "../components/dashboard/UserViewer";
    import StorageStats from "@/components/StorageStats";
    import LogSummary from "@/components/dashboard/LogSummary";
    import ComponentStats from "@/components/dashboard/ComponentStats";
    export default {
        name: "VMLogs",
        components: {
            //ContentFilter,
            MainTableLayout,
            LogSummary,
            ComponentStats,
            //ViewSwitcher,
            //Pagination,
           // LogViewer,
           StorageStats,
          UserViewer
        },
        mixins: [DateMixin],
        data() {
            return {
               organizations: [],
               logs: {},
               projects: {},
               forceReRenderKey: 0,
               showDetail: false,
               storageUsage: {},
               loadingStorageUsage: false,
              showPrivate: false,
              searchOrg: ""
            };
        },
      computed: {
          filtered() {
            return this.organizations.filter(item => {
              return item.name.includes(this.searchOrg) || item.displayName.includes(this.searchOrg)
            })
          }
      },
        beforeMount() {
          this.loadOrgs();
        },
        methods: {
            loadOrgs() {
              let args = {
                sort: 'name'
              };
              if(!this.showPrivate) {
                args.filter = 'visibility gt 0';
              }
              this.$store.dispatch('clientLoadOrganizations', args)
                  .then(async data => {
                    this.organizations = data;
                    this.forceReRenderKey++;
              });
            }
        },
    }
</script>

<style lang="scss" scoped>
  .year {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .year-section {
    color: #000;
    background-color: #efefef;
  }
  .orgname {
    width: 150px;
    padding: 3px;
  }
  .user-view {
    height: 200px;
    overflow-Y:scroll;
    width: 150px;
  }
</style>
