<template>
  <div>
    <label class="low-margin-bottom lighter vform-label white medium" v-if="!displayOnly">{{ $t('selectVM') }}</label>
    <select :disabled="displayOnly" :class="['custom-select new-style', customClasses ]" v-model="vm">
      <option v-for="(vm, index) in vms" :key="index" :value="vm.id">{{ vm.displayName }}<span v-if="showPrices">: {{ vm.clientPricePerMinute }} CHF/min</span></option>
    </select>
    <!--<selector
        v-if="vms.length"
        :disabled="displayOnly"
        :values="mappedVMS"
        :pre-selected-value="vm"
        @setValue="(val) => {vm = val}"
        :show-key-instead-of-value="true"
    />-->
    <div v-if="!vms">{{ $t('noVMAvailable') }}</div>
  </div>
</template>

<script>
//import Selector from "./baseComponents/Selector";
export default {
  name: "VmSelector",
  components: {
    //Selector
  },
  props: {
    showPrices: {type: Boolean, default: true},
    preSetValue: {type: String, default: ""},
    displayOnly: {type: Boolean, default: false},
    customClasses: {type: String, default: "dark"}
  },
  data() {
    return {
      vms: [],
      vm: ''
    }
  },
  computed: {
    mappedVMS() {
      if(this.vms.length) {
            let items = {};
           this.vms.map(item => {
             items[item.displayName] = item.id;
           });
           return items;
      } else {
        return {};
      }
    },
  },
  watch: {
    vm() {
      this.$emit('selected', this.vm);
    },
    preSetValue() {
      if(this.preSetValue) {
        this.vm = this.preSetValue;
      }
    }
  },
  beforeMount() {
    this.loadVMs();
    if(this.preSetValue) {
      this.vm = this.preSetValue;
    }
  },
  methods: {
    loadVMs() {
      this.$store.dispatch('loadVirtualMachines', {
        listName: Math.random().toString(36).substring(7),
        add: {
          filter: {
            vmSelector: 'state eq a'
          }
        }
      }).then(data => {
        if(data.length) {
          this.vms = data;
          if(!this.vm && !this.displayOnly) {
            this.vm = data[0].id;
          }
        } else  {
          console.log('novm')
        }
      })
    },
  }
}
</script>