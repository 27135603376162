<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="organizationbackbutton"
      sub-menu-selector="adminpanel"
      tab-selector="organizationtab"
      type="organization"
  >
    <div slot="mainContent">
      <organization-edit-form
          :organization-id="$route.params.id"
      >
        <div class="bg-dark border-radius p-2 user-edit-meta mb-2">
          <h3>{{ $t('Meta') }}</h3>
          <div class="vform-label">{{ $t('Created') }}</div>
          <div class="value">{{ $store.getters.getOrganizationCreationDate($route.params.id) }}</div>
          <div class="vform-label">{{ $t('Last change') }}</div>
          <div class="value mb-2">{{ $store.getters.getOrganizationChangeDate($route.params.id) }}</div>
        </div>
      </organization-edit-form>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import OrganizationEditForm from "@/components/forms/OrganizationEditForm";

export default {
  name: 'OrganizationEdit',
  components: {
    OneColumnSidebarLeft,
    OrganizationEditForm,
  },
  data() {
    return {
      id: '',
    };
  },
  beforeMount() {
    this.id = this.$route.params.id;
  },
  methods: {
    removeTag: function (tag) {
      this.$store.dispatch('removeOrganizationTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addOrganizationTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateTags: function (tags) {
      this.$store.dispatch('updateOrganization', {
        id: this.$route.params.id,
        tags: tags,
      });
    },
  },
}
</script>
