<template>
  <div class="mb-3 position-relative w-100">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @dragstartSort="(event) => {
          $emit('dragstartSort', event);
        }"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <select v-if="camopts.length > 1" v-model="camera">
      <option v-for="opt in camopts" :key="opt" :value="opt">{{ opt }}</option>
    </select>
    <div v-if="isActive && !editorMode && !hasError && showCode" class="qrcode-outer">
      <qrcode-stream
          :key="forceReRenderKey"
          class="vform-viewer-qrcode"
          @decode="onDecode"
          @init="onInit"
      ></qrcode-stream>
    </div>
    <div v-else-if="editorMode" class="qr-code-faker">
      <!--<icon size="2" type="camera" />-->
      <div class="text">{{ $t('qrCameraView') }}</div>
    </div>
    <div v-else-if="!editorMode && !showCode" class="vform-slide-control-element">
      <div class="next-button d-inline-block" @click="showCode = true">{{ $t('Restart camera') }}</div>
    </div>
    <div v-if="error" class="error mt-2 w-100 p-2">{{ error }}</div>
  </div>
</template>
<div @click="getCam()">{{ $t('yourCameraIsntWorkingTryThis') }}</div>
<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import {QrcodeStream} from 'vue-qrcode-reader';
//import Icon from "@/components/Icon";
import EditorOverlay from "./EditorOverlay";

export default {
  name: "QrCode",
  components: {
    QrcodeStream,
    //Icon,
    EditorOverlay
  },
  mixins: [ElementMixin],
  props: {
    projectId: {type: String, required: true},
  },
  data() {
    return {
      compareString: "",
      error: "",
      forceReRenderKey: 0,
      camera: null,
      camopts: [],
      hasError: false,
      showCode: true,
      hasGoneTo: false
    };
  },
  watch: {
    isActive(val) {
      if (val && !this.previewMode) {
        this.getCam();
      }
    }
  },
  mounted() {
    if (!this.previewMode) {
      this.getCam();
    }
    this.increaseCounter();
  },
  methods: {
    getCam() {
      /*window.navigator.mediaDevices.getUserMedia({video:true}).then((stream)=>{
        const cams = stream.getVideoTracks();
        if(cams && cams.length) {
          this.camopts = cams.map(item => {return item.label});
        }
        if(this.camopts.length) {
          this.camera = this.camopts[0];
        }

        //document.querySelector("video").srcObject = stream;
      }).catch(err=> console.log(err.name))*/
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    makeTimeout() {
      const $this = this;
      this.timeout = setTimeout(() => {
        $this.showCode = false;
      }, 30000);
    },
    async onInit(promise) {
      await promise
          .then(async () => {
            await promise;
            this.hasError = false;
            this.makeTimeout();
          }).catch(error => {
            console.log("catch qrcode error: " + error.name)
            this.hasError = true;
            if (error.name === 'NotAllowedError') {
              this.error = "you need to grant camera access permission"
            } else if (error.name === 'NotFoundError') {
              this.error = "no camera on this device"
            } else if (error.name === 'NotSupportedError') {
              this.error = "secure context required (HTTPS, localhost)"
            } else if (error.name === 'NotReadableError') {
              this.error = "the camera might already be in use"
            } else if (error.name === 'OverconstrainedError') {
              this.error = "installed cameras are not suitable"
            } else if (error.name === 'StreamApiNotSupportedError') {
              this.error = "Stream API is not supported in this browser"
            } else if (error.name === 'InsecureContextError') {
              this.error = 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
            } else {
              this.error = `Camera error (${error.name})`;
            }
          })
    },
    setError() {
      this.error = this.$t("wrongQrCode");
      this.compareString = "";
    },
    getValue(string) {
      const split = string.split(':');
      return {
        [split[0]]: split[1]
      };
    },
    getValues(decodedString) {
      console.log("getValues")
      console.log(decodedString);
      const split = decodedString.split(';');
      let object = {};
      for (let i = 0; i < split.length; i++) {
        object = {...object, ...this.getValue(split[i])};
      }
      return object;
    },
    onDecode(decodedString) {
      console.log('decode!')
      if (this.hasGoneTo) {
        console.log('hasgoneto is done, return;')
        return;
      }
      this.error = "";
      // eslint-disable-next-line no-unreachable
      const split = decodedString.split(':');
      //const action = split[0];
      const assetId = split[1];
      console.log('decoded assetId: ' + assetId);
      if (this.config && this.config.codes) {
        //select from codes matching assetId
        const qrCodeFromConfig = this.config.codes.find((qr) => qr.assetId === assetId);
        if (qrCodeFromConfig) {
          this.hasGoneTo = true;
          this.handleGoTo(qrCodeFromConfig);
        }
      }
    },
    validate() {
      return true;
    },
    checkRequired() {
      console.log("this is the qr code, my required is: " + !this.getRequired(this.config))
      return !this.getRequired(this.config);
    },
  }
}
</script>

<style lang="scss">
.qrcode-outer {
  padding-top: 100%;
  width: 100%;
  position: relative;
}

.vform-viewer-qrcode, .qrcode-stream-wrapper {
  position: absolute !important;
  top: 0;
  left: 0;
  //height: 250px !important;
  button, #reader__camera_permission_button {
    background-color: var(--vform-editor-layout-tertiary-color);
    color: var(--vform-editor-layout-quaternary-color);
    padding: 3px;
    border-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.qr-code-faker {
  text-transform: uppercase;
  width: 100%;
  padding-top: 100%;
  background-color: var(--vform-editor-layout-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  position: relative;

  .icon, .text {
    text-align: center;
    font-weight: bold;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.error {
  background-color: var(--vform-error-color);
  color: var(--vform-on-dark-color);
}

.cam-hint {
  font-size: 12px;
  padding-left: 3px;
}

.cam-selector {
  font-size: 13px;
  max-width: 80%;

  option {
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    break-word: break-all;
  }
}
</style>