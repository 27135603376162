import Vue from 'vue';

const state = {
    /*
    * projectsLists contains all lists (e.g. if there is a box with the projects per organization, you would find it
    * in here with the id of the organization as the listname
    * */
    connectionsList: {
        connectionList: {},
    },
    connectionsListOptions: {},
    defaultQueryParams: {

    },

    /*
    * The query params contain the different parameters of a query added by filters, table sorts and so on
    * the default filters always stay there
    *
    * */
    queryParams: {

    },
    /*
    * Combined query params are e.g. filter params which get combined from 'filter eq vstage' and 'filter eq coolman' to 'filter in vstage, coolman'
    * */
    combinedQueryParams: {
        connectionList: {},
    },
    /**
     * Used keys so far:
     * last_inserted
     * */
    connectionTmp: {
    },
}

const getters = {
    getConnections() {
        return state.connectionsList.connectionList;
    },
    getConnectionsByListName: (state) => (listName) => {
        return state.connectionsList[listName] ? state.connectionsList[listName] : [];
    },
    getConnectionById: (state) => (id, listName = 'connectionList') => {
        return state.connectionsList[listName] && state.connectionsList[listName][id] ? state.connectionsList[listName][id] : null;
    },
    getConnectionOriginalQueryParams: function() {
        return state.queryParams;
    },
    getConnectionQueryParams: (state) => (listName) => {
        return state.combinedQueryParams[listName];
    },

};

const mutations = {
    setConnectionTmpMutation(state, data) {
        if(state.connectionTmp[data.key]) {
            Vue.delete(state.connectionTmp, data.key);
        }
        Vue.set(state.connectionTmp, data.key, data.value);
    },
    /**
     * @param args Object
     *  args.data contains the data
     *  args.listName the listName (can be empty if default listName)
     * */
    loadConnectionsMutation(state, args) {
        let listName = 'connectionList';
        if(args.listName) {
            listName = args.listName;
        }
        Vue.set(state.connectionsList, listName, args.data);
        /*for (var i = 0; i < args.data.length; i++) {
            let connection = args.data[i];
            Vue.set(state.connectionsList[listName], connection.id, connection);
         }*/
    },
    loadConnectionMutation(state, data) {
        let listName = 'connectionList';
        if(data.listName) {
            listName = data.listName;
        }
        if(!state.connectionsList[listName]) {
            Vue.set(state.connectionsList, listName, {});
        }
        Vue.set(state.connectionsList[listName], data.id, data);
    },
    deleteConnectionMutation(state, data) {
        let listName = 'connectionList';
        if(state.connectionsList[listName][data.id]) {
            Vue.delete(state.connectionsList[listName], data.id);
        }
    },
}
const actions = {
    /*
    * Loads the projects and adds them to the store
    * args:
    *   listName: optional (default is projectList)
    *   to add a filter item to the query, do it with add (and use 'remove' to clear the query of this item)
    *   args.add = {filter: {
    *                   customId: 'name like %lalala%',
    *                   customId2: 'type eq media',
    *               }}
    * */
    registerConnectionsQueryParams: ({commit}, args) => {
        let listName = 'connectionList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'connections', listName: listName, params: args});
        }
    },
    loadConnections: async ({commit, dispatch, getters}, args) => {
        let listName = 'connectionList';
        if(args.listName) {
            listName = args.listName;
        }
        if(Object.keys(args).length > 0) {
            commit('queryParamsSave', {storeType: 'connections', listName: listName, params: args});
        }
        commit('combineQueryParams', {storeType: 'connections', listName: listName});
        const params = getters.getConnectionQueryParams(listName);
        if(!params.include) {
            params.include = [];
        }
        if(!params.include.includes("instanceAndAsset")) {
            params.include.push("instanceAndAsset");
        }
        dispatch('getProjectConnections', getters.getConnectionQueryParams(listName)).then(data => {
            commit('loadConnectionsMutation', {data: data, listName: listName});
        });
    },
    deleteConnection: async ({commit, dispatch}, args) => {
        return dispatch('deleteProjectInstanceConnections', {
            id: args.projectId,
            args: [args.id],
        }).then(() => {
            commit('deleteConnectionMutation', args);
        });
    },
}
export default {
    state, getters, mutations, actions
}
