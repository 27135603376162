<template>
  <div @click="download" class="inline-block">
    <Button v-if="!divInsteadOfButton" :class="classes"><slot/></Button>
    <div v-else><slot/></div>
    <div class="mb-1" v-if="showStats">{{ readableFileSize }}</div>
    <loading-panel
        v-if="loading"
        :class="[loadingPanelClasses, 'loading-panel-download']"
        :message="downloadMessage"
        :time-in-seconds="0"
        :show-progress-bar="true"
        :progress="progress"
    />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import Button from "@/components/forms/Button";
import LoadingPanel from "@/components/LoadingPanel";
import axios from "axios";
import filesize from "file-size";

export default {
  name: "DownloadButton",
  components: {
    Button,
    LoadingPanel
  },
  props: {
    objectId: {type: String, required: true},
    fileKey: {type: String, default: ""},
    showStats: {type: Boolean, default: false},
    classes: {type: String, default: ''},
    username: {type: String, default: ''},
    password: {type: String, default: ''},
    fileKeyOnly: {type: Boolean, default: false},
    divInsteadOfButton: {type: Boolean, default: false},
    downloadMessage: {type: String, default: 'Downloading your file.<br />Please wait.'},
    loadingPanelClasses: {type: String, default: '' },
    externalFileSize: {type: Number, default: null},
  },
  data() {
    return {
      fileSize: '',
      readableFileSize: '',
      loading: false,
      progress: 0,
      storageURL: '',
      clientURL: '',
      src: '',
      action:'',
      authToken: ''
    };
  },
  watch: {
    fileSize() {
      this.readableFileSize = filesize(this.fileSize*1000, {fixed: 2, spacer: ' '}).human('si');
    }
  },
  beforeMount() {
    if(this.externalFileSize) {
      this.fileSize = this.externalFileSize;
    }
    else if(this.showStats) {
      this.getStats();
    }
    this.setUpStorage();
  },
  methods: {
    setUpStorage() {
      return fetch(process.env.VUE_APP_CONFIG)
                .then(r => r.json())
                .then(config => {
                  this.storageURL = config.sfx.project.webapp.storageHost + '/api/v2';
                  this.clientURL = config.sfx.project.webapp.apiHost + '/api/v2';
                });
    },
    async getStats() {
      const type = await this.$store.dispatch('getItemType', {
        id: this.objectId
      });
      return await this.$store.dispatch(`clientGet${type}Info`, {id: this.objectId, key: this.fileKey}).then(data => {
        this.fileSize = (parseInt(data['content-length'])/ 1000);
        return data;
      });
    },
    async download() {
      this.progress = 1;
      let type;
      let config;
      if(!this.username) {
        this.loading = true;
        type = await this.$store.dispatch('getItemType', {
          id: this.objectId
        });
        config = {
           headers: {'Authorization': 'Bearer ' + this.$store.getters.authToken},
        };
        await this.doTheDownload(config, type);
      }
      else {
        type = 'asset';
       /*
        config = {
           auth: {
            username: this.username,
            password: this.password,
          }
        };*/
        window.open(`${this.storageURL}/${type.toLowerCase()}s/${this.objectId}/${this.fileKey}`);
      }
    },
    async doTheDownload(config, type) {
      let $this = this;
      config.responseType = 'blob';
      config.onDownloadProgress = progressEvent => {
            const total = parseFloat(progressEvent.total);
            const current = progressEvent.loaded;
            $this.progress = Math.floor(current / total * 100)
      }
      let url = `${this.storageURL}/${type.toLowerCase()}s/${this.objectId}`;
      if(this.fileKey) {
        url += '/' + this.fileKey
      }
      axios.get(url, config
        ).then(data => {
          if (!data) {
            return '';
          }
          (async () => {
            $this.loading = false;
            saveAs(data.data, $this.fileKeyOnly ? $this.fileKey : $this.objectId + '_' +$this.fileKey);
          })();
        }).catch(e => {
          console.log(e)
      })
    }
  }
}
</script>

<style lang="scss">
  .loading-panel-download {
    position:absolute;
    z-index:55;
  }
  .not-absolute {
    .loading-panel-download {
      position:static;
    }
  }
   #frame, #downloadForm {
      visibility:hidden;
     position:absolute;
     top:0;
      z-index: -1;
    }
</style>