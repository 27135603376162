<template>
  <main-table-layout
      sub-menu-selector="adminpanel"
  >
    <div slot="buttonsRight"/>

    <content-filter :id="listName"
                    slot="filter"
                    :org-filtering="false"
                    :tag-filtering="false"
                    store-name="Organization"
                    text-filtering-field-name="displayName"
                    @filter="filtering"
    />
    <view-switcher
        :id="'mainContent'"
        slot="mainContentButtons"
        :table="true"
        :thumbnails="true"
    />

    <pagination
        :id="'organizationtablepage'"
        ref="pagination"
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getOrganizationListOptions(listName, 'pagination_items')"
        @setActive="loadPaginatedData"
    />
    <div slot="mainContentButtons">
      <Button
          icon="plus"
          link="/admin/organizations/add"
          permission="createOrganization"
          type="action-button">
        {{ $t('createNewOrganization') }}
      </Button>
    </div>

    <Button
        slot="mainContent"
        class="icon-button"
        icon="redo"
        type="reload"
        @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
    />

    <table-widget
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
        id="organizationsDefaultTable"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getOrganizationsByVisibility([0, 1, 2], listName)"
        :deletable="false"
        :fields="tableFields"
        :nav-by-clicking-row="true"
        :show-tags="false"
        :title-as-links="true"
        @deleteRow="deleteRow"
        @sort="sort"
        view-permission="writeOrganization"
    />
    <thumbnails
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
        id="organizationsDefaultThumbnails"
        :key="forceReRenderKey"
        slot="mainContent"
        :data="$store.getters.getVisibleOrganizationsByListName(listName)"
        :deletable="false"
        :fields="thumbnailFields"
        :nav-by-clicking-row="true"
        :title-as-links="true"
        @deleteRow="deleteRow"
        @sort="sort"
    />
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import TableWidget from "../components/widgets/TableWidget";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import {mapState} from 'vuex';
import ContentFilter from "@/components/widgets/filter/ContentFilter";

export default {
  name: "Organizations",
  components: {
    Button,
    TableWidget,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
    ContentFilter,
  },
  data() {
    return {
      listName: 'orgOverview',
      paginationLimit: 15,
      viewId: 'mainContent',
      forceReRenderKey: 0,
      queryRelevantComponents: 2, //pagination and filter
      queryRelevantInits: 0,
      tableFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'name',
          id: 'name',
          type: 'string',
          sortable: true
        },
        {
          name: 'visibility',
          id: 'visibility',
          type: 'orgvisibility',
          sortable: true
        },
        {
          name: 'DisplayName',
          id: 'displayName',
          type: 'string',
          sortable: true
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
      thumbnailFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true
        },
        {
          name: 'DisplayName',
          id: 'displayName',
          type: 'string',
          sortable: true
        },
        {
          name: 'Edit',
          type: 'editButton'
        },
      ],
    };
  },
  computed: mapState({
    pages: state => state.page.pages,
  }),
  watch: {
    queryRelevantInits: function (newval) {
      if (newval === this.queryRelevantComponents) {
        this.loadData(false);
      }
    }
  },
  methods: {
    loadPaginatedData(offset, initial) {
      this.offset = offset;
      this.loadData(initial);
    },
    loadData(initial = false) {
      this.loading = true;
      let args = {
        listName: this.listName,
      }
      const paginationAddOn = {
        paging: {
          [this.listName]: true,
        },
        offset: {
          [this.listName]: this.offset,
        },
        limit: {
          [this.listName]: this.paginationLimit,
        },
        include: {
          [this.listName]: 'tags',
        },
        sort: {
          [this.listName]: this.sorting,
        },
      };
      args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add} : paginationAddOn;
      if (this.filter && this.filter.remove) {
        args.remove = this.filter.remove;
      }

      if (initial) {
        this.queryRelevantInits++;
        this.$store.dispatch('registerOrganizationsQueryParams', {listName: this.listName, params: args});
      } else {
        this.$store.dispatch('loadOrganizations', args).then(() => {
          this.loading = false;
        });
      }
    },
    deleteRow: function (id) {
      this.$store.dispatch('deleteOrganization', {
        args: [id]
      });
    },
    filtering: function (args, initial) {
      this.filter = args;
      this.loadData(initial);
    },
    sort(args) {
      const {fieldname, direction} = args;
      const sortString = direction + fieldname;
      if (this.sorting !== sortString) {
        this.sorting = sortString;
      }
      this.loadData(false);
    }
  }
}
</script>