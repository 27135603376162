<template>
    <div>
        <select v-model="parsedValue" ref="select">
            <option :key="key" v-for="(key) in possibleValues" :value="key">
                {{ key }}
            </option>
        </select><br >
        <Button
                class="mt-3"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    export default {
        name: "Enum",
        components: {
            Button,
            Icon
        },
        props: {
            value: {type: String, default: ''},
            possibleValues: {type: Array, required: true},
        },
        data() {
            return {
                parsedValue: null,
            };
        },
        beforeMount() {
            this.parsedValue = this.value;
        },
        methods: {
            save: function() {
                this.$emit('save', this.$refs.select.value);
            }
        },
    }
</script>