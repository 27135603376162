<template>
  <div :class="['position-relative w-100 vertical-space-container', editable ? 'edit-mode' : '', !config.showAsPercentage ? 'mt-2 mb-2' : '']"
       :style="{
          height: getHeight()
          }"
  >
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
    />
    <div

         :class="['vertical-space', editable ? 'edit-mode' : '']">
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "Divider",
  components: {EditorOverlay},
  mixins: [ElementMixin],
  mounted() {
    this.increaseCounter();
  },
  methods: {
    getHeight() {
      const {height, showAsPercentage} = this.config;
      let heightVal = height;
      if(!height) {
        heightVal = 20;
      }
      if(showAsPercentage) {
        return (heightVal / 6) + 'vh';
      } else {
        return heightVal + 'px';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-space-container {
  //background-color: blue;
}
  .vertical-space {
    width: 100%;
  }
  .edit-mode {
    background-color: #e5e5f7;
    opacity: 0.8;
    background: repeating-linear-gradient( -45deg, var(--vform-editor-logo-color), var(--vform-editor-logo-color) 1px, #e5e5f7 1px, transparent 5px );
  }
</style>