<template>
    <main-table-layout
      sub-menu-selector="librarypanel"
    >
        <div slot="buttonsRight"/>
        <content-filter
                slot="filter"
                id="formList"
                @filter="filtering"
                store-name="Form"
                :team-filtering="true"
        />
        <view-switcher
                slot="mainContentButtons"
                :id="'mainContent'"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getFormListOptions(listName, 'pagination_items')"
                :id="'formtablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
      <div slot="mainContentButtons">
        <Button
            type="action-button"
            link="/library/forms/add"
            icon="plus">
          {{ $t('createNewForm') }}
        </Button>
      </div>

        <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <table-widget
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                slot="mainContent"
                :id="'formDefaultTable'"
                :nav-by-clicking-row="true"
                :deletable="true"
                @deleteRow="deleteRow"
                :show-tags="true"
                :show-organization="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="tableFields"
                :data="$store.getters.getFormsByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
                :initial-sorting="sortObject"
        />
        <thumbnails
                v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                slot="mainContent"
                :id="'formDefaultTable'"
                :deletable="true"
                :nav-by-clicking-row="true"
                edit-permission="write"
                delete-permission="delete"
                :fields="thumbnailFields"
                :data="$store.getters.getFormsByListName(listName)"
                :title-as-links="true"
                @sort="sort"
                :loading="loading"
        />
</main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
    export default {
        name: "Form",
      mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
              registerMethod: 'registerFormQueryParams',
              loadMethod: 'loadForms',
              include: 'tags,teams',
              deleteMethod: 'deleteForm',

                listName: 'FormList',
                thumbnailFields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                    },
                     {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                tableFields: [
                    {
                      name: '',
                      id: '',
                      type: 'image',
                    },
                    {
                        name: 'Name',
                        id:'name',
                        type: 'string',
                        sortable: true
                    },
                     {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Last Update',
                        id:'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            }
        },
    }
</script>
