<template>
  <div class="leaf p-3 pt-6">
        <div class="float-right clickable"><div @click="editing ? editing = null : editing = item.id"><icon :style="editing ? 'color: #e24d5e' : 'color: #baa53d'" class="p-1" type="cloud" />Upload</div></div>
    <div>{{ item.name }}</div>
    <div>
      <div class="darker p-1 mb-1" :key="index" v-for="(subitem, index) in files">
        {{ subitem.key }} <span class="clickable ml-3" @click="deleteFile(subitem.key)"><icon class="mr-1" type="ghost" />Delete</span>
        <br />
        <download-button
                  loading-panel-classes="black"
                class="clickable darker p-1"
                download-message=""
                :div-instead-of-button="true"
                :show-stats="true"
                :object-id="item.id"
                :file-key="subitem.key"
            >{{ $t('Download') }}</download-button>
      </div>
    </div>
    <div v-if="editing">
       <upload-drop-zone
                    class="col-12 col-md-3 mb-5 mt-3"
                    slot="sidebarLeft"
                    :instance-id="item.id"
                    :project-id="item.projectId"
                    target-type="Instance"
                    :form-id="item.id"
                    :use-default-key="false"
                    @fileReceived="listFiles"
                    :key="forceReRenderKey"
                    :auto-update-preview="false"
                />
      <div @click="editing = null">save<icon type="disk" /></div>
    </div>
    <div :key="index + 1000" v-for="(subItem, index) in item.children">
      <simple-node
        :item="subItem" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import SimpleNode from "@/components/tree/SimpleNode";
import UploadDropZone from "@/components/files/UploadDropZone";
import DownloadButton from "@/components/forms/DownloadButton";
export default {
  name: "SimpleNode",
   components: {
    Icon,
    SimpleNode,
     UploadDropZone,
     DownloadButton
  },
  props: {
    item: {type: Object, required: true},
  },
  data() {
    return {
      editing: false,
      forceReRenderKey: 0,
      files: []
    }
  },
  beforeMount() {
    this.listFiles();
  },
  methods: {
    listFiles() {
        this.$store.dispatch('clientListInstanceParts', {id: this.item.id}).then(data => {
          this.files = data;
          this.forceReRenderKey++;
        })
    },
    deleteFile(key) {
      this.$store.dispatch('clientDeleteInstancePart', {id: this.item.id, key: key}).then(() => {
        this.forceReRenderKey++;
        this.listFiles();
      })
    }
  }
}
</script>

<style scoped>

</style>