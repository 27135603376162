<template>
    <div class="p-2 mb-3">
        <tile-button
            slot="content"
            :make-compact-display="true"
            ref="tiles"
            :initial-active-tiles="seletedTiles"
            :is-multi-select="true"
            :icons="true"
            :tiles="Object.keys(mediaCategories)"
            :values="Object.values(mediaCategories)"
            @trigger="setActive"
        />
        <div class="col-12"><slot /></div>
    </div>
</template>

<script>
import { vFormControls } from "@/enum";
import TileButton from "@/components/forms/TileButton";
import FileTypeMixin from "@/components/files/FileTypeMixin.js";

export default {
    name: "vFormMEDIA_LOADER",
    components: {
        TileButton,
    },
    computed: {
        seletedTiles() {
            return this.element.values.map((x) => {
                return x.text;
            });
        },
    },
    props: {
        element: { type: Object, required: true },
    },
    mixins: [FileTypeMixin],
    data() {
        return {
            vFormControls: vFormControls,
            selectedTiles: [],
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["MEDIA_LOADER"];
        if (!this.element.values) {
            this.element.values = [];
        }
    },
    methods: {
        setActive(item) {
            if (item.operation === "add") {
                this.element.values.push({ value: item.value, text: item.button });
            }

            if (item.operation === "remove") {
                this.element.values = this.element.values.filter(function (e) {
                    return e.value !== item.value;
                });
            }
        },
    },
};
</script>

<style scoped></style>
