<template>
    <li :class="['nav-item', arrowStyle ? 'arrowStyle' : '']">
        <router-link
                v-if="!disabled"
                :class="['nav-link', activeClass, inactiveClass]"
                :to="{name: routename, params: {id: pid, type: type}}"
                :translate="translate">
            <slot />
            <icon v-if="icon" :type="icon"/>
            <span v-if="translate">{{ $t("nav." + title) }}</span>
            <span v-else>{{ title }}</span>
        </router-link>
        <router-link
                v-else
                :class="['nav-link', 'disabled', activeClass, inactiveClass]"
                :to="{name: routename, params: {id: pid, type: type}}"
                :translate="translate"
                :event="!disabled ? 'click' : ''"
        >
            <span class="pre-arrow" v-if="arrowStyle" />
            <span v-if="translate">{{ $t("nav." + title) }}</span>
            <span v-else>{{ title }}</span>
            <span class="arrow" v-if="arrowStyle" />
        </router-link>
    </li>
</template>

<script>
    import Icon from "../Icon";

    export default {
        name: 'NavigationTab',
        components: {
            Icon
        },
        props: {
          routename : { type: String, required: true, default: ''},
          pid : { type: String, required: false, default: ''},
          translate : Boolean,
          title: { type: String, required: true},
          active: Boolean,
          icon: { type: String, required: false, default: null},
          disabled: {type: Boolean, default: false},
          addClasses: {type: Array, default: null},
          arrowStyle: {type: Boolean, default: false},
          activeClass: {type: String, default: ''},
          inactiveClass: {type: String, default: ''},
          /**
           * @type String replaces the :type in the route
           */
          type : { type: String, default: ''},
        },
    }
</script>
