<template>
    <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      back-button-tab-selector="servicebackbutton"
      :id="$route.params.id"
      tab-selector="servicetab"
    >
        <info-panel slot="sidebarLeft"
                    :id="$route.params.id"
                    upload-type="Service"
                    :created="$store.getters.getServiceCreationDate($route.params.id)"
                    :last-change="$store.getters.getServiceChangeDate($route.params.id)"
                    :downloadable="true"
                    :removable="true"
                    preview-id="serviceEdit"
        />

        <div slot="mainContent">
            <service-edit-form
                    :service-id="$route.params.id"
            />
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    export default {
        name: 'ServiceEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            ServiceEditForm: () => import("@/components/ServiceEditForm"),
        },
        data() {
            return {
                id: '',
                accessAllowed: false,
            };
        },
        beforeMount() {
            this.accessAllowed = this.$store.getters.isSuperAdmin;
            if(this.accessAllowed) {
                this.$store.dispatch('loadService', {
                    id: this.$route.params.id,
                    include: 'organizations',
                });
            }
            else {
                this.$router.push('/access-denied');
            }
        },
        mounted() {

        },
    }
</script>
