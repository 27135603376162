<template>
    <div>
        <input :class="['form-text', $v.currValue.$error ? 'form-group--error' : '']"
               ref="number"
               @keydown.enter="save"
               v-model="currValue"
        >
        <div class="form-error" v-if="!$v.currValue.integer && $v.currValue.$error">{{ $t('errors.mustBeNumeric') }}</div>
        <Button
                class="mt-1"
                @click="save"
        >
            <icon type="save"/>
            {{ $t('SAVE') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import Icon from "../../Icon";
    import { integer } from 'vuelidate/lib/validators'
    export default {
        name: "Number",
        components: {
            Button,
            Icon
        },
        props: {
            value: {type: Number, default: 1},
            type: {type: String, required: true}
        },
        data() {
            return {
              currValue: null,
            };
        },
        validations: {
            currValue: {
                integer,
            },
        },
        beforeMount() {
            this.currValue = this.value;

        },
        methods: {
            save: function() {
                this.$v.currValue.$touch();
                if(!this.$v.$anyError) {
                    this.$emit('save', this.currValue);
                }
            }
        },
    }
</script>