<template>
  <div>
    <div :key="forceReRenderKey" v-if="loaded" class="bg-dark border-radius container-fluid">
      <div class="row p-3">
        <div class="col-12">
          <h3>{{ $t('instances') }}</h3>
        </div>
        <div v-for="instance in instances" :key="instance.id" class="col-12 col-md-12 d-flex align-items-center">
          <div style="min-width: 200px;">
            <div class="tag">{{ instance.type }}</div>
          </div>
          <div style="min-width: 500px">
            <div class="vform-label white">Instance:</div>
            <h3>{{ instance.name }}</h3><br/><span class="lighter">{{ instance.id }}</span>
            <div class="vform-label white">Asset:</div>
            {{instance.asset && instance.asset.name ? instance.asset.name : 'loading' }}<br/><span class="lighter">{{ instance.asset ? instance.asset.id : '' }}</span>
          </div>
          <team-attacher
              v-if="instance.asset && instance.asset.id"
              :id="instance.asset.id"
              slot="info"
              :limit-by-organization-id="organizationId"
              :teams="instance.asset.teams"
              class="mt-1"
              store-name="Asset"
              @added="loadAssetTeams(instance.asset.id)"
              @removed="loadAssetTeams(instance.asset.id)"
          />
        </div>
      </div>
    </div>
    <loading-panel v-else />
  </div>
</template>

<script>
import TeamAttacher from "./organizations/TeamAttacher";
import LoadingPanel from "./LoadingPanel";
export default {
  name: "ProjectInstanceViewer",
  components: {TeamAttacher, LoadingPanel},
  props: {
    itemId: {type: String, required: true},
    organizationId: {type: String, required: true},
    itemType: {type: String, default: 'Project'}
  },
  data() {
    return {
      instances: [],
      loaded: false,
      forceReRenderKey: 0
    };
  },
  beforeMount() {
    if(this.itemType === 'Project') {
      this.loadInstances();
    }
  },
  methods: {
    loadInstances() {
      this.loaded = false;
      this.$store.dispatch('clientLoadProjectInstances', {id: this.itemId})
          .then(async instances => {
            this.instances = instances;
            for (let i = 0; i < instances.length; i++) {
              const asset = await this.$store.dispatch('clientLoadAsset', {
                id: instances[i].assetId,
                include: ['teams']
              });
              this.instances[i].asset = asset;
            }
            this.loaded = true;
          })
    },
    loadAssetTeams(assetId) {
      this.$store
          .dispatch("clientLoadAssetTeams", {
            id: assetId
          })
          .then((data) => {
            const index = this.instances.findIndex(item => {
              return item.assetId === assetId
            });
            this.instances[index].asset.teams = data;
            this.forceReRenderKey++;
          });
    }
  }
}
</script>