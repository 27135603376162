<template>
  <div
      :class="['mt-3 pl-3 pt-2 pb-1 ml-2 mr-2 vform-panel', !isGlobalPanelActiveOnStep() && (!showSelectStepsPopup && !showDeletePanelPrompt) ? 'global-inactive' : '']"
      :style="{ borderTop: '3px solid ' + panel.identifierColor }"
  >
    <popup
        v-if="showSelectStepsPopup"
        class="global-step-select-popup"
        @close="() => {showSelectStepsPopup = false;}">
      <div slot="popupMainContent">
        <label class="vform-editor-button-text clickable panel-title">{{ panel.isMaster ? 'Global' : '' }} PANEL
          {{ panelIndex + 1 }}</label
        ><br>
        <label class="vform-editor-button-text info-label clickable mb-3 mt-1">Show In Steps</label>
        <div v-if="isMasterPanel(panel)" class="d-flex fancy-scrollbar multi-select">
          <div v-for="(step, index) in steps"
               v-if="steps"
               :key="'stepChooser' + index"
               :class="['pl-2 pr-2 multi-select-item clickable', isPanelVisibleOnStep(panel, step) ? 'selected' : '', index % 2 == 0 ? 'even' : 'odd']"
               @click="toggleGlobalPanelOnStep(panel, step)">{{ `Step ${index + 1} - ${step.name}` }}</div>
        </div>

        <label class="vform-editor-button-text info-label text-notransform clickable mt-4 mt-1">Press SHIFT between two
          selections to define a range</label>

        <div v-if="isMasterPanel(panel)" class="mt-1">
          <div class="clickable settings-button d-inline-block mr-3" @click="showGlobalPanelOnSteps(panel,true)">
Select
            All
          </div>
          <div class="clickable settings-button d-inline-block mb-2 mr-3" @click="showGlobalPanelOnSteps(panel,false)">
            Unselect All
          </div>
          <div class="clickable settings-button d-inline-block mb-2" @click="showSelectStepsPopup = false;">
            Apply
          </div>
        </div>
      </div>
    </popup>

    <popup
        v-if="showDeletePanelPrompt"
        @close="() => {
          resetDeletePanel();
        }">
      <delete-prompt
          slot="popupMainContent"
          @abort="() => {resetDeletePanel();}"
          @confirm="() => {
            removePanel(panelToBeDeleted);
            resetDeletePanel();
          }"
      />
    </popup>
    <label class="vform-editor-button-text clickable panel-title"
           @click="expanded = !expanded">{{ panel.isMaster ? 'Global' : '' }} PANEL {{ panelIndex + 1 }}</label
    ><br>
    <div
        v-if="panel.isMaster"
        class="global-panel-toggle mr-2 mt-1"
        @click="toggleGlobalPanelOnStep(panel, activeStepObject)"
    >
      <icon :size="'1.4'" :type="isGlobalPanelActiveOnStep() ? 'eye' : 'eye-slash'"/>
    </div>
    <div
        class="panel-remover mr-2 mt-1"
        @click="removePanel(panel)"
    >
      <icon :size="'1.4'" type="trash"/>
    </div>

    <div v-if="expanded">
      <div v-if="enableMasterPanelsFeature">
        <div v-if="isMasterPanel(panel)">
          <label class="vform-editor-button-text clickable mb-0 mt-1">Show In Steps</label>
          <br>
          <div
              class="settings-button d-inline-block mb-2 mt-1 mr-2"
              @click="selectSteps(panel)">
            Select Steps
            <icon type="table-layout"/>
          </div>
        </div>
      </div>

      <div v-if="isGlobalPanelActiveOnStep()">
        <label class="vform-editor-button-text clickable mb-2 mt-3">Panel Size</label>
        <div class="ml-2">
          <label
              :for="'autoFit' + panel.uuid"
              class="container d-inline-block vform-editor-body"
              style="padding-left: 5px !important"
          >
            <input
                :id="'autoFit' + panel.uuid"
                v-model="panel.autoFit"
                type="checkbox"
                @change="setReRender()"
            >
            <span class="checkmark"/>
            <div class="ml-4">{{ $t("vform.autoFit") }}</div>
          </label>

          <label
              :for="'pinBottom' + panel.uuid"
              class="container d-inline-block vform-editor-body"
              style="padding-left: 5px !important"
          >
            <input
                :id="'pinBottom' + panel.uuid"
                v-model="panel.pinBottom"
                type="checkbox"
                @change="setReRender()"
            >
            <span class="checkmark"/>
            <div class="ml-4">{{ $t("vform.pinBottom") }}</div>
          </label>

          <label
              :for="'hideBottomBar' + panel.uuid"
              class="container d-inline-block vform-editor-body"
              style="padding-left: 5px !important"
          >
            <input
                :id="'hideBottomBar' + panel.uuid"
                v-model="panel.hideBottomBar"
                type="checkbox"
                @change="setReRender()"
            >
            <span class="checkmark"/>
            <div class="ml-4">{{ $t("vform.hideBottomBar") }}</div>
          </label>

          <div class="settings-button d-inline-block mt-1" @click="adjustToContent(panel.uuid, panel)">
            {{ $t('fitToContent') }}
          </div>

          <div class="w-100 mt-2"/>
          <label class="vform-editor-label section mt-2">Border radius</label>
          <label class="section ml-2 vform-editor-body slider-label"
          >{{ panel.borderRadius }}</label>
          <div class="col-12 pl-1">
            <vue-slider v-model="panel.borderRadius" :interval="1" :max="10" :min="0"
                        @change="state.panelsRerenderKey++"/>
          </div>
          <div class="w-100 mt-2"/>

          <label
              :for="'hideTopPadding' + panel.uuid"
              class="container d-inline-block vform-editor-body"
              style="padding-left: 5px !important"
          >
            <input
                :id="'hideTopPadding' + panel.uuid"
                v-model="panel.hideTopPadding"
                type="checkbox"
            >
            <span class="checkmark"/>
            <div class="ml-4">{{ $t("noTopPadding") }}</div>
          </label>

          <div class="mt-1 clickable settings-button d-inline-block" @click="showSliders = !showSliders">
            {{ $t("vform.showHideSliders") }}
          </div>
          <div class="w-100"/>

          <div v-if="showSliders" class="pl-2 pr-2">
            <!--Width-->
            <label class="vform-editor-label section">Width</label>
            <label class="section ml-2 vform-editor-body slider-label"
            >{{ panel.width }}%</label
            >
            <slider :key="'sliderwidth' + forceReRenderKey" :default-value="30" :initial-value="panel.width"
                    :interval="1" :max="100" :min="0"
                    @change="(val) => { changePanelProperty(panel, 'width', val); }"/>

            <!---Min-Width-->
            <label class="vform-editor-label section">Minimal Width</label>
           <input type="text" v-model="panel.minWidth" @change="(evt) => {changePanelProperty(panel, 'minWidth', evt.target.value)}" class="mb-2 form-text v-form-label form-text-dark" >
            <tile-button
                :values="['px', '%']"
                :tiles="['px', '%']"
                :initial-active-tiles="['px']"
                :is-multi-select="false"
                :rows="2"
                @trigger="(val) => {changePanelProperty(panel, 'minWidthUnit', val.value)}"
            />

            <!---Max-Width-->
            <label class="vform-editor-label section">Maximal Width</label>
            <input type="text" v-model="panel.maxWidth" @change="(evt) => {changePanelProperty(panel, 'maxWidth', evt.target.value)}" class="mb-2 form-text v-form-label form-text-dark" >
            <tile-button
                :values="['px', '%']"
                :tiles="['px', '%']"
                :initial-active-tiles="['px']"
                :is-multi-select="false"
                :rows="2"
                @trigger="(val) => {changePanelProperty(panel, 'maxWidthUnit', val.value)}"
            />

            <!--Height-->
            <label class="vform-editor-label section">Height</label>
            <label class="section ml-2 vform-editor-body slider-label"
            >{{ panel.height ? `${panel.height}%` : 'AUTO' }}</label
            >
            <slider :key="'height' + panel.uuid + forceReRenderHeightSlider" :default-value="null"
                    :initial-value="panel.height"
                    :interval="1" :max="100" :min="0"
                    @change="(val) => { changePanelProperty(panel, 'height', val); }"/>

            <label class="vform-editor-label section">Horizontal Position</label>
            <label class="section ml-2 vform-editor-body slider-label"
            >{{ panel.xLeft ? `${panel.xLeft}%` : 'AUTO' }}</label
            >
            <slider :key="'left' + panel.uuid + forceReRenderXLeftSlider" :default-value="null"
                    :initial-value="panel.xLeft"
                    :interval="1" :max="100" :min="0"
                    @change="(val) => { changePanelProperty(panel, 'xLeft', val); }"/>

            <label class="vform-editor-label section">Vertical Position</label>
            <label class="section ml-2 vform-editor-body slider-label">
              {{ panel.y ? `${panel.y}%` : 'AUTO' }}
            </label>
            <slider
                :key="'top' + panel.uuid + forceReRenderKey"
                :default-value="null"
                :initial-value="panel.y"
                :interval="1" :max="100" :min="0"
                @change="(val) => { changePanelProperty(panel, 'y', val); }"/>
          </div>
        </div>
        <label class="vform-editor-button-text clickable mt-4">COLOR</label>
        <div class="ml-2">
          <!--Background Color-->
          <label class="vform-editor-label section mt-1 mb-1">Background</label>
          <div
              :style="{
                width: '20px',
                height: '20px',
                backgroundColor: panel.backgroundColor ? panel.backgroundColor : '#fff',
              }"
              class="clickable"
              @click="(event) => {
                colorPickrId = 'backgroundColor';
                this.$nextTick(() => {
                  this.$refs.colorpickr.positionColorPicker(event);
                });
              }"
          />

          <label class="vform-editor-label section mt-3 mb-1">Bottom Bar Background</label>
          <div
              :style="{
                        width: '20px',
                        height: '20px',
                        backgroundColor: panel.bottomBackgroundColor
                            ? panel.bottomBackgroundColor
                            : panel.backgroundColor ? panel.backgroundColor : '#fff',
                    }"
              class="clickable"
              @click="(event) => {
                colorPickrId = 'bottomBackgroundColor';
                this.$nextTick(() => {
                  this.$refs.colorpickr.positionColorPicker(event);
                });
              }"
          />

          <div class="w-100"/>
          <!--Font Color-->
          <label class="vform-editor-label section mt-3 mb-1">Font</label>
          <div
              :style="{
                        width: '20px',
                        height: '20px',
                        backgroundColor: panel.fontColor
                            ? panel.fontColor
                            : '#000',
                    }"
              class="clickable"
              @click="(event) => {
                colorPickrId = 'fontColor';
                this.$nextTick(() => {
                  this.$refs.colorpickr.positionColorPicker(event);
                });
              }"
          />
        </div>
        <div class="w-100"/>
        <color-picker
          v-show="colorPickrId"
          :key="colorPickrId"
          ref="colorpickr"
          :popup-style="true"
          :show-save-button="true"
          :value="panel[colorPickrId] ? panel[colorPickrId] : '#fff'"
          class="step-settings-color-pickr"
          @changeColor="(color) => { setItemColor(colorPickrId, color, panel, false); }"
          @close="() => { colorPickrId = null;}"
          @save="(color) => { setItemColor(colorPickrId, color, panel, true); }"
      />
        <div
            class="settings-button d-inline-block mb-2 mt-4 mr-2"
            @click="resetPanel(panel)">
          {{ $t('resetAll') }}
          <icon type="table-layout"/>
        </div>
        <div
            class="settings-button d-inline-block mb-2 mt-4"
            @click="resetPanelStyling(panel)">
          {{ $t('resetStyling') }}
          <icon class="ml-1" type="paint-roller"/>
        </div>
        <br>
        <div v-if="!panel.isMaster"
             class="settings-button d-inline-block mb-2 mt-2"
             @click="$emit('applyForAll', panelIndex, panel)"
        >
          {{ $t('applyForAllSteps') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "../widgets/editors/ColorPicker";
import Icon from "@/components/Icon";
import Slider from "@/components/widgets/editors/Slider";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import VueSlider from "vue-slider-component";
import vFormAndProjectMixin from "@/components/vForm/vFormAndProjectMixin.js.vue";
import TileButton from "@/components/forms/TileButton.vue";

export default {
  name: "StepSettingsPanelProperties",
  components: {
    TileButton,
    ColorPicker,
    Icon,
    Slider,
    Popup,
    DeletePrompt,
    VueSlider
  },
  mixins: [vFormAndProjectMixin],
  props: {
    steps: {
      type: Array, default: () => {
        return []
      }
    },
    global: {
      type: Object, default: () => {
        return []
      }
    },
    panel: {type: Object, default: null},
    panelIndex: {type: Number, default: null},
    state: {type: Object, default: null},
    activeStepObject: {type: Object, default: null},
    initialExpanded: {type: Boolean, default: true},
  },
  data() {
    return {
      forceReRenderKey: 0,
      forceReRenderXLeftSlider: 0,
      forceReRenderHeightSlider: 0,
      colorPickrId: null,
      showSliders: false,
      enableMasterPanelsFeature: true,
      expanded: true,
      showSelectStepsPopup: false,
      lastSelectedIndex: -1,
      showDeletePanelPrompt: false,
      panelToBeDeleted: null,
    };
  },
  watch: {
    "panel.xLeft": function () {
      this.autoSnapxLeft();
    },
    "panel.height": function () {
      this.autoSnapHeight();
    },
    initialExpanded() {
      this.setInitialExpanded();
    },
  },
  beforeMount() {
    this.setInitialExpanded();
  },
  methods: {
    setInitialExpanded() {
      this.expanded = this.initialExpanded;
    },
    autoSnapxLeft() {
      const panel = document.getElementById('panel-' + this.panel.uuid);
      const parentContainerRect = document.getElementById("slideshow-inner").getBoundingClientRect();

      const {width, height} = panel.getBoundingClientRect()
      const scale = {x: width / panel.offsetWidth, y: height / panel.offsetHeight}
      let newOffsetLeft =
          Number(panel.offsetLeft) * scale.x +
          Number(panel.getAttribute("data-x") * scale.x);
      if (newOffsetLeft < 0) {
        newOffsetLeft = 0;
      }

      let rightBoundary =
          newOffsetLeft +
          Number(panel.getBoundingClientRect().width);
      if (rightBoundary > parentContainerRect.width) {
        newOffsetLeft =
            parentContainerRect.width -
            panel.getBoundingClientRect().width;

        let percentageOffsetLeft =
            (newOffsetLeft / parentContainerRect.width) * 100;
        this.$set(this.panel, "xLeft", Math.round(percentageOffsetLeft));
        this.forceReRenderXLeftSlider++;
      }
    },
    autoSnapHeight() {
      const panel = document.getElementById('panel-' + this.panel.uuid);
      let parentContainerRect = document.getElementById("slideshow-inner").getBoundingClientRect();

      const {width, height} = panel.getBoundingClientRect()
      const scale = {x: width / panel.offsetWidth, y: height / panel.offsetHeight}
      let newBottomPanelEnd =
          Number(panel.offsetTop) * scale.y +
          height;

      if (newBottomPanelEnd > parentContainerRect.height) {
        //adjust height
        let maxHeight = parentContainerRect.height - Number(panel.offsetTop) * scale.y;
        let maxHeightPercentage = (maxHeight / parentContainerRect.height) * 100;
        this.$set(this.panel, "height", Math.round(maxHeightPercentage));
        this.forceReRenderHeightSlider++;
      }

      parentContainerRect = null; // garbage collector
    },
    isGlobalPanelActiveOnStep() {
      if (this.panel.globalVisibleOnSteps) {
        return this.panel.globalVisibleOnSteps.find((s) => {
          return s === this.activeStepObject.uuid;
        });
      }

      return true;
    },
    changePanelProperty(panel, propertyName, width) {
      this.$set(panel, propertyName, width);
    },
    resetPanelStyling(panel) {
      this.$set(panel, "backgroundColor", null);
      this.$set(panel, "fontColor", null);
      this.$set(panel, "bottomBackgroundColor", null);
    },
    resetPanel(panel) {
      this.$set(panel, "backgroundColor", null);
      this.$set(panel, "fontColor", null);
      this.$set(panel, "bottomBackgroundColor", null);
      this.$set(panel, "width", 30);
      this.$set(panel, "height", null);
      //updated the reset values to integer values, so that they allign with "grid-snap" feel of panels moved by hand
      this.$set(panel, "y", 6);
      this.$set(panel, "xLeft", 66);
      this.forceReRenderKey++;
    },
    /**
     * This one is calculating the height of the inner elements of a panel and setting its dimensions so it fits
     * */
    adjustToContent(panelUuid, panel) {
      const el = document.getElementById('panel-' + panelUuid);
      let totalHeight = 0;
      const formInner = el.querySelector('.form-inner-inner');
      const children = formInner.children;
      for (let i = 0; i < children.length; i++) {
        const childEl = children[i];
        const height = window.getComputedStyle(childEl, null).getPropertyValue("height").replace('px', '');
        totalHeight += parseFloat(height);
      }
      const buttonContainer = el.querySelector('.button-container');
      if (buttonContainer) {
        const bt = buttonContainer;
        const height = window.getComputedStyle(bt, null).getPropertyValue("height").replace('px', '');
        totalHeight += parseFloat(height);
      }

      const wrapper = document.getElementById('slideshow-div');
      const wrapperHeight = parseFloat(window.getComputedStyle(wrapper, null).getPropertyValue("height").replace('px', ''));
      const totalHeightInPercent = 100 / (wrapperHeight / (totalHeight + 18));

      panel.height = totalHeightInPercent;
      if (panel.y || panel.y === 0) {
        if (panel.y + totalHeight > wrapperHeight) {
          panel.y = 0;
        }
      }
    },
    setItemColor(propertyName, color, panel, closePickr = true) {
      if (closePickr) {
        this.colorPickrId = null;
      }
      this.$set(panel, propertyName, color);
    },
    removePanel(panel) {
      if (!this.panelToBeDeleted) {
        this.showDeletePanelPrompt = true;
        this.panelToBeDeleted = panel;
      } else {
        const {uuid} = panel;
        const panelIndex = this.getPanelIndex(uuid, this.activeStepObject, this.global, !panel.isMaster);
        const els = this.getElementsByPanel(uuid, this.global, this.activeStepObject, !panel.isMaster && panelIndex === 0);
        for (let i = 0; i < els.length; i++) {
          const element = els[i];
          this.$emit("removeBlock", element.uuid, true);
        }

        if (panel.isMaster) {
          this.global.panels.splice(panelIndex, 1);
        } else {
          this.activeStepObject.panels.splice(panelIndex, 1);
        }
        this.state.dirty = true;
      }
  },
  resetDeletePanel() {
    this.panelToBeDeleted = null;
    this.showDeletePanelPrompt = false;
  },
  setReRender() {
    this.state.panelsRerenderKey++;
  },
  toggleGlobalPanelOnStep(panel, step) {
    if (this.isPanelVisibleOnStep(panel, step)) {
      panel.globalVisibleOnSteps = panel.globalVisibleOnSteps.filter((s) => s !== step.uuid);
      this.lastSelectedIndex = -1;
    } else {
      panel.globalVisibleOnSteps.push(step.uuid);
      this.lastSelectedIndex = this.steps.findIndex((s) => s.uuid === step.uuid);
    }
  },
  selectSteps() {
    this.showSelectStepsPopup = true;
  },
  showGlobalPanelOnSteps(panel, show) {
    if (show) {
      this.$emit('addAllStepsToGlobalPanel', panel);
    } else {
      panel.globalVisibleOnSteps = [];
    }
  },
  isPanelVisibleOnStep(panel, step) {
    if (panel.globalVisibleOnSteps) {
      return panel.globalVisibleOnSteps.find((s) => s === step.uuid);
    }
    return false;
  },
  isMasterPanel(panel) {
    return panel.isMaster;
  }
}
,
}
;
</script>
<style lang="scss">
.vform-container {
  .step-settings-color-pickr {
    .form-submit-button {
      border: 0 solid transparent;
      font-size: 0.9rem;

      .a {
        border: 0 solid transparent;
        border-radius: 4px;
      }

      .content {
        padding: 5px 8px;
        height: auto;
      }
    }
  }
}

.global-step-select-popup {
  .popup {
    background-color: var(--vform-editor-layout-tertiary-color);
  }
}
</style>
<style lang="scss" scoped>
.vform-panel {
  background-color: var(--vform-editor-layout-tertiary-color);
  border-radius: 5px;
  position: relative;
  color: var(--vform-editor-ui-tertiary-color);

  label:nth-child(1) {
    color: var(--vform-editor-ui-tertiary-color);
  }
}

.color-remover {
  border-radius: 3px;
}

.panel-remover {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.global-panel-toggle {
  position: absolute;
  top: 0;
  right: 40px;
  cursor: pointer;
}

.multi-select {
  //flex-wrap: wrap;
  flex-direction: column;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  border: solid 1px var(--vform-editor-ui-quaternary-color);
}

.multi-select-item {
  min-width: 50px;
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  //margin: 0 4px 4px 0;
  width: 99%;
  color: white;
  justify-content: start;
  user-select: none;

  &:hover {
    background-color: var(--vform-editor-ui-quinary-color) !important;
  }

  &.selected {
    background-color: var(--vform-editor-accent-color);

    &:hover {
      background-color: var(--vform-editor-accent-color-hover) !important;
    }
  }

  &.odd {
    background-color: var(--vform-editor-ui-secondary-color);

    &.selected {
      //background-image: linear-gradient(to right, var(--vform-editor-accent-color) 10%, var(--vform-editor-ui-secondary-color));
      background-color: var(--vform-editor-accent-color);
    }
  }

  &.even {
    background-color: var(--vform-editor-layout-tertiary-color);

    &.selected {
      //background-image: linear-gradient(to right, var(--vform-editor-accent-color-hover) 10% , var(--vform-editor-ui-quinary-color));
      background-color: var(--vform-editor-accent-color-hover);
    }
  }
}

.panel-title {
  width: 80%;
  color: var(--vform-editor-ui-primary-color) !important;
}

.info-label {
  color: var(--vform-editor-ui-tertiary-color);
  font-size: 0.75rem !important;
}

.text-notransform {
  text-transform: revert !important;
}

.global-inactive {
  opacity: 0.5;
}

</style>
