<template>
  <div>
    <label class="vform-editor-label section">Text</label>
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="element.currentLang === key"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="dflex"
    >
      <div class="col p-0">
        <input
            v-model="element.label.dix[key]"
            :placeholder="
                        key === 'Unknown'
                            ? placeholders.SHORT
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
            :title="element.label.dix['Unknown']"
            class="mb-1 form-text v-form-label form-text-dark"
            type="text"
        >
      </div>
    </div>
    <label class="vform-editor-label section">Options</label>
    <div class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Size</label>
      </div>
      <div
          v-for="(headingCategory, index) in headingCategories"
          :key="index"
          :class="[
                    'vform-heading-cat-button clickable',
                    headingCategory === element.headingCategory ? 'active' : '',
                ]"
          @click="setCategory(headingCategory)"
      >
        <div class="size">{{ headingCategory.toUpperCase() }}</div>
      </div>
    </div>
    <div class="w-100"/>
    <template v-if="!element.emphasized">
      <label class="vform-editor-label section">Background Color</label>
      <div
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          @click="(event) => {
            setColorPickrId(event, 'backgroundColor');
          }"
          class="clickable"
      />
      <label class="vform-editor-label section">Font Color</label>
      <div
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          @click="(event) => {
            setColorPickrId(event, 'fontColor');
          }"
          class="clickable"
      />
      <color-picker
        v-show="colorPickrId"
        :key="colorPickrId"
        ref="colorpickr"
        :popup-style="true"
        :show-save-button="true"
        :value="element[colorPickrId] ? element[colorPickrId] : '#000'"
        class="step-settings-color-pickr"
        @changeColor="(color) => { setItemColor(colorPickrId, color, false); }"
        @close="() => { colorPickrId = null;}"
        @save="(color) => { setItemColor(colorPickrId, color); }"
    />
      <div
          v-if="element.backgroundColor || element.fontColor"
          class="settings-button d-inline-block mt-2"
          @click="element.backgroundColor = null; element.fontColor = null;">
        {{ $t('resetColor') }}
      </div>
    </template>
    <div class="col row dflex mt-4">
      <div class="dflex">
        <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import ColorPicker from "../widgets/editors/ColorPicker";
export default {
  name: "vFormHEADLINE",
  components: {
    ColorPicker,
  },
  mixins: [vFormElementMixin],
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
  },
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
      headingCategories: ['h1', 'h2', 'h3'],
      colorPickrId: null
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls["HEADLINE"];
    if (!this.element.headingCategory) {
      this.$set(this.element, "headingCategory", "h3");
    }
  },
  methods: {
    setCategory(val) {
        this.$set(this.element, "headingCategory", val);
    },
    save() {
      this.$emit("save", this.element);
    },
  },
};
</script>

<style lang="scss" scoped>
.vform-heading-cat-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;
  transition: all 300ms ease;


  .size {
    vertical-align: baseline;
    font-size: 0.7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }

  &.active, &:hover {
    background-color: var(--vform-editor-accent-color);
  }
}
</style>
