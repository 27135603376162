<template>
<div>
  <div :key="metaSet.id" v-for="metaSet in assetMetaSets">
    <div class="tag">{{ metaSet.name }}</div>
    <div class="mb-2" :key="metaField.id" v-for="metaField in metaSet.metaFields">
      <div class="vform-label white lighter">{{ metaField.description ? metaField.description :  metaField.key }}:</div>
      <div class="d-inline-block meta-value">
        <span v-if="getItemMetaValue(metaField.id)">
          <lightweight-meta-field
              label="metaField"
              :value="getItemMetaValue(metaField.id)"
              :field="metaField"
              :editing="false"
          />
          <!--{{ getItemMetaValue(metaField.id) }} (LOCAL)--></span>
        <span v-else-if="metaField.metaValues && metaField.metaValues.length && metaField.metaValues[0].value !== null">
          <lightweight-meta-field
              label="metaField"
              :value="metaField.metaValues[0].value"
              :field="metaField"
              :editing="false"
          />
          <!--{{ metaField.metaValues[0].value }} (GLOBAL)-->
        </span>
        <span v-else-if="metaField.defaultValue">
          <lightweight-meta-field
              label="metaField"
              :value="metaField.defaultValue"
              :field="metaField"
              :editing="false"
          />
          <!--{{ metaField.defaultValue }} (DEFAULT)-->
        </span>
        <span v-else>-</span>


      </div>
      <!--<div class="d-inline-block meta-value">LOCAL: {{ getItemMetaValue(metaField.id) }}<br />
        GLOBAL: {{ metaField.metaValues && metaField.metaValues.length ? metaField.metaValues[0].value : '-' }}<br />
        DEAFULT: {{ metaField.defaultValue }}</div>-->
      <!--<div class="vform-label white lighter d-inline-block">{{ metaField.metaValues ? metaField.metaValues.key.split('.').pop() : ""}}:</div> <div class="d-inline-block meta-value">{{ metaField.metaValues.value }}</div>-->
    </div>
  </div>
</div>
</template>

<script>
import LightweightMetaField from "./LightweightMetaField";
export default {
  name: "LightweightMetaPanel",
  components: {
    LightweightMetaField,
  },
  props: {
    instanceMetaFields: {type: Array, default: () => {}},
    assetMetaSets: {type: Array, default: () => {}},
    asset: {type: Object, default: () => {}},
    instance: {type: Object, default: () => {}},
  },
  methods: {
    getItemMetaValue(metaFieldId) {
      if(!this.instanceMetaFields) {
        return null;
      }
      const localMetaField = this.instanceMetaFields.filter(metaField => {return metaField.id === metaFieldId});
      if(localMetaField && localMetaField.length) {
        return localMetaField[0].metaValues && localMetaField[0].metaValues.value && localMetaField[0].metaValues.value !== null ? localMetaField[0].metaValues.value : null;
      }
      return null;
    },
  }
}
</script>

<style scoped>
.meta-value {
  font-size: 0.8rem;
}
</style>