<template>
  <main-table-layout sub-menu-selector="librarypanel">
    <div slot="buttonsRight" />
    <content-filter
        slot="filter"
        id="templateList"
        @filter="filtering"
        store-name="Template"
        :category-filter-items="Object.keys(mediaCategories)"
        :category-filter-values="Object.values(mediaCategories)"
        :category-filter-exclude-values="$store.getters.isSuperAdmin ? [] : [0]"
        :team-filtering="true"
    />
    <view-switcher
        slot="mainContentButtons"
        :id="'mainContent'"
        :table="true"
        :thumbnails="true"
    />
    <pagination
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getTemplateListOptions(listName, 'pagination_items')"
        id="templatetablepage"
        @setActive="loadPaginatedData"
        ref="pagination"
    />
    <div slot="mainContentButtons">
      <!--<Button
          permission="create"
          type="action-button"
          slot="mainContentButtons"
          link="/library/template/add"
          icon="plus"
      >
        {{ $t('createNewTemplate') }}
      </Button>-->
    </div>
    <Button
        slot="mainContent"
        @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
        type="reload"
        icon="redo"
        class="icon-button"
    />
    <table-widget
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
        slot="mainContent"
        :id="'templateDefaultTable'"
        :nav-by-clicking-row="true"
        :deletable="true"
        @deleteRow="deleteRow"
        :show-tags="true"
        :show-organization="true"
        edit-permission="write"
        delete-permission="delete"
        :fields="tableFields"
        :data="$store.getters.getTemplatesByListName(listName)"
        :title-as-links="true"
        :use-team-permission="true"
        :loading="loading"
        @sort="sort"
        :initial-sorting="sortObject"
    />
    <thumbnails
        v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
        slot="mainContent"
        :id="'templateDefaultTable'"
        :deletable="true"
        :nav-by-clicking-row="true"
        edit-permission="write"
        delete-permission="delete"
        :fields="thumbnailFields"
        :data="$store.getters.getTemplatesByListName(listName)"
        :title-as-links="true"
        :use-team-permission="true"
        :loading="loading"
        @sort="sort"
    />
  </main-table-layout>
</template>

<script>
import Button from "../components/forms/Button";
import TableWidget from "../components/widgets/TableWidget";
import ContentFilter from "../components/widgets/filter/ContentFilter";
import MainTableLayout from "../layouts/MainTableLayout";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Thumbnails from "../components/widgets/ThumbnailView";
import Pagination from "../components/Pagination";
import FileTypeMixin from '@/components/files/FileTypeMixin.js';
import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";

export default {
  name: "Template",
  components: {
    Button,
    TableWidget,
    ContentFilter,
    MainTableLayout,
    ViewSwitcher,
    Thumbnails,
    Pagination,
  },
  mixins: [FileTypeMixin, LoadOverviewMixinJs],
  data() {
    return {
      listName: 'TemplateList',
      registerMethod: 'registerTemplateQueryParams',
      loadMethod: 'loadTemplates',
      include: 'tags,teams,versions',
      deleteMethod: 'deleteTemplate',
      thumbnailFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'Organization',
          id: 'organization',
          type: 'organization',
          sortable: true,
        },
        {
          name: 'Type',
          id: 'type',
          type: 'string',
          translate: true,
          translateFn: (f, i) => `mediaCategory.${i.mediaCategory}`,
          sortable: true,
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true,
        },
        {
          name: 'DisplayName',
          id: 'displayName',
          type: 'string',
          sortable: true,
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
          sortable: false,
        },
        {
          name: 'Tags',
          id: 'tags',
          type: 'tags',
        },
        {
          name: 'Edit',
          type: 'editButton',
        },
      ],
      tableFields: [
        {
          name: '',
          id: '',
          type: 'image',
        },
        {
          name: 'DisplayName',
          id: 'displayName',
          type: 'string',
          sortable: true,
        },
        {
          name: 'Teams',
          id: 'teams',
          type: 'teams',
          sortable: true,
        },
        {
          name: 'Type',
          id: 'type',
          type: 'string',
          translate: true,
          translateFn: (f, i) => `mediaCategory.${i.mediaCategory}`,
          sortable: true,
        },
        {
          name: 'Last Update',
          id: 'updatedAt',
          type: 'date',
          sortable: true,
        },
        {
          name: 'Edit',
          type: 'editButton',
        },
      ],
    };
  },
  beforeMount() {
    if (this.$store.getters.isSuperAdmin) {
      this.mediaCategories.application = 0;
    }
  },
};
</script>
