<template>
  <div class="row">
    <div class="col-12">
      <content-title-block
          :id="$route.params.id"
          :editable="false"
          :value="$store.getters.getUserName(userId)"
          icon="user"
          store-name="User"
      />
    </div>
    <div class="col-12 col-lg-4 col-xxl-5">
      <div v-if="$store.getters.isSuperAdmin" class="mb-4">
        <select v-model="blocked" class="custom-select border-radius mb-2" @change="updateUser('blocked')">
          <option :value="true">blocked</option>
          <option :value="false">unblocked</option>
        </select>
        <div class="vform-label white mb-0 pb-0 mt-2">{{ $t('Home Organization') }}</div>
        <select v-model="homeOrganizationId" class="custom-select border-radius mt-2"
                @change="updateUser('homeOrganizationId')">
          <option v-for="org in $store.getters.getOrganizations" :key="org.id" :value="org.id">{{
              org.displayName
            }}
          </option>
        </select>
      </div>
      <slot/>
      <h2>{{ $t('personalData') }}</h2>
      <div class="form-error p-2" v-if="error">{{ error }}</div>
      <label-with-edit-icon-and-content
          v-if="!isEditing('name')"
          :make-placeholder-space="false"
          :value="name"
          label="name"
          @edit="setEditing('name')"
      />
      <div v-if="isEditing('name')">
        <input
            v-model="name"
            :class="['form-text', $v.name.$error ? 'form-group--error' : '']"
            type="text"
            @blur="$v.name.$touch"
        >
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="updateUser('name')">
          <icon :type="'save'"/>
        </div>
        <div v-if="!$v.name.minLength" class="form-error">
          {{ $t('errors.atLeastCharacters', {num: 3}) }}
        </div>
      </div>
      <label-with-edit-icon-and-content
          v-if="!isEditing('email')"
          :make-placeholder-space="false"
          :value="email"
          class="mb-2"
          label="email"
          @edit="setEditing('email')"
      />
      <div v-if="isEditing('email')">
        <input
            v-model="email"
            :class="['form-text', $v.email.$error ? 'form-group--error' : '']"
            type="text"
            @blur="$v.email.$touch"
        >
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="updateUser('email')">
          <icon :type="'save'"/>
        </div>
        <div v-if="!$v.email.email" class="form-error">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
        <div v-if="!$v.email.isUnique" class="form-error">{{ $t('errors.emailAddressAlreadyExists') }}</div>
      </div>
      <label-with-edit-icon-and-content
          v-if="!isEditing('firstName')"
          :make-placeholder-space="false"
          :value="firstName"
          label="firstName"
          @edit="setEditing('firstName')"
      />
      <div v-if="isEditing('firstName')">
        <input
            v-model="firstName"
            :class="['form-text', $v.firstName.$error ? 'form-group--error' : '']"
            type="text"
            @blur="$v.firstName.$touch"
        >
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="updateUser('firstName')">
          <icon :type="'save'"/>
        </div>
        <div v-if="!$v.firstName.minLength" class="form-error">
          {{ $t('errors.atLeastCharacters', {num: 3}) }}
        </div>
      </div>
      <label-with-edit-icon-and-content
          v-if="!isEditing('lastName')"
          :make-placeholder-space="false"
          :value="lastName"
          class="mb-2"
          label="lastName"
          @edit="setEditing('lastName')"
      />
      <div v-if="isEditing('lastName')">
        <input
            v-model="lastName"
            :class="['form-text', $v.lastName.$error ? 'form-group--error' : '']"
            type="text"
            @blur="$v.lastName.$touch">
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="updateUser('lastName')">
          <icon :type="'save'"/>
        </div>
        <div v-if="!$v.lastName.minLength" class="form-error">
          {{ $t('errors.atLeastCharacters', {num: 3}) }}
        </div>
      </div>


      <div v-if="($store.getters.isSuperAdmin || $store.getters.getCurrentUserHomeOrg === homeOrganizationId)">
        <label-with-edit-icon-and-content
            v-if="!isEditing('password')"
            :is-password="true"
            :value="password"
            label="password"
            @edit="setEditing('password')"
        />
        <div v-if="isEditing('password')" class="darker p-3">
          <!--<div class="w-100">
            <label class="mt-3">{{ $t('oldPassword') }}*</label>
            <input
                v-model="oldPassword"
                :class="['form-text mb-1', $v.oldPassword.$error ? 'form-group--error' : '']"
                :type="showPassword ? 'text' : 'password'"
                @blur="$v.oldPassword.$touch"
            >
          </div>-->
          <div class="w-100">
            <label class="mt-3">{{ $t('newPassword') }}*</label>
            <input
                v-model="password"
                :class="['form-text mb-1', $v.password.$error ? 'form-group--error' : '']"
                :type="showPassword ? 'text' : 'password'"
                @blur="$v.password.$touch"
            >
            <input v-model="showPassword" type="checkbox"/>{{ $t('showPassword') }}
            <password-checker :password="password"/>
          </div>
          <div class="w-100">
            <label class="mt-3">{{ $t('repeatPassword') }}*</label>
            <input v-model="password2"
                   :class="['form-text mb-1', $v.password2.$error ? 'form-group--error' : '']"
                   type="password"
                   @blur="$v.password2.$touch"
            >
            <div v-if="$v.password2.$error" class="form-error">{{ $t('errors.bothPasswordsMustBeTheSame') }}</div>
          </div>
          <div class="d-flex mt-3">
            <div class="edit-trigger mr-2 clickable" @click="cancelEditing()">
              <icon :type="'times'"/>
            </div>
            <div class="edit-trigger clickable" @click="updateUser('password')">
              <icon :type="'save'"/>
            </div>
          </div>
        </div>
      </div>
      <label-with-edit-icon-and-content
          v-if="!isEditing('appPanel')"
          :make-placeholder-space="false"
          :value="appPanel"
          label="appPanel"
          @edit="setEditing('appPanel')"
      />
      <div v-if="isEditing('appPanel')">
        <input
            v-model="appPanel"
            :class="['form-text', $v.email.$error ? 'form-group--error' : '']"
            placeholder="trest"
            type="text"
        >
        <div class="edit-trigger mr-2" @click="cancelEditing()">
          <icon :type="'times'"/>
        </div>
        <div class="edit-trigger" @click="updateUser('appPanel')">
          <icon :type="'save'"/>
        </div>
      </div>
      <user-idp-setter
          class="mt-2"
          v-if="authMethod && hasSuperPerms"
          :user-id="userId"
          :user-auth-method="authMethod"
      />
    </div>
    <div class="col-12 col-lg-8 col-xxl-7">
      <div class="mb-2 mt-5 vform-label white bigger">{{ $t('organizations') }}</div>
      <info-panel
          :collapsible="true"
          :info-panel-header="$t('organizations')"
          :initially-collapsed="true"
          preview-id="none"
      >
        <div v-if="organizations" slot="info" class="container-fluid no-gutters">
          <div class="row">
            <div class="w-100">
              <view-switcher
                  id="orgTag"
                  :table="true"
                  :thumbnails="true"
                  class="mb-3 mt-3"
              />
            </div>
            <organization-tag
                v-for="org in organizations"
                v-if="organizations.length"
                :key="org.id"
                :is-default="defaultOrganizationId === org.id"
                :is-editable="orgEditPermissions.includes(org.id) || $store.getters.isSuperAdmin"
                :is-removable="(orgEditPermissions.includes(org.id) || $store.getters.isSuperAdmin) && !$store.getters.isSuperOrg(org.id)"
                :org="org"
                :show-details="!orgEditPermissions.includes(org.id)"
                :view-style="$store.getters.getViewSwitcher($route.name, 'orgTag')"
                icon-left="user"
                @removeorg="(obj) => {removeItemUser(obj,'Organization' )}"
                @setDefaultItem="(id) => {updateItemUser(id, {default: true}, 'Organization')}"
                @update="(orgId,item) => {updateItemUser(orgId, item, 'Organization')}"
            />
            <div v-if="!organizations">{{ $t('noOrgsAvailable') }}</div>
            <universal-selector
                v-if="editAllUserData || $store.getters.isSuperAdmin"
                :exclude-from-item-list="organizations"
                :tag-condition="{
                      fieldName: 'visibility',
                      '0': 'user',
                    }"
                class="mt-2"
                filter-attribute="displayName"
                list-name="userOrgList"
                store-name="Organization"
                @addItem="addItemUser"
            />
          </div>
        </div>
      </info-panel>

      <div class="mb-2 mt-5 vform-label white bigger">{{ $t('teams') }}</div>
      <info-panel
          v-for="org in organizations"
          v-if="organizations"
          :key="org.id + 'teams'"
          :collapsible="true"
          :info-panel-header="org.displayName + ': ' + $t('teams')"
          :initially-collapsed="true"
          preview-id="noneNone"
      >
        <div slot="info" class="container-fluid no-gutters">
          <div class="row">
            <div class="w-100">
              <view-switcher
                  id="teamTag"
                  :table="true"
                  :thumbnails="true"
                  class="mb-3 mt-3"
              />
            </div>
            <team-tag
                v-for="team in teams[org.id]"
                :key="team.id"
                :is-default="team.default"
                :is-editable="teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin"
                :is-removable="(teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin) && team.name !== $store.getters.getUserName(userId)"
                :show-details="!teamEditPermissions.includes(team.id) || $store.getters.isSuperAdmin"
                :team="team"
                :view-style="$store.getters.getViewSwitcher($route.name, 'teamTag')"
                icon-left="users"
                @removeteam="(obj) => {removeItemUser(obj,'Team' )}"
                @setDefaultItem="(id) => {updateItemUser(id, {default: true}, 'Team')}"
                @update="(orgId,item) => {updateItemUser(orgId, item, 'Team')}"
            />
            <div v-if="!teams">{{ $t('noOrgsAvailable') }}</div>
            <universal-selector
                v-if="orgEditPermissions.includes(org.id)"
                :key="forceReRenderKey"
                :exclude-from-item-list="teams[org.id]"
                :limit-by-organization-id="org.id"
                :show-organization="true"
                class="mt-2"
                filter-attribute="displayName"
                list-name="userTeamList"
                store-name="Team"
                tag-icon="users"
                @addItem="(item) => {addItemUser(item, 'Team')}"
            />
          </div>
        </div>
      </info-panel>

      <div v-if="$store.getters.isSuperAdmin" class="mb-2 mt-5 vform-label white bigger">{{ $t('licenses') }}</div>
      <info-panel
          v-if="$store.getters.isSuperAdmin"
          :collapsible="true"
          :initially-collapsed="false"
          info-panel-header="licenses"
          preview-id="noneNone"
      >
        <div slot="info" class="container-fluid no-gutters">
          <licenses :user-id="userId"/>
        </div>
      </info-panel>
    </div>
  </div>
</template>

<script>
import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
import Icon from "../Icon";
import LabelWithEditIconAndContent from "../LabelWithEditIconAndContent";
import UniversalSelector from "../organizations/UniversalSelector";
import OrganizationTag from "../organizations/OrganizationTag";
import TeamTag from "@/components/organizations/TeamTag";
import ContentTitleBlock from "../ContentTitleBlock";
import ViewSwitcher from "../widgets/ViewSwitcher";
import InfoPanel from "@/components/InfoPanel";
import {AdminData} from "@/enum";
import PasswordChecker from "@/components/forms/PasswordChecker";
import Licenses from "../Licenses";
import ErrorMixinJs from "../mixins/ErrorMixin.js";
import UserIdpSetter from "@/components/UserIdpSetter.vue";

export default {
  name: "UserEditForm",
  components: {
    Icon,
    LabelWithEditIconAndContent,
    UniversalSelector,
    OrganizationTag,
    ContentTitleBlock,
    ViewSwitcher,
    InfoPanel,
    TeamTag,
    PasswordChecker,
    Licenses,
    UserIdpSetter
  },
  mixins: [ErrorMixinJs],
  props: {
    userId: {type: String, default: null},
    hasSuperPerms: {type: Boolean, default: false}
  },
  data() {
    return {
      blocked: 0,
      showPassword: false,
      AdminData: AdminData,
      appPanel: '',
      editAllUserData: false,
      orgEditPermissions: [],
      teamEditPermissions: [],
      fieldEditing: '',
      enableSaving: false,
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      //oldPassword: '',
      password: '',
      password2: '',
      error: '',
      role: '',
      organizations: [],
      teams: {},
      defaultOrganizationId: '',
      defaultTeamId: '',
      forceReRenderKey: 0,
      licenses: [],
      homeOrganizationId: '',
      authMethod: null
    };
  },
  mounted() {
    this.init();
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfUserNameExists', {name: value, type: 'User', value: value}).then(data => {
          if (data.length > 0 && data[0]) {
            if (data[0].id === this.userId) {
              bool = true;
            }
          } else {
            bool = data.length === 0;
          }
        });
        return bool;
      }
    },
    firstName: {
      required,
      minLength: minLength(3)
    },
    lastName: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email,
      async isUnique(value) {
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfUserEmailExists', value).then(data => {
          if (data.length > 0 && data[0]) {
            if (data[0].id === this.userId) {
              bool = true;
            }
          } else {
            bool = data.length === 0;
          }
        });
        return bool;
      }
    },
    /*oldPassword: {
      required
    },*/
    password: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password')
    },
  },
  methods: {
    async init() {
      await this.loadUser();
      await this.loadUserOrganizations();
      await this.loadUserTeams();
    },
    async loadUser() {
      let listName = 'userList';
      if (this.userId === this.$store.getters.getCurrentUserId) {
        listName = 'globalUser';
      }
      await this.$store.dispatch('loadUser', {
        id: this.userId,
        include: 'organizations,teams',
        listName: listName
      }).then(async data => {
        if (data) {
          if (!this.$store.getters.isSuperAdmin && !this.$store.getters.isSFXMember) {
            this.$emit('checkPermission', data.homeOrganizationId);
          }
          this.homeOrganizationId = data.homeOrganizationId;
          delete data.teams;
          delete data.organizations;
          this.setData(data);
        } else {
          this.$emit('userNotFound');
        }
      });
    },
    async loadUserTeams() {
      await this.$store.dispatch('clientLoadUserTeams', {id: this.userId}).then(async data => {
        this.teams = {};
        data.map(team => {
          if (!this.teams[team.organizationId]) {
            this.teams[team.organizationId] = [];
          }
          this.teams[team.organizationId].push(team);
        })
        await this.setPermissionPerTeam(data);

        this.forceReRenderKey++;
      })
    },
    async setPermissionPerTeam(data) {
      for (let i = 0; i < data.length; i++) {
        const hasTeamAccess = await this.$store.dispatch('checkTeamOrgPermission', {
          op: 'writeUser',
          opOnly: true,
          orgPermissionOnly: true,
          organizationId: data[i].organizationId,
        });
        if (hasTeamAccess || this.$store.getters.isSuperAdmin) {
          this.teamEditPermissions.push(data[i].id);
        }
      }
    },
    async loadUserOrganizations() {
      await this.$store.dispatch('clientLoadUserOrganizations', {
        id: this.userId,
        include: ['requiredUser']
      }).then(async data => {
        this.organizations = data;

        const filtered = this.organizations.filter(item => {
          return item.members && item.members[0] && item.members[0].relation.default;
        });
        if (filtered && filtered.length) {
          this.defaultOrganizationId = filtered[0].id;
        }
        await this.setPermissionPerOrganization();
      })
    },
    async setPermissionPerOrganization() {
      for (let i = 0; i < this.organizations.length; i++) {
        const hasOrgAccess = await this.$store.dispatch('checkTeamOrgPermission', {
          op: 'writeOrganization',
          organizationId: this.organizations[i].id
        });
        if (hasOrgAccess) {
          this.orgEditPermissions.push(this.organizations[i].id);
        }
      }
    },
    /**
     * Copies data from query result to local data of the userEditForm component
     * */
    setData(data) {
      const disattachedData = JSON.parse(JSON.stringify(data));
      Object.keys(disattachedData).map(key => {
        this[key] = disattachedData[key];
      })
    },
    /**
     * Sets a field to editing
     * */
    setEditing(fieldName) {
      this.fieldEditing = fieldName;
    },
    /**
     * Checks whether a field is editing
     * */
    isEditing(fieldName) {
      return this.fieldEditing === fieldName;
    },
    /**
     * Cancels editing
     * */
    cancelEditing: function () {
      this.fieldEditing = '';
      this.loadUser();
    },
    /***
     * Removes a user from either an organization or a team
     * @params {object} obj - the user object
     * @params {string} type - either 'Organization' or 'Team'
     * */
    removeItemUser: function (obj, type = 'Organization') {
      this.$store.dispatch(`clientRemove${type}Member`, {
            id: obj.id,
            cid: this.id,
            args: [this.userId],
          }
      ).then(() => {
        this.init();
      });
    },
    /***
     * Adds a user to either an organization or a team
     * @params {object} obj - the user object
     * @params {string} type - either 'Organization' or 'Team'
     * */
    addItemUser: function (org, type = 'Organization') {
      this.$store.dispatch(`clientAdd${type}Member`, {
            id: org.id,
            cid: this.id,
            args: [{
              userId: this.id,
              role: type === 'Organization' && org.id !== this.AdminData.vHubOrganizationId ? 'none' : 'viewer',
            }],
          }
      ).then(() => {
        this.init();
      });
    },
    /***
     * Updates a user relationship to either an organization or a team
     * @params {string} organizationId - the id of the organization concerning this
     * @params {object} obj - the membership object containing e.g. the role
     * @params {string} type - either 'Organization' or 'Team'
     * */
    updateItemUser: function (organizationId, memberObject, type = 'Organization') {
      this.$store.dispatch(`clientUpdate${type}Member`, {
            id: organizationId,
            cid: this.userId,
            ...memberObject
          }
      ).then(() => {
        this[`loadUser${type}s`]();
        this.loadUser();
      });
    },
    updateUser: async function (fieldName) {
      this.error = '';
      if (!this.$v.$anyError) {
        let args = {
          [fieldName]: this[fieldName],
          id: this.userId
        };
        /*if (fieldName === 'password') {
          args.oldPassword = this.oldPassword;
        }*/
        await this.$store.dispatch('updateUser', args)
          .then(() => {
            this.cancelEditing();
          })
          .catch(e => {
            this.error = this.getErrorContent(e);
          });

      }
    },
  },
}
</script>