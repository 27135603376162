<template>
  <one-column-sidebar-left
      sub-menu-selector="adminpanel"
      tab-selector="roletab"
      type="role"
      back-button-tab-selector="rolebackbutton"
      :id="$route.params.id"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                upload-type="Role"
                :created="$store.getters.getRoleCreationDate($route.params.id)"
                :last-change="$store.getters.getRoleChangeDate($route.params.id)"
                :downloadable="true"
                :organization="$store.getters.getRoleOrganization($route.params.id)"
                preview-id="roleEdit"
                :removable="true"
    />

        <div slot="mainContent">
          <h1>{{ $store.getters.getRoleDisplayName(id) }}</h1>
          <p>({{ $store.getters.getRoleName(id) }})</p>
          <content-field-block
              class="mb-2 mt-3"
              :value="$store.getters.getRoleDisplayName(id)"
              :id="$route.params.id"
              store-name="Role"
              field-name="displayName"
              label="displayName"
              input-type="text"
              @update="loadRole"
          />
          <content-description-block
              :value="$store.getters.getRoleDescription(id)"
              :id="$route.params.id"
              store-name="Role"
          />
          <div v-if="finishedLoading">
            <div :key="index * 55" v-for="(category, index) in categories">
              <h3 class="mt-5 darker p-2">{{ $t('permissions.' + category) }}</h3>
              <div class="p-2">
                <div :key="index" v-for="(permission, index) in permissions.filter(item => {return item.category === category})">
                 <input class="mr-1" @change="changePermission(permission.id)" type="checkbox" v-model="rolePermissions[permission.id]" />{{ permission.displayName }}
                <div class="lighter mb-1">{{ permission.description }}</div>
              </div>
              </div>
            </div>
            <div class="form-error mb-2" v-if="error">{{ error }}</div>
          </div>

</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import ContentFieldBlock from "@/components/ContentFieldBlock";

    export default {
        name: 'RoleEdit',
        components: {
            InfoPanel,
            OneColumnSidebarLeft,
            ContentDescriptionBlock,
            ContentFieldBlock,
        },
        data() {
            return {
                id: '',
                permissions: [],
                rolePermissions: {},
                finishedLoading: false,
                error: '',
                categories: []
            };
        },
        beforeMount() {
          this.loadRole();
          this.loadPermissions();
          this.id = this.$route.params.id;
        },
        methods: {
          loadRole() {
              this.$store.dispatch('loadRole', {
                id: this.$route.params.id,
              }).catch(e => {
                if(e.status === 401) {
                  this.$router.push('/access-denied');
                } else if(e.status === 404) {
                  this.$router.push('/not-found')
                }
              });
            },
          loadPermissions() {
            this.finishedLoading = false;
            this.$store.dispatch('clientLoadPermissions').then(permissions => {
              this.permissions = permissions;
              permissions.map(item => {
                this.rolePermissions[item.id] = false;
              });
              this.categories = permissions.map(item => {return item.category}).filter((value, index, self) => {return self.indexOf(value) === index;})
              return this.$store.dispatch('clientLoadRolePermissions',{id: this.$route.params.id});
            }).then(rolePermissions => {
              rolePermissions.map(item => {
                this.rolePermissions[item.permissionId] = true;
              });
              this.finishedLoading = true;
            });
          },
          changePermission(permissionId) {
            this.error = '';
            const dispatchMethod = this.rolePermissions[permissionId] ? 'clientAddRolePermissions' : 'clientRemoveRolePermissions';
            this.$store.dispatch(dispatchMethod, {
              id: this.$route.params.id,
              args: [permissionId]
            }).then(() => {
              this.loadPermissions();
            }).catch(() => {
              this.error = 'Die Basis-Permissions der Rollen können aus Sicherheitsgründen nicht bearbeitet werden';
              this.loadPermissions();
            });
          },
        },
}
</script>