import Vue from 'vue'
import {vformModes} from "@/enum";
//import $router from '@/router'
//import _ from 'lodash';
const state = {
    images: {},
    imagesLoading: {},
    imagesFailed: {},
    imagesErrors: {},
    vFormMode: vformModes.PRODUCTION,
    visitedElements: {},
    templates: {}
};

const getters = {
    getBlockTemplates: (state) => {
        return state.templates ? Object.keys(state.templates).map(key => {
            return state.templates[key]
        }) : []
    },
    isElementVisited: (state) => (itemId) => {
        return state.visitedElements[itemId]
    },
    getvFormMode: (state) => {
        return state.vFormMode;
    },
    getFormImageById: (state) => (imageId) => {
        return state.images[imageId] ? state.images[imageId] : null;
    },
    getImagesLoading: (state) => (imageId) => {
        return state.imagesLoading[imageId] ? state.imagesLoading[imageId] : false;
    },
    getImagesFailed: (state) => (imageId) => {
        return state.imagesFailed[imageId] ? state.imagesFailed[imageId] : false;
    },
    getImagesError:(state) => (imageId) => {
        return state.imagesErrors[imageId] ? state.imagesErrors[imageId] : "";
    },
};

const mutations = {
    setTemplates(state, templates) {
        console.log('got templates', templates)
        for(let i = 0; i < templates.length; i++) {
            const templ = templates[i];
            console.log('templ', templ);
            Vue.set(state.templates, templ.key, templ);
        }
    },
    setElementVisited(state, elId) {
        Vue.set(state.visitedElements, elId, true);
    },
    setvFormModeMutation(state, mode) {
      Vue.set(state, "vFormMode", mode);
    },
    clearCacheMutation(state) {
        Vue.set(state, "images", {});
        Vue.set(state.imagesLoading, {});
    },
    /**
     * @params thingys
     *  - uuid - the uuid of the image asset
     *  - code - the base64 image string
     * **/
    setImageMutation(state, thingys) {
        const {uuid, code} = thingys;
        Vue.set(state.images, uuid, code);
    },
    imagesLoadingMutation(state, imageUuid) {
        Vue.set(state.imagesLoading, imageUuid, true);
    },
    imagesNotLoading(state, imageUuid) {
        Vue.set(state.imagesLoading, imageUuid, false);
    },
    imagesFailedMutation(state, {id, counter}) {
        Vue.set(state.imagesFailed, id, {counter: counter});
    },
    imagesErrorsMutation(state, {id, error}) {
        Vue.set(state.imagesErrors, id, error);
    },
    imagesFailedResetMutation() {
        Vue.set(state, "imagesFailed", {});
        Vue.set(state, "imagesLoading", {});
        Vue.set(state, "imagesErrors", {});
    }
};

const actions = {
    async loadBlockTemplates({dispatch, commit}, args) {
        const file = await dispatch('clientLoadCombinedAssetPart', args);
        commit('setTemplates', file);
        return file;
    },
    setElementVisited({commit}, elId) {
        commit('setElementVisited', elId);
    },
    /**
     * Sets the vform mode
     * if mode equals test, then logging is disabled automagically
     * */
    setvFormMode({commit}, mode) {
      commit('setvFormModeMutation', mode);
    },
    imagesFailedLoadingReset({commit}) {
        commit("imagesFailedResetMutation");
    },
    clearFormsImageCache({commit}) {
        commit("clearCacheMutation");
    },
    async loadFormImageFromDisk({commit}, args) {
        const {uuid, imageData} = args;
        commit('setImageMutation', {uuid: uuid, code: imageData});
    },
    async resetFormImage({commit}, args) {
        const {id} = args;
        commit('setImageMutation', {uuid: id, code: null});
    },
    async loadFormImage({state, commit, dispatch, getters}, args) {
        const {id, force} = args;
        if(getters.getImagesLoading(id) && !force) {
            return;
        }
        commit('imagesLoadingMutation', id);
        //Vue.set(state.imagesLoading, id, true);
        // if image already exists: don't load it a second time
        if(state[id] && !force) {
            return;
        }
        let key;
        return await dispatch("clientLoadAsset", { id: id })
            .then(async (asset) => {
                const previewUri = asset.previewUri;
                key = previewUri.split("/")[1];
                return await dispatch("clientDownloadAsset", {id: id, key: key})
            })
            .then(async (data) => {
                const imageUrl = window.URL.createObjectURL(data.text);
                commit('setImageMutation', {uuid: id, code: imageUrl});
                commit('imagesNotLoading', id);
                commit('imagesFailedMutation', {id, counter: 0});
            })
            .catch(async e => {
                console.log('could not load image')
                if(e.statusCode === 401) {
                    commit('imagesErrorsMutation', {id, error: {message: "Permission denied", code: 401}})
                    throw new Error(e.statusCode);
                } else if(e.statusCode === 404) {
                    commit('imagesErrorsMutation', {id, error: {message: "Image not found", code: 404}})
                    return;
                }
                let counter = state.imagesFailed[id] && state.imagesFailed[id].counter ?  state.imagesFailed[id].counter : 1;
                if(counter < 4) {
                    console.log('trying again...')
                    commit('imagesNotLoading', id);
                    //Vue.set(state.imagesLoading, id, false);
                    //Vue.set(state.imagesFailed, id, {counter: counter + 1});
                    commit('imagesFailedMutation', {id, counter: counter + 1});
                    setTimeout(async () => {
                        return await dispatch("loadFormImage", args);
                    }, 2000)
                } else {
                    console.log(e.errorCode)
                    commit('imagesNotLoading', id);
                    const message = e.statusCode === 404 ? "Image not found" : "There was a problem loading the image, please reload the form";
                    commit('imagesErrorsMutation', {id, error: {message: message, code: e.statusCode}})
                    console.log('giving up')
                    throw new Error(e);
                }
            });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}