import Vue from 'vue'
//import {_} from "vue-underscore";
//import i18n from "../translation";

const state = {
    exportProfiles: {},
};

const getters = {
    /*getExportProfiles() {
        return state.exportProfiles.textList;
    },*/
    getExportProfilesById: (state) => (id) => {
        return state.exportProfiles[id] ? state.exportProfiles[id] : {};
    },
    getMergeById: (state) => (id) => {
        return state.exportProfiles[id] && state.exportProfiles[id].merge ? state.exportProfiles[id].merge : [];
    },
    getExportFilterById: (state) => (id) => {
        return state.exportProfiles[id] && state.exportProfiles[id].filter ? state.exportProfiles[id].filter : [];
    },
    getExportFilterStringById: (state) => (id) => {
        return state.exportProfiles[id] && state.exportProfiles[id].filterStringArray ? state.exportProfiles[id].filterStringArray : [];
    },
    getExportMergeStringById: (state) => (id) => {
        return state.exportProfiles[id] && state.exportProfiles[id].mergeStringArray ? state.exportProfiles[id].mergeStringArray : [];
    },
    getExportFilterModeById: (state) => (id) => {
        return state.exportProfiles[id] && state.exportProfiles[id].pipeline && state.exportProfiles[id].pipeline.subVersion ? state.exportProfiles[id].pipeline.subVersion.mode : 'include';
    },

};

const mutations = {
    importExportProfileMutation(state, data) {
        const {id, value} = data;

        if (state.exportProfiles[id]) {
            Vue.delete(state.exportProfiles, id);
        }
        Vue.set(state.exportProfiles, id, value);
    },
    overwritePropertyMutation(state, data) {
        const {property, id, value} = data;
        if(!state.exportProfiles[data.id]) {
            return;
        }
        Vue.set(state.exportProfiles[id], property, value);
    },
    overwriteModeMutation(state, data) {
        const {id, value} = data;
        if(!state.exportProfiles[data.id]) {
            return;
        }
        if(!state.exportProfiles[id].pipeline) {
            Vue.set(state.exportProfiles[id], "pipeline", {});
        }
        if(!state.exportProfiles[id].pipeline.subVersion) {
            Vue.set(state.exportProfiles[id].pipeline, "subVersion", {});
        }
        Vue.set(state.exportProfiles[id].pipeline.subVersion, "mode", value);
    }
};

const actions = {
   importProfile({commit}, args = {}) {
       const {id, value} = args;
       if(!value.filter) {
           value.filter = [];
       }
       if(!value.merge) {
           value.merge = [];
       }
       if(!value.filterStringArray) {
           value.filterStringArray = [];
       }
       if(!value.mergeStringArray) {
           value.mergeStringArray = [];
       }
       commit('importExportProfileMutation', {id, value});
   },
    overwriteFilter({commit}, args = {}) {
        const {id, value} = args;
        commit('overwritePropertyMutation', {id, value, property: 'filter'});
    },
    overWriteMerge({commit}, args = {}) {
        const {id, value} = args;
        commit('overwritePropertyMutation', {id, value, property: 'merge'});
    },
    overWriteFilterStringArray({commit}, args = {}) {
        const {id, value} = args;
        commit('overwritePropertyMutation', {id, value, property: 'filterStringArray'});
    },
    overWriteMergeStringArray({commit}, args = {}) {
        const {id, value} = args;
        commit('overwritePropertyMutation', {id, value, property: 'mergeStringArray'});
    },
    overWriteFilterMode({commit}, args = {}) {
        const {id, value} = args;
        commit('overwriteModeMutation', {id, value});
    },
};
export default {
    state, getters, mutations, actions
}
