<template>
  <div class="w-100">
    <video :key="forceReRenderKey" ref="video99" v-if="(initialVideoURL || videoURL) && !limitedPreview && autoPlay" width="100%" height="auto"
           controls autoplay :muted="muted">
      <source
          :type="mimeType"
          :src="initialVideoURL ? initialVideoURL : videoURL"
      >
      Your browser does not support the video tag.
    </video>
    <video :key="forceReRenderKey" ref="video99" v-else-if="(initialVideoURL || videoURL) && !limitedPreview" width="100%" height="auto"
           controls>
      <source
          :type="mimeType"
          :src="initialVideoURL ? initialVideoURL : videoURL"
      >
      Your browser does not support the video tag.
    </video>
    <div v-else class="square-image lighter">
      <icon class="preview-icon" type="video" size="1.8" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "VideoPreview",
  components: {
    Icon,
  },
  props: {
    previewData: {type: String, default: ''},
    initialVideoURL: {type: String, default: ''},
    mimeType: {type: String, default: 'video/mp4'},
    limitedPreview: {type: Boolean, default: false},
    autoPlay: {type: Boolean, default: false},
    muted: {type: Boolean, default: false},
  },
  data() {
    return {
      videoURL: null,
      forceReRenderKey: 0
    };
  },
  watch: {
    previewData(val){
      if (val){
        this.createVideoURL();
      }
    },
    initialVideoURL() {
      this.forceReRenderKey++;
    },
  },
  mounted() {
    this.createVideoURL();
  },
  methods: {
    stop() {
      this.$refs.video99.pause();
    },
    start() {
      this.$refs.video99.play();
    },
    createVideoURL(){
      if (this.previewData && !this.videoURL && !this.initialVideoURL) {
        const base64VideoString = this.previewData; // Replace with your actual Base64 string

        // Decode the Base64 string
        const binaryData = atob(base64VideoString.split(',')[1]);

        // Create a Uint8Array from the binary data
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], {type: 'video/mp4'});

        this.videoURL = URL.createObjectURL(blob);
      }
    }
  }
}
</script>

<style scoped>
video {
  width: 100%;
  transition: all 300ms ease;
}
</style>