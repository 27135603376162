<template>
  <div>
    <label>{{ $t('chooseType', {type: $t('type.MetaField')}) }}*</label>
    <select class="custom-select" v-model="selectedMetaField">
      <option :value="item.id" :key="value" v-for="(item, key, value) in metaFields">
        {{ item.key }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "MetaFieldSelector",
  props: {
    metaSetId: {type: String, required: true}
  },
  data() {
    return {
      metaFields: [],
      selectedMetaField: {}
    };
  },
  watch: {
    metaSetId() {
      this.loadMetaFields();
    },
    selectedMetaField() {
      this.select();
    }
  },
  beforeMount() {
    this.loadMetaFields();
  },
  methods: {
    select() {
      const selected = this.metaFields.filter(item => {return item.id === this.selectedMetaField})[0];
       this.$emit('change', selected);
    },
    loadMetaFields() {
      if(!this.metaSetId) {return;}
      this.$store.dispatch('clientLoadMetaSet', {
            id: this.metaSetId,
            include: ['metaFields']
          }).then(metaSet => {
            this.metaSetName = metaSet.name;
            if(metaSet) {
              this.metaFields = metaSet.metaFields;
            }
          }).catch(() => {
            this.error = this.$t('couldntBeLoaded');
          })
    }
  }
}
</script>

<style scoped>

</style>