<template>
    <div class="row">
        <div class="col-12 col-md-5">
            <span class="chart-font-size"/>
            <canvas class="mt-3 mb-3" :id="uniqueIdentifier" width="400" height="400" />
            <div v-if="legend" class="chart-legend" v-html="legend" />
        </div>
        <div class="col-5 mr-3">
            {{ message }}
            <div v-if="localConfig.preFilteringIndizes && preFilterValues && preFilterValues.length" :class="['collapsible mt-4 mb-3', preFilterCollapsed ? 'is-collapsed' : '']">
                <div @click="preFilterCollapsed = !preFilterCollapsed" class="header transparent slim"><h3>{{ $t('dataPreSelection') }}</h3> <icon type="angle-down" /></div>
                <div class="content">
                    <div :key="index" v-for="(obj, index) in preFilterValues">
                        <label>{{ $t(obj.label) }}</label><br >
                        <div class="float-right mt-1" v-if="localConfig.preFilterSelected[index] && index !== 0" @click="preFilterData(true, index)">
                            <icon type="times" />
                        </div>
                        <select :key="Math.random()"
                                class="custom-select dark mb-3 col-11"
                                :ref="'select' + index"
                                v-model="localConfig.preFilterSelected[index]"
                                @change="preFilterData(index === 0)"
                                @click="checkPreFilter(index)"
                                :disabled="localConfig.preFilterSelected[index] && index !== 0"
                        >
                            <option v-for="(value, index2) in obj.data"
                                    :key="index2"
                                    :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div v-if="showConfig">
                <div :class="['collapsible mt-4', basicConfiguratorCollapsed ? 'is-collapsed' : '']">
                    <div @click="basicConfiguratorCollapsed = !basicConfiguratorCollapsed" class="header transparent slim"><h3>{{ $t('basicConfiguration') }}</h3> <icon type="angle-down" /></div>
                    <div class="content">
                        <!--<label>{{ $t('type') }}</label>
                        <select class="custom-select dark mb-3"
                                ref="typeSelect"
                                v-model="currentType"
                                @change="() => {
                        theChart.destroy();
                        initChart();
                    }"
                        >
                            <option v-for="(value, index) in types"
                                    :key="index"
                                    :value="value"
                            >
                                {{ value }}
                            </option>
                        </select>-->

                        <label>{{ $t('CategoryIndex 1 (x axis)') }}</label>
                        <select class="custom-select dark mb-3"
                                ref="typeSelect"
                                v-model="localConfig.categoryIndex1"
                                @change="prepareDataForChart"
                        >
                            <option v-for="(field, index) in Object.keys(fields)"
                                    :key="index"
                                    :value="index"
                            >
                                {{ field }}
                            </option>
                        </select>

                        <label>{{ $t('CategoryIndex 2 (sorting type)') }}</label>
                        <select class="custom-select dark mb-3"
                                ref="typeSelect"
                                v-model="localConfig.categoryIndex2"
                                @change="prepareDataForChart"
                        >
                            <option v-for="(field, index) in Object.keys(fields)"
                                    :key="index"
                                    :value="index"
                            >
                                {{ field }}
                            </option>
                        </select>

                        <label>{{ $t('CategoryIndex 3 (y axis)') }}</label>
                        <select class="custom-select dark"
                                ref="typeSelect"
                                v-model="localConfig.categoryIndex3"
                                @change="prepareDataForChart"
                        >
                            <option v-for="(field, index) in Object.keys(fields)"
                                    :key="index"
                                    :value="index"
                            >
                                {{ field }}
                            </option>
                        </select>
                    </div>
                </div>
                <div :class="['collapsible mt-4', labelConfiguratorCollapsed ? 'is-collapsed' : '']">
                    <div @click="labelConfiguratorCollapsed = !labelConfiguratorCollapsed" class="header transparent slim"><h3>{{ $t('labelConfigurator') }}</h3><icon type="angle-down" /></div>
                    <div class="content">
                        {{ $t('chooseLabelConfiguration') }}<br ><br >
                        <div v-for="(field, index) in Object.keys(fields)"
                             :key="index"
                        >
                            <input @click="addRemoveLabelData(fields[Object.keys(fields)[index]].columnIndex)" :checked="localConfig.addValuesToLabelIndizes && localConfig.addValuesToLabelIndizes.includes(index)" class="mr-1" type="checkbox" id="vehicle3" :name="field" :value="index">
                            <label :for="field"> {{ field }}</label>
                        </div>
                    </div>
                </div>
</div>

            <div :class="['collapsible mt-4', namingConfiguratorCollapsed ? 'is-collapsed' : '']">
                <div @click="namingConfiguratorCollapsed = !namingConfiguratorCollapsed" class="header transparent slim"><h3>{{ $t('additionalConfiguration') }}</h3><icon type="angle-down" /></div>
                <div class="content">
                    <label>{{ $t('chartName') }}</label>
                    <input :value="localConfig.name ? localConfig.name : uniqueIdentifier" class="form-text w-100" type="text" placeholder="chartname" @change="(e) => { localConfig.name = e.target.value}" >

                    <label>{{ $t('chartDescription') }}</label>
                    <div class="small">{{ $t('limitToNCharactersHint', {num: 180}) }}</div>
                    <textarea :value="localConfig.description ? localConfig.description : ''" class="form-text w-100" placeholder="description" @keyup="(e) => { localConfig.description = e.target.value; countDescriptionCharacters(e.target.value)}" />
                    <div class="small">{{ $t('remainingCharacters') }}: {{ descriptionLength }}</div>
                </div>
            </div>
            <Button
                    v-if="showSaveButton"
                    type="button"
                    class="mb-3 mt-3"
                    @click="$emit('saveConfig', localConfig, chartId)"
            >
                <icon type="save"/> {{ $t('SAVE') }}
            </Button><br >
            <Button
                    v-if="showDeleteButton"
                    type="button"
                    class=""
                    @click="$emit('removeConfig', chartId)"
            >
                <icon type="times"/> {{ $t('REMOVE') }}
            </Button>
        </div>
    </div>
</template>

<script>
    import Chart from 'chart.js';
    import DateMixin from '@/components/mixins/DateMixin.js';
    import Button from "../../../components/forms/Button";
    import Icon from "../../Icon";

    export default {
        name: "Chart",
        components: {
            Button,
            Icon,
        },
        mixins: [
            DateMixin
        ],
        props: {
            chartConfig: {type: Object, default: null},
            fields: {type: Object, required: true},
            uniqueIdentifier: {type: String, required: true},
            data: {type: Array, required: true},
            config: {type: Object, default: null},
            showSaveButton: {type: Boolean, default: false},
            showDeleteButton: {type: Boolean, default: false},
            chartId: {type: String, required: true},
        },
        data() {
            return {
                descriptionLength: 0,
                fontSize: 14,
                labelConfiguratorCollapsed: true,
                basicConfiguratorCollapsed: true,
                preFilterCollapsed: false,
                namingConfiguratorCollapsed: true,
                message: '',
                localConfig: {
                    categoryIndex1: 0,
                    categoryIndex2: 0,
                    categoryIndex3: 0,
                    afterSortingIndex: -1,
                    preFilteringIndizes: null,
                    customColorConfig: {},
                    preFilterSelected: [],
                },
                localData: null,
                preFilterValues: [],
                theChart: null,
                currentType: 'bar',
                showConfig: true,
                types: [
                    'bar',
                    'pie',
                    'line',
                    'radar',
                    'doughnut',
                    'bubble',
                    'scatter'
                ],
                yAxisType: 'linear',
                xAxisType: 'category',
                legend: '',
                xAxisLabel: 'xAxis',
                yAxisLabel: 'yAxis',
                dataCategory1: [],
                dataCategory2: [],
                datasetsByCategories: [],
                xdata: [],
                chartDataSets: [],
                colorArray: [
                    '#11858c',
                    '#e21c49',
                    '#dc5353',
                    '#dabd4f',
                    '#515163',
                    '#14d27b',
                    '#dc5353',
                    '#dabd4f',
                    '#11858c',
                    '#e21c49',
                    '#dc5353',
                    '#dabd4f',
                ],
            };
        },
        watch: {
            data: function() {
              this.loadData();
            },
            config: function() {
                this.loadConfig();
            },
        },
        mounted() {
            this.loadConfig();

            if(this.localConfig.description) {
                this.countDescriptionCharacters(this.localConfig.description);
            }
            this.fontSize =  parseFloat(getComputedStyle(document.getElementsByClassName('chart-font-size')[0]).fontSize);
        },
        methods: {
            loadConfig() {
                this.localConfig = this.config;
                if(this.localConfig.preFilteringIndizes.length) {
                    this.showConfig = false;
                }
                this.loadData();
            },
            loadData() {
                this.message = '';
                if(this.data) {
                    if(this.data.length && this.fields) {
                        this.localData = this.data;
                        if(!this.localConfig.preFilteringIndizes) {
                            //if there is a preFiltering, the preFilterData will trigger the prepareDataForChart when everything is ready
                            this.prepareDataForChart();
                        }
                        else {
                            if(!this.preFilterValues || !this.preFilterValues.length) {
                                //add prefiter Dropdowns if missing (in case we duplicate a config with preselected filters)
                                this.preparePreFilterDropdowns();
                            }
                            this.preFilterData();
                        }
                    }
                }
                else {
                    this.message = this.$t('noDataAvailable');
                }
            },
            preparePreFilterDropdowns() {
                this.preFilterValues = [];
                for(let i = 0; i < this.localConfig.preFilteringIndizes.length; i++) {
                    let useGlobalData = i === 0;
                    this.preFilterValues.push({label: Object.keys(this.fields)[this.localConfig.preFilteringIndizes[i]], data: this.getDistinctRowValues(this.localConfig.preFilteringIndizes[i], useGlobalData)});
                    this.preFilterValues[i].data.unshift('all');
                }
            },
            preSortData() {
                let targetField = this.getField(this.localConfig.afterSortingIndex);
                this.localData.sort((a,b) => { return this.compareItems(targetField.type, a[this.localConfig.afterSortingIndex], b[this.localConfig.afterSortingIndex])});
                //this.$log.warn( JSON.stringify(this.localData, null, 2));
            },
            checkPreFilter(index) {
                if(index !== 0) {
                    if(this.localConfig.preFilterSelected[index]) {
                        this.preFilterData(true, index);
                    }
                }
            },
            preFilterData(reset = false, resetIndex = null) {
                if(!this.localConfig.preFilterSelected) {
                    this.localConfig.preFilterSelected = [];
                }
                //reset data/prefilters if the first dropdown is changed
                if(reset) {
                    this.localData = this.data;
                    this.showConfig = false;
                    if(resetIndex || resetIndex === 0) {
                        this.localConfig.preFilterSelected.splice(resetIndex, this.localConfig.preFilterSelected.length - 1);
                        this.preFilterData();
                        /*this.$refs['select' + resetIndex][0].click();
                        this.$refs['select' + resetIndex][0].style.backgroundColor = 'red';*/
                    }
                    else {
                        this.localConfig.preFilterSelected.splice(1, this.localConfig.preFilterSelected.length - 1);
                    }
                    if(this.theChart) {
                        this.theChart.destroy();
                    }
                }
                //filter for selected prefilter dropdowns
                if(this.localConfig.preFilterSelected) {
                    for(let i = 0; i < this.localConfig.preFilterSelected.length; i++) {
                        if(this.localConfig.preFilterSelected[i] !== 'all' ) {
                            this.localData = this.localData.filter(item => {return item[this.localConfig.preFilteringIndizes[i]] === this.localConfig.preFilterSelected[i]});
                        }
                    }
                }
                //if all prefilters are set, go for it, show the chart
                if(this.localConfig.preFilterSelected && !reset) {
                    this.showConfig = true;
                    this.prepareDataForChart();
                }

                //redo the prefilterdropdowns based on the filtered data
                this.preparePreFilterDropdowns();
                this.prepareDataForChart();

            },
            prepareDataForChart() {
                if(this.localConfig.afterSortingIndex !== -1) {
                    this.preSortData();
                }
                this.setDataCategory1(this.localConfig.categoryIndex1);
                this.setDataCategory2(this.localConfig.categoryIndex2);

                let targetField = this.getField(this.localConfig.categoryIndex3);
                this.yAxisType = this.getAxisType(targetField.type);

                let targetField2 = this.getField(this.localConfig.categoryIndex1);
                this.xAxisType = this.getAxisType(targetField2.type);

                //category 1: [0,1,2] (e.g. slideNames)
                //category 2: failed, success e.g. (grouping-field for labels)
                //category 3: time, y-axis
                this.filterByCategory2(); //groups the data by category 2
                this.filterByCategory1();
                this.filterByCategory3();
                this.initChart();
                this.legend = this.theChart.generateLegend();
            },
            //these are the labels for e.g. the x-axis
            setDataCategory1(index) {
                this.dataCategory1 = [];
                if(this.localData) {
                    //get all distinct values and set them up as labels and grouping values
                    this.dataCategory1 = this.getDistinctRowValues(index);
                }
            },
            //these are the categories for the datasets which form the labels
            setDataCategory2(propertyName, index) {
                this.dataCategory2 = [];
                if(this.localData) {
                    this.dataCategory2 = this.createDatasetCategories(index);
                }
            },
            createDatasetCategories() {
                let datasets = [];
                let categories = this.getDistinctRowValues(this.localConfig.categoryIndex2);
                for(let i = 0; i < categories.length; i++) {
                    if(categories[i]) {
                        let dataset = {
                            data: [],
                            borderWidth: 1,
                            borderColor: '#444',
                            label: categories[i],
                            backgroundColor: this.localConfig.customColorConfig && this.localConfig.customColorConfig[categories[i]] ? this.localConfig.customColorConfig[categories[i]] : this.colorArray[i]
                        };
                        datasets.push(dataset);
                    }
                }
                return datasets;
            },
            filterByCategory1() {
                let targetField = this.getField(this.localConfig.categoryIndex1);
                this.xAxisLabel = this.getFieldName(this.localConfig.categoryIndex1);
                //we have a data array containing e.g. 5 datarows
                //but now we need arrays with category1.length as max length
                // like: [category1.1, category1.2, category1.3] (the index marks the place on the x-axis)
                let rawData = [];
                for(let j = 0; j < this.dataCategory2.length; j++) {
                    if(!rawData[0]) {
                        rawData = this.dataCategory2[j].data;
                    }
                    //filter all data containing no value for this field
                    rawData = rawData.filter(item => {return item[targetField.columnIndex] || item[targetField.columnIndex] === 0});
                    this.dataCategory2[j].data = [];
                    //dataCategory1.length sets the length of the data array (x-axis categories)
                        for(let i = 0; i < this.dataCategory1.length; i++) {
                            if(rawData[0]) {
                                // if(item in temp for current category)
                                let matchedIndex = rawData.findIndex(item => {
                                    return item && item[targetField.columnIndex].includes(this.dataCategory1[i])
                                });
                                if (matchedIndex !== -1) {
                                    // push
                                    this.dataCategory2[j].data.push(rawData.splice([matchedIndex], 1)[0]);
                                } else {
                                    // else
                                    // push null
                                    this.dataCategory2[j].data.push(null);
                                }
                            }
                        }
                        if(rawData[0]) {
                            // if(category is last) && elements in rawData left
                            //duplicate category, empty its data attribute
                            //push category to j++
                            let deepCopy = JSON.parse(JSON.stringify(this.dataCategory2[j]));
                            this.dataCategory2.splice(j + 1, 0, deepCopy);
                            this.dataCategory2[j + 1].data = [];
                        }
                }
            },
            //makes an array like this:
            /*
            * [
            *  {
                "data": [
                  [
                    "Test Number 2",
                    "16f0e136-7990-4b8a-80ed-ca21c3db8aaa",
                    "Finish",
                    "7/1/2020 12:26:58 PM",
                    "This is the end",
                    "",
                    "",
                    "6"
                  ]
                ],
                "borderWidth": 1,
                "borderColor": "#000",
                "label": "Finish",
                "backgroundColor": "#515163"
              }
            * ]
            * */
            filterByCategory2() {
                for(let i = 0; i < this.dataCategory2.length; i++) {
                    let filteredData = this.localData.filter(item => {return item.includes(this.dataCategory2[i].label)});
                    this.dataCategory2[i].data = filteredData;
                }
            },
            filterByCategory3() {
                let targetField = this.getField(this.localConfig.categoryIndex3);
                this.yAxisLabel = this.getFieldName(this.localConfig.categoryIndex3);
                for(let j = 0; j < this.dataCategory2.length; j++) {
                    var rowHasAtLeastOneValue = false;
                    for(let i = 0; i < this.dataCategory2[j].data.length; i++) {
                        let dataRow = this.dataCategory2[j].data[i];
                        let label = this.dataCategory2[j].label;
                        if(dataRow && dataRow[targetField.columnIndex]) {
                            this.dataCategory2[j].data[i] = {
                                y: this.convertData(targetField.type, dataRow[targetField.columnIndex]),
                                //hover-label generation @todo
                                legend: this.generateLegendString(dataRow, targetField, label)
                            };
                            rowHasAtLeastOneValue = true;
                        }
                        else {
                            this.dataCategory2[j].data[i] = null;
                        }
                    }
                    if(!rowHasAtLeastOneValue) {
                        this.dataCategory2.splice(j, 1);
                        j -= 1; //use the same index again because we deleted the row with no values
                    }
                }
            },
            encodeHTML(string) {
              var lt = /</g,
              gt = />/g,
              ap = /'/g,
              ic = /"/g;
              return string.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");
            },
            generateLegendString(dataRow, targetField) {
                let string = '<div class="label">' + this.encodeHTML(targetField.name) + '</div>' + this.yAxisLabel + ': ' + this.convertDataForDisplay(targetField.type, dataRow[targetField.columnIndex]);
                if(this.localConfig.addValuesToLabelIndizes) {
                    for(let i = 0; i < this.localConfig.addValuesToLabelIndizes.length; i++) {
                        let field = this.getField(this.localConfig.addValuesToLabelIndizes[i]);
                        string += '<br /><br /><div class="label">' + this.encodeHTML(field.name) + '</div>' + this.convertDataForDisplay(field.type, dataRow[field.columnIndex]);
                    }
                }
                return string;
            },
            initChart() {
                this.destroyChart();
                let ctx = document.getElementById(this.uniqueIdentifier);
                this.theChart = new Chart(ctx, {
                    type: this.currentType,
                    data: {
                        borderWidth: 2,
                        datasets: this.dataCategory2,
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                        legend: {
                            position: 'bottom',
                            display:false,
                        },
                        legendCallback: function(chart) {
                            var text = [];
                            var list = [];
                            if(chart.data.datasets.length) {
                                text.push('<ul class="' + chart.id + '-legend">');
                                for (var i = 0; i < chart.data.datasets.length; i++) {
                                    if (chart.data.datasets[i].label) {
                                        let label = chart.data.datasets[i].label;
                                        if(!list.includes(label)) {
                                            text.push('<li><div style="background-color:' +
                                                chart.data.datasets[i].backgroundColor +
                                                '"></div>');
                                            list.push(label);
                                            text.push(label);
                                            text.push('</li>');
                                        }
                                    }
                                }
                                text.push('</ul>');
                            }
                            return text.join('');
                            // Return the HTML string here.
                        },
                        tooltips: {
                            displayColors: true,
                            mode: 'point',
                            //axis: 'dataset',
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    return data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']].legend;
                                }
                            },
                            enabled: false,
                            custom: (tooltipModel) => { return this.customTooltip(tooltipModel)},
                        },
                        scales: {
                                xAxes: [{
                                    ticks: {
                                        fontColor: "rgba(0,0,0,0.4)",
                                        fontSize: this.fontSize/100*80,
                                    },
                                    stacked: true,
                                    labels: this.dataCategory1,
                                    gridLines: {
                                       // display: false,
                                        offsetGridLines: true,
                                        color: "rgba(0,0,0,0.4)",
                                        lineWidth: this.fontSize/100*8,
                                    },
                                    type: this.xAxisType,
                                    scaleLabel: {
                                        display:     true,
                                        labelString: this.xAxisLabel,
                                        fontColor: "rgba(0,0,0,0.6)",
                                        fontSize: this.fontSize,
                                    },
                                    time:       {
                                        parser: 'DD.MM.YYYY HH:mm:ss',
                                        distribution: 'series',
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: "rgba(0,0,0,0.4)",
                                        lineWidth: this.fontSize/100*8,
                                    },
                                    stacked: true,
                                    unitStepSize: 1000000,
                                    //ticks: {source: 'labels'},
                                    ticks: {
                                        beginAtZero: true,
                                        fontColor: "rgba(0,0,0,0.4)",
                                        fontSize: this.fontSize/100*80,
                                    },
                                    distribution: 'series',
                                    scaleLabel: {
                                        display:     true,
                                        labelString: this.yAxisLabel,
                                        fontColor: "rgba(0,0,0,0.6)",
                                        fontSize: this.fontSize,
                                    },
                                    type: this.yAxisType,
                                    time:       {
                                        parser: 'DD.MM.YYYY HH:mm:ss',
                                        distribution: 'series',
                                    },
                                }]
                        }
                    }
                });
            },

            /*
            * Helper Methods
            * */
            countDescriptionCharacters(value) {
                this.descriptionLength = 180 - value.length;
            },
            getChartName() {
                return this.localConfig.name ? this.localConfig.name : this.uniqueIdentifier;
            },
            getChartDescription() {
                return this.localConfig.description ? this.localConfig.description : null;
            },
            getField(index) {
                let obj = this.fields[Object.keys(this.fields)[index]];
                obj.name = Object.keys(this.fields)[index];
                return obj;
            },
            getFieldName(index) {
                return Object.keys(this.fields)[index];
            },
            getDistinctRowValues(index, globalData = false) {
                let array = [];
                let data = this.localData;
                if(globalData) {
                    data = this.data;
                }
                for(let i = 0; i < data.length; i++) {
                    if(data[i] && data[i][index]) {
                        let value = data[i][index];
                        if(value !== 'undefined' && !array.includes(value)) {
                            array.push(value);
                        }
                    }
                }
                return array;
            },
            convertData(fieldType, dataItem) {
                let convertedValue = dataItem;
                if(dataItem) {
                    if(fieldType === 'time') {
                        convertedValue = this.$moment(dataItem);
                    }
                    else if(fieldType === 'timestamp') {
                        convertedValue = this.$moment(dataItem);
                    }
                    else if(fieldType === 'number') {
                        convertedValue = parseInt(dataItem);
                    }
                }
                return convertedValue;
            },
            convertDataForDisplay(fieldType, dataItem) {
                let convertedValue = dataItem;
                if(dataItem) {
                    if(fieldType === 'time') {
                        convertedValue = this.$moment(dataItem).format('HH:mm:ss');
                    }
                    else if(fieldType === 'timestamp') {
                        convertedValue = this.$moment(dataItem).format('DD.MM.YYYY HH:mm:ss');
                    }
                    else if(fieldType === 'number') {
                        convertedValue = parseInt(dataItem);
                    }
                }
                return convertedValue;
            },
            getAxisType(fieldType) {
                if(fieldType === 'time' || fieldType === 'timestamp') {
                    return 'time';
                }
                else if(fieldType === 'number') {
                    return 'linear';
                }
                return 'category';
                //there is a 4th type: logarithmic
            },
            destroyChart() {
                if(this.theChart) {
                    this.theChart.destroy();
                    this.theChart = null;
                }
            },
            compareItems(fieldType, item1, item2) {
                if(item1 === item2) {
                    return 0;
                }
               if(fieldType === 'time' || fieldType === 'timestamp') {
                    return this.unixFromISO(item1) < this.unixFromISO(item2) ? -1 : 1;
                }
                return item1 > item2 ? -1 : 1;
            },
            addRemoveLabelData: function(columnIndex) {
                if(!this.localConfig.addValuesToLabelIndizes) {
                    this.localConfig.addValuesToLabelIndizes = [];
                }
                let labelIndex = this.localConfig.addValuesToLabelIndizes.findIndex(item => {return item === columnIndex});
                if(labelIndex === -1) {
                    this.localConfig.addValuesToLabelIndizes.push(columnIndex);
                }
                else {
                    this.localConfig.addValuesToLabelIndizes.splice(labelIndex, 1);
                }
                this.prepareDataForChart();
            },
            customTooltip: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body) {
                        innerHtml += '<tr><td>' + body + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this.theChart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        }
    }
</script>
<style lang="scss">

    .chart-legend {
        ul {
            text-align: left;
            li {
                color: #000;
                font-weight:bold;
                display:inline-block;
                margin-right:10px;
                margin-bottom: 10px;
                list-style:none;
                position:relative;
                padding: 8px 8px 8px 25px;
                max-height: 140px;
                overflow:auto;
                border: 1px solid rgba(0,0,0,0.2);
                background-color: rgba(0,0,0,0.1);
                div {
                    width: 15px;
                    height: 15px;
                    position:absolute;
                    left:5px;
                    margin-top:1px;
                    top:50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
        }
        //styles for canvasToPDF-printing
        &.print {
            ul {
                margin-bottom:0;
                li {
                    background-color: transparent;
                    border: 0 transparent;
                    font-family: 'Helvetica', 'sans-serif';
                    font-weight: normal;
                    margin:0;
                    display:block;
                    div {
                        margin-top:0;
                    }
                }
            }
        }
    }
    #chartjs-tooltip {
        max-width: 400px;
        border: 0 transparent !important;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        tr, th, td, thead, tbody, table, div {
            border: 0 transparent !important;
        }
        background-color: rgba(0,0,0,0.5);
        margin-top: 10px;
        thead {
            background-color: rgba(0,0,0,0.4);
        }
        .label {
            font-weight:bold;
        }
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 10px 6px 0;
            border-color: transparent rgba(0,0,0,0.8) transparent transparent;
            position:absolute;
            top:10px;
            left:-10px;
        }
    }
    /*
    ---------------
    CHARTS
    ----------------
    */
    .chart-font-size {
        font-size: 14px;
    }

    @media (min-width: 1400px) {
        .chart-font-size {
            font-size: 16px;
        }
    }

</style>