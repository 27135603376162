<template>
    <div v-if="localRoutes && finished" :class="[classes ? classes : 'col-9 col-lg-8']">
        <ul id="project-edit-tabs" class="row no-gutters tabs">
            <template v-for="(item, key) in localRoutes">
                <navigation-tab
                      v-if="item.access"
                        :title="customTabName ? customTabName : item.name"
                        :link="item.path"
                        :routename="item.name"
                        :pid="id"
                        :type="type"
                        :translate="true"
                        :disabled="disabled"
                        :arrow-style="arrowStyle"
                        :active-class="isActive(item.name) || firstActive && key === 0 ? 'active' : ''"
                        :inactive-class="localRoutes.filter(route =>{return route[tabSelector]}).length === 1 && firstInactive ? 'inactive' : ''"
                        :key="key"
                />
            </template>
        </ul>
    </div>
</template>

<script>
    import NavigationTab from "./NavigationTab";
    export default {
        name: 'Tabs',
        components: {
            NavigationTab
        },
        props: {
            /**
             * @id String replaces the :id in the route
             */
            id : { type: String, default: ''},
            /**
             * @disabled Boolean if true, the tabs won't be clickable
             */
            disabled: {type: Boolean, default: false},
            /**
             * @arrowStyle Boolean whether or not to use the arrow style of the tabs
             */
            arrowStyle: {type: Boolean, default: false},
            /**
             * @firstActive Boolean whether or not to show the first item as active by default
             */
            firstActive: {type: Boolean, default: false},
            /**
             * @firstActive Boolean whether or not to show the first item as inactive by default
             */
            firstInactive: {type: Boolean, default: false},
            /**
             * @tabSelector String the property set in router.js for the route used for filtering (e.g. "assettab" = true)
             */
            tabSelector: {type: String, required: true},
            /**
             * @classes String custom classes instead of the default ones in case you want to overwrite them
             */
            classes: {type:String, default: ''},
            /**
             * @customTabName String if you want to overwrite the tab-title (usually just for single-tab navs)
             */
            customTabName: {type: String, default: ''},
        },
        data() {
          return {
            localRoutes: [],
            type: '',
            finished: false,
          };
        },
        watch: {
            tabSelector() {
              this.type = this.tabSelector.replace('tab', '');
              this.setRoutes();
            }
        },
        beforeMount(){
          this.type = this.tabSelector.replace('tab', '');
          if(!this.localRoutes.length) {
            this.setRoutes();
          }
        },
        methods: {
          async setRoutes() {
            this.finished = false;
            const routes = this.$router.options.routes;
            this.localRoutes = routes.filter(item => {
              return item[this.tabSelector];
            });
            for(let i = 0; i < this.localRoutes.length; i++) {
              const route = this.localRoutes[i];
              const meta = route.meta;
              route.access = true;
              if(meta.accessPermission && meta.staticPermission) {
                route.access = await this.$store.dispatch('checkTeamOrgPermission', {
                  op: this.localRoutes[i].meta.accessPermission,
                  opOnly: true,
                  orgPermissionOnly: meta.orgPermissionOnly ? meta.orgPermissionOnly : false,
                  allowSFXUsers: meta.allowSFXUsers,
                  allowSFXBilling: meta.allowSFXBilling
                });
              } else if(meta.servicePermission) {
                route.access = (this.$store.getters.isSuperAdmin || this.$store.getters.isSFXMember) ? true : await this.$store.dispatch('checkIfService', {
                  organizationIds: this.$store.getters.getCurrentUserOrgs.map(item => {return item.id}),
                  serviceName: meta.servicePermission
                });
              }
            }
            this.finished = true;
          },
          isActive(input) {
            return this.$route.name === input;
          },
        }
    }
</script>