<template>
    <two-column-layout
      sub-menu-selector="librarypanel"
      tab-selector="texttab"
      back-button-tab-selector="textbackbutton"
    >
        <info-panel
                slot="sidebarLeft"
                :id="$route.params.id"
                :upload-type="'Asset'"
                :created="$store.getters.getTextCreationDate(id)"
                :last-change="$store.getters.getTextChangeDate(id)"
                :downloadable="true"
                preview-id="textEdit"
                :organization="$store.getters.getTextOrganization($route.params.id)"
        />

       <team-attacher
              slot="sidebarLeft"
              class="mt-3"
              :id="$route.params.id"
              :teams="teams"
              store-name="Asset"
              v-if="$store.getters.getTextOrganization($route.params.id)"
              :limit-by-organization-id="$store.getters.getTextOrganization($route.params.id)"
              @added="loadAssetTeams"
              @removed="loadAssetTeams"
          />
        <info-panel
                slot="sidebarLeft"
                :id="$route.params.id"
                :tags="$store.getters.getTextTags($route.params.id)"
                :taggable="true"
                preview-id="textEdit"
                @removeTag="removeTag"
                @addTag="addTag"
                :key="'infopanel2'"
                :limit-tags-to-organization="$store.getters.getTextOrganization($route.params.id)"
        />

        <div slot="mainContent">
          <content-title-block
                  v-if="$store.getters.getTextTitle(id)"
            :value="$store.getters.getTextTitle(id)"
            :id="$route.params.id"
                  store-name="Text"
                  :organization-id="$store.getters.getTextOrganization($route.params.id)"
          />
            <language-selector
                class="mb-3 d-inline-block"
                :value="$store.getters.getTextLocale($route.params.id)"
                @update="updateLanguage"
            />
            <content-field-block
                :value="$store.getters.getTextContent(id)"
                :id="$route.params.id"
                store-name="Text"
                field-name="content"
                label="maintext"
                input-type="textarea"
            />

          <content-description-block
                  :value="$store.getters.getTextDescription(id)"
                  :id="$route.params.id"
                  store-name="Text"
          />

            <meta-panel
                    :key="item.id"
                    v-for="(item) in this.$store.getters.getTextMetaSets(id)"
                    :title="item.description"
                    :fields="item.metaFields"
                    icon-left="folder"
                    store-name="Text"
                    :organization-id="[$store.getters.getTextOrganization(id)]"
                    @updated="loadText"
            />
        </div>
    </two-column-layout>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import TwoColumnLayout from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import ContentFieldBlock from "../components/ContentFieldBlock";
    import LanguageSelector from "../components/forms/LanguageSelector";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    export default {
        name: 'TextEdit',
        components: {
            MetaPanel,
            InfoPanel,
            TwoColumnLayout,
            ContentTitleBlock,
            ContentDescriptionBlock,
            ContentFieldBlock,
            LanguageSelector,
            TeamAttacher
        },
        data() {
            return {
                id: '',
                teams: []
            };
        },
        beforeMount() {
            this.loadText();
            this.loadAssetTeams();
            this.id = this.$route.params.id;
        },
        methods: {
          loadText() {
             this.$store.dispatch('loadText', {
                id: this.$route.params.id,
                include: 'metaSets,tags'
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              } else if(e.status === 404) {
                this.$router.push('/not-found')
              }
            });
          },
          loadAssetTeams() {
            this.$store.dispatch('clientLoadAssetTeams', {
              id: this.$route.params.id
            }).then(data => {
              this.teams = data;
            });
          },
            removeTag: function(tag) {
                this.$store.dispatch('removeTextTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            addTag: function(tag) {
                this.$store.dispatch('addTextTag', {
                    id: this.$route.params.id,
                    args: [tag.id],
                });
            },
            updateLanguage(lang) {
                let args = {
                    id: this.$route.params.id,
                    locale: lang,
                };
                this.$store.dispatch('updateText', args);
            },
        },
    }
</script>
