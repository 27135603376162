<template>
    <drop-down
        class="language-selector"
        v-if="languages && languages.length"
        :labels="languages.map(item => { return $t(item) })"
        :open-direction="openDirection"
        :values="languages"
        :initial-active-value="value"
        @select="setValue"
    />
</template>

<script>
import {Languages, PlainLanguages} from '@/enum';
import listenerMixinJs from "../mixins/listenerMixin.js";
import DropDown from "./DropDown";

export default {
  name: "LanguageSelector",
  components: {
    DropDown
  },
  mixins: [listenerMixinJs],
  props: {
    value: {type: String, default: null},
    usePlainLanguage: {type: Boolean, default: false},
    /***/
    chooseText: {type: String, default: ""},

    /*
    * Add language keys to exclude
    * **/
    exclude: {type: Array, default: () => {return []}},
    /**
     * whether the opened options should expand to the top or to the bottom
     * **/
    openDirection: {type: String, default: "bottom"}
  },
  data() {
    return {
      updateValue: '',
      languages: {},
      closed: true,
      Languages: Languages,
      PlainLanguages: PlainLanguages
    };
  },
  watch: {
    value: function () {
      this.updateValue = this.value;
    },
    updateValue() {
      if (this.updateValue && (this.updateValue !== this.value)) {
        this.$emit('update', this.updateValue);
      }
    }
  },
  beforeMount() {
    this.updateValue = this.value;
    let languages = this.usePlainLanguage ? PlainLanguages : Languages;
    /*if (this.usePlainLanguage && this.chooseText) {
      languages.Unknown.name = this.chooseText;
    }*/
    this.getActiveLanguagesAsArray(languages);
  },
  methods: {
    getActiveLanguagesAsArray(languages) {
      this.languages = Object.values(languages).filter(item => {
            return !!item.active && (!this.exclude || !this.exclude.includes(item.name));
          }).map(item => {
            return item.name
          })
    },
    setValue(val) {
      this.updateValue = val;
    },
    toggleOpenClosed() {
      this.closed = !this.closed;
    }
  }
}
</script>

<style lang="scss" scoped>
.invisible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.form-switch {
  border-radius: 3px;
}
</style>