<template>
<div></div>
</template>

<script>
export default {
  name: "ErrorMixin.js",
  methods: {
    getErrorContent(e) {
      const {response} = e;
      if(response) {
        const {errors} = response;
        return errors && errors.message ? errors.message : e;
      } else {
        return e;
      }
    }
  }
}
</script>

<style scoped>

</style>