<template>
  <div/>
</template>

<script>
export default {
  name: "MetaSetsMixin",
  methods: {
    smashMetaSets(metaSets, applyDefaultValue) {
      let metaFields = {};
      metaSets.map(item => {
        item.metaFields.map(field => {
          metaFields[field.key] = field.metaValues[0] ? field.metaValues[0].value : applyDefaultValue ? field.defaultValue : null
        })
      });
      return metaFields;
    },
  },
}
</script>
